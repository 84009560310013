(function() {
    'use strict';

    angular.module('app.core').service("DialogService", DialogService);
    DialogService.$inject = ['$uibModal'];

    function DialogService($uibModal) {
        return {
            alert: function(msn) {
                $uibModal.open({
                    ariaLabelledBy: 'modal-title',
                    ariaDescribedBy: 'modal-body',
                    templateUrl: "core/components/dialog/partials/alert.html",
                    controllerAs: 'vm',
                    controller: function($uibModalInstance) {
                        var _vm = this;
                        _vm.msn = msn;

                        _vm.ok = function() {
                            $uibModalInstance.close();
                        };
                    }
                }).result.then(new Function(), new Function());
            },
            confirm: function(msn, functionConfirm, title) {
                $uibModal.open({
                    ariaLabelledBy: 'modal-title',
                    ariaDescribedBy: 'modal-body',
                    templateUrl: "core/components/dialog/partials/confirm.html",
                    controllerAs: 'vm',
                    controller: function($uibModalInstance) {
                        var _vm = this;

                        _vm.title = "";
                        if (title) {
                            _vm.title = title;
                        }

                        _vm.msn = msn;
                        _vm.confirm = function() {
                            functionConfirm();
                            $uibModalInstance.close();
                        };
                        _vm.cancel = function() {
                            $uibModalInstance.dismiss('cancel');
                        };
                    }
                }).result.then(new Function(), new Function());
            },
            modal: function(templateUrl, controller) {
                $uibModal.open({
                    ariaLabelledBy: 'modal-title',
                    ariaDescribedBy: 'modal-body',
                    templateUrl: templateUrl,
                    size: 'lg',
                    controllerAs: 'vm',
                    controller: controller
                }).result.then(new Function(), new Function());
            }
        };
    }

})();