(function() {
    'use strict';

    angular.module('app.core').directive("iasPaginationClient", IasPaginationClient);

    function IasPaginationClient() {
        return {
            scope: {
                totalItems: '=',
                currentPage: '=?',
                maxSize: '=?',
                itemsPerPage: '=?',
                ngChange: '&?'
            },
            templateUrl: "core/directives/pagination/partials/ias-pagination-client.html",
            link: function(scope, element, attr) {
                scope.maxSize = scope.maxSize || 5;
                scope.currentPage = scope.currentPage || 1;
                scope.itemsPerPage = scope.itemsPerPage || 10;
            }
        };
    }

})();
