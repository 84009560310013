/*
jslint
    devel: true, maxerr: 10
*/

/*
global
    required: true
*/

/*
 * Core Module
 * @directive iasMenu
 * @author iFactory Solutions Ltda.
 * @version 1.0.0
 * @since 1.0.0
 */
(function() {
    'use strict';

    angular.module('app.core').directive("iasMenu", IasMenu);
    IasMenu.$inject = ['MENU', '$state', 'SecurityManagerFactory'];

    function IasMenu(MENU, $state, securityManager) {
        return {
            scope: {},
            templateUrl: "core/directives/menu/partials/menu.html",
            link: function(scope, element, attr) {
                scope.hasPermission = function(privilege) {
                    return securityManager.hasPermission(privilege);
                };

                

                var menuItems = [];
                _.each(MENU, function(menu) {
                    var item = _.extend({ SUBMENU: [] }, _.pick(menu, 'LABEL', 'STATE'));
                    _.each(menu.SUBMENU, function(subMenu) {
                        if (is.existy(subMenu.STATE) && is.not.empty(subMenu.STATE)) {
                            if (is.not.existy(subMenu.PERMISSION)) {
                                item.SUBMENU.push(subMenu);
                            } else if (scope.hasPermission(subMenu.PERMISSION)) {
                                item.SUBMENU.push(subMenu);
                            }
                        }
                    });

                    if (item.SUBMENU.length > 0) {
                        menuItems.push(item);
                    }
                });
                scope.listMenu = menuItems;

                scope.goState = function(state) {
                    $state.go(state);
                };
            }
        };
    }

})();