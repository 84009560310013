/*
jslint
    devel: true, maxerr: 10
*/

/*
global
    required: true
*/

/*
 * Selection User Profile Controller
 * @module app.usuario (User Module)
 * @author iFactory Solutions Ltda.
 * @version 1.0.0
 * @since 1.0.0
 */
(function() {
    'use strict';

    angular.module('app.usuario').controller('UserProfileController', UserProfileController);
    UserProfileController.$inject = ['APP_STATE', 'APP_EVENT', '$state', '$rootScope', 'toastService', 'SecurityManagerFactory', 'Usuario', 'UsuarioPerfil'];

    function UserProfileController(APP_STATE, APP_EVENT, $state, $scope, toastService, securityManagerFactory, userResource, userAccessProfileResource) {
        var vm = this;

        vm.goBack = function(clearMessages) {
            // Retorna ao "state" anterior, se houver um "state" válido.
            if (is.existy($state.previous) && is.not.empty($state.previous.name)) {
                if (clearMessages) {
                    toastService.clear();
                }
                $state.go($state.previous.name, $state.previous.params);
            } else {
                $state.reload($state.current);
            }
        };

        vm.save = function() {
            if (vm.isValid()) {
                var user = {
                    id: vm.model.user.id,
                    nomeCurto: vm.model.user.identity.shortName,
                    nomeCompleto: vm.model.user.identity.fullName,
                    email: vm.model.user.login,
                    cpf: vm.model.user.identity.cpf,
                    login: vm.model.user.login,
                    senha: vm.model.user.newPassword
                };
                userResource.changeProfile({ id: user.id }, user, function(response) {
                    toastService.success("Seus dados foram salvos com sucesso.");
                    if (is.existy(vm.userForm)) {
                        vm.userForm.$setPristine();
                        vm.userForm.$commited = false;
                    }
                    vm.model.credentials.name = vm.model.user.identity.shortName;
                    vm.model.credentials.identity.shortName = vm.model.user.identity.shortName;
                    vm.model.credentials.identity.fullName = vm.model.user.identity.fullName;
                    securityManagerFactory.updateUserCredentials(vm.model.credentials);
                    vm.goBack(false);
                    // TODO Go to the previous state.
                }, function(error) {
                    if (error.status == 404) {
                        toastService.error(error.data.message);
                    } else if (error.status == 409) {
                        toastService.error(error.data.message);
                    } else {
                        toastService.error('Não foi possível processar a sua requisição. Revise os dados e tente novamente.');
                    }
                });
            }
        };

        vm.enableEditMode = function() {
            vm.view.isInEditMode = true;
        };

        vm.cancel = function() {
            if (vm.refreshSearch) {
                $scope.$emit(APP_EVENT.CRITERIO_SELECAO.REFRESH);
            }

            vm.goBack();
        };

        vm.goEditPage = function() {
            vm.enableEditMode();
            // Quando a máscara é aplicada no campo CPF, logo após a exibição da tela o formulário fica $dirty.
            // O trecho de código abaixo retorna o formulário ao estado $pristine.
            if (is.existy(vm.userForm)) {
                vm.userForm.$setPristine();
                if (is.existy(vm.userForm.cpf)) {
                    vm.userForm.cpf.$setPristine();
                }
            }
        };

        vm.showErrorBox = function(type) {
            var result = false;

            if (type == 'required') {
                result = vm.userForm && vm.userForm.$error && vm.userForm.$error.required;
            } else if (type == 'minlength') {
                result = vm.userForm && vm.userForm.$error && vm.userForm.$error.minlength && !vm.userForm.$error.required;
            } else if (type == 'cpf') {
                result = vm.userForm && vm.userForm.$error && vm.userForm.$error.cpf && !vm.userForm.$error.required && !vm.userForm.$error.minlength;
            } else if (type == 'password') {
                result = vm.userForm && vm.userForm.$error && !vm.view.passwordValid && !vm.userForm.$error.cpf && !vm.userForm.$error.required && !vm.userForm.$error.minlength;
            }

            result = (is.existy(result) ? result : false) && vm.userForm.$invalid && vm.userForm.$submitted && vm.view.isInEditMode;
            return result && vm.view.isInEditMode;
        };

        vm.showWarnBox = function() {
            return vm.userForm && vm.userForm.$dirty && vm.view.isInEditMode;
        };

        vm.changePasswordChanged = function() {
            vm.model.user.newPassword = null;
            vm.userForm.newPassword.$setPristine();
            vm.userForm.newPassword.$setUntouched();
            vm.model.user.newPasswordConfirmed = null;
            vm.userForm.newPasswordConfirmed.$setPristine();
            vm.userForm.newPasswordConfirmed.$setUntouched();
            vm.userForm.$submitted = false;
            if (!_hasAtLastOneDirtyFieldExcept(['newPassword', 'newPasswordConfirmed', 'changePassword'])) {
                vm.userForm.$setPristine();
            }
        };

        vm.isValid = function() {
            vm.view.passwordValid = (!vm.view.changePassword) || (vm.model.user.newPassword === vm.model.user.newPasswordConfirmed);

            if (vm.userForm) {
                vm.userForm.$valid = vm.view.passwordValid;
                vm.userForm.$invalid = !vm.view.passwordValid;
            }

            return is.existy(vm.userForm) && vm.userForm.$valid && vm.view.passwordValid;
        };

        var _hasAtLastOneDirtyFieldExcept = function(excludedFields) {
            var fields = _.reject(vm.userForm.$$controls, function(element) {
                return _.indexOf(excludedFields, element.$name) > -1;
            });

            if (is.empty(fields)) {
                return false;
            }

            return is.any.truthy(_.map(fields, function(element) {
                return element.$dirty;
            }));
        };

        (function() {
            vm.model = {
                user: null
            };
            vm.model.credentials = securityManagerFactory.getUserCredentials();
            vm.view = {
                changePassword: false,
                isInEditMode: false,
                isAvailable: true,
                passwordValid: true,
            };

            userResource.get({ id: vm.model.credentials.id }).$promise.then(function(user) {
                vm.model.user = {
                    id: user.id,
                    login: user.login,
                    newPassword: null,
                    newPasswordConfirmed: null,
                    identity: {
                        id: user.pessoa.id,
                        fullName: user.pessoa.nomeCompleto,
                        shortName: user.pessoa.nomeCurto,
                        cpf: user.pessoa.cpf
                    },
                    profile: {}
                };

                userAccessProfileResource.get({ id: vm.model.credentials.id }).$promise.then(function(userAccessProfile) {
                    vm.model.user.profile = {
                        id: userAccessProfile.id,
                        accessProfile: {
                            id: userAccessProfile.perfilAcesso.id,
                            name: userAccessProfile.perfilAcesso.nome
                        }
                    };
                }, function(error) {
                    vm.view.isInEditMode = false;
                    if (error.status != 400) {
                        toastService.warning('Não foi possível recuperar o seu perfil de acesso.');
                    }
                });
            }, function(error) {
                vm.view.isInEditMode = false;
                vm.view.isAvailable = false;
                toastService.error({
                    status: error.status,
                    message: 'Não foi possível recuperar as informações das suas credenciais.'
                });
            });
        }());
    }

}());