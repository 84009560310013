(function() {
    'use strict';

    angular
        .module('app.core')
        .directive('iasPermission', IasPermission);

    IasPermission.$injector = ['$rootScope', 'SecurityManagerFactory'];

    function IasPermission($rootScope, SecurityManagerFactory) {
        return {
            restrict: 'A',
            scope: {
                permission: '@'
            },
            link: function(scope, element, attrs) {
                if (!SecurityManagerFactory.hasPermission(scope.permission)) {
                    element.remove();
                }
            }
        };
    }
})();