(function(){
    'use strict';

    angular.module('app.core').service('dateUtilService', DateUtilService);

    function DateUtilService($filter) {
        var self = this;

        this.TYPE = {
            DATE: 'date',
            DATE_TIME: 'datetime',
            TIME: 'time',
            SHORT_DATE: 'shortDate'
        };

        var _SERVER_FORMATS = {
            datetime: 'YYYY-MM-DD HH:mm',
            date: 'YYYY-MM-DD',
            time: 'HH:mm',
            shortDate: 'YYYY-MM'
        };

        var _CLIENT_FORMATS = {
            datetime: 'DD/MM/YYYY HH:mm',
            date: 'DD/MM/YYYY',
            time: 'HH:mm',
            shortDate: 'MM/YYYY'
        };

        var _FORMAT_TO = {
            datetime: 'YYYYMMDDHHmm',
            date: 'YYYYMMDD',
            time: 'HHmm',
            shortDate: 'YYYYMM'
        };

        var _parseDate = function(value, format) {
            if (value) {
                var numberValue = Number(value);

                if (is.nan(numberValue)) {
                    return moment(value, format).toDate();
                } else {
                    return moment(numberValue).toDate();
                }
            } else {
                return null;
            }
        };

        var _parseFrom = function(value, type, format) {
            type = type || 'datetime';

            switch (type) {
                case 'date':
                    return _parseDate(value, format.date);
                    break;
                case 'time':
                    return _parseDate(value, format.time);
                    break;
                case self.TYPE.SHORT_DATE:
                    return _parseDate(value, format.shortDate);
                    break;
                default:
                    return _parseDate(value, format.datetime);
            }
        };

        var _formatTo = function(value, type, format) {
            type = type || 'datetime';

            switch (type) {
                case 'date':
                    return moment(value).format(format.date);
                    break;
                case 'time':
                    return moment(value).format(format.time);
                    break;
                case self.TYPE.SHORT_DATE:
                    return moment(value).format(format.shortDate);
                    break;
                default:
                    return moment(value).format(format.datetime);
            }
        };

        this.parseFromClient = function(value, type) {
            return _parseFrom(value, type, _CLIENT_FORMATS);
        };

        this.parseFromServer = function(value, type) {
            return _parseFrom(value, type, _SERVER_FORMATS);
        };

        this.formatToClient = function(value, type) {
            return _formatTo(value, type, _CLIENT_FORMATS);
        };

        this.formatToServer = function(value, type) {
            return _formatTo(value, type, _SERVER_FORMATS);
        };

        this.parseTimeToDate = function(value) {
            return _parseDate(value, 'HH:mm');
        };

        this.parseToDateTime = function(value) {
            return _parseDate(value, _SERVER_FORMATS.datetime);
        };

        this.parseToDate = function(value) {
            return _parseDate(value, _SERVER_FORMATS.date);
        };

        this.getFormatedTime = function(date) {
            return moment(date).format('HH:mm:ss');
        };

        this.parseToInt = function(dateOrTime, type) {
            var value;
            var formatFrom, formatTo;

            type = type || 'datetime';
            var numberValue = Number(dateOrTime);

            formatFrom = _CLIENT_FORMATS[type];
            formatTo = _FORMAT_TO[type];

            if (is.nan(numberValue)) {
                value = moment(dateOrTime, formatFrom).format(formatTo);
            } else {
                value = moment(numberValue).format(formatTo);
            }

            return parseInt(value);
        };

        this.isValid = function(date) {
            return moment(date).isValid();
        };

        this.getMonthList = function() {
            var monthList = [];
            var monthNames = [ "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho",
"Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro" ];

            angular.forEach(monthNames, function(month, index) {
                monthList.push({
                    key: index,
                    value: month
                })
            });

            return monthList;
        };

        this.getMonths = function() {
            return [
                {key: "01", value: "Janeiro", short: "JAN"},
                {key: "02", value: "Fevereiro", short: "FEV"},
                {key: "03", value: "Março", short: "MAR"},
                {key: "04", value: "Abril", short: "ABR"},
                {key: "05", value: "Maio", short: "MAI"},
                {key: "06", value: "Junho", short: "JUN"},
                {key: "07", value: "Julho", short: "JUL"},
                {key: "08", value: "Agosto", short: "AGO"},
                {key: "09", value: "Setembro", short: "SET"},
                {key: "10", value: "Outubro", short: "OUT"},
                {key: "11", value: "Novembro", short: "NOV"},
                {key: "12", value: "Dezembro", short: "DEZ"},
            ]
        }

        this.getMonth = function(month) {
            var months = this.getMonths();
            var resultado = $filter('filter')(months, function(row, index) {   
                return row.key == month;
            });
            
            return (resultado.length) ? resultado[0] : null; 
        }
        
    }

}());
