(function(){
    'use strict';

    angular.module('app.escolas').service('escolasService', EscolasService);
    EscolasService.$inject = ['Escolas', 'STATUS_ENUM'];

    function EscolasService(Escolas, STATUS_ENUM) {

        var _normalizeAtributosToServer = function(escola) {
            var atributosEntidadesAlteradosDTO = _.filter(escola.atributosEntidadesDTO, function (item) {
                item.situacao = {key:"A"};
                return item._updated;
            });

            escola.atributosEntidadesAlteradosDTO = atributosEntidadesAlteradosDTO;

            var atributosEntidadesIncluidosDTO = _.filter(escola.atributosEntidadesDTO, function(item) {
                item.situacao = {key:"A"};
                return is.not.existy(item.id);
            });

            escola.atributosEntidadesIncluidosDTO = atributosEntidadesIncluidosDTO;
        };

        this.filter = function(filter) {
            return Escolas.filter(filter).$promise;            
        };

        this.filterAtivo = function(filter) {
            return Escolas.filterAtivo(filter).$promise;            
        };
        
        this.get = function(id) {
            return Escolas.get({ id: id }).$promise;
        };

        this.delete = function(programa) {
            return Escolas.delete({id: programa.id}).$promise;
        };

        this.save = function(escola) {
            var entity = new Escolas(escola);

            _normalizeAtributosToServer(entity);
            
            if (entity.id) {
                return entity.$update();
            } else {
                return entity.$save();
            }
        };

        this.ativos = function() {
            return Escolas.ativo();
        };
        
        this.porRegionalMunicipio = function(idRegionalMunicipio) {
            return Escolas.porRegionalMunicipio({idRegionalMunicipio:idRegionalMunicipio});
        };
        
        this.porMunicipio = function(idMunicipio) {
            return Escolas.porMunicipio({idMunicipio:idMunicipio});
        };
        
        this.parceirasPorMunicipioOrUf = function(idMunicipio, idUf) {
            return Escolas.parceirasPorMunicipioOrUf({idMunicipio:idMunicipio, idUf:idUf});
        };
        
        this.parceirasPorMunicipio = function(idMunicipio) {
            return Escolas.parceirasPorMunicipio({idMunicipio:idMunicipio});
        };

        this.getTiposOrganizacao = function() {
            return Escolas.getTiposOrganizacao();
        }

        this.searchBy = function(filter) {
            return Escolas.searchBy(filter).$promise;
        }

        this.searchByWithPermission = function(filter) {
            return Escolas.searchByWithPermission(filter).$promise;
        }

        this.searchByFilterAndProgramasWithPermission = function(filter) {
            return Escolas.searchByFilterAndProgramasWithPermission(filter).$promise;
        }
        
        this.byFilter = function(filter) {
            return Escolas.byFilter(filter).$promise;
        }
        
        this.semAssociacao = function(filter) {
            return Escolas.semAssociacao(filter).$promise;
        }
        
        this.removeOldValuesFromAtributo = function(dados) {
            if (is.existy(dados) && is.not.empty(dados)) {
                var newData = angular.copy(dados);
                delete newData[0].opcaoItem;
                delete newData[0].valueOpcaoItemResposta;
                delete newData[0].valueNumericResposta;
                delete newData[0].valueDateResposta;
                delete newData[0].valueTimeResposta;
                delete newData[0].valueDateTimeResposta;
                delete newData[0].linha;
                delete newData[0].paragrafo;

                return newData;
            } else {
                return [];
            }
        };

        this.tiposCalendarios = function() {
            return Escolas.tiposCalendarios();
        };

    }

}());
