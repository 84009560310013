(function() {
    'use strict';

    angular.module('app.core').directive("iasMovimentacaoAluno", IasMovimentacaoAluno);
    IasMovimentacaoAluno.$inject = [
        '$uibModal', 'DialogService', '$http', 
        'toastService', 'APP_PROPERTY', 'utilService', 
        'turmasService'
    ];

    function IasMovimentacaoAluno($uibModal, DialogService, $http, 
        toastService, APP_PROPERTY, utilService, turmasService) {

        return {
            scope: {
                turma: '=',
                referencia: '=?',
                ngDisabled: '=?'
            },
            restrict: "E",
            templateUrl: "core/directives/movimentacao/partials/aluno.html",
            link: function(scope) { 
                scope.msnSuccess = false;
                scope.msnError = false;
                scope.transferenciaRecebidaInvalid = false;
                scope.transferenciaExpedidaInvalid = false;
                scope.abandonoInvalid = false;
                scope.isUltimaMovimentacao = true;

                var _movimentacao = function() {
                    var _movimentacao = [];
                    if (scope.turma.turmasMovimentacaoMensal.length > 0) {
                        _movimentacao = _.filter(scope.turma.turmasMovimentacaoMensal, function(movimentacao){
                            return movimentacao.referencia == scope.referencia;
                        });
                    }

                    if (_movimentacao.length > 0) {
                        return _movimentacao[0];
                    }

                    return null;
                };

                var _movimentacaoAnterior = function(movimentacao) {
                    var movimentacoesMensais = scope.turma.turmasMovimentacaoMensal;
                    
                    if (movimentacoesMensais.length > 0) {
                        var _movimentacao = null
                        _.filter(scope.turma.turmasMovimentacaoMensal, function(mov, index){
                            mov.index = index;
                            if(mov.referencia < scope.referencia){
                                _movimentacao = mov;
                            }
                            
                            return mov.referencia == scope.referencia;
                        });

                        return _movimentacao;
                    }

                    return null;
                };

                var _verificaIsUltimaMovimentacao = function(){
                    scope.isUltimaMovimentacao = true;
                    var movMensal = scope.turma.turmasMovimentacaoMensal;
                    for(var i = 0; movMensal != null &&  i < movMensal.length; i++){
                        if(movMensal[i].referencia > scope.referencia){
                            scope.isUltimaMovimentacao = false;
                        }
                    }
                }

                scope.calcularMatriculaAtual = function(movimentacao) {
                    scope.msnError = false;

                    var recebida = 0;
                    var expedida = 0;
                    var abandono = 0;
                    var anterior = 0;
                    var valorInicial = 0;

                    if (movimentacao.transferenciaRecebida) {
                        scope.transferenciaRecebidaInvalid = false;
                        recebida = parseInt(movimentacao.transferenciaRecebida);
                    }    

                    if (movimentacao.transferenciaExpedida) {
                        scope.transferenciaExpedidaInvalid = false;
                        expedida = parseInt(movimentacao.transferenciaExpedida);
                    }

                    if (movimentacao.abandono) {
                        scope.abandonoInvalid = false;
                        abandono = parseInt(movimentacao.abandono);
                    }

                    if (scope.numeroMatriculaAnterior)
                        anterior = parseInt(scope.numeroMatriculaAnterior);

                    valorInicial = (anterior > 0) ? anterior : matriculaAtual;

                    var total = ((valorInicial + recebida) - expedida) - abandono;

                    if (total < 0) {
                        scope.msnError = "A matrícula atual não pode ficar negativa.";
                    }

                    movimentacao.matriculaAtual = total;
                };

                if (undefined !== scope.turma) {
                    scope.numeroMatriculaAnterior = scope.turma.numeroMatriculaInicial;
                    var totalMovimentacao = scope.turma.turmasMovimentacaoMensal.length;

                    if (totalMovimentacao > 0) {
                        var movimentacao = _movimentacao();
                        var movimentacaoAnterior = _movimentacaoAnterior(movimentacao);
                        if (movimentacaoAnterior) {
                            scope.numeroMatriculaAnterior = movimentacaoAnterior.matriculaAtual;
                        }
                        _verificaIsUltimaMovimentacao();
                        if (movimentacao) {   
                            scope.movimentacao = movimentacao;
                            scope.calcularMatriculaAtual(scope.movimentacao);
                        }
                    }
                }

                scope.salvarMovimentacao = function(movimentacao) {
                    if (angular.isUndefined(movimentacao)) {
                        scope.transferenciaRecebidaInvalid = true;
                        scope.transferenciaExpedidaInvalid = true;
                        scope.abandonoInvalid = true;
                        return;
                    }

                    if (angular.isUndefined(movimentacao.transferenciaRecebida)) {
                        scope.transferenciaRecebidaInvalid = true;
                        return;
                    }
                    
                    if (angular.isUndefined(movimentacao.transferenciaExpedida)) {
                        scope.transferenciaExpedidaInvalid = true;
                        return;
                    }
                    
                    if (angular.isUndefined(movimentacao.abandono)) {
                        scope.abandonoInvalid = true;
                        return;
                    }

                    scope.transferenciaRecebidaInvalid = false;
                    scope.transferenciaExpedidaInvalid = false;
                    scope.abandonoInvalid = false;

                    var movimentacaoSalvar = angular.copy(movimentacao);
                    movimentacaoSalvar.turma = scope.turma;
                    if (scope.referencia) {
                        movimentacaoSalvar.referencia = scope.referencia;
                    } else {
                        movimentacaoSalvar.referencia = moment().format("YMM");
                    }
                    movimentacaoSalvar.situacao = { key:"A" };
                    
                    scope.msnSuccess = false;
                    scope.msnError = false;
                    turmasService.salvarMovimentacaoMensal(movimentacaoSalvar).then(function(response){
                        scope.movimentacao = response;
                        scope.msnSuccess = "A movimentação mensal foi salva com sucesso.";                   
                    }, function(error){
                        scope.msnError = error.data.message;                   
                    });
                };          
                
            }
        };
    }

})();
