(function() {
    'use strict';

    angular.module('app.core').directive("iasTranslate", IasTranslate);
    IasTranslate.$inject = ['TRANSLATE_PTBR', 'TRANSLATE_EN'];

    function IasTranslate(TRANSLATE_PTBR, TRANSLATE_EN) {
        return {
            restrict: 'A',
            link: function(scope, element, attr) {
                var translate = TRANSLATE_PTBR;
                var keyToTranslate = attr['iasTranslate'];
                var iasAttrValue = attr['iasValue'];
                var txt = null;

                var _translate = function(iasValue) {
                    if (translate[keyToTranslate]) {
                        if (iasValue) {
                            txt = translate[keyToTranslate].replace('{?}', iasValue);
                        } else {
                            txt = translate[keyToTranslate];
                        }

                        element.html(txt);
                    } else {
                        element.html(keyToTranslate);
                    }
                };

                if (iasAttrValue) {
                    attr.$observe('iasValue', function(newValue) {
                        _translate(newValue);
                    });
                } else {
                    _translate();
                }
            } 
        };
    }

})();
