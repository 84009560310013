/*
jslint
    devel: true, maxerr: 10
*/

/*
global
    required: true
*/

/*
 * Security Factory for App Core Module
 * @author iFactory Solutions Ltda.
 * @version 1.0.0
 * @since 1.0.0
 */
(function() {
    'use strict';

    angular.module('app.core').factory('SecurityManagerFactory', SecurityFactory);
    SecurityFactory.$inject = ['SessionManagerFactory', '$rootScope'];

    function SecurityFactory(sessionManagerFactory, $rootScope) {
        return {
            isUserLogged: isUserLogged,
            logOut: logOut,
            storeUserCredentials: storeUserCredentials,
            getUserCredentials: getUserCredentials,
            hasPermission: hasPermission,
            updateUserCredentials: updateUserCredentials
        };

        function isUserLogged() {
            return (sessionManagerFactory.get('security.accessToken')) ? true : false;
        }

        function logOut() {
            var user = angular.copy($rootScope._user);
            $rootScope._user = null;
            sessionManagerFactory.removeAll();
            $rootScope.$broadcast('security.event.userLoggedOut', user);
        }

        function storeUserCredentials(user) {
            sessionManagerFactory.add('security.accessToken', user.credentials.accessToken);
            sessionManagerFactory.add('security.refreshToken', user.credentials.refreshToken);
            sessionManagerFactory.add('security.expiresTokenIn', user.credentials.expiresIn);
            sessionManagerFactory.add('user', user);
            $rootScope._user = user;
            $rootScope.$broadcast('security.event.userLoggedIn', user);
        }

        function updateUserCredentials(user) {
            sessionManagerFactory.add('security.accessToken', user.credentials.accessToken);
            sessionManagerFactory.add('security.refreshToken', user.credentials.refreshToken);
            sessionManagerFactory.add('security.expiresTokenIn', user.credentials.expiresIn);
            sessionManagerFactory.add('user', user);
            $rootScope._user = user;
            $rootScope.$broadcast('security.event.userUpdated', user);
        }

        function getUserCredentials() {
            if (is.not.existy($rootScope._user)) {
                $rootScope._user = sessionManagerFactory.get('user');
            }
            return $rootScope._user;
        }

        function hasPermission(permission) {
            var user = getUserCredentials();
            
            if (user.admin) {
                return true;
            }

            if (is.not.existy(permission)) {
                return false;
            }

            permission = permission.trim().toUpperCase();
            if (permission.indexOf(':') < 0) {
                // It's a feature.
                return is.existy(user.privilegies[permission]);
            } else {
                // It's an action.
                var privilegy = permission.split(':');
                if (is.not.existy(user.privilegies[privilegy[0]])) {
                    return false;
                }
                return user.privilegies[privilegy[0]].indexOf(permission) > -1;
            }
        }
    }

}());