(function() {
    'use strict';

    angular.module('app.coletaRubrica.resposta').factory('ColetaResposta', ColetaResposta);
    ColetaResposta.$inject = ['$resource', 'APP_PROPERTY', 'APP_MIME_TYPE', 'fileService'];

    function ColetaResposta($resource, APP_PROPERTY, APP_MIME_TYPE, fileService) {
        return $resource(APP_PROPERTY.URL + '/coletas/respostas/:id', { id: '@idColetaResposta' }, {
            update: {
                method: 'PATCH'
            },
            viewById: {
                method: 'GET',
                url: APP_PROPERTY.URL + '/coletas/respostas/:id'
            },
            findByColetaId: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/coletas/:id/respostas'
            },
            findStatus: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/coletas/respostas/status'
            },
            listasTipoSolicitacao: {
                method: 'GET',
                isArray:true,
                url: APP_PROPERTY.URL + '/coletas/respostas/solicitacoes/tipoColetaResposta'
            },
            conclude: {
                method: 'PATCH',
                hasBody: false,
                url: APP_PROPERTY.URL + '/coletas/respostas/:id/concluir'
            },
            export: {
                method: 'GET',
                cache: false,
                responseType: 'arraybuffer',
                url: APP_PROPERTY.URL + '/coletas/respostas/:id/arquivo',
                headers: {
                    accept: [APP_MIME_TYPE.JSON, APP_MIME_TYPE.EXCEL].join()
                },
                transformResponse: function (data, headers) {
                    return fileService.transform(data, headers);
                }
            },
            viewAnonymous: {
                method: 'GET',
                url: APP_PROPERTY.URL + '/coletas/respostas/anonima/:id'
            },
            updateAnonymous: {
                method: 'PATCH',
                url: APP_PROPERTY.URL + '/coletas/respostas/anonima/:id'
            },
            concludeAnonymous: {
                method: 'PATCH',
                hasBody: false,
                url: APP_PROPERTY.URL + '/coletas/respostas/anonima/:id/concluir'
            }
        });

    }

}());