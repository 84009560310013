(function() {
    'use strict';

    angular.module('app.validarEmail', ['app.core']).config(config);
    config.$inject = ['$stateProvider', 'APP_STATE'];

    function config($stateProvider, APP_STATE) {
        $stateProvider
            .state(APP_STATE.VALIDAR_EMAIL.BASE, {
                url: '/validar-email/:token',
                templateUrl: 'validar-email/partials/validar-email.tpl.html',
                controller: 'ValidarEmailController',
                controllerAs: 'vm'
            })
    }

}());