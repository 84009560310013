(function() {
    'use strict';

    angular.module ('app.core').directive ('iasHorizontalLoader', IasHorizontalLoader);
    IasHorizontalLoader.$inject = [];

    function IasHorizontalLoader() {
        return {
            restrict: "E",
            templateUrl: "core/directives/load/partials/horizontal-loader.html"
        };
    }

} ());