/*
jslint
    devel: true, maxerr: 10
*/

/*
global
    required: true
*/

/*
 * Core Module
 * @author iFactory Solutions Ltda.
 * @version 1.0.0
 * @since 1.0.0
 */
(function() {
    'use strict';

    angular.module('app.core', [
        'ngMessages',
        'ngAnimate',
        'ngTouch',
        'ngResource',
        'ngMask',
        'ui.router',
        'ui.bootstrap',
        'ngFlash',
        'btorfs.multiselect',
        'colorpicker.module',
        'ui.utils.masks',
        'ngTagsInput',
        'ngFileUpload',
        'ngLocale',
        'ngFileSaver'
    ]).config(config);

    config.$inject = ['$provide', ];

    function config($provide) {
        $provide.decorator('$q', ['$delegate', function($delegate) {
            var $q = $delegate;

            $q.allSettled = function(promises) {
                var wrappedPromises = angular.isArray(promises) ? promises.slice(0) : {};

                angular.forEach(promises, function(promise, index) {
                    wrappedPromises[index] = promise.then(function(value) {
                        return { state: 'fulfilled', value: value };
                    }, function(reason) {
                        return { state: 'rejected', reason: reason };
                    });
                });

                return $q.all(wrappedPromises);
            };

            return $q;
        }]);
    }

}());