(function() {
    'use strict';

    angular.module('app.core').constant('COLETA_MESSAGES', {
      success: {

        fecharColeta : 'Coleta fechada com sucesso!'
      },
      error: {
        fecharColeta: 'Falha ao fechar coleta manualmente!'
  
      },
      info: {
        title: 'Fechamento de coleta'
      },
      warning: {
        fecharColeta: 'Deseja mesmo fechar esta coleta?'
      }        
    });

}());

