(function(){
  'use strict';

  angular.module('app.professor').controller('ProfessorController', ProfessorController);
  ProfessorController.$inject = ['PROFESSOR_MESSAGES', 'APP_STATE', '$state', 'Professor', 'dialogService', 'toastService',
    'DialogService', '$scope', 'APP_EVENT', 'professorService', 'SecurityManagerFactory'];

  function ProfessorController(PROFESSOR_MESSAGES, APP_STATE, $state, Professor, dialogService, toastService,
    DialogService, $scope, APP_EVENT, professorService, SecurityManagerFactory) {

    var vm = this;
    //
    vm.SEARCH_BY = {
        CODE: {
            field: "id",
            placeholder: "código",
            mask: "9999999999",
            restrict: "[^0-9]",
            minLength: 1,
            maxLength: 10
        },
        NAME: {
            field: "nome",
            placeholder: "nome",
            mask: "*",
            restrict: "%",
            maxLength: 50,
            minLength: 3,
        },
        CPF: {
            field: "cpf",
            placeholder: "CPF",
            mask: "999.999.999-99",
            maxLength: 14,
            minLength:14
        },
        EMAIL:{
          field:"email",
          placeholder:"e-mail",
          mask:"*",
          restrict: "%",
          maxLength:100,
          minLength:1
        },
        INEP: {
            field: "codigoINEP",
            placeholder: "código INEP",
            mask: "99999999999999999999",
            restrict: "[^0-9]",
            minLength: 1,
            maxLength: 20
        }
    };

    $scope.$on(APP_EVENT.PROFESSOR.REFRESH, function() {
        vm.search();
    });

    vm.professores = [];
    vm.searchValue ="";
    vm.searchType =vm.SEARCH_BY.NAME;
    vm.nomeSearch ='';
    vm.validation = {hasError: false, message: ''};
    vm.notFound = false;

    vm.mascaraCpf= function(valor) {
        return valor.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g,"\$1.\$2.\$3\-\$4");
   }

    vm.clearSearchValue = function() {
        vm.searchValue = "";
    };

    vm.goNewPage = function() {
        $state.go(APP_STATE.PROFESSOR.NEW);
    };

    vm.goImportTemplatePage = function() {
        $state.go(APP_STATE.PROFESSOR.IMPORT_TEMPLATE);
    };

    vm.goEditPage = function(id) {
        $state.go(APP_STATE.PROFESSOR.EDIT, { id: id });
    };

    vm.stayPage = function(){
      $state.reload();
    }

    vm.professorSearchValue = function() {
        vm.searchValue = "";
    };

    vm.validTrue = function(){
    }

    vm.resetSenha = function(professor){
        professorService.resetSenha(professor)
          .then(function(response){
            console.log(response.resultado);
            if (response.resultado == true) {
                  toastService.success(PROFESSOR_MESSAGES.success.reset);
                } else {
                  toastService.error(PROFESSOR_MESSAGES.error.reset);
                }
          }, function(response) {
                toastService.error({
                  status: response.status,
                  message: response.data.message
                });
            });
      };

    vm.delete = function(professor) {
        dialogService.delete().then(function() {
            Professor.delete({id: professor.id}).$promise
                .then(function(response) {
                        if (response.responseStatusCode == 200) {
                            toastService.success("Registro removido com sucesso.");
                        } else if (response.responseStatusCode == 202) {
                            toastService.success("Registro inativado com sucesso.");
                        }
                    vm.search();
                }, function(response) {
                    toastService.error({
                        status: response.status,
                        message: response.data.message
                    });
                });
        }, function() {});
    };

    vm.onChangeFilter = function() {
        vm.professoresSearch.$setPristine();
    }

    vm.search = function() {
        var filter = {};
        vm.professores = [];
        vm.searchWasPerformed = false;

        filter[vm.searchType.field] = vm.searchValue;

        if (vm.searchValue) {
            Professor.filter(filter).$promise
                .then(function(data) {
                    vm.professores = angular.copy(data);
                    vm.professoresTotal = vm.professores.length;

                    if(vm.professores != 0 ){
                      vm.notFound = false;
                    }else{
                      vm.notFound = true;
                    }
                }, function(error) {
                    vm.professores = [];
                    vm.notFound = true;
                })
                .finally(function() {
                    vm.searchWasPerformed = true;
                    vm.professoresSearch.$setPristine();
                });
        }
    };

    vm.modalAtributos = function(professor) {
            DialogService.modal(
                'professor/partials/professor-search-modal-atributos.html',
                function($uibModalInstance){
                    var _vm = this;

                    professorService.get(professor.id).then(function (response) {
						_vm.atributos = response.atributosEntidadesDTO;
					}, function (response) {
                        _vm.atributos = [];
					});

                    _vm.cancelar = function() {
                        $uibModalInstance.dismiss('cancel');
                    };
                }
            );
        };

    (function() {
        vm.hasPermissionView = SecurityManagerFactory.hasPermission('PRF:VI');
        vm.searchWasPerformed = false;

        $scope.$on(APP_EVENT.PROFESSOR.REFRESH, function() {
            vm.search();
        });

    }());

  }

}());
