(function() {
    'use strict';

    angular.module('app.disciplinas').controller('DisciplinaFormController', DisciplinaFormController);
    DisciplinaFormController.$inject = ['APP_STATE', 'TABLE_FIELDS', 'APP_EVENT', 'COMMON_STATUS_LIST',
        '$state', '$scope', 'disciplinasService', 'dialogService', 'toastService',
        'areaConhecimentoService','$rootScope'
    ];

    function DisciplinaFormController(APP_STATE, TABLE_FIELDS, APP_EVENT, COMMON_STATUS_LIST,
        $state, $scope, disciplinasService, dialogService, toastService,
        areaConhecimentoService,$rootScope) {

        var vm = this;

        var _init = function() {
            vm.selectedItems = [];
            vm.salvarContinuar = false;
            vm.editMode = false;
            vm.editPage = false;
            vm.defaultStatus = COMMON_STATUS_LIST[0];

            vm.areas = areaConhecimentoService.ativos();
            if ($state.is(APP_STATE.DISCIPLINA.EDIT)) {
                vm.editPage = true;
                
                if ($state.params.id) {
                    disciplinasService.get($state.params.id).then(function(disciplina) {
                        vm.disciplina = disciplina;
                        if (is.truthy($state.params.editMode)) {
                                vm.enableEditMode();
                        }
                    }, function(response) {
                        toastService.error({
                            status: response.status,
                            message: response.data.message
                        });
                    });
                } else {
                    vm.goBack();
                }
            } else {
                vm.enableEditMode();
                vm.disciplina = {};
                vm.disciplina.situacao = vm.defaultStatus;
            }
        }; 

        var _saveModalItem = function(modalForm){
            vm.registerForm.$setDirty();
            this.ok(modalForm);
        }

        vm.goBack = function() {
            $state.go(APP_STATE.DISCIPLINA.SEARCH);
        };

        vm.save = function() {
            if (vm.registerForm.$valid) {
                disciplinasService.save(vm.disciplina).then(function(disciplina) {
                    $scope.$emit(APP_EVENT.DISCIPLINA.SAVED);
                    if (!vm.salvarContinuar) {
                        vm.goBack();
                    } else {
                        _init();
                        //Irá enviar notificação para limpar os itens selecionados
                        $rootScope.$broadcast(APP_EVENT.GENERIC.CLEAR_ITEMS_SELECTED)
                        vm.registerForm.$setPristine();
                        $state.go(APP_STATE.DISCIPLINA.EDIT, { id: disciplina.id, editMode: true });
                    }
                }, function(response) {
                    toastService.error({
                        status: response.status,
                        message: response.data.message
                    });
                });
            }
        };



        vm.enableEditMode = function() {
            vm.editMode = true;
        };

        vm.cancel = function() {
            if (vm.refreshSearch) {
                $scope.$emit(APP_EVENT.DISCIPLINA.REFRESH);
            }

            vm.goBack();
        };

        var _saveAndContinue = function(createNewItems) {

            if (vm.registerForm.$valid) {
                disciplinasService.save(vm.disciplina).then(function(response) {
                    vm.refreshSearch = true;
                    vm.registerForm.$setPristine();

                    
                    vm.disciplina = response;
                    toastService.success("Operação realizada com sucesso.");
                }, function(response) {
                    vm.refreshSearch = false;

                    toastService.error({
                        message: response.data.message
                    });
                });
            }
        };

        (function() {
            _init();     
        }());
    }

}());