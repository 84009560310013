/*
jslint
    devel: true, maxerr: 10
*/

/*
global
    required: true
*/

/*
 * Funcionalidade do Sistema Resource
 * @module Funcionalidade do Sistema
 * @author iFactory Solutions Ltda.
 * @version 1.0.0
 * @since 1.0.0
 */
(function() {
    'use strict';

    angular.module('app.funcionalidadeSistema').factory('FuncionalidadeSistemaResource', FuncionalidadeSistemaResource);
    FuncionalidadeSistemaResource.$inject = ['$resource', 'APP_PROPERTY'];

    function FuncionalidadeSistemaResource($resource, APP_PROPERTY) {
        return $resource(APP_PROPERTY.URL + '/funcionalidades-sistema/:id', { id: '@id' }, {
            update: {
                method: 'PUT'
            },
            get: {
                method: 'GET'
            },
            search: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/funcionalidades-sistema'
            },
            findByStatus: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/funcionalidades-sistema/:status'
            },
            findActionsByStatus: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/funcionalidades-sistema/:id/acoes/:status'
            }
        });
    }

}());