(function(){
    'use strict';

    angular.module('app.atributo').controller('AtributoFormController', AtributoFormController);
    AtributoFormController.$inject = ['APP_STATE', 'APP_EVENT', 'COMMON_STATUS_LIST', '$scope', '$state',
        'atributoService', 'opcaoService', 'medidaService', 'toastService', 'programasService'];

    function AtributoFormController(APP_STATE, APP_EVENT, COMMON_STATUS_LIST, $scope, $state,
        atributoService, opcaoService, medidaService, toastService, programasService) {

        var vm = this;
        vm.programas = programasService.ativos();
        vm.atributosProgramas = [];
        vm.atributosProgramasIncluidos = [];
		vm.atributosProgramasExcluidos = [];

        var _populateFieldTipo = function() {
            atributoService.findTipos()
                .then(function(tipos) {
                    vm.tipos = _.map(tipos, function(tipo) {
                        return {
                            key: tipo.id,
                            value: tipo.name
                        };
                    });
                });
        };

        var _populateFieldEntidade = function() {
            atributoService.findEntidades()
                .then(function(entidades) {
                    vm.entidades = _.map(entidades, function(entidade) {
                        return {
                            key: entidade.id,
                            value: entidade.name
                        };
                    });
                });
        };

        var _populateFieldOpcao = function() {
            vm.showOpcoes = true;

            if (!vm.opcoes.length) {
                opcaoService.findActives().then(function(opcoes) {
                    vm.opcoes = opcoes;
                    if (is.not.existy(_.findWhere(opcoes, {id: vm.atributo.idOpcao.id}))){
                        vm.opcoes.push(vm.atributo.idOpcao);
                        vm.opcoes = _.sortBy(vm.opcoes, 'nome');
                    }
                });
            }
        };

        var _populateFieldMedida = function() {
            vm.showMedidas = true;

            if (!vm.medidas.length) {
                medidaService.findActives().then(function(medidas) {
                    vm.medidas = medidas;   
                    if (is.not.existy(_.findWhere(medidas, {id: vm.atributo.idMedida.id}))){
                        vm.medidas.push(vm.atributo.idMedida);
                        vm.medidas = _.sortBy(vm.medidas, 'nome');
                    }
                });
            }
        };

        vm.goBack = function() {
            $state.go(APP_STATE.ATRIBUTO.SEARCH);
        };

        vm.save = function() {
            vm.atributo.atributosProgramas = vm.atributosProgramas;
            vm.atributo.atributosProgramasIncluidos = vm.atributosProgramasIncluidos;
            vm.atributo.atributosProgramasExcluidos = vm.atributosProgramasExcluidos;

            if (vm.registerForm.$valid) {

                atributoService.save(vm.atributo)
                    .then(function() {
                        $scope.$emit(APP_EVENT.ATRIBUTO.SAVED);                        
                   
                        vm.goBack();
                    }, function(response) {
                        toastService.error({
                            status: response.status,
                            message: response.data.description
                        });
                    });
            }
        };

        vm.addPrograma = function(programa) {
			vm.programaInvalido = false;
			vm.programaVazio = false;

			if (undefined == programa) {
				vm.programaVazio = true;
				return false;
			}

			angular.forEach(vm.atributosProgramas, function(value) {
				if (value.programa.id == programa.id) {
					vm.programaInvalido = true;
				}	
			});

			if (vm.programaInvalido) {
				return false;
			}

            var novoPrograma = {
				programa: programa,
				situacao: {key: "A"}
			};

			vm.atributosProgramas.push(angular.copy(novoPrograma));
			vm.atributosProgramasIncluidos.push(angular.copy(novoPrograma));
			vm.programa = null;	
		};
		
		vm.excluirPrograma = function(i) {
			var programa = vm.atributosProgramas[i];
            if (programa.id) {
			    vm.atributosProgramasExcluidos.push(programa);	
            }
			_excluirPrograma(programa);
		};

        var _excluirPrograma = function(programa) {
            angular.forEach(vm.atributosProgramas, function(value, index) {
                if (value.programa.id == programa.programa.id) {
                    vm.atributosProgramas.splice(index, 1);
                }	
            });
            angular.forEach(vm.atributosProgramasIncluidos, function(value, index) {
                if (value.programa.id == programa.programa.id) {
                    vm.atributosProgramasIncluidos.splice(index, 1);
                }	
            });
        };        

        vm.enableEditMode = function() {
            vm.editMode = true;
        };

        vm.cancel = function() {
            vm.goBack();
        };

        vm.showFieldByType = function() {
            var tipoAtributo = vm.atributo.tipoAtributo;

            if (tipoAtributo) {
                vm.showOpcoes = false;
                vm.showMedidas = false;
                
                delete vm.atributo.idOpcao;
                delete vm.atributo.idMedida;

                if (tipoAtributo.key == 'O') {
                    _populateFieldOpcao();
                } else if (tipoAtributo.key == 'M') {
                    _populateFieldMedida();
                }
            }
        };

        (function() {
            vm.editMode = false;
            vm.editPage = false;
            vm.showOpcoes = false;
            vm.showMedidas = false;
            vm.opcoes = [];
            vm.medidas = [];
            vm.tipos = [];
            vm.entidades = [];
            vm.atributosProgramas = [];
            vm.atributosProgramasIncluidos = [];
		    vm.atributosProgramasExcluidos = [];
            
            _populateFieldTipo();
            _populateFieldEntidade();

            if ($state.is(APP_STATE.ATRIBUTO.EDIT)) {
                vm.editPage = true;

                if ($state.params.id) {
                    atributoService.get($state.params.id)
                        .then(function(atributo) {
                            vm.atributo = atributo;
                            if (atributo.atributosProgramas) {
                                vm.atributosProgramas = atributo.atributosProgramas;
                            }
                             
                            if (vm.atributo.idOpcao) {
                                _populateFieldOpcao();
                            } else if (vm.atributo.idMedida) {
                                _populateFieldMedida();
                            }
                        }, function(response) {
                            toastService.error({
                                status: response.status,
                                message: response.data.message
                            });
                        });
                } else {
                    vm.goBack();
                }
            } else {
                vm.enableEditMode();
                vm.atributo = {};
                vm.atributo.situacao = COMMON_STATUS_LIST[0];
                vm.atributo.tipoEntidadeAtributo = {key: "T"};
            }
        }());
    }

}());