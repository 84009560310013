(function() {
    'use strict';

    angular.module('app.relatorios').factory('Relatorios', Relatorios);
    Relatorios.$inject = ['$resource', 'APP_PROPERTY', 'APP_MIME_TYPE', 'fileService'];

    function Relatorios($resource, APP_PROPERTY, APP_MIME_TYPE, fileService) {
        return $resource(APP_PROPERTY.URL + '/relatorios/:id', { id: '@id' }, {
            filter: {
                method: 'GET',
                url: APP_PROPERTY.URL + '/relatorios/:reportPath'
            },
            findDetalhamentos: {
                method: 'GET',
                url: APP_PROPERTY.URL + '/relatorios/detalhamentos',
                isArray: true
            },
            findPerfilTurmaAlfabetizacaoAceleracao: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/relatorios-perfil-turma/alfabetizacao-aceleracao'
            },
            findPerfilTurmaGestaoDaAlfabetizacao: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/relatorios-perfil-turma/gestao-da-alfabetizacao'
            },
            findPerfilTurmaSistematicaAcompanhamentoDialogos: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/relatorios-perfil-turma/sistematica-acompanhamento-dialogos'
            },
            findPerfilTurmaFormulaVitoria: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/relatorios-perfil-turma/formula-vitoria'
            },
            findPerfilTurmaEnsinoMedioGestaoNota10: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/relatorios-perfil-turma/ensino-medio-gestao-nota-10'
            },
            findPerfilTurmaEnsinoRegular: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/relatorio-perfil-turma-ensino-regular/relatorio'
            },
            findMovimentacaoMensalAlfabetizacaoAceleracao: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/relatorios-acompanhamento-mensal/alfabetizacao-aceleracao'
            },
            findMovimentacaoMensalLeituraEscritaSeLiga: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/relatorios-acompanhamento-mensal/leitura-escrita-oralidade-se-liga'
            },
            findMovimentacaoBase: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/relatorio-acompanhamento-base/relatorio'
            },
            findMovimentacaoMensal: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/relatorios-acompanhamento-mensal/relatorio'
            },
            findMovimentacaoLeituraEstrategiaCompreensaoMatematicaNivel1: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/relatorios-acompanhamento-mensal/leitura-estrategia-compreensao-matematica-nivel-1'
            },
            findMovimentacaoLeituraEstrategiaCompreensaoMatematicaNivel2: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/relatorios-acompanhamento-mensal/leitura-estrategia-compreensao-matematica-nivel-2'
            },
            findMovimentacaoMensalLeituraEscritaAceleraBrasil: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/relatorios-acompanhamento-mensal/leitura-escrita-producao-texto'
            },
            findPerfilAtendimentoUnidadesEscolaresCursos: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/relatorios-acompanhamento-anual/perfil-atendimento-unidades-escolares-cursos'
            },
            findResultadoFinalFormulaVitoria: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/relatorios-resultado-final/formula-vitoria'
            },
            findResultadoFinalGestaoAlfacao: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/relatorios-resultado-final/gestao-da-alfabetizacao'
            },
            findResultadoFinalEnsinoRegular: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/relatorios-resultado-final/ensino-regular'
            },
            findResultadoFinalEnsinoRegularMatriculaMovimentacao: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/relatorios-resultado-final/ensino-regular-matricula-movimentacao'
            },
            findResultadoFinalAlfabetiza2Ano1SerieRegular: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/relatorios-resultado-final/alfabetizacao-2serie-1ano-regular'
            },
            findResultadoFinalLetramentoProgramacao: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/relatorios-resultado-final/letramento-em-programacao'
            },
            findResultadoAlfabetizacaoAceleracaoVitoria: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/relatorios-resultado-final/alfabetizacao-aceleracao'
            },
            findIndicadoresSucessoResultados: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/relatorios-resultado-final/indicadores-sucesso-resultados'
            },
            findResultadoBimestral: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/relatorios-resultado-bimestral/relatorio'
            },
            findFormulaVitoriaLinguaPortuguesa: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/relatorios-acompanhamento-mensal/fluxo-de-oficinas'
			},
            totalescolasporprograma: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/relatorios-acompanhamento-anual/total-escolas-por-programa'
            },
            findPerfilAtendimentoUnidadesEscolaresTurmas: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/relatorios-acompanhamento-anual/perfil-atendimento-unidades-escolares-turmas'
            },
            findFluxoAulas: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/relatorios-acompanhamento-mensal/fluxo-aulas'
            },
            findMunicipiosPorPrograma: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/relatorios-municipios-por-programa/municipios-por-programa'
            },
            findUnidadesEscolares: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/relatorios-lista-unidades-escolares/lista-unidades-escolares'
            },
            findUnidadesEscolaresPerfilAtendimento: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/relatorios-outros/lista-unidades-escolares-perfil-atendimento'
            },
            findIdadeConclusao4Serie5Ano: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/relatorios-outros/idade-conclusao-4serie-5ano'
            },
            findNumeroAlunosPorAnoSituacao: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/relatorios-outros/numero-alunos-por-ano-situacao'
            },
            findIdadeConclusao8Serie9Ano: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/relatorios-outros/idade-conclusao-8serie-9ano'
            },
            findGeneroSituacaoAnoAnterior: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/relatorios-outros/genero-situacao-ano-anterior'
            },
            findPerfilAtendimentoMunicipio: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/relatorios-outros/perfil-atendimento-municipio'
            },
            findMediaDiasLetivos: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/relatorios-outros-media-dias-letivos/media-dias-letivos'
            },
            findMatriculaEnsinoFundamentalRede: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/relatorios-outros/matricula-ensino-fundamental-rede'
            },
            findEscolasEducacaoInfantilJovensAdultos: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/relatorios-outros/escolas-educacao-infantil-jovens-adultos'
            },
            findStatusInsercaoColetas: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/relatorios-outros/status-insercao-coletas'
            },
            findAcompanhamentoMunicipio: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/relatorios-acompanhamento-mensal/municipio'
            },
            findAcompanhamentoMunicipioFormulario: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/relatorios-acompanhamento-mensal/municipio/formulario'
            },
            findAcompanhamentoMunicipioDocx: {
                method: 'GET',
                cache: false,
                responseType: 'arraybuffer',
                url: APP_PROPERTY.URL + '/relatorios-acompanhamento-mensal/municipio/docx',
                headers: {
                    accept: [APP_MIME_TYPE.JSON, 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].join()
                },
                transformResponse: function (data, headers) {
                    var response = fileService.transform(data, headers);
                    var contentDisposition = headers('Content-Disposition');
                    if (contentDisposition) {
                        var result = contentDisposition.split(";")[2].trim().split("=")[1];
                        var name = result.replace(/"/g, '');
                        response.name = name;
                    }

                    return response;
                }
            },
            findFormulaVitoriaLinguaPortuguesaAcompanhamento: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/relatorios-acompanhamento-mensal/relatorio-formula-da-vitoria'
            },
            /* findAcompanhamentoSemestral */
            findResultadoBimestralLetramento: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/relatorios-resultado-bimestral/letramento-em-programacao'
            },
            findDialogosAcompanhamento: {
                method: 'GET',
                url: APP_PROPERTY.URL + '/relatorios-dialogos/acompanhamento'
            },
            findDialogosMonitoramento: {
                method: 'GET',
                url: APP_PROPERTY.URL + '/relatorios-dialogos/monitoramento'
            },
            findDialogosAcompanhamentoCentral: {
                method: 'GET',
                url: APP_PROPERTY.URL + '/relatorios-dialogos/acompanhamento-central'
            },
            findDialogosMonitoramentoCentral: {
                method: 'GET',
                url: APP_PROPERTY.URL + '/relatorios-dialogos/monitoramento-central'
            }
        });
    }
}());
