(function() {
    'use strict';

    angular.module('app.core').constant('PUBLIC_PAGES', [
        '/coletas/respostas/anonima/:id',
        '/usuarios/forgottenpassword',
        '/redefinir-senha/:id',
        '/validar-email/:token'
    ]);

}());
