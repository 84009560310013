/*
jslint
    devel: true, maxerr: 10
*/

/*
global
    required: true
*/

/*
 * Security Interceptor Factory for App Core Module
 * @author iFactory Solutions Ltda.
 * @version 1.0.0
 * @since 1.0.0
 */
(function() {
    'use strict';

    angular.module('app.core').factory('SecurityInterceptorFactory', SecurityInterceptorFactory);
    SecurityInterceptorFactory.$inject = ['SecurityManagerFactory', 'APP_CONFIG', '$q', '$location', '$rootScope'];

    function SecurityInterceptorFactory(securityManagerFactory, APP_CONFIG, $q, $location, $rootScope) {
        var canceller = null;

        return {
            request: requestHandler,
            response: responseHandler,
            responseError: responseErrorHandler
        };

        function requestHandler(config) {
            if (APP_CONFIG.SECURITY.STATUS == APP_CONFIG.enums.security.status.on) {
                canceller = $q.defer();
                if (is.not.existy(config.headers.Authorization) && is.existy(securityManagerFactory.getUserCredentials())) {
                    var currentUser = securityManagerFactory.getUserCredentials();
                    if (is.existy(currentUser.credentials) && is.existy(currentUser.credentials.accessToken)) {
                        config.headers.Authorization = APP_CONFIG.SECURITY.TOKEN.TYPE + ' ' + currentUser.credentials.accessToken;
                    }
                }
                config.timeout = canceller.promise;
            }

            return config;
        }

        function responseHandler(response) {
            return response || $q.when(response);
        }

        function responseErrorHandler(rejection) {
            if (APP_CONFIG.SECURITY.STATUS == APP_CONFIG.enums.security.status.on) {
                if (is.existy(rejection.status)) {
                    //console.log('SecurityInterceptorFactory::requestErrorHandle', rejection);
                    if (rejection.status == 401) {
                        /*Qnd a url requisitada for de logout e a sessão estiver expirada, não deverá ser chamado
                        o securityManagerFactory.logOut() para não cair em um ciclo infinito de logout.*/
                        if(!(rejection.config.url.indexOf(APP_CONFIG.SECURITY.AUTHENTICATION.URL.REMOVE_TOKEN) > -1
                            && rejection.config.method.toUpperCase() == "DELETE")){
                            securityManagerFactory.logOut(); // TODO O ideal é antes exibir tela de que as credenciais estão expiradas e depois enviar para a tela de login.
                        }
                        canceller.resolve('Unauthorized');
                        $rootScope.$broadcast('security.event.requestError', rejection);
                        $location.url('/'); // TODO Enviar para tela de erro indicando que a sessão expirou com a única opção de ir para a tela de login.
                    } else if (rejection.status === 403) {
                        /*Qnd a url requisitada for de logout e a sessão estiver expirada, não deverá ser chamado
                        o securityManagerFactory.logOut() para não cair em um ciclo infinito de logout.*/
                        if(!(rejection.config.url.indexOf(APP_CONFIG.SECURITY.AUTHENTICATION.URL.REMOVE_TOKEN) > -1
                            && rejection.config.method.toUpperCase() == "DELETE")){
                            securityManagerFactory.logOut(); // TODO O ideal é antes exibir tela de que as credenciais estão expiradas e depois enviar para a tela de login.
                        }
                        canceller.resolve('Forbidden');
                        $rootScope.$broadcast('security.event.requestError', rejection);
                        $location.url('/'); // TODO Enviar para a tela de erro de usuário não autorizado.
                    } else {
                        canceller.reject(rejection);
                    }
                }
            }
            return $q.reject(rejection);
        }
    }

}());