(function() {
    'use strict';

    angular.module('app.modalidadeEnsino').factory('ModalidadeEnsino', ModalidadeEnsino);
    ModalidadeEnsino.$inject = ['$resource', 'APP_PROPERTY'];

    function ModalidadeEnsino($resource, APP_PROPERTY) {
        return $resource(APP_PROPERTY.URL + '/modalidade-ensino/:id', { id: '@id' }, {
            update: {
                method: 'PUT'
            },
            get: {
                method: 'GET'
            },
            filter: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/modalidade-ensino'
            },
            ativo: {
                method: 'GET',
                url: APP_PROPERTY.URL + '/modalidade-ensino/ATIVO',
                isArray: true
            },
            findByStatus: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/modalidade-ensino/:status'
            }
        });

    }

}());