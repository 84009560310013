(function() {
    'use strict';

    angular.module('app.localizacao').factory('Localizacao', Localizacao);
    Localizacao.$inject = ['$resource', 'APP_PROPERTY'];

    function Localizacao($resource, APP_PROPERTY) {
        return $resource(APP_PROPERTY.URL + '/localizacao/:id', { id: '@id' }, {
            update: {
                method: 'PUT' 
            },
            get: {
                method: 'GET'
            },
            filter: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/localizacao'
            },
            ativo: {
                method: 'GET',
                url: APP_PROPERTY.URL + '/localizacao/ATIVO',
                isArray: true
            }
        });

    }

}());