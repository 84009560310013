(function(){
    'use strict';

    angular.module('app.calendario').service('calendarioService', CalendarioService);
    CalendarioService.$inject = ['Calendario', 'STATUS_ENUM'];

    function CalendarioService(Calendario, STATUS_ENUM) {

        this.filter = function(filter) {
            return Calendario.filter(filter).$promise;            
        };

        this.get = function(id) {
            return Calendario.get({ id: id }).$promise;
        };

        this.save = function(calendario) {
            var entity = new Calendario(calendario);
            
            if (entity.id) {
                return entity.$update();
            } else {
                return entity.$save();
            }
        };

        this.delete = function(calendario) {
            return Calendario.delete({id: calendario.id}).$promise;
        };

        this.ativos = function() {
            return Calendario.ativo();
        };

        this.porEscolaIdSemPadrao = function(escolaId) {
            return Calendario.porEscolaIdSemPadrao({ escolaId: escolaId }).$promise;
        };
   
    }

}());
