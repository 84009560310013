/*
jslint
    devel: true, maxerr: 10
*/

/*
global
    required: true
*/

/*
 * Configurations values for App Core Module
 * @author iFactory Solutions Ltda.
 * @version 1.0.0
 * @since 1.0.0
 */
(function() {
  "use strict";

  angular.module("app.core").constant("APP_CONFIG", {
    enums: {
      security: {
        status: {
          on: "on",
          off: "off"
        }
      }
    },
    SECURITY: {
      ENCRYPTION: {
        SALT: "/X?/8]w8a:<0^4Yb{;9259G4pa9hn4SIKxPzu(n3tP3}_hk6|CLiy3HPtqcV3t8"
      },
      TOKEN: {
        ACCESS_TOKEN: "security.accessToken",
        REFRESH_TOKEN: "security.refreshToken",
        EXPIRES_IN: "security.expiresTokenIn",
        USER: "user",
        TYPE: "Bearer"
      },
      AUTHENTICATION: {
        TYPE: "oauth",
        SERVER: "SECURITY_AUTH_HOST:8082", // Variável substituída durante build do gulp
        URL: {
          ACCESS_TOKEN: "/oauth/token",
          USER_INFO: "/users/info",
          REMOVE_TOKEN: "/users/tokens/access-token"
        },
        PARAM: {
          GRANT_TYPE: "password"
        },
        HEADER: {
          AUTHORIZATION:
            "Basic aWFzLmluZGljYWRvcmVzLmNvcmUtc2VydmljZS1zZXJ2ZXI6NDU0RTRDODdGN0VFNzlDMjZBNTdFNkQ2Q0Q3MzM="
        }
      },
      STATUS: "on" /* Can be on or off. */
    },

    STORAGE: {
      NAME: "ias.indicators"
    }
  });
})();
