(function(){
    'use strict';

    angular.module('app.opcao').service('opcaoService', OpcaoService);
    OpcaoService.$inject = ['Opcao', 'STATUS_ENUM'];

    function OpcaoService(Opcao, STATUS_ENUM) {

        this.filter = function(filter) {
            return Opcao.filter(filter).$promise;            
        };

        this.get = function(id) {
            return Opcao.get({ id: id }).$promise;
        };

        this.save = function(opcao, items, newItems) {
            var allItems = items.concat(newItems);

            var orderedItems = _.map(allItems, function(value, index) {
                value.nrOrdem = index + 1;

                return value;
            });

            var entity = new Opcao(opcao);
            entity.opcaoItensDTO = orderedItems;

            if (entity.id) {
                return entity.$update();
            } else {
                return entity.$save();
            }
        };

        this.delete = function(opcao) {
            return Opcao.delete({id: opcao.id}).$promise;
        };

        this.findActives = function() {
            return Opcao.findByStatus({status: STATUS_ENUM.ATIVO}).$promise;
        };

        this.isUsed = function(id) {
            return Opcao.isUsed({id: id}).$promise;
        };

    }

}());
