/*
jslint
    devel: true, maxerr: 10
*/

/*
global
    required: true
*/

/*
 * Security Directives for App Core Module
 * @author iFactory Solutions Ltda.
 * @version 1.0.0
 * @since 1.0.0
 */
(function() {
    'use strict';

    angular
        .module('app.core')
        .directive('iasSecuritySection', SecuritySectionDirective);

    SecuritySectionDirective.$injector = ['$rootScope'];

    function SecuritySectionDirective($rootScope) {
        return {
            restrict: 'E',
            scope: {
                errorCode: '=?'
            },
            //templateUrl: "core/directives/security/partials/ias-security-section.tpl.html",
            link: function(scope, element, attrs) {
                scope.hasError = hasError;

                function toggleElement(hasError) {
                    // TODO O ideal é remover ou incluir o elemento no DOM (mas como guardar para depois incluir?).
                    if (hasError) {
                        element[0].style.display = "block";
                    } else {
                        element[0].style.display = "none";
                    }
                }

                function hasError() {
                    return scope.errorCode === 401 || scope.errorCode === 403;
                }

                scope.$watch(scope.hasError, toggleElement);

                $rootScope.$on('security.event.requestError', function(event, data) {
                    scope.errorCode = data.status;
                    scope.error = data;
                });
            }
        };
    }
})();