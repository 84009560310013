/*
jslint
    devel: true, maxerr: 10
*/

/*
global
    required: true
*/

/*
 * Relatórios Module
 * @module constants for class profile report
 * @author iFactory Solutions Ltda.
 * @version 1.0.0
 * @since 1.0.0
 */
(function() {
    'use strict';

    /* Municípios por programa */
    angular.module('app.relatorios').constant('OUTROS_MUNICIPIOS_POR_PROGRAMA_FIELDS', [ 
        { name: 'totalMunicipios', type: 'integer', calculateTotal: true, calculateType: 'sum' },
    ]);
}());