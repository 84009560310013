(function() {
    'use strict';

    angular.module('app.uf').controller('UfController', UfController);
    UfController.$inject = ['APP_STATE', 'APP_EVENT', 'SEARCH_BY_FIELD', '$state', '$scope', '$uibModal',
        'dialogService', 'ufService', 'toastService', 'SecurityManagerFactory'
    ];

    function UfController(APP_STATE, APP_EVENT, SEARCH_BY_FIELD, $state, $scope, $uibModal,
        dialogService, ufService, toastService, SecurityManagerFactory) {

        var vm = this;

        vm.goNewPage = function() {
            $state.go(APP_STATE.UF.NEW);
        };

        vm.goEditPage = function(id) {
            $state.go(APP_STATE.UF.EDIT, { id: id });
        };

        vm.clearSearchValue = function() {
            vm.searchValue = "";
            vm.searchValueModel = "";
        };

        vm.search = function() {
            vm.ufs = [];
            vm.ufsTotal = 0;
            vm.searchWasPerformed = false;
            vm.searchValue = vm.searchValueModel || vm.searchValue;

            if (!vm.searchValueModel) {
                vm.searchValueModel = vm.searchValue;
            }

            if (vm.searchValue) {
                var filter = {};
                filter[vm.searchType.field] = vm.searchValue;

                ufService.filter(filter)
                    .then(function(data) {
                        vm.ufs = angular.copy(data);
                    }, function(response) {
                        toastService.error({
                            status: response.status,
                            message: response.data.message
                        });
                    })
                    .finally(function() {
                        vm.searchWasPerformed = true;
                        vm.ufsTotal = vm.ufs.length;
                        vm.formSearch.$setPristine();
                    });
            }
        };

        vm.delete = function(uf) {
            dialogService.delete().then(function() {
                ufService.delete({ id: uf.id })
                    .then(function(response) {
                        if (response.responseStatusCode == 200) {
                            toastService.success("Registro removido com sucesso.");
                        } else if (response.responseStatusCode == 202) {
                            toastService.success("Registro inativado com sucesso.");
                        }
                        vm.search();
                    }, function(response) {
                        toastService.error({
                            status: response.status,
                            message: response.data.message
                        });
                    });
            }, function() {});
        };

        (function() {
            vm.hasPermissionView = SecurityManagerFactory.hasPermission('ALU:UNF');
            vm.ufs = [];
            vm.searchValue = "";
            vm.searchValueModel = "";
            vm.SEARCH_BY = SEARCH_BY_FIELD;
            vm.searchType = vm.SEARCH_BY.NAME;
            vm.searchTypeSigla = vm.SEARCH_BY.SIGLA;
            vm.searchWasPerformed = false;

            $scope.$on(APP_EVENT.UF.SAVED, function() {
                toastService.success("Operação realizada com sucesso.");
                vm.search();
            });

            $scope.$on(APP_EVENT.UF.REFRESH, function() {
                vm.search();
            });

        }());

    }

}());