(function() {
    'use strict';

    angular.module('app.escala').controller('EscalaController', EscalaController);
    EscalaController.$inject = ['APP_STATE', 'APP_EVENT', 'SEARCH_BY_FIELD', '$state', '$scope', '$uibModal',
        'escalaService', 'dialogService', 'toastService', 'SecurityManagerFactory'
    ];

    function EscalaController(APP_STATE, APP_EVENT, SEARCH_BY_FIELD, $state, $scope,
        $uibModal, escalaService, dialogService, toastService, SecurityManagerFactory) {

        var vm = this;

        vm.goNewPage = function() {
            $state.go(APP_STATE.ESCALA.NEW);
        };

        vm.goEditPage = function(id) {
            $state.go(APP_STATE.ESCALA.EDIT, { id: id });
        };

        vm.clearSearchValue = function() {
            vm.searchValue = "";
            vm.searchValueModel = "";
        };

        vm.search = function() {
            vm.escalas = [];
            vm.escalasTotal = 0;
            vm.searchWasPerformed = false;
            vm.searchValue = vm.searchValueModel || vm.searchValue;

            if (!vm.searchValueModel) {
                vm.searchValueModel = vm.searchValue;
            }

            if (vm.searchValue) {
                var filter = {};
                filter[vm.searchType.field] = vm.searchValue;

                escalaService.filter(filter)
                    .then(function(data) {
                        vm.escalas = angular.copy(data);
                        vm.escalasTotal = vm.escalas.length;
                    }, function(response) {
                        toastService.error({
                            status: response.status,
                            message: response.data.message
                        });
                    })
                    .finally(function() {
                        vm.searchWasPerformed = true;
                        vm.escalasTotal = vm.escalas.length;
                        vm.formSearch.$setPristine();
                    });
            }
        };

        var _modalListItem = function(escala) {
            var formModal = $uibModal.open({
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl: 'escala/partials/escala-search-item-modal.html',
                controllerAs: 'vm',
                controller: function($uibModalInstance) {
                    var _vm = this;
                    _vm.items = [];
                    _vm.modalTitle = escala.nome;

                    if (escala.escalaItensDTO) {
                        _vm.items = angular.copy(escala.escalaItensDTO);
                    }

                    _vm.cancel = function() {
                        $uibModalInstance.dismiss('cancel');
                    };
                }
            });

            return formModal.result;
        };

        vm.showItems = function(escala) {
            _modalListItem(escala).then(new Function(), new Function());
        };

        vm.delete = function(escala) {
            dialogService.delete().then(function() {
                escalaService.delete({ id: escala.id })
                    .then(function(response) {
                        if (response.responseStatusCode == 200) {
                            toastService.success("Registro removido com sucesso.");
                        } else if (response.responseStatusCode == 202) {
                            toastService.success("Registro inativado com sucesso.");
                        }
                        vm.search();
                    }, function(response) {
                        toastService.error({
                            status: response.status,
                            message: response.data.message
                        });
                    });
            }, function() {});
        };

        (function() {
            vm.hasPermissionView = SecurityManagerFactory.hasPermission('ECL:VI');
            vm.escalas = [];
            vm.searchValue = "";
            vm.searchValueModel = "";
            vm.SEARCH_BY = SEARCH_BY_FIELD;
            vm.searchType = vm.SEARCH_BY.NAME;
            vm.searchWasPerformed = false;

            $scope.$on(APP_EVENT.ESCALA.SAVED, function() {
                toastService.success("Operação realizada com sucesso.");
                vm.search();
            });

            $scope.$on(APP_EVENT.ESCALA.REFRESH, function() {
                vm.search();
            });

        }());

    }

}());