(function () {
  'use strict';

  angular.module('app.aluno').factory('Aluno', Aluno);
  Aluno.$inject = ['$resource', 'APP_PROPERTY', 'APP_MIME_TYPE', 'fileService'];

  function Aluno($resource, APP_PROPERTY, APP_MIME_TYPE, fileService) {
    return $resource(APP_PROPERTY.URL + '/alunos/:id', { id: '@id' }, {
      update: {
        method: 'PUT'
      },
      filter: {
        method: 'GET',
        isArray: true,
        url: APP_PROPERTY.URL + '/alunos'
      },
      resetSenha: {
        method: 'PUT',
        url: APP_PROPERTY.URL + '/alunos/reset-senha/:id' 
      },
      findByStatus: {
        method: 'GET',
        isArray: true,
        url: APP_PROPERTY.URL + '/alunos/:situacao'
      },
      delete: {
        method: 'DELETE',
        transformResponse: function (data, headers, statusCode) {
          return {
            data: data,
            responseStatusCode: statusCode
          };
        },
        url: APP_PROPERTY.URL + '/alunos/:id'
      },
      searchBy: {
        method: 'GET',
        isArray: false,
        url: APP_PROPERTY.URL + '/alunos/searchBy'
      },
      searchByPageable: {
        method: 'GET',
        isArray: false,
        url: APP_PROPERTY.URL + '/alunos/searchByPageable'
      },
      findByIdPessoa: {
        method: 'GET',
        isArray: false,
        url: APP_PROPERTY.URL + '/alunos/findByIdPessoa'
      },
      criarUsuario: {
        method: 'PATCH',
        isArray: false,
        url: APP_PROPERTY.URL + '/alunos/criarUsuario'
      },
      exportByUfAndMunicipioAndEscola: {
          method: 'GET',
          cache: false,
          responseType: 'arraybuffer',
          url: APP_PROPERTY.URL + '/alunos/exportar/:idAnoLetivo/:idPrograma/:idUf/:idMunicipio/:idEscola/import_template',
          headers: {
              accept: [APP_MIME_TYPE.JSON, APP_MIME_TYPE.EXCEL].join()
          },
          transformResponse: function (data, headers) {
              return fileService.transform(data, headers);
          }
      },
      exportByUfAndMunicipio: {
          method: 'GET',
          cache: false,
          responseType: 'arraybuffer',
          url: APP_PROPERTY.URL + '/alunos/exportar/:idAnoLetivo/:idPrograma/:idUf/:idMunicipio/import_template',
          headers: {
              accept: [APP_MIME_TYPE.JSON, APP_MIME_TYPE.EXCEL].join()
          },
          transformResponse: function (data, headers) {
              return fileService.transform(data, headers);
          }
      },
      exportByUf: {
          method: 'GET',
          cache: false,
          responseType: 'arraybuffer',
          url: APP_PROPERTY.URL + '/alunos/exportar/:idAnoLetivo/:idPrograma/:idUf/import_template',
          headers: {
              accept: [APP_MIME_TYPE.JSON, APP_MIME_TYPE.EXCEL].join()
          },
          transformResponse: function (data, headers) {
              return fileService.transform(data, headers);
          }
      },
      export: {
          method: 'GET',
          cache: false,
          responseType: 'arraybuffer',
          url: APP_PROPERTY.URL + '/alunos/exportar/:idAnoLetivo/:idPrograma/import_template',
          headers: {
              accept: [APP_MIME_TYPE.JSON, APP_MIME_TYPE.EXCEL].join()
          },
          transformResponse: function (data, headers) {
              return fileService.transform(data, headers);
          }
      },
      niveisEscolaridade: {
        method: 'GET',
        isArray: true,
        url: APP_PROPERTY.URL + '/alunos/niveisEscolaridade'          
      }
    });

  }

}());
