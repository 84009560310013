(function() {
    'use strict';

    angular.module('app.programas').controller('ProgramaController', ProgramaController);
    ProgramaController.$inject = ['APP_STATE', 'APP_EVENT', 'SEARCH_BY_FIELD', '$state', '$scope','$uibModal', 
        'dialogService', 'programasService', 'toastService', 'SecurityManagerFactory'];

    function ProgramaController(APP_STATE, APP_EVENT, SEARCH_BY_FIELD, $state, $scope, $uibModal, 
        dialogService, programasService, toastService, SecurityManagerFactory) {

        var vm = this;

        vm.goNewPage = function() {
            $state.go(APP_STATE.PROGRAMA.NEW);
        };

        vm.goEditPage = function(id) {
            $state.go(APP_STATE.PROGRAMA.EDIT, { id: id });
        };

        vm.clearSearchValue = function() {
            vm.searchValue = "";
            vm.searchValueModel = "";
        };

        vm.search = function() {
            vm.programas = [];
            vm.programasTotal = 0;
            vm.searchWasPerformed = false;
            vm.searchValue = vm.searchValueModel || vm.searchValue;

            if (!vm.searchValueModel) {
                vm.searchValueModel = vm.searchValue;
            }

            if (vm.searchValue) {
                var filter = {};
                filter[vm.searchType.field] = vm.searchValue;

                filter.page = 0;
                filter.size = 10;

                vm.filterProgramaToPaginate = angular.copy(filter);

                _findProgramaByFilters(filter);
            }
        };



        vm.onPageChangePrograma = function (page, size) {
            vm.filterProgramaToPaginate.page = page;
            vm.filterProgramaToPaginate.size = size;
            _findProgramaByFilters(vm.filterProgramaToPaginate);
        };
    

        var _findProgramaByFilters = function (filter) {

            programasService.filterPageable(filter).then(function(data) {
                vm.programas = angular.copy(data.content);
                vm.programasTotal = data.totalElements;
            }, function(error) {
                vm.programas = [];
                vm.programasTotal = 0;
            })
            .finally(function() {
                vm.searchWasPerformed = true;
                vm.formSearch.$setPristine();
            });
        };


        vm.delete = function(programa) {
            dialogService.delete().then(function() {
                programasService.delete({id: programa.id})
                    .then(function(response) {
                        if (response.responseStatusCode == 200) {
                            toastService.success("Registro removido com sucesso.");
                        } else if (response.responseStatusCode == 202) {
                            toastService.success("Registro inativado com sucesso.");
                        }
                        vm.search();
                    }, function(response) {
                        toastService.error({
                            status: response.status,
                            message: response.data.message
                        });
                    });
            }, function() {});
        };

        (function() {
            vm.hasPermissionView = SecurityManagerFactory.hasPermission('PRO:VI');
            vm.programas = [];
            vm.searchValue = "";
            vm.searchValueModel = "";
            vm.SEARCH_BY = SEARCH_BY_FIELD;
            vm.searchType = vm.SEARCH_BY.NAME;
            vm.searchWasPerformed = false;
            vm.filterProgramaToPaginate = {};

            $scope.$on(APP_EVENT.PROGRAMA.SAVED, function() {
                toastService.success("Operação realizada com sucesso.");
                vm.search();
            });

            $scope.$on(APP_EVENT.PROGRAMA.REFRESH, function() {
                vm.search();
            });

        }());

    }

}());
