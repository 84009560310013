(function(){
    'use strict';

    angular.module('app.coleta.resposta').service('coletaRespostaProcessamentoService', ColetaRespostaProcessamentoService);
    ColetaRespostaProcessamentoService.$inject = ['$q', 'ColetaRespostaProcessamento'];

    function ColetaRespostaProcessamentoService($q, ColetaRespostaProcessamento) {
       
        this.findByColetaRespostaId = function(id) {
            return ColetaRespostaProcessamento.findByColetaRespostaId({ id: id }).$promise;
        };

        this.save = function(idSolicitacao) {
           return ColetaRespostaProcessamento.save(idSolicitacao);
        };

        this.update = function(id, idSolicitacao) {
           var data = idSolicitacao;
           data.idColetaResposta = id; 
           return ColetaRespostaProcessamento.update(data);
        };


    }

}());