(function () {
  'use strict';

  angular.module('app.aluno').service('alunoService', AlunoService);
  AlunoService.$inject = ['Aluno','APP_PROPERTY','fileService','$q','HTTP_STATUS_CODE'];

  function AlunoService(Aluno,APP_PROPERTY,fileService,$q,HTTP_STATUS_CODE) {

    this.filter = function (filter) {
      return Aluno.filter(filter).$promise;
    };

    this.resetSenha = function(aluno){
      var entity = new Aluno(aluno);
      return entity.$resetSenha();
    };

    this.get = function (id) {
      return Aluno.get({ id: id }).$promise;
    };

    this.delete = function (id) {
      return Aluno.delete({ id: id }).$promise;
    };

    this.save = function (aluno) {
      var entity = new Aluno(aluno);

      if (entity.id) {
        return entity.$update();
      } else {
        return entity.$save();
      }
    };

    this.criarUsuario = function(idPessoa){
      return Aluno.criarUsuario({idPessoa:idPessoa}).$promise;
  }

    this.findAtivos = function () {
      return Aluno.findByStatus({ situacao: 'ATIVO' }).$promise;
    };

    this.filterAtivos = function (filter) {
      var newFilter = angular.copy(filter);
      newFilter.situacao = 'ATIVO';

      return Aluno.findByStatus(newFilter).$promise;
    };

    this.removeOldValuesFromAtributo = function (dados) {
      if (is.existy(dados) && is.not.empty(dados)) {
        var newData = angular.copy(dados);
        delete newData[0].opcaoItem;
        delete newData[0].valueOpcaoItemResposta;
        delete newData[0].valueNumericResposta;
        delete newData[0].valueDateResposta;
        delete newData[0].valueTimeResposta;
        delete newData[0].valueDateTimeResposta;
        delete newData[0].linha;
        delete newData[0].paragrafo;

        return newData;
      } else {
        return [];
      }
    };

    this._normalizeAtributosToServer = function (aluno) {
      var atributosEntidadesAlteradosDTO = _.filter(aluno.atributosEntidadesDTO, function (item) {
        return item._updated;
      });

      aluno.atributosEntidadesAlteradosDTO = atributosEntidadesAlteradosDTO;

      var atributosEntidadesIncluidosDTO = _.filter(aluno.atributosEntidadesDTO, function (item) {
        return is.not.existy(item.id);
      });

      aluno.atributosEntidadesIncluidosDTO = atributosEntidadesIncluidosDTO;
    };

    this.searchBy = function (filter) {
      console.log('url');
      
      var newFilter = angular.copy(filter);
      newFilter.situacao = 'ATIVO';

      return Aluno.searchBy(newFilter).$promise;
    }

    this.searchByPageable = function (filter) {
      return Aluno.searchByPageable(filter).$promise;
    }

    this.findByIdPessoa = function(idPessoa) {
      var params = {};
      params.idPessoa = idPessoa;
      return Aluno.findByIdPessoa(params).$promise;
    }

    this.import = function(file){
      var url = APP_PROPERTY.URL + '/alunos/importar/arquivo_excel';
      return $q(function(resolve, reject) {
          fileService.uploadAsArrayBuffer(url, file)
              .then(function(response) {
                  resolve(response);
              })
              .catch(function(error) {
                  var customError = {};
                  switch (error.status) {
                      case HTTP_STATUS_CODE.PRECONDITION_FAILED:
                          customError.messages = error.data;
                          break;
                      case HTTP_STATUS_CODE.BAD_REQUEST:
                          customError.formConstraints = error.data;
                          break;
                      default:
                          customError.messages = [error.data.message];
                  }
                  reject(customError);
              });
      });
    };

    this.exportByUfAndMunicipioAndEscola = function (idAnoLetivo, idPrograma, idUf, idMunicipio, idEscola) {
      return $q(function (resolve, reject) {
          Aluno.exportByUfAndMunicipioAndEscola({ idAnoLetivo: idAnoLetivo, idPrograma: idPrograma, idUf: idUf, idMunicipio: idMunicipio, idEscola: idEscola }).$promise
              .then(function (file) {
                  fileService.download(file);
                  resolve();
              })
              .catch(function (error) {
                  reject(error);
              });
      });
    };

    this.exportByUfAndMunicipio = function (idAnoLetivo, idPrograma, idUf, idMunicipio) {
      return $q(function (resolve, reject) {
          Aluno.exportByUfAndMunicipio({ idAnoLetivo: idAnoLetivo, idPrograma: idPrograma, idUf: idUf, idMunicipio: idMunicipio }).$promise
              .then(function (file) {
                  fileService.download(file);
                  resolve();
              })
              .catch(function (error) {
                  reject(error);
              });
      });
    };

    this.exportByUf = function (idAnoLetivo, idPrograma, idUf) {
      return $q(function (resolve, reject) {
          Aluno.exportByUf({ idAnoLetivo: idAnoLetivo, idPrograma: idPrograma, idUf: idUf }).$promise
              .then(function (file) {
                  fileService.download(file);
                  resolve();
              })
              .catch(function (error) {
                  reject(error);
              });
      });
    };

    this.export = function (idAnoLetivo, idPrograma) {
      return $q(function (resolve, reject) {
          Aluno.export({ idAnoLetivo: idAnoLetivo, idPrograma: idPrograma }).$promise
              .then(function (file) {
                  fileService.download(file);
                  resolve();
              })
              .catch(function (error) {
                  reject(error);
              });
      });
    };

    this.niveisEscolaridade = function() {
      return Aluno.niveisEscolaridade();
    }


  }

}());
