(function(){
    'use strict';

    angular.module('app.uf').service('ufService', UfService);
    UfService.$inject = ['Uf', 'STATUS_ENUM'];

    function UfService(Uf, STATUS_ENUM) {

        this.filter = function(filter) {
            return Uf.filter(filter).$promise;            
        };

        this.get = function(id) {
            return Uf.get({ id: id }).$promise;
        };

        this.save = function(uf) {
            var entity = new Uf(uf);
            
            if (entity.id) {
                return entity.$update();
            } else {
                return entity.$save();
            }
        };

        this.delete = function(uf) {
            return Uf.delete({id: uf.id}).$promise;
        };

        this.ativos = function() {
            return Uf.ativo();
        };
        
        this.withPermissionFilter = function(params) {
            return Uf.withPermissionFilter(params).$promise;
        };

        this.regionais = function(id) {
            return Uf.regionais({ id: id });
        };

    }

}());
