/*
jslint
    devel: true, maxerr: 10
*/

/*
global
    required: true
*/

/*
 * Relatórios Module
 * @module constants for class profile report
 * @author iFactory Solutions Ltda.
 * @version 1.0.0
 * @since 1.0.0
 */
(function() {
    'use strict';

    /* Acompanhamento de Leitura, Escrita e Oralidade/Produção de Texto: Acelera Brasil */
    angular.module('app.relatorios').constant('ACOMPANHAMENTO_ANUAL_PERFIL_ATENDIMENTO_UNIDADES_ESCOLARES_TURMAS_FIELDS', [
        { name: 'report.id', type: 'integer', calculateTotal: false, showFooter: false  },

        { name: 'school.name', type: 'string', calculateTotal: false, showFooter: false  },

        { name: 'ensinoregular.startyear.m', type: 'integer', calculateTotal: true, calculateType: 'sum'  },
         { name: 'ensinoregular.startyear.t', type: 'integer', calculateTotal: true, calculateType: 'sum'  },
         { name: 'ensinoregular.startyear.n', type: 'integer', calculateTotal: true, calculateType: 'sum'  },
         { name: 'ensinoregular.startyear.it', type: 'integer', calculateTotal: true, calculateType: 'sum'  },

         { name: 'ensinoregular.endyear.m', type: 'integer', calculateTotal: true, calculateType: 'sum'  },
         { name: 'ensinoregular.endyear.t', type: 'integer', calculateTotal: true, calculateType: 'sum'  },
         { name: 'ensinoregular.endyear.n', type: 'integer', calculateTotal: true, calculateType: 'sum'  },
         { name: 'ensinoregular.endyear.it', type: 'integer', calculateTotal: true, calculateType: 'sum'  },

         { name: 'ensinoregular.mediumyear.m', type: 'integer', calculateTotal: true, calculateType: 'sum'  },
         { name: 'ensinoregular.mediumyear.t', type: 'integer', calculateTotal: true, calculateType: 'sum'  },
         { name: 'ensinoregular.mediumyear.n', type: 'integer', calculateTotal: true, calculateType: 'sum'  },
         { name: 'ensinoregular.mediumyear.it', type: 'integer', calculateTotal: true, calculateType: 'sum'  },

         { name: 'alfabetizacao.defasados.m', type: 'integer', calculateTotal: true, calculateType: 'sum'  },
         { name: 'alfabetizacao.defasados.t', type: 'integer', calculateTotal: true, calculateType: 'sum'  },
         { name: 'alfabetizacao.defasados.n', type: 'integer', calculateTotal: true, calculateType: 'sum'  },
         { name: 'alfabetizacao.defasados.it', type: 'integer', calculateTotal: true, calculateType: 'sum'  },

         { name: 'aceleracao.startyear.m', type: 'integer', calculateTotal: true, calculateType: 'sum'  },
         { name: 'aceleracao.startyear.t', type: 'integer', calculateTotal: true, calculateType: 'sum'  },
         { name: 'aceleracao.startyear.n', type: 'integer', calculateTotal: true, calculateType: 'sum'  },
         { name: 'aceleracao.startyear.it', type: 'integer', calculateTotal: true, calculateType: 'sum'  },

         { name: 'seliga.m', type: 'integer', calculateTotal: true, calculateType: 'sum'  },
         { name: 'seliga.t', type: 'integer', calculateTotal: true, calculateType: 'sum'  },
         { name: 'seliga.n', type: 'integer', calculateTotal: true, calculateType: 'sum'  },
         { name: 'seliga.it', type: 'integer', calculateTotal: true, calculateType: 'sum'  },

         { name: 'acelerabrasil.m', type: 'integer', calculateTotal: true, calculateType: 'sum'  },
         { name: 'acelerabrasil.t', type: 'integer', calculateTotal: true, calculateType: 'sum'  },
         { name: 'acelerabrasil.n', type: 'integer', calculateTotal: true, calculateType: 'sum'  },
         { name: 'acelerabrasil.it', type: 'integer', calculateTotal: true, calculateType: 'sum'  },

         { name: 'formulavitoria.m', type: 'integer', calculateTotal: true, calculateType: 'sum'  },
         { name: 'formulavitoria.t', type: 'integer', calculateTotal: true, calculateType: 'sum'  },
         { name: 'formulavitoria.n', type: 'integer', calculateTotal: true, calculateType: 'sum'  },
         { name: 'formulavitoria.it', type: 'integer', calculateTotal: true, calculateType: 'sum'  },

         { name: 'formulavitoriaMatN1.m', type: 'integer', calculateTotal: true, calculateType: 'sum'  },
         { name: 'formulavitoriaMatN1.t', type: 'integer', calculateTotal: true, calculateType: 'sum'  },
         { name: 'formulavitoriaMatN1.n', type: 'integer', calculateTotal: true, calculateType: 'sum'  },
         { name: 'formulavitoriaMatN1.it', type: 'integer', calculateTotal: true, calculateType: 'sum'  },

         { name: 'formulavitoriaMatN2.m', type: 'integer', calculateTotal: true, calculateType: 'sum'  },
         { name: 'formulavitoriaMatN2.t', type: 'integer', calculateTotal: true, calculateType: 'sum'  },
         { name: 'formulavitoriaMatN2.n', type: 'integer', calculateTotal: true, calculateType: 'sum'  },
         { name: 'formulavitoriaMatN2.it', type: 'integer', calculateTotal: true, calculateType: 'sum'  },

         { name: 'totalturmas.value', type: 'integer', calculateTotal: true, calculateType: 'sum'  },
         { name: 'totalalunos.value', type: 'integer', calculateTotal: true, calculateType: 'sum'  }

    ]);

    angular.module('app.relatorios').constant('ACOMPANHAMENTO_ANUAL_PERFIL_ATENDIMENTO_UNIDADES_ESCOLARES_CURSOS_FIELDS', [
            { name: 'report.id', type: 'integer', calculateTotal: false, showFooter: false  },

            { name: 'detailing.name', type: 'string', calculateTotal: false, showFooter: false  },
            { name: 'detailing.indicators.classAmmount', type: 'integer', calculateTotal: true, calculateType: 'sum' },
            { name: 'detailing.indicators.studentAmmount', type: 'integer', calculateTotal: true, calculateType: 'sum' },

            { name: 'reading.readSilabando.ammount', type: 'integer', calculateTotal: true, calculateType: 'sum' },
            { name: 'reading.readSilabando.percent', type: 'integer', calculateTotal: true, calculateType: 'average' },
        ]);

    angular.module('app.relatorios').constant('TOTAL_ESCOLAS_PROGRAMA_FIELDS', [
            { name: 'id', type: 'integer', calculateTotal: false, showFooter: false  },

            { name: 'uf', type: 'string', calculateTotal: false, showFooter: false  },
            { name: 'detalhamento', type: 'string', calculateTotal: false, showFooter: false  },
            { name: 'seliga', type: 'integer', calculateTotal: true, calculateType: 'sum' },
            { name: 'aceleraBrasil', type: 'integer', calculateTotal: true, calculateType: 'sum' },
            { name: 'circuitoCampeao1', type: 'integer', calculateTotal: true, calculateType: 'sum' },
            { name: 'circuitoCampeao2', type: 'integer', calculateTotal: true, calculateType: 'sum' },
            { name: 'circuitoCampeao3', type: 'integer', calculateTotal: true, calculateType: 'sum' },
            { name: 'circuitoCampeao4', type: 'integer', calculateTotal: true, calculateType: 'sum' },
            { name: 'circuitoCampeao5', type: 'integer', calculateTotal: true, calculateType: 'sum' },
            { name: 'gestaoNota10AnoIniciais1', type: 'integer', calculateTotal: true, calculateType: 'sum' },
            { name: 'gestaoNota10AnoIniciais2', type: 'integer', calculateTotal: true, calculateType: 'sum' },
            { name: 'gestaoNota10AnoIniciais3', type: 'integer', calculateTotal: true, calculateType: 'sum' },
            { name: 'gestaoNota10AnoIniciais4', type: 'integer', calculateTotal: true, calculateType: 'sum' },
            { name: 'gestaoNota10AnoIniciais5', type: 'integer', calculateTotal: true, calculateType: 'sum' },
            { name: 'gestaoNota10AnoFinais6', type: 'integer', calculateTotal: true, calculateType: 'sum' },
            { name: 'gestaoNota10AnoFinais7', type: 'integer', calculateTotal: true, calculateType: 'sum' },
            { name: 'gestaoNota10AnoFinais8', type: 'integer', calculateTotal: true, calculateType: 'sum' },
            { name: 'gestaoNota10AnoFinais9', type: 'integer', calculateTotal: true, calculateType: 'sum' },
            { name: 'gestaoNota10EnsinoMedio1', type: 'integer', calculateTotal: true, calculateType: 'sum' },
            { name: 'gestaoNota10EnsinoMedio2', type: 'integer', calculateTotal: true, calculateType: 'sum' },
            { name: 'gestaoNota10EnsinoMedio3', type: 'integer', calculateTotal: true, calculateType: 'sum' },
            { name: 'gestaoAlfabetizacao', type: 'integer', calculateTotal: true, calculateType: 'sum' },
            { name: 'linguaPortuguesa', type: 'integer', calculateTotal: true, calculateType: 'sum' },
            { name: 'matematicaNivel1', type: 'integer', calculateTotal: true, calculateType: 'sum' },
            { name: 'matematicaNivel2', type: 'integer', calculateTotal: true, calculateType: 'sum' },
            { name: 'total', type: 'integer', calculateTotal: true, calculateType: 'sum' },
            { name: 'totalEscolas', type: 'integer', calculateTotal: true, calculateType: 'sum' }
        ]);







}());