(function() {
    'use strict';

    angular.module('app.coleta.resposta').factory('ColetaRespostaSolicitacao', ColetaRespostaSolicitacao);
    ColetaRespostaSolicitacao.$inject = ['$resource', 'APP_PROPERTY'];

    function ColetaRespostaSolicitacao($resource, APP_PROPERTY) {
        return $resource(APP_PROPERTY.URL + '/coletas/respostas/solicitacoes/:id', { id: '@idColetaResposta' }, {
            update: {
                method: 'PUT'
            },
            viewById: {
                method: 'GET',
                url: APP_PROPERTY.URL + '/coletas/respostas/solicitacoes/v2/:id'
            },
            findByColetaSolicitacaoId: {
                method: 'GET',
                isArray:true,
                url: APP_PROPERTY.URL + '/coletas/respostas/:id/solicitacoes'
             },
            findByColetaSolicitacaoAnaliseId:{
                method: 'GET',
                isArray:true,
                url: APP_PROPERTY.URL + '/coletas/respostas/:id/solicitacoes/:situacao'
            },
            findStatus: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/coletas/respostas/solicitacoes/status'
            },
            findByTipos: {
                method: 'GET',
                isArray:true,
                url: APP_PROPERTY.URL + '/coletas/respostas/solicitacoes/tipoColetaResposta'
            },
            save: {
                method: 'POST',
                url: APP_PROPERTY.URL + '/coletas/respostas/solicitacoes'
            },

        });

    }

}());