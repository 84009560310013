(function() {
    'use strict';

    angular.module('app.core').directive("iasFormularioFields", IasFormularioFields);
    IasFormularioFields.$inject = ['dateUtilService', 'VALIDATION_PATTERN'];

    function IasFormularioFields(dateUtilService, VALIDATION_PATTERN) {
        return {
            scope: {
                item: '=',
                ngModel: '=?',
                ngDisabled: '=?',
                ngRequired: '=?',
                externalFormSubmitted: '=?'
            },
            templateUrl: "core/directives/formulario/partials/fields.html",
            controller: function IasFormularioFieldsController($scope, Opcao, Medida, $filter){
                $scope.ngModel = $scope.ngModel || [];
                $scope.ngDisabled = is.existy($scope.ngDisabled) ? $scope.ngDisabled : true;
                $scope.ngRequired = is.existy($scope.ngRequired) ? $scope.ngRequired : false;
                $scope.externalFormSubmitted = is.existy($scope.externalFormSubmitted) ? $scope.externalFormSubmitted : false;

                var vm = this;
                var TEXT_AREA_MAX_LENGTH = 1000;
                vm.dateFormat = 'dd/MM/yyyy';
                vm.valueNumericMaxLength = 10;
                vm.randomIndex = Math.floor((Math.random() * 100000) + 1);
                vm.VALIDATION_PATTERN = VALIDATION_PATTERN;
                vm.countParagrafo = TEXT_AREA_MAX_LENGTH;
                vm.habilitarOpcaoComplemento = {};
                vm.opcaoComplemento = {};

                var _itemWatcher = $scope.$watch("item", function(newValue) {
                    if (newValue) {
                        _initOpcao();
                        _initMedida();
                    }
                });

                $scope.$on("$destroy", function() {
                    _itemWatcher();
                });

                vm.dateOptions = {
                    dateDisabled: _disabledWeekend,
                    formatYear: 'yy',
                    startingDay: 1
                };

                var _disabledWeekend = function(data) {
                    var date = data.date,
                        mode = data.mode;

                    return mode === 'day' && (date.getDay() === 0 || date.getDay() === 6);
                };

                var _findIndex = function(list, id) {
                    return _.findIndex(list, function(item) {
                        return item.opcaoItem.id == id;
                    });
                };

                var _initOpcao = function() {
                    if (is.existy($scope.item.opcao)) {
                        var items = $scope.item.opcao.opcaoItensDTO;
                        
                        if (undefined !== items && is.existy(items) && is.not.empty(items)) {
                            vm.opcoesItens = items;
                        } else {
                            Opcao.get({id: $scope.item.opcao.id}, function(opcao) {
                                vm.opcoesItens = opcao.opcaoItensDTO;
                            });
                        }
                    }
                };

                var _initMedida = function() {
                    if (is.existy($scope.item.medida) && is.not.existy($scope.item.medida.tipoValor)) {
                        Medida.filter({id: $scope.item.medida.id}, function(medida) {
                            $scope.item.medida = medida;
                        });
                    }
                };

                var _optionToModelValue = function(optionValue, id) {
                    var modelValue = {
                        opcaoItem: optionValue,
                        valueOpcaoItemResposta: optionValue.valor,
                        opcaoComplemento: (is.existy(optionValue.opcaoComplemento)) ? optionValue.opcaoComplemento : null 
                    };
                    
                    if (is.existy(id)) {
                        modelValue.id = id;
                    }

                    return modelValue;
                };

                vm.changeTimeValue = function() {
                    if (vm.valueTimeResposta) {
                        $scope.ngModel.valueTimeResposta = dateUtilService.getFormatedTime(vm.valueTimeResposta);
                    }
                };

                vm.populateTimeValue = function() {
                    if ($scope.ngModel.valueTimeResposta) {
                        vm.valueTimeResposta = angular.copy($scope.ngModel.valueTimeResposta);
                    }
                };

                vm.changeInputValue = function(fieldModelName, fieldName, dateType) {
                    var newValue;
                    var viewValue = (is.existy(fieldName)) ? vm[fieldName] : vm[fieldModelName];

                    if (viewValue) {
                        if (is.not.existy($scope.ngModel[0])) {
                            $scope.ngModel[0] = {};
                        }

                        if (dateType) {
                            var date = dateUtilService.parseFromClient(viewValue, dateType);
                            newValue = dateUtilService.formatToServer(date, dateType);
                        } else {
                            newValue = viewValue;
                        }

                        $scope.ngModel[0][fieldModelName] = newValue;
                        delete $scope.ngModel[0]['deleted'];
                    } else {
                        if (is.existy($scope.ngModel[0])) {
                            if (is.existy($scope.ngModel[0].id)) {
                                if (vm.medidaForm) {
                                    if (vm.medidaForm.fieldValue.$valid) {
                                        $scope.ngModel[0]['deleted'] = true;
                                    }
                                } else {
                                     $scope.ngModel[0]['deleted'] = true;
                                }
                            } else {
                                $scope.ngModel = [];
                            }
                        }
                    }
                };

                vm.markCheckboxes = function() {
                    angular.forEach(vm.opcoesItens, function(value, index) {
                        var checked = (_findIndex($scope.ngModel, value.id) != -1);
                        var itemOpcaoFechada = {};
                        
                        if (is.not.existy(vm['itemOpcaoFechada' + index])) {
                            vm['itemOpcaoFechada' + index] = angular.copy(value);
                        }

                        vm['itemOpcaoFechada' + index].checked = checked;
                        
                        if (vm['itemOpcaoFechada' + index].permiteComplemento && checked) {
                            vm.habilitarOpcaoComplemento[$scope.item.id + '-' + index] = true;    
                        }

                        var model = $filter('filter')($scope.ngModel, function(v) {
                            return v.opcaoItem.id == vm['itemOpcaoFechada' + index].id
                        });

                        if (model.length > 0) {
                            vm['itemOpcaoFechada' + index].opcaoComplemento = model[0].opcaoComplemento;
                        }
                    });
                };

                vm.changeOpcaoMultiplaEscolha = function(item) {
                    var index = _findIndex($scope.ngModel, item.id);

                    if (item.checked) {
                        if (index != -1) {
                            $scope.ngModel[index] = _optionToModelValue(item, $scope.ngModel[index].id);
                        } else {
                            $scope.ngModel.push(_optionToModelValue(item));
                        }
                    } else {
                        if (index != -1) {
                            if (is.existy($scope.ngModel[index].id)) {
                                $scope.ngModel[index]['deleted'] = true;
                            } else {
                                $scope.ngModel.splice(index, 1);
                            }
                        }
                    }
                };

                vm.changeOpcaoFechada = function(optionValue) {
                    if (optionValue) {
                        var id = null;

                        if (is.existy($scope.ngModel[0])) {
                            id = $scope.ngModel[0].id;
                        }

                        $scope.ngModel[0] = _optionToModelValue(optionValue, id);
                    } else {
                        if (is.existy($scope.ngModel[0])) {
                            if (is.existy($scope.ngModel[0].id)) {
                                $scope.ngModel[0]['deleted'] = true;
                            } else {
                                $scope.ngModel = [];
                            }
                        }
                    }
                };

                var _verificarOpcaoItemPermiteComplemento = function(opcaoItem, items) {
                    var opcoesItem = $filter('filter')(items, function(value, index) {
                        return value.id == opcaoItem.id;
                    });

                    if (opcoesItem.length > 0) {
                        if (opcoesItem[0].permiteComplemento) {
                            vm.permitirComplemento(opcoesItem[0], $scope.item, opcaoItem);
                        }
                    }              
                };

                vm.initOpcaoFechada = function() {
                    vm.itemOpcao = null;
                    var model = $scope.ngModel[0];
                    var habilitarOpcaoComplemento = false;
                    
                    if (model) {
                        vm.itemOpcao = model.opcaoItem;

                        if (undefined !== model.opcaoComplemento) {
                            vm.opcaoComplemento[$scope.item.id] = model.opcaoComplemento;
                        }

                        if (is.existy($scope.item.opcao)) {
                            var items = $scope.item.opcao.opcaoItensDTO;
                            
                            if (is.existy(items) && is.not.empty(items)) {
                                _verificarOpcaoItemPermiteComplemento(vm.itemOpcao, items);
                            } else {
                                Opcao.get({id: $scope.item.opcao.id}, function(opcao) {
                                    _verificarOpcaoItemPermiteComplemento(vm.itemOpcao, opcao.opcaoItensDTO);
                                }); 
                            }
                        }
                    }
                };

                vm.initInputValue = function(fieldModelName, fieldName, dateType) {
                    var model = $scope.ngModel[0];

                    if (dateType) {
                        if (model) {
                            var date = dateUtilService.parseFromServer(model[fieldModelName], dateType);

                            if (date) {
                                vm[fieldModelName] = date;
                                vm[fieldName] = dateUtilService.formatToClient(date, dateType);
                            } else {
                                vm[fieldModelName] = null;
                            }
                        } else {
                            vm[fieldModelName] = null;
                        }
                    } else {
                        vm[fieldModelName] = (model) ? model[fieldModelName] : null;
                    }
                };

                vm.charRest = function(val) {
                  var charValDig = (val) ? parseInt(val.length) : 0;

                  return  TEXT_AREA_MAX_LENGTH - charValDig;
                };

                vm.initParagrafo = function() {
                  vm.initInputValue('paragrafo');
                  var paragrafoValue = vm['paragrafo'];

                  if (is.existy(paragrafoValue) && is.not.empty(paragrafoValue)) {
                    vm.countParagrafo -= paragrafoValue.length;
                  }
                };

                vm.updateAmountChar = function() {
                    vm.countParagrafo = vm.charRest(vm.paragrafo);
                }

                vm.permitirComplemento = function(optionItem, item, vmItemOpcao) {
                    vm.habilitarOpcaoComplemento[item.id] = false;
                    
                    if (undefined !== optionItem.permiteComplemento) {
                        vm.habilitarOpcaoComplemento[item.id] = optionItem.permiteComplemento;
                        
                        if (optionItem.permiteComplemento) {
                            vm.setarComplemento(vm.opcaoComplemento[item.id]);
                        }
                    }
                };

                vm.permitirComplementoCheckbox = function(optionItem, item, vmItemOpcao, i) {
                    vm.habilitarOpcaoComplemento[item.id + '-' + i] = optionItem.permiteComplemento && vmItemOpcao.checked;
                };

                vm.setarComplemento = function(complemento) {
                    $scope.ngModel[0].opcaoComplemento = complemento;
                }
            },
            controllerAs: 'vm'
        };
    }

})();
