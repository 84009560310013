(function(){
    'use strict';

    angular.module('app.formulario').service('formularioService', FormularioService);
    FormularioService.$inject = ['$q', 'Formulario'];

    function FormularioService($q, Formulario) {
        
        this.findAtivos = function(embed) {
            return Formulario.findByStatus({status: 'ATIVO', embed: embed}).$promise;
        };

        this.findItensAtivosById = function(id) {
            return Formulario.findItensByFormularioId({id: id, status: 'ATIVO'}).$promise;
        };

        this.findSituacoes = function() {
            var deferred = $q.defer();

            Formulario.status().$promise.then(function(situacaoList) {
                var situacoes = _.map(situacaoList, function(situacao) {
                    return {
                        key: situacao.id,
                        value: situacao.name
                    };
                });

                deferred.resolve(situacoes);
            }, function(error) {
                deferred.reject(error);
            });

            return deferred.promise;
        };

        this.findTipos = function() {
            var deferred = $q.defer();

            Formulario.tiposItens().$promise.then(function(tipoList) {
                var tipos = _.map(tipoList, function(tipo) {
                    return {
                        key: tipo.id,
                        value: tipo.name
                    };
                });

                deferred.resolve(tipos);
            }, function(error) {
                deferred.reject(error);
            });

            return deferred.promise;
        };

        this.isUsed = function(id) {
            return Formulario.isUsed({id: id}).$promise;
        };
        
        this.findByRubrica = function(idRubrica){
            return Formulario.findByRubrica({idRubrica:idRubrica,status:'ATIVO'}).$promise;
        };

        this.findByTurmaRubrica = function(idTurma,idRubrica){
            return Formulario.findByTurmaRubrica({idTurma:idTurma,idRubrica:idRubrica}).$promise;
        };

        this.findAll = function(){
            return Formulario.findAll().$promise;
        };

        this.findByTurma = function(idTurma){

            return Formulario.findByTurma({idTurma:idTurma}).$promise;
        };

    }

}());
