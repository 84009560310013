(function() {
    'use strict';

    angular.module('app.core').directive("iasPreviewItems", IasPreviewItems);
    IasPreviewItems.$inject = ['$uibModal', 'DialogService'];

    function IasPreviewItems($uibModal, DialogService) {
        return {
            scope: {
                itens: '=',
                tipo: '=',
                tipos: '=',
                formulario: '='
            },
            restrict: "E",
            templateUrl: "core/directives/formulario/partials/button-preview.html",
            controller: ['$scope', '$filter', function($scope, $filter) {                
                $scope.openModal = function (itens, tipo, tipos,formulario) {
                    DialogService.modal(
                        'core/directives/formulario/partials/modal-preview.html',
                        function($uibModalInstance){
                            var vm = this;
                            var key = tipo.$viewValue || tipo.key;
                            var tipoName = $filter('filter')(tipos, { id: key });
                            
                            vm.itens = [];
                            vm.tipoName = tipoName[0].name;
                            vm.tipoId = tipoName[0].id;
                            vm.formulario = formulario;
                            if (is.existy(itens)) {
                                var filtered = _.filter(itens, function(item) {
                                    return item.selected;
                                });

                                if (is.not.empty(filtered)) {
                                    vm.itens = filtered;
                                } else {
                                    vm.itens = angular.copy(itens);
                                }
                            }
                            
                            vm.cancel = function() {
                                $uibModalInstance.dismiss('cancel');
                            };
                        }
                    );
                }
            }]
        };
    }

})();
