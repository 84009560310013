/*
jslint
    devel: true, maxerr: 10
*/

/*
global
    required: true
*/

/*
 * Login Module Resource
 * @author iFactory Solutions Ltda.
 * @version 1.0.0
 * @since 1.0.0
 */
(function() {
    'use strict';

    angular.module('app.login').factory('LoginResource', LoginResource);
    LoginResource.$inject = ['$http', 'APP_CONFIG', '$httpParamSerializer'];

    function LoginResource($http, APP_CONFIG, $httpParamSerializer) {
        return {
            logIn: logIn,
            getUserInfo: getUserInfo
        }

        /**
         * Realiza o login do usuário via o servidor de autenticação OAuth, utilizando grant type "password".
         * 
         * @function logIn
         * @argument credentials contém as credenciais (login e senha) para a tentiva de autenticação
         * @since 1.0.0
         * @author iFactory Solutions Ltda.
         */
        function logIn(credentials) {
            var login = credentials.login;
            var password = credentials.password;

            var url = APP_CONFIG.SECURITY.AUTHENTICATION.SERVER + APP_CONFIG.SECURITY.AUTHENTICATION.URL.ACCESS_TOKEN;
            var data = {
                grant_type: APP_CONFIG.SECURITY.AUTHENTICATION.PARAM.GRANT_TYPE,
                username: login,
                password: password
            }

            return $http.post(url, $httpParamSerializer(data), {
                headers: {
                    "Authorization": APP_CONFIG.SECURITY.AUTHENTICATION.HEADER.AUTHORIZATION,
                    "Content-Type": "application/x-www-form-urlencoded"
                },
                withCredentials: true
            });
        }

        /**
         * Recupera informações do usuário após autenticação.
         * 
         * @function logIn
         * @argument credentials contém as credenciais (login e senha) para a tentiva de autenticação
         * @since 1.0.0
         * @author iFactory Solutions Ltda.
         */
        function getUserInfo(user) {
            var accessToken = user.credentials.accessToken;
            var url = APP_CONFIG.SECURITY.AUTHENTICATION.SERVER + APP_CONFIG.SECURITY.AUTHENTICATION.URL.USER_INFO;

            return $http.get(url, {
                headers: {
                    "Authorization": "Bearer " + accessToken,
                    "Content-Type": "application/json;charset=UTF-8"
                },
                data: "",
                withCredentials: true
            });
        }
    }

}());