/*
jslint
    devel: true, maxerr: 10
*/

/*
global
    required: true
*/

/*
 * Selection Criteria Service
 * @module app.criterioSelecao (Selection Criteria Module
 * @author iFactory Solutions Ltda.
 * @version 1.0.0
 * @since 1.0.0
 */
(function() {
    'use strict';

    angular.module('app.criterioSelecao').service('CriterioSelecaoService', CriterioSelecaoService);
    CriterioSelecaoService.$inject = ['CriterioSelecaoResource'];

    function CriterioSelecaoService(resource) {

        this.filter = function(filter) {
            return resource.filter(filter).$promise;
        };

        this.get = function(id) {
            return resource.get({ id: id }).$promise;
        };

        this.save = function(model) {
            var entity = new resource(model);

            if (entity.id) {
                return entity.$update();
            } else {
                return entity.$save();
            }
        };

        this.delete = function(model) {
            return resource.delete({ id: model.id }).$promise;
        };

        this.allEnabled = function() {
            return resource.findAllEnabled();
        };

    }

}());