(function(){
    'use strict';

    angular.module('app.grupoMunicipio').service('grupoMunicipioService', GrupoMunicipioService);
    GrupoMunicipioService.$inject = ['GrupoMunicipio'];

    function GrupoMunicipioService(GrupoMunicipio) {
        var self = this;

        
        this.status = [];

        this.filter = function(filter) {
            return GrupoMunicipio.filter(filter).$promise;            
        };

        this.get = function(id) {
            return GrupoMunicipio.get({ id: id }).$promise;
        };
    
        this.save = function(grupoMunicipio) {
            var entity = new GrupoMunicipio(grupoMunicipio);
            if (entity.id) {
                return entity.$update();
            } else {
                return entity.$save();
            }
        };

        this.findStatus = function() {
            return GrupoMunicipio.findByStatus({status: 'ATIVO'});
        };

        this.delete = function(escala) {
            return GrupoMunicipio.delete({id: escala.id}).$promise;
        };

    }

}());
