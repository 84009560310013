/*
jslint
    devel: true, maxerr: 10
*/
/*
global
    angular
*/
/*
 * App Main Module
 * @author iFactory Solutions Ltda.
 * @version 1.0.0
 * @since 1.0.0
 */
(function() {
    'use strict';

    angular.module('app', [
        'config',
        'app.core',
        'app.layout',

        /* Features (modules) */
        'app.login',
        'app.home',
        'app.usuario',
        'app.opcao',
        'app.medida',
        'app.atributo',
        'app.formulario',
        'app.calendario',
        'app.medida',
        'app.escala',
        'app.coleta',
        'app.programas',
        'app.perfilAcesso',
        'app.programaExterno',
        'app.etapa',
        'app.modalidadeEnsino',
        'app.localizacao',
        'app.dependenciaAdministrativa',
        'app.uf',
        'app.municipios',
        'app.periodoLetivo',
        'app.escolas',
        'app.turmas',
        'app.professor',
        'app.aluno',
        'app.coleta.resposta',
        'app.criterioSelecao',
        'app.contrato',
        'app.relatorios',
        'app.professor',
        'app.disciplinas',
        'app.grupoMunicipio',
        'app.rubricas',
        'app.areaConhecimento',
        'app.coletaRubrica',
        'app.formExemplo',
        'app.validarEmail'
    ]).config(config).run(run);

    config.$inject = ['$urlRouterProvider', '$stateProvider', '$httpProvider', 'APP_STATE', '$qProvider'];
    run.$inject = ['$state', '$rootScope', '$http', 'SecurityManagerFactory', 'APP_STATE', 'PUBLIC_PAGES', 'APP_CONFIG', 'toastService'];

    function config($urlRouterProvider, $stateProvider, $httpProvider, APP_STATE, $qProvider) {
        $urlRouterProvider.otherwise('/');

        // Initialize get if not there.
        if (!$httpProvider.defaults.headers.get) {
            $httpProvider.defaults.headers.get = {};
        }

        // Disable IE ajax request caching.
        $httpProvider.defaults.headers.get['Cache-Control'] = 'no-cache';
        $httpProvider.defaults.headers.get['Pragma'] = 'no-cache';

        $httpProvider.interceptors.push('SecurityInterceptorFactory');

        $qProvider.errorOnUnhandledRejections(false);
    }

    function run($state, $rootScope, $http, securityManager, APP_STATE, PUBLIC_PAGES, APP_CONFIG, toastService) {
        $rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams) {
            var currentUrl = toState.url || null;

            if (securityManager.isUserLogged()) {
                if (currentUrl === '/login') {
                    $state.go(APP_STATE.HOME.BASE);
                    event.preventDefault();
                }

                if (undefined !== toState.data) {
                    if (!securityManager.hasPermission(toState.data.permission)) {
                        toastService.error("Você não tem permissão para acessar.");
                        $state.go(APP_STATE.HOME.BASE);
                        event.preventDefault();  
                    }
                }                
            } else {
                if (currentUrl !== '/login') {
                    if (!is.inArray(currentUrl, PUBLIC_PAGES)) {
                        $state.go(APP_STATE.LOGIN.BASE);
                        event.preventDefault();
                    }
                }
            }
        });

        $rootScope.$on('$stateChangeSuccess', function(event, toState, toParams, fromState, fromParams) {
            $state.previous = fromState;
            $state.previous.params = fromParams;
        });

        $rootScope.$on('security.event.userLoggedIn', function(event, data) {
            if (APP_CONFIG.SECURITY.STATUS == APP_CONFIG.enums.security.status.on) {
                $http.defaults.headers.common.Authorization = APP_CONFIG.SECURITY.TOKEN.TYPE + ' ' + data.credentials.accessToken;
            }
        });

        $rootScope.$on('security.event.userLoggedOut', function(event, user) {
            $http.delete(APP_CONFIG.SECURITY.AUTHENTICATION.SERVER + APP_CONFIG.SECURITY.AUTHENTICATION.URL.REMOVE_TOKEN,  {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8"
                },
                data: user
            })
            .then(function successCallback(data){
                if (APP_CONFIG.SECURITY.STATUS == APP_CONFIG.enums.security.status.on) {
                    $http.defaults.headers.common.Authorization = undefined;
                }
            });
        });
    }

})();