(function () {
	'use strict';

	angular.module('app.medida').controller('MedidaFormularioController', MedidaFormularioController);
	MedidaFormularioController.$inject = ['APP_STATE', '$state', '$filter', 'Medida', 'toastService',
	'$scope','APP_EVENT', '$document', 'dateUtilService', 'VALIDATION_PATTERN'];

	function MedidaFormularioController(APP_STATE, $state, $filter, Medida, toastService,$scope, 
		APP_EVENT, $document, dateUtilService, VALIDATION_PATTERN) {

		var vm = this;
		vm.mySwitch = false;
		vm.mySwiteEdit = true;
		vm.mySwitchAnalitico = false
		vm.key = false;
		vm.used = false;
		vm.VALIDATION_PATTERN = VALIDATION_PATTERN;
		
		if (typeof ($state.params.id) != 'undefined' && $state.params.id != null) {
			vm.mySwitch = true;
			vm.mySwitchAnalitico = true
			vm.key = true;
			vm.mySwiteEdit = false;
		} else {
			vm.mySwiteEdit = false;
		};

		vm.TYPESCHOYCE = {
			'I': {
				type: "text",
				format: "currency",
				maxlength: 8
			},
			'R': {
				type: "number",
				format: "currency",
				maxlength: 8
			},
			'M': {
				placeholder: "",
				type: "number",
				maxlength: 8
			},
			'P': {
				placeholder: "",
				maxlength: 8
			},
			'D': {
				placeholder: "",
				type: "date",
				maxlength: 10
			},
			'H': {
				placeholder: "",
				type: "date",
				maxlength: 10
			},
			'T': {
				placeholder: "",
				type: "date",
				maxlength: 10
			}
		};

		vm.goBack = function () {
			$state.go(APP_STATE.MEDIDA.SEARCH);

		$document.ready(function () {
							$('#bootstrap-multiselect-tipo').multiselect({
									nonSelectedText: "Nenhum tipo selecionado",
									allSelectedText: "Todos tipos selecionados",
									numberDisplayed: 7,
									includeSelectAllOption: true,
									selectAllText: " Selecionar todos tipos",
									onChange: function (option, checked, select) {
											vm.changeSelect(option, checked, select);
									}
							});
					});
			$document.ready(function () {
					$('#bootstrap-multiselect-scope').multiselect({
							nonSelectedText: "Nenhum escopo selecionado",
							allSelectedText: "Todos escopos selecionados",
							numberDisplayed: 2,
							includeSelectAllOption: true,
							selectAllText: " Selecionar todos escopos",
							onChange: function (option, checked, select) {
									vm.changeSelect(option, checked, select);
							}
					});
			});

		};

		vm.mask = function () {
			var type = vm.model.tipoValor.key;
			if (type == "I") {
				return "9";
			}
		};

		vm.clearValidationFields = function () {
			vm.message = "";
			vm.mytime = null;
			vm.model.valorMinimoNumero = null;
			vm.model.valorMaximoNumero = null;
			vm.model.valorMinimoData = null;
			vm.model.valorMaximoData = null;
			vm.model.valorMinimoHora = null;
			vm.model.valorMaximoHora = null;
			vm.model.valorMinimoDataHora = null;
			vm.model.valorMaximoDataHora = null;

			vm.valorMinimoData = null;
			vm.valorMaximoData = null;
			vm.valorMinimoDataHora = null;
			vm.valorMaximoDataHora = null;
			vm.valorMinimoHora = null;
			vm.valorMaximoHora = null;
		};

		vm.changeTipo = function() {
			vm.clearValidationFields();

			if (is.existy(vm.model.tipoValor.key)) {
				vm.type = vm.TYPESCHOYCE[vm.model.tipoValor.key];
			} else {
				vm.type = null;
			}
		};

		vm.changeTipoValidacao = function () {
			vm.clearValidationFields();

			if (is.existy(vm.model.tipoValidacao.key)) {
				vm.typeValid = vm.TYPESVALIDATION[vm.model.tipoValidacao.key];
			} else {
				vm.typeValid = null;
			}
		};

		vm.TYPESVALIDATION = {
			'0': {
				required: false
			},
			'1': {
				required: true
			},
			'2': {
				required: true
			},
			'3': {
				required: true
			},
			'4': {
				required: true
			},
			'5': {
				required: true
			}
		};

		//============================= calendario =======================================

		vm.inlineOptions = {
			customClass: getDayClass,
			minDate: new Date(),
			showWeeks: true
		};

		vm.dateOptions = {
			dateDisabled: disabled,
			formatYear: 'yy',
			maxDate: new Date(2020, 5, 22),
			minDate: new Date(),
			startingDay: 1
		};

		// Disable weekend selection
		function disabled(data) {
			var date = data.date,
				mode = data.mode;
			return mode === 'day' && (date.getDay() === 0 || date.getDay() === 6);
		}

		vm.formats = ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
		vm.format = vm.formats[0];
		vm.altInputFormats = ['M!/d!/yyyy'];

		var tomorrow = new Date();
		tomorrow.setDate(tomorrow.getDate() + 1);
		var afterTomorrow = new Date();
		afterTomorrow.setDate(tomorrow.getDate() + 1);
		vm.events = [
			{
				date: tomorrow,
				status: 'full'
			},
			{
				date: afterTomorrow,
				status: 'partially'
			}
		];

		function getDayClass(data) {
			var date = data.date,
				mode = data.mode;
			if (mode === 'day') {
				var dayToCheck = new Date(date).setHours(0, 0, 0, 0);

				for (var i = 0; i < $scope.events.length; i++) {
					var currentDay = new Date($scope.events[i].date).setHours(0, 0, 0, 0);

					if (dayToCheck === currentDay) {
						return $scope.events[i].status;
					}
				}
			}

			return '';
		}
		vm.open1 = function () {
			vm.popup1.opened = true;
		};

		vm.open2 = function () {
			vm.popup2.opened = true;
		};

		vm.popup1 = {
			opened: false
		};

		vm.popup2 = {
			opened: false
		};

		//=============== end calendario ==========================

		//================= Hora ==================================

		vm.mytime = new Date();

		vm.hstep = 1;
		vm.mstep = 15;

		vm.options = {
			hstep: [1, 2, 3],
			mstep: [1, 5, 10, 15, 25, 30]
		};

		vm.ismeridian = true;
		vm.toggleMode = function () {
			vm.ismeridian = !vm.ismeridian;
		};

		vm.ismeridian2 = true;
		vm.toggleMode = function () {
			vm.ismeridian2 = !vm.ismeridian2;
		};

		vm.update = function () {
			var d = new Date();
			d.setHours(14);
			d.setMinutes(0);
			vm.mytime = d;
		};

		//====================end Hora=============================

		vm.typesScope = Medida.listasMedidas({ id: "escopos" });
		vm.typesValue = Medida.listasMedidas({ id: "valores" });
		vm.typesValidation = Medida.listasMedidas({ id: "validadores" });
		//vm.medidas = Medida.listasStatus({ status: "ativo" });
		vm.medidas = Medida.findMedidasSinteticas();
		
		vm.clearValidationMessage = function () {
			vm.message = "";
		};

		if ($state.is(APP_STATE.MEDIDA.EDIT)) {
			if ($state.params.id) {
				

				Medida.filter({ id: $state.params.id }).$promise.then(function (response) {
					Medida.isUsedInColeta({id: response.id}).$promise.then(function(_used){
						vm.used = _used.used;
					});

					if (is.existy(response.medidaPai)) {
						vm.medidas.$promise.then(function(data){
							if (is.not.existy(_.findWhere(data, {id: response.medidaPai.id}))){
								vm.medidas.push(response.medidaPai);
								vm.medidas = _.sortBy(vm.medidas, 'nome');
							}
						});
					}

					if (is.not.null(response.valorMaximoDataHora)) {
						vm.valorMaximoDataHora = moment(response.valorMaximoDataHora).format('DD/MM/YYYY HH:mm');
					}

					if (is.not.null(response.valorMinimoDataHora)) {
						vm.valorMinimoDataHora = moment(response.valorMinimoDataHora).format('DD/MM/YYYY HH:mm');
					}

					if (is.not.null(response.valorMinimoData)) {
						vm.valorMinimoData = dateUtilService.formatToClient(response.valorMinimoData, dateUtilService.TYPE.DATE);
					}

					if (is.not.null(response.valorMaximoData)) {
						vm.valorMaximoData = dateUtilService.formatToClient(response.valorMaximoData, dateUtilService.TYPE.DATE);
					}
					
					if (is.not.null(response.valorMinimoHora)) {
						vm.valorMinimoHora = response.valorMinimoHora;
					}

					if (is.not.null(response.valorMaximoHora)) {
						vm.valorMaximoHora = response.valorMaximoHora;
					}

					vm.model = response;

					if (is.existy(vm.model.tipoValor.key)) {
						vm.type = vm.TYPESCHOYCE[vm.model.tipoValor.key];
					}

				}, function (error) {
				});
			}
		} else {
			vm.model = new Medida();
		};

		vm.validacaoFormulario = function () {
			habilita(vm.model.tipoEscopo.key);
			var _validoCamposMinimoMaximo = function (valorMinimo, valorMaximo) {
				
				if (vm.model.tipoValidacao.key == 1) {
					if (!_validoMinimoMaximo(valorMinimo, valorMaximo)) {
						return false;
					}
				} else if (vm.model.tipoValidacao.key == 2) {
					if (!_validoSomenteMinimo(valorMinimo)) {
						return false;
					}
				} else if (vm.model.tipoValidacao.key == 3) {
					if (!_validoSomenteMaximo(valorMaximo)) {
						return false;
					}
				} else if (vm.model.tipoValidacao.key == 4) {
					if (!_validoSomenteMinimo(valorMinimo)) {
						return false;
					}
				} else if (vm.model.tipoValidacao.key == 5) {
					if (!_validoSomenteMaximo(valorMaximo)) {
						return false;
					}
				}

				return true;
			}

			var _validoMinimoMaximo = function (valorMinimo, valorMaximo) {
				if (is.null(valorMaximo) || is.null(valorMinimo)) {
					vm.message = "Preencha o valor mínino e o máximo.";
					return false;
				} else if (valorMinimo > valorMaximo) {
					vm.message = "Valor mínino não pode ser maior que o valor máximo.";
					return false;
				}

				return true;
			}

			var _validoSomenteMinimo = function (valorMinimo) {
				if (undefined == valorMinimo || is.null(valorMinimo) || is.empty(valorMinimo)) {
					vm.message = "Preencha somente o valor mínino.";
					return false;
				}

				return true;
			}

			var _validoSomenteMaximo = function (valorMaximo) {
				if (undefined == valorMaximo || is.null(valorMaximo) || is.empty(valorMaximo)) {
					vm.message = "Preencha somente o valor máximo.";
					return false;
				}

				return true;
			}

			if (['I', 'R', 'M', 'P'].indexOf(vm.model.tipoValor.key) != -1) {
				var valorMinimo = vm.model.valorMinimoNumero;
				var valorMaximo = vm.model.valorMaximoNumero;

				if (!_validoCamposMinimoMaximo(valorMinimo, valorMaximo)) {
					return false;
				}
			} else if (vm.model.tipoValor.key == "D") {
				var valorMinimo = null;
				var	valorMaximo = null;

				if (is.not.null(vm.valorMinimoData) && is.not.empty(vm.valorMinimoData)) {
					valorMinimo = dateUtilService.parseToInt(vm.valorMinimoData, dateUtilService.TYPE.DATE);
				}

				if (is.not.null(vm.valorMaximoData) && is.not.empty(vm.valorMaximoData)) {
					valorMaximo = dateUtilService.parseToInt(vm.valorMaximoData, dateUtilService.TYPE.DATE);
				}

				if (!_validoCamposMinimoMaximo(valorMinimo, valorMaximo)) {
					return false;
				}
			} else if (vm.model.tipoValor.key == "H") {
				var valorMinimo = null;
				var valorMaximo = null;

				if (is.not.null(vm.valorMinimoHora) && is.not.empty(vm.valorMinimoHora)) {
					valorMinimo = dateUtilService.parseToInt(vm.valorMinimoHora, dateUtilService.TYPE.TIME);
				}

				if (is.not.null(vm.valorMaximoHora) && is.not.empty(vm.valorMaximoHora)) {
					valorMaximo = dateUtilService.parseToInt(vm.valorMaximoHora, dateUtilService.TYPE.TIME);
				}

				if (!_validoCamposMinimoMaximo(valorMinimo, valorMaximo)) {
					return false;
				}
			} else if (vm.model.tipoValor.key == "T") {
				var valorMinimo = null;
				var valorMaximo = null;

				if (is.not.null(vm.valorMinimoDataHora) && is.not.empty(vm.valorMinimoDataHora)) {
					valorMinimo = dateUtilService.parseToInt(vm.valorMinimoDataHora, dateUtilService.TYPE.DATE_TIME);
				}

				if (is.not.null(vm.valorMaximoDataHora) && is.not.empty(vm.valorMaximoDataHora)) {
					valorMaximo = dateUtilService.parseToInt(vm.valorMaximoDataHora, dateUtilService.TYPE.DATE_TIME);
				}

				if (!_validoCamposMinimoMaximo(valorMinimo, valorMaximo)) {
					return false;
				}
			}

			var selectedTypesScope = _.map(
				_.filter(vm.typesScope, function (element) {
					return element.id == vm.model.tipoEscopo.key;
				}),
				function (element) {
					return { key: element.id, value: element.name };
				});

			if (selectedTypesScope && _.isArray(selectedTypesScope)) {
				vm.model.tipoEscopo = selectedTypesScope[0];
			}


			var selectedType = _.map(
				_.filter(vm.typesValue, function (element) {
					return element.id == vm.model.tipoValor.key;
				}),
				function (element) {
					return { key: element.id, value: element.name };
				});

			if (selectedType && _.isArray(selectedType)) {
				vm.model.tipoValor = selectedType[0];
			}

			var selectedTypeValidation = _.map(
				_.filter(vm.typesValidation, function (element) {
					return element.id == vm.model.tipoValidacao.key;
				}),
				function (element) {
					return { key: element.id, value: element.name };
				});

			if (selectedTypeValidation && _.isArray(selectedTypeValidation)) {
				vm.model.tipoValidacao = selectedTypeValidation[0];
			}

			if (vm.key) {
				// editar
				// verificar se o campos nome, descricao, tipo, validacao, escopo estão vazios
				if (vm.medidaForm.$valid) {
					vm.save();
				}
			} else {
				// salvar
				if (vm.medidaForm.$valid) {
					vm.save();

				}

			}
		};

		vm.save = function () {
			if (is.existy(vm.valorMinimoHora)) {
				vm.model.valorMinimoHora = moment(vm.valorMinimoHora, 'HH:mm').format('HH:mm');
			}

			if (is.existy(vm.valorMaximoHora)) {
				vm.model.valorMaximoHora = moment(vm.valorMaximoHora, 'HH:mm').format('HH:mm');
			}

			if (is.existy(vm.valorMinimoData)) {
				vm.model.valorMinimoData = moment(vm.valorMinimoData, 'DD/MM/YYYY').format('YYYY-MM-DD');
			}

			if (is.existy(vm.valorMaximoData)) {
				vm.model.valorMaximoData = moment(vm.valorMaximoData, 'DD/MM/YYYY').format('YYYY-MM-DD');
			}

			if (is.existy(vm.valorMinimoDataHora)) {
				vm.model.valorMinimoDataHora = moment(vm.valorMinimoDataHora, 'DD/MM/YYYY HH:mm');
			}

			if (is.existy(vm.valorMaximoDataHora)) {
				vm.model.valorMaximoDataHora = moment(vm.valorMaximoDataHora, 'DD/MM/YYYY HH:mm');
			}

			if (vm.key) {
				vm.updade(vm.model);
			} else {
				vm.insert(vm.model);
			}
		}

		vm.updade = function (element) {
			Medida.update(element).$promise.then(function (response) {
				toastService.success("Medida foi salva com sucesso.");
				vm.goSeachPage();
				$scope.$emit(APP_EVENT.MEDIDA.SAVED);

								$document.ready(function () {
										$('#bootstrap-multiselect-tipo').multiselect({
												nonSelectedText: "Nenhum tipo selecionado",
												allSelectedText: "Todos tipos selecionados",
												numberDisplayed: 7,
												includeSelectAllOption: true,
												selectAllText: " Selecionar todos tipos",
												onChange: function (option, checked, select) {
														vm.changeSelect(option, checked, select);
												}
										});
								});
						$document.ready(function () {
								$('#bootstrap-multiselect-scope').multiselect({
										nonSelectedText: "Nenhum escopo selecionado",
										allSelectedText: "Todos escopos selecionados",
										numberDisplayed: 2,
										includeSelectAllOption: true,
										selectAllText: " Selecionar todos escopos",
										onChange: function (option, checked, select) {
												vm.changeSelect(option, checked, select);
										}
								});
						});

			},
				function (response) {
					toastService.error({
						message: response.data.message
					});
				});
		};

		vm.goSeachPage = function () {
			$state.go(APP_STATE.MEDIDA.SEARCH, { id: $state.params.id });
		};

		vm.insert = function (element) {
			element.situacao = { key: "A" };
			Medida.save(element).$promise.then(function (data) {
				toastService.success("Medida foi salva com sucesso.");
				vm.goSeachPage();
				$document.ready(function () {
									$('#bootstrap-multiselect-tipo').multiselect({
											nonSelectedText: "Nenhum tipo selecionado",
											allSelectedText: "Todos tipos selecionados",
											numberDisplayed: 7,
											includeSelectAllOption: true,
											selectAllText: " Selecionar todos tipos",
											onChange: function (option, checked, select) {
													vm.changeSelect(option, checked, select);
											}
									});
							});
					$document.ready(function () {
							$('#bootstrap-multiselect-scope').multiselect({
									nonSelectedText: "Nenhum escopo selecionado",
									allSelectedText: "Todos escopos selecionados",
									numberDisplayed: 2,
									includeSelectAllOption: true,
									selectAllText: " Selecionar todos escopos",
									onChange: function (option, checked, select) {
											vm.changeSelect(option, checked, select);
									}
							});
					});
			}, function (response) {
				toastService.error({
					message: response.data.message
				});
			});
		};

		vm.edit = function (element) {
			var medidasPai = [];
			angular.forEach(vm.medidas, function(medida, i) {
				if (medida.id != vm.model.id) {
					medidasPai.push(medida);
				}
				vm.medidas = medidasPai;
			});

			vm.mySwitch = false;
			habilita(vm.model.tipoEscopo.key);
		};

		vm.habitataPai = function () {
			habilita(vm.model.tipoEscopo.key);
		}

		vm.cancel = function () {
			if (vm.refreshSearch) {
				$scope.$emit(APP_EVENT.OPCAO.REFRESH);
			}

			vm.goBack();
		};

		function habilita(element) {
			if (element === "S") {
				vm.mySwitchAnalitico = false;
				vm.model.medidaPai = null;
			} else {
				vm.mySwitchAnalitico = true;
			}
		}
	}

})();
