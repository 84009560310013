(function() {
    'use strict';

    angular.module('app.coletaRubrica').factory('ColetaRubrica', ColetaRubrica);
    ColetaRubrica.$inject = ['$resource', 'APP_PROPERTY'];

    function ColetaRubrica($resource, APP_PROPERTY) {
        return $resource(APP_PROPERTY.URL + '/coletaRubricas/:id', { id: '@id' }, {
            update: {
                method: 'PUT'
            },
            filter: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/coletaRubricas'
            },
            findByStatus: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/coletaRubricas/:status'
            },
            findItens: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/coletaRubricas/:id/itens/:status'
            },
            viewById: {
                method: 'GET',
                url: APP_PROPERTY.URL + '/coletaRubricas/:id/view'
            },
            findByType: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/coletaRubricas/:type'
            },
            saveItens: {
                method: 'POST',
                isArray: true,
                url: APP_PROPERTY.URL + '/coletaRubricas/:id/itens'
            },
            updateItens: {
                method: 'PUT',
                isArray: true,
                url: APP_PROPERTY.URL + '/coletaRubricas/:id/itens'
            },
            findAlvos: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/coletaRubricas/:id/alvos'
            },
            sendEmails: {
                method: 'POST',
                isArray: true,
                url: APP_PROPERTY.URL + '/coletaRubricas/:id/send-emails'
            },
            updateAgenda: {
                method: 'PUT',
                isArray:false,
                url: APP_PROPERTY.URL + '/coletas/agendas/:id'
            }
        });

    }

}());