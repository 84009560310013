/*
jslint
    devel: true, maxerr: 10
*/

/*
global
    required: true
*/

/*
 * Usuário Module
 * @description The main module for the user CRUD and others operations.
 * @author iFactory Solutions Ltda.
 * @version 1.0.0
 * @since 1.0.0
 */
(function() {
    'use strict';

    angular.module('app.usuario', ['app.core', 'ngFlash']).config(config);
    config.$inject = ['$stateProvider', 'APP_STATE']

    function config($stateProvider, APP_STATE) {
        $stateProvider
            .state(APP_STATE.USUARIO.BASE, {
                url: '/usuarios',
                abstract: true,
                views: {
                    'content': {
                        template: '<div ui-view="internal"></div>',
                        controller: 'UsuarioController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state(APP_STATE.USUARIO.SEARCH, {
                url: '/search',
                views: {
                    'internal': {
                        templateUrl: 'usuario/partials/usuario-search.html'
                    }
                },
                data: {
                    permission: "USU:RE",
                    breadCrumb: [{
                            display: "Segurança",
                            active: false
                        },
                        {
                            display: "Usuários",
                            active: true
                        }
                    ],
                    search: {
                        enabled: false
                    }
                }
            })
            .state(APP_STATE.USUARIO.NEW, {
                url: '/new',
                views: {
                    'internal': {
                        templateUrl: 'usuario/partials/usuario-form.html',
                        controller: 'usuarioNewController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    permission: "USU:CR",
                    breadCrumb: [{
                            display: "Segurança",
                            active: false
                        },
                        {
                            display: "Usuários",
                            active: false,
                            stateName: APP_STATE.USUARIO.SEARCH
                        },
                        {
                            display: "Novo",
                            active: true
                        }
                    ],
                    search: {
                        placeHolder: "Digite o nome do usuário",
                        enabled: false
                    }
                }
            })
            .state(APP_STATE.USUARIO.EDIT, {
                url: '/edit/:id',
                views: {
                    'internal': {
                        templateUrl: 'usuario/partials/usuario-form.html',
                        controller: 'usuarioNewController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    permission: "USU:VI",
                    breadCrumb: [{
                            display: "Segurança",
                            active: false
                        },
                        {
                            display: "Usuários",
                            active: false,
                            stateName: APP_STATE.USUARIO.SEARCH
                        },
                        {
                            display: "Edição",
                            active: true
                        }
                    ],
                    search: {
                        placeHolder: "Digite o nome do usuário",
                        enabled: false
                    }
                }
            })
            .state(APP_STATE.USUARIO.PROFILE, {
                url: '/profile',
                views: {
                    'internal': {
                        templateUrl: 'usuario/partials/usuario-profile.html',
                        controller: 'UserProfileController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state(APP_STATE.USUARIO.IMPORT_TEMPLATE, {
                url: '/import_template',
                views: {
                    'internal': {
                        templateUrl: 'usuario/partials/usuario-import_template-form.html',
                        controller: 'usuarioImportTemplateController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    permission: "USU:CR",
                    breadCrumb: [{
                        display: "Parcerias",
                        active: false
                    },
                        {
                            display: "Usuários",
                            active: false,
                            stateName: APP_STATE.USUARIO.SEARCH
                        },
                        {
                            display: "Importar",
                            active: true
                        }
                    ],
                    search: {
                        placeHolder: "Digite o nome do usuário",
                        enabled: false
                    }
                }
            });
    }

}());
