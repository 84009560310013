(function() {
    'use strict';

    angular.module('app.escolas').constant('TIPO_ORGANIZACAO_ESCOLA', [
        {key: "1", value: "Escola única"},
        {key: "2", value: "Escola núcleo"},
        {key: "3", value: "Escola nucleada"},
    ]);

    angular.module('app.escolas').constant('TIPO_AGRUPAMENTO_RESULTADO', [
        {key: "B", value: "Bimestral"},
        {key: "T", value: "Trimestral"}
    ]);

    angular.module('app.escolas').constant('TIPO_REGIME_ENSINO', [
        {key: "R", value: "Regular"},
        {key: "I", value: "Integral"}
    ]);

    angular.module('app.escolas').constant('TIPO_CALENDARIO', [
        {key: "R", value: "Regular"},
        {key: "D", value: "Diferenciado"}
    ]);

}());