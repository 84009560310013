(function(){
    'use strict';

    angular.module('app.localizacao').service('localizacaoService', LocalizacaoService);
    LocalizacaoService.$inject = ['Localizacao', 'STATUS_ENUM'];

    function LocalizacaoService(Localizacao, STATUS_ENUM) {

        this.filter = function(filter) {
            return Localizacao.filter(filter).$promise;            
        };

        this.get = function(id) {
            return Localizacao.get({ id: id }).$promise;
        };

        this.delete = function(programa) {
            return Localizacao.delete({id: programa.id}).$promise;
        };

        this.ativos = function() {
            return Localizacao.ativo();
        };

    }

}());
