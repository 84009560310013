/*
jslint
    devel: true, maxerr: 10
*/

/*
global
    required: true
*/

/*
 * Session Factory for App Core Module
 * @author iFactory Solutions Ltda.
 * @version 1.0.0
 * @since 1.0.0
 */
(function() {
    'use strict';

    angular.module('app.core').factory('SessionManagerFactory', SessionManagerFactory);
    SessionManagerFactory.$inject = ['$window', 'APP_CONFIG'];

    function SessionManagerFactory($window, APP_CONFIG) {
        return {
            add: add,
            get: get,
            removeAll: removeAll
        };

        function add(key, value) {
            if (!key) return;
                        
            var sessionObject = $window.sessionStorage.getItem(APP_CONFIG.STORAGE.NAME) || null;            
            
            if (sessionObject) {
                // TODO É necessário (des)criptografar os dados antes de colocar o storage, por questões de segurança.
                //sessionObject = CryptoJS.AES.decrypt(sessionObject, APP_CONFIG.SECURITY.ENCRYPTION.SALT).toString(CryptoJS.enc.Utf8);
                sessionObject = JSON.parse(sessionObject);
            } else {
                sessionObject = {};
            }
            
            if (value) {
                sessionObject[key] = value;
            } else {
                sessionObject[key] = null;
            }
            
            sessionObject = JSON.stringify(sessionObject)
            // TODO É necessário criptografar os dados antes de colocar o storage, por questões de segurança.
            //sessionObject = CryptoJS.AES.encrypt(sessionObject, APP_CONFIG.SECURITY.ENCRYPTION.SALT).toString();
            
            $window.sessionStorage.setItem(APP_CONFIG.STORAGE.NAME, sessionObject);
        }

        function get(key) {            
            if (!key) return;            
            
            var sessionObject = $window.sessionStorage.getItem(APP_CONFIG.STORAGE.NAME) || null;            
            
            if (sessionObject) {
                // TODO É necessário (des)criptografar os dados antes de colocar o storage, por questões de segurança.
                //sessionObject = CryptoJS.AES.decrypt(sessionObject, APP_CONFIG.SECURITY.SALT).toString(CryptoJS.enc.Utf8);
                sessionObject = JSON.parse(sessionObject);
            } else {
                sessionObject = {};
            }            
            
            if (sessionObject[key]) {
                return sessionObject[key];
            }
            
            return null;
        }

        function removeAll() {
            $window.sessionStorage.removeItem(APP_CONFIG.STORAGE.NAME);
        }
    }

}());
