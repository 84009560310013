(function() {
    'use strict';

    angular.module('app.core').filter('meses', Meses);

    function Meses() {
        return function (input) {
            var mes = parseInt(input) - 1;
            var _meses = [
                {mes: 'Janeiro'},
                {mes: 'Fevereiro'},
                {mes: 'Março'},
                {mes: 'Abril'},
                {mes: 'Maio'},
                {mes: 'Junho'},
                {mes: 'Julho'},
                {mes: 'Agosto'},
                {mes: 'Setembro'},
                {mes: 'Outubro'},
                {mes: 'Novembro'},
                {mes: 'Dezembro'}
            ];            

            if (_meses[mes]) {
                return _meses[mes].mes;
            }

            return;
        };
    }
}());