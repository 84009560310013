(function() {
    'use strict';

    angular.module('app.usuario').factory('UsuarioPerfil', UsuarioPerfil);
    UsuarioPerfil.$inject = ['$resource', 'APP_PROPERTY'];

    function UsuarioPerfil($resource, APP_PROPERTY) {
        return $resource(APP_PROPERTY.URL + '/usuarios/:id/perfil-acesso', { id: '@usuario.id' }, {
            update: {
                method: 'PUT'
            },
            associarPerfil: {
                method: 'POST'
            },
            editarAssociacaoPerfil: {
                method: 'PUT'
            },
            viewPerfil: {
                method: 'GET',
                url: APP_PROPERTY.URL + '/usuarios/:id/perfil-acesso/view'            
            },
            viewPerfilSemId: {
                method: 'GET',
                url: APP_PROPERTY.URL + '/usuarios/perfil-acesso/view'            
            },
            hierarquiasPorPerfil: {
                method: 'GET',
                isArray:true,
                url: APP_PROPERTY.URL + '/perfis-acesso/:id/hierarquias'            
            },
            perfisAcesso: {
                method: 'GET',
                isArray:true,
                url: APP_PROPERTY.URL + '/perfis-acesso/ATIVO'            
            }
        });
    }

}());
