(function() {
    'use strict';

    angular.module('app.etapa').factory('Etapa', Etapa);
    Etapa.$inject = ['$resource', 'APP_PROPERTY'];

    function Etapa($resource, APP_PROPERTY) {
        return $resource(APP_PROPERTY.URL + '/etapa/:id', { id: '@id' }, {
            update: {
                method: 'PUT'
            },
            get: {
                method: 'GET'
            },
            filter: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/etapa'
            },
            ativo: {
                method: 'GET',
                url: APP_PROPERTY.URL + '/etapa/ATIVO',
                isArray: true
            },
            findByStatus: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/etapa/:status'
            }
        });

    }

}());