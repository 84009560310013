(function() {
    'use strict';

    angular.module('app.coletaRubrica.resposta').controller('ColetaRespostaController', ColetaRespostaController);
    ColetaRespostaController.$inject = ['APP_STATE', 'COLETA_RESPOSTA', 'APP_EVENT', '$q', '$state', '$scope',
        'coletaRespostaService', 'coletaService', 'ufService', 'municipiosService',
        'escolasService', 'turmasService', 'professorService', 'alunoService', 'objectService', '$window',
        'toastService', 'dialogService', 'coletaRespostaSolicitacaoService', 'scrollService', 
        'coletaRespostaProcessamentoService', 'coletaRespostaProcessamentoLogService', 'VALIDATION_PATTERN', 
        'COLETA_RESPOSTA_MESSAGES'
    ];

    function ColetaRespostaController(APP_STATE, COLETA_RESPOSTA, APP_EVENT, $q, $state, $scope,
        coletaRespostaService, coletaService, ufService, municipiosService,
        escolasService, turmasService, professorService, alunoService, objectService, $window,
        toastService, dialogService, coletaRespostaSolicitacaoService, scrollService,
        coletaRespostaProcessamentoService, coletaRespostaProcessamentoLogService, VALIDATION_PATTERN,
        COLETA_RESPOSTA_MESSAGES) {

        var vm = this;
        var msgs = COLETA_RESPOSTA_MESSAGES;
        var MODAL_FORM_UPLOAD = 'coleta/resposta/autenticada/partials/form-upload.html';
        var MODAL_IMPORT_ERROR_LIST = 'coleta/resposta/autenticada/partials/importacao-error-list.html';

        vm.goBack = function() {
            $state.go(APP_STATE.COLETA.RESPOSTA.SEARCH);
        };

        vm.cancelColeta = function() {
            if (vm.refreshSearch) {
                $scope.$emit(APP_EVENT.COLETA.REFRESH);
            }

            vm.goBack();
        };

        var _resetRespostasDependencies = function() {
            vm.respostas = [];
            vm.respostasTotal = 0;
            vm.currentPageRespostas = 0;
            vm.selectedAlvo = null;
        };

        var _resetAutocompletes = function() {
            delete vm.filterAluno;
            delete vm.filterProfessor;
        };

        var _findAlvosByFilters = function(filter) {
            filter.idPrograma = vm.coleta.programa.id;
            
            coletaService.findAlvosByIdAndFilters(vm.coleta.id, filter)
                .then(function(result) {
                    vm.coletasAlvo = result;
                    vm.coletasAlvoTotal = result.length;

                    scrollService.scrollTo('coleta-tabela-alvo');
                }).finally(function() {
                    vm.searchWasPerformed = true;
                });
        };

        var _findRespostasByFilters = function(filter) {
            coletaRespostaService.findByColetaIdAndFilters(vm.coleta.id, filter)
                .then(function(result) {
                    vm.respostas = result;
                    vm.respostasTotal = result.length;

                    scrollService.scrollTo('coleta-tabela-resposta');
                })
                .finally(function() {
                    vm.searchForm.$setPristine();
                });
        };

        var _mountFilter = function(nome) {
            if (nome) {
                var filter = angular.copy(vm.filter);
                filter.nome = nome;

                return filter;
            } else {
                return null;
            }
        };

        var _populateDependencies = function() {
            var idPrograma = vm.coleta.programa.id;
            ufService.withPermissionFilter({idPrograma:idPrograma}).then(function(response){
                vm.ufs = response;
            });

            coletaRespostaService.findStatus().then(function(status) {
                vm.respostaSituacoes = status;
            });
        };

        vm.changeUf = function() {
            if (vm.filter.idUf) {
                var idPrograma = vm.coleta.programa.id;
                municipiosService.withPermissionFilter({idUf: vm.filter.idUf, idPrograma:idPrograma}).then(function(response){
                    vm.municipios = response;
                });    
            } else {
                vm.filter = {};
                vm.municipios = [];
            }
        };

        vm.changeMunicipio = function() {
            if (vm.filter.idMunicipio) {
                var programas = [];
                programas.push(vm.coleta.programa.id);
                escolasService.searchByWithPermission({situacao:"A",idMunicipio:vm.filter.idMunicipio, programas:programas}).then(function(escolas) {
                        vm.escolas = escolas;
                    });
            } else {
                vm.escolas = [];
                delete vm.filter.idEscola;
                _resetAutocompletes();
            }
        };

        vm.changeEscola = function() {
            if (vm.filter.idEscola) {
                turmasService.withPermissionFilter({idEscola: vm.filter.idEscola, idPrograma: vm.coleta.programa.id}).then(function(turmas) {
                        vm.turmas = turmas;
                    });
            } else {
                vm.turmas = [];
                _resetAutocompletes();
            }
        };

        vm.filterAlunos = function(value) {
            return $q(function(resolve, reject) {
                var filter = _mountFilter(value);

                if (filter) {
                    alunoService.filterAtivos(filter).then(function(alunos) {
                        resolve(alunos);
                    }, function(error) {
                        reject(error);
                    });
                } else {
                    resolve([]);
                }
            });
        };

        vm.filterProfessores = function(value) {
            return $q(function(resolve, reject) {
                var filter = _mountFilter(value);

                if (filter) {
                    professorService.filterAtivos(filter).then(function(professores) {
                        resolve(professores);
                    }, function(error) {
                        reject(error);
                    });
                } else {
                    resolve([]);
                }
            });
        };

        vm.onPageChangeAlvo = function(page, size) {
            //vm.filterToPaginate.page = page;
            //vm.filterToPaginate.size = size;
            _findAlvosByFilters(vm.filterToPaginate);
        };

        vm.findAlvos = function() {
            if (vm.searchForm.$valid) {
                vm.filter.page = 0;
                vm.filter.size = vm.ITENS_PER_PAGE;

                if (vm.filterAluno) {
                    vm.filter.idAluno = vm.filterAluno.id;
                } else {
                    delete vm.filter.idAluno;
                }

                if (vm.filterProfessor) {
                    vm.filter.idProfessor = vm.filterProfessor.id;
                } else {
                    delete vm.filter.idProfessor;
                }

                vm.filterToPaginate = angular.copy(vm.filter);
                vm.searchWasPerformed = false;
                _resetRespostasDependencies();

                _findAlvosByFilters(vm.filter);
            }
        };

        vm.getFieldValue = function(item, field) {
            if (is.existy(item)) {
                return objectService.deepValueFormated(item, field.name, field.type);
            } else {
                return null;
            }
        };

        vm.sortTableAlvo = function(fieldName) {
            vm.sortTypeAlvo = fieldName;
            vm.sortReverseAlvo = !vm.sortReverseAlvo;
        };

        vm.sortTableResposta = function(fieldName) {
            vm.sortTypeResposta = fieldName;
            vm.sortReverseResposta = !vm.sortReverseResposta;
        };

        vm.showRespostas = function(alvo) {
            if (is.not.empty(alvo)) {
                vm.selectedAlvo = alvo;

                var filter = {
                    idColetaAlvo: alvo.id,
                    page: 0,
                    size: vm.ITENS_PER_PAGE
                };

                vm.filterRespostaToPaginate = angular.copy(filter);

                _findRespostasByFilters(filter);
            } else {
                _resetRespostasDependencies();
            }
        };

        vm.onPageChangeRespostas = function(page, size) {
            vm.filterRespostaToPaginate.page = page;
            vm.filterRespostaToPaginate.size = size;
            _findRespostasByFilters(vm.filterRespostaToPaginate);
        };

        vm.getSituacaoCssClass = function(situacao) {
            return COLETA_RESPOSTA.SITUACAO_CSS_CLASS[situacao];
        };

        vm.goEditPage = function(resposta) {
            $state.go(APP_STATE.RESPOSTA_COLETA.EDIT, {
                id: resposta.id
            });
        };

        vm.importResposta = function(resposta) {
            var modalImportMustBeUpdated = false;
            var canImport = coletaRespostaService.canImport(resposta.situacao);

            if (!canImport) {
                var allowImport = coletaRespostaService.getSituacoesAllowImport();
                var cannotImportMsg = msgs.error.cannotImport + allowImport.join(", ") + ".";

                dialogService.info(cannotImportMsg, msgs.info.importTitle);
                return;
            }

            var modalImport = dialogService.modal(MODAL_FORM_UPLOAD, function($uibModalInstance) {
                var _vm = this;

                _vm.ok = function(file) {
                    if (_vm.formUpload.$valid) {
                        dialogService.confirm(msgs.warning.deleteResponses, msgs.info.importTitle)
                            .then(function () {
                                _vm.success = null;
                                _vm.error = null;

                                coletaRespostaService.import(resposta.id, file)
                                    .then(function (response) {
                                        _vm.success = msgs.success.import;
                                        modalImportMustBeUpdated = true;
                                    })
                                    .catch(function (error) {
                                        _vm.error = error;
                                    });
                            });
                    }
                }

                _vm.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

                _vm.showErrors = function(error) {
                    dialogService.modal(MODAL_IMPORT_ERROR_LIST, function($uibModalInstance) {
                        this.formConstraints = error.formConstraints;
                        this.messages = error.messages;

                        this.cancel = function() {
                            $uibModalInstance.dismiss('cancel');
                        };
                    });
                };

                _vm.onChangeFile = function() {
                    _vm.success = null;
                    _vm.error = null;
                };
            });

            modalImport.closed
                .then(function() {
                    if (modalImportMustBeUpdated) {
                        $scope.$emit(APP_EVENT.RESPOSTA_COLETA.REFRESH);
                    }
                });
        };

        vm.exportColeta = function(idColetaResposta) {
            coletaRespostaService.export(idColetaResposta)
                .then(function() {
                    toastService.success(msgs.success.export);
                })
                .catch(function(error) {
                    toastService.error(error);
                });
        };

        vm.findRespostaBySituacao = function() {
            vm.filterRespostaToPaginate.page = 0;

            if (vm.situacaoResposta) {
                vm.filterRespostaToPaginate.situacao = vm.situacaoResposta.key;
            } else {
                delete vm.filterRespostaToPaginate.situacao;
            }

            _findRespostasByFilters(vm.filterRespostaToPaginate);
        };

        (function() {
            if ($state.params.coleta) {
                vm.coleta = $state.params.coleta;

                vm.sortTypeAlvo = '';
                vm.sortReverseAlvo = false;
                vm.sortTypeResposta = '';
                vm.sortReverseResposta = false;
                vm.respostas = [];
                vm.respostasTotal = 0;
                vm.coletasAlvo = [];
                vm.coletasAlvoTotal = 0;
                vm.searchWasPerformed = false;
                vm.selectedAlvo = null;

                vm.filter = {};
                vm.filterToPaginate = {};
                vm.filterRespostaToPaginate = {};
                vm.ITENS_PER_PAGE = 10;

                var granularidade = vm.coleta.granularidade.key;

                vm.tableRespostaFields = COLETA_RESPOSTA.TABLE_FIELDS.RESPOSTA;
                vm.tableFields = COLETA_RESPOSTA.TABLE_FIELDS.ALVO[granularidade];

                vm.hasEscola = is.inArray(granularidade, ['A', 'P', 'T', 'E']);
                vm.hasTurma = is.inArray(granularidade, ['A', 'P', 'T']);
                vm.hasAluno = granularidade == 'A';
                vm.hasProfessor = granularidade == 'P';
                vm.escolaRequired = is.inArray(granularidade, ['A', 'P']);

                _populateDependencies();

                $scope.$on(APP_EVENT.RESPOSTA_COLETA.REFRESH, function() {
                    _findRespostasByFilters(vm.filterRespostaToPaginate);
                });

            } else {
                $state.go(APP_STATE.COLETA.SEARCH);
            }
        }());


        vm.oneAtATime = true;

        var MODAL_RESPOSTA_ABRIR_SOLICITACAO = 'coleta/resposta/autenticada/partials/coleta-resposta-solicitacao-abrir-modal.html';
        var MODAL_RESPOSTA_ANALISAR_SOLICITACAO = 'coleta/resposta/autenticada/partials/coleta-resposta-solicitacao-analisar-modal.html';
        var MODAL_RESPOSTA_RELATORIO = 'coleta/resposta/autenticada/partials/coleta-resposta-solicitacao-relatorio-modal.html';

        vm.abrirSolicitacao = function(resposta) {
            dialogService.modal(MODAL_RESPOSTA_ABRIR_SOLICITACAO, function($uibModalInstance) {
                var _vm = this;

                _vm.refreshIsNeeded = false;

                _vm.solicitacao = {
                    coletaResposta: {
                        id: resposta.id
                    }
                };

                _vm.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                    if (_vm.refreshIsNeeded) {
                        $scope.$emit(APP_EVENT.RESPOSTA_COLETA.REFRESH);
                    }
                };

                _vm.tipoSolicitacaoRespostaAberta = coletaRespostaSolicitacaoService.findByColetaSolicitacaoId(resposta.id);

                //Tratamento para não exibir o form de abertura de solicitação quando existir solicitações pendentes
                _vm.tipoSolicitacaoRespostaAberta.$promise.then(function(response){
                    _vm.canOpenSolicitacao = is.inArray(resposta.situacao.key, ['3', '4', '5', 'B']);
                    if (_vm.canOpenSolicitacao){
                        _vm.canOpenSolicitacao = _.filter(response, function(solicitacao) {
                            return solicitacao.situacao.key == 'P';
                        }).length == 0;    
                    }
                    _vm.statusAbrir = is.falsy(_vm.canOpenSolicitacao);
                });

                _vm.tipoSolicitacaoResposta = coletaRespostaSolicitacaoService.findByTipos();

                _vm.validacaoFormularioSolicitacao = function() {
                    var selectedTipoSolicitacaoResposta = _.map(
                        _.filter(_vm.tipoSolicitacaoResposta, function(element) {
                            return element.id == _vm.solicitacao.tipoColetaResposta.key;
                        }),
                        function(element) {
                            return {
                                key: element.id,
                                value: element.name
                            };
                        }
                    );

                    if (selectedTipoSolicitacaoResposta && _.isArray(selectedTipoSolicitacaoResposta)) {
                        _vm.solicitacao.tipoColetaResposta = selectedTipoSolicitacaoResposta[0];
                    }

                    var obj = coletaRespostaSolicitacaoService.save(_vm.solicitacao);
                    obj.$promise.then(function(response) {
                        toastService.success("Solicitação realizada com sucesso.", 'flash-modal');
                        _vm.coletaRespostaSolicitacaoForm.$setPristine();
                        _vm.canOpenSolicitacao = false;
                        _vm.statusAbrir = false;

                        _vm.refreshIsNeeded = true;
                        _vm.tipoSolicitacaoRespostaAberta = coletaRespostaSolicitacaoService.findByColetaSolicitacaoId(resposta.id);
                        _vm.solicitacao.justificativaColetaRespostaSolicitacao = "";
                        _vm.tipoSolicitacaoResposta = coletaRespostaSolicitacaoService.findByTipos();
                    }, function(response) {
                        toastService.error(response, 'flash-modal');
                    });
                };

                _vm.filterNotPending = function(request) {
                    return request.situacao.key != 'P';
                }

                _vm.filterPending = function(request) {
                    return request.situacao.key == 'P';
                }
            }).result.then(new Function(), new Function());
        }

        vm.relatorioSolicitacao = function(resposta) {
            dialogService.modal(MODAL_RESPOSTA_RELATORIO, function($uibModalInstance) {
                var _vm = this;
                _vm.relatorio = {
                    coletaResposta: {
                        id: resposta.id
                    }
                };

                _vm.cancel = function() {
                    $uibModalInstance.dismiss('cancel');

                };

                coletaRespostaProcessamentoService.findByColetaRespostaId(resposta.id).then(function(data) {
                    _vm.coletaProcessamentoRelatorio = data;

                    if (is.existy(data) && is.not.empty(data)) {
                        _vm.coletaProcessamentoRelatorioLog = coletaRespostaProcessamentoLogService.findByColetaRespostaProcessamentoId(_vm.coletaProcessamentoRelatorio[0].idColetaRespostaProcessamento);
                    }

                }, function(error) {

                });

                _vm.popularLog = function(valor) {
                    coletaRespostaProcessamentoLogService.findByColetaRespostaProcessamentoId(valor).then(function(data) {
                        _vm.coletaProcessamentoRelatorioLog = data;
                    });

                }
            });
        }

        vm.analisarSolicitacao = function(resposta) {
            dialogService.modal(MODAL_RESPOSTA_ANALISAR_SOLICITACAO, function($uibModalInstance) {
                var _vm = this;

                _vm.VALIDATION_PATTERN = VALIDATION_PATTERN;
                _vm.refreshIsNeeded = false;

                var valorAguardo = {
                    key: "P",
                    value: "Pendente"
                };

                _vm.solicitacao = {
                    coletaResposta: {
                        id: resposta.id
                    }
                };

                _vm.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                    if (_vm.refreshIsNeeded) {
                        $scope.$emit(APP_EVENT.RESPOSTA_COLETA.REFRESH);
                    }
                };

                _vm.tipoSolicitacaoRespostaAnalise = coletaRespostaSolicitacaoService.findByColetaSolicitacaoAnaliseId(resposta.id, valorAguardo);
                _vm.tipoSolicitacaoResposta = coletaRespostaSolicitacaoService.findByTipos();

                _vm.validacaoFormularioAnalise = function(form, solicitacaoResposta) {
                    solicitacaoResposta = angular.copy(solicitacaoResposta);
                    if (form.$invalid) {
                        return false;
                    }

                    if (solicitacaoResposta.autorizacao == 1) {
                        var dataLimiteInicioColetaResposta = moment(solicitacaoResposta.dataLimiteInicioColetaResposta, 'DD/MM/YYYY').format('YYYY-MM-DD');
                        var dataLimiteAlteracaoColetaResposta = moment(solicitacaoResposta.dataLimiteAlteracaoColetaResposta, 'DD/MM/YYYY').format('YYYY-MM-DD');

                        if (dataLimiteInicioColetaResposta >= dataLimiteAlteracaoColetaResposta) {
                            _vm.errosDeDatas = true;
                        } else {
                            var valor = {
                                key: "A",
                                value: "Aprovado"
                            }
                            solicitacaoResposta.situacao = valor;
                            var obj = coletaRespostaSolicitacaoService.update(solicitacaoResposta.id, solicitacaoResposta);
                            obj.$promise.then(function(response) {
                                _vm.refreshIsNeeded = true;
                                _vm.tipoSolicitacaoRespostaAnalise = coletaRespostaSolicitacaoService.findByColetaSolicitacaoAnaliseId(resposta.id);
                                _vm.errosDeDatas = true;
                            });
                        }
                        solicitacaoResposta.dataLimiteInicioColetaResposta = dataLimiteInicioColetaResposta;
                        solicitacaoResposta.dataLimiteAlteracaoColetaResposta = dataLimiteAlteracaoColetaResposta;
                    } else {
                        var valor = {
                            key: "N",
                            value: "Negado"
                        }
                        solicitacaoResposta.situacao = valor;

                        var obj = coletaRespostaSolicitacaoService.update(solicitacaoResposta.id, solicitacaoResposta);
                        obj.$promise.then(function(response) {
                            _vm.refreshIsNeeded = true;
                            _vm.tipoSolicitacaoRespostaAnalise = coletaRespostaSolicitacaoService.findByColetaSolicitacaoAnaliseId(resposta.id);
                        });
                    }
                };

                _vm.clearValue = function(solicitacaParaAnalise) {
                    if (solicitacaParaAnalise.autorizacao == "0") {
                        _vm.solicitacao.dataInicioColeta = "";
                        _vm.solicitacao.dataFimColeta = "";
                        solicitacaParaAnalise.dataLimiteInicioColetaResposta = null;
                        solicitacaParaAnalise.dataLimiteAlteracaoColetaResposta = null;
                    }
                };

                _vm.filterPending = function(request) {
                    return request.situacao.key == 'P';
                }

                _vm.filterNotPending = function(request) {
                    return request.situacao.key != 'P';
                }

                //============================= calendario =======================================

                _vm.inlineOptions = {
                    customClass: getDayClass,
                    minDate: new Date(),
                    showWeeks: true
                };

                _vm.dateOptions = {
                    dateDisabled: disabled,
                    formatYear: 'yy',
                    maxDate: new Date(2020, 5, 22),
                    minDate: new Date(),
                    startingDay: 1
                };

                // Disable weekend selection
                function disabled(data) {
                    var date = data.date,
                        mode = data.mode;
                    return mode === 'day' && (date.getDay() === 0 || date.getDay() === 6);
                }

                vm.formats = ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
                vm.format = vm.formats[0];
                vm.altInputFormats = ['M!/d!/yyyy'];

                var tomorrow = new Date();
                tomorrow.setDate(tomorrow.getDate() + 1);
                var afterTomorrow = new Date();
                afterTomorrow.setDate(tomorrow.getDate() + 1);
                vm.events = [{
                        date: tomorrow,
                        status: 'full'
                    },
                    {
                        date: afterTomorrow,
                        status: 'partially'
                    }
                ];

                function getDayClass(data) {
                    var date = data.date,
                        mode = data.mode;
                    if (mode === 'day') {
                        var dayToCheck = new Date(date).setHours(0, 0, 0, 0);

                        for (var i = 0; i < $scope.events.length; i++) {
                            var currentDay = new Date($scope.events[i].date).setHours(0, 0, 0, 0);

                            if (dayToCheck === currentDay) {
                                return $scope.events[i].status;
                            }
                        }
                    }

                    return '';
                }
                _vm.open1 = function() {
                    _vm.popup1.opened = true;
                };

                _vm.open2 = function() {
                    _vm.popup2.opened = true;
                };

                _vm.popup1 = {
                    opened: false
                };

                _vm.popup2 = {
                    opened: false
                };

                //=============== end calendario ==========================
            }).result.then(new Function(), new Function());
        };
    }

}());