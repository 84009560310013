(function() {
    'use strict';

    angular.module('app.escolas').controller('EscolasController', EscolasController);
    EscolasController.$inject = ['APP_STATE', 'APP_EVENT', 'SEARCH_BY_FIELD', '$state', '$scope','$uibModal', 
        'DialogService', 'escolasService', 'toastService', 'dialogService', 'SecurityManagerFactory'];

    function EscolasController(APP_STATE, APP_EVENT, SEARCH_BY_FIELD, $state, $scope, $uibModal, 
        DialogService, escolasService, toastService, dialogService, SecurityManagerFactory) {

        var vm = this;

        vm.goNewPage = function() {
            $state.go(APP_STATE.ESCOLA.NEW);
        };

        vm.goEditPage = function(id) {
            $state.go(APP_STATE.ESCOLA.EDIT, { id: id });
        };

        vm.clearSearchValue = function() {
            vm.searchValue = "";
            vm.searchValueModel = "";
        };

        var _findEscolasByFilters = function (filter) {
            filter.associacao = true;
            
             escolasService.searchBy(filter)
                .then(function (result) {
                    vm.escolas = result.content;
                    vm.escolasTotal = result.totalElements;
                    vm.searchWasPerformed = true;
                })
                .finally(function() {
                    vm.searchWasPerformed = true;
                    vm.formSearch.$setPristine();
                });
        };

        vm.search = function() {
            vm.escolas = [];
            vm.currentPage = 1;
            vm.escolasTotal = -1;
            vm.searchWasPerformed = false;
            vm.searchValue = vm.searchValueModel || vm.searchValue;

            if (!vm.searchValueModel) {
                vm.searchValueModel = vm.searchValue;
            }

            if (vm.searchValue) {
                var filter = {};
                filter[vm.searchType.field] = vm.searchValue;

                filter.page = 0;
                filter.size = 10;

                vm.filterEscolaToPaginate = angular.copy(filter);

                _findEscolasByFilters(filter);
            }
        };

        vm.onPageChangeEscolas = function (page, size) {
            vm.filterEscolaToPaginate.page = page;
            vm.filterEscolaToPaginate.size = size;
            _findEscolasByFilters(vm.filterEscolaToPaginate);
        };

        vm.verAtributos = function(escolaId) {
            DialogService.modal(
                'escolas/partials/escolas-search-modal-atributos.html',
                function($uibModalInstance){
                    var _vm = this;

                    _vm.cancelar = function() {
                        $uibModalInstance.dismiss('cancel');
                    };
                }
            );        
        }

        vm.verEscolasNucleadas = function(escolaId) {
            DialogService.modal(
                'escolas/partials/escolas-search-modal-nucleadas.html',
                function($uibModalInstance){
                    var _vm = this;

                    escolasService.get(escolaId).then(function (response) {
						_vm.escolasAssociadas = response.escolasAssociadas;
					}, function (response) {
                        _vm.escolasAssociadas = [];
                    });

                    _vm.cancelar = function() {
                        $uibModalInstance.dismiss('cancel');
                    };
                }
            );        
        }

        vm.verProgramas = function(escolaId) {
            DialogService.modal(
                'escolas/partials/escolas-search-modal-programas.html',
                function($uibModalInstance){
                    var _vm = this;

                    escolasService.get(escolaId).then(function (response) {
						_vm.programas = response.escolaProgramasExternos;
					}, function (response) {
                        _vm.programas = [];
                    });

                    _vm.cancelar = function() {
                        $uibModalInstance.dismiss('cancel');
                    };
                }
            );        
        }

        vm.delete = function(escola) {
            dialogService.delete().then(function() {
                escolasService.delete({id: escola.id}).then(function(response) {
                        if (response.responseStatusCode == 200) {
                            toastService.success("Registro removido com sucesso.");
                        } else if (response.responseStatusCode == 202) {
                            toastService.success("Registro inativado com sucesso.");
                        }
                    vm.search();
                }, function(response) {
                    toastService.error(response);
                });
            }, function() {});
        };

        (function() {
            vm.hasPermissionView = SecurityManagerFactory.hasPermission('ESC:VI');
            vm.escolas = [];
            vm.searchValue = "";
            vm.searchValueModel = "";
            vm.SEARCH_BY = SEARCH_BY_FIELD;
            vm.searchType = vm.SEARCH_BY.NAME;
            vm.searchWasPerformed = false;
            vm.filterEscolaToPaginate = {};

            $scope.$on(APP_EVENT.ESCOLA.SAVED, function() {
                toastService.success("Operação realizada com sucesso.");
                vm.search();
            });

            $scope.$on(APP_EVENT.ESCOLA.REFRESH, function() {
                vm.search();
            });

        }());

    }

}());
