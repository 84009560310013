(function() {
    'use strict';

    angular.module('app.core').constant('TRANSLATE_PTBR', {
        SAVO_SUCESSO: 'Os dados foram salvo com sucesso.',
        ERRO_SALVAR: 'Ocorreu um erro ao salvar.',
        TOTAL_REGISTRO: 'Total de resultados: <b>{?}</b>.',
        NENHUM_REGISTRO: 'Nenhum resultado encontrado.',
        CAMPO_PESQUISA_OBRIGATORIO: 'O campo {?} é obrigatório para a pesquisa.',
        PESQUISA_MINIMA: 'A pesquisa por nome deve conter ao menos três caracteres.',
        
        // Formulário
        FORMULARIO_ADD_NOVO: 'Deseja adicionar um novo formulário?'

        // Escala

        // etc
    });

}());
