/*
jslint
    devel: true, maxerr: 10
*/

/*
global
    required: true
*/

/*
 * Relatórios Module
 * @module constants for class profile report
 * @author iFactory Solutions Ltda.
 * @version 1.0.0
 * @since 1.0.0
 */
(function() {
    'use strict';

    /* Municípios por programa */
    angular.module('app.relatorios').constant('RESULTADO_BIMESTRAL_REPORT_ENSINO_MEDIO_GESTAO_NOTA_10_FIELDS', []);

    /* Fórmula da Virotia */
    angular.module('app.relatorios').constant('RESULTADO_BIMESTRAL_REPORT_FORMULA_VITORIA_FIELDS', [
        { name: 'id', type: 'integer', calculateTotal: false, showFooter: false },
        { name: 'detalhamento', type: 'string', calculateTotal: false, showFooter: false },

        { name: 'numeroAlunos', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'portuguesAM', type: 'integer', calculateTotal: true, calculateType: 'sum' }, 
        { name: 'portuguesAMPercentual', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'portuguesAM', referenceTotalField: 'numeroAlunos', percent: true },
        { name: 'portuguesAMFaltas', type: 'integer', calculateTotal: true, calculateType: 'sum' }, 
        { name: 'matematicaAM', type: 'integer', calculateTotal: true, calculateType: 'sum' }, 
        { name: 'matematicaAMPercentual', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'matematicaAM', referenceTotalField: 'numeroAlunos', percent: true },
        { name: 'matematicaAMFaltas', type: 'integer', calculateTotal: true, calculateType: 'sum' }, 
        { name: 'cienciasAM', type: 'integer', calculateTotal: true, calculateType: 'sum' }, 
        { name: 'cienciasAMPercentual', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'cienciasAM', referenceTotalField: 'numeroAlunos', percent: true },
        { name: 'cienciasAMFaltas', type: 'integer', calculateTotal: true, calculateType: 'sum' }, 
        { name: 'historiaAM', type: 'integer', calculateTotal: true, calculateType: 'sum' }, 
        { name: 'historiaAMPercentual', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'historiaAM', referenceTotalField: 'numeroAlunos', percent: true },
        { name: 'historiaAMFaltas', type: 'integer', calculateTotal: true, calculateType: 'sum' }, 
        { name: 'geografiaAM', type: 'integer', calculateTotal: true, calculateType: 'sum' }, 
        { name: 'geografiaAMPercentual', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'geografiaAM', referenceTotalField: 'numeroAlunos', percent: true },
        { name: 'geografiaAMFaltas', type: 'integer', calculateTotal: true, calculateType: 'sum' } 

    ]);

    angular.module('app.relatorios').constant('RESULTADO_BIMESTRAL_REPORT_ENSINO_REGULAR_FIELDS', [
        { name: 'id', type: 'integer', calculateTotal: false, showFooter: false },
        { name: 'detalhamento', type: 'string', calculateTotal: false, showFooter: false },

        { name: 'alunosTodos', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'alunosColetados', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'portuguesAM', type: 'integer', calculateTotal: true, calculateType: 'sum' }, 
        { name: 'portuguesAMPercentual', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'portuguesAM', referenceTotalField: 'alunosColetados', percent: true },
        { name: 'matematicaAM', type: 'integer', calculateTotal: true, calculateType: 'sum' }, 
        { name: 'matematicaAMPercentual', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'matematicaAM', referenceTotalField: 'alunosColetados', percent: true },
        { name: 'cienciasAM', type: 'integer', calculateTotal: true, calculateType: 'sum' }, 
        { name: 'cienciasAMPercentual', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'cienciasAM', referenceTotalField: 'alunosColetados', percent: true },
        { name: 'historiaAM', type: 'integer', calculateTotal: true, calculateType: 'sum' }, 
        { name: 'historiaAMPercentual', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'historiaAM', referenceTotalField: 'alunosColetados', percent: true },
        { name: 'geografiaAM', type: 'integer', calculateTotal: true, calculateType: 'sum' }, 
        { name: 'geografiaAMPercentual', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'geografiaAM', referenceTotalField: 'alunosColetados', percent: true }

    ]);
}());