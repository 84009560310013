(function() {
    'use strict';

    angular.module('app.grupoMunicipio', ['app.core']).config(config);
    config.$inject = ['$stateProvider', 'APP_STATE'];

    function config($stateProvider, APP_STATE) {
        $stateProvider
            .state(APP_STATE.GRUPO_MUNICIPIO.BASE, {
                url: '/grupoMunicipio',
                abstract: true,
                views: {
                    'content': {
                        template: '<div ui-view="internal"></div>',
                        controller: 'GrupoMunicipioController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state(APP_STATE.GRUPO_MUNICIPIO.SEARCH, {
                url: '/search',
                views: {
                    'internal': {
                        templateUrl: 'grupo-municipio/partials/grupo-municipio-search.html'
                    }
                },
                data: {
                    permission: "GRM:RE",
                    breadCrumb: [{
                            display: "Grupo Município",
                            active: false
                        },
                        {
                            display: "Cadastrar Grupo Município",
                            active: true
                        }
                    ],
                    search: {
                        enabled: false
                    }
                }
            })
            .state(APP_STATE.GRUPO_MUNICIPIO.NEW, {
                url: '/new',
                views: {
                    'internal': {
                        templateUrl: 'grupo-municipio/partials/grupo-municipio-form.html',
                        controller: 'GrupoMunicipioFormController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    grupoMunicipio: null
                },
                data: {
                    permission: "GRM:CR",
                    breadCrumb: [{
                            display: "Grupo Município",
                            active: false
                        },
                        {
                            display: "Cadastrar Grupo Município",
                            active: false,
                            stateName: APP_STATE.GRUPO_MUNICIPIO.SEARCH
                        },
                        {
                            display: "Nova",
                            active: true
                        }
                    ],
                    search: {
                        placeHolder: "Digite o nome do grupo município",
                        enabled: false
                    }
                }
            })
            .state(APP_STATE.GRUPO_MUNICIPIO.EDIT, {
                url: '/edit/:id',
                views: {
                    'internal': {
                        templateUrl: 'grupo-municipio/partials/grupo-municipio-form.html',
                        controller: 'GrupoMunicipioFormController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    editMode: null
                },
                data: {
                    permission: "GRM:VI",
                    breadCrumb: [{
                            display: "Grupo Município",
                            active: false
                        },
                        {
                            display: "Cadastrar Grupo Município",
                            active: false,
                            stateName: APP_STATE.GRUPO_MUNICIPIO.SEARCH
                        },
                        {
                            display: "Edição",
                            active: true
                        }
                    ],
                    search: {
                        placeHolder: "Digite o nome do grupo município",
                        enabled: false
                    }
                }
            })
    }
}());