/*
jslint
    devel: true, maxerr: 10
*/

/*
global
    required: true
*/

/*
 * Perfil de Acesso View Controller
 * @module Perfil de Acesso
 * @author iFactory Solutions Ltda.
 * @version 1.0.0
 * @since 1.0.0
 */
(function() {
    'use strict';

    angular.module('app.perfilAcesso').controller('PerfilAcessoViewController', PerfilAcessoViewController);
    PerfilAcessoViewController.$inject = [
        'APP_STATE', 'TABLE_FIELDS', 'APP_EVENT', 'COMMON_STATUS_LIST',
        '$state', '$scope', 'dialogService', 'toastService', 'PerfilAcessoService', '$uibModal', 'FuncionalidadeSistemaService'
    ];

    function PerfilAcessoViewController(APP_STATE, TABLE_FIELDS, APP_EVENT, COMMON_STATUS_LIST,
        $state, $scope, dialogService, toastService, service, $uibModal, funcionalidadeService) {

        var vm = this;

        vm.goBack = function() {
            $state.go(APP_STATE.PERFIL_ACESSO.SEARCH);
        };

        vm.enableEditMode = function() {
            vm.isInEditMode = true;
            vm.view.isInEditMode = true;
        };

        vm.disableEditMode = function() {
            vm.isInEditMode = false;
            vm.view.isInEditMode = false;
        };

        vm.cancel = function() {
            if (vm.refreshSearch) {
                $scope.$emit(APP_EVENT.PERFIL_ACESSO.REFRESH);
            }

            vm.goBack();
        };

        // Initializing fields for viewing, editing or creating.
        (function() {
            vm.isInEditMode = false;
            vm.refreshSearch = false;
            vm.defaultStatus = COMMON_STATUS_LIST[0];
            vm.view = {
                isInEditMode: false,
                isInEditPage: false,
                accordion: {
                    isCloseOthers: false,
                    items: []
                },
                partitionActions: {},
                accessProfiles: {}
            };
            vm.model = {
                id: null,
                nome: null,
                descricao: null,
                situacao: vm.defaultStatus,
                perfilAcessoPai: null,
                assignedFeatures: [],
                assignedActions: {},
                createdAssignedFeatures: [],
                changedAssignedFeatures: [],
                deletedAssignedFeatures: []
            };

            if ($state.is(APP_STATE.PERFIL_ACESSO.EDIT)) {
                vm.view = _.extend(vm.view, { isInEditPage: true });

                if ($state.params.id) {
                    service.getForView($state.params.id)
                        .then(function(response) {
                            vm.model = _.extend(vm.model, response.model);
                            vm.model.assignedFeatures = vm.sortFeatures(vm.model.assignedFeatures);
                            vm.model.assignedActions = vm.extractAllAssignedActions(vm.model.assignedFeatures);
                            vm.view.partitionActions = vm.partitionAllActions(vm.model.assignedFeatures);
                            vm.view.accessProfiles = angular.copy(response.view.perfisAcesso);
                            if (is.existy(vm.model.perfilAcessoPai)) {
                                // Os perfis de acesso exibidos são os ativos, caso o perfil de acesso pai esteja inativo, não será encontrado
                                // então será incluído de volta.
                                var index = _.findIndex(vm.view.accessProfiles, function(element) {
                                    return element.id == vm.model.perfilAcessoPai.id;
                                });
                                if (index < 0) {
                                    vm.view.accessProfiles.push(vm.model.perfilAcessoPai);
                                    vm.view.accessProfiles = _.sortBy(vm.view.accessProfiles, function(element) {
                                        return element.nome;
                                    });
                                }
                            }
                            // O perfil de acesso não pode ser pai dele próprio.
                            var index = _.findIndex(vm.view.accessProfiles, function(element) {
                                return element.id == vm.model.id;
                            });
                            if (index >= 0) {
                                vm.view.accessProfiles.splice(index, 1);
                            }
                        }, function(response) {
                            if (response.status != -1) {
                                toastService.error({
                                    status: response.status,
                                    message: response.data.message
                                });
                            } else {
                                // TODO Redirect to error default page.
                                toastService.error({
                                    status: response.status,
                                    message: "Não foi possível processar a requisição."
                                });
                            }
                        });
                } else {
                    vm.goBack();
                }
            } else {
                vm.enableEditMode();
                vm.model = _.extend(vm.model, {});
                service.findActives().then(
                    function(data) {
                        vm.view.accessProfiles = angular.copy(data);
                    },
                    function(error) {
                        vm.view.accessProfiles = [];
                    }
                );
            }
        }());

        vm.getLastModificationBy = function() {
            if (vm.model && vm.model.auditing) {
                var auditing = vm.model.auditing;
                if (auditing.modificationUser && auditing.modificationUser.nome) {
                    return auditing.modificationUser.nome;
                } else if (auditing.creationUser && auditing.creationUser.nome) {
                    return auditing.creationUser.nome;
                } else {
                    return null;
                }
            } else {
                return null;
            }
        };

        vm.getLastModificationAt = function() {
            if (vm.model && vm.model.auditing) {
                var auditing = vm.model.auditing;
                if (auditing.modificationDate) {
                    return auditing.modificationDate;
                } else if (auditing.creationDate) {
                    return auditing.creationDate;
                } else {
                    return null;
                }
            } else {
                return null;
            }
        };

        vm.hasChangedInAssignedFeatures = function() {
            return (vm.model.createdAssignedFeatures.length + vm.model.changedAssignedFeatures.length + vm.model.deletedAssignedFeatures.length) > 0;
        };

        vm.addFeature = function() {
            funcionalidadeService.findActives().then(function(data) {
                    var modal = $uibModal.open({
                        ariaLabelledBy: 'modal-title',
                        ariaDescribedBy: 'modal-body',
                        templateUrl: 'perfil-acesso/partials/perfil-acesso-feature-edit-modal.tpl.html',
                        size: 'lg',
                        controllerAs: 'vm',
                        controller: function($uibModalInstance) {
                            var _vm = this;
                            _vm.features = [];
                            _vm.selectedFeature = {};
                            _.each(data, function(element) {
                                if (is.not.existy(_.findWhere(vm.model.assignedFeatures, { id: element.id }))) {
                                    _vm.features.push(angular.copy(element));
                                }
                            });
                            _vm.features = vm.sortFeatures(_vm.features);
                            /**/
                            _.each(_vm.features, function(element) { element.actions = []; });
                            _vm.partitionActions = [];
                            _vm.validations = {};
                            _vm.selectedActions = {};
                            _vm.validations.hasNoActionSelected = undefined;
                            _vm.validations.hasNoActionFound = undefined;
                            _vm.validations.hasErrorOnSearchAction = undefined;

                            _vm.addPermission = function() {
                                if (!_vm.hasFeatureSelected()) {
                                    return false;
                                }

                                if (!is.any.existy(_.values(_vm.selectedActions))) {
                                    _vm.validations.hasNoActionSelected = true;
                                    return false;
                                }

                                var permission = angular.copy(_vm.selectedFeature);
                                _.each(permission.actions, function(element) {
                                    if (is.existy(_vm.selectedActions[element.key.id])) {
                                        element.value = { id: null, situacao: { key: 'A', value: 'Ativo' }, acao: { id: element.id } };
                                    } else {
                                        element.value = null;
                                    }
                                });
                                permission.partitionActions = _.groupBy(permission.actions, function(element, index) {
                                    return index % Math.round(permission.actions.length / 2);
                                });

                                if (is.not.existy(_.findWhere(vm.model.assignedFeatures, { id: permission.id }))) {
                                    vm.model.assignedFeatures.push(permission);
                                    vm.model.assignedFeatures = vm.sortFeatures(vm.model.assignedFeatures);
                                }
                                if (is.not.existy(vm.model.createdAssignedFeatures[permission.id])) {
                                    vm.model.createdAssignedFeatures[permission.id] = permission;
                                }
                                vm.view.partitionActions[permission.id] = vm.partitionActions(permission);

                                $uibModalInstance.dismiss('ok');
                                vm.registerForm.$setDirty(true);
                                return true;
                            };

                            _vm.findActiveActions = function(feature) {
                                _vm.validations.hasNoActionSelected = undefined;
                                _vm.validations.hasNoActionFound = undefined;
                                _vm.selectedActions = [];
                                _vm.partitionActions = [];
                                if (is.not.empty(feature)) {
                                    if (is.empty(feature.actions) || feature.actions.length == 0)
                                        funcionalidadeService.findActiveActions(feature.id).then(function(data) {
                                            var actions = _.sortBy(angular.copy(data), 'id');
                                            _vm.validations.hasNoActionFound = actions.length == 0;
                                            _vm.validations.hasErrorOnSearchAction = false;
                                            if (!_vm.validations.hasNoActionFound) {
                                                _.each(actions, function(element) { feature.actions.push({ key: element }); });
                                                _vm.partitionActions = vm.partitionActions(feature);
                                            }
                                        }, function(response) {
                                            _vm.validations.hasErrorOnSearchAction = true;
                                        });
                                }
                            };

                            _vm.cancel = function() {
                                $uibModalInstance.dismiss('cancel');
                            };

                            _vm.hasFeatureSelected = function() {
                                return is.not.empty(_vm.featureForm.feature.$viewValue);
                            };

                            _vm.updateActionSelectedState = function() {
                                if (_vm.featureForm.$submitted) {
                                    _vm.validations.hasNoActionSelected = is.empty(_vm.selectedActions) || !is.any.existy(_.values(_vm.selectedActions));
                                }
                            };
                        }
                    });

                    modal.result.then(new Function(), new Function());
                },
                function(response) {
                    if (response.status != -1) {
                        toastService.error({
                            status: response.status,
                            message: response.data.message
                        });
                    } else {
                        // TODO Redirect to error default page.
                        toastService.error({
                            status: response.status,
                            message: "Não foi possível recuperar as funcionalidades do sistema para vincular ao perfil de acesso."
                        });
                    }
                });
        };

        vm.deleteFeature = function(feature) {
            dialogService.delete().then(function() {
                if (is.existy(feature) && is.existy(feature.id)) {
                    if (is.existy(vm.model.createdAssignedFeatures[feature.id])) {
                        delete vm.model.createdAssignedFeatures[feature.id];
                    }
                    if (is.not.existy(vm.model.deletedAssignedFeatures[feature.id])) {
                        vm.model.deletedAssignedFeatures[feature.id] = feature;
                    }
                    vm.model.assignedFeatures = _.reject(vm.model.assignedFeatures, function(element) {
                        return element.id == feature.id;
                    });
                    vm.registerForm.$setDirty(true);
                    toastService.info("Permissão será removida efetivamente após a confirmação da operação.");
                }
            }, function() {});
        };

        vm.save = function() {
            if (vm.registerForm.$invalid) {
                return false;
            }

            if (vm.model.assignedFeatures.length === 0) {
                return false;
            }

            if (is.not.existy(vm.model.id)) {
                vm._create();
            } else {
                vm._update();
            }
        };

        vm._create = function() {
            var entity = {
                id: null,
                nome: vm.model.nome,
                descricao: vm.model.descricao,
                situacao: vm.model.situacao,
                perfilAcessoPai: vm.model.perfilAcessoPai,
                createdPermissions: []
            }
            var permissions = vm.mergePermissionChanges();
            entity.createdPermissions = permissions.createdPermissions;

            service.save(entity).then(function(response) {
                $scope.$emit(APP_EVENT.PERFIL_ACESSO.SAVED);
                $scope.$emit(APP_EVENT.PERFIL_ACESSO.REFRESH);
                vm.goBack();
            }, function(response) {
                if (response.status == -1) {
                    toastService.error({
                        status: response.status,
                        message: "Não foi possível se conectar com o servidor para realizar a operação."
                    });
                } else {
                    toastService.error({
                        status: response.status,
                        message: response.data.message
                    });
                }
            });
        };

        vm._update = function() {
            var entity = {
                id: vm.model.id,
                nome: vm.model.nome,
                descricao: vm.model.descricao,
                situacao: vm.model.situacao,
                perfilAcessoPai: vm.model.perfilAcessoPai,
                createdPermissions: [],
                deletedPermissions: []
            };
            var permissions = vm.mergePermissionChanges();
            entity.createdPermissions = permissions.createdPermissions;
            entity.deletedPermissions = permissions.deletedPermissions;

            service.save(entity).then(function(response) {
                $scope.$emit(APP_EVENT.PERFIL_ACESSO.SAVED);
                $scope.$emit(APP_EVENT.PERFIL_ACESSO.REFRESH);
                vm.goBack();
            }, function(response) {
                if (response.status == -1) {
                    toastService.error({
                        status: response.status,
                        message: "Não foi possível se conectar com o servidor para realizar a operação."
                    });
                } else {
                    toastService.error({
                        status: response.status,
                        message: response.data.message
                    });
                }
            });
        };

        vm.mergePermissionChanges = function() {
            var result = {
                createdPermissions: [],
                deletedPermissions: []
            }

            var actualAssignedActions = vm.extractAllAssignedActions(vm.model.assignedFeatures);
            _.each(actualAssignedActions, function(action) {
                if (is.not.existy(vm.model.assignedActions[action.id])) {
                    result.createdPermissions.push(action);
                }
            });
            _.each(vm.model.assignedActions, function(action) {
                if (is.not.existy(actualAssignedActions[action.id])) {
                    result.deletedPermissions.push(action);
                }
            });

            return result;
        };

        vm.sortFeatures = function(features) {
            return _.sortBy(features, 'nome');
        };

        vm.partitionActions = function(feature) {
            var result = [];

            var actions = feature.acoes || feature.actions;
            result = _.groupBy(actions, function(element, index) {
                return index % Math.round(actions.length / 2);
            });

            return result;
        };

        vm.partitionAllActions = function(features) {
            var result = {};

            _.each(features, function(feature) {
                var actions = feature.acoes || feature.actions;
                result[feature.id] = _.groupBy(actions, function(element, index) {
                    return index % Math.round(actions.length / 2);
                });
            });

            return result;
        };

        vm.extractAllAssignedActions = function(features) {
            var result = {};

            _.each(features, function(feature) {
                var actions = feature.acoes || feature.actions;
                _.each(actions, function(action) {
                    if (is.existy(action.value)) {
                        result[action.key.id] = action.key;
                    }
                });
            });

            return result;
        };
    }
}());