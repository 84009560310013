(function() {
    'use strict';

    angular.module('app.core').directive("iasFormFieldSimpleError", IasFormFieldSimpleError);
    IasFormFieldSimpleError.$inject = [];

    function IasFormFieldSimpleError() {
        return {
            scope: {
                label: '@',
                field: '=',
                form: '='
            },
            restrict: "E",
            templateUrl: "core/directives/error/partials/ias-form-field-simple-error.html"
        };
    }

})();