(function () {
    'use strict';

    angular.module('app.uf').controller('UfFormController', UfFormController);
    UfFormController.$inject = ['APP_STATE', 'TABLE_FIELDS', 'APP_EVENT', 'COMMON_STATUS_LIST',
        '$state', '$scope', 'ufService', 'dialogService', 'toastService'];

    function UfFormController(APP_STATE, TABLE_FIELDS, APP_EVENT, COMMON_STATUS_LIST,
        $state, $scope, ufService, dialogService, toastService) {

        var vm = this;

        vm.goBack = function () {
            $state.go(APP_STATE.UF.SEARCH);
        };

        vm.save = function () {
            if (vm.registerForm.$valid) {
                ufService.save(vm.uf).then(function (uf) {
                    $scope.$emit(APP_EVENT.UF.SAVED);
                    vm.goBack();
                }, function (response) {
                    toastService.error({
                        status: response.status,
                        message: response.data.message
                    });
                });
            }
        };

        vm.enableEditMode = function () {
            vm.editMode = true;
        };

        vm.cancel = function () {
            if (vm.refreshSearch) {
                $scope.$emit(APP_EVENT.UF.REFRESH);
            }

            vm.goBack();
        };

        (function () {
            vm.editMode = false;
            vm.editPage = false;
            vm.defaultStatus = COMMON_STATUS_LIST[0];
            
            if ($state.is(APP_STATE.UF.EDIT)) {
                vm.editPage = true;

                if ($state.params.id) {
                    ufService.get($state.params.id).then(function (uf) {
                        vm.uf = uf;
                    }, function (response) {
                        toastService.error({
                            status: response.status,
                            message: response.data.message
                        });
                    });
                } else {
                    vm.goBack();
                }
            } else {
                vm.enableEditMode();
                vm.uf = {};
                vm.uf.situacao = vm.defaultStatus;
            }
        } ());
    }

} ());
