/*
jslint
    devel: true, maxerr: 10
*/

/*
global
    required: true
*/

/*
 * Relatórios Module
 * @module constants for final result report
 * @author iFactory Solutions Ltda.
 * @version 1.0.0
 * @since 1.0.0
 */
(function() {
    'use strict';

    /* Base de Monitoramento: Se Liga (planilha) */
    angular.module('app.relatorios').constant('BASE_MONITORAMENTO_REPORT_SE_LIGA_FIELDS', [
        { name: 'id', type: 'integer', calculateTotal: false, showFooter: false },
        { name: 'detalhamento', type: 'string', calculateTotal: false, showFooter: false },

        { percent: true, name: 'percentualTurmasColetadasMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'totalTurmasColetadasAnterior', referenceTotalField: 'totalTurmas' },
        { percent: true, name: 'percentualTurmasColetadasMesAtual', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'totalTurmasColetadas', referenceTotalField: 'totalTurmas' },
        { name: 'matriculaIncial', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'totalTurmas', type: 'integer', calculateTotal: true, calculateType: 'sum', hide: true },
        { name: 'totalTurmasColetadas', type: 'integer', calculateTotal: true, calculateType: 'sum', hide: true },
        { name: 'totalTurmasColetadasAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum', hide: true },
        { name: 'matriculaAtualMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'matriculaAtualMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'mediaDiasLetivosPrevistosMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'diasLetivosPrevistosMesAnterior', referenceTotalField: 'totalTurmasColetadasAnterior' },
        { name: 'mediaDiasLetivosPrevistosMesAtual', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'diasLetivosPrevistosMesAtual', referenceTotalField: 'totalTurmasColetadas' },
        { name: 'mediaDiasLetivosDadosMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'diasLetivosDadosMesAnterior', referenceTotalField: 'totalTurmasColetadasAnterior' },
        { name: 'mediaDiasLetivosDadosMesAtual', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'diasLetivosDadosMesAtual', referenceTotalField: 'totalTurmasColetadas' },
        { hide: true, name: 'diasLetivosPrevistosMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'diasLetivosPrevistosMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'diasLetivosDadosMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'diasLetivosDadosMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { percent: true, name: 'percentualDiasLetivosDadosMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'diasLetivosDadosMesAnterior', referenceTotalField: 'diasLetivosPrevistosMesAnterior' },
        { percent: true, name: 'percentualDiasLetivosDadosMesAtual', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'diasLetivosDadosMesAtual', referenceTotalField: 'diasLetivosPrevistosMesAtual' },
        //Professor
        { name: 'faltasProfessorJustificadasMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { percent: true, name: 'percentualFaltasProfessorJustificadasMesAnterior', referenceField: 'faltasProfessorJustificadasMesAnterior', 
        referenceTotalField: 'diasLetivosPrevistosMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'faltasProfessorNaoJustificadasMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { percent: true, name: 'percentualFaltasProfessorNaoJustificadasMesAnterior', referenceField: 'faltasProfessorNaoJustificadasMesAnterior', 
        referenceTotalField: 'diasLetivosPrevistosMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'faltasProfessorTotalMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { percent: true, name: 'percentualFaltasProfessorTotalMesAnterior', referenceField: 'faltasProfessorTotalMesAnterior', 
        referenceTotalField: 'diasLetivosPrevistosMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'faltasProfessorJustificadasMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { percent: true, name: 'percentualFaltasProfessorJustificadasMesAtual', referenceField: 'faltasProfessorJustificadasMesAtual', 
        referenceTotalField: 'diasLetivosPrevistosMesAtual', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'faltasProfessorNaoJustificadasMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { percent: true, name: 'percentualFaltasProfessorNaoJustificadasMesAtual', referenceField: 'faltasProfessorNaoJustificadasMesAtual', 
        referenceTotalField: 'diasLetivosPrevistosMesAtual', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'faltasProfessorTotalMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { percent: true, name: 'percentualFaltasProfessorTotalMesAtual', referenceField: 'faltasProfessorTotalMesAtual', 
        referenceTotalField: 'diasLetivosPrevistosMesAtual', type: 'integer', calculateTotal: true, calculateType: 'average' },
            //Alunos
        { name: 'faltasAlunoJustificadasMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { percent: true, name: 'percentualFaltasAlunoJustificadasMesAnterior', referenceField: 'faltasAlunoJustificadasMesAnterior', 
        getReferenceTotalField: function(data) {
            return parseFloat(Math.round((data.calculations['diasLetivosDadosMesAnterior'] / data.calculations['totalTurmasColetadasAnterior']) * 100) / 100).toFixed(1) * data.calculations['matriculaAtualMesAnterior'];
        }, type: 'integer', calculateTotal: true, calculateType: 'average' }, 
        { name: 'faltasAlunoNaoJustificadasMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { percent: true, name: 'percentualFaltasAlunoNaoJustificadasMesAnterior', referenceField: 'faltasAlunoNaoJustificadasMesAnterior', 
        getReferenceTotalField: function(data) {
            return parseFloat(Math.round((data.calculations['diasLetivosDadosMesAnterior'] / data.calculations['totalTurmasColetadasAnterior']) * 100) / 100).toFixed(1) * data.calculations['matriculaAtualMesAnterior'];
        }, type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'faltasAlunoTotalMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { percent: true, name: 'percentualFaltasAlunoTotalMesAnterior', referenceField: 'faltasAlunoTotalMesAnterior', 
        getReferenceTotalField: function(data) {
            return parseFloat(Math.round((data.calculations['diasLetivosDadosMesAnterior'] / data.calculations['totalTurmasColetadasAnterior']) * 100) / 100).toFixed(1) * data.calculations['matriculaAtualMesAnterior'];
        }, type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'faltasAlunoJustificadasMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { percent: true, name: 'percentualFaltasAlunoJustificadasMesAtual', referenceField: 'faltasAlunoJustificadasMesAtual', 
        getReferenceTotalField: function(data) {
            return parseFloat(Math.round((data.calculations['diasLetivosDadosMesAtual'] / data.calculations['totalTurmasColetadas']) * 100) / 100).toFixed(1) * data.calculations['matriculaAtualMesAtual'];
        }, type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'faltasAlunoNaoJustificadasMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { percent: true, name: 'percentualFaltasAlunoNaoJustificadasMesAtual', referenceField: 'faltasAlunoNaoJustificadasMesAtual', 
        getReferenceTotalField: function(data) {
            return parseFloat(Math.round((data.calculations['diasLetivosDadosMesAtual'] / data.calculations['totalTurmasColetadas']) * 100) / 100).toFixed(1) * data.calculations['matriculaAtualMesAtual'];
        }, type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'faltasAlunoTotalMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { percent: true, name: 'percentualFaltasAlunoTotalMesAtual', referenceField: 'faltasAlunoTotalMesAtual', 
        getReferenceTotalField: function(data) {
            return parseFloat(Math.round((data.calculations['diasLetivosDadosMesAtual'] / data.calculations['totalTurmasColetadas']) * 100) / 100).toFixed(1) * data.calculations['matriculaAtualMesAtual'];
        }, type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'diasLetivosMatriculaMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum', hide: true },
        { name: 'diasLetivosMatriculaMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum', hide: true },
        { name: 'mediaCasaNaoFeitosMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'casaNaoFeitosMesAnterior', referenceTotalField: 'matriculaAtualMesAnterior' },
        { name: 'mediaCasaNaoFeitosMesAtual', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'casaNaoFeitosMesAtual', referenceTotalField: 'matriculaAtualMesAtual' },
        { name: 'mediaLivrosLidosMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'livrosLidosMesAnterior', referenceTotalField: 'matriculaAtualMesAnterior' },
        { name: 'mediaLivrosLidosMesAtual', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'livrosLidosMesAtual', referenceTotalField: 'matriculaAtualMesAtual' },
        { name: 'mediaVisitasMediadorMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'visitasMediadorMesAnterior', referenceTotalField: 'totalTurmasColetadasAnterior' },
        { name: 'mediaVisitasMediadorMesAtual', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'visitasMediadorMesAtual', referenceTotalField: 'totalTurmasColetadas' },
        { name: 'mediaReunioesMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'reunioesMesAnterior', referenceTotalField: 'totalTurmasColetadasAnterior' },
        { name: 'mediaReunioesMesAtual', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'reunioesMesAtual', referenceTotalField: 'totalTurmasColetadas' },
        { hide: true, name: 'casaNaoFeitosMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'casaNaoFeitosMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'livrosLidosMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'livrosLidosMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'visitasMediadorMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'visitasMediadorMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'reunioesMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'reunioesMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },

        //Acompanhamento de leitura, escrita e oralidade
        { hide: true, percent: true, name: 'leituraTurmasColetadasMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, percent: true, name: 'leituraTurmasColetadasMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, percent: true, name: 'leituraAindaNaoLeMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, percent: true, name: 'leituraAindaNaoLeMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, percent: true, name: 'leituraPalavraFraseMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, percent: true, name: 'leituraPalavraFraseMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, percent: true, name: 'leituraExplicitasTextoCurtosMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, percent: true, name: 'leituraExplicitasTextoCurtosMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, percent: true, name: 'leituraExplicitasTextoLongoMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, percent: true, name: 'leituraExplicitasTextoLongoMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, percent: true, name: 'escritaTurmasColetadasMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, percent: true, name: 'escritaTurmasColetadasMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, percent: true, name: 'escritaSemCompreensaoMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, percent: true, name: 'escritaSemCompreensaoMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, percent: true, name: 'escritaPalavrasFrasesMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, percent: true, name: 'escritaPalavrasFrasesMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, percent: true, name: 'escritaParagrafoCurtosMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, percent: true, name: 'escritaParagrafoCurtosMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, percent: true, name: 'escritaParagrafoAmpliadosMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, percent: true, name: 'escritaParagrafoAmpliadosMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, percent: true, name: 'oralidadeTurmasColetadasMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, percent: true, name: 'oralidadeTurmasColetadasMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, percent: true, name: 'oralidadePoucaClarezaMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, percent: true, name: 'oralidadePoucaClarezaMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, percent: true, name: 'oralidadeFormaAdequadaMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, percent: true, name: 'oralidadeFormaAdequadaMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, percent: true, name: 'oralidadeFormaOriginalMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, percent: true, name: 'oralidadeFormaOriginalMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        
        { percent: true, name: 'percentualLeituraTurmasColetadasMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'totalTurmasColetadasAnterior', referenceTotalField: 'totalTurmas' },
        { percent: true, name: 'percentualLeituraTurmasColetadasMesAtual', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'totalTurmasColetadas', referenceTotalField: 'totalTurmas' },
        { percent: true, name: 'percentualLeituraAindaNaoLeMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'leituraAindaNaoLeMesAnterior', referenceTotalField: 'matriculaAtualMesAnterior' },
        { percent: true, name: 'percentualLeituraAindaNaoLeMesAtual', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'leituraAindaNaoLeMesAtual', referenceTotalField: 'matriculaAtualMesAtual' },
        { percent: true, name: 'percentualLeituraPalavraFraseMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'leituraPalavraFraseMesAnterior', referenceTotalField: 'matriculaAtualMesAnterior' },
        { percent: true, name: 'percentualLeituraPalavraFraseMesAtual', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'leituraPalavraFraseMesAtual', referenceTotalField: 'matriculaAtualMesAtual' },
        { percent: true, name: 'percentualLeituraExplicitasTextoCurtosMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'leituraExplicitasTextoCurtosMesAnterior', referenceTotalField: 'matriculaAtualMesAnterior' },
        { percent: true, name: 'percentualLeituraExplicitasTextoCurtosMesAtual', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'leituraExplicitasTextoCurtosMesAtual', referenceTotalField: 'matriculaAtualMesAtual' },
        { percent: true, name: 'percentualLeituraExplicitasTextoLongoMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'leituraExplicitasTextoLongoMesAnterior', referenceTotalField: 'matriculaAtualMesAnterior' },
        { percent: true, name: 'percentualLeituraExplicitasTextoLongoMesAtual', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'leituraExplicitasTextoLongoMesAtual', referenceTotalField: 'matriculaAtualMesAtual' },
        { percent: true, name: 'percentualEscritaTurmasColetadasMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'totalTurmasColetadasAnterior', referenceTotalField: 'totalTurmas' },
        { percent: true, name: 'percentualEscritaTurmasColetadasMesAtual', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'totalTurmasColetadas', referenceTotalField: 'totalTurmas' },
        { percent: true, name: 'percentualEscritaSemCompreensaoMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'escritaSemCompreensaoMesAnterior', referenceTotalField: 'matriculaAtualMesAnterior' },
        { percent: true, name: 'percentualEscritaSemCompreensaoMesAtual', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'escritaSemCompreensaoMesAtual', referenceTotalField: 'matriculaAtualMesAtual' },
        { percent: true, name: 'percentualEscritaPalavrasFrasesMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'escritaPalavrasFrasesMesAnterior', referenceTotalField: 'matriculaAtualMesAnterior' },
        { percent: true, name: 'percentualEscritaPalavrasFrasesMesAtual', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'escritaPalavrasFrasesMesAtual', referenceTotalField: 'matriculaAtualMesAtual' },
        { percent: true, name: 'percentualEscritaParagrafoCurtosMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'escritaParagrafoCurtosMesAnterior', referenceTotalField: 'matriculaAtualMesAnterior' },
        { percent: true, name: 'percentualEscritaParagrafoCurtosMesAtual', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'escritaParagrafoCurtosMesAtual', referenceTotalField: 'matriculaAtualMesAtual' },
        { percent: true, name: 'percentualEscritaParagrafoAmpliadosMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'escritaParagrafoAmpliadosMesAnterior', referenceTotalField: 'matriculaAtualMesAnterior' },
        { percent: true, name: 'percentualEscritaParagrafoAmpliadosMesAtual', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'escritaParagrafoAmpliadosMesAtual', referenceTotalField: 'matriculaAtualMesAtual' },
        { percent: true, name: 'percentualOralidadeTurmasColetadasMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'totalTurmasColetadasAnterior', referenceTotalField: 'totalTurmas' },
        { percent: true, name: 'percentualOralidadeTurmasColetadasMesAtual', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'totalTurmasColetadas', referenceTotalField: 'totalTurmas' },
        { percent: true, name: 'percentualOralidadePoucaClarezaMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'oralidadePoucaClarezaMesAnterior', referenceTotalField: 'matriculaAtualMesAnterior' },
        { percent: true, name: 'percentualOralidadePoucaClarezaMesAtual', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'oralidadePoucaClarezaMesAtual', referenceTotalField: 'matriculaAtualMesAtual' },
        { percent: true, name: 'percentualOralidadeFormaAdequadaMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'oralidadeFormaAdequadaMesAnterior', referenceTotalField: 'matriculaAtualMesAnterior' },
        { percent: true, name: 'percentualOralidadeFormaAdequadaMesAtual', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'oralidadeFormaAdequadaMesAtual', referenceTotalField: 'matriculaAtualMesAtual' },
        { percent: true, name: 'percentualOralidadeFormaOriginalMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'oralidadeFormaOriginalMesAnterior', referenceTotalField: 'matriculaAtualMesAnterior' },
        { percent: true, name: 'percentualOralidadeFormaOriginalMesAtual', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'oralidadeFormaOriginalMesAtual', referenceTotalField: 'matriculaAtualMesAtual' }
    ]);

    /* Base de Monitoramento: Acelera Brasil (planilha) */
    angular.module('app.relatorios').constant('BASE_MONITORAMENTO_REPORT_ACELERA_BRASIL_FIELDS', [
        { name: 'id', type: 'integer', calculateTotal: false, showFooter: false },
        { name: 'detalhamento', type: 'string', calculateTotal: false, showFooter: false },

        { percent: true, name: 'percentualTurmasColetadasMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'totalTurmasColetadasAnterior', referenceTotalField: 'totalTurmas' },
        { percent: true, name: 'percentualTurmasColetadasMesAtual', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'totalTurmasColetadas', referenceTotalField: 'totalTurmas' },
        { name: 'matriculaIncial', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'totalTurmas', type: 'integer', calculateTotal: true, calculateType: 'sum', hide: true },
        { name: 'totalTurmasColetadas', type: 'integer', calculateTotal: true, calculateType: 'sum', hide: true },
        { name: 'totalTurmasColetadasAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum', hide: true },
        { name: 'matriculaAtualMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'matriculaAtualMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'mediaDiasLetivosPrevistosMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'diasLetivosPrevistosMesAnterior', referenceTotalField: 'totalTurmasColetadasAnterior' },
        { name: 'mediaDiasLetivosPrevistosMesAtual', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'diasLetivosPrevistosMesAtual', referenceTotalField: 'totalTurmasColetadas' },
        { name: 'mediaDiasLetivosDadosMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'diasLetivosDadosMesAnterior', referenceTotalField: 'totalTurmasColetadasAnterior' },
        { name: 'mediaDiasLetivosDadosMesAtual', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'diasLetivosDadosMesAtual', referenceTotalField: 'totalTurmasColetadas' },
        { hide: true, name: 'diasLetivosPrevistosMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'diasLetivosPrevistosMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'diasLetivosDadosMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'diasLetivosDadosMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { percent: true, name: 'percentualDiasLetivosDadosMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'diasLetivosDadosMesAnterior', referenceTotalField: 'diasLetivosPrevistosMesAnterior' },
        { percent: true, name: 'percentualDiasLetivosDadosMesAtual', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'diasLetivosDadosMesAtual', referenceTotalField: 'diasLetivosPrevistosMesAtual' },
        //Professor
        { name: 'faltasProfessorJustificadasMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { percent: true, name: 'percentualFaltasProfessorJustificadasMesAnterior', referenceField: 'faltasProfessorJustificadasMesAnterior', 
        referenceTotalField: 'diasLetivosPrevistosMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'faltasProfessorNaoJustificadasMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { percent: true, name: 'percentualFaltasProfessorNaoJustificadasMesAnterior', referenceField: 'faltasProfessorNaoJustificadasMesAnterior', 
        referenceTotalField: 'diasLetivosPrevistosMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'faltasProfessorTotalMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { percent: true, name: 'percentualFaltasProfessorTotalMesAnterior', referenceField: 'faltasProfessorTotalMesAnterior', 
        referenceTotalField: 'diasLetivosPrevistosMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'faltasProfessorJustificadasMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { percent: true, name: 'percentualFaltasProfessorJustificadasMesAtual', referenceField: 'faltasProfessorJustificadasMesAtual', 
        referenceTotalField: 'diasLetivosPrevistosMesAtual', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'faltasProfessorNaoJustificadasMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { percent: true, name: 'percentualFaltasProfessorNaoJustificadasMesAtual', referenceField: 'faltasProfessorNaoJustificadasMesAtual', 
        referenceTotalField: 'diasLetivosPrevistosMesAtual', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'faltasProfessorTotalMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { percent: true, name: 'percentualFaltasProfessorTotalMesAtual', referenceField: 'faltasProfessorTotalMesAtual', 
        referenceTotalField: 'diasLetivosPrevistosMesAtual', type: 'integer', calculateTotal: true, calculateType: 'average' },
        //Aluno
        { name: 'faltasAlunoJustificadasMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { percent: true, name: 'percentualFaltasAlunoJustificadasMesAnterior', referenceField: 'faltasAlunoJustificadasMesAnterior', 
        getReferenceTotalField: function(data) {
            return parseFloat(Math.round((data.calculations['diasLetivosDadosMesAnterior'] / data.calculations['totalTurmasColetadasAnterior']) * 100) / 100).toFixed(1) * data.calculations['matriculaAtualMesAnterior'];
        }, type: 'integer', calculateTotal: true, calculateType: 'average' }, 
        { name: 'faltasAlunoNaoJustificadasMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { percent: true, name: 'percentualFaltasAlunoNaoJustificadasMesAnterior', referenceField: 'faltasAlunoNaoJustificadasMesAnterior', 
        getReferenceTotalField: function(data) {
            return parseFloat(Math.round((data.calculations['diasLetivosDadosMesAnterior'] / data.calculations['totalTurmasColetadasAnterior']) * 100) / 100).toFixed(1) * data.calculations['matriculaAtualMesAnterior'];
        }, type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'faltasAlunoTotalMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { percent: true, name: 'percentualFaltasAlunoTotalMesAnterior', referenceField: 'faltasAlunoTotalMesAnterior', 
        getReferenceTotalField: function(data) {
            return parseFloat(Math.round((data.calculations['diasLetivosDadosMesAnterior'] / data.calculations['totalTurmasColetadasAnterior']) * 100) / 100).toFixed(1) * data.calculations['matriculaAtualMesAnterior'];
        }, type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'faltasAlunoJustificadasMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { percent: true, name: 'percentualFaltasAlunoJustificadasMesAtual', referenceField: 'faltasAlunoJustificadasMesAtual', 
        getReferenceTotalField: function(data) {
            return parseFloat(Math.round((data.calculations['diasLetivosDadosMesAtual'] / data.calculations['totalTurmasColetadas']) * 100) / 100).toFixed(1) * data.calculations['matriculaAtualMesAtual'];
        }, type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'faltasAlunoNaoJustificadasMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { percent: true, name: 'percentualFaltasAlunoNaoJustificadasMesAtual', referenceField: 'faltasAlunoNaoJustificadasMesAtual', 
        getReferenceTotalField: function(data) {
            return parseFloat(Math.round((data.calculations['diasLetivosDadosMesAtual'] / data.calculations['totalTurmasColetadas']) * 100) / 100).toFixed(1) * data.calculations['matriculaAtualMesAtual'];
        }, type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'faltasAlunoTotalMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { percent: true, name: 'percentualFaltasAlunoTotalMesAtual', referenceField: 'faltasAlunoTotalMesAtual', 
        getReferenceTotalField: function(data) {
            return parseFloat(Math.round((data.calculations['diasLetivosDadosMesAtual'] / data.calculations['totalTurmasColetadas']) * 100) / 100).toFixed(1) * data.calculations['matriculaAtualMesAtual'];
        }, type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'diasLetivosMatriculaMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum', hide: true },
        { name: 'diasLetivosMatriculaMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum', hide: true },
        { name: 'mediaCasaNaoFeitosMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'casaNaoFeitosMesAnterior', referenceTotalField: 'matriculaAtualMesAnterior' },
        { name: 'mediaCasaNaoFeitosMesAtual', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'casaNaoFeitosMesAtual', referenceTotalField: 'matriculaAtualMesAtual' },
        { name: 'mediaLivrosLidosMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'livrosLidosMesAnterior', referenceTotalField: 'matriculaAtualMesAnterior' },
        { name: 'mediaLivrosLidosMesAtual', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'livrosLidosMesAtual', referenceTotalField: 'matriculaAtualMesAtual' },
        { name: 'mediaVisitasMediadorMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'visitasMediadorMesAnterior', referenceTotalField: 'totalTurmasColetadasAnterior' },
        { name: 'mediaVisitasMediadorMesAtual', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'visitasMediadorMesAtual', referenceTotalField: 'totalTurmasColetadas' },
        { name: 'mediaReunioesMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'reunioesMesAnterior', referenceTotalField: 'totalTurmasColetadasAnterior' },
        { name: 'mediaReunioesMesAtual', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'reunioesMesAtual', referenceTotalField: 'totalTurmasColetadas' },
        { hide: true, name: 'casaNaoFeitosMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'casaNaoFeitosMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'livrosLidosMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'livrosLidosMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'visitasMediadorMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'visitasMediadorMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'reunioesMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'reunioesMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        //Acompanhamento de leitura, escrita e oralidade
        { hide: true, name: 'leituraTurmasColetadasAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'leituraTurmasColetadas', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'leituraLeSilabandoAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'leituraLeSilabando', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'leituraLePalavraPorPalavraAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'leituraLePalavraPorPalavra', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'leituraLeComFluenciaAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'leituraLeComFluencia', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'escritaTurmasColetadasAnterior',  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'escritaTurmasColetadas', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'escritaSilabasSimplesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'escritaSilabasSimples', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'escritaSilabasComplexasAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'escritaSilabasComplexas', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'oralidadeTurmasColetadasAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'oralidadeTurmasColetadas', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'oralidadeFrasesSoltasAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'oralidadeFrasesSoltas', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'oralidadeTextoComecoMeioFimAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'oralidadeTextoComecoMeioFim', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'oralidadeComunicaFormaOriginalAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'oralidadeComunicaFormaOriginal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        
        { name: 'leituraTurmasColetadasPercentualAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'totalTurmasColetadasAnterior', referenceTotalField: 'totalTurmas' },
        { name: 'leituraTurmasColetadasPercentual', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'totalTurmasColetadas', referenceTotalField: 'totalTurmas' },
        { name: 'leituraLeSilabandoPercentualAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'leituraLeSilabandoAnterior', referenceTotalField: 'matriculaAtualMesAnterior' },
        { name: 'leituraLeSilabandoPercentual', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'leituraLeSilabando', referenceTotalField: 'matriculaAtualMesAtual' },
        { name: 'leituraLePalavraPorPalavraPercentualAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'leituraLePalavraPorPalavraAnterior', referenceTotalField: 'matriculaAtualMesAnterior' },
        { name: 'leituraLePalavraPorPalavraPercentual', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'leituraLePalavraPorPalavra', referenceTotalField: 'matriculaAtualMesAtual' },
        { name: 'leituraLeComFluenciaPercentualAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'leituraLeComFluenciaAnterior', referenceTotalField: 'matriculaAtualMesAnterior' },
        { name: 'leituraLeComFluenciaPercentual', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'leituraLeComFluencia', referenceTotalField: 'matriculaAtualMesAtual' },
        { name: 'escritaTurmasColetadasPercentualAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average' , referenceField: 'totalTurmasColetadasAnterior', referenceTotalField: 'totalTurmas'},
        { name: 'escritaTurmasColetadasPercentual', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'totalTurmasColetadas', referenceTotalField: 'totalTurmas' },
        { name: 'escritaSilabasSimplesPercentualAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'escritaSilabasSimplesAnterior', referenceTotalField: 'matriculaAtualMesAnterior' },
        { name: 'escritaSilabasSimplesPercentual', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'escritaSilabasSimples', referenceTotalField: 'matriculaAtualMesAtual' },
        { name: 'escritaSilabasComplexasPercentualAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'escritaSilabasComplexasAnterior', referenceTotalField: 'matriculaAtualMesAnterior' },
        { name: 'escritaSilabasComplexasPercentual', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'escritaSilabasComplexas', referenceTotalField: 'matriculaAtualMesAtual' },
        { name: 'oralidadeTurmasColetadasPercentualAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'totalTurmasColetadasAnterior', referenceTotalField: 'totalTurmas' },
        { name: 'oralidadeTurmasColetadasPercentual', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'totalTurmasColetadas', referenceTotalField: 'totalTurmas' },
        { name: 'oralidadeFrasesSoltasPercentualAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'oralidadeFrasesSoltasAnterior', referenceTotalField: 'matriculaAtualMesAnterior' },
        { name: 'oralidadeFrasesSoltasPercentual', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'oralidadeFrasesSoltas', referenceTotalField: 'matriculaAtualMesAtual' },
        { name: 'oralidadeTextoComecoMeioFimPercentualAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'oralidadeTextoComecoMeioFimAnterior', referenceTotalField: 'matriculaAtualMesAnterior' },
        { name: 'oralidadeTextoComecoMeioFimPercentual', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'oralidadeTextoComecoMeioFim', referenceTotalField: 'matriculaAtualMesAtual' },
        { name: 'oralidadeComunicaFormaOriginalPercentualAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'oralidadeComunicaFormaOriginalAnterior', referenceTotalField: 'matriculaAtualMesAnterior' },
        { name: 'oralidadeComunicaFormaOriginalPercentual', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'oralidadeComunicaFormaOriginal', referenceTotalField: 'matriculaAtualMesAtual' }
    ]);

    /* Base de Monitoramento: Formula Português (planilha) */
    angular.module('app.relatorios').constant('BASE_MONITORAMENTO_REPORT_FORMULA_PORTUGUES_FIELDS', [
        { name: 'id', type: 'integer', calculateTotal: false, showFooter: false },
        { name: 'detalhamento', type: 'string', calculateTotal: false, showFooter: false },

        { percent: true, name: 'percentualTurmasColetadasMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'totalTurmasColetadasAnterior', referenceTotalField: 'totalTurmas' },
        { percent: true, name: 'percentualTurmasColetadasMesAtual', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'totalTurmasColetadas', referenceTotalField: 'totalTurmas' },
        { name: 'matriculaIncial', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'totalTurmas', type: 'integer', calculateTotal: true, calculateType: 'sum', hide: true },
        { name: 'totalTurmasColetadas', type: 'integer', calculateTotal: true, calculateType: 'sum', hide: true },
        { name: 'totalTurmasColetadasAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum', hide: true },
        { name: 'matriculaAtualMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'matriculaAtualMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'mediaDiasLetivosPrevistosMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'diasLetivosPrevistosMesAnterior', referenceTotalField: 'totalTurmasColetadasAnterior' },
        { name: 'mediaDiasLetivosPrevistosMesAtual', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'diasLetivosPrevistosMesAtual', referenceTotalField: 'totalTurmasColetadas' },
        { name: 'mediaDiasLetivosDadosMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'diasLetivosDadosMesAnterior', referenceTotalField: 'totalTurmasColetadasAnterior' },
        { name: 'mediaDiasLetivosDadosMesAtual', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'diasLetivosDadosMesAtual', referenceTotalField: 'totalTurmasColetadas' },
        { hide: true, name: 'diasLetivosPrevistosMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'diasLetivosPrevistosMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'diasLetivosDadosMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'diasLetivosDadosMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { percent: true, name: 'percentualDiasLetivosDadosMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'diasLetivosDadosMesAnterior', referenceTotalField: 'diasLetivosPrevistosMesAnterior' },
        { percent: true, name: 'percentualDiasLetivosDadosMesAtual', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'diasLetivosDadosMesAtual', referenceTotalField: 'diasLetivosPrevistosMesAtual' },
        //Professor
        { name: 'faltasProfessorJustificadasMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { percent: true, name: 'percentualFaltasProfessorJustificadasMesAnterior', referenceField: 'faltasProfessorJustificadasMesAnterior', 
        referenceTotalField: 'diasLetivosPrevistosMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'faltasProfessorNaoJustificadasMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { percent: true, name: 'percentualFaltasProfessorNaoJustificadasMesAnterior', referenceField: 'faltasProfessorNaoJustificadasMesAnterior', 
        referenceTotalField: 'diasLetivosPrevistosMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'faltasProfessorTotalMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { percent: true, name: 'percentualFaltasProfessorTotalMesAnterior', referenceField: 'faltasProfessorTotalMesAnterior', 
        referenceTotalField: 'diasLetivosPrevistosMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'faltasProfessorJustificadasMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { percent: true, name: 'percentualFaltasProfessorJustificadasMesAtual', referenceField: 'faltasProfessorJustificadasMesAtual', 
        referenceTotalField: 'diasLetivosPrevistosMesAtual', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'faltasProfessorNaoJustificadasMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { percent: true, name: 'percentualFaltasProfessorNaoJustificadasMesAtual', referenceField: 'faltasProfessorNaoJustificadasMesAtual', 
        referenceTotalField: 'diasLetivosPrevistosMesAtual', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'faltasProfessorTotalMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { percent: true, name: 'percentualFaltasProfessorTotalMesAtual', referenceField: 'faltasProfessorTotalMesAtual', 
        referenceTotalField: 'diasLetivosPrevistosMesAtual', type: 'integer', calculateTotal: true, calculateType: 'average' },
        //Aluno
        { name: 'faltasAlunoJustificadasMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { percent: true, name: 'percentualFaltasAlunoJustificadasMesAnterior', referenceField: 'faltasAlunoJustificadasMesAnterior', 
        getReferenceTotalField: function(data) {
            return parseFloat(Math.round((data.calculations['diasLetivosDadosMesAnterior'] / data.calculations['totalTurmasColetadasAnterior']) * 100) / 100).toFixed(1) * data.calculations['matriculaAtualMesAnterior'];
        }, type: 'integer', calculateTotal: true, calculateType: 'average' }, 
        { name: 'faltasAlunoNaoJustificadasMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { percent: true, name: 'percentualFaltasAlunoNaoJustificadasMesAnterior', referenceField: 'faltasAlunoNaoJustificadasMesAnterior', 
        getReferenceTotalField: function(data) {
            return parseFloat(Math.round((data.calculations['diasLetivosDadosMesAnterior'] / data.calculations['totalTurmasColetadasAnterior']) * 100) / 100).toFixed(1) * data.calculations['matriculaAtualMesAnterior'];
        }, type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'faltasAlunoTotalMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { percent: true, name: 'percentualFaltasAlunoTotalMesAnterior', referenceField: 'faltasAlunoTotalMesAnterior', 
        getReferenceTotalField: function(data) {
            return parseFloat(Math.round((data.calculations['diasLetivosDadosMesAnterior'] / data.calculations['totalTurmasColetadasAnterior']) * 100) / 100).toFixed(1) * data.calculations['matriculaAtualMesAnterior'];
        }, type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'faltasAlunoJustificadasMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { percent: true, name: 'percentualFaltasAlunoJustificadasMesAtual', referenceField: 'faltasAlunoJustificadasMesAtual', 
        getReferenceTotalField: function(data) {
            return parseFloat(Math.round((data.calculations['diasLetivosDadosMesAtual'] / data.calculations['totalTurmasColetadas']) * 100) / 100).toFixed(1) * data.calculations['matriculaAtualMesAtual'];
        }, type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'faltasAlunoNaoJustificadasMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { percent: true, name: 'percentualFaltasAlunoNaoJustificadasMesAtual', referenceField: 'faltasAlunoNaoJustificadasMesAtual', 
        getReferenceTotalField: function(data) {
            return parseFloat(Math.round((data.calculations['diasLetivosDadosMesAtual'] / data.calculations['totalTurmasColetadas']) * 100) / 100).toFixed(1) * data.calculations['matriculaAtualMesAtual'];
        }, type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'faltasAlunoTotalMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { percent: true, name: 'percentualFaltasAlunoTotalMesAtual', referenceField: 'faltasAlunoTotalMesAtual', 
        getReferenceTotalField: function(data) {
            return parseFloat(Math.round((data.calculations['diasLetivosDadosMesAtual'] / data.calculations['totalTurmasColetadas']) * 100) / 100).toFixed(1) * data.calculations['matriculaAtualMesAtual'];
        }, type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'diasLetivosMatriculaMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum', hide: true },
        { name: 'diasLetivosMatriculaMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum', hide: true },
        { name: 'mediaVisitasMediadorMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'visitasMediadorMesAnterior', referenceTotalField: 'totalTurmasColetadasAnterior' },
        { name: 'mediaVisitasMediadorMesAtual', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'visitasMediadorMesAtual', referenceTotalField: 'totalTurmasColetadas' },
        { name: 'mediaReunioesMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'reunioesMesAnterior', referenceTotalField: 'totalTurmasColetadasAnterior' },
        { name: 'mediaReunioesMesAtual', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'reunioesMesAtual', referenceTotalField: 'totalTurmasColetadas' },
        { hide: true, name: 'visitasMediadorMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'visitasMediadorMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'reunioesMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'reunioesMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        //Acompanhamento de leitura, escrita e oralidade
        { hide: true, name: 'leTurmasColetadasAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average' },
        { hide: true, name: 'leTurmasColetadas', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average' },
        { hide: true, name: 'leModoFramentadoAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average' },
        { hide: true, name: 'leModoFramentado', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average' },
        { hide: true, name: 'leConsiderandoTextoIsoladamenteAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average' },
        { hide: true, name: 'leConsiderandoTextoIsoladamente', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average' },
        { hide: true, name: 'leConsiderandoContextoAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average' },
        { hide: true, name: 'leConsiderandoContexto', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average' },
        { hide: true, name: 'lePosicionaTextoAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average' },
        { hide: true, name: 'lePosicionaTexto', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average' },
        { hide: true, name: 'escritaTurmasColetadasAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average' },
        { hide: true, name: 'escritaTurmasColetadas', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average' },
        { hide: true, name: 'escreveModoFragmentadoAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average' },
        { hide: true, name: 'escreveModoFragmentado', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average' },
        { hide: true, name: 'escreveTextoPoucoAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average' },
        { hide: true, name: 'escreveTextoPouco', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average' },
        { hide: true, name: 'apoiaBemContextoAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average' },
        { hide: true, name: 'apoiaBemContexto', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average' },
        { hide: true, name: 'oralidadeTurmasColetadasAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average' },
        { hide: true, name: 'oralidadeTurmasColetadas', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average' },
        { hide: true, name: 'organizaPrecariamenteAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average' },
        { hide: true, name: 'organizaPrecariamente', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average' },
        { hide: true, name: 'organizaFalaAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average' },
        { hide: true, name: 'organizaFala', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average' },
        { hide: true, name: 'compreendeEntonacaoAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average' },
        { hide: true, name: 'compreendeEntonacao', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average' },
        
        { name: 'leTurmasColetadasPercentualAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'totalTurmasColetadasAnterior', referenceTotalField: 'totalTurmas' },
        { name: 'leTurmasColetadasPercentual', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'totalTurmasColetadas', referenceTotalField: 'totalTurmas' },
        { name: 'leModoFramentadoPercentualAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'leModoFramentadoAnterior', referenceTotalField: 'matriculaAtualMesAnterior' },
        { name: 'leModoFramentadoPercentual', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'leModoFramentado', referenceTotalField: 'matriculaAtualMesAtual' },
        { name: 'leConsiderandoTextoIsoladamentePercentualAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'leConsiderandoTextoIsoladamenteAnterior', referenceTotalField: 'matriculaAtualMesAnterior' },
        { name: 'leConsiderandoTextoIsoladamentePercentual', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'leConsiderandoTextoIsoladamente', referenceTotalField: 'matriculaAtualMesAtual' },
        { name: 'leConsiderandoContextoPercentualAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'leConsiderandoContextoAnterior', referenceTotalField: 'matriculaAtualMesAnterior' },
        { name: 'leConsiderandoContextoPercentual', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'leConsiderandoContexto', referenceTotalField: 'matriculaAtualMesAtual' },
        { name: 'lePosicionaTextoPercentualAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'lePosicionaTextoAnterior', referenceTotalField: 'matriculaAtualMesAnterior' },
        { name: 'lePosicionaTextoPercentual', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'lePosicionaTexto', referenceTotalField: 'matriculaAtualMesAtual' },
        { name: 'escritaTurmasColetadasPercentualAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'totalTurmasColetadasAnterior', referenceTotalField: 'totalTurmas' },
        { name: 'escritaTurmasColetadasPercentual', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'totalTurmasColetadas', referenceTotalField: 'totalTurmas' },
        { name: 'escreveModoFragmentadoPercentualAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'escreveModoFragmentadoAnterior', referenceTotalField: 'matriculaAtualMesAnterior' },
        { name: 'escreveModoFragmentadoPercentual', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'escreveModoFragmentado', referenceTotalField: 'matriculaAtualMesAtual' },
        { name: 'escreveTextoPoucoPercentualAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'escreveTextoPoucoAnterior', referenceTotalField: 'matriculaAtualMesAnterior' },
        { name: 'escreveTextoPoucoPercentual', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'escreveTextoPouco', referenceTotalField: 'matriculaAtualMesAtual' },
        { name: 'apoiaBemContextoPercentualAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'apoiaBemContextoAnterior', referenceTotalField: 'matriculaAtualMesAnterior' },
        { name: 'apoiaBemContextoPercentual', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'apoiaBemContexto', referenceTotalField: 'matriculaAtualMesAtual' },
        { name: 'oralidadeTurmasColetadasPercentualAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'totalTurmasColetadasAnterior', referenceTotalField: 'totalTurmas' },
        { name: 'oralidadeTurmasColetadasPercentual', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'totalTurmasColetadas', referenceTotalField: 'totalTurmas' },
        { name: 'organizaPrecariamentePercentualAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'organizaPrecariamenteAnterior', referenceTotalField: 'matriculaAtualMesAnterior' },
        { name: 'organizaPrecariamentePercentual', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'organizaPrecariamente', referenceTotalField: 'matriculaAtualMesAtual' },
        { name: 'organizaFalaPercentualAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'organizaFalaAnterior', referenceTotalField: 'matriculaAtualMesAnterior' },
        { name: 'organizaFalaPercentual', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'organizaFala', referenceTotalField: 'matriculaAtualMesAtual' },
        { name: 'compreendeEntonacaoPercentualAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'compreendeEntonacaoAnterior', referenceTotalField: 'matriculaAtualMesAnterior' },
        { name: 'compreendeEntonacaoPercentual', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'compreendeEntonacao', referenceTotalField: 'matriculaAtualMesAtual' }
    ]);

    /* Base de Monitoramento: Matemática Nível 1 (planilha) */
    angular.module('app.relatorios').constant('BASE_MONITORAMENTO_REPORT_MATEMATICA_NIVEL_1_FIELDS', [
        { name: 'id', type: 'integer', calculateTotal: false, showFooter: false },
        { name: 'detalhamento', type: 'string', calculateTotal: false, showFooter: false },

        { percent: true, name: 'percentualTurmasColetadasMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'totalTurmasColetadasAnterior', referenceTotalField: 'totalTurmas' },
        { percent: true, name: 'percentualTurmasColetadasMesAtual', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'totalTurmasColetadas', referenceTotalField: 'totalTurmas' },
        { name: 'matriculaIncial', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'totalTurmas', type: 'integer', calculateTotal: true, calculateType: 'sum', hide: true },
        { name: 'totalTurmasColetadas', type: 'integer', calculateTotal: true, calculateType: 'sum', hide: true },
        { name: 'totalTurmasColetadasAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum', hide: true },
        { name: 'matriculaAtualMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'matriculaAtualMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'mediaDiasLetivosPrevistosMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'diasLetivosPrevistosMesAnterior', referenceTotalField: 'totalTurmasColetadasAnterior' },
        { name: 'mediaDiasLetivosPrevistosMesAtual', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'diasLetivosPrevistosMesAtual', referenceTotalField: 'totalTurmasColetadas' },
        { name: 'mediaDiasLetivosDadosMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'diasLetivosDadosMesAnterior', referenceTotalField: 'totalTurmasColetadasAnterior' },
        { name: 'mediaDiasLetivosDadosMesAtual', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'diasLetivosDadosMesAtual', referenceTotalField: 'totalTurmasColetadas' },
        { hide: true, name: 'diasLetivosPrevistosMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'diasLetivosPrevistosMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'diasLetivosDadosMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'diasLetivosDadosMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { percent: true, name: 'percentualDiasLetivosDadosMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'diasLetivosDadosMesAnterior', referenceTotalField: 'diasLetivosPrevistosMesAnterior' },
        { percent: true, name: 'percentualDiasLetivosDadosMesAtual', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'diasLetivosDadosMesAtual', referenceTotalField: 'diasLetivosPrevistosMesAtual' },
        //Professor
        { name: 'faltasProfessorJustificadasMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { percent: true, name: 'percentualFaltasProfessorJustificadasMesAnterior', referenceField: 'faltasProfessorJustificadasMesAnterior', 
        referenceTotalField: 'diasLetivosPrevistosMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'faltasProfessorNaoJustificadasMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { percent: true, name: 'percentualFaltasProfessorNaoJustificadasMesAnterior', referenceField: 'faltasProfessorNaoJustificadasMesAnterior', 
        referenceTotalField: 'diasLetivosPrevistosMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'faltasProfessorTotalMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { percent: true, name: 'percentualFaltasProfessorTotalMesAnterior', referenceField: 'faltasProfessorTotalMesAnterior', 
        referenceTotalField: 'diasLetivosPrevistosMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'faltasProfessorJustificadasMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { percent: true, name: 'percentualFaltasProfessorJustificadasMesAtual', referenceField: 'faltasProfessorJustificadasMesAtual', 
        referenceTotalField: 'diasLetivosPrevistosMesAtual', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'faltasProfessorNaoJustificadasMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { percent: true, name: 'percentualFaltasProfessorNaoJustificadasMesAtual', referenceField: 'faltasProfessorNaoJustificadasMesAtual', 
        referenceTotalField: 'diasLetivosPrevistosMesAtual', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'faltasProfessorTotalMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { percent: true, name: 'percentualFaltasProfessorTotalMesAtual', referenceField: 'faltasProfessorTotalMesAtual', 
        referenceTotalField: 'diasLetivosPrevistosMesAtual', type: 'integer', calculateTotal: true, calculateType: 'average' },
        //Aluno
        { name: 'faltasAlunoJustificadasMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { percent: true, name: 'percentualFaltasAlunoJustificadasMesAnterior', referenceField: 'faltasAlunoJustificadasMesAnterior', 
        getReferenceTotalField: function(data) {
            return parseFloat(Math.round((data.calculations['diasLetivosDadosMesAnterior'] / data.calculations['totalTurmasColetadasAnterior']) * 100) / 100).toFixed(1) * data.calculations['matriculaAtualMesAnterior'];
        }, type: 'integer', calculateTotal: true, calculateType: 'average' }, 
        { name: 'faltasAlunoNaoJustificadasMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { percent: true, name: 'percentualFaltasAlunoNaoJustificadasMesAnterior', referenceField: 'faltasAlunoNaoJustificadasMesAnterior', 
        getReferenceTotalField: function(data) {
            return parseFloat(Math.round((data.calculations['diasLetivosDadosMesAnterior'] / data.calculations['totalTurmasColetadasAnterior']) * 100) / 100).toFixed(1) * data.calculations['matriculaAtualMesAnterior'];
        }, type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'faltasAlunoTotalMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { percent: true, name: 'percentualFaltasAlunoTotalMesAnterior', referenceField: 'faltasAlunoTotalMesAnterior', 
        getReferenceTotalField: function(data) {
            return parseFloat(Math.round((data.calculations['diasLetivosDadosMesAnterior'] / data.calculations['totalTurmasColetadasAnterior']) * 100) / 100).toFixed(1) * data.calculations['matriculaAtualMesAnterior'];
        }, type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'faltasAlunoJustificadasMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { percent: true, name: 'percentualFaltasAlunoJustificadasMesAtual', referenceField: 'faltasAlunoJustificadasMesAtual', 
        getReferenceTotalField: function(data) {
            return parseFloat(Math.round((data.calculations['diasLetivosDadosMesAtual'] / data.calculations['totalTurmasColetadas']) * 100) / 100).toFixed(1) * data.calculations['matriculaAtualMesAtual'];
        }, type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'faltasAlunoNaoJustificadasMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { percent: true, name: 'percentualFaltasAlunoNaoJustificadasMesAtual', referenceField: 'faltasAlunoNaoJustificadasMesAtual', 
        getReferenceTotalField: function(data) {
            return parseFloat(Math.round((data.calculations['diasLetivosDadosMesAtual'] / data.calculations['totalTurmasColetadas']) * 100) / 100).toFixed(1) * data.calculations['matriculaAtualMesAtual'];
        }, type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'faltasAlunoTotalMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { percent: true, name: 'percentualFaltasAlunoTotalMesAtual', referenceField: 'faltasAlunoTotalMesAtual', 
        getReferenceTotalField: function(data) {
            return parseFloat(Math.round((data.calculations['diasLetivosDadosMesAtual'] / data.calculations['totalTurmasColetadas']) * 100) / 100).toFixed(1) * data.calculations['matriculaAtualMesAtual'];
        }, type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'diasLetivosMatriculaMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum', hide: true },
        { name: 'diasLetivosMatriculaMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum', hide: true },
        { name: 'mediaVisitasMediadorMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'visitasMediadorMesAnterior', referenceTotalField: 'totalTurmasColetadasAnterior' },
        { name: 'mediaVisitasMediadorMesAtual', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'visitasMediadorMesAtual', referenceTotalField: 'totalTurmasColetadas' },
        { name: 'mediaReunioesMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'reunioesMesAnterior', referenceTotalField: 'totalTurmasColetadasAnterior' },
        { name: 'mediaReunioesMesAtual', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'reunioesMesAtual', referenceTotalField: 'totalTurmasColetadas' },
        
        { hide: true, name: 'visitasMediadorMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'visitasMediadorMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'reunioesMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'reunioesMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        //Acompanhamento de leitura, escrita e oralidade
        { hide: true, name: 'leituraTurmasColetadasAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'leituraTurmasColetadas', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'leituraLeModoFramentadoAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'leituraLeModoFramentado', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'leFormaIndependenteAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'leFormaIndependente', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'leInterpretaTextoAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'leInterpretaTexto', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'resolveTurmasColetadasAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'resolveTurmasColetadas', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'resolveProblemasNaoConvencionalAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'resolveProblemasNaoConvencional', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'resolveProblemasConvencionalAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'resolveProblemasConvencional', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'resolveProblemasSemAjudaAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'resolveProblemasSemAjuda', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'nnResolveTurmasColetadasAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'nnResolveTurmasColetadas', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'nnResolve1Anterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'nnResolve1', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'nnResolve2Anterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'nnResolve2', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'nnResolve3Anterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'nnResolve3', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        
        { name: 'leituraTurmasColetadasPercentualAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'totalTurmasColetadasAnterior', referenceTotalField: 'totalTurmas' },
        { name: 'leituraTurmasColetadasPercentual', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'totalTurmasColetadas', referenceTotalField: 'totalTurmas' },
        { name: 'leituraLeModoFramentadoPercentualAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'leituraLeModoFramentadoAnterior', referenceTotalField: 'matriculaAtualMesAnterior' },
        { name: 'leituraLeModoFramentadoPercentual', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'leituraLeModoFramentado', referenceTotalField: 'matriculaAtualMesAtual' },
        { name: 'leFormaIndependentePercentualAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'leFormaIndependenteAnterior', referenceTotalField: 'matriculaAtualMesAnterior' },
        { name: 'leFormaIndependentePercentual', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'leFormaIndependente', referenceTotalField: 'matriculaAtualMesAtual' },
        { name: 'leInterpretaTextoPercentualAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'leInterpretaTextoAnterior', referenceTotalField: 'matriculaAtualMesAnterior' },
        { name: 'leInterpretaTextoPercentual', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'leInterpretaTexto', referenceTotalField: 'matriculaAtualMesAtual' },
        { name: 'resolveTurmasColetadasPercentualAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'totalTurmasColetadasAnterior', referenceTotalField: 'totalTurmas' },
        { name: 'resolveTurmasColetadasPercentual', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'totalTurmasColetadas', referenceTotalField: 'totalTurmas' },
        { name: 'resolveProblemasNaoConvencionalPercentualAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'resolveProblemasNaoConvencionalAnterior', referenceTotalField: 'matriculaAtualMesAnterior' },
        { name: 'resolveProblemasNaoConvencionalPercentual', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'resolveProblemasNaoConvencional', referenceTotalField: 'matriculaAtualMesAtual' },
        { name: 'resolveProblemasConvencionalPercentualAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'resolveProblemasConvencionalAnterior', referenceTotalField: 'matriculaAtualMesAnterior' },
        { name: 'resolveProblemasConvencionalPercentual', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'resolveProblemasConvencional', referenceTotalField: 'matriculaAtualMesAtual' },
        { name: 'resolveProblemasSemAjudaPercentualAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'resolveProblemasSemAjudaAnterior', referenceTotalField: 'matriculaAtualMesAnterior' },
        { name: 'resolveProblemasSemAjudaPercentual', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'resolveProblemasSemAjuda', referenceTotalField: 'matriculaAtualMesAtual' },
        { name: 'nnResolveTurmasColetadasPercentualAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'totalTurmasColetadasAnterior', referenceTotalField: 'totalTurmas' },
        { name: 'nnResolveTurmasColetadasPercentual', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'totalTurmasColetadas', referenceTotalField: 'totalTurmas' },
        { name: 'nnResolve1PercentualAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'nnResolve1Anterior', referenceTotalField: 'matriculaAtualMesAnterior' },
        { name: 'nnResolve1Percentual', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'nnResolve1', referenceTotalField: 'matriculaAtualMesAtual' },
        { name: 'nnResolve2PercentualAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'nnResolve2Anterior', referenceTotalField: 'matriculaAtualMesAnterior' },
        { name: 'nnResolve2Percentual', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'nnResolve2', referenceTotalField: 'matriculaAtualMesAtual' },
        { name: 'nnResolve3PercentualAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'nnResolve3Anterior', referenceTotalField: 'matriculaAtualMesAnterior' },
        { name: 'nnResolve3Percentual', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'nnResolve3', referenceTotalField: 'matriculaAtualMesAtual' }
    ]);

    /* Base de Monitoramento: Matemática Nível 2 (planilha) */
    angular.module('app.relatorios').constant('BASE_MONITORAMENTO_REPORT_MATEMATICA_NIVEL_2_FIELDS', [
        { name: 'id', type: 'integer', calculateTotal: false, showFooter: false },
        { name: 'detalhamento', type: 'string', calculateTotal: false, showFooter: false },

        { percent: true, name: 'percentualTurmasColetadasMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'totalTurmasColetadasAnterior', referenceTotalField: 'totalTurmas' },
        { percent: true, name: 'percentualTurmasColetadasMesAtual', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'totalTurmasColetadas', referenceTotalField: 'totalTurmas' },
        { name: 'matriculaIncial', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'totalTurmas', type: 'integer', calculateTotal: true, calculateType: 'sum', hide: true },
        { name: 'totalTurmasColetadas', type: 'integer', calculateTotal: true, calculateType: 'sum', hide: true },
        { name: 'totalTurmasColetadasAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum', hide: true },
        { name: 'matriculaAtualMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'matriculaAtualMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'mediaDiasLetivosPrevistosMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'diasLetivosPrevistosMesAnterior', referenceTotalField: 'totalTurmasColetadasAnterior' },
        { name: 'mediaDiasLetivosPrevistosMesAtual', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'diasLetivosPrevistosMesAtual', referenceTotalField: 'totalTurmasColetadas' },
        { name: 'mediaDiasLetivosDadosMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'diasLetivosDadosMesAnterior', referenceTotalField: 'totalTurmasColetadasAnterior' },
        { name: 'mediaDiasLetivosDadosMesAtual', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'diasLetivosDadosMesAtual', referenceTotalField: 'totalTurmasColetadas' },
        { hide: true, name: 'diasLetivosPrevistosMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'diasLetivosPrevistosMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'diasLetivosDadosMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'diasLetivosDadosMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { percent: true, name: 'percentualDiasLetivosDadosMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'diasLetivosDadosMesAnterior', referenceTotalField: 'diasLetivosPrevistosMesAnterior' },
        { percent: true, name: 'percentualDiasLetivosDadosMesAtual', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'diasLetivosDadosMesAtual', referenceTotalField: 'diasLetivosPrevistosMesAtual' },
        //Professor
        { name: 'faltasProfessorJustificadasMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { percent: true, name: 'percentualFaltasProfessorJustificadasMesAnterior', referenceField: 'faltasProfessorJustificadasMesAnterior', 
        referenceTotalField: 'diasLetivosPrevistosMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'faltasProfessorNaoJustificadasMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { percent: true, name: 'percentualFaltasProfessorNaoJustificadasMesAnterior', referenceField: 'faltasProfessorNaoJustificadasMesAnterior', 
        referenceTotalField: 'diasLetivosPrevistosMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'faltasProfessorTotalMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { percent: true, name: 'percentualFaltasProfessorTotalMesAnterior', referenceField: 'faltasProfessorTotalMesAnterior', 
        referenceTotalField: 'diasLetivosPrevistosMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'faltasProfessorJustificadasMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { percent: true, name: 'percentualFaltasProfessorJustificadasMesAtual', referenceField: 'faltasProfessorJustificadasMesAtual', 
        referenceTotalField: 'diasLetivosPrevistosMesAtual', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'faltasProfessorNaoJustificadasMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { percent: true, name: 'percentualFaltasProfessorNaoJustificadasMesAtual', referenceField: 'faltasProfessorNaoJustificadasMesAtual', 
        referenceTotalField: 'diasLetivosPrevistosMesAtual', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'faltasProfessorTotalMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { percent: true, name: 'percentualFaltasProfessorTotalMesAtual', referenceField: 'faltasProfessorTotalMesAtual', 
        referenceTotalField: 'diasLetivosPrevistosMesAtual', type: 'integer', calculateTotal: true, calculateType: 'average' },
        //Aluno
        { name: 'faltasAlunoJustificadasMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { percent: true, name: 'percentualFaltasAlunoJustificadasMesAnterior', referenceField: 'faltasAlunoJustificadasMesAnterior', 
        getReferenceTotalField: function(data) {
            return parseFloat(Math.round((data.calculations['diasLetivosDadosMesAnterior'] / data.calculations['totalTurmasColetadasAnterior']) * 100) / 100).toFixed(1) * data.calculations['matriculaAtualMesAnterior'];
        }, type: 'integer', calculateTotal: true, calculateType: 'average' }, 
        { name: 'faltasAlunoNaoJustificadasMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { percent: true, name: 'percentualFaltasAlunoNaoJustificadasMesAnterior', referenceField: 'faltasAlunoNaoJustificadasMesAnterior', 
        getReferenceTotalField: function(data) {
            return parseFloat(Math.round((data.calculations['diasLetivosDadosMesAnterior'] / data.calculations['totalTurmasColetadasAnterior']) * 100) / 100).toFixed(1) * data.calculations['matriculaAtualMesAnterior'];
        }, type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'faltasAlunoTotalMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { percent: true, name: 'percentualFaltasAlunoTotalMesAnterior', referenceField: 'faltasAlunoTotalMesAnterior', 
        getReferenceTotalField: function(data) {
            return parseFloat(Math.round((data.calculations['diasLetivosDadosMesAnterior'] / data.calculations['totalTurmasColetadasAnterior']) * 100) / 100).toFixed(1) * data.calculations['matriculaAtualMesAnterior'];
        }, type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'faltasAlunoJustificadasMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { percent: true, name: 'percentualFaltasAlunoJustificadasMesAtual', referenceField: 'faltasAlunoJustificadasMesAtual', 
        getReferenceTotalField: function(data) {
            return parseFloat(Math.round((data.calculations['diasLetivosDadosMesAtual'] / data.calculations['totalTurmasColetadas']) * 100) / 100).toFixed(1) * data.calculations['matriculaAtualMesAtual'];
        }, type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'faltasAlunoNaoJustificadasMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { percent: true, name: 'percentualFaltasAlunoNaoJustificadasMesAtual', referenceField: 'faltasAlunoNaoJustificadasMesAtual', 
        getReferenceTotalField: function(data) {
            return parseFloat(Math.round((data.calculations['diasLetivosDadosMesAtual'] / data.calculations['totalTurmasColetadas']) * 100) / 100).toFixed(1) * data.calculations['matriculaAtualMesAtual'];
        }, type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'faltasAlunoTotalMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { percent: true, name: 'percentualFaltasAlunoTotalMesAtual', referenceField: 'faltasAlunoTotalMesAtual', 
        getReferenceTotalField: function(data) {
            return parseFloat(Math.round((data.calculations['diasLetivosDadosMesAtual'] / data.calculations['totalTurmasColetadas']) * 100) / 100).toFixed(1) * data.calculations['matriculaAtualMesAtual'];
        }, type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'diasLetivosMatriculaMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum', hide: true },
        { name: 'diasLetivosMatriculaMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum', hide: true },
        { name: 'mediaVisitasMediadorMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'visitasMediadorMesAnterior', referenceTotalField: 'totalTurmasColetadasAnterior' },
        { name: 'mediaVisitasMediadorMesAtual', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'visitasMediadorMesAtual', referenceTotalField: 'totalTurmasColetadas' },
        { name: 'mediaReunioesMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'reunioesMesAnterior', referenceTotalField: 'totalTurmasColetadasAnterior' },
        { name: 'mediaReunioesMesAtual', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'reunioesMesAtual', referenceTotalField: 'totalTurmasColetadas' },
        { hide: true, name: 'visitasMediadorMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'visitasMediadorMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'reunioesMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'reunioesMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        //Acompanhamento de leitura, escrita e oralidade
        { hide: true, name: 'leituraTurmasColetadasAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'leituraTurmasColetadas', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'leituraLeModoFramentadoAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'leituraLeModoFramentado', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'leFormaIndependenteAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'leFormaIndependente', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'leInterpretaTextoAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'leInterpretaTexto', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'resolveTurmasColetadasAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'resolveTurmasColetadas', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'resolveProblemasNaoConvencionalAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'resolveProblemasNaoConvencional', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'resolveProblemasConvencionalAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'resolveProblemasConvencional', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'resolveProblemasSemAjudaAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'resolveProblemasSemAjuda', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'compreensaoTurmasColetadasAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'compreensaoTurmasColetadas', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'compreensao1Anterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'compreensao1', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'compreensao2Anterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'compreensao2', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'compreensao3Anterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'compreensao3', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'compreensao4Anterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'compreensao4', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'compreensao5Anterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'compreensao5', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'compreensao6Anterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'compreensao6', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'compreensao7Anterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'compreensao7', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'compreensao8Anterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'compreensao8', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'compreensao9Anterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'compreensao9', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        
        { name: 'leituraTurmasColetadasPercentualAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'totalTurmasColetadasAnterior', referenceTotalField: 'totalTurmas' },
        { name: 'leituraTurmasColetadasPercentual', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'totalTurmasColetadas', referenceTotalField: 'totalTurmas' },
        { name: 'leituraLeModoFramentadoPercentualAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'leituraLeModoFramentadoAnterior', referenceTotalField: 'matriculaAtualMesAnterior' },
        { name: 'leituraLeModoFramentadoPercentual', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'leituraLeModoFramentado', referenceTotalField: 'matriculaAtualMesAtual' },
        { name: 'leFormaIndependentePercentualAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'leFormaIndependenteAnterior', referenceTotalField: 'matriculaAtualMesAnterior' },
        { name: 'leFormaIndependentePercentual', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'leFormaIndependente', referenceTotalField: 'matriculaAtualMesAtual' },
        { name: 'leInterpretaTextoPercentualAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'leInterpretaTextoAnterior', referenceTotalField: 'matriculaAtualMesAnterior' },
        { name: 'leInterpretaTextoPercentual', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'leInterpretaTexto', referenceTotalField: 'matriculaAtualMesAtual' },
        { name: 'resolveTurmasColetadasPercentualAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'totalTurmasColetadasAnterior', referenceTotalField: 'totalTurmas' },
        { name: 'resolveTurmasColetadasPercentual', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'totalTurmasColetadas', referenceTotalField: 'totalTurmas' },
        { name: 'resolveProblemasNaoConvencionalPercentualAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'resolveProblemasNaoConvencionalAnterior', referenceTotalField: 'matriculaAtualMesAnterior' },
        { name: 'resolveProblemasNaoConvencionalPercentual', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'resolveProblemasNaoConvencional', referenceTotalField: 'matriculaAtualMesAtual' },
        { name: 'resolveProblemasConvencionalPercentualAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'resolveProblemasConvencionalAnterior', referenceTotalField: 'matriculaAtualMesAnterior' },
        { name: 'resolveProblemasConvencionalPercentual', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'resolveProblemasConvencional', referenceTotalField: 'matriculaAtualMesAtual' },
        { name: 'resolveProblemasSemAjudaPercentualAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'resolveProblemasSemAjudaAnterior', referenceTotalField: 'matriculaAtualMesAnterior' },
        { name: 'resolveProblemasSemAjudaPercentual', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'resolveProblemasSemAjuda', referenceTotalField: 'matriculaAtualMesAtual' },
        { name: 'compreensaoTurmasColetadasPercentualAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'totalTurmasColetadasAnterior', referenceTotalField: 'totalTurmas' },
        { name: 'compreensaoTurmasColetadasPercentual', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'totalTurmasColetadas', referenceTotalField: 'totalTurmas' },
        { name: 'compreensao1PercentualAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'compreensao1Anterior', referenceTotalField: 'matriculaAtualMesAnterior' },
        { name: 'compreensao1Percentual', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'compreensao1', referenceTotalField: 'matriculaAtualMesAtual' },
        { name: 'compreensao2PercentualAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'compreensao2Anterior', referenceTotalField: 'matriculaAtualMesAnterior' },
        { name: 'compreensao2Percentual', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'compreensao2', referenceTotalField: 'matriculaAtualMesAtual' },
        { name: 'compreensao3PercentualAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'compreensao3Anterior', referenceTotalField: 'matriculaAtualMesAnterior' },
        { name: 'compreensao3Percentual', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'compreensao3', referenceTotalField: 'matriculaAtualMesAtual' },
        { name: 'compreensao4PercentualAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'compreensao4Anterior', referenceTotalField: 'matriculaAtualMesAnterior' },
        { name: 'compreensao4Percentual', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'compreensao4', referenceTotalField: 'matriculaAtualMesAtual' },
        { name: 'compreensao5PercentualAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'compreensao5Anterior', referenceTotalField: 'matriculaAtualMesAnterior' },
        { name: 'compreensao5Percentual', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'compreensao5', referenceTotalField: 'matriculaAtualMesAtual' },
        { name: 'compreensao6PercentualAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'compreensao6Anterior', referenceTotalField: 'matriculaAtualMesAnterior' },
        { name: 'compreensao6Percentual', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'compreensao6', referenceTotalField: 'matriculaAtualMesAtual' },
        { name: 'compreensao7PercentualAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'compreensao7Anterior', referenceTotalField: 'matriculaAtualMesAnterior' },
        { name: 'compreensao7Percentual', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'compreensao7', referenceTotalField: 'matriculaAtualMesAtual' },
        { name: 'compreensao8PercentualAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'compreensao8Anterior', referenceTotalField: 'matriculaAtualMesAnterior' },
        { name: 'compreensao8Percentual', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'compreensao8', referenceTotalField: 'matriculaAtualMesAtual' },
        { name: 'compreensao9PercentualAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'compreensao9Anterior', referenceTotalField: 'matriculaAtualMesAnterior' },
        { name: 'compreensao9Percentual', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'compreensao9', referenceTotalField: 'matriculaAtualMesAtual' },
    ]);

    /* Base de Monitoramento: Gestão de Alfabetização (planilha) */
    angular.module('app.relatorios').constant('BASE_MONITORAMENTO_REPORT_GESTAO_ALFABETIZACAO_FIELDS', [
        { name: 'id', type: 'integer', calculateTotal: false, showFooter: false },
        { name: 'detalhamento', type: 'string', calculateTotal: false, showFooter: false },

        { percent: true, name: 'percentualTurmasColetadasMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'totalTurmasColetadasAnterior', referenceTotalField: 'totalTurmas' },
        { percent: true, name: 'percentualTurmasColetadasMesAtual', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'totalTurmasColetadas', referenceTotalField: 'totalTurmas' },
        { name: 'matriculaIncial', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'totalTurmas', type: 'integer', calculateTotal: true, calculateType: 'sum', hide: true },
        { name: 'totalTurmasColetadas', type: 'integer', calculateTotal: true, calculateType: 'sum', hide: true },
        { name: 'totalTurmasColetadasAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum', hide: true },
        { name: 'matriculaAtualMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'matriculaAtualMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'mediaDiasLetivosPrevistosMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'diasLetivosPrevistosMesAnterior', referenceTotalField: 'totalTurmasColetadasAnterior' },
        { name: 'mediaDiasLetivosPrevistosMesAtual', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'diasLetivosPrevistosMesAtual', referenceTotalField: 'totalTurmasColetadas' },
        { name: 'mediaDiasLetivosDadosMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'diasLetivosDadosMesAnterior', referenceTotalField: 'totalTurmasColetadasAnterior' },
        { name: 'mediaDiasLetivosDadosMesAtual', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'diasLetivosDadosMesAtual', referenceTotalField: 'totalTurmasColetadas' },
        { hide: true, name: 'diasLetivosPrevistosMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'diasLetivosPrevistosMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'diasLetivosDadosMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'diasLetivosDadosMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { percent: true, name: 'percentualDiasLetivosDadosMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'diasLetivosDadosMesAnterior', referenceTotalField: 'diasLetivosPrevistosMesAnterior' },
        { percent: true, name: 'percentualDiasLetivosDadosMesAtual', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'diasLetivosDadosMesAtual', referenceTotalField: 'diasLetivosPrevistosMesAtual' },
        //Professor
        { name: 'faltasProfessorJustificadasMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { percent: true, name: 'percentualFaltasProfessorJustificadasMesAnterior', referenceField: 'faltasProfessorJustificadasMesAnterior', 
        referenceTotalField: 'diasLetivosPrevistosMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'faltasProfessorNaoJustificadasMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { percent: true, name: 'percentualFaltasProfessorNaoJustificadasMesAnterior', referenceField: 'faltasProfessorNaoJustificadasMesAnterior', 
        referenceTotalField: 'diasLetivosPrevistosMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'faltasProfessorTotalMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { percent: true, name: 'percentualFaltasProfessorTotalMesAnterior', referenceField: 'faltasProfessorTotalMesAnterior', 
        referenceTotalField: 'diasLetivosPrevistosMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'faltasProfessorJustificadasMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { percent: true, name: 'percentualFaltasProfessorJustificadasMesAtual', referenceField: 'faltasProfessorJustificadasMesAtual', 
        referenceTotalField: 'diasLetivosPrevistosMesAtual', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'faltasProfessorNaoJustificadasMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { percent: true, name: 'percentualFaltasProfessorNaoJustificadasMesAtual', referenceField: 'faltasProfessorNaoJustificadasMesAtual', 
        referenceTotalField: 'diasLetivosPrevistosMesAtual', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'faltasProfessorTotalMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { percent: true, name: 'percentualFaltasProfessorTotalMesAtual', referenceField: 'faltasProfessorTotalMesAtual', 
        referenceTotalField: 'diasLetivosPrevistosMesAtual', type: 'integer', calculateTotal: true, calculateType: 'average' },
        //Aluno
        { name: 'faltasAlunoJustificadasMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { percent: true, name: 'percentualFaltasAlunoJustificadasMesAnterior', referenceField: 'faltasAlunoJustificadasMesAnterior', 
        getReferenceTotalField: function(data) {
            return parseFloat(Math.round((data.calculations['diasLetivosDadosMesAnterior'] / data.calculations['totalTurmasColetadasAnterior']) * 100) / 100).toFixed(1) * data.calculations['matriculaAtualMesAnterior'];
        }, type: 'integer', calculateTotal: true, calculateType: 'average' }, 
        { name: 'faltasAlunoNaoJustificadasMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { percent: true, name: 'percentualFaltasAlunoNaoJustificadasMesAnterior', referenceField: 'faltasAlunoNaoJustificadasMesAnterior', 
        getReferenceTotalField: function(data) {
            return parseFloat(Math.round((data.calculations['diasLetivosDadosMesAnterior'] / data.calculations['totalTurmasColetadasAnterior']) * 100) / 100).toFixed(1) * data.calculations['matriculaAtualMesAnterior'];
        }, type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'faltasAlunoTotalMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { percent: true, name: 'percentualFaltasAlunoTotalMesAnterior', referenceField: 'faltasAlunoTotalMesAnterior', 
        getReferenceTotalField: function(data) {
            return parseFloat(Math.round((data.calculations['diasLetivosDadosMesAnterior'] / data.calculations['totalTurmasColetadasAnterior']) * 100) / 100).toFixed(1) * data.calculations['matriculaAtualMesAnterior'];
        }, type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'faltasAlunoJustificadasMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { percent: true, name: 'percentualFaltasAlunoJustificadasMesAtual', referenceField: 'faltasAlunoJustificadasMesAtual', 
        getReferenceTotalField: function(data) {
            return parseFloat(Math.round((data.calculations['diasLetivosDadosMesAtual'] / data.calculations['totalTurmasColetadas']) * 100) / 100).toFixed(1) * data.calculations['matriculaAtualMesAtual'];
        }, type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'faltasAlunoNaoJustificadasMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { percent: true, name: 'percentualFaltasAlunoNaoJustificadasMesAtual', referenceField: 'faltasAlunoNaoJustificadasMesAtual', 
        getReferenceTotalField: function(data) {
            return parseFloat(Math.round((data.calculations['diasLetivosDadosMesAtual'] / data.calculations['totalTurmasColetadas']) * 100) / 100).toFixed(1) * data.calculations['matriculaAtualMesAtual'];
        }, type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'faltasAlunoTotalMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { percent: true, name: 'percentualFaltasAlunoTotalMesAtual', referenceField: 'faltasAlunoTotalMesAtual', 
        getReferenceTotalField: function(data) {
            return parseFloat(Math.round((data.calculations['diasLetivosDadosMesAtual'] / data.calculations['totalTurmasColetadas']) * 100) / 100).toFixed(1) * data.calculations['matriculaAtualMesAtual'];
        }, type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'diasLetivosMatriculaMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum', hide: true },
        { name: 'diasLetivosMatriculaMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum', hide: true },
        { name: 'mediaCasaNaoFeitosMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'casaNaoFeitosMesAnterior', referenceTotalField: 'matriculaAtualMesAnterior' },
        { name: 'mediaCasaNaoFeitosMesAtual', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'casaNaoFeitosMesAtual', referenceTotalField: 'matriculaAtualMesAtual' },
        { name: 'mediaLivrosLidosMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'livrosLidosMesAnterior', referenceTotalField: 'matriculaAtualMesAnterior' },
        { name: 'mediaLivrosLidosMesAtual', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'livrosLidosMesAtual', referenceTotalField: 'matriculaAtualMesAtual' },
        { name: 'mediaVisitasMediadorMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'visitasMediadorMesAnterior', referenceTotalField: 'totalTurmasColetadasAnterior' },
        { name: 'mediaVisitasMediadorMesAtual', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'visitasMediadorMesAtual', referenceTotalField: 'totalTurmasColetadas' },
        { name: 'mediaReunioesMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'reunioesMesAnterior', referenceTotalField: 'totalTurmasColetadasAnterior' },
        { name: 'mediaReunioesMesAtual', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'reunioesMesAtual', referenceTotalField: 'totalTurmasColetadas' },
        
        { hide: true, name: 'casaNaoFeitosMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'casaNaoFeitosMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'livrosLidosMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'livrosLidosMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'visitasMediadorMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'visitasMediadorMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'reunioesMesAnterior', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'reunioesMesAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        //Acompanhamento de leitura, escrita e oralidade
        { hide: true, name: 'leituraTurmasColetadasAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'leituraTurmasColetadas', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'leituraAindaNaoLeAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'leituraAindaNaoLe', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'realizaLeituraAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'realizaLeitura', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'localizaTextoCurtoAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'localizaTextoCurto', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'localizaTextoLongoAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'localizaTextoLongo', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'escritaTurmasColetadasAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'escritaTurmasColetadas', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'semCompreesaoAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'semCompreesao', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'palavraFraseAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'palavraFrase', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'paragrafoCurtoAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'paragrafoCurto', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'paragrafoLongoAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'paragrafoLongo', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'producaoTurmasColetadasAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'producaoTurmasColetadas', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'poucaClarezaAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'poucaClareza', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'formaAdequadaAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'formaAdequada', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'formaOriginalAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { hide: true, name: 'formaOriginal', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        
        { name: 'leituraTurmasColetadasPercentualAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'totalTurmasColetadasAnterior', referenceTotalField: 'totalTurmas' },
        { name: 'leituraTurmasColetadasPercentual', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'totalTurmasColetadas', referenceTotalField: 'totalTurmas' },
        { name: 'leituraAindaNaoLePercentualAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'leituraAindaNaoLeAnterior', referenceTotalField: 'matriculaAtualMesAnterior' },
        { name: 'leituraAindaNaoLePercentual', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'leituraAindaNaoLe', referenceTotalField: 'matriculaAtualMesAtual' },
        { name: 'realizaLeituraPercentualAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'realizaLeituraAnterior', referenceTotalField: 'matriculaAtualMesAnterior' },
        { name: 'realizaLeituraPercentual', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'realizaLeitura', referenceTotalField: 'matriculaAtualMesAtual' },
        { name: 'localizaTextoCurtoPercentualAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'localizaTextoCurtoAnterior', referenceTotalField: 'matriculaAtualMesAnterior' },
        { name: 'localizaTextoCurtoPercentual', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'localizaTextoCurto', referenceTotalField: 'matriculaAtualMesAtual' },
        { name: 'localizaTextoLongoPercentualAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'localizaTextoLongoAnterior', referenceTotalField: 'matriculaAtualMesAnterior' },
        { name: 'localizaTextoLongoPercentual', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'localizaTextoLongo', referenceTotalField: 'matriculaAtualMesAtual' },
        { name: 'escritaTurmasColetadasPercentualAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'totalTurmasColetadasAnterior', referenceTotalField: 'totalTurmas' },
        { name: 'escritaTurmasColetadasPercentual', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'totalTurmasColetadas', referenceTotalField: 'totalTurmas' },
        { name: 'semCompreesaoPercentualAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'semCompreesaoAnterior', referenceTotalField: 'matriculaAtualMesAnterior' },
        { name: 'semCompreesaoPercentual', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'semCompreesao', referenceTotalField: 'matriculaAtualMesAtual' },
        { name: 'palavraFrasePercentualAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'palavraFraseAnterior', referenceTotalField: 'matriculaAtualMesAnterior' },
        { name: 'palavraFrasePercentual', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'palavraFrase', referenceTotalField: 'matriculaAtualMesAtual' },
        { name: 'paragrafoCurtoPercentualAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'paragrafoCurtoAnterior', referenceTotalField: 'matriculaAtualMesAnterior' },
        { name: 'paragrafoCurtoPercentual', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'paragrafoCurto', referenceTotalField: 'matriculaAtualMesAtual' },
        { name: 'paragrafoLongoPercentualAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'paragrafoLongoAnterior', referenceTotalField: 'matriculaAtualMesAnterior' },
        { name: 'paragrafoLongoPercentual', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'paragrafoLongo', referenceTotalField: 'matriculaAtualMesAtual' },
        { name: 'producaoTurmasColetadasPercentualAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'totalTurmasColetadasAnterior', referenceTotalField: 'totalTurmas' },
        { name: 'producaoTurmasColetadasPercentual', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'totalTurmasColetadas', referenceTotalField: 'totalTurmas' },
        { name: 'poucaClarezaPercentualAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'poucaClarezaAnterior', referenceTotalField: 'matriculaAtualMesAnterior' },
        { name: 'poucaClarezaPercentual', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'poucaClareza', referenceTotalField: 'matriculaAtualMesAtual' },
        { name: 'formaAdequadaPercentualAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'formaAdequadaAnterior', referenceTotalField: 'matriculaAtualMesAnterior' },
        { name: 'formaAdequadaPercentual', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'formaAdequada', referenceTotalField: 'matriculaAtualMesAtual' },
        { name: 'formaOriginalPercentualAnterior', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'formaOriginalAnterior', referenceTotalField: 'matriculaAtualMesAnterior' },
        { name: 'formaOriginalPercentual', percent: true,  type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'formaOriginal', referenceTotalField: 'matriculaAtualMesAtual' }
    ]);
    

}());