(function() {
    'use strict';

    angular.module('app.coleta.resposta').factory('ColetaRespostaProcessamento', ColetaRespostaProcessamento);
    ColetaRespostaProcessamento.$inject = ['$resource', 'APP_PROPERTY'];

    function ColetaRespostaProcessamento($resource, APP_PROPERTY) {
        return $resource(APP_PROPERTY.URL + '/coletas/respostas/processamento/:id', { id: '@id' }, {
            update: {
                method: 'PUT'
            },
            findByColetaRespostaId: {
                method: 'GET',
                isArray:true,
                url: APP_PROPERTY.URL + '/coletas/respostas/processamento/relatorio/:id'
            },
            save: {
                method: 'POST',
                url: APP_PROPERTY.URL + '/coletas/respostas/processamento'
            },

        });

    }

}());