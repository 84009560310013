(function () {
  'use strict';

  angular.module('app.turmas', ['app.core']).config(config);
  config.$inject = ['$stateProvider', 'APP_STATE'];

  function config($stateProvider, APP_STATE) {
    $stateProvider.state(APP_STATE.TURMA.BASE, {
      url: '/turmas',
      abstract: true,
      views: {
        'content': {
          template: '<div ui-view="internal"></div>',
          controller: 'TurmasController',
          controllerAs: 'vm'
        }
      }
    })
      .state(APP_STATE.TURMA.SEARCH, {
        url: '/search',
        views: {
          'internal': {
            templateUrl: 'turmas/partials/turmas-search.html'
          }
        },
        data: {
          permission: "TUR:RE",
          breadCrumb: [{
            display: "Parcerias",
            active: false
          },
            {
              display: "Turmas",
              active: true
            }
          ],
          search: {
            enabled: false
          }
        }
      })
      .state(APP_STATE.TURMA.NEW, {
        url: '/new',
        views: {
          'internal': {
            templateUrl: 'turmas/partials/turmas-form.html',
            controller: 'TurmasFormController',
            controllerAs: 'vm'
          }
        },
        data: {
          permission: "TUR:CR",
          breadCrumb: [{
            display: "Parcerias",
            active: false
          },
            {
              display: "Turmas",
              active: false,
              stateName: APP_STATE.TURMA.SEARCH
            },
            {
              display: "Nova",
              active: true
            }
          ],
          search: {
            placeHolder: "Digite o nome da turma",
            enabled: false
          }
        }
      })
      .state(APP_STATE.TURMA.LOTE, {
        url: '/lote',
        views: {
          'internal': {
            templateUrl: 'turmas/partials/turmas-competencias-lote.html',
            controller: 'TurmasLoteController',
            controllerAs: 'vm'
          }
        },
        data: {
          permission: "TUR:CR",
          breadCrumb: [{
            display: "Parcerias",
            active: false
          },
            {
              display: "Turmas",
              active: false,
              stateName: APP_STATE.TURMA.SEARCH
            },
            {
              display: "Competências em Lote",
              active: true
            }
          ],
          search: {
            placeHolder: "Digite o nome da turma",
            enabled: false
          }
        }
      })
      .state(APP_STATE.TURMA.EDIT, {
        url: '/edit/:id',
        views: {
          'internal': {
            templateUrl: 'turmas/partials/turmas-form.html',
            controller: 'TurmasFormController',
            controllerAs: 'vm'
          }
        },
        data: {
          permission: "TUR:VI",
          breadCrumb: [{
            display: "Parcerias",
            active: false
          },
            {
              display: "Turmas",
              active: false,
              stateName: APP_STATE.TURMA.SEARCH
            },
            {
              display: "Edição",
              active: true
            }
          ],
          search: {
            placeHolder: "Digite o nome da turma",
            enabled: false
          }
        }
      })
      .state(APP_STATE.TURMA.IMPORT_TEMPLATE, {
        url: '/import_template',
        views: {
          'internal': {
            templateUrl: 'turmas/partials/turmas-import_template-form.html',
            controller: 'turmaImportTemplateController',
            controllerAs: 'vm'
          }
        },
        data: {
          permission: "TUR:VI",
          breadCrumb: [{
            display: "Parcerias",
            active: false
          },
            {
              display: "Turmas",
              active: false,
              stateName: APP_STATE.TURMA.SEARCH
            },
            {
              display: "Edição",
              active: true
            }
          ],
          search: {
            placeHolder: "Digite o nome da turma",
            enabled: false
          }
        }
      });
  }
}());
