(function(){
    'use strict';

    angular.module('app.coletaRubrica').service('coletaRubricaService', ColetaRubricaService);
    ColetaRubricaService.$inject = ['ColetaRubrica', '$q'];

    function ColetaRubricaService(ColetaRubrica, $q) {
        var self = this;

        this.tipos = [];
        this.status = [];
        this.abrangencias = [];
        this.granularidades = [];
        this.periodicidades = [];
        this.acessos = [];

        var _findByType = function(type) {
            if (self[type].length > 0) {
                return $q.resolve(self[type]);
            } else {
                var deferred = $q.defer();

                ColetaRubrica.findByType({type: type}).$promise.then(function(data) {
                        self[type] = _.map(data, function(item) {
                            return {
                                key: item.id,
                                value: item.name
                            };
                        });

                        deferred.resolve(self[type]);
                    }, function(response) {
                        deferred.reject(response);
                    });

                return deferred.promise;
            }
        };

        var _setAgendasCriadas = function(coletaRubrica) {
            var agendasCriadas = _.filter(coletaRubrica.agendas, function(agenda) {
                return !agenda.id;
            });

            if (agendasCriadas.length) {
                var agendasCriadasMoment = [];
                /*angular.forEach(agendasCriadas, function(agenda) {
                    agenda.dataInicio = moment(agenda.dataInicio, 'DD/MM/YYYY').format('YYYY-MM-DD');
                    agenda.dataFim = moment(agenda.dataFim, 'DD/MM/YYYY').format('YYYY-MM-DD');
                    agendasCriadasMoment.push(agenda);
                });*/
                coletaRubrica.agendasCriadas = agendasCriadas;
            }
        };

        var _setItensAlterados = function(coletaRubrica) {
            var itensAlterados = _.filter(coletaRubrica.itens, function(item) {
                return item._updated;
            });

            if (itensAlterados.length) {
                coletaRubrica.itensAlterados = itensAlterados;
            }
        };

        this.filter = function(filter) {
            return ColetaRubrica.filter(filter).$promise;            
        };

        this.get = function(id) {
            return ColetaRubrica.get({ id: id }).$promise;
        };

        this.save = function(coletaRubrica) {
            _setAgendasCriadas(coletaRubrica);
            _setItensAlterados(coletaRubrica);
            coletaRubrica.agendas = [];

            var entity = new ColetaRubrica(coletaRubrica);
            if (entity.id) {
                return entity.$update();
            } else {
                return entity.$save();
            }
        };

        this.saveItens = function(coletaRubrica) {
            return ColetaRubrica.saveItens({id: coletaRubrica.id}, coletaRubrica.itensCriados).$promise;
        };

        this.updateItens = function(coletaRubrica) {
            _setItensAlterados(coletaRubrica);

            return ColetaRubrica.updateItens({id: coletaRubrica.id}, coletaRubrica).$promise;
        };

        this.delete = function(coletaRubrica) {
            return ColetaRubrica.delete({id: coletaRubrica.id}).$promise;
        };

        this.findTipos = function() {
            return _findByType("tipos");
        };

        this.findAbrangencias = function() {
            return _findByType("abrangencias");
        };

        this.findStatus = function() {
            return _findByType("status");
        };

        this.findGranularidades = function() {
            return _findByType("granularidades");
        };

        this.findPeriodicidades = function() {
            return _findByType("periodicidades");
        };

        this.findAcessos = function() {
            return _findByType("acessos");
        };

        this.findItensByColetaRubricaId = function(coletaRubricaId) {
            return ColetaRubrica.findItens({id: coletaRubricaId}).$promise;
        };

        this.findItensAtivosByColetaRubricaId = function(coletaRubricaId) {
            return ColetaRubrica.findItens({id: coletaRubricaId, status: 'ATIVO'}).$promise;
        };

        this.viewById = function(coletaRubricaId) {
            return ColetaRubrica.viewById({id: coletaRubricaId}).$promise;
        };

        this.findAlvosByIdAndFilters = function(id, filter) {
            var newFilter = angular.copy(filter);
            newFilter.id = id;

            return ColetaRubrica.findAlvos(newFilter).$promise;
        };

        this.sendEmails = function(coletaRubricaId, emails) {
            return ColetaRubrica.sendEmails({id: coletaRubricaId}, emails).$promise;
        };

        this.updateAgenda = function(agenda){
            return ColetaRubrica.updateAgenda({id:agenda.id}, agenda).$promise;
        }

    }

}());
