/*
jslint
    devel: true, maxerr: 10
*/

/*
global
    required: true
*/

/*
 * Layout Module
 * @description The main layout definition of the application, when user is authenticated.
 * @author iFactory Solutions Ltda.
 * @version 1.0.0
 * @since 1.0.0
 */
(function() {
    'use strict';

    angular.module('app.layout', ['app.core']).config(config);
    config.$inject = ['$stateProvider', 'APP_STATE'];

    function config($stateProvider, APP_STATE) {
        $stateProvider.state(APP_STATE.BASE, {
            url: '',
            abstract: true,
            templateUrl: 'layout/partials/shell.html',
            controller: 'ShellController',
            controllerAs: 'vm'
        });
    }

}());