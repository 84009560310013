(function() {
    'use strict';

    angular.module('app.contrato').factory('Contrato', Contrato);
    Contrato.$inject = ['$resource', 'APP_PROPERTY'];

    function Contrato($resource, APP_PROPERTY) {
        return $resource(APP_PROPERTY.URL + '/contratos/:id', { id: '@id' }, {
            update: {
                method: 'PUT'
            },
            get: {
                method: 'GET'
            },
            filter: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/contratos'
            },
            ativo: {
                method: 'GET',
                url: APP_PROPERTY.URL + '/contratos/ATIVO',
                isArray: true
            },
            findByStatus: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/contratos/:status'
            },
            findByPrograma: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/contratos/:programa'
            }
            ,
            findProgramas: {
                method: 'GET',
                url: APP_PROPERTY.URL + '/contratos/programas',
                isArray: true
            },
            findEscopos: {
                method: 'GET',
                url: APP_PROPERTY.URL + '/contratos/escopos',
                isArray: true
            },
            findEscolasAssociadas: {
                method: 'GET',
                url: APP_PROPERTY.URL + '/contratos/:id/escolas',
                isArray: true
            },
            findProgramasCriterios: {
                method: 'GET',
                url: APP_PROPERTY.URL + '/contratos/:id/programas/criterios',
                isArray: true
            },
            findProgramasAssociados: {
                method: 'GET',
                url: APP_PROPERTY.URL + '/contratos/:id/programas',
                isArray: true
            },
            /*findCriteriosSelecao: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/contratos/criterios'
            },*/
            findContratoProgramaEscolas: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/contratos/:id/contrato-programa-escolas'
            },
            findContratoProgramaEscolasOtimizado: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/contratos/:id/contrato-programa-escolas-otimizado'
            },
            findCriteriosAdesao: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/contratos/criterios-adesao'
            },
            findUfs: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/contratos/:periodo_letivo/:programa/ufs'
            },
            findMunicipios: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/contratos/:periodo_letivo/:programa/:uf/municipios'
            },
            findEscolas: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/contratos/:periodo_letivo/:programa/:municipio/escolas'
            },            
            delete: {
                method: 'DELETE',
                transformResponse: function(data, headers, statusCode) {
                        var finalRsponse = {
                            data: data,
                            responseStatusCode: statusCode
                        };
                    return finalRsponse;
                },
                url: APP_PROPERTY.URL + '/contratos/:id'
            }
        });

    }

}());
