$(document).ready(function() {
    $('[data-toggle="tooltip"]').tooltip();


    // var pull 		= $('#pull');
    // menu 		= $('#nav-ias ul');
    // menuHeight	= menu.height();
    // subMenu = $('.header-nav .main-menu li:hover ul');


    // $(window).resize(function(){
    //       var w = $(window).width();
    //       if(w > 320 && menu.is(':hidden')) {
    //         menu.removeAttr('style');
    //       }
    //   });

    // $(pull).on('click', function(e) {
    //   e.preventDefault();
    //   menu.toggle();
    //
    // });

    // subMenu.on('mouseover', function(event) {
    //   event.preventDefault();
    //   $(this).toggle();
    // });

    var isBreakPoint = function(bp) {
        var bps = [320, 480, 600, 768, 1024],
            w = $(window).width(),
            min, max
        for (var i = 0, l = bps.length; i < l; i++) {
            if (bps[i] === bp) {
                min = bps[i - 1] || 0
                max = bps[i]
                break
            }
        }
        return w > min && w <= max
    }

    if (isBreakPoint(600)) {

    }

    var options = [];

    $('.dropdown-menu a').on('click', function(event) {

        var $target = $(event.currentTarget),
            val = $target.attr('data-value'),
            $inp = $target.find('input'),
            idx;

        if ((idx = options.indexOf(val)) > -1) {
            options.splice(idx, 1);
            setTimeout(function() { $inp.prop('checked', false) }, 0);
        } else {
            options.push(val);
            setTimeout(function() { $inp.prop('checked', true) }, 0);
        }

        $(event.target).blur();

        return false;
    });




    // var $window = $(window),
    //       $btn-tb-form = $('.btn-tabela-form');
    //
    //   function resize() {
    //       if ($window.width() < 600) {
    //           return $html.addClass('mobile');
    //       }
    //
    //       $html.removeClass('mobile');
    //   }
    //
    //   $window
    //       .resize(resize)
    //       .trigger('resize');

    // $(pull).click( function() {
    //   menu.slideToggle();
    // })

    // $( "#btn-pesq" ).addClass( "form-control" );
    $(".btn-form-control").addClass("form-control");

    var logos = new Array();
    logos[0] = '<a class="" href="#"><img src="contents/images/logo_IAS_1.jpg" alt="logo_Intituto Ayrton Senna" /></a>';
    logos[1] = '<a class="" href="#"><img src="contents/images/logo_IAS_2.jpg" alt="logo_Intituto Ayrton Senna" /></a>';
    logos[2] = '<a class="" href="#"><img src="contents/images/logo_IAS_3.jpg" alt="logo_Intituto Ayrton Senna" /></a>';
    logos[3] = '<a class="" href="#"><img src="contents/images/logo_IAS_4.jpg" alt="logo_Intituto Ayrton Senna" /></a>';

    var rand = logos[Math.floor(Math.random() * logos.length)];
    var logo_iAS = document.getElementById("logo_iAS");
    if (logo_iAS && logo_iAS.innerHTML) {
        logo_iAS.innerHTML = rand;
    }
});