(function() {
    'use strict';

    angular.module('app.core').directive("iasFormDateErrorValidation", IasFormDateErrorValidation);

    function IasFormDateErrorValidation() {
        return {
            scope: {
                form: '=',
                startDateLabel: '@',
                endDateLabel: '@'
            },
            restrict: "E",
            templateUrl: "core/directives/error/partials/ias-form-date-error-validation.html"
        };
    }

})();