(function() {
    'use strict';

    angular.module('app.programas').factory('Programas', Programas);
    Programas.$inject = ['$resource', 'APP_PROPERTY'];

    function Programas($resource, APP_PROPERTY) {
        return $resource(APP_PROPERTY.URL + '/programas/:id', { id: '@id' }, {
            update: {
                method: 'PUT'
            },
            get: {
                method: 'GET'
            },
            filter: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/programas'
            },
            filterPageable: {
                method: 'GET',
                isArray: false,
                url: APP_PROPERTY.URL + '/programas/pageable'
            },
            ativo: {
                method: 'GET',
                url: APP_PROPERTY.URL + '/programas/ATIVO?size=50',
                isArray: true
            },
            ativosIgnoringPermission: {
                method: 'GET',
                url: APP_PROPERTY.URL + '/programas/ignoring-permission/ATIVO',
                isArray: true
            },
            ativos: {
                method: 'GET',
                url: APP_PROPERTY.URL + '/programas/ativos',
                isArray: true
            },
            ativosPorIdAnoLetivo: {
                method: 'GET',
                url: APP_PROPERTY.URL + '/programas/ativos/:idAnoLetivo',
                isArray: true
            },
            byUfAndMunicipioWithPermissionFilter: {
                method: 'GET',
                url: APP_PROPERTY.URL + '/programas/with-permission-filter',
                isArray: true
            },
            porIdEscola: {
                method: 'GET',
                url: APP_PROPERTY.URL + '/programas',
                isArray: true
            },
            findByStatus: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/programas/:status'
            },
            delete: {
                method: 'DELETE',
                transformResponse: function(data, headers, statusCode) {
                        var finalRsponse = {
                            data: data,
                            responseStatusCode: statusCode
                        };
                    return finalRsponse;
                },
                url: APP_PROPERTY.URL + '/programas/:id'
            },
            configuracaoProgramas: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/configuracao-programa'
            }
        });

    }

}());