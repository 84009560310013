(function(){
    'use strict';

    angular.module('app.core').service('objectService', ObjectService);
    ObjectService.$inject = ['$filter'];

    function ObjectService($filter) {
        var self = this;

        this.deepValue = function(obj, pathObj) {
            for (var i = 0, path = pathObj.split('.'), len = path.length; i < len; i++) {
                obj = obj[path[i]];

                if (!obj) {
                    break;
                }
            }

            return obj;
        };

        this.deepValueFormated = function(obj, pathObj, type) {
            var value = self.deepValue(obj, pathObj);

            if (type) {
                var formatedValue;

                switch (type) {
                    case 'datetime':
                        formatedValue = $filter('date')(value, 'dd/MM/yyyy HH:mm');
                        break;
                    case 'date': 
                        formatedValue = $filter('date')(value, 'dd/MM/yyyy');
                        break;
                    default:
                        formatedValue = value;
                }

                return formatedValue;
            } else {
                return value;
            }            
        }

    }

}());
