(function(){
    'use strict';

    angular.module('app.atributo').service('atributoService', AtributoService);
    AtributoService.$inject = ['Atributo'];

    function AtributoService(Atributo) {

        this.filter = function(filter) {
            return Atributo.filter(filter).$promise;            
        };

        this.get = function(id) {
            return Atributo.get({ id: id }).$promise;
        };

        this.save = function(atributo) {
            var entity = new Atributo(atributo);

            if (entity.id) {
                return entity.$update();
            } else {
                return entity.$save();
            }
        };

        this.delete = function(atributo) {
            return Atributo.delete({id: atributo.id}).$promise;
        };

        this.findTipos = function() {
            return Atributo.findTipos().$promise;
        };
        
        this.porPrograma = function(idPrograma) {
            return Atributo.porPrograma({'idPrograma':idPrograma}).$promise;
        };

        this.findEntidades = function() {
            return Atributo.findEntidades().$promise;
        };
        
        this.ativos = function() {
            return Atributo.ativos();
        };

        this.porTipoEntidadePrograma = function(tipoEntidade, idPrograma) {
            return Atributo.porTipoEntidadePrograma({'tipoEntidade' :tipoEntidade, 'idPrograma':idPrograma}).$promise;
        }

    }

}());
