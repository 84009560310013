/*
jslint
    devel: true, maxerr: 10
*/

/*
global
    required: true
*/

/*
 * Perfil de Acesso Search Controller
 * @module Perfil de Acesso
 * @author iFactory Solutions Ltda.
 * @version 1.0.0
 * @since 1.0.0
 */
(function() {
    'use strict';

    angular.module('app.perfilAcesso').controller('PerfilAcessoController', PerfilAcessoController);
    PerfilAcessoController.$inject = [
        'APP_STATE', 'APP_EVENT', 'SEARCH_BY_FIELD', '$state', '$scope', '$uibModal',
        'dialogService', 'toastService', 'PerfilAcessoService', 'SecurityManagerFactory'
    ];

    function PerfilAcessoController(APP_STATE, APP_EVENT, SEARCH_BY_FIELD, $state, $scope, $uibModal, 
        dialogService, toastService, service, SecurityManagerFactory) {
        var vm = this;

        // Initializing fields for searching.
        (function() {
            vm.hasPermissionView = SecurityManagerFactory.hasPermission('PLA:VI');
            vm.searchValue = "";
            vm.searchValueModel = "";
            vm.SEARCH_BY = SEARCH_BY_FIELD;
            vm.searchType = vm.SEARCH_BY.NAME;
            vm.recordsFoundTotal = 0;
            vm.searchWasPerformed = false;
            vm.records = [];

            $scope.$on(APP_EVENT.PERFIL_ACESSO.SAVED, function() {
                toastService.success("Operação realizada com sucesso.");
                vm.search();
            });

            $scope.$on(APP_EVENT.PERFIL_ACESSO.REFRESH, function() {
                vm.search();
            });
        }());

        vm.goNewPage = function() {
            $state.go(APP_STATE.PERFIL_ACESSO.NEW);
        };

        vm.goEditPage = function(id) {
            $state.go(APP_STATE.PERFIL_ACESSO.EDIT, {
                id: id
            });
        };

        vm.clearSearchValue = function() {
            vm.searchValue = "";
            vm.searchValueModel = "";
            vm.formSearch.$setPristine();
            vm.formSearch.$setUntouched();
        };

        vm.search = function() {
            vm.searchValue = vm.searchValueModel || vm.searchValue;
            vm.searchWasPerformed = false;
            vm.records = [];
            vm.recordsFoundTotal = vm.records.length;

            if (!vm.searchValueModel) {
                vm.searchValueModel = vm.searchValue;
            }

            if (vm.searchValue) {
                var filter = {};
                filter[vm.searchType.field] = vm.searchValue;

                service.search(filter)
                    .then(function(data) {
                        vm.records = angular.copy(data);
                    }, function(response) {
                        if (response.status != -1) {
                            toastService.error({
                                status: response.status,
                                message: response.data.message
                            });
                        } else {
                            // TODO Redirect to the default error page.
                            toastService.error({
                                status: response.status,
                                message: "Não foi possível realizar a pesquisa."
                            });
                        }
                    })
                    .finally(function() {
                        vm.searchWasPerformed = true;
                        vm.recordsFoundTotal = vm.records.length;
                        vm.formSearch.$setPristine();
                    });
            }
        };

        vm.showFeatures = function(record) {
            service.assignedFeatures(record.id).then(function(data) {
                var modal = $uibModal.open({
                    ariaLabelledBy: 'modal-title',
                    ariaDescribedBy: 'modal-body',
                    templateUrl: 'perfil-acesso/partials/perfil-acesso-feature-modal.tpl.html',
                    controllerAs: 'vm',
                    size: 'lg',
                    controller: function($uibModalInstance) {
                        var _vm = this;
                        _vm.features = angular.copy(data);
                        _.each(_vm.features, function(feature) {
                            feature.partitionActions = _.groupBy(feature.acoes, function(element, index) {
                                return index % Math.round(feature.acoes.length / 2);
                            });
                        });
                        _vm.record = record;

                        _vm.cancel = function() {
                            $uibModalInstance.dismiss('cancel');
                        };
                    }
                });

                modal.result.then(new Function(), new Function());
            }, function(error) {
                toastService.error("Não foi possível recuperar as funcionalidades do sistema vinculadas ao perfil de acesso.");
            });
        };

        vm.showUsers = function(record) {
            service.assignedUsers(record.id).then(function(data) {
                var modal = $uibModal.open({
                    ariaLabelledBy: 'modal-title',
                    ariaDescribedBy: 'modal-body',
                    templateUrl: 'perfil-acesso/partials/perfil-acesso-user-modal.tpl.html',
                    controllerAs: 'vm',
                    size: 'lg',
                    controller: function($uibModalInstance) {
                        var _vm = this;
                        _vm.users = angular.copy(data);
                        _vm.record = record;

                        _vm.cancel = function() {
                            $uibModalInstance.dismiss('cancel');
                        };
                    }
                });

                modal.result.then(new Function(), new Function());
            }, function(error) {
                toastService.error("Não foi possível recuperar os usuários vinculados ao perfil de acesso.");
            });
        }

        vm.delete = function(entity) {
            dialogService.delete().then(function() {
                service.delete({ id: entity.id })
                    .then(function(response) {
                        if (response.responseStatusCode == 200) {
                            toastService.success("Registro removido com sucesso.");
                        } else if (response.responseStatusCode == 202) {
                            toastService.success("Registro inativado com sucesso.");
                        }
                        vm.search();
                    }, function(response) {
                        toastService.error({
                            status: response.status,
                            message: response.data.message
                        });
                    });
            }, function() {});
        };
    }

}());