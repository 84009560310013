(function() {
    'use strict';

    angular.module('app.formExemplo').factory('FormExemplo', FormExemplo);
    FormExemplo.$inject = ['$resource', 'APP_PROPERTY'];

    function FormExemplo($resource, APP_PROPERTY) {
        return $resource(APP_PROPERTY.URL + '/formularioExemplo/:id', { id: '@id' }, {
            update: {
                method: 'PUT'
            },
            filter: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/formularioExemplo/busca'
            }
        });

    }

}());