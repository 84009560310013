/*
jslint
    devel: true, maxerr: 10
*/

/*
global
    required: true
*/

/*
 * Login Module
 * @author iFactory Solutions Ltda.
 * @version 1.0.0
 * @since 1.0.0
 */
(function () {
    'use strict';

    angular.module('app.login', ['app.core']).config(config);
    config.$inject = ['$stateProvider', '$urlRouterProvider', 'APP_STATE'];

    function config($stateProvider, $urlRouterProvider, APP_STATE) {
        $stateProvider
            .state(APP_STATE.LOGIN.BASE, {
                url: '/login',
                templateUrl: 'login/partials/login.tpl.html',
                controller: 'LoginController',
                controllerAs: 'vm'
            })
            .state(APP_STATE.LOGIN.RESET_PASSWORD, {
                url: '/redefinir-senha/:id',
                templateUrl: 'login/partials/reset-password.tpl.html',
                controller: 'ResetPasswordController',
                controllerAs: 'vm'
            });
    }
})();