(function(){
    'use strict';

    angular.module('app.core').service('scrollService', ScrollService);
    ScrollService.$inject = ['$window', '$timeout'];

    function ScrollService($window, $timeout) {
        var self = this;

        this.scrollTo = function(elementId) {
            $timeout(function() {
                if (elementId) {
                    var element = $window.document.getElementById(elementId);
            
                    if (element) {
                        element.scrollIntoView();
                    }
                } else {
                    self.scrollTop();
                }
            }, 100);
        };

        this.scrollTop = function() {
            $window.scrollTo(0, 0);
        };
    }

}());
