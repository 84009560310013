(function() {
    'use strict';

    angular.module('app.core').directive("iasFormFieldPreviewServerError", IasFormFieldPreviewServerError);

    function IasFormFieldPreviewServerError() {
        return {
            scope: {
                ngModel: '='
            },
            restrict: "E",
            templateUrl: "core/directives/error/partials/ias-form-field-preview-server-error.html"
        };
    }

})();