/*
jslint
    devel: true, maxerr: 10
*/

/*
global
    required: true
*/

/*
 * Selection Criteria Module
 * @author iFactory Solutions Ltda.
 * @version 1.0.0
 * @since 1.0.0
 */
(function() {
    'use strict';

    angular.module('app.criterioSelecao', ['app.core']).config(config);
    config.$inject = ['$stateProvider', 'APP_STATE'];

    function config($stateProvider, APP_STATE) {
        $stateProvider
            .state(APP_STATE.CRITERIO_SELECAO.BASE, {
                url: '/criterio-selecao',
                abstract: true,
                views: {
                    'content': {
                        template: '<div ui-view="internal"></div>',
                        controller: 'CriterioSelecaoController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state(APP_STATE.CRITERIO_SELECAO.SEARCH, {
                url: '/search',
                views: {
                    'internal': {
                        templateUrl: 'criterio-selecao/partials/criterio-selecao-search.tpl.html'
                    }
                },
                data: {
                    permission: "CRS:RE",
                    breadCrumb: [{
                            display: "Cadastros",
                            active: false
                        },
                        {
                            display: "Critérios de Seleção",
                            active: true
                        }
                    ],
                    search: {
                        enabled: false
                    }
                }
            })
            .state(APP_STATE.CRITERIO_SELECAO.NEW, {
                url: '/new',
                views: {
                    'internal': {
                        templateUrl: 'criterio-selecao/partials/criterio-selecao-form.tpl.html',
                        controller: 'CriterioSelecaoFormController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    permission: "CRS:CR",
                    breadCrumb: [{
                            display: "Cadastros",
                            active: false
                        },
                        {
                            display: "Critérios de Seleção",
                            active: false,
                            stateName: APP_STATE.CRITERIO_SELECAO.SEARCH
                        },
                        {
                            display: "Novo",
                            active: true
                        }
                    ],
                    search: {
                        placeHolder: "Digite o nome do critério de seleção",
                        enabled: false
                    }
                }
            })
            .state(APP_STATE.CRITERIO_SELECAO.EDIT, {
                url: '/edit/:id',
                views: {
                    'internal': {
                        templateUrl: 'criterio-selecao/partials/criterio-selecao-form.tpl.html',
                        controller: 'CriterioSelecaoFormController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    permission: "CRS:VI",
                    breadCrumb: [{
                            display: "Cadastros",
                            active: false
                        },
                        {
                            display: "Critérios de Seleção",
                            active: false,
                            stateName: APP_STATE.CRITERIO_SELECAO.SEARCH
                        },
                        {
                            display: "Edição",
                            active: true
                        }
                    ],
                    search: {
                        placeHolder: "Digite o nome do critério de seleção",
                        enabled: false
                    }
                }
            });
    }
}());