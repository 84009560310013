(function(){
    'use strict';

    angular.module('app.coleta.resposta').service('coletaRespostaProcessamentoLogService', ColetaRespostaProcessamentoLogService);
    ColetaRespostaProcessamentoLogService.$inject = ['$q', 'ColetaRespostaProcessamentoLog'];

    function ColetaRespostaProcessamentoLogService($q, ColetaRespostaProcessamentoLog) {
       
        this.findByColetaRespostaProcessamentoId = function(id) {
            return ColetaRespostaProcessamentoLog.findByColetaRespostaProcessamentoId({ id: id }).$promise;
        };

        this.save = function(idSolicitacao) {
           return ColetaRespostaProcessamentoLog.save(idSolicitacao);
        };

        this.update = function(id, idSolicitacao) {
           var data = idSolicitacao;
           data.idColetaResposta = id; 
           return ColetaRespostaProcessamentoLog.update(data);
        };

    }

}());