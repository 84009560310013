(function(){
    'use strict';

    angular.module('app.coletaRubrica.resposta', ['app.core']).config(config);
    config.$inject = ['$stateProvider', 'APP_STATE'];

    function config($stateProvider, APP_STATE) {
        $stateProvider
            .state(APP_STATE.COLETA_RUBRICA.RESPOSTA_COLETA.ANONYMOUS, {
                url: '/coletaRubricas/respostas/anonima/:id',
                templateUrl: 'coleta/resposta/anonima/partials/coleta-resposta-anonima.html',
                controller: 'ColetaRespostaAnonimaController',
                controllerAs: 'vm'
            })
            .state(APP_STATE.COLETA_RUBRICA.RESPOSTA_COLETA.BASE, {
                url: '/coletaRubricas/respostas',
                abstract: true,
                views: {
                    'internal': {
                        template: '<div ui-view="sub-internal"></div>',
                        controller: 'ColetaRespostaController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state(APP_STATE.COLETA_RUBRICA.RESPOSTA_COLETA.SEARCH, {
                url: '/search',
                views: {
                    'sub-internal': {
                        templateUrl: 'coleta/resposta/autenticada/partials/coleta-resposta-search.html'
                    }
                },
                params: {
                    coleta: null
                },
                data: {
                    permission: "REC:VC",
                    breadCrumb: [
                        {
                            display: "Formulários",
                            active: false
                        },
                        {
                            display: "Coletas",
                            active: false,
                            stateName: APP_STATE.COLETA.SEARCH
                        },
                        {
                            display: "Respostas",
                            active: true
                        }
                    ],
                    search: {
                        enabled: false
                    }
                }
            })
            .state(APP_STATE.COLETA_RUBRICA.RESPOSTA_COLETA.EDIT, {
                url: '/edit/:id',
                views: {
                    'sub-internal': {
                        templateUrl: 'coleta/resposta/autenticada/partials/coleta-resposta-form.html',
                        controller: 'ColetaRespostaFormController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    permission: "REC:PD",
                    breadCrumb: [
                        {
                            display: "Formulários",
                            active: false
                        },
                        {
                            display: "Coletas",
                            active: false,
                            stateName: APP_STATE.COLETA.SEARCH
                        },
                        {
                            display: "Respostas",
                            active: false,
                            stateName: APP_STATE.RESPOSTA_COLETA.SEARCH
                        },
                        {
                            display: "Preencher",
                            active: true
                        }
                    ],
                    search: {
                        enabled: false
                    }
                }
            });
    }
}());
