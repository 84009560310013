(function() {
    'use strict';
    angular.module('app.usuario').controller('usuarioNewController', usuarioNewController);
    usuarioNewController.$inject = [
        'APP_STATE', '$state', 'Usuario', '$scope',
        'DialogService', 'programasService', 'ufService',
        'municipiosService', 'escolasService', 'turmasService',
        'UsuarioPerfil', 'APP_PROPERTY', 'toastService', 
        'validateService', 'VALIDATION_PATTERN', 'APP_EVENT', 
        'SecurityManagerFactory', 'Usuario', 'grupoMunicipioService', 'alunoService','coletaService'
    ];

    function usuarioNewController(APP_STATE, $state, Usuario, $scope,
        DialogService, programasService, ufService,
        municipiosService, escolasService, turmasService,
        UsuarioPerfil, APP_PROPERTY, toastService, 
        validateService, VALIDATION_PATTERN, APP_EVENT,
        securityManagerFactory, userResource, grupoMunicipioService, alunoService, coletaService) {

        var vm = this;
        vm.usuario = {};
        vm.ufs = ufService.ativos();
        vm.perfilAssociado = false;
        vm.validEmail = {
            emailValid: 'me@example.com'
        };
        vm.perfilView = {};
        vm.mySwitch = false;
        vm.validation = {
            hasError: false,
            message: ''
        };
        vm.refreshSearch = false;
        vm.aluno = null;
        
        vm.VALIDATION_PATTERN = VALIDATION_PATTERN;

        vm.validateDate = function() {
            if (validateService.date(vm.usuarioForm.dataNascimento)) {
                return true;
            } else {
                vm.usuarioForm.dataNascimento.$setValidity("dataNascimento", false);
            }
        };

        vm.getMunicipiosPorUf = function(ufId) {
            if (ufId > 0) {
                vm.municipios = municipiosService.porUf(ufId);
                vm.usuarioForm.municipio.$setValidity("required", false);
            } else {
                vm.municipios = [];
                vm.usuarioForm.municipio.$setValidity("required", true);
            }
        };

        vm.checkMuncipios = function(municipioId) {
            if (municipioId > 0) {
                vm.usuarioForm.municipio.$setValidity("required", true);
            }
        }

        if (typeof($state.params.id) != 'undefined' && $state.params.id != null) {
            vm.mySwitch = true;
            vm.goEditPage = function() {
                vm.mySwitch = false;
                vm.hasError = false;
                vm.disableEmail = false;
                vm.email = vm.usuario.pessoa.email;
            };
        }

        if ($state.is(APP_STATE.USUARIO.EDIT)) {

            if ($state.params.id) {
                vm.mostrarBotaoPerfil = true;
                vm.perfilView = {};

                Usuario.get({ id: $state.params.id }).$promise.then(function(response) {
                    vm.usuario = response;

                    _normalizeCpf();

                    UsuarioPerfil.viewPerfil({ id: $state.params.id }).$promise.then(function(pefilView) {
                        if (undefined !== pefilView.model) {
                            vm.usuario.perfilAcesso = {
                                nome: pefilView.model.perfilAcesso.nome,
                                situacao: pefilView.model.situacao.value,
                                descricao: pefilView.model.perfilAcesso.descricao
                            };

                            vm.perfilAssociado = true;
                            vm.mostrarBotaoPerfil = false;
                        }

                        vm.perfilView = pefilView;
                        vm.usuarioForm.$setPristine();
                    }, function(error) {});

                    if (vm.usuario.pessoa.dataNascimento != null) {
                        vm.usuario.pessoa.dataNascimento = moment(vm.usuario.pessoa.dataNascimento, 'YYYY-MM-DD').format('DD/MM/YYYY')
                    }

                    if (vm.usuario.pessoa.municipio) {
                        var pessoa = vm.usuario.pessoa;
                        pessoa.uf = vm.usuario.pessoa.municipio.uf;
                        vm.usuario.pessoa = pessoa;
                        vm.getMunicipiosPorUf(vm.usuario.pessoa.uf.id);
                        vm.usuarioForm.municipio.$invalid = false;
                    }
                    vm.usuarioForm.municipio.$setValidity("required", is.not.existy(vm.usuario.pessoa.uf));

                    alunoService.findByIdPessoa(vm.usuario.pessoa.id).then(function(aluno) {
                        vm.aluno = aluno;
                    }, function(error) {});
                }, function(error) {});
            }
        } else {
            vm.usuario = {};
            vm.mostrarBotaoPerfil = true;
            UsuarioPerfil.viewPerfilSemId().$promise.then(function(pefilView) {
                vm.perfilView = pefilView;
            }, function(error) {console.log(error);});
        }

        vm.linkProfile = function() {
            var operation = is.existy($state.params.id) ? 'update' : 'create';

            if (operation === 'update') {
                UsuarioPerfil.viewPerfil({ id: $state.params.id }).$promise.then(function(pefilView) {
                    vm.perfilView = pefilView;
                }, function(error) {});
            }

            var itemFormModal = DialogService.modal(
                'usuario/partials/usuarios-perfil.html',
                function($uibModalInstance) {
                    var _vm = this;
                    _vm.invalidFormLocalidade = false;
                    _vm.programas = programasService.ativosIgnoringPermission();
                    _vm.ufs = ufService.ativos();
                    _vm.usuario = vm.usuario;
                    _vm.regionais = [];
                    _vm.municipios = [];
                    _vm.grupoMunicipios = grupoMunicipioService.findStatus({status: 'ATIVO'});
                    _vm.perfis = [];
                    _vm.perfisNew = [];
                    _vm.perfisDeleted = [];
                    _vm.situacoes = vm.perfilView.view.situacoes;
                    _vm.granularidades = vm.perfilView.view.granularidades;
                    _vm.perfisAcesso = vm.perfilView.view.perfisAcesso;
                    _vm.disabledPerfilAcesso = false;
                    
                    var userLogged = securityManagerFactory.getUserCredentials();
                    
                    if (!userLogged.admin) {
                        userResource.get({ id: userLogged.id }).$promise.then(function(user) {
                            if (user.usuarioPerfisAcesso.length > 0) {
                                var perfilAcessoId = user.usuarioPerfisAcesso[0].perfilAcesso.id;

                                UsuarioPerfil.hierarquiasPorPerfil({id:perfilAcessoId}).$promise.then(function(hierarquias) {
                                    _vm.perfisAcesso = hierarquias;

                                    var existePerfilHierarquia = _.filter(hierarquias, function(hierarquia){
                                        return hierarquia.id == vm.usuario.perfil.perfilAcesso.id;
                                    });

                                    if (existePerfilHierarquia.length == 0) {
                                        _vm.disabledPerfilAcesso = true;
                                        _vm.perfisAcesso = vm.perfilView.view.perfisAcesso;
                                    }
                                });
                            } else {
                                if (typeof($state.params.id) == 'undefined') {
                                    _vm.perfisAcesso = [];
                                } else if (vm.usuario.perfil.perfilAcesso) {
                                    _vm.disabledPerfilAcesso = true;
                                } else {
                                    _vm.perfisAcesso = [];    
                                }
                            }
                        });
                    }

                    _vm.usuario.perfil = null;
                    _vm.usuario.perfil = {
                        situacao: { key: "A" }
                    };

                    _vm.getRegionais = function(uf) {
                        if (uf) {
                            _vm.usuario.perfil.regionalUF = null;
                            _vm.regionais = ufService.regionais(uf.id);
                        }
                    };

                    _vm.getMunicipiosPorUf = function(uf) {
                        if (uf) _vm.municipios = municipiosService.porUf(uf.id);
                    };

                    _vm.getMunicipiosPorRegional = function(regional) {
                        if (regional) _vm.municipios = municipiosService.porRegionalUf(regional.id);
                    };

                    _vm.getRegionalMunicipio = function(municipio) {
                        if (municipio) _vm.regionaisMunicipios = municipiosService.regionais(municipio.id);
                    };

                    _vm.getEscolasPorRegionalMunicipio = function(regionalMunicipio) {
                        if (regionalMunicipio) _vm.escolas = escolasService.porRegionalMunicipio(regionalMunicipio.id);
                    };

                    _vm.getEscolasPorMunicipio = function(municipio) {
                        if (municipio) _vm.escolas = escolasService.porMunicipio(municipio.id);
                    };

                    _vm.getTurmas = function(escola) {
                        if (escola) _vm.turmas = turmasService.porEscola(escola.id);
                    };

                    _vm.getColetas = function(programa) {
                        if(['9'].indexOf(vm.usuario.perfil.granularidade.key) != -1){
                            if (programa) _vm.coletas = coletaService.porPrograma(programa.id);
                        }
                    };

                    _vm.clearSelect = function(form){
                        Object.keys(_vm.usuario.perfil).filter(function(item){
                            return item !== 'granularidade' && item !== 'situacao' && item !== 'perfilAcesso' && item !== 'id';
                        }).forEach(function(item){
                            delete _vm.usuario.perfil[item];
                        });
                    }

                    if (vm.perfilView) {
                        var localidades = "";
                        var programa = "";
                        var uf = "";
                        var regionalUF = "";
                        var municipio = "";
                        var regionalMunicipio = "";
                        var escola = "";
                        var turma = "";

                        if (undefined !== vm.perfilView.model) {
                            _vm.usuario.perfil = {
                                id: vm.perfilView.model.id,
                                situacao: { key: vm.perfilView.model.situacao.key },
                                localidades: localidades,
                                perfilAcesso: vm.perfilView.model.perfilAcesso,
                                programa: programa,
                                uf: uf,
                                regionalUF: regionalUF,
                                municipio: municipio,
                                regionalMunicipio: regionalMunicipio,
                                escola: escola,
                                turma: turma
                            };

                            if (undefined !== vm.perfilView.model.items && vm.perfilView.model.items.length) {
                                _vm.perfis = vm.perfilView.model.items;
                            }
                        }

                    }

                    _vm.incluirPerfil = function(perfil) {
                        if (_vm.modalFormLocalidade.$invalid) {
                            _vm.invalidFormLocalidade = true;
                            return false;
                        }

                        // Verificar se tem repetidos
                        var itemRepetido = false;
                        var programaRepetido = false;
                        var ufRepetido = false;
                        var todosPerfis = _vm.perfisNew.concat(_vm.perfis);

                        var _objLocalidades = function() {
                            return {
                                programa: 0,
                                uf: 0,
                                regionalUf: 0,
                                grupoMunicipio: 0,
                                regionalMunicipio: 0,
                                municipio: 0,
                                escola: 0,
                                turma: 0,
                                coleta: 0
                            };
                        }

                        var _objPerfil = function() {
                            var objPerfil = _objLocalidades();

                            angular.forEach(perfil, function(value, index) {
                                if (is.existy(objPerfil[index]) && value) {
                                    if (is.existy(value.id) || undefined !== value.id) {
                                        objPerfil[index] = value.id;
                                    }
                                }
                            });

                            return objPerfil;
                        }

                        var _perfis = function() {
                            var perfis = [];
                            angular.forEach(todosPerfis, function(value, index) {
                                var objValue = _objLocalidades();

                                angular.forEach(value, function(v, i) {
                                    if (is.existy(objValue[i]) && v) {
                                        if (is.existy(v.id) || undefined !== v.id) {
                                            objValue[i] = v.id;
                                        }
                                    }
                                });

                                perfis.push(objValue);
                            });

                            return perfis;
                        }

                        var _compararPerfil = function(perfil1, perfil2){
                            var props1 = Object.getOwnPropertyNames(perfil1);
                            var props2 = Object.getOwnPropertyNames(perfil2);

                            if(props1.length != props2.length){
                                return false;
                            }

                            var itemRepetido = true;

                            for(var i = 0; i < props1.length; i++){
                                if(perfil1[props1[i]].toString() != perfil2[props1[i]].toString()){
                                    itemRepetido = false;
                                    break;
                                }
                            }

                            return itemRepetido;
                        }

                        angular.forEach(_perfis(), function(perfil, index) {
                            if(_compararPerfil(_objPerfil(), perfil)){
                                itemRepetido = true;    
                            }
                        });

                        if (itemRepetido) {
                            toastService.error({
                                message: "A localidade já foi inserida. Por favor tente outra."
                            }, 'flash-modal');
                            return true;
                        }

                        var granularidade = _vm.granularidades.filter(function(item) {
                            return item.key == perfil.granularidade.key;
                        });

                        var copyPerfil = angular.copy(perfil);
                        copyPerfil.granularidade = granularidade[0];
                        _vm.perfisNew.push(copyPerfil);

                        perfil.granularidade.key = null;
                        perfil.programa = null;
                        perfil.uf = null;
                        perfil.municipio = null;
                        perfil.grupoMunicipio = null;
                        perfil.regionalMunicipio = null;
                        perfil.regionalUF = null;
                        perfil.escola = null;
                        perfil.turma = null;
                        perfil.coleta = null;

                        if (_vm.modalForm.$valid) {
                            _vm.modalForm.$setPristine();
                        }

                        _vm.invalidFormLocalidade = false;
                    };

                    _vm.excluirPerfil = function(i, tipoItem) {
                        DialogService.confirm("Você tem certeza que deseja excluir a localidade?", function() {
                            if (tipoItem == 'new') {
                                _vm.perfisNew.splice(i, 1);
                            } else if (tipoItem == 'itens') {
                                _vm.perfisDeleted.push(_vm.perfis[i]);
                                _vm.perfis.splice(i, 1);
                            }
                        });
                    };

                    var _filtrarItens = function(itens) {
                        var itensFiltrados = [];
                        angular.forEach(itens, function(value, index) {
                            var item = {};
                            //item.id = value.id;
                            item.granularidade = value.granularidade;
                            item.perfilAcesso = value.perfilAcesso;
                            item.situacao = value.situacao;

                            if (value.programa != "") {
                                item.programa = value.programa;
                            }
                            if (value.uf != "") {
                                item.uf = value.uf;
                            }
                            if (value.municipio != "") {
                                item.municipio = value.municipio;
                            }
                            if (value.regionalMunicipio != "") {
                                item.regionalMunicipio = value.regionalMunicipio;
                            }
                            if (value.grupoMunicipio != "") {
                                item.grupoMunicipio = value.grupoMunicipio;
                            }
                            if (value.regionalUF != "") {
                                item.regionalUF = value.regionalUF;
                            }
                            if (value.escola != "") {
                                item.escola = value.escola;
                            }
                            if (value.turma != "") {
                                item.turma = value.turma;
                            }
                            if (value.coleta != "") {
                                item.coleta = value.coleta;
                            }

                            itensFiltrados.push(item);
                        });

                        return itensFiltrados;
                    };

                    _vm.ok = function(perfil) {
                        _vm.modalForm.$submitted = true;
                        if (_vm.modalForm.$valid) {

                            perfil.usuario = { id: vm.usuario.id };
                            perfil.items = _vm.perfis;
                            perfil.newItems = _filtrarItens(_vm.perfisNew);
                            perfil.deletedItems = _vm.perfisDeleted;
                            if (perfil.id) {
                                if (_vm.perfisNew.length == 0 && _vm.perfis.length == 0) {
                                    toastService.error({
                                        message: "Informe pelo menos uma localidade."
                                    }, 'flash-modal');
                                    return true;
                                }

                                UsuarioPerfil.editarAssociacaoPerfil(perfil).$promise.then(function(response) {
                                    UsuarioPerfil.viewPerfil({ id: perfil.usuario.id }).$promise.then(function(data) {
                                        vm.usuario.perfilAcesso.nome = data.model.perfilAcesso.nome;
                                        vm.usuario.perfilAcesso.situacao = data.model.situacao.value;
                                        vm.usuario.perfilAcesso.descricao = data.model.perfilAcesso.descricao;
                                        vm.perfilView = data;
                                        _vm.perfis = data.model.itens;
                                        vm.perfilAssociado = true;
                                        toastService.success("Perfil foi editado com sucesso.");

                                        $uibModalInstance.close();
                                    }, function(error) {});
                                }, function(response) {
                                    toastService.error({
                                        message: response.data.message
                                    }, 'flash-modal');
                                });
                            } else {
                                if (_vm.perfis.length === 0 && _vm.perfisNew.length === 0) {
                                    toastService.error({
                                        message: "Informe pelo menos uma localidade."
                                    }, 'flash-modal');
                                    return true;
                                }
                                var usuarioPerfisAcesso = [];
                                if (perfil.usuario.id !== undefined && perfil.usuario.id !== null) {
                                    UsuarioPerfil.associarPerfil(perfil).$promise.then(function(response) {
                                        UsuarioPerfil.viewPerfil({ id: perfil.usuario.id }).$promise.then(function(data) {
                                            vm.usuario.perfilAcesso = {
                                                nome: data.model.perfilAcesso.nome,
                                                situacao: data.model.situacao.value,
                                                descricao: data.model.perfilAcesso.descricao
                                            };
                                            vm.perfilView = data;
                                            _vm.perfis = data.model.itens;
                                            vm.perfilAssociado = true;
                                            vm.mostrarBotaoPerfil = false;
                                            toastService.success("Perfil foi vinculado com sucesso.");

                                            $uibModalInstance.close();
                                        }, function(error) {});
                                    }, function(response) {
                                        toastService.error({
                                            status: response.status,
                                            message: response.data.message
                                        }, 'flash-modal');
                                    });
                                } else {
                                    usuarioPerfisAcesso.push(perfil);
                                    vm.usuario.usuarioPerfisAcesso = usuarioPerfisAcesso;
                                    vm.perfilView.model = usuarioPerfisAcesso[0];
                                    vm.perfilView.model.perfilAcesso = usuarioPerfisAcesso[0].perfilAcesso;
                                    _.forEach(usuarioPerfisAcesso[0].newItems, function(element) {
                                        vm.perfilView.model.items.push(element);
                                    });
                                    $uibModalInstance.close();
                                }

                            }
                        }
                    };

                    _vm.addItem = function() {
                        $uibModalInstance.close();
                    };

                    _vm.cancel = function() {
                        $uibModalInstance.dismiss('cancel');
                    };

                    _vm.changeAccessProfile = function() {
                        if (vm.perfilView.model && vm.perfilView.model.items && vm.perfilView.model.items.length) {
                            if (is.not.existy(vm.perfilView.model.deletedItems)) {
                                vm.perfilView.model.deletedItems = [];
                            }
                            _.forEach(vm.perfilView.model.items, function(element) {
                                if (is.existy(element.id)) {
                                    vm.perfilView.model.deletedItems.push(element);
                                }
                            });
                            if (is.existy(vm.perfilView.model.deletedItems) && vm.perfilView.model.deletedItems.length > 0) {
                                _vm.perfisDeleted = angular.copy(vm.perfilView.model.deletedItems);
                            }
                            vm.perfilView.model.items = [];
                            _vm.perfis = [];
                        }
                    };
                }
            );
        };

        vm.goNewPage = function() {
            $state.go(APP_STATE.USUARIO.NEW);
        };

        vm.goSearchPage = function() {
            if (vm.refreshSearch) {
                $scope.$emit(APP_EVENT.USUARIO.REFRESH);
            }

            $state.go(APP_STATE.USUARIO.SEARCH);
        };

        vm.stayPage = function() {
            $state.reload();
        };

        vm.edit = function(usuario, i) {
            vm.update = angular.copy(usuario);
            index = i;
        };

        vm.extractNomeCurto = function(nome) {
            var nomeCurto = "";

            if (is.existy(nome) && is.not.empty(nome)) {
                var normalizedNome = nome.replace(/\s{2,}/g, ' ');
                var nomes = normalizedNome.split(" ");

                if (nomes.length > 1 && nomes[nomes.length - 1].length > 2) {
                    nomeCurto = nomes[0] + " " + nomes[nomes.length - 1];
                } else {
                    nomeCurto = nomes[0]
                }
            }

            vm.usuario.pessoa.nomeCurto = nomeCurto;
        };

        var _showErrorMessage = function(error) {
            var msg;
            var errorData = error.data;
            var description = errorData.description;

            if (is.existy(description) && is.not.empty(description)) {
                msg = errorData.message + " " + description;
            } else {
                msg = errorData.message;
            }

            toastService.error(msg);
        };

        var _normalizeCpf = function() {
            if (is.existy(vm.usuario.pessoa.cpf)) {
                var cpf = vm.usuario.pessoa.cpf.replace(/\s/g, '');

                if (is.empty(cpf)) {
                    vm.usuario.pessoa.cpf = null;
                }
            }
        };

        vm.save = function() {
            var usuario = vm.usuario;

            if (usuario.pessoa.dataNascimento) {
                var dataNascimento = moment(usuario.pessoa.dataNascimento, 'DD/MM/YYYY').format('YYYY-MM-DD');
                usuario.pessoa.dataNascimento = dataNascimento;
            }

            usuario.login = usuario.pessoa.email;
            if (!usuario.id) {
                usuario.senha = usuario.pessoa.email;
            }
            
            _normalizeCpf();

            if (typeof($state.params.id) != 'undefined') {

                Usuario.update(usuario, function(response) {

                    vm.refreshSearch = true;
                    toastService.success("Usuário foi salvo com sucesso.");
                    vm.goSearchPage();
                }, function(error) {
                    vm.refreshSearch = false;
                    _showErrorMessage(error);
                });
            } else {
                // salvar
                Usuario.save(usuario, function(data) {

                    vm.refreshSearch = true;
                    vm.validation.hasOK = true;
                    toastService.success("Usuário foi salvo com sucesso.");
                    vm.goSearchPage();
                }, function(error) {
                    vm.refreshSearch = false;
                    vm.validation.hasError = true;
                    _showErrorMessage(error);
                });
            }

        };
    }
}());