(function() {
    'use strict';

    angular.module('app.core').directive("iasTableModal", IasTableModal);
    IasTableModal.$inject = ['$filter', 'objectService', 'dialogService'];

    function IasTableModal($filter, objectService, dialogService) {
        var defaults = {
            onInitializeItem: null
        };

        return {
            scope: {
                title: '@',
                sortField: '@?',
                fields: '=',
                items: '=',
                deleted: '=?',
                msnDelete: '@',
                canCreate: '=?',
                canUpdate: '=?',
                canDelete: '=?',
                ngDisabled: '=?',
                onShowForm: '&?',
                onSave: '&?',
                onlyUpdate: '=?',
                selectedItems: '=?',
                hasAction: '=?',
                hasClear: '=?',
                onSelectChange: '&?',
                onSelectedItemsChange: '&?',
                onInitializeItem: '&?',
                onRemove: '&?'
            },
            restrict: "E",
            templateUrl: "core/directives/table/partials/ias-table-modal.html",
            link: function(scope, element, attr) {
                scope.canUpdate = typeof scope.canUpdate === 'undefined' ? true : scope.canUpdate;
                scope.canDelete = typeof scope.canDelete === 'undefined' ? true : scope.canDelete;
                scope.selectedItems = scope.selectedItems || [];
                scope.selectedAll = false;
                scope.hasAction = is.existy(scope.hasAction) ? scope.hasAction : true;
                scope.hasClear = is.existy(scope.hasClear) ? scope.hasClear : false;
                scope.onInitializeItem = scope.onInitializeItem || defaults.onInitializeItem;

                scope.toggleMark = function(item) {
                    var itemIndex = scope.selectedItems.indexOf(item);

                    if (itemIndex === -1) {
                        scope.selectedItems.push(item);
                    } else {
                        scope.selectedItems.splice(itemIndex, 1);
                    }

                    if (scope.onSelectedItemsChange) {
                        scope.onSelectedItemsChange({ selectedItems: scope.selectedItems });
                    }
                };

                scope.toggleSelectAll = function() {
                    scope.selectedAll = !scope.selectedAll;

                    if (scope.selectedAll) {
                        angular.forEach(scope.items, function(item, index) {
                            if (scope.selectedItems.indexOf(item) === -1) {
                                scope.selectedItems.push(item);
                                item.selected = true;
                            }
                        });
                    } else {
                        angular.forEach(scope.items, function(item, index) {
                            var itemIndex = scope.selectedItems.indexOf(item);

                            if (itemIndex !== -1) {
                                scope.selectedItems.splice(itemIndex, 1);
                                item.selected = false;
                            }
                        });
                    }
                };

                scope.deleteSelected = function() {
                    var msnDelete = (scope.msnDelete) ? scope.msnDelete : null;
                    dialogService.delete(msnDelete).then(function() {
                        angular.forEach(scope.selectedItems, function(value, index) {
                            var deletedItem = scope.items.splice(scope.items.indexOf(value), 1);

                            if (scope.deleted) {
                                if (deletedItem.length && deletedItem[0].id) {
                                    scope.deleted.push({ id: deletedItem[0].id });
                                }
                            }
                        });

                        if (scope.onRemove) {
                            scope.onRemove();
                        }

                        scope.selectedItems = [];
                    }, function() {});
                };

                scope.showCreateForm = function() {
                    if (scope.onShowForm) {
                        scope.onShowForm();
                    }
                };

                scope.showEditForm = function() {
                    if (scope.onShowForm && (scope.selectedItems.length == 1)) {
                        scope.onShowForm({ item: scope.selectedItems[0] });
                    }
                };

                scope.save = function() {
                    if (scope.onSave) {
                        scope.onSave();
                    }
                };

                scope.format = function(item, field) {
                    if (field.type == "date") {
                        return $filter('date')(objectService.deepValue(item, field.name), 'dd/MM/yyyy');
                    } else {
                        return objectService.deepValue(item, field.name);
                    }
                };

                var _clearWatcher = scope.$watch("hasClear", function(hasClear) {
                    if (hasClear && !scope.clearTableByPass) {
                        angular.forEach(scope.items, function(item, index) {
                            item.selected = false;
                        });

                        scope.selectedItems = [];
                        scope.hasClear = false;
                        scope.clearTableByPass = true;
                    } else {
                        scope.clearTableByPass = false;
                    }
                });

                var _selectedItemsWatcher = scope.$watch("selectedItems", function(selectedItems) {
                    if (scope.onSelectedItemsChange) {
                        scope.onSelectedItemsChange({ selectedItems: selectedItems });
                    }
                });

                scope.$on('$destroy', function() {
                    _clearWatcher();
                    _selectedItemsWatcher();
                });

                scope.initializeItem = function() {
                    if (angular.isArray(scope.items)) {
                        angular.forEach(scope.items, function(element, index) {
                            element.selected = false;
                            if (angular.isFunction(scope.onInitializeItem)) {
                                scope.onInitializeItem(element, index);
                            }
                        });
                    }
                };
                scope.initializeItem();
            }
        };
    }

})();