(function() {
    'use strict';

    angular.module('app.core').filter('iasTelefoneMask', IasTelefoneMask);

    function IasTelefoneMask() {
        return function (input) {

            if (is.not.null(input)) {
                var str = input+ '';
                str = str.replace(/\D/g,'');
                if (str.length === 11 ) {
                    str=str.replace(/^(\d{2})(\d{5})(\d{4})/,'($1) $2-$3');
                } else {
                    str=str.replace(/^(\d{2})(\d{4})(\d{4})/,'($1) $2-$3');
                }
                return str;
            }

            return null;
            
        };
    }
}());