/*
jslint
    devel: true, maxerr: 10
*/

/*
global
    required: true
*/

/*
 * Constants for App Core Module
 * @author iFactory Solutions Ltda.
 * @version 1.0.0
 * @since 1.0.0
 */
(function() {
    "use strict";

    angular.module("app.core").constant("APP_PROPERTY", {
        URL: "APP_PROPERTY_HOST:8080" // Variável substituída durante build do gulp
    });

    angular.module("app.core").constant("APP_MIME_TYPE", {
        JSON: "application/json",
        EXCEL: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    });

    var _commonStatusList = [
        { key: "A", value: "Ativo" },
        { key: "I", value: "Inativo" }
    ];

    angular.module("app.core").constant("COMMON_STATUS_LIST", _commonStatusList);

    angular.module("app.core").constant("STATUS_ENUM", {
        ATIVO: "ATIVO",
        INATIVO: "INATIVO"
    });

    angular.module("app.core").constant("STATUS_ENUM_OBJECT", {
        ATIVO: _commonStatusList[0],
        INATIVO: _commonStatusList[1]
    });

    angular.module("app.core").constant("MULTISELECT_LABELS", {
        selectAll: "Selecionar todos",
        unselectAll: "Desmarcar",
        itemsSelected: "selecionados",
        search: "Pesquisar",
        select: "Selecione"
    });

    angular.module("app.core").constant("APP_STATE", {
        BASE: "app",

        LOGIN: {
            BASE: "login",
            RESET_PASSWORD: "reset-password"
        },

        VALIDAR_EMAIL: {
            BASE: "validar-email"
        },

        HOME: {
            BASE: "app.home"
        },

        OPCAO: {
            BASE: "app.opcao",
            SEARCH: "app.opcao.search",
            NEW: "app.opcao.new",
            EDIT: "app.opcao.edit"
        },

        UF: {
            BASE: "app.uf",
            SEARCH: "app.uf.search",
            NEW: "app.uf.new",
            EDIT: "app.uf.edit"
        },

        FORM: {
            BUSCA: "app.formExemplo.search"
        },

        PROGRAMA: {
            BASE: "app.programa",
            SEARCH: "app.programa.search",
            NEW: "app.programa.new",
            EDIT: "app.programa.edit"
        },

        PROGRAMA_EXTERNO: {
            BASE: "app.programaExterno",
            SEARCH: "app.programaExterno.search",
            NEW: "app.programaExterno.new",
            EDIT: "app.programaExterno.edit"
        },

        MUNICIPIOS: {
            BASE: "app.municipios",
            SEARCH: "app.municipios.search",
            NEW: "app.municipios.new",
            EDIT: "app.municipios.edit"
        },

        GRUPO_MUNICIPIO: {
            BASE: "app.grupoMunicipio",
            SEARCH: "app.grupoMunicipio.search",
            NEW: "app.grupoMunicipio.new",
            EDIT: "app.grupoMunicipio.edit"
        },

        PERIODO_LETIVO: {
            BASE: "app.periodoLetivo",
            SEARCH: "app.periodoLetivo.search",
            NEW: "app.periodoLetivo.new",
            EDIT: "app.periodoLetivo.edit"
        },

        USUARIO: {
            BASE: "app.usuario",
            SEARCH: "app.usuario.search",
            NEW: "app.usuario.new",
            EDIT: "app.usuario.edit",
            PROFILE: "app.usuario.profile",
            IMPORT_TEMPLATE: "app.usuario.import_template"
        },

        PROFESSOR: {
            BASE: "app.professor",
            SEARCH: "app.professor.search",
            NEW: "app.professor.new",
            EDIT: "app.professor.edit",
            IMPORT_TEMPLATE: "app.professor.import_template"
        },

        FORMULARIO: {
            BASE: "app.formulario",
            SEARCH: "app.formulario.search",
            NEW: "app.formulario.new",
            EDIT: "app.formulario.edit"
        },

        MEDIDA: {
            BASE: "app.medida",
            SEARCH: "app.medida.search",
            NEW: "app.medida.new",
            EDIT: "app.medida.edit"
        },

        ATRIBUTO: {
            BASE: "app.atributo",
            SEARCH: "app.atributo.search",
            NEW: "app.atributo.new",
            EDIT: "app.atributo.edit"
        },

        ESCALA: {
            BASE: "app.escala",
            SEARCH: "app.escala.search",
            NEW: "app.escala.new",
            EDIT: "app.escala.edit"
        },

        COLETA: {
            BASE: "app.coleta",
            SEARCH: "app.coleta.search",
            NEW: "app.coleta.new",
            EDIT: "app.coleta.edit",
            RESPOSTA: {
                SEARCH: "app.coleta.resposta"
            }
        },
        ESCOLA: {
            BASE: "app.escola",
            SEARCH: "app.escola.search",
            NEW: "app.escola.new",
            EDIT: "app.escola.edit"
        },
        TURMA: {
            BASE: "app.turmas",
            SEARCH: "app.turmas.search",
            NEW: "app.turmas.new",
            EDIT: "app.turmas.edit",
            LOTE: "app.turmas.lote",
            IMPORT_TEMPLATE: "app.turmas.import_template"
        },
        RESPOSTA_COLETA: {
            ANONYMOUS: "resposta-coleta-anonymous",
            BASE: "app.coleta.resposta-coleta",
            SEARCH: "app.coleta.resposta-coleta.search",
            NEW: "app.coleta.resposta-coleta.new",
            EDIT: "app.coleta.resposta-coleta.edit"
        },

        PERFIL_ACESSO: {
            BASE: "app.perfilAcesso",
            SEARCH: "app.perfilAcesso.search",
            NEW: "app.perfilAcesso.new",
            EDIT: "app.perfilAcesso.edit"
        },

        TURMAS: {
            BASE: "app.turmas",
            SEARCH: "app.turmas.search",
            NEW: "app.turmas.new",
            EDIT: "app.turmas.edit"
        },

        CONTRATO: {
            BASE: "app.contrato",
            SEARCH: "app.contrato.search",
            NEW: "app.contrato.new",
            EDIT: "app.contrato.edit"
        },

        RELATORIOS: {
            BASE: "app.relatorios",
            PERFIL_TURMA: {
                BASE: "app.relatorios.perfil-turma",
                ALFABETIZACAO_ACELERACAO: "app.relatorios.perfil-turma-alfabetizacao-aceleracao",
                FORMULA_VITORIA: "app.relatorios.perfil-turma-formula-vitoria",
                ENSINO_MEDIO_GESTAO_NOTA_10: "app.relatorios.perfil-turma-ensino-medio-gestao-nota-10",
                ENSINO_REGULAR: "app.relatorios.perfil-turma-ensino-regular",
                GESTAO_DA_ALFABETIZACAO: "app.relatorios.perfil-turma-gestao-da-alfabetizacao",
                SISTEMATICA_ACOMPANHAMENTO_DIALOGOS: "app.relatorios.perfil-turma-sistematica-acompanhamento-dialogos"
            },
            ESCOLA: {
                ANUAL: {
                    PERFIL_TURMA_ALFAB_ACEL: "app.relatorios.esc-anual-perf-turma"
                }
            },
            BASE_MONITORAMENTO: {
                BASE: "app.relatorios.base-monitoramento",
                ACOMPANHAMENTO: "app.relatorios.acompanhamento"
            },
            RESULTADO_BIMESTRAL: {
                BASE: "app.relatorios.resultado-bimestral",
                ENSINO_MEDIO_GESTAO_NOTA_10: "app.relatorios.resultado-bimestral-ensino-medio-gestao-nota-10",
                FORMULA_VITORIA: "app.relatorios.resultado-bimestral-formula-vitoria",
                ENSINO_REGULAR: "app.relatorios.resultado-bimestral-ensino-regular",
                LETRAMENTO_EM_PROGRAMACAO: "app.relatorios.acompanhamento-semestral-letramento-em-programacao"
            },
            RESULTADO_FINAL: {
                BASE: "app.relatorios.resultado-final",
                ALFABETIZACAO_ACELERACAO: "app.relatorios.resultado-final-alfabetizacao-aceleracao",
                FORMULA_VITORIA: "app.relatorios.resultado-final-formula-vitoria",
                ENSINO_REGULAR: "app.relatorios.resultado-final-ensino-regular",
                ENSINO_REGULAR_MATRICULA_MOVIMENTACAO: "app.relatorios.resultado-final-ensino-regular-matricula-movimentacao",
                ALFABETIZACAO_2ANO_1SERIE_REGULAR: "app.relatorios.resultado-final-alfabetizacao-2ano-1serie-regular",
                GESTAO_DA_ALFABETIZACAO: "app.relatorios.resultado-final-gestao-alfabetizacao",

                IDADE_CONCLUSAO_4SERIE_5ANO: "app.relatorios.resultado-final-idade-conclusao-4serie-5ano",
                IDADE_CONCLUSAO_8SERIE_9ANO: "app.relatorios.resultado-final-idade-conclusao-8serie-9ano",

                LETRAMENTO_EM_PROGRAMACAO: "app.relatorios.resultado-final-letramento-em-programacao",
                INDICADORES_SUCESSO_RESULTADOS: "app.relatorios.resultado-final-indicadores-sucesso-resultados"
            },
            ACOMPANHAMENTO_MENSAL: {
                BASE: "app.relatorios.acompanhamento-mensal",
                LEITURA_ESCRITA_ORALIDADE_TEXTO: "app.relatorios.acompanhamento-mensal-leitura-escrita-oralidade-texto",
                LEITURA_ESCRITA_PRODUCAO_TEXTO: "app.relatorios.acompanhamento-mensal-leitura-escrita-producao-texto",
                ALFABETIZACAO_ACELERACAO_FORMULA_VITORIA: "app.relatorios.acompanhamento-mensal-alfabetizacao-aceleracao-formula-vitoria",
                ENSINO_REGULAR: "app.relatorios.acompanhamento-mensal-ensino-regular",
                FORMULA_VITORIA: "app.relatorios.acompanhamento-mensal-formula-vitoria",
                MATEMATICA: "app.relatorios.acompanhamento-mensal-matematica",
                FLUXO_AULAS: "app.relatorios.acompanhamento-mensal-fluxo-aulas",
                ACOMPANHAMENTO_MUNICIPIO: "app.relatorios.acompanhamento-mensal-acompanhamento-municipio",
                FLUXO_DE_OFICINAS: "app.relatorios.acompanhamento-mensal-fluxo-de-oficinas",
                LEITURA_ESTRATEGIA_COMPREENSAO_MAT_NV_1: "app.relatorios.acompanhamento-mensal-leitura-estrategia-compreensao-mat-nv-1",
                LEITURA_ESTRATEGIA_COMPREENSAO_MAT_NV_2: "app.relatorios.acompanhamento-mensal-leitura-estrategia-compreensao-mat-nv-2",
                FORMULA_VITORIA_LINGUA_PORTUGUESA_ACOMPANHAMENTO: "app.relatorios.formula-vitoria-lingua-portuguesa-acompanhamento"
            },
            ACOMPANHAMENTO_ANUAL: {
                BASE: "app.relatorios.acompanhamento-anual",
                PERFIL_ATENDIMENTO_UNIDADES_ESCOLARES_CURSOS: "app.relatorios.acompanhamento-anual-perfil-atendimento-unidades-escolares-cursos",
                PERFIL_ATENDIMENTO_UNIDADES_ESCOLARES_TURMAS: "app.relatorios.acompanhamento-anual-perfil-atendimento-unidades-escolares-turmas"
            },
            ACOMPANHAMENTO_SEMESTRAL: {
                BASE: "app.relatorios.acompanhamento-semestral"
            },
            OUTROS: {
                BASE: "app.relatorios.outros",
                NUMERO_ALUNOS_POR_ANO_SITUACAO: "app.relatorios.numero-alunos-por-ano-situacao",
                PERFIL_ATENDIMENTO_MUNICIPIO: "app.relatorios.perfil-atendimento-municipio",
                MUNICIPIOS_POR_PROGRAMA: "app.relatorios.municipios-por-programa",
                ESCOLAS_EDUCACAO_INFANTIL_JOVENS_ADULTOS: "app.relatorios.escolas-educacao-infantil-jovens-adultos",
                LISTA_UNIDADES_ESCOLARES: "app.relatorios.lista-unidades-escolares",
                LISTA_UNIDADES_ESCOLARES_PERFIL_ATENDIMENTO: "app.relatorios.lista-unidades-escolares-perfil-atendimento",
                TOTAL_ESCOLAS_PROGRAMA: "app.relatorios.total-escolas-por-programa",
                GENERO_SITUACAO_ANO_ANTERIOR: "app.relatorios.genero-situacao-ano-anterior",
                MEDIA_DIAS_LETIVOS: "app.relatorios.media-dias-letivos",
                MATRICULA_ENSINO_FUNDAMENTAL_REDE: "app.relatorios.matricula-ensino-fundamental-rede",
                STATUS_INSERCAO_COLETAS: "app.relatorios.status-insercao-coletas",
                SISTEMATICA_ACOMPANHAMENTO_VISITAS_REGIONAL: "app.relatorios.sistematica-acompanhamento-visitas-regional",
                SISTEMATICA_MONITORAMENTO_REGIONAL: "app.relatorios.sistematica-monitotamento-regional",
                SISTEMATICA_ACOMPANHAMENTO_VISITAS_CENTRAL: "app.relatorios.sistematica-acompanhamento-visitas-central",
                SISTEMATICA_MONITORAMENTO_CENTRAL: "app.relatorios.sistematica-monitotamento-central"
            }
        },

        CRITERIO_SELECAO: {
            BASE: "app.criterioSelecao",
            SEARCH: "app.criterioSelecao.search",
            NEW: "app.criterioSelecao.new",
            EDIT: "app.criterioSelecao.edit"
        },
        ALUNO: {
            BASE: "app.aluno",
            SEARCH: "app.aluno.search",
            NEW: "app.aluno.new",
            EDIT: "app.aluno.edit",
            IMPORT_TEMPLATE: "app.aluno.import_template"
        },
        RUBRICA: {
            BASE: "app.rubrica",
            SEARCH: "app.rubrica.search",
            NEW: "app.rubrica.new",
            EDIT: "app.rubrica.edit"
        },
        DISCIPLINA: {
            BASE: "app.disciplina",
            SEARCH: "app.disciplina.search",
            NEW: "app.disciplina.new",
            EDIT: "app.disciplina.edit"
        },
        COLETA_RUBRICA: {
            BASE: "app.coletaRubrica",
            SEARCH: "app.coletaRubrica.search",
            NEW: "app.coletaRubrica.new",
            EDIT: "app.coletaRubrica.edit",
            RESPOSTA: {
                SEARCH: "app.coletaRubrica.resposta"
            }
        }
    });
})();
