/*
jslint
    devel: true, maxerr: 10
*/

/*
global
    required: true
*/

/*
 * Relatórios Module
 * @service Report Common Service
 * @author iFactory Solutions Ltda.
 * @version 1.0.0
 * @since 1.0.0
 */
(function () {
    'use strict';

    angular.module('app.relatorios').service('RelatoriosService', RelatoriosService);
    RelatoriosService.$inject = ['SecurityManagerFactory', 'Relatorios', 'APP_STATE', 'APP_PROPERTY', '$http', 'REPORT_GROUP',
        'RESULTADO_FINAL_REPORT_ALFABETIZACAO_ACELERACAO_SE_LIGA_MODELO_1_FIELDS',
        'RESULTADO_FINAL_REPORT_ALFABETIZACAO_ACELERACAO_SE_LIGA_MODELO_2_FIELDS',
        'RESULTADO_FINAL_REPORT_ALFABETIZACAO_ACELERACAO_SE_LIGA_MODELO_3_FIELDS',
        'RESULTADO_FINAL_REPORT_ALFABETIZACAO_ACELERACAO_ACELERA_BRASIL_MODELO_1_FIELDS',
        'RESULTADO_FINAL_REPORT_ALFABETIZACAO_ACELERACAO_ACELERA_BRASIL_MODELO_2_FIELDS',
        'RESULTADO_FINAL_REPORT_ALFABETIZACAO_ACELERACAO_ACELERA_BRASIL_MODELO_3_FIELDS',
        'RESULTADO_FINAL_REPORT_ENSINO_REGULAR_INICIAIS_MODELO_1_FIELDS',
        'RESULTADO_FINAL_REPORT_ENSINO_REGULAR_FINAIS_MODELO_1_FIELDS',
        'RESULTADO_FINAL_REPORT_ENSINO_REGULAR_INICIAIS_MODELO_2_FIELDS',
        'RESULTADO_FINAL_REPORT_ENSINO_REGULAR_FINAIS_MODELO_2_FIELDS',
        'RESULTADO_FINAL_REPORT_ENSINO_REGULAR_MATRICULA_MOVIMENTACAO_FIELDS',
        'RESULTADO_FINAL_REPORT_ALFABETIZACAO_2ANO_1SERIE_REGULAR_FIELDS',
        'BASE_MONITORAMENTO_REPORT_SE_LIGA_FIELDS',
        'BASE_MONITORAMENTO_REPORT_ACELERA_BRASIL_FIELDS',
        'BASE_MONITORAMENTO_REPORT_FORMULA_PORTUGUES_FIELDS',
        'BASE_MONITORAMENTO_REPORT_MATEMATICA_NIVEL_1_FIELDS',
        'BASE_MONITORAMENTO_REPORT_MATEMATICA_NIVEL_2_FIELDS',
        'BASE_MONITORAMENTO_REPORT_GESTAO_ALFABETIZACAO_FIELDS',
        'RESULTADO_BIMESTRAL_REPORT_FORMULA_VITORIA_FIELDS',
        'RESULTADO_BIMESTRAL_REPORT_ENSINO_REGULAR_FIELDS',
        'PERFIL_TURMA_REPORT_FORMULA_VITORIA_FIELDS',
        'PERFIL_TURMA_REPORT_GESTAO_DA_ALFABETIZACAO_FIELDS',
        'PERFIL_TURMA_REPORT_SISTEMATICA_ACOMPANHAMENTO_DIALOGOS_FIELDS',
        'ACOMPANHAMENTO_MENSAL_MUNICIPIO_QUANTITATIVO_FIELDS',
        'ACOMPANHAMENTO_MENSAL_MUNICIPIO_QUANTITATIVO_RELATORIO_I_FIELDS',
        'ACOMPANHAMENTO_MENSAL_REPORT_LEITURA_ESCRITA_ORALIDADE_TEXTO_MODEL_ACELERA_FIELDS',
        'ACOMPANHAMENTO_MENSAL_REPORT_LEITURA_ESCRITA_ORALIDADE_TEXTO_MODEL_SE_LIGA_FIELDS',
        'ACOMPANHAMENTO_MENSAL_REPORT_ALFABETIZACAO_ACELERACAO_FIELDS',
        'ACOMPANHAMENTO_MENSAL_REPORT_ENSINO_REGULAR_FIELDS',
        'TABLE_FIELDS_TYPE', 'ACOMPANHAMENTO_MENSAL_REPORT_FORMULA_VITORIA_FIELDS', 'ACOMPANHAMENTO_MENSAL_REPORT_MATEMATICA_FORMULA_VITORIA_MATEMATICA_NIVEL_2_FIELDS',
        'FORMULA_VITORIA_LINGUA_PORTUGUESA_ACOMPANHAMENTO_FIELDS',
        'ACOMPANHAMENTO_MENSAL_REPORT_MATEMATICA_FORMULA_VITORIA_MATEMATICA_NIVEL_1_FIELDS',
        'ACOMPANHAMENTO_MENSAL_REPORT_FLUXO_AULAS_FIELDS',
        'ACOMPANHAMENTO_MENSAL_REPORT_FLUXO_AULAS_SE_LIGA_FIELDS',
        'ACOMPANHAMENTO_MENSAL_FLUXO_DE_OFICINAS_FORMULA_VITORIA_MATEMATICA_FIELDS', 'ACOMPANHAMENTO_MENSAL_FLUXO_DE_OFICINAS_FORMULA_VITORIA_LINGUA_PORTUGUESA_FIELDS',
        'ACOMPANHAMENTO_MENSAL_REPORT_ACOMPANHAMENTO_MUNICIPIO_RELATORIO_A_QUANTITATIVO_FIELDS',

        'PERFIL_TURMA_REPORT_ALFABETIZACAO_ACELERACAO_FIELDS', 'ACOMPANHAMENTO_ANUAL_PERFIL_ATENDIMENTO_UNIDADES_ESCOLARES_TURMAS_FIELDS', 'TOTAL_ESCOLAS_PROGRAMA_FIELDS', 'PERFIL_TURMA_REPORT_ENSINO_MEDIO_GESTAO_NOTA_10_FIELDS',
        'PERFIL_TURMA_REPORT_ENSINO_REGULAR_FIELDS', 'OUTROS_MUNICIPIOS_POR_PROGRAMA_FIELDS', 'OUTROS_ESCOLAS_EDUCACAO_INFANTIL_JOVENS_ADULTOS_FIELDS', 'RESULTADO_FINAL_IDADE_CONCLUSAO_4SERIE_5ANO_FIELDS', 'RESULTADO_FINAL_IDADE_CONCLUSAO_4SERIE_5ANO_ESTADO_FIELDS',
        'RESULTADO_FINAL_REPORT_FORMULA_VITORIA_FIELDS', 'OUTROS_LISTA_UNIDADES_ESCOLARES_FIELDS', 'RESULTADO_FINAL_IDADE_CONCLUSAO_8SERIE_9ANO_FIELDS', 'RESULTADO_FINAL_IDADE_CONCLUSAO_8SERIE_9ANO_ESTADO_FIELDS',

        'OUTROS_GENERO_SITUACAO_ANO_ANTERIOR_FIELDS',
        'OUTROS_PERFIL_ATENDIMENTO_MUNICIPIO_FIELDS',
        'OUTROS_MEDIA_DIAS_LETIVOS_FIELDS',
        'OUTROS_UNIDADE_ESCOLAR_PERFIL_ATENDIMENTO_FIELDS',
        'OUTROS_NUMERO_ALUNOS_POR_ANO_SITUACAO_FIELDS',
        'OUTROS_STATUS_INSERCAO_COLETAS_FIELDS',
        'OUTROS_SISTEMATICA_ACOMPANHAMENTO_VISITAS_REGIONAL',
        'OUTROS_SISTEMATICA_MONITORAMENTO_REGIONAL',
        'OUTROS_SISTEMATICA_ACOMPANHAMENTO_VISITAS_CENTRAL',
        'OUTROS_SISTEMATICA_MONITORAMENTO_CENTRAL',
        'RESULTADO_FINAL_REPORT_GESTAO_DA_ALFABETIZACAO_FIELDS',
        'OUTROS_MATRICULA_ENSINO_FUNDAMENTAL_REDE_FIELDS',

        'ACOMPANHAMENTO_SEMESTRAL_REGIONAL_UF_FIELDS',
        'ACOMPANHAMENTO_SEMESTRAL_REGIONAL_MUNICIPAL_FIELDS',
        'ACOMPANHAMENTO_SEMESTRAL_GRUPO_MUNICIPIO_IAS_FIELDS',
        'ACOMPANHAMENTO_SEMESTRAL_ESTADO_FIELDS',
        'ACOMPANHAMENTO_SEMESTRAL_MUNICIPIO_FIELDS',
        'ACOMPANHAMENTO_SEMESTRAL_ESCOLA_FIELDS',
        'ACOMPANHAMENTO_SEMESTRAL_TURMA_FIELDS',
        'ACOMPANHAMENTO_SEMESTRAL_PROFESSOR_FIELDS',

        'RESULTADO_FINAL_REGIONAL_UF_FIELDS',
        'RESULTADO_FINAL_REGIONAL_MUNICIPAL_FIELDS',
        'RESULTADO_FINAL_GRUPO_MUNICIPIO_IAS_FIELDS',
        'RESULTADO_FINAL_ESTADO_FIELDS',
        'RESULTADO_FINAL_MUNICIPIO_FIELDS',
        'RESULTADO_FINAL_ESCOLA_FIELDS',
        'RESULTADO_FINAL_TURMA_FIELDS',
        'RESULTADO_FINAL_PROFESSOR_FIELDS',
        'RESULTADO_FINAL_REPORT_INDICADORES_SUCESSO_RESULTADOS_FIELDS',

        'ufService', 'periodoLetivoService', 'escolasService', 'localizacaoService', 'municipiosService', 'dependenciaAdministrativaService', 'turmasService',
        'programasService', 'professorService',
        'objectService',
        '$q', '$filter', 'FileSaver'
    ];

    function RelatoriosService(SecurityManagerFactory, Relatorios, APP_STATE, APP_PROPERTY, $http, REPORT_GROUP,
        RESULTADO_FINAL_REPORT_ALFABETIZACAO_ACELERACAO_SE_LIGA_MODELO_1_FIELDS,
        RESULTADO_FINAL_REPORT_ALFABETIZACAO_ACELERACAO_SE_LIGA_MODELO_2_FIELDS,
        RESULTADO_FINAL_REPORT_ALFABETIZACAO_ACELERACAO_SE_LIGA_MODELO_3_FIELDS,
        RESULTADO_FINAL_REPORT_ALFABETIZACAO_ACELERACAO_ACELERA_BRASIL_MODELO_1_FIELDS,
        RESULTADO_FINAL_REPORT_ALFABETIZACAO_ACELERACAO_ACELERA_BRASIL_MODELO_2_FIELDS,
        RESULTADO_FINAL_REPORT_ALFABETIZACAO_ACELERACAO_ACELERA_BRASIL_MODELO_3_FIELDS,
        RESULTADO_FINAL_REPORT_ENSINO_REGULAR_INICIAIS_MODELO_1_FIELDS,
        RESULTADO_FINAL_REPORT_ENSINO_REGULAR_FINAIS_MODELO_1_FIELDS,
        RESULTADO_FINAL_REPORT_ENSINO_REGULAR_INICIAIS_MODELO_2_FIELDS,
        RESULTADO_FINAL_REPORT_ENSINO_REGULAR_FINAIS_MODELO_2_FIELDS,
        RESULTADO_FINAL_REPORT_ENSINO_REGULAR_MATRICULA_MOVIMENTACAO_FIELDS,
        RESULTADO_FINAL_REPORT_ALFABETIZACAO_2ANO_1SERIE_REGULAR_FIELDS,
        BASE_MONITORAMENTO_REPORT_SE_LIGA_FIELDS,
        BASE_MONITORAMENTO_REPORT_ACELERA_BRASIL_FIELDS,
        BASE_MONITORAMENTO_REPORT_FORMULA_PORTUGUES_FIELDS,
        BASE_MONITORAMENTO_REPORT_MATEMATICA_NIVEL_1_FIELDS,
        BASE_MONITORAMENTO_REPORT_MATEMATICA_NIVEL_2_FIELDS,
        BASE_MONITORAMENTO_REPORT_GESTAO_ALFABETIZACAO_FIELDS,
        RESULTADO_BIMESTRAL_REPORT_FORMULA_VITORIA_FIELDS,
        RESULTADO_BIMESTRAL_REPORT_ENSINO_REGULAR_FIELDS,
        PERFIL_TURMA_REPORT_FORMULA_VITORIA_FIELDS,
        PERFIL_TURMA_REPORT_GESTAO_DA_ALFABETIZACAO_FIELDS,
        PERFIL_TURMA_REPORT_SISTEMATICA_ACOMPANHAMENTO_DIALOGOS_FIELDS,
        ACOMPANHAMENTO_MENSAL_MUNICIPIO_QUANTITATIVO_FIELDS,
        ACOMPANHAMENTO_MENSAL_MUNICIPIO_QUANTITATIVO_RELATORIO_I_FIELDS,
        ACOMPANHAMENTO_MENSAL_REPORT_LEITURA_ESCRITA_ORALIDADE_TEXTO_MODEL_ACELERA_FIELDS,

        ACOMPANHAMENTO_MENSAL_REPORT_LEITURA_ESCRITA_ORALIDADE_TEXTO_MODEL_SE_LIGA_FIELDS,
        ACOMPANHAMENTO_MENSAL_REPORT_ALFABETIZACAO_ACELERACAO_FIELDS,
        ACOMPANHAMENTO_MENSAL_REPORT_ENSINO_REGULAR_FIELDS,
        TABLE_FIELDS_TYPE, ACOMPANHAMENTO_MENSAL_REPORT_FORMULA_VITORIA_FIELDS, ACOMPANHAMENTO_MENSAL_REPORT_MATEMATICA_FORMULA_VITORIA_MATEMATICA_NIVEL_2_FIELDS,
        FORMULA_VITORIA_LINGUA_PORTUGUESA_ACOMPANHAMENTO_FIELDS,
        ACOMPANHAMENTO_MENSAL_REPORT_MATEMATICA_FORMULA_VITORIA_MATEMATICA_NIVEL_1_FIELDS,
        ACOMPANHAMENTO_MENSAL_REPORT_FLUXO_AULAS_FIELDS,
        ACOMPANHAMENTO_MENSAL_REPORT_FLUXO_AULAS_SE_LIGA_FIELDS,
        ACOMPANHAMENTO_MENSAL_FLUXO_DE_OFICINAS_FORMULA_VITORIA_MATEMATICA_FIELDS, ACOMPANHAMENTO_MENSAL_FLUXO_DE_OFICINAS_FORMULA_VITORIA_LINGUA_PORTUGUESA_FIELDS,
        ACOMPANHAMENTO_MENSAL_REPORT_ACOMPANHAMENTO_MUNICIPIO_RELATORIO_A_QUANTITATIVO_FIELDS,

        PERFIL_TURMA_REPORT_ALFABETIZACAO_ACELERACAO_FIELDS, ACOMPANHAMENTO_ANUAL_PERFIL_ATENDIMENTO_UNIDADES_ESCOLARES_TURMAS_FIELDS, TOTAL_ESCOLAS_PROGRAMA_FIELDS, PERFIL_TURMA_REPORT_ENSINO_MEDIO_GESTAO_NOTA_10_FIELDS,
        PERFIL_TURMA_REPORT_ENSINO_REGULAR_FIELDS, OUTROS_MUNICIPIOS_POR_PROGRAMA_FIELDS, OUTROS_ESCOLAS_EDUCACAO_INFANTIL_JOVENS_ADULTOS_FIELDS, RESULTADO_FINAL_IDADE_CONCLUSAO_4SERIE_5ANO_FIELDS, RESULTADO_FINAL_IDADE_CONCLUSAO_4SERIE_5ANO_ESTADO_FIELDS,
        RESULTADO_FINAL_REPORT_FORMULA_VITORIA_FIELDS, OUTROS_LISTA_UNIDADES_ESCOLARES_FIELDS, RESULTADO_FINAL_IDADE_CONCLUSAO_8SERIE_9ANO_FIELDS, RESULTADO_FINAL_IDADE_CONCLUSAO_8SERIE_9ANO_ESTADO_FIELDS,

        OUTROS_GENERO_SITUACAO_ANO_ANTERIOR_FIELDS,
        OUTROS_PERFIL_ATENDIMENTO_MUNICIPIO_FIELDS,
        OUTROS_MEDIA_DIAS_LETIVOS_FIELDS,
        OUTROS_UNIDADE_ESCOLAR_PERFIL_ATENDIMENTO_FIELDS,
        OUTROS_NUMERO_ALUNOS_POR_ANO_SITUACAO_FIELDS,
        OUTROS_STATUS_INSERCAO_COLETAS_FIELDS,
        OUTROS_SISTEMATICA_ACOMPANHAMENTO_VISITAS_REGIONAL,
        OUTROS_SISTEMATICA_MONITORAMENTO_REGIONAL,
        OUTROS_SISTEMATICA_ACOMPANHAMENTO_VISITAS_CENTRAL,
        OUTROS_SISTEMATICA_MONITORAMENTO_CENTRAL,
        RESULTADO_FINAL_REPORT_GESTAO_DA_ALFABETIZACAO_FIELDS,
        OUTROS_MATRICULA_ENSINO_FUNDAMENTAL_REDE_FIELDS,

        ACOMPANHAMENTO_SEMESTRAL_REGIONAL_UF_FIELDS,
        ACOMPANHAMENTO_SEMESTRAL_REGIONAL_MUNICIPAL_FIELDS,
        ACOMPANHAMENTO_SEMESTRAL_GRUPO_MUNICIPIO_IAS_FIELDS,
        ACOMPANHAMENTO_SEMESTRAL_ESTADO_FIELDS,
        ACOMPANHAMENTO_SEMESTRAL_MUNICIPIO_FIELDS,
        ACOMPANHAMENTO_SEMESTRAL_ESCOLA_FIELDS,
        ACOMPANHAMENTO_SEMESTRAL_TURMA_FIELDS,
        ACOMPANHAMENTO_SEMESTRAL_PROFESSOR_FIELDS,

        RESULTADO_FINAL_REGIONAL_UF_FIELDS,
        RESULTADO_FINAL_REGIONAL_MUNICIPAL_FIELDS,
        RESULTADO_FINAL_GRUPO_MUNICIPIO_IAS_FIELDS,
        RESULTADO_FINAL_ESTADO_FIELDS,
        RESULTADO_FINAL_MUNICIPIO_FIELDS,
        RESULTADO_FINAL_ESCOLA_FIELDS,
        RESULTADO_FINAL_TURMA_FIELDS,
        RESULTADO_FINAL_PROFESSOR_FIELDS,
        RESULTADO_FINAL_REPORT_INDICADORES_SUCESSO_RESULTADOS_FIELDS,

        stateService, schoolYearService, schoolService, localizationService, cityService, administrativeDependencyService, turmasService,
        programService, professorService,
        objectService, $q, $filter, FileSaver) {
        var self = this;

        // Constantes do relatório de Acompanhamento de Diálogos - Sistematica auto criadas através de for
        for (var i = 1; i <= 4; i++) {
            PERFIL_TURMA_REPORT_SISTEMATICA_ACOMPANHAMENTO_DIALOGOS_FIELDS.push({ name: 'frequenciaPrevistaBimestre' + i, type: 'integer', calculateTotal: true, calculateType: 'sum' });
        }

        for (var i = 1; i <= 4; i++) {
            PERFIL_TURMA_REPORT_SISTEMATICA_ACOMPANHAMENTO_DIALOGOS_FIELDS.push({ name: 'frequenciaRealizadaBimestre' + i, type: 'integer', calculateTotal: true, calculateType: 'sum' });
        }

        for (var i = 1; i <= 4; i++) {
            PERFIL_TURMA_REPORT_SISTEMATICA_ACOMPANHAMENTO_DIALOGOS_FIELDS.push({ name: 'percentualFrequenciaBimestre' + i, type: 'integer', percent: true, calculateTotal: true, calculateType: 'average' });
        }

        for (var i = 1; i <= 4; i++) {
            PERFIL_TURMA_REPORT_SISTEMATICA_ACOMPANHAMENTO_DIALOGOS_FIELDS.push({ name: 'aulasPrevistasBimestre' + i, type: 'integer', calculateTotal: true, calculateType: 'sum' });
        }

        for (var i = 1; i <= 4; i++) {
            PERFIL_TURMA_REPORT_SISTEMATICA_ACOMPANHAMENTO_DIALOGOS_FIELDS.push({ name: 'aulasRealizadasBimestre' + i, type: 'integer', calculateTotal: true, calculateType: 'sum' });
        }

        for (var i = 1; i <= 4; i++) {
            PERFIL_TURMA_REPORT_SISTEMATICA_ACOMPANHAMENTO_DIALOGOS_FIELDS.push({ name: 'percentualAulasBimestre' + i, type: 'integer', percent: true, calculateTotal: true, calculateType: 'average' });
        }

        for (var i = 1; i <= 4; i++) {
            PERFIL_TURMA_REPORT_SISTEMATICA_ACOMPANHAMENTO_DIALOGOS_FIELDS.push({ name: 'percentualAdesaoEstudantesAutoAvaliacaoBimestre' + i, type: 'integer', percent: true, calculateTotal: true, calculateType: 'average' });
        }

        for (var i = 1; i <= 4; i++) {
            PERFIL_TURMA_REPORT_SISTEMATICA_ACOMPANHAMENTO_DIALOGOS_FIELDS.push({ name: 'percentualAdesaoEstudantesAvaliacaoProfessorBimestre' + i, type: 'integer', percent: true, calculateTotal: true, calculateType: 'average' });
        }

        for (var i = 1; i <= 4; i++) {
            PERFIL_TURMA_REPORT_SISTEMATICA_ACOMPANHAMENTO_DIALOGOS_FIELDS.push({ name: 'percentualAdesaoEducadoresBimestre' + i, type: 'integer', percent: true, calculateTotal: true, calculateType: 'average' });
        }

        for (var i = 1; i <= 4; i++) {
            PERFIL_TURMA_REPORT_SISTEMATICA_ACOMPANHAMENTO_DIALOGOS_FIELDS.push({ name: 'percentualEstudantesAbaixoMediaPortuguesBimestre' + i, type: 'integer', percent: true, calculateTotal: true, calculateType: 'average' });
        }

        for (var i = 1; i <= 4; i++) {
            PERFIL_TURMA_REPORT_SISTEMATICA_ACOMPANHAMENTO_DIALOGOS_FIELDS.push({ name: 'percentualEstudantesAbaixoMediaMatematicaBimestre' + i, type: 'integer', percent: true, calculateTotal: true, calculateType: 'average' });
        }

        for (var i = 1; i <= 12; i++) {
            PERFIL_TURMA_REPORT_SISTEMATICA_ACOMPANHAMENTO_DIALOGOS_FIELDS.push({ name: 'frequenciaPrevistaMensais' + i, type: 'integer', calculateTotal: true, calculateType: 'sum' });
        }

        for (var i = 1; i <= 12; i++) {
            PERFIL_TURMA_REPORT_SISTEMATICA_ACOMPANHAMENTO_DIALOGOS_FIELDS.push({ name: 'frequenciaRealizadaMensais' + i, type: 'integer', calculateTotal: true, calculateType: 'sum' });
        }

        for (var i = 1; i <= 12; i++) {
            PERFIL_TURMA_REPORT_SISTEMATICA_ACOMPANHAMENTO_DIALOGOS_FIELDS.push({ name: 'percentualFrequenciaMensais' + i, type: 'integer', percent: true, calculateTotal: true, calculateType: 'average' });
        }

        for (var i = 1; i <= 12; i++) {
            PERFIL_TURMA_REPORT_SISTEMATICA_ACOMPANHAMENTO_DIALOGOS_FIELDS.push({ name: 'aulasPrevistasMensais' + i, type: 'integer', calculateTotal: true, calculateType: 'sum' });
        }

        for (var i = 1; i <= 12; i++) {
            PERFIL_TURMA_REPORT_SISTEMATICA_ACOMPANHAMENTO_DIALOGOS_FIELDS.push({ name: 'aulasRealizadasMensais' + i, type: 'integer', calculateTotal: true, calculateType: 'sum' });
        }

        for (var i = 1; i <= 12; i++) {
            PERFIL_TURMA_REPORT_SISTEMATICA_ACOMPANHAMENTO_DIALOGOS_FIELDS.push({ name: 'percentualAulasMensais' + i, type: 'integer', percent: true, calculateTotal: true, calculateType: 'average' });
        }

        // FIM - Constantes do relatório de Acompanhamento de Diálogos - Sistematica auto criadas através de for

        var _reportsGroup = {};
        _reportsGroup[REPORT_GROUP.RESULTADO_FINAL.BASE] = [
            {
                id: REPORT_GROUP.RESULTADO_FINAL.ALFABETIZACAO_ACELERACAO,
                name: 'Alfabetização/Aceleração',
                title: 'Resultado Final Alfabetização/Aceleração',
                state: APP_STATE.RELATORIOS.RESULTADO_FINAL.ALFABETIZACAO_ACELERACAO,
                enabled: true
            },
            {
                id: REPORT_GROUP.RESULTADO_FINAL.IDADE_CONCLUSAO_4SERIE_5ANO,
                name: 'Idade de Conclusão da 4ª Série/5º ano',
                title: 'Idade de Conclusão da 4ª Série/5º ano',
                state: APP_STATE.RELATORIOS.RESULTADO_FINAL.IDADE_CONCLUSAO_4SERIE_5ANO,
                enabled: true,
                template: 'relatorios/partials/resultado-final/idade-conclusao-4serie-5ano-filter.tpl.html'
            },
            {
                id: REPORT_GROUP.RESULTADO_FINAL.IDADE_CONCLUSAO_8SERIE_9ANO,
                name: 'Idade de Conclusão da 8ª Série/9º ano',
                title: 'Idade de Conclusão da 8ª Série/9º ano',
                state: APP_STATE.RELATORIOS.RESULTADO_FINAL.IDADE_CONCLUSAO_8SERIE_9ANO,
                enabled: true,
                template: 'relatorios/partials/resultado-final/idade-conclusao-8serie-9ano-filter.tpl.html'
            },
            {
                id: REPORT_GROUP.RESULTADO_FINAL.FORMULA_VITORIA,
                name: 'Fórmula da Vitória',
                title: 'Resultado Final - Fórmula da Vitória',
                state: APP_STATE.RELATORIOS.RESULTADO_FINAL.FORMULA_VITORIA,
                enabled: true
            },
            {
                id: REPORT_GROUP.RESULTADO_FINAL.ENSINO_REGULAR,
                name: 'Ensino Regular',
                title: 'Resultado Final - Ensino Regular',
                state: APP_STATE.RELATORIOS.RESULTADO_FINAL.ENSINO_REGULAR,
                enabled: true
            },
            {
                id: REPORT_GROUP.RESULTADO_FINAL.GESTAO_DA_ALFABETIZACAO,
                name: 'Gestão da Alfabetização',
                title: 'Resultado Final - Gestão da Alfabetização',
                state: APP_STATE.RELATORIOS.RESULTADO_FINAL.GESTAO_DA_ALFABETIZACAO,
                enabled: true
            },
            {
                id: REPORT_GROUP.RESULTADO_FINAL.ENSINO_REGULAR_MATRICULA_MOVIMENTACAO,
                name: 'Matrícula, movimentação, rendimento e reprovação por falta e desempenho',
                title: 'Matrícula, movimentação, rendimento e reprovação por falta e desempenho',
                state: APP_STATE.RELATORIOS.RESULTADO_FINAL.ENSINO_REGULAR_MATRICULA_MOVIMENTACAO,
                enabled: true
            },
            {
                id: REPORT_GROUP.RESULTADO_FINAL.ALFABETIZACAO_2ANO_1SERIE_REGULAR,
                name: 'Alfabetização 2º ano/1ª série regular',
                title: 'Alfabetização 2º ano/1ª série regular',
                state: APP_STATE.RELATORIOS.RESULTADO_FINAL.ALFABETIZACAO_2ANO_1SERIE_REGULAR,
                enabled: true,
                template: 'relatorios/partials/resultado-final/resultado-final-alfabetizacao-2ano-1serie-regular-filter.tpl.html'
            },
            {
                id: REPORT_GROUP.RESULTADO_FINAL.LETRAMENTO_EM_PROGRAMACAO,
                name: 'Letramento em Programação',
                title: 'Letramento em Programação',
                state: APP_STATE.RELATORIOS.RESULTADO_FINAL.LETRAMENTO_EM_PROGRAMACAO,
                enabled: true,
                template: 'relatorios/partials/resultado-final/resultado-final-letramento-em-programacao-filter.tpl.html'
            },
            {
                id: REPORT_GROUP.RESULTADO_FINAL.INDICADORES_SUCESSO_RESULTADOS,
                name: 'Indicadores de sucesso - Resultados',
                title: 'Indicadores de sucesso - Resultados',
                state: APP_STATE.RELATORIOS.RESULTADO_FINAL.INDICADORES_SUCESSO_RESULTADOS,
                enabled: true,
                template: 'relatorios/partials/resultado-final/resultado-final-indicadores-sucesso-resultados-filter.tpl.html'
            }
        ];
        _reportsGroup[REPORT_GROUP.BASE_MONITORAMENTO.BASE] = [{
            id: REPORT_GROUP.BASE_MONITORAMENTO.ACOMPANHAMENTO,
            name: 'Acompanhamento Base',
            title: 'Acompanhamento Base - Monitoramento',
            state: APP_STATE.RELATORIOS.BASE_MONITORAMENTO.ACOMPANHAMENTO,
            enabled: true
        }
        ];
        _reportsGroup[REPORT_GROUP.PERFIL_TURMA.BASE] = [
            {
                id: REPORT_GROUP.PERFIL_TURMA.ALFABETIZACAO_ACELERACAO,
                name: 'Alfabetização e Aceleração',
                title: 'Perfil da Turma - Alfabetização e Aceleração',
                state: APP_STATE.RELATORIOS.PERFIL_TURMA.ALFABETIZACAO_ACELERACAO,
                enabled: true
            },
            {
                id: REPORT_GROUP.PERFIL_TURMA.ENSINO_MEDIO_GESTAO_NOTA_10,
                name: 'Ensino Médio - Gestão Nota 10',
                title: 'Perfil da Turma - Ensino Médio - Gestão Nota 10',
                state: APP_STATE.RELATORIOS.PERFIL_TURMA.ENSINO_MEDIO_GESTAO_NOTA_10,
                enabled: true
            },
            {
                id: REPORT_GROUP.PERFIL_TURMA.FORMULA_VITORIA,
                name: 'Fórmula da Vitória',
                title: 'Perfil da Turma - Fórmula da Vitória',
                state: APP_STATE.RELATORIOS.PERFIL_TURMA.FORMULA_VITORIA,
                enabled: true
            },
            {
                id: REPORT_GROUP.PERFIL_TURMA.ENSINO_REGULAR,
                name: 'Ensino Regular',
                title: 'Perfil da Turma - Ensino Regular',
                state: APP_STATE.RELATORIOS.PERFIL_TURMA.ENSINO_REGULAR,
                enabled: true
            },
            {
                id: REPORT_GROUP.PERFIL_TURMA.GESTAO_DA_ALFABETIZACAO,
                name: 'Gestão da Alfabetização',
                title: 'Perfil da Turma - Gestão da alfabetização',
                state: APP_STATE.RELATORIOS.PERFIL_TURMA.GESTAO_DA_ALFABETIZACAO,
                enabled: true
            },
            {
                id: REPORT_GROUP.PERFIL_TURMA.SISTEMATICA_ACOMPANHAMENTO_DIALOGOS,
                name: 'Diálogos Socioemocionais – Sistemática',
                title: 'Perfil da Turma - Diálogos Socioemocionais – Sistemática',
                state: APP_STATE.RELATORIOS.PERFIL_TURMA.SISTEMATICA_ACOMPANHAMENTO_DIALOGOS,
                enabled: true
            }
        ];
        _reportsGroup[REPORT_GROUP.ACOMPANHAMENTO_ANUAL.BASE] = [
            {
                id: REPORT_GROUP.ACOMPANHAMENTO_ANUAL.PERFIL_ATENDIMENTO_UNIDADES_ESCOLARES_CURSOS,
                name: 'Perfil do Atendimento das Unidades Escolares - Cursos',
                title: 'Perfil do Atendimento das Unidades Escolares - Cursos',
                state: APP_STATE.RELATORIOS.ACOMPANHAMENTO_ANUAL.PERFIL_ATENDIMENTO_UNIDADES_ESCOLARES_CURSOS,
                enabled: true
            },
            {
                id: REPORT_GROUP.ACOMPANHAMENTO_ANUAL.PERFIL_ATENDIMENTO_UNIDADES_ESCOLARES_TURMAS,
                name: 'Perfil do Atendimento das Unidades Escolares - Turmas',
                title: 'Perfil do Atendimento das Unidades Escolares - Turmas',
                state: APP_STATE.RELATORIOS.ACOMPANHAMENTO_ANUAL.PERFIL_ATENDIMENTO_UNIDADES_ESCOLARES_TURMAS,
                enabled: true
            }
        ];
        _reportsGroup[REPORT_GROUP.RESULTADO_BIMESTRAL.BASE] = [
            {
                id: REPORT_GROUP.RESULTADO_BIMESTRAL.FORMULA_VITORIA,
                name: 'Fórmula da Vitória',
                title: 'Resultado Bimestral - Fórmula da Vitória',
                state: APP_STATE.RELATORIOS.RESULTADO_BIMESTRAL.FORMULA_VITORIA,
                enabled: true
            },
            {
                id: REPORT_GROUP.RESULTADO_BIMESTRAL.ENSINO_REGULAR,
                name: 'Ensino Regular',
                title: 'Resultado Bimestral - Ensino Regular',
                state: APP_STATE.RELATORIOS.RESULTADO_BIMESTRAL.ENSINO_REGULAR,
                enabled: true
            },
            {
                id: REPORT_GROUP.RESULTADO_BIMESTRAL.LETRAMENTO_EM_PROGRAMACAO,
                name: 'Letramento em Programação',
                title: 'Letramento em Programação',
                state: APP_STATE.RELATORIOS.RESULTADO_BIMESTRAL.LETRAMENTO_EM_PROGRAMACAO,
                enabled: true,
                template: 'relatorios/partials/acompanhamento-semestral/acompanhamento-semestral-filter.tpl.html'
            }
        ];
        _reportsGroup[REPORT_GROUP.ACOMPANHAMENTO_MENSAL.BASE] = [
            {
                id: REPORT_GROUP.ACOMPANHAMENTO_MENSAL.LEITURA_ESCRITA_ORALIDADE_TEXTO,
                name: 'Acompanhamento de Leitura, Escrita e Oralidade',
                title: 'Acompanhamento Mensal - Leitura, Escrita e Oralidade',
                state: APP_STATE.RELATORIOS.ACOMPANHAMENTO_MENSAL.LEITURA_ESCRITA_ORALIDADE_TEXTO,
                enabled: true
            },
            {
                id: REPORT_GROUP.ACOMPANHAMENTO_MENSAL.LEITURA_ESCRITA_PRODUCAO_TEXTO,
                name: 'Acompanhamento de Leitura, Escrita e Produção de Texto',
                title: 'Acompanhamento Mensal – Leitura, Escrita e Produção de Texto',
                state: APP_STATE.RELATORIOS.ACOMPANHAMENTO_MENSAL.LEITURA_ESCRITA_PRODUCAO_TEXTO,
                enabled: true
            },
            {
                id: REPORT_GROUP.ACOMPANHAMENTO_MENSAL.LEITURA_ESTRATEGIA_COMPREENSAO_MAT_NV_1,
                name: 'Acompanhamento de Leitura, Estratégia e Compreensão Matemática Nível 1',
                title: 'Acompanhamento de Leitura, Estratégia e Compreensão Matemática Nível 1',
                state: APP_STATE.RELATORIOS.ACOMPANHAMENTO_MENSAL.LEITURA_ESTRATEGIA_COMPREENSAO_MAT_NV_1,
                enabled: true
            },
            {
                id: REPORT_GROUP.ACOMPANHAMENTO_MENSAL.LEITURA_ESTRATEGIA_COMPREENSAO_MAT_NV_2,
                name: 'Acompanhamento de Leitura, Estratégia e Compreensão Matemática Nível 2',
                title: 'Acompanhamento de Leitura, Estratégia e Compreensão Matemática Nível 2',
                state: APP_STATE.RELATORIOS.ACOMPANHAMENTO_MENSAL.LEITURA_ESTRATEGIA_COMPREENSAO_MAT_NV_2,
                enabled: true
            },
            {
                id: REPORT_GROUP.ACOMPANHAMENTO_MENSAL.ALFABETIZACAO_ACELERACAO_FORMULA_VITORIA,
                name: 'Acompanhamento Mensal',
                title: 'Acompanhamento Mensal',
                state: APP_STATE.RELATORIOS.ACOMPANHAMENTO_MENSAL.ALFABETIZACAO_ACELERACAO_FORMULA_VITORIA,
                enabled: true,
                template: 'relatorios/partials/acompanhamento-mensal/acompanhamento-mensal-aafv-filter.tpl.html'
            },
            {
                id: REPORT_GROUP.ACOMPANHAMENTO_MENSAL.ACOMPANHAMENTO_MUNICIPIO,
                name: 'Acompanhamento Município Mensal',
                title: 'Acompanhamento Município Mensal',
                state: APP_STATE.RELATORIOS.ACOMPANHAMENTO_MENSAL.ACOMPANHAMENTO_MUNICIPIO,
                enabled: true,
            },
            {
                id: REPORT_GROUP.ACOMPANHAMENTO_MENSAL.FLUXO_AULAS,
                name: 'Fluxo de Aulas',
                title: 'Acompanhamento Mensal - Fluxo de Aulas',
                state: APP_STATE.RELATORIOS.ACOMPANHAMENTO_MENSAL.FLUXO_AULAS,
                enabled: true,
                template: 'relatorios/partials/acompanhamento-mensal/acompanhamento-mensal-fluxo-aulas.tpl.html'
            },
            {
                id: REPORT_GROUP.ACOMPANHAMENTO_MENSAL.FLUXO_DE_OFICINAS,
                name: 'Fluxo de Oficinas',
                title: 'Fluxo de Oficinas',
                state: APP_STATE.RELATORIOS.ACOMPANHAMENTO_MENSAL.FLUXO_DE_OFICINAS,
                enabled: true,
                template: 'relatorios/partials/acompanhamento-mensal/acompanhamento-mensal-fluxo-de-oficinas.tpl.html'
            },
            {
                id: REPORT_GROUP.ACOMPANHAMENTO_MENSAL.FORMULA_VITORIA_LINGUA_PORTUGUESA_ACOMPANHAMENTO,
                name: 'Fórmula da Vitória Língua Portuguesa – Acompanhamento de Leitura, Escrita',
                title: 'Fórmula da Vitória Língua Portuguesa – Acompanhamento de Leitura, Escrita',
                state: APP_STATE.RELATORIOS.ACOMPANHAMENTO_MENSAL.FORMULA_VITORIA_LINGUA_PORTUGUESA_ACOMPANHAMENTO,
                enabled: true
            }
        ];
        _reportsGroup[REPORT_GROUP.ACOMPANHAMENTO_SEMESTRAL.BASE] = [
        ];
        _reportsGroup[REPORT_GROUP.OUTROS.BASE] = [
            {
                id: REPORT_GROUP.OUTROS.NUMERO_ALUNOS_POR_ANO_SITUACAO,
                name: 'Número de alunos por ano/série e situação',
                title: 'Número de alunos por ano/série e situação',
                state: APP_STATE.RELATORIOS.OUTROS.NUMERO_ALUNOS_POR_ANO_SITUACAO,
                enabled: true,
                template: 'relatorios/partials/outros/numero-alunos-por-ano-situacao-filter.tpl.html'
            },
            {
                id: REPORT_GROUP.OUTROS.MUNICIPIOS_POR_PROGRAMA,
                name: 'Total de Municípios por Programa',
                title: 'Total de Municípios por Programa',
                state: APP_STATE.RELATORIOS.OUTROS.MUNICIPIOS_POR_PROGRAMA,
                enabled: true,
                template: 'relatorios/partials/municipios-por-programa/municipios-por-programa.tpl.html'
            },
            {
                id: REPORT_GROUP.OUTROS.ESCOLAS_EDUCACAO_INFANTIL_JOVENS_ADULTOS,
                name: 'Escolas com educação infantil e de jovens e adultos',
                title: 'Escolas com educação infantil e de jovens e adultos',
                state: APP_STATE.RELATORIOS.OUTROS.ESCOLAS_EDUCACAO_INFANTIL_JOVENS_ADULTOS,
                enabled: true,
                template: 'relatorios/partials/outros/escolas-educacao-infantil-jovens-adultos.tpl.html'
            },
            {
                id: REPORT_GROUP.OUTROS.LISTA_UNIDADES_ESCOLARES,
                name: 'Lista de Unidades Escolares',
                title: 'Lista de Unidades Escolares',
                state: APP_STATE.RELATORIOS.OUTROS.LISTA_UNIDADES_ESCOLARES,
                enabled: true,
                template: 'relatorios/partials/lista-unidades-escolares/lista-unidades-escolares.tpl.html'
            },
            {
                id: REPORT_GROUP.OUTROS.LISTA_UNIDADES_ESCOLARES_PERFIL_ATENDIMENTO,
                name: 'Lista de Unidades Escolares - Perfil do Atendimento',
                title: 'Lista de Unidades Escolares - Perfil do Atendimento',
                state: APP_STATE.RELATORIOS.OUTROS.LISTA_UNIDADES_ESCOLARES_PERFIL_ATENDIMENTO,
                enabled: true,
                template: 'relatorios/partials/outros/lista-unidades-escolares-perfil-atendimento-filter.tpl.html'
            },
            {
                id: REPORT_GROUP.OUTROS.MEDIA_DIAS_LETIVOS,
                name: 'Média de Dias Letivos',
                title: 'Média de Dias Letivos',
                state: APP_STATE.RELATORIOS.OUTROS.MEDIA_DIAS_LETIVOS,
                enabled: true,
                template: 'relatorios/partials/media-dias-letivos/media-dias-letivos.tpl.html'
            },
            {
                id: REPORT_GROUP.OUTROS.TOTAL_ESCOLAS_PROGRAMA,
                name: 'Total de Escolas por Programa',
                title: 'Total de Escolas por Programa',
                state: APP_STATE.RELATORIOS.OUTROS.TOTAL_ESCOLAS_PROGRAMA,
                enabled: true
            },
            {
                id: REPORT_GROUP.OUTROS.GENERO_SITUACAO_ANO_ANTERIOR,
                name: 'Gênero e situação do ano anterior',
                title: 'Gênero e situação do ano anterior',
                state: APP_STATE.RELATORIOS.OUTROS.GENERO_SITUACAO_ANO_ANTERIOR,
                enabled: true
            },
            {
                id: REPORT_GROUP.OUTROS.PERFIL_ATENDIMENTO_MUNICIPIO,
                name: 'Perfil do Atendimento do Município',
                title: 'Perfil do Atendimento do Município',
                state: APP_STATE.RELATORIOS.OUTROS.PERFIL_ATENDIMENTO_MUNICIPIO,
                enabled: true
            },
            {
                id: REPORT_GROUP.OUTROS.MATRICULA_ENSINO_FUNDAMENTAL_REDE,
                name: 'Matrícula do Ensino Fundamental por Rede',
                title: 'Matrícula do Ensino Fundamental por Rede',
                state: APP_STATE.RELATORIOS.OUTROS.MATRICULA_ENSINO_FUNDAMENTAL_REDE,
                enabled: true
            },
            {
                id: REPORT_GROUP.OUTROS.STATUS_INSERCAO_COLETAS,
                name: 'Status de Inserção de Coletas',
                title: 'Status de Inserção de Coletas',
                state: APP_STATE.RELATORIOS.OUTROS.STATUS_INSERCAO_COLETAS,
                enabled: true
            }
        ];

        if (SecurityManagerFactory.hasPermission('DSP:03')) {
            _reportsGroup[REPORT_GROUP.OUTROS.BASE].push(
                {
                    id: REPORT_GROUP.OUTROS.SISTEMATICA_ACOMPANHAMENTO_VISITAS_REGIONAL,
                    name: 'Sistematica - Acompanhamento visitas - Regional',
                    title: 'Sistematica - Acompanhamento visitas - Regional',
                    state: APP_STATE.RELATORIOS.OUTROS.SISTEMATICA_ACOMPANHAMENTO_VISITAS_REGIONAL,
                    enabled: true
                },
                {
                    id: REPORT_GROUP.OUTROS.SISTEMATICA_MONITORAMENTO_REGIONAL,
                    name: 'Sistemática – Monitoramento - Regional',
                    title: 'Sistemática – Monitoramento - Regional',
                    state: APP_STATE.RELATORIOS.OUTROS.SISTEMATICA_MONITORAMENTO_REGIONAL,
                    enabled: true
                }
            );
        }

        if (SecurityManagerFactory.hasPermission('DSP:04')) {
            _reportsGroup[REPORT_GROUP.OUTROS.BASE].push(
                {
                    id: REPORT_GROUP.OUTROS.SISTEMATICA_ACOMPANHAMENTO_VISITAS_CENTRAL,
                    name: 'Sistematica - Acompanhamento visitas - Central',
                    title: 'Sistematica - Acompanhamento visitas - Central',
                    state: APP_STATE.RELATORIOS.OUTROS.SISTEMATICA_ACOMPANHAMENTO_VISITAS_CENTRAL,
                    enabled: true
                },
                {
                    id: REPORT_GROUP.OUTROS.SISTEMATICA_MONITORAMENTO_CENTRAL,
                    name: 'Sistemática – Monitoramento - Central',
                    title: 'Sistemática – Monitoramento - Central',
                    state: APP_STATE.RELATORIOS.OUTROS.SISTEMATICA_MONITORAMENTO_CENTRAL,
                    enabled: true
                }
            );
        }

        var _reportsField = {};
        _reportsField[REPORT_GROUP.RESULTADO_FINAL.ALFABETIZACAO_ACELERACAO] = {
            '1.1': RESULTADO_FINAL_REPORT_ALFABETIZACAO_ACELERACAO_SE_LIGA_MODELO_1_FIELDS,
            '1.2': RESULTADO_FINAL_REPORT_ALFABETIZACAO_ACELERACAO_SE_LIGA_MODELO_2_FIELDS,
            '1.3': RESULTADO_FINAL_REPORT_ALFABETIZACAO_ACELERACAO_SE_LIGA_MODELO_3_FIELDS,
            '2.1': RESULTADO_FINAL_REPORT_ALFABETIZACAO_ACELERACAO_ACELERA_BRASIL_MODELO_1_FIELDS,
            '2.2': RESULTADO_FINAL_REPORT_ALFABETIZACAO_ACELERACAO_ACELERA_BRASIL_MODELO_2_FIELDS,
            '2.3': RESULTADO_FINAL_REPORT_ALFABETIZACAO_ACELERACAO_ACELERA_BRASIL_MODELO_3_FIELDS
        };
        _reportsField[REPORT_GROUP.RESULTADO_FINAL.ENSINO_REGULAR] = {
            '8.1': RESULTADO_FINAL_REPORT_ENSINO_REGULAR_INICIAIS_MODELO_1_FIELDS,
            '9.1': RESULTADO_FINAL_REPORT_ENSINO_REGULAR_INICIAIS_MODELO_1_FIELDS,
            '10.1': RESULTADO_FINAL_REPORT_ENSINO_REGULAR_INICIAIS_MODELO_1_FIELDS,
            '13.1': RESULTADO_FINAL_REPORT_ENSINO_REGULAR_INICIAIS_MODELO_1_FIELDS,
            '14.1': RESULTADO_FINAL_REPORT_ENSINO_REGULAR_INICIAIS_MODELO_1_FIELDS,
            '16.1': RESULTADO_FINAL_REPORT_ENSINO_REGULAR_INICIAIS_MODELO_1_FIELDS,
            '17.1': RESULTADO_FINAL_REPORT_ENSINO_REGULAR_INICIAIS_MODELO_1_FIELDS,
            '18.1': RESULTADO_FINAL_REPORT_ENSINO_REGULAR_INICIAIS_MODELO_1_FIELDS,
            '19.1': RESULTADO_FINAL_REPORT_ENSINO_REGULAR_INICIAIS_MODELO_1_FIELDS,
            '20.1': RESULTADO_FINAL_REPORT_ENSINO_REGULAR_INICIAIS_MODELO_1_FIELDS,
            '21.1': RESULTADO_FINAL_REPORT_ENSINO_REGULAR_FINAIS_MODELO_1_FIELDS,
            '22.1': RESULTADO_FINAL_REPORT_ENSINO_REGULAR_FINAIS_MODELO_1_FIELDS,
            '23.1': RESULTADO_FINAL_REPORT_ENSINO_REGULAR_FINAIS_MODELO_1_FIELDS,
            '24.1': RESULTADO_FINAL_REPORT_ENSINO_REGULAR_FINAIS_MODELO_1_FIELDS,
            '8.2': RESULTADO_FINAL_REPORT_ENSINO_REGULAR_INICIAIS_MODELO_2_FIELDS,
            '9.2': RESULTADO_FINAL_REPORT_ENSINO_REGULAR_INICIAIS_MODELO_2_FIELDS,
            '10.2': RESULTADO_FINAL_REPORT_ENSINO_REGULAR_INICIAIS_MODELO_2_FIELDS,
            '13.2': RESULTADO_FINAL_REPORT_ENSINO_REGULAR_INICIAIS_MODELO_2_FIELDS,
            '14.2': RESULTADO_FINAL_REPORT_ENSINO_REGULAR_INICIAIS_MODELO_2_FIELDS,
            '16.2': RESULTADO_FINAL_REPORT_ENSINO_REGULAR_INICIAIS_MODELO_2_FIELDS,
            '17.2': RESULTADO_FINAL_REPORT_ENSINO_REGULAR_INICIAIS_MODELO_2_FIELDS,
            '18.2': RESULTADO_FINAL_REPORT_ENSINO_REGULAR_INICIAIS_MODELO_2_FIELDS,
            '19.2': RESULTADO_FINAL_REPORT_ENSINO_REGULAR_INICIAIS_MODELO_2_FIELDS,
            '20.2': RESULTADO_FINAL_REPORT_ENSINO_REGULAR_INICIAIS_MODELO_2_FIELDS,
            '21.2': RESULTADO_FINAL_REPORT_ENSINO_REGULAR_FINAIS_MODELO_2_FIELDS,
            '22.2': RESULTADO_FINAL_REPORT_ENSINO_REGULAR_FINAIS_MODELO_2_FIELDS,
            '23.2': RESULTADO_FINAL_REPORT_ENSINO_REGULAR_FINAIS_MODELO_2_FIELDS,
            '24.2': RESULTADO_FINAL_REPORT_ENSINO_REGULAR_FINAIS_MODELO_2_FIELDS
        };
        _reportsField[REPORT_GROUP.RESULTADO_FINAL.FORMULA_VITORIA] = RESULTADO_FINAL_REPORT_FORMULA_VITORIA_FIELDS;
        _reportsField[REPORT_GROUP.RESULTADO_FINAL.ENSINO_REGULAR_MATRICULA_MOVIMENTACAO] = RESULTADO_FINAL_REPORT_ENSINO_REGULAR_MATRICULA_MOVIMENTACAO_FIELDS;
        _reportsField[REPORT_GROUP.RESULTADO_FINAL.ALFABETIZACAO_2ANO_1SERIE_REGULAR] = RESULTADO_FINAL_REPORT_ALFABETIZACAO_2ANO_1SERIE_REGULAR_FIELDS;
        _reportsField[REPORT_GROUP.RESULTADO_FINAL.GESTAO_DA_ALFABETIZACAO] = RESULTADO_FINAL_REPORT_GESTAO_DA_ALFABETIZACAO_FIELDS;
        _reportsField[REPORT_GROUP.OUTROS.NUMERO_ALUNOS_POR_ANO_SITUACAO] = OUTROS_NUMERO_ALUNOS_POR_ANO_SITUACAO_FIELDS;
        _reportsField[REPORT_GROUP.BASE_MONITORAMENTO.ACOMPANHAMENTO] = {
            '1': BASE_MONITORAMENTO_REPORT_SE_LIGA_FIELDS,
            '2': BASE_MONITORAMENTO_REPORT_ACELERA_BRASIL_FIELDS,
            '3': BASE_MONITORAMENTO_REPORT_FORMULA_PORTUGUES_FIELDS,
            '5': BASE_MONITORAMENTO_REPORT_MATEMATICA_NIVEL_1_FIELDS,
            '6': BASE_MONITORAMENTO_REPORT_MATEMATICA_NIVEL_2_FIELDS,
            '7': BASE_MONITORAMENTO_REPORT_GESTAO_ALFABETIZACAO_FIELDS
        };
        _reportsField[REPORT_GROUP.RESULTADO_BIMESTRAL.FORMULA_VITORIA] = {
            '3': RESULTADO_BIMESTRAL_REPORT_FORMULA_VITORIA_FIELDS,
            '5': RESULTADO_BIMESTRAL_REPORT_FORMULA_VITORIA_FIELDS,
            '6': RESULTADO_BIMESTRAL_REPORT_FORMULA_VITORIA_FIELDS
        };
        _reportsField[REPORT_GROUP.RESULTADO_BIMESTRAL.ENSINO_REGULAR] = {
            '8': RESULTADO_BIMESTRAL_REPORT_ENSINO_REGULAR_FIELDS,
            '9': RESULTADO_BIMESTRAL_REPORT_ENSINO_REGULAR_FIELDS,
            '10': RESULTADO_BIMESTRAL_REPORT_ENSINO_REGULAR_FIELDS,
            '11': RESULTADO_BIMESTRAL_REPORT_ENSINO_REGULAR_FIELDS,
            '12': RESULTADO_BIMESTRAL_REPORT_ENSINO_REGULAR_FIELDS,
            '13': RESULTADO_BIMESTRAL_REPORT_ENSINO_REGULAR_FIELDS,
            '14': RESULTADO_BIMESTRAL_REPORT_ENSINO_REGULAR_FIELDS,
            '15': RESULTADO_BIMESTRAL_REPORT_ENSINO_REGULAR_FIELDS,
            '16': RESULTADO_BIMESTRAL_REPORT_ENSINO_REGULAR_FIELDS,
            '17': RESULTADO_BIMESTRAL_REPORT_ENSINO_REGULAR_FIELDS,
            '18': RESULTADO_BIMESTRAL_REPORT_ENSINO_REGULAR_FIELDS,
            '19': RESULTADO_BIMESTRAL_REPORT_ENSINO_REGULAR_FIELDS,
            '20': RESULTADO_BIMESTRAL_REPORT_ENSINO_REGULAR_FIELDS,
            '21': RESULTADO_BIMESTRAL_REPORT_ENSINO_REGULAR_FIELDS,
            '22': RESULTADO_BIMESTRAL_REPORT_ENSINO_REGULAR_FIELDS,
            '23': RESULTADO_BIMESTRAL_REPORT_ENSINO_REGULAR_FIELDS,
            '24': RESULTADO_BIMESTRAL_REPORT_ENSINO_REGULAR_FIELDS
        };
        _reportsField[REPORT_GROUP.ACOMPANHAMENTO_MENSAL.ALFABETIZACAO_ACELERACAO_FORMULA_VITORIA] = {
            '1': ACOMPANHAMENTO_MENSAL_REPORT_ALFABETIZACAO_ACELERACAO_FIELDS,
            '2': ACOMPANHAMENTO_MENSAL_REPORT_ALFABETIZACAO_ACELERACAO_FIELDS,
            '3': ACOMPANHAMENTO_MENSAL_REPORT_FORMULA_VITORIA_FIELDS,
            '5': ACOMPANHAMENTO_MENSAL_REPORT_FORMULA_VITORIA_FIELDS,
            '6': ACOMPANHAMENTO_MENSAL_REPORT_FORMULA_VITORIA_FIELDS,
            '7': ACOMPANHAMENTO_MENSAL_REPORT_FORMULA_VITORIA_FIELDS,
            '8': ACOMPANHAMENTO_MENSAL_REPORT_ENSINO_REGULAR_FIELDS,
            '9': ACOMPANHAMENTO_MENSAL_REPORT_ENSINO_REGULAR_FIELDS,
            '10': ACOMPANHAMENTO_MENSAL_REPORT_ENSINO_REGULAR_FIELDS,
            '11': ACOMPANHAMENTO_MENSAL_REPORT_ENSINO_REGULAR_FIELDS,
            '12': ACOMPANHAMENTO_MENSAL_REPORT_ENSINO_REGULAR_FIELDS,
            '13': ACOMPANHAMENTO_MENSAL_REPORT_ENSINO_REGULAR_FIELDS,
            '14': ACOMPANHAMENTO_MENSAL_REPORT_ENSINO_REGULAR_FIELDS,
            '16': ACOMPANHAMENTO_MENSAL_REPORT_ENSINO_REGULAR_FIELDS,
            '17': ACOMPANHAMENTO_MENSAL_REPORT_ENSINO_REGULAR_FIELDS,
            '18': ACOMPANHAMENTO_MENSAL_REPORT_ENSINO_REGULAR_FIELDS,
            '19': ACOMPANHAMENTO_MENSAL_REPORT_ENSINO_REGULAR_FIELDS,
            '20': ACOMPANHAMENTO_MENSAL_REPORT_ENSINO_REGULAR_FIELDS,
            '21': ACOMPANHAMENTO_MENSAL_REPORT_ENSINO_REGULAR_FIELDS,
            '22': ACOMPANHAMENTO_MENSAL_REPORT_ENSINO_REGULAR_FIELDS,
            '23': ACOMPANHAMENTO_MENSAL_REPORT_ENSINO_REGULAR_FIELDS,
            '24': ACOMPANHAMENTO_MENSAL_REPORT_ENSINO_REGULAR_FIELDS
        };
        _reportsField[REPORT_GROUP.BASE_MONITORAMENTO.ALFABETIZACAO] = BASE_MONITORAMENTO_REPORT_SE_LIGA_FIELDS;
        _reportsField[REPORT_GROUP.BASE_MONITORAMENTO.ACELERACAO] = BASE_MONITORAMENTO_REPORT_ACELERA_BRASIL_FIELDS;
        _reportsField[REPORT_GROUP.PERFIL_TURMA.ALFABETIZACAO_ACELERACAO] = PERFIL_TURMA_REPORT_ALFABETIZACAO_ACELERACAO_FIELDS;
        _reportsField[REPORT_GROUP.PERFIL_TURMA.FORMULA_VITORIA] = PERFIL_TURMA_REPORT_FORMULA_VITORIA_FIELDS;
        _reportsField[REPORT_GROUP.PERFIL_TURMA.GESTAO_DA_ALFABETIZACAO] = PERFIL_TURMA_REPORT_GESTAO_DA_ALFABETIZACAO_FIELDS;
        _reportsField[REPORT_GROUP.PERFIL_TURMA.SISTEMATICA_ACOMPANHAMENTO_DIALOGOS] = PERFIL_TURMA_REPORT_SISTEMATICA_ACOMPANHAMENTO_DIALOGOS_FIELDS;
        _reportsField[REPORT_GROUP.PERFIL_TURMA.ENSINO_MEDIO_GESTAO_NOTA_10] = PERFIL_TURMA_REPORT_ENSINO_MEDIO_GESTAO_NOTA_10_FIELDS;
        _reportsField[REPORT_GROUP.PERFIL_TURMA.ENSINO_REGULAR] = PERFIL_TURMA_REPORT_ENSINO_REGULAR_FIELDS;
        _reportsField[REPORT_GROUP.ACOMPANHAMENTO_MENSAL.LEITURA_ESCRITA_ORALIDADE_TEXTO] = ACOMPANHAMENTO_MENSAL_REPORT_LEITURA_ESCRITA_ORALIDADE_TEXTO_MODEL_SE_LIGA_FIELDS;
        _reportsField[REPORT_GROUP.ACOMPANHAMENTO_MENSAL.LEITURA_ESCRITA_PRODUCAO_TEXTO] = ACOMPANHAMENTO_MENSAL_REPORT_LEITURA_ESCRITA_ORALIDADE_TEXTO_MODEL_ACELERA_FIELDS;
        _reportsField[REPORT_GROUP.ACOMPANHAMENTO_MENSAL.ENSINO_REGULAR] = ACOMPANHAMENTO_MENSAL_REPORT_ENSINO_REGULAR_FIELDS;
        _reportsField[REPORT_GROUP.ACOMPANHAMENTO_MENSAL.MATEMATICA] = {
            /* Fórmula da Vitória Matemática – Nível 1 */
            'level.1': ACOMPANHAMENTO_MENSAL_REPORT_MATEMATICA_FORMULA_VITORIA_MATEMATICA_NIVEL_1_FIELDS,
            /* Fórmula da Vitória Matemática – Nível 2 */
            'level.2': ACOMPANHAMENTO_MENSAL_REPORT_MATEMATICA_FORMULA_VITORIA_MATEMATICA_NIVEL_2_FIELDS
        };
        _reportsField[REPORT_GROUP.ACOMPANHAMENTO_MENSAL.FORMULA_VITORIA_LINGUA_PORTUGUESA_ACOMPANHAMENTO] = FORMULA_VITORIA_LINGUA_PORTUGUESA_ACOMPANHAMENTO_FIELDS;
        /* Fórmula da Vitória Matemática – Nível 2 */
        _reportsField[REPORT_GROUP.ACOMPANHAMENTO_MENSAL.LEITURA_ESTRATEGIA_COMPREENSAO_MAT_NV_1] = ACOMPANHAMENTO_MENSAL_REPORT_MATEMATICA_FORMULA_VITORIA_MATEMATICA_NIVEL_1_FIELDS;
        _reportsField[REPORT_GROUP.ACOMPANHAMENTO_MENSAL.LEITURA_ESTRATEGIA_COMPREENSAO_MAT_NV_2] = ACOMPANHAMENTO_MENSAL_REPORT_MATEMATICA_FORMULA_VITORIA_MATEMATICA_NIVEL_2_FIELDS;
        _reportsField[REPORT_GROUP.ACOMPANHAMENTO_MENSAL.FLUXO_AULAS] = {
            '1': ACOMPANHAMENTO_MENSAL_REPORT_FLUXO_AULAS_SE_LIGA_FIELDS,
            '2': ACOMPANHAMENTO_MENSAL_REPORT_FLUXO_AULAS_FIELDS
        };
        _reportsField[REPORT_GROUP.ACOMPANHAMENTO_MENSAL.FLUXO_DE_OFICINAS] = {
            /* Formula da Vitoria Lingua Portuguesa*/
            '3': ACOMPANHAMENTO_MENSAL_FLUXO_DE_OFICINAS_FORMULA_VITORIA_LINGUA_PORTUGUESA_FIELDS,
            /* Formula da Vitoria Lingua Matematica 1*/
            '5': ACOMPANHAMENTO_MENSAL_FLUXO_DE_OFICINAS_FORMULA_VITORIA_MATEMATICA_FIELDS,
            /* Formula da Vitoria Lingua Matematica 2*/
            '6': ACOMPANHAMENTO_MENSAL_FLUXO_DE_OFICINAS_FORMULA_VITORIA_MATEMATICA_FIELDS
        };
        _reportsField[REPORT_GROUP.ACOMPANHAMENTO_MENSAL.ACOMPANHAMENTO_MUNICIPIO] = {
            '1.5': ACOMPANHAMENTO_MENSAL_MUNICIPIO_QUANTITATIVO_FIELDS,
            '2.5': ACOMPANHAMENTO_MENSAL_MUNICIPIO_QUANTITATIVO_FIELDS,
            '8.3': ACOMPANHAMENTO_MENSAL_MUNICIPIO_QUANTITATIVO_RELATORIO_I_FIELDS,
            '1.6': ACOMPANHAMENTO_MENSAL_MUNICIPIO_QUANTITATIVO_FIELDS,
            '2.6': ACOMPANHAMENTO_MENSAL_MUNICIPIO_QUANTITATIVO_FIELDS,
            '8.4': ACOMPANHAMENTO_MENSAL_MUNICIPIO_QUANTITATIVO_FIELDS,
        };

        _reportsField[REPORT_GROUP.RESULTADO_FINAL.INDICADORES_SUCESSO_RESULTADOS] = {
            '1': RESULTADO_FINAL_REPORT_INDICADORES_SUCESSO_RESULTADOS_FIELDS
        };

        /* Inicio ACOMPANHAMENTO_SEMESTRAL*/
        _reportsField[REPORT_GROUP.RESULTADO_BIMESTRAL.LETRAMENTO_EM_PROGRAMACAO] = {

            '1': ACOMPANHAMENTO_SEMESTRAL_REGIONAL_UF_FIELDS,
            '2': ACOMPANHAMENTO_SEMESTRAL_REGIONAL_MUNICIPAL_FIELDS,
            '3': ACOMPANHAMENTO_SEMESTRAL_GRUPO_MUNICIPIO_IAS_FIELDS,
            '4': ACOMPANHAMENTO_SEMESTRAL_ESTADO_FIELDS,
            '5': ACOMPANHAMENTO_SEMESTRAL_MUNICIPIO_FIELDS,
            '6': ACOMPANHAMENTO_SEMESTRAL_ESCOLA_FIELDS,
            '7': ACOMPANHAMENTO_SEMESTRAL_TURMA_FIELDS,
            '8': ACOMPANHAMENTO_SEMESTRAL_PROFESSOR_FIELDS
        }

        /* Fim ACOMPANHAMENTO_SEMESTRAL*/

        /* Inicio RESULTADO_FINAL LETRAMENTO EM PROGRAMACAO */
        _reportsField[REPORT_GROUP.RESULTADO_FINAL.LETRAMENTO_EM_PROGRAMACAO] = {
            '1': RESULTADO_FINAL_REGIONAL_UF_FIELDS,
            '2': RESULTADO_FINAL_REGIONAL_MUNICIPAL_FIELDS,
            '3': RESULTADO_FINAL_GRUPO_MUNICIPIO_IAS_FIELDS,
            '4': RESULTADO_FINAL_ESTADO_FIELDS,
            '5': RESULTADO_FINAL_MUNICIPIO_FIELDS,
            '6': RESULTADO_FINAL_ESCOLA_FIELDS,
            '7': RESULTADO_FINAL_TURMA_FIELDS,
            '8': RESULTADO_FINAL_PROFESSOR_FIELDS
        };
        /* Inicio RESULTADO_FINAL LETRAMENTO EM PROGRAMACAO */

        _reportsField[REPORT_GROUP.ACOMPANHAMENTO_ANUAL.PERFIL_ATENDIMENTO_UNIDADES_ESCOLARES_TURMAS] = ACOMPANHAMENTO_ANUAL_PERFIL_ATENDIMENTO_UNIDADES_ESCOLARES_TURMAS_FIELDS;
        _reportsField[REPORT_GROUP.OUTROS.TOTAL_ESCOLAS_PROGRAMA] = TOTAL_ESCOLAS_PROGRAMA_FIELDS;
        /* RESULTADO_FINAL - Idade de Conclusão da 4a Série/5o ano */
        _reportsField[REPORT_GROUP.RESULTADO_FINAL.IDADE_CONCLUSAO_4SERIE_5ANO] = {
            '1': RESULTADO_FINAL_IDADE_CONCLUSAO_4SERIE_5ANO_ESTADO_FIELDS,
            '2': RESULTADO_FINAL_IDADE_CONCLUSAO_4SERIE_5ANO_FIELDS
        };
        _reportsField[REPORT_GROUP.OUTROS.MUNICIPIOS_POR_PROGRAMA] = OUTROS_MUNICIPIOS_POR_PROGRAMA_FIELDS;

        _reportsField[REPORT_GROUP.OUTROS.ESCOLAS_EDUCACAO_INFANTIL_JOVENS_ADULTOS] = OUTROS_ESCOLAS_EDUCACAO_INFANTIL_JOVENS_ADULTOS_FIELDS;

        _reportsField[REPORT_GROUP.OUTROS.LISTA_UNIDADES_ESCOLARES] = OUTROS_LISTA_UNIDADES_ESCOLARES_FIELDS;
        _reportsField[REPORT_GROUP.OUTROS.MEDIA_DIAS_LETIVOS] = OUTROS_MEDIA_DIAS_LETIVOS_FIELDS;
        /** RESULTADO_FINAL - Idade de Conclusão da 8a Série/9o ano */

        _reportsField[REPORT_GROUP.RESULTADO_FINAL.IDADE_CONCLUSAO_8SERIE_9ANO] = {
            '1': RESULTADO_FINAL_IDADE_CONCLUSAO_8SERIE_9ANO_ESTADO_FIELDS,
            '2': RESULTADO_FINAL_IDADE_CONCLUSAO_8SERIE_9ANO_FIELDS
        };
        /** Outros - Gênero e situação do ano anterior */
        _reportsField[REPORT_GROUP.OUTROS.GENERO_SITUACAO_ANO_ANTERIOR] = OUTROS_GENERO_SITUACAO_ANO_ANTERIOR_FIELDS;
        _reportsField[REPORT_GROUP.OUTROS.PERFIL_ATENDIMENTO_MUNICIPIO] = OUTROS_PERFIL_ATENDIMENTO_MUNICIPIO_FIELDS;
        _reportsField[REPORT_GROUP.OUTROS.LISTA_UNIDADES_ESCOLARES_PERFIL_ATENDIMENTO] = OUTROS_UNIDADE_ESCOLAR_PERFIL_ATENDIMENTO_FIELDS;
        _reportsField[REPORT_GROUP.OUTROS.MATRICULA_ENSINO_FUNDAMENTAL_REDE] = OUTROS_MATRICULA_ENSINO_FUNDAMENTAL_REDE_FIELDS;
        _reportsField[REPORT_GROUP.OUTROS.STATUS_INSERCAO_COLETAS] = OUTROS_STATUS_INSERCAO_COLETAS_FIELDS;

        if (SecurityManagerFactory.hasPermission('DSP:03')) {
            _reportsField[REPORT_GROUP.OUTROS.SISTEMATICA_ACOMPANHAMENTO_VISITAS_REGIONAL] = OUTROS_SISTEMATICA_ACOMPANHAMENTO_VISITAS_REGIONAL;
            _reportsField[REPORT_GROUP.OUTROS.SISTEMATICA_MONITORAMENTO_REGIONAL] = OUTROS_SISTEMATICA_MONITORAMENTO_REGIONAL;
        }

        if (SecurityManagerFactory.hasPermission('DSP:04')) {
            _reportsField[REPORT_GROUP.OUTROS.SISTEMATICA_ACOMPANHAMENTO_VISITAS_CENTRAL] = OUTROS_SISTEMATICA_ACOMPANHAMENTO_VISITAS_CENTRAL;
            _reportsField[REPORT_GROUP.OUTROS.SISTEMATICA_MONITORAMENTO_CENTRAL] = OUTROS_SISTEMATICA_MONITORAMENTO_CENTRAL;
        }


        this.findDetalhamentos = function () {
            return $q(function (resolve, reject) {
                Relatorios.findDetalhamentos().$promise.then(function (data) {
                    var detalhamentos = _.map(data, function (item) {
                        return {
                            key: item.id,
                            value: item.name
                        };
                    });

                    resolve(detalhamentos);
                }, function (error) {
                    reject(error);
                });
            });
        };

        this.filter = function (filter) {
            var newFilter = self.transformFilter(filter);

            return Relatorios.filter(newFilter).$promise;
        };

        this.filterGestaoAlfabetizacao = function (dados, filtros) {
            if (filtros.tipoModelo == 1) {
                for (let i = 0; i < dados.length; i++) {
                    if (filtros.detalhamento == 'T') {
                        delete dados[i].turmasPercentual

                        delete dados[i].turmasTotal;
                        delete dados[i].turmasColetadas;

                    }
                    if (filtros.detalhamento != 'R') {
                        delete dados[i].nomeRegionalMunicipal

                    }
                    if (!dados[i].anoEscolar) {
                        dados[i].anoEscolar = "Não Informado"
                    }
                    if (filtros.detalhamento == '8') {
                        if (!dados[i].nomeGrupoMunicipioIAS) {
                            dados[i].nomeGrupoMunicipioIAS = "Todos"
                        }
                        if (!dados[i].nomeMunicipio) {
                            dados[i].nomeMunicipio = "Todos"
                        }
                    } else {
                        delete dados[i].nomeGrupoMunicipioIAS
                    }

                    if ((filtros.detalhamento != '8') && (filtros.detalhamento != 'R') && (filtros.detalhamento != 'M')) {
                        delete dados[i].nomeMunicipio
                    }


                    if (filtros.detalhamento == 'R') {
                        dados[i].detalhamento
                        if (!dados[i].nomeRegionalMunicipal) {
                            dados[i].nomeRegionalMunicipal = "Todos"
                        }
                        delete dados[i].detalhamento
                    } else {
                        delete dados[i].detalhamento;
                    }
                    delete dados[i].matriculaInicialAno
                    delete dados[i].matriculaInicialMes
                    delete dados[i].matriculaTransferenciaRecebidas
                    delete dados[i].matriculaTransferenciaRecebidasPercentual
                    delete dados[i].matriculaTransferenciaExpedidas
                    delete dados[i].matriculaTransferenciaExpedidasPercentual
                    delete dados[i].matriculaAbandonoNumero
                    delete dados[i].matriculaAbandonoNumeroPercentual
                    delete dados[i].matriculaPercentual
                    delete dados[i].alunosFaltasJustificadas
                    delete dados[i].alunosFaltasNaoJustificadas
                    delete dados[i].alunosFaltasTotal
                    delete dados[i].alunosFaltasPercentual
                    delete dados[i].motivoFaltaMotivoDoenca
                    delete dados[i].motivoFaltaDificuldadeDeslocamento
                    delete dados[i].motivoFaltaQuestoesSociais
                    delete dados[i].motivoFaltaQuestoesPessoais
                    delete dados[i].motivoFaltaMotivoFaltaApoio
                    delete dados[i].motivoFaltaTrabalhoInfantil

                    //delete dados[i].turmasTotal;
                    //delete dados[i].turmasColetadas;
                    if (filtros.auxTotalization.id === 'accumulated') {
                        delete dados[i].andamentoDiasLetivosDadosMedia;
                        delete dados[i].andamentoDiasLetivosPrevistosMedia;
                    } else {
                        delete dados[i].andamentoDiasLetivosDados;
                        delete dados[i].andamentoDiasLetivosPrevistos;
                    }

                    delete dados[i].paraCasaNaoFeitosTotal;
                    delete dados[i].diasLetivosMatriculaAtual;

                    dados[i].mediacaoVisitas = parseFloat(parseFloat(dados[i].mediacaoVisitasMedia).toFixed(2));
                    dados[i].mediacaoReunioes = parseFloat(parseFloat(dados[i].mediacaoReunioesMedia).toFixed(2));

                    delete dados[i].mediacaoVisitasMedia;
                    delete dados[i].mediacaoVisitasMediaMes;
                    delete dados[i].mediacaoVisitasMes;
                    delete dados[i].mediacaoReunioesMedia;
                    delete dados[i].mediacaoReunioesMediaMes;
                    delete dados[i].mediacaoReunioesMes;
                    delete dados[i].qtDiasLetivos;
                    delete dados[i].matriculaInicialMesAnterior;
                    delete dados[i].nomef
                }

                //continua
                for (let j = 0; j < dados.length; j++) {

                }

                return dados
            } else if (filtros.tipoModelo == 2) {

                for (let i = 0; i < dados.length; i++) {
                    if (filtros.detalhamento == 'T') {
                        delete dados[i].turmasPercentual
                        delete dados[i].matriculaInicialAno
                        delete dados[i].turmasTotal;
                        delete dados[i].turmasColetadas;
                    }
                    if (filtros.detalhamento == 'E') {
                        delete dados[i].matriculaInicialAno
                    }
                    if (!dados[i].anoEscolar) {
                        dados[i].anoEscolar = "Não Informado"
                    }

                    if (filtros.detalhamento == '8') {
                        if (!dados[i].nomeGrupoMunicipioIAS) {
                            dados[i].nomeGrupoMunicipioIAS = "Todos"
                        }
                        if (!dados[i].nomeMunicipio) {
                            dados[i].nomeMunicipio = "Todos"
                        }
                    } else {
                        delete dados[i].nomeGrupoMunicipioIAS
                    }

                    if ((filtros.detalhamento != '8') && (filtros.detalhamento != 'R') && (filtros.detalhamento != 'M')) {
                        delete dados[i].nomeMunicipio
                    }

                    if (filtros.detalhamento == 'R') {
                        dados[i].detalhamento
                        if (!dados[i].nomeRegionalMunicipal) {
                            dados[i].nomeRegionalMunicipal = "Todos"
                        }
                    } else {
                        delete dados[i].detalhamento;
                        delete dados[i].nomeRegionalMunicipal
                    }

                    delete dados[i].andamentoDiasLetivosPrevistos
                    delete dados[i].andamentoDiasLetivosDados
                    delete dados[i].andamentoDiasLetivosDadosPercentual
                    delete dados[i].professorFaltasJustificadas
                    delete dados[i].professorFaltasNaoJustificadas
                    delete dados[i].professorFaltasTotal
                    delete dados[i].professorFaltasPercentual
                    delete dados[i].professorLicensas
                    delete dados[i].mediacaoVisitas
                    delete dados[i].mediacaoReunioes
                    delete dados[i].vlExperimentos
                    delete dados[i].paraCasaNaoFeitosTotal
                    delete dados[i].paraCasaNaoFeitosMedia
                    delete dados[i].alunosLivrosLidos
                    delete dados[i].alunosLivrosLidosMedia
                    delete dados[i].vl_nivel_pt_inicio_1
                    delete dados[i].vl_nivel_pt_inicio_2
                    delete dados[i].vl_nivel_pt_inicio_3
                    delete dados[i].vl_nivel_pt_inicio_4
                    delete dados[i].vl_nivel_mt_inicio_1
                    delete dados[i].vl_nivel_mt_inicio_2
                    delete dados[i].vl_nivel_mt_inicio_3
                    delete dados[i].vl_nivel_mt_inicio_4
                    delete dados[i].detalhamento;

                    //delete dados[i].turmasTotal;
                    //delete dados[i].turmasColetadas;

                    delete dados[i].andamentoDiasLetivosPrevistosMedia;
                    delete dados[i].andamentoDiasLetivosDadosMedia;
                    delete dados[i].paraCasaNaoFeitosTotal;
                    delete dados[i].diasLetivosMatriculaAtual;
                    delete dados[i].mediacaoVisitasMedia;
                    delete dados[i].mediacaoVisitasMediaMes;
                    delete dados[i].mediacaoVisitasMes;
                    delete dados[i].mediacaoReunioesMedia;
                    delete dados[i].mediacaoReunioesMediaMes;
                    delete dados[i].mediacaoReunioesMes;
                    delete dados[i].qtDiasLetivos;
                    delete dados[i].matriculaInicialMesAnterior;
                    delete dados[i].nomef
                    delete dados[i].paraCasaNaoFeitos
                    delete dados[i].andamentoDiasLetivosPrevistosMedia

                }

                return dados
            }
        }


        this.transformFilter = function (filter) {
            var newFilter = {};

            newFilter.page = filter.page;
            newFilter.size = filter.size;
            newFilter.idUf = (filter.uf) ? filter.uf.id : null;
            newFilter.idMunicipio = (filter.municipio) ? filter.municipio.id : null;
            newFilter.anoReferencia = filter.anoReferencia;
            newFilter.idRegional = (filter.regional) ? filter.regional.id : null;
            newFilter.idRegionalMunicipal = (filter.regionalMunicipal) ? filter.regionalMunicipal.id : null;
            newFilter.idDistrito = (filter.distrito) ? filter.distrito.id : null;
            newFilter.idPrograma = (filter.programa) ? filter.programa.id : null;
            newFilter.idColeta = (filter.coleta) ? filter.coleta.id : null;

            newFilter.tipoContrato = (filter.tipoContrato) ? filter.tipoContrato.key : null;
            newFilter.tipoEscola = (filter.tipoEscola) ? filter.tipoEscola.key : null;

            newFilter.idLocalizacao = (filter.localizacao) ? filter.localizacao.id : null;
            newFilter.idDependenciaAdministrativa = (filter.dependenciaAdministrativa) ? filter.dependenciaAdministrativa.id : null;
            newFilter.idEscola = (filter.escola) ? filter.escola.id : null;
            newFilter.idEtapa = (filter.etapa) ? filter.etapa.id : null;

            newFilter.turno = (filter.turno) ? filter.turno.key : null;
            newFilter.detalhamento = (filter.detalhamento) ? filter.detalhamento.key : null;
            //newFilter.anoEscolar = filter.

            var mesAnoTotalizacaoArray = _mesAnoToArray(filter.mesAnoTotalizacao);
            var mesAnoInicioArray = _mesAnoToArray(filter.mesAnoInicio);
            var mesAnoFimArray = _mesAnoToArray(filter.mesAnoFim);

            if (is.not.empty(mesAnoTotalizacaoArray)) {
                newFilter.mesTotalizacao = parseInt(mesAnoTotalizacaoArray[0]);
                newFilter.anoTotalizacao = parseInt(mesAnoTotalizacaoArray[1]);
            }

            if (is.not.empty(mesAnoInicioArray)) {
                newFilter.mesInicio = parseInt(mesAnoInicioArray[0]);
                newFilter.anoInicio = parseInt(mesAnoInicioArray[1]);
            }

            if (is.not.empty(mesAnoFimArray)) {
                newFilter.mesFim = parseInt(mesAnoFimArray[0]);
                newFilter.anoFim = parseInt(mesAnoFimArray[1]);
            }

            newFilter.reportPath = filter.reportType.path;
            newFilter.alvo = filter.reportType.alvo;

            return newFilter;
        };

        var _mesAnoToArray = function (mesAno) {
            if (is.existy(mesAno) && is.not.empty(mesAno)) {
                return mesAno.split("/");
            } else {
                return [];
            }
        };

        this.loadStates = function () {
            return stateService.ativos();
        };

        this.loadMunicipiosIAS = function () {
            return $http.get(APP_PROPERTY.URL + '/grupoMunicipio/findAll')
        }


        this.loadSchoolYears = function () {
            return schoolYearService.ativos();
        };

        this.loadProfessor = function (filter) {
            return professorService.byEscola(filter);
        }


        this.loadMonths = function () {
            return [
                { id: 1, key: '01', name: 'Janeiro', shortName: 'JAN' },
                { id: 2, key: '02', name: 'Fevereiro', shortName: 'FEV' },
                { id: 3, key: '03', name: 'Março', shortName: 'MAR' },
                { id: 4, key: '04', name: 'Abril', shortName: 'ABR' },
                { id: 5, key: '05', name: 'Maio', shortName: 'MAI' },
                { id: 6, key: '06', name: 'Junho', shortName: 'JUN' },
                { id: 7, key: '07', name: 'Julho', shortName: 'JUL' },
                { id: 8, key: '08', name: 'Agosto', shortName: 'AGO' },
                { id: 9, key: '09', name: 'Setembro', shortName: 'SET' },
                { id: 10, key: '10', name: 'Outubro', shortName: 'OUT' },
                { id: 11, key: '11', name: 'Novembro', shortName: 'NOV' },
                { id: 12, key: '12', name: 'Dezembro', shortName: 'DEZ' }
            ];
        };

        this.loadReportsFromGroup = function (reportGroup) {
            return _reportsGroup[reportGroup];
        };

        this.loadShoolTypes = function () {
            return schoolService.getTiposOrganizacao();
        };

        this.loadLocalizations = function () {
            return localizationService.ativos();
        };

        this.loadCitiesOfState = function (stateId) {
            return cityService.porUf(stateId);
        };

        this.loadCitiesOfStateRegional = function (stateRegionalId) {
            return cityService.porRegionalUf(stateRegionalId);
        };

        this.loadStateRegionalsOfState = function (stateId) {
            return stateService.regionais(stateId);
        };

        this.loadCityRegionalsOfCity = function (cityId) {
            return cityService.regionais(cityId);
        };

        this.loadSchoolsOfCity = function (cityId) {
            return schoolService.porMunicipio(cityId);
        };

        this.loadSchoolsOfCityRegion = function (idRegionalMunicipio) {
            return schoolService.porRegionalMunicipio(idRegionalMunicipio);
        };

        this.loadAdministrativeDependencies = function () {
            return administrativeDependencyService.ativos();
        };

        this.loadSchoolByFilter = function (filter) {
            return schoolService.byFilter(filter);
        };

        this.loadFormsByFilter = function (filter) {
            return Relatorios.findAcompanhamentoMunicipioFormulario(this.filter(1, filter));
        };

        this.loadClassShifts = function () {
            return turmasService.turnos();
        };

        this.loadClasses = function (filter) {
            return []; // TODO Implementar a pesquisa de classes pelo filtro.
        };

        this.loadPrograms = function (reportInfo) {
            // TODO Criar uma configuração para indicar, para cada relatório os programas que fazem parte do relatório
            // como os relatórios estão sendo criados estaticamente, os programas ficarão hard coded, e como há
            // vários ambientes é necessário criar configuração que será resolvido no momento do build, o build para
            // cada ambiente vai indicar os programas certos para cada relatório.
            // Exemplos: o relatório "Perfil da Turma - Fórmula da Vitória" usa os programas Fórmula da Vitória Língua Portuguesa, Matemática, etc.
            return programService.ativos().$promise;
        };

        this.loadClassSeries = function (resportInfo) {
            // TODO Criar um mecanismo para exibir as séries/anos escolares que um relatório vai utilizar.
            // Será uma configuração? Ou algo hard coded como está os meses do ano?
            return [];
        };

        this.loadClassTypes = function () {
            return turmasService.atendimentos();
        };

        this.formatColumn = function (item, field, reportInfo) {
            var formatedValue;
            var decimal = is.existy(field.decimal) ? field.decimal : 1;

            switch (field.type) {
                case TABLE_FIELDS_TYPE.DATE:
                    formatedValue = $filter('date')(objectService.deepValue(item, field.name), 'dd/MM/yyyy');
                    break;
                case TABLE_FIELDS_TYPE.AVERAGE:
                    formatedValue = $filter('number')(objectService.deepValue(item, field.name), decimal);
                    break;
                case TABLE_FIELDS_TYPE.INTEGER:
                    if (field.calculateType == TABLE_FIELDS_TYPE.AVERAGE) {
                        formatedValue = $filter('number')(objectService.deepValue(item, field.name), decimal);
                    } else {
                        formatedValue = objectService.deepValue(item, field.name);
                    }
                    break;
                default:
                    formatedValue = objectService.deepValue(item, field.name);
            }

            if (is.existy(field.na)) {
                if (formatedValue == "" || formatedValue == "0")
                    return "";
            }

            if (is.existy(field.programaList)) {
                formatedValue = this.formatProgramasColumn(formatedValue);
            }

            if (is.existy(field.etapaList)) {
                formatedValue = this.formatEtapasColumn(formatedValue);
            }

            return formatedValue;
        };

        this.formatProgramasColumn = function (formatedValue) {
            var newValue = "";
            var programas = formatedValue.split(";");

            for (var p = 0; p < programas.length; p++) {
                newValue += "&bull; " + programas[p] + "<br>";
            }

            return newValue;
        }

        this.formatEtapasColumn = function (formatedValue) {
            var newValue = "";
            var etapas = formatedValue.split(";");

            for (var p = 0; p < etapas.length; p++) {
                newValue += etapas[p] + "<br>";
            }

            return newValue;
        }

        //faz a somatoria para a porcentagem
        this.calculoSomaPorcentagens = function (dados, filtros) {
            var somatoria = {}
            somatoria.portugues = 0;
            somatoria.matematica = 0;
            somatoria.motivoFalta = 0;
            for (let i = 0; i < dados.length; i++) {

                if (filtros.tipoModelo == 1) {
                    somatoria.portugues = somatoria.portugues + dados[i].vl_nivel_pt_inicio_1 + dados[i].vl_nivel_pt_inicio_2 + dados[i].vl_nivel_pt_inicio_3 + dados[i].vl_nivel_pt_inicio_4;
                    somatoria.matematica = somatoria.matematica + dados[i].vl_nivel_mt_inicio_1 + dados[i].vl_nivel_mt_inicio_2 + dados[i].vl_nivel_mt_inicio_3 + dados[i].vl_nivel_mt_inicio_4;
                } else if (filtros.tipoModelo == 2) {
                    somatoria.motivoFalta = somatoria.motivoFalta + dados[i].motivoFaltaMotivoDoenca + dados[i].motivoFaltaDificuldadeDeslocamento + dados[i].motivoFaltaQuestoesSociais + dados[i].motivoFaltaQuestoesPessoais + dados[i].motivoFaltaMotivoFaltaApoio + dados[i].motivoFaltaTrabalhoInfantil;
                }
            }
            return somatoria;

        }

        //faz a somatoria para a porcentagem
        this.calculoSomaPorcentagensPerfilTurma = function (dados, filtros) {
            var somatoria = {};
            somatoria.idade = 0;
            somatoria.matematicaInicio = 0;
            somatoria.matematicaFim = 0;
            somatoria.portuguesInicio = 0;
            somatoria.portuguesFim = 0;
            for (let i = 0; i < dados.length; i++) {

                if (filtros.tipoModelo == 1) {
                    somatoria.idade = somatoria.idade + dados[i].idade5 + dados[i].idade6
                        + dados[i].idade7 + dados[i].idade8 + dados[i].idade9 + dados[i].idade10
                        + dados[i].idade11 + dados[i].idade12 + dados[i].idade13 + dados[i].idade14mais;
                } else if (filtros.tipoModelo == 2) {
                    somatoria.portuguesInicio = somatoria.portuguesInicio + dados[i].vlNivelPtInicio1 + dados[i].vlNivelPtInicio2
                        + dados[i].vlNivelPtInicio3 + dados[i].vlNivelPtInicio4;
                    somatoria.portuguesFim = somatoria.portuguesFim + dados[i].vlNivelPtFinal1 + dados[i].vlNivelPtFinal2
                        + dados[i].vlNivelPtFinal3 + dados[i].vlNivelPtFinal4;
                    somatoria.matematicaInicio = somatoria.matematicaInicio + dados[i].vlNivelMtInicio1 + dados[i].vlNivelMtInicio2
                        + dados[i].vlNivelMtInicio3 + dados[i].vlNivelMtInicio4;
                    somatoria.matematicaFim = somatoria.matematicaFim + dados[i].vlNivelMtFinal1 + dados[i].vlNivelMtFinal2
                        + dados[i].vlNivelMtFinal3 + dados[i].vlNivelMtFinal4;
                }
            }
            return somatoria;
        }

        //RONIFER
        this.calculoTotalRelatorio = function (dados, filtros, tipo) {
            var totalObj = {}
            if (tipo == "FINAL_GESTAO_ALFABETIZACAO") {
                delete totalObj.paraCasaNaoFeitosMedia
                delete totalObj.alunosLivrosLidosMedia
                if (filtros.tipoModelo == 1) {
                    for (let i = 0; i < dados.length; i++) {
                        for (let key in dados[i].modelo1) {
                            if (isNaN(dados[i].modelo1[key])) {
                                totalObj[key] = '-';
                            } else {
                                if (!totalObj[key]) {
                                    totalObj[key] = dados[i].modelo1[key]
                                } else {
                                    totalObj[key] = totalObj[key] + dados[i].modelo1[key]
                                }
                            }
                        }
                    }
                    let somaInicialPortugues1 = totalObj.vlPortuguesInicio1Fim1 + totalObj.vlPortuguesInicio1Fim2 + totalObj.vlPortuguesInicio1Fim3 + totalObj.vlPortuguesInicio1Fim4
                    totalObj.vlPortuguesTransicao1a1 = Number(Math.round(((totalObj.vlPortuguesInicio1Fim1 / somaInicialPortugues1) * 100) + 'e' + 1) + 'e-' + 1) || 0;
                    totalObj.vlPortuguesTransicao1a2 = Number(Math.round(((totalObj.vlPortuguesInicio1Fim2 / somaInicialPortugues1) * 100) + 'e' + 1) + 'e-' + 1) || 0;
                    totalObj.vlPortuguesTransicao1a3 = Number(Math.round(((totalObj.vlPortuguesInicio1Fim3 / somaInicialPortugues1) * 100) + 'e' + 1) + 'e-' + 1) || 0;
                    totalObj.vlPortuguesTransicao1a4 = Number(Math.round(((totalObj.vlPortuguesInicio1Fim4 / somaInicialPortugues1) * 100) + 'e' + 1) + 'e-' + 1) || 0;

                    let somaInicialPortugues2 = totalObj.vlPortuguesInicio2Fim2 + totalObj.vlPortuguesInicio2Fim3 + totalObj.vlPortuguesInicio2Fim4
                    totalObj.vlPortuguesTransicao2a2 = Number(Math.round(((totalObj.vlPortuguesInicio2Fim2 / somaInicialPortugues2) * 100) + 'e' + 1) + 'e-' + 1) || 0;
                    totalObj.vlPortuguesTransicao2a3 = Number(Math.round(((totalObj.vlPortuguesInicio2Fim3 / somaInicialPortugues2) * 100) + 'e' + 1) + 'e-' + 1) || 0;
                    totalObj.vlPortuguesTransicao2a4 = Number(Math.round(((totalObj.vlPortuguesInicio2Fim4 / somaInicialPortugues2) * 100) + 'e' + 1) + 'e-' + 1) || 0;

                    let somaInicialPortugues3 = totalObj.vlPortuguesInicio3Fim3 + totalObj.vlPortuguesInicio3Fim4
                    totalObj.vlPortuguesTransicao3a3 = Number(Math.round(((totalObj.vlPortuguesInicio3Fim3 / somaInicialPortugues3) * 100) + 'e' + 1) + 'e-' + 1) || 0;
                    totalObj.vlPortuguesTransicao3a4 = Number(Math.round(((totalObj.vlPortuguesInicio3Fim4 / somaInicialPortugues3) * 100) + 'e' + 1) + 'e-' + 1) || 0;

                    totalObj.vlPortuguesTransicao4a4 = Number(Math.round(((totalObj.vlPortuguesInicio4Fim4 / totalObj.vlPortuguesInicio4Fim4) * 100) + 'e' + 1) + 'e-' + 1) || 0;

                    totalObj.vlPortuguesTransicaoInicial1Avacou1Nivel = Number(Math.round((((totalObj.vlPortuguesInicio1Fim2 + totalObj.vlPortuguesInicio1Fim3 + totalObj.vlPortuguesInicio1Fim4) / somaInicialPortugues1) * 100) + 'e' + 1) + 'e-' + 1) || 0;
                    totalObj.vlPortuguesTransicaoInicial2Avacou1Nivel = Number(Math.round((((totalObj.vlPortuguesInicio2Fim3 + totalObj.vlPortuguesInicio2Fim4) / somaInicialPortugues2) * 100) + 'e' + 1) + 'e-' + 1) || 0;
                    totalObj.vlPortuguesTransicaoInicial3Avacou1Nivel = Number(Math.round((((totalObj.vlPortuguesInicio3Fim4) / somaInicialPortugues3) * 100) + 'e' + 1) + 'e-' + 1) || 0;



                    let somaInicialMatematica1 = totalObj.vlMatematicaInicio1Fim1 + totalObj.vlMatematicaInicio1Fim2 + totalObj.vlMatematicaInicio1Fim3 + totalObj.vlMatematicaInicio1Fim4
                    totalObj.vlMatematicaTransicao1a1 = Number(Math.round(((totalObj.vlMatematicaInicio1Fim1 / somaInicialMatematica1) * 100) + 'e' + 1) + 'e-' + 1) || 0;
                    totalObj.vlMatematicaTransicao1a2 = Number(Math.round(((totalObj.vlMatematicaInicio1Fim2 / somaInicialMatematica1) * 100) + 'e' + 1) + 'e-' + 1) || 0;
                    totalObj.vlMatematicaTransicao1a3 = Number(Math.round(((totalObj.vlMatematicaInicio1Fim3 / somaInicialMatematica1) * 100) + 'e' + 1) + 'e-' + 1) || 0;
                    totalObj.vlMatematicaTransicao1a4 = Number(Math.round(((totalObj.vlMatematicaInicio1Fim4 / somaInicialMatematica1) * 100) + 'e' + 1) + 'e-' + 1) || 0;

                    let somaInicialMatematica2 = totalObj.vlMatematicaInicio2Fim2 + totalObj.vlMatematicaInicio2Fim3 + totalObj.vlMatematicaInicio2Fim4
                    totalObj.vlMatematicaTransicao2a2 = Number(Math.round(((totalObj.vlMatematicaInicio2Fim2 / somaInicialMatematica2) * 100) + 'e' + 1) + 'e-' + 1) || 0;
                    totalObj.vlMatematicaTransicao2a3 = Number(Math.round(((totalObj.vlMatematicaInicio2Fim3 / somaInicialMatematica2) * 100) + 'e' + 1) + 'e-' + 1) || 0;
                    totalObj.vlMatematicaTransicao2a4 = Number(Math.round(((totalObj.vlMatematicaInicio2Fim4 / somaInicialMatematica2) * 100) + 'e' + 1) + 'e-' + 1) || 0;

                    let somaInicialMatematica3 = totalObj.vlMatematicaInicio3Fim3 + totalObj.vlMatematicaInicio3Fim4
                    totalObj.vlMatematicaTransicao3a3 = Number(Math.round(((totalObj.vlMatematicaInicio3Fim3 / somaInicialMatematica3) * 100) + 'e' + 1) + 'e-' + 1) || 0;
                    totalObj.vlMatematicaTransicao3a4 = Number(Math.round(((totalObj.vlMatematicaInicio3Fim4 / somaInicialMatematica3) * 100) + 'e' + 1) + 'e-' + 1) || 0;

                    totalObj.vlMatematicaTransicao4a4 = Number(Math.round(((totalObj.vlMatematicaInicio4Fim4 / totalObj.vlMatematicaInicio4Fim4) * 100) + 'e' + 1) + 'e-' + 1) || 0;

                    totalObj.vlMatematicaTransicaoInicial1Avacou1Nivel = Number(Math.round((((totalObj.vlMatematicaInicio1Fim2 + totalObj.vlMatematicaInicio1Fim3 + totalObj.vlMatematicaInicio1Fim4) / somaInicialMatematica1) * 100) + 'e' + 1) + 'e-' + 1) || 0;
                    totalObj.vlMatematicaTransicaoInicial2Avacou1Nivel = Number(Math.round((((totalObj.vlMatematicaInicio2Fim3 + totalObj.vlMatematicaInicio2Fim4) / somaInicialMatematica2) * 100) + 'e' + 1) + 'e-' + 1) || 0;
                    totalObj.vlMatematicaTransicaoInicial3Avacou1Nivel = Number(Math.round((((totalObj.vlMatematicaInicio3Fim4) / somaInicialMatematica3) * 100) + 'e' + 1) + 'e-' + 1) || 0;

                    totalObj.vlPortuguesTransicaoInicial1Avacou2Niveis = Number(Math.round((((totalObj.vlPortuguesInicio1Fim3 + totalObj.vlPortuguesInicio1Fim4) / somaInicialPortugues1) * 100) + 'e' + 1) + 'e-' + 1) || 0;
                    totalObj.vlPortuguesTransicaoInicial2Avacou2Niveis = Number(Math.round((((totalObj.vlPortuguesInicio2Fim4) / somaInicialPortugues2) * 100) + 'e' + 1) + 'e-' + 1) || 0;


                    totalObj.vlMatematicaTransicaoInicial1Avacou2Niveis = Number(Math.round((((totalObj.vlMatematicaInicio1Fim3 + totalObj.vlMatematicaInicio1Fim4) / somaInicialMatematica1) * 100) + 'e' + 1) + 'e-' + 1) || 0;
                    totalObj.vlMatematicaTransicaoInicial2Avacou2Niveis = Number(Math.round((((totalObj.vlMatematicaInicio2Fim4) / somaInicialMatematica2) * 100) + 'e' + 1) + 'e-' + 1) || 0;

                    let somaTotalPortugues = somaInicialPortugues1 + somaInicialPortugues2 + somaInicialPortugues3 + totalObj.vlPortuguesInicio4Fim4
                    totalObj.vlTotalPortuguesN1 = Number(Math.round(((somaInicialPortugues1 / somaTotalPortugues) * 100) + 'e' + 1) + 'e-' + 1) || 0;
                    totalObj.vlTotalPortuguesN2 = Number(Math.round(((somaInicialPortugues2 / somaTotalPortugues) * 100) + 'e' + 1) + 'e-' + 1) || 0;
                    totalObj.vlTotalPortuguesN3 = Number(Math.round(((somaInicialPortugues3 / somaTotalPortugues) * 100) + 'e' + 1) + 'e-' + 1) || 0;
                    totalObj.vlTotalPortuguesN4 = Number(Math.round(((totalObj.vlPortuguesInicio4Fim4 / somaTotalPortugues) * 100) + 'e' + 1) + 'e-' + 1) || 0;


                    let somaTotalMatematica = somaInicialMatematica1 + somaInicialMatematica2 + somaInicialMatematica3 + totalObj.vlMatematicaInicio4Fim4
                    totalObj.vlTotalMatematicaN1 = Number(Math.round(((somaInicialMatematica1 / somaTotalMatematica) * 100) + 'e' + 1) + 'e-' + 1) || 0;
                    totalObj.vlTotalMatematicaN2 = Number(Math.round(((somaInicialMatematica2 / somaTotalMatematica) * 100) + 'e' + 1) + 'e-' + 1) || 0;
                    totalObj.vlTotalMatematicaN3 = Number(Math.round(((somaInicialMatematica3 / somaTotalMatematica) * 100) + 'e' + 1) + 'e-' + 1) || 0;
                    totalObj.vlTotalMatematicaN4 = Number(Math.round(((totalObj.vlMatematicaInicio4Fim4 / somaTotalMatematica) * 100) + 'e' + 1) + 'e-' + 1) || 0;

                    totalObj.vlTotalPortugues = Number(Math.round(((somaTotalPortugues)) + 'e' + 1) + 'e-' + 1);
                    totalObj.vlTotalMatematica = Number(Math.round(((somaTotalMatematica)) + 'e' + 1) + 'e-' + 1);

                } else if (filtros.tipoModelo == 2) {
                    for (let i = 0; i < dados.length; i++) {
                        for (let key in dados[i].modelo2) {
                            if (isNaN(dados[i].modelo2[key])) {
                                totalObj[key] = '-';
                            } else {
                                if (!totalObj[key]) {
                                    totalObj[key] = dados[i].modelo2[key]
                                } else {
                                    totalObj[key] = totalObj[key] + dados[i].modelo2[key]
                                }
                            }
                        }
                    }
                }
            } else if (tipo == "MENSAL_GESTAO_ALFABETIZACAO") {
                for (let i = 0; i < dados.length; i++) {
                    for (let key in dados[i]) {
                        if (!totalObj[key]) {
                            totalObj[key] = dados[i][key]
                        } else {
                            totalObj[key] = totalObj[key] + dados[i][key]
                        }
                    }
                }

                if (filtros.auxTotalization.id == "monthly") {
                    totalObj['andamentoDiasLetivosPrevistosMedia'] = totalObj['andamentoDiasLetivosPrevistosMedia'] / dados.length
                    totalObj['andamentoDiasLetivosDadosMedia'] = totalObj['andamentoDiasLetivosDadosMedia'] / dados.length
                    totalObj['andamentoDiasLetivosDadosPercentual'] = ((totalObj['andamentoDiasLetivosDadosMedia'] / totalObj['andamentoDiasLetivosPrevistosMedia']) * 100).toFixed(1);
                } else {
                    totalObj.andamentoDiasLetivosPrevistos = totalObj.andamentoDiasLetivosPrevistos / dados.length
                    totalObj.andamentoDiasLetivosDados = totalObj.andamentoDiasLetivosDados / dados.length
                    totalObj['andamentoDiasLetivosDadosPercentual'] = ((totalObj['andamentoDiasLetivosDados'] / totalObj['andamentoDiasLetivosPrevistos']) * 100).toFixed(1);
                }
                totalObj.turmasPercentual = (totalObj.turmasColetadas * 100) / totalObj.turmasTotal

                if (filtros.detalhamento != 'T') {
                    totalObj.mediacaoVisitas = totalObj.mediacaoVisitas / dados.length
                    totalObj.mediacaoReunioes = totalObj.mediacaoReunioes / dados.length
                    totalObj.vlExperimentos = totalObj.vlExperimentos / dados.length
                }
            } else {
                for (let i = 0; i < dados.length; i++) {
                    for (let key in dados[i]) {
                        if (!totalObj[key]) {
                            totalObj[key] = dados[i][key]
                        } else {
                            totalObj[key] = totalObj[key] + dados[i][key]
                        }
                    }
                }
            }

            if (filtros.detalhamento.id == 'T') {
                delete totalObj.turmasPercentual;
            }
            try {


                if (filtros.tipoModelo == 1) {
                    if (totalObj.andamentoDiasLetivosPrevistos) {



                        totalObj.andamentoDiasLetivosDadosPercentual = Number(Math.round(((totalObj.andamentoDiasLetivosDados / totalObj.andamentoDiasLetivosPrevistos) * 100) + 'e' + 1) + 'e-' + 1);
                        //     totalObj.professorFaltasPercentual = Number(Math.round(((totalObj.professorFaltasTotal / totalObj.andamentoDiasLetivosPrevistosMedia) * 100)+'e'+2)+'e-'+2) +'%';
                        totalObj.professorFaltasPercentual = Number(Math.round(((totalObj.professorFaltasTotal / totalObj.aulasPrevistasSoma) * 100) + 'e' + 1) + 'e-' + 1);
                    }
                    if (totalObj.matriculaAtual == 0) {
                        totalObj.paraCasaNaoFeitosMedia = 0;
                        totalObj.alunosLivrosLidosMedia = 0;
                    } else {
                        totalObj.paraCasaNaoFeitosMedia = Number(Math.round(((totalObj.paraCasaNaoFeitos / totalObj.matriculaAtual)) + 'e' + 1) + 'e-' + 1);
                        totalObj.alunosLivrosLidosMedia = Number(Math.round(((totalObj.alunosLivrosLidos / totalObj.matriculaAtual)) + 'e' + 1) + 'e-' + 1);
                    }


                } else {

                    if (totalObj.matriculaInicialMes == 0) {
                        totalObj.matriculaTransferenciaRecebidasPercentual = 0;
                        totalObj.matriculaTransferenciaExpedidasPercentual = 0;
                        totalObj.matriculaAbandonoNumeroPercentual = 0;
                        totalObj.matriculaPercentual = 0;
                    } else {
                        totalObj.matriculaTransferenciaRecebidasPercentual = Number(Math.round(((totalObj.matriculaTransferenciaRecebidas / totalObj.matriculaInicialMes) * 100) + 'e' + 1) + 'e-' + 1);

                        totalObj.matriculaTransferenciaExpedidasPercentual = Number(Math.round(((totalObj.matriculaTransferenciaExpedidas / totalObj.matriculaInicialMes) * 100) + 'e' + 1) + 'e-' + 1);

                        totalObj.matriculaAbandonoNumeroPercentual = Number(Math.round(((totalObj.matriculaAbandonoNumero / totalObj.matriculaInicialMes) * 100) + 'e' + 1) + 'e-' + 1);

                        totalObj.matriculaPercentual = Number(Math.round(((totalObj.matriculaAtual / totalObj.matriculaInicialMes) * 100) + 'e' + 1) + 'e-' + 1) + '%';
                    }

                    if (totalObj.matriculaAtualDiasLetivosDados == 0) {
                        totalObj.alunosFaltasPercentual = 0;
                    } else {
                        totalObj.alunosFaltasPercentual = Number(Math.round(((totalObj.alunosFaltasTotal / totalObj.matriculaAtualDiasLetivosDados) * 100) + 'e' + 1) + 'e-' + 1);
                    }


                }
                if (filtros.tipoModelo == 1) {
                    for (let i = 0; i < dados.length; i++) {
                        delete dados[i].matriculaAtual;
                    }
                    delete totalObj.matriculaAtual;

                    delete totalObj.matriculaTransferenciaRecebidasPercentual;
                    delete totalObj.matriculaTransferenciaExpedidasPercentual;
                    delete totalObj.matriculaAbandonoNumeroPercentual;
                    delete totalObj.matriculaPercentual;
                    delete totalObj.alunosFaltasPercentual;
                    if (filtros.detalhamento == "T") {
                        delete totalObj.turmasPercentual
                    }
                } else {
                    for (let i = 0; i < dados.length; i++) {
                        delete dados[i].andamentoDiasLetivosDadosMedia;
                        delete dados[i].andamentoDiasLetivosDados;
                    }
                    delete totalObj.andamentoDiasLetivosDadosPercentual;

                    delete totalObj.andamentoDiasLetivosDadosMedia;
                    delete totalObj.andamentoDiasLetivosDados;
                    delete totalObj.professorFaltasPercentual;
                    delete totalObj.paraCasaNaoFeitosMedia;
                    delete totalObj.alunosLivrosLidosMedia;

                    //if(filtros.detalhamento == "M"){
                    delete totalObj.mediacaoReunioes;
                    delete totalObj.mediacaoVisitas;
                    delete totalObj.vlExperimentos;
                    delete totalObj.andamentoDiasLetivosPrevistos

                    if (filtros.detalhamento == "T") {
                        delete totalObj.turmasPercentual
                    }

                    //}
                }

                if (tipo == "FINAL_GESTAO_ALFABETIZACAO") {
                    delete totalObj.paraCasaNaoFeitosMedia
                    delete totalObj.alunosLivrosLidosMedia

                    delete totalObj.matriculaTransferenciaRecebidasPercentual
                    delete totalObj.matriculaTransferenciaExpedidasPercentual
                    delete totalObj.matriculaAbandonoNumeroPercentual
                    delete totalObj.matriculaPercentual
                    delete totalObj.alunosFaltasPercentual
                }
            } catch (err) {
                console.log(err.name + ': "' + err.message + '" occurred when assigning x.');
            }
            delete totalObj.anoEscolar;
            delete totalObj.nomeEscola;
            delete totalObj.nomeTurma;
            delete totalObj.id;
            delete totalObj.toJSON;

            delete totalObj.nomeMunicipio
            delete totalObj.nomeUf
            delete totalObj.nomeRegionalUF
            delete totalObj.nomeGrupoMunicipioIAS
            delete totalObj.nomeRegionalMunicipal
            delete totalObj.matriculaAtualDiasLetivosDados;

            delete totalObj.aulasPrevistasSoma;
            for (let i = 0; i < dados.length; i++) {
                delete dados[i].aulasPrevistasSoma;

                delete dados[i].matriculaAtualDiasLetivosDados;
            }

            for (var prop in totalObj) {
                if (!isNaN(totalObj[prop])) {
                    totalObj[prop] = Number(Math.round(totalObj[prop] + 'e' + 1) + 'e-' + 1);;
                }
            }


            return totalObj

        }

        this.formatSummaryColumn = function (calculations, field, reportInfo) {
            var result = is.existy(field.calculateTotal) && is.truthy(field.calculateTotal) ? '0' : '';

            var decimal = is.existy(field.decimal) ? field.decimal : 1;

            if (field.name == "andamentoDiasLetivosPrevistos" || field.name == "andamentoDiasLetivosDados") {
                field.calculateType = "average";
            }

            if (is.existy(field.calculateTotal) && is.truthy(field.calculateTotal)) {
                var value = calculations[field.name];
                var numElements = reportInfo.reportDataCount;

                if (is.existy(value) && numElements > 0) {
                    if (is.existy(field.calculateType) && field.calculateType == TABLE_FIELDS_TYPE.AVERAGE) {
                        if (is.existy(field.referenceField)) {
                            result = $filter('number')(calculateTotalPercent(calculations, field, reportInfo), decimal);
                            if (!result || result == "∞")
                                result = '0';
                        } else {
                            result = $filter('number')(value / numElements, decimal);
                        }
                    } else {
                        if (field.type == TABLE_FIELDS_TYPE.NUMBER) {
                            result = $filter('number')(value, decimal);
                        } else {
                            result = value;
                        }
                    }
                }
            }

            return result;
        };

        this.formatSummaryColumnTotalSemestral = function (calculations, field, reportInfo) {
            var result = is.existy(field.calculateTotal) && is.truthy(field.calculateTotal) ? '0' : '';

            if (is.existy(field.calculateTotal) && is.truthy(field.calculateTotal)) {
                var value = calculations[field.name];
                var numElements = reportInfo.reportDataCount;

                if (is.existy(value) && numElements > 0) {
                    if (is.existy(field.calculateType) && field.calculateType == TABLE_FIELDS_TYPE.AVERAGE) {
                        if (is.existy(field.referenceField)) {
                            result = $filter('number')(calculateTotalPercent(calculations, field, reportInfo), 1);
                            if (!result || result == "∞")
                                result = '0';
                        } else {
                            result = $filter('number')(value / numElements, 1);
                        }
                    } else {
                        if (field.type == TABLE_FIELDS_TYPE.NUMBER) {
                            result = $filter('number')(value, 1);
                        } else {
                            result = value;
                        }
                    }
                }
            }

            return result;
        };

        this.yearSubtotalColumn = function (results, field, year) {
            var resultCalulate = 0;
            var totalToAverage = 0;

            angular.forEach(results, function (value) {
                if (field.calculateTotal) {
                    if (field.calculateType == 'sum' && year == value.anoOrigemSerie) {
                        resultCalulate += value[field.name];
                    } else if (field.calculateType == 'average' && year == value.anoOrigemSerie) {
                        resultCalulate += value[field.referenceField];
                        totalToAverage += value[field.referenceTotalField];
                    }
                }
            });

            if (field.calculateTotal && field.calculateType == 'average') {
                if (field.percent) {
                    resultCalulate = $filter('number')(resultCalulate / totalToAverage * 100, 1);
                } else {
                    resultCalulate = $filter('number')(resultCalulate / totalToAverage, 1);
                }
            }

            if (is.empty(resultCalulate)) {
                resultCalulate = 0;
            }

            return resultCalulate;
        };

        /**
        * Função responsável por realizar o calculo do percentual de uma coluna para os casos em que existir o atributo 'referenceField'
        * na definição do campo.
        * O mesmo poderá ser usado em conjunto com atributo 'referenceTotalField' na definição do campo, que poderá ser uma String
        * ou um Array de String com o nome dos campos que serão utilizados no calculo.
        */
        function calculateTotalPercent(calculations, field, reportInfo) {
            var totalFieldSum;
            var numElements = reportInfo.reportDataCount;

            if (Array.isArray(field.referenceField)) {
                totalFieldSum = 0;
                for (var i = 0; i < field.referenceField.length; i++) {
                    totalFieldSum = calculations[field.referenceField[i]];
                }
            } else {
                totalFieldSum = calculations[field.referenceField];
            }

            if (is.existy(field.referenceTotalField)) {
                totalFieldSum *= 1;

                if (field.percent)
                    totalFieldSum *= 100;

                if (Array.isArray(field.referenceTotalField)) {
                    numElements = 0;
                    for (var i = 0; i < field.referenceTotalField.length; i++) {
                        numElements += calculations[field.referenceTotalField[i]];
                    }
                } else {
                    numElements = calculations[field.referenceTotalField];
                }
            }

            if (is.existy(field.getReferenceTotalField)) {
                totalFieldSum *= 1;

                if (field.percent)
                    totalFieldSum *= 100;

                numElements = field.getReferenceTotalField(reportInfo);
            }

            if (is.existy(field.relCalcCustomTotal)) {
                return field.relCalcCustomTotal(reportInfo);
            }

            return totalFieldSum / numElements;
        }

        this.formatSummaryColumnPerc = function (calculations, field, reportInfo, reportSumLastField) {

            var result = is.existy(field.calculateTotal) && is.truthy(field.calculateTotal) ? '0' : '';

            if (is.existy(field.calculateTotal) && is.truthy(field.calculateTotal)) {
                var value = calculations[field.name];
                var numElements = calculations['total'];

                if (is.existy(value) && numElements > 0) {
                    if (is.existy(field.calculateType) && field.calculateType == TABLE_FIELDS_TYPE.AVERAGE) {
                        result = $filter('number')(value / numElements, 1);
                    } else {
                        if (field.type == TABLE_FIELDS_TYPE.NUMBER) {
                            result = $filter('number')(value, 1);
                        } else {
                            result = value;
                        }
                    }
                }
            }

            var calcule = (result / reportSumLastField) * 100;

            if (is.nan(calcule)) {
                calcule = 0;
            }

            return calcule;

        };

        this.performatCalculation = function (item, field, oldValue, reportInfo) {
            if (is.existy(field.calculateTotal) && is.truthy(field.calculateTotal) && !isNaN(objectService.deepValue(item, field.name))) {
                if (is.existy(oldValue)) {
                    return oldValue += objectService.deepValue(item, field.name);
                } else {
                    return objectService.deepValue(item, field.name);
                }
            }
        };

        this.reportFields = function (reportId, modelId) {
            if (is.existy(modelId)) {
                return _reportsField[reportId][modelId];
            } else {
                return _reportsField[reportId];
            }
        };

        this.generateRandomFloat = function (min, max) {
            return Math.random() * (max - min) + min;
        };

        this.generateRandomInt = function (min, max) {
            return Math.floor(Math.random() * (max - min + 1) + min);
        };

        this.filter = function (tipoRelatorio, filterInfo) {

            var filter = {};
            filter.tipoRelatorio = tipoRelatorio;

            if (is.existy(filterInfo.yearTotalization) && is.existy(filterInfo.monthTotalization)) {
                filter.referencia = filterInfo.yearTotalization.anoLetivo + filterInfo.monthTotalization.key;
            }

            if (is.existy(filterInfo.biMonthlyTotalization)) {
                filter.bimestre = parseInt(filterInfo.biMonthlyTotalization.key);
            }

            if (is.existy(filterInfo.anoEscolar)) {
                filter.nuAnoEscolar = filterInfo.anoEscolar;
            }

            if (is.existy(filterInfo.yearTotalizationFinal) && is.existy(filterInfo.monthTotalizationFinal)) {
                filter.referenciaFinal = filterInfo.yearTotalizationFinal.anoLetivo + filterInfo.monthTotalizationFinal.key;
            }

            if (undefined != filterInfo.grupoMunicipioIas && is.not.null(filterInfo.grupoMunicipioIas)) {
                filter.idGrupoIAS = filterInfo.grupoMunicipioIas.id;
            }

            if (is.existy(filterInfo.modeloRel)) {
                filter.tipoModelo = filterInfo.modeloRel
            }

            if (is.existy(filterInfo.program)) {
                if (filterInfo.program.id != 0) {
                    filter.idPrograma = filterInfo.program.id;
                }
            }

            if (is.existy(filterInfo.collect)) {
                if (filterInfo.collect.id != 0) {
                    filter.idColeta = filterInfo.collect.id;
                }
            }

            if (is.existy(filterInfo.schoolLocalization)) {
                if (filterInfo.schoolLocalization.id != 0) {
                    filter.idLocalizacao = filterInfo.schoolLocalization.id;
                }
            }

            if (is.existy(filterInfo.schoolType)) {
                if (filterInfo.schoolType.id != 0) {
                    filter.tipoOrganizacaoEscola = filterInfo.schoolType.id;
                }
            }

            if (is.existy(filterInfo.classType)) {
                if (filterInfo.classType.key != 0) {
                    filter.tipoAtendimento = filterInfo.classType.key;
                }
            }

            if (is.existy(filterInfo.classMultiserie)) {
                if (filterInfo.classMultiserie.key != 0) {
                    filter.multiserie = filterInfo.classMultiserie.key;
                }
            }

            if (is.existy(filterInfo.city)) {
                if (filterInfo.city.id != 0) {
                    filter.idMunicipio = filterInfo.city.id;
                }
            }

            if (is.existy(filterInfo.state)) {
                if (filterInfo.state.id != 0) {
                    filter.idUF = filterInfo.state.id;
                }
            }

            if (is.existy(filterInfo.school)) {
                if (filterInfo.school.id != 0) {
                    filter.idEscola = filterInfo.school.id;
                }
            }

            if (is.existy(filterInfo.schoolYear)) {
                if (filterInfo.schoolYear.id != 0) {
                    filter.idPeriodoLetivo = filterInfo.schoolYear.id;
                }
            }

            if (is.existy(filterInfo.classShift)) {
                if (filterInfo.classShift.key) {
                    filter.turno = filterInfo.classShift.key;
                }
            }

            if (is.existy(filterInfo.bimestre)) {
                if (filterInfo.bimestre) {
                    filter.bimestre = parseInt(filterInfo.bimestre);
                }
            }

            if (is.existy(filterInfo.bimestreInicio)) {
                if (filterInfo.bimestreInicio) {
                    filter.bimestreInicio = parseInt(filterInfo.bimestreInicio);
                }
            }

            if (is.existy(filterInfo.bimestreTermino)) {
                if (filterInfo.bimestreTermino) {
                    filter.bimestreTermino = parseInt(filterInfo.bimestreTermino);
                }
            }

            if (is.existy(filterInfo.shift)) {
                if (filterInfo.shift.key) {
                    filter.turno = filterInfo.shift.key;
                }
            }

            if (is.existy(filterInfo.stateRegional)) {
                if (filterInfo.stateRegional.id != 0) {
                    filter.idRegionalUF = filterInfo.stateRegional.id;
                }
            }

            if (is.existy(filterInfo.schoolAdministrativeDependency)) {
                if (filterInfo.schoolAdministrativeDependency.id != 0) {
                    filter.idDependenciaAdministrativa = filterInfo.schoolAdministrativeDependency.id;
                }
            }

            if (is.existy(filterInfo.cityRegional)) {
                if (filterInfo.cityRegional.id != 0) {
                    filter.idRegionalMunicipio = filterInfo.cityRegional.id;
                }
            }

            if (is.existy(filterInfo.monitoring)) {
                if (filterInfo.monitoring.id != 0) {
                    filter.acompanhamento = filterInfo.monitoring.id;
                }
            }

            if (is.existy(filterInfo.detailing)) {
                if (filterInfo.detailing.id != 0) {
                    filter.detalhamento = filterInfo.detailing.id;
                }
            }

            if (is.existy(filterInfo.model)) {
                if (filterInfo.model.id != 0) {
                    filter.modelo = filterInfo.model.id;
                }
            }

            if (is.existy(filterInfo.inep)) {
                filter.codigoINEP = filterInfo.inep;
            }

            if (is.existy(filterInfo.etapa)) {
                filter.idEtapa = filterInfo.etapa.id;
            }

            if (is.existy(filterInfo.schoolStatus)) {
                filter.situacaoEscola = filterInfo.schoolStatus.id;
            }

            if (is.existy(filterInfo.mesInicio)) {
                filter.mesInicio = filterInfo.mesInicio.id;
            }

            if (is.existy(filterInfo.mesFim)) {
                filter.mesFim = filterInfo.mesFim.id;
            }

            if (is.existy(filterInfo.monitoringTypeReport)) {
                filter.monitoramento = filterInfo.monitoringTypeReport.id;
            }

            if (is.existy(filterInfo.form)) {
                if (filterInfo.form.id != 0) {
                    filter.idFormulario = filterInfo.form.id;
                }
            }

            if (undefined != filterInfo.semestre && is.not.null(filterInfo.semestre)) {
                if (filterInfo.semestre.id != 0) {
                    filter.referencia = filterInfo.semestre.id;
                }
            }

            if (undefined != filterInfo.ano && is.not.null(filterInfo.ano)) {
                if (filterInfo.ano.id != 0) {
                    filter.ano = filterInfo.ano.id;
                }
            }

            if (undefined != filterInfo.professor && is.not.null(filterInfo.professor)) {
                if (filterInfo.professor.id != 0) {
                    filter.idProfessor = filterInfo.professor.id;
                }
            }

            return filter;
        };

        this.fieldsByProgramId = function (programId, fields) {
            var newFields = [];
            angular.forEach(fields, function (value) {
                if (is.existy(value.hideByPrograms)) {
                    if (is.not.inArray(programId, value.hideByPrograms)) {
                        newFields.push(value);
                    }
                } else {
                    newFields.push(value);
                }
            });

            return newFields;
        };

        this.fieldsByGroup = function (detalhamento, fields) {
            var newFields = [];
            angular.forEach(fields, function (value) {
                if (value.showOnGroup && is.existy(value.showOnGroup)) {
                    if (is.inArray(detalhamento, value.showOnGroup)) {
                        newFields.push(value);
                    }
                } else {
                    newFields.push(value);
                }
            });

            return newFields;
        };

        this.fieldsByModelo = function (modelo, fields) {
            var newFields = [];
            angular.forEach(fields, function (value) {
                if (value.showOnModelo && is.existy(value.showOnModelo)) {
                    if (is.inArray(modelo, value.showOnModelo)) {
                        newFields.push(value);
                    }
                } else {
                    newFields.push(value);
                }
            });

            return newFields;
        };

        this.generateReportResult = function (filterInfo, resultInfo) {
            resultInfo.reportDataCount = 0;
            resultInfo.reportData = [];

            if (is.inArray(resultInfo.report.id, [REPORT_GROUP.ACOMPANHAMENTO_MENSAL.ALFABETIZACAO_ACELERACAO])
                || is.inArray(resultInfo.report.id, [REPORT_GROUP.RESULTADO_FINAL.FORMULA_VITORIA])
                || is.inArray(resultInfo.report.id, [REPORT_GROUP.RESULTADO_FINAL.ENSINO_REGULAR_MATRICULA_MOVIMENTACAO])
                || is.inArray(resultInfo.report.id, [REPORT_GROUP.RESULTADO_FINAL.ALFABETIZACAO_2ANO_1SERIE_REGULAR])
                || is.inArray(resultInfo.report.id, [REPORT_GROUP.RESULTADO_FINAL.INDICADORES_SUCESSO_RESULTADOS])
                || is.inArray(resultInfo.report.id, [REPORT_GROUP.OUTROS.NUMERO_ALUNOS_POR_ANO_SITUACAO])
                || is.inArray(resultInfo.report.id, [REPORT_GROUP.OUTROS.STATUS_INSERCAO_COLETAS])) {
                delete filterInfo.option;
                delete filterInfo.model;
            }
            if (is.existy(filterInfo.option)) {
                resultInfo.reportFields = this.reportFields(resultInfo.report.id, filterInfo.option.id);
            } else if (is.existy(filterInfo.model)) {
                resultInfo.reportFields = this.reportFields(resultInfo.report.id, filterInfo.program.id + '.' + filterInfo.model.id);
            } else if (is.existy(filterInfo.typeMonitoring) && is.existy(filterInfo.typeMonitoringTypeReport)) {
                resultInfo.reportFields = this.reportFields(resultInfo.report.id, filterInfo.typeMonitoring.id + '.' + filterInfo.typeMonitoringTypeReport.id);
            } else {
                resultInfo.reportFields = this.reportFields(resultInfo.report.id);
            }

            resultInfo.reportTitle = resultInfo.report.title;
            if (is.not.existy(resultInfo.reportData)) {
                resultInfo.reportData = [];
            }

            if (is.inArray(resultInfo.report.id, [REPORT_GROUP.OUTROS.PERFIL_ATENDIMENTO_MUNICIPIO])) {
                var filter = this.filter(1, filterInfo);
                filter.detalhamento = filterInfo.detailing.id;
                resultInfo.detailing = filterInfo.detailing;
                resultInfo.filter = angular.copy(filterInfo);

                var result = Relatorios.findPerfilAtendimentoMunicipio(filter).$promise.then(function (data) {
                    resultInfo.reportDataCount = data.length;
                    resultInfo.reportData = data;
                });
            }
            if (is.inArray(resultInfo.report.id, [REPORT_GROUP.OUTROS.NUMERO_ALUNOS_POR_ANO_SITUACAO])) {
                var filter = this.filter(1, filterInfo);
                resultInfo.filter = angular.copy(filterInfo);

                var result = Relatorios.findNumeroAlunosPorAnoSituacao(filter).$promise.then(function (data) {
                    resultInfo.reportDataCount = data.length;
                    resultInfo.reportData = data;
                });
            }

            if (is.inArray(resultInfo.report.id, [REPORT_GROUP.OUTROS.STATUS_INSERCAO_COLETAS])) {
                var filter = this.filter(1, filterInfo);
                resultInfo.filter = angular.copy(filterInfo);

                var result = Relatorios.findStatusInsercaoColetas(filter).$promise.then(function (data) {
                    resultInfo.reportDataCount = data.length;
                    resultInfo.reportData = data;
                });
            }

            if (is.inArray(resultInfo.report.id, [REPORT_GROUP.RESULTADO_FINAL.ALFABETIZACAO_ACELERACAO])) {
                var filter = this.filter(1, filterInfo);
                filter.detalhamento = filterInfo.detailing.id;
                resultInfo.detailing = filterInfo.detailing;
                resultInfo.filter = angular.copy(filterInfo);

                var result = Relatorios.findResultadoAlfabetizacaoAceleracaoVitoria(filter).$promise.then(function (data) {
                    resultInfo.reportDataCount = data.length;
                    resultInfo.reportData = data;
                });
            }
            if (is.inArray(resultInfo.report.id, [REPORT_GROUP.RESULTADO_FINAL.FORMULA_VITORIA])) {
                var filter = this.filter(1, filterInfo);
                filter.detalhamento = filterInfo.detailing.id;
                resultInfo.detailing = filterInfo.detailing;
                resultInfo.filter = angular.copy(filterInfo);

                var result = Relatorios.findResultadoFinalFormulaVitoria(filter).$promise.then(function (data) {
                    resultInfo.reportDataCount = data.length;
                    resultInfo.reportData = data;
                });
            }

            //RONIFER

            if (is.inArray(resultInfo.report.id, [REPORT_GROUP.RESULTADO_FINAL.GESTAO_DA_ALFABETIZACAO])) {
                var filter = this.filter(1, filterInfo);
                filter.detalhamento = filterInfo.detailing.id;
                resultInfo.detailing = filterInfo.detailing;
                resultInfo.filter = angular.copy(filterInfo);
                let escopoGeral = this
                var result = Relatorios.findResultadoFinalGestaoAlfacao(filter).$promise.then(function (data) {
                    resultInfo.reportDataCount = data.length;
                    resultInfo.reportData = data;
                    //escopoGeral.filterResultadoFinalGestaoAlfacao(filter, data)
                    resultInfo.totalResultados = escopoGeral.calculoTotalRelatorio(data, filter, "FINAL_GESTAO_ALFABETIZACAO")
                });
            }



            if (is.inArray(resultInfo.report.id, [REPORT_GROUP.RESULTADO_FINAL.ENSINO_REGULAR])) {
                var filter = this.filter(1, filterInfo);
                filter.detalhamento = filterInfo.detailing.id;
                resultInfo.detailing = filterInfo.detailing;
                resultInfo.filter = angular.copy(filterInfo);

                var result = Relatorios.findResultadoFinalEnsinoRegular(filter).$promise.then(function (data) {
                    resultInfo.reportDataCount = data.length;
                    resultInfo.reportData = data;
                });
            }
            if (is.inArray(resultInfo.report.id, [REPORT_GROUP.RESULTADO_FINAL.ENSINO_REGULAR_MATRICULA_MOVIMENTACAO])) {
                var filter = this.filter(1, filterInfo);
                resultInfo.filter = angular.copy(filterInfo);

                var result = Relatorios.findResultadoFinalEnsinoRegularMatriculaMovimentacao(filter).$promise.then(function (data) {
                    resultInfo.reportDataCount = data.length;
                    resultInfo.reportData = data;
                });
            }
            if (is.inArray(resultInfo.report.id, [REPORT_GROUP.RESULTADO_FINAL.ALFABETIZACAO_2ANO_1SERIE_REGULAR])) {
                var filter = this.filter(1, filterInfo);
                resultInfo.filter = angular.copy(filterInfo);

                var result = Relatorios.findResultadoFinalAlfabetiza2Ano1SerieRegular(filter).$promise.then(function (data) {
                    resultInfo.reportDataCount = data.length;
                    resultInfo.reportData = data;
                });
            }
            if (is.inArray(resultInfo.report.id, [REPORT_GROUP.BASE_MONITORAMENTO.ACOMPANHAMENTO])) {
                var filter = this.filter(1, filterInfo);
                resultInfo.detailing = filterInfo.detailing;
                resultInfo.filter = angular.copy(filterInfo);
                var result = Relatorios.findMovimentacaoBase(filter).$promise.then(function (data) {
                    resultInfo.reportDataCount = data.length;
                    resultInfo.reportData = data;
                });
            }
            if (is.inArray(resultInfo.report.id, [REPORT_GROUP.RESULTADO_BIMESTRAL.ENSINO_REGULAR, REPORT_GROUP.RESULTADO_BIMESTRAL.FORMULA_VITORIA])) {
                var filter = this.filter(1, filterInfo);
                resultInfo.detailing = filterInfo.detailing;
                resultInfo.filter = angular.copy(filterInfo);

                var result = Relatorios.findResultadoBimestral(filter).$promise.then(function (data) {
                    resultInfo.reportDataCount = data.length;
                    resultInfo.reportData = data;
                });
            }
            if (is.inArray(resultInfo.report.id, [REPORT_GROUP.PERFIL_TURMA.ALFABETIZACAO_ACELERACAO])) {

                var objSchoolYear = {
                    'id': filterInfo.schoolYear.id
                };

                var objSchoolLocalization = filterInfo.schoolLocalization.id;
                var keyShift = null;
                if (is.existy(filterInfo.shift)) {
                    keyShift = filterInfo.shift.key;
                }

                resultInfo.detailing = filterInfo.detailing;
                var filter = angular.copy(filterInfo);

                filter.schoolYear = objSchoolYear;
                filter.schoolLocalization = objSchoolLocalization;
                if (keyShift == '0') {
                    keyShift = null;
                }
                filter.shift = keyShift;

                if (keyShift == '0') {
                    keyShift = null;
                }
                filter.shift = keyShift;

                filter.detailing = filterInfo.detailing.id;
                filter.schoolType = filterInfo.schoolType.id;

                var schoolAdministrativeDependency = filter.schoolAdministrativeDependency;
                filter.schoolAdministrativeDependency = { id: schoolAdministrativeDependency.id };

                if (is.existy(filter.school)) {
                    var school = filter.school;
                    filter.school = { id: school.id };
                }

                var result = Relatorios.findPerfilTurmaAlfabetizacaoAceleracao(filter).$promise
                    .then(function (data) {

                        resultInfo.reportDataCount = data.length;
                        for (var i = 0; i < data.length; i++) {

                            resultInfo.reportData.push({
                                report: { id: data[i].id },
                                detailing: { name: data[i].detalhamento },
                                averageSchoolDaysPriorProgram: { average: 'N/A' },
                                student: {
                                    enrolled: {
                                        ammount: data[i].alunosMatriculadosTotal,
                                        percent: 0, //nao sei
                                        male: { ammount: data[i].alunosMatriculadosMasculinoTotal, percent: data[i].alunosMatriculadosMasculinoPercentual },
                                        female: { ammount: data[i].alunosMatriculadosFemininoTotal, percent: data[i].alunosMatriculadosFemininoPercentual }
                                    },
                                    previeousYearSituation: {
                                        ammount: 0,//não sei
                                        percent: 0,//não sei
                                        approved: { ammount: data[i].situacaoAnoAnteriorAprovadoTotal, percent: data[i].situacaoAnoAnteriorAprovadoPercentual },
                                        disapproved: { ammount: data[i].situacaoAnoAnteriorReprovadoTotal, percent: data[i].situacaoAnoAnteriorReprovadoPercentual },
                                        absent: { ammount: data[i].situacaoAnoAnteriorNaoFrequentouTotal, percent: data[i].situacaoAnoAnteriorNaoFrequentouPercentual }
                                    },
                                    originSerie: {
                                        ammount: data[i].anoSerieOrigemTotal,
                                        percent: data[i].anoSerieOrigemPercentual,
                                        serie21: { ammount: data[i].anoSerieOrigem21, percent: 0 },
                                        serie32: { ammount: data[i].anoSerieOrigem32, percent: 0 },
                                        serie43: { ammount: data[i].anoSerieOrigem43, percent: 0 },
                                        serie54: { ammount: data[i].anoSerieOrigem54, percent: 0 },
                                        sl: filter.program.id == 1 ? '' : data[i].alunosSL
                                    },
                                    age: {
                                        ammount: data[i].idadeTotal,
                                        percent: data[i].idadePercentual,
                                        equals8: { ammount: data[i].idade8, percent: 0 },
                                        equals9: { ammount: data[i].idade9, percent: 0 },
                                        equals10: { ammount: data[i].idade10, percent: 0 },
                                        equals11: { ammount: data[i].idade11, percent: 0 },
                                        equals12: { ammount: data[i].idade12, percent: 0 },
                                        equals13: { ammount: data[i].idade13, percent: 0 },
                                        equals14: { ammount: data[i].idade14, percent: 0 },
                                        equals15: { ammount: data[i].idade15, percent: 0 }
                                    }
                                }
                            });
                        }
                    }, function (error) {
                    })
                    .finally(function () {
                    });
            }

            if (is.inArray(resultInfo.report.id, [REPORT_GROUP.PERFIL_TURMA.SISTEMATICA_ACOMPANHAMENTO_DIALOGOS])) {
                var filter = angular.copy(filterInfo);
                resultInfo.reportFields = this.fieldsByGroup(filterInfo.detailing.id, resultInfo.reportFields);
                // resultInfo.reportFields = this.fieldsByModelo(filterInfo.modeloRel, resultInfo.reportFields);

                var objSchoolYear = {
                    'id': filterInfo.schoolYear.id
                };
                filter.idPeriodoLetivo = objSchoolYear.id;

                var objSchoolLocalization = filterInfo.schoolLocalization.id;
                filter.idLocalizacao = objSchoolLocalization;

                var keyShift = null;
                if (is.existy(filterInfo.shift)) {
                    keyShift = filterInfo.shift.key;
                    if (keyShift == '0') {
                        keyShift = null;
                    }
                }
                filter.turno = keyShift;

                resultInfo.detailing = filterInfo.detailing;
                filter.detalhamento = filterInfo.detailing.id;

                filter.tipoOrganizacaoEscola = filterInfo.schoolType.id;
                if (is.existy(filterInfo.stateRegional)) {
                    if (filterInfo.stateRegional.id != 0) {
                        filter.idRegionalUF = filterInfo.stateRegional.id;
                    }
                }

                if (is.existy(filterInfo.cityRegional)) {
                    if (filterInfo.cityRegional.id != 0) {
                        filter.idRegionalMunicipio = filterInfo.cityRegional.id;
                    }
                }

                if (is.existy(filterInfo.city)) {
                    if (filterInfo.city.id != 0) {
                        filter.idMunicipio = filterInfo.city.id;
                    }
                }

                if (is.existy(filterInfo.state)) {
                    if (filterInfo.state.id != 0) {
                        filter.idUF = filterInfo.state.id;
                    }
                }

                var schoolAdministrativeDependency = filter.schoolAdministrativeDependency;
                filter.idDependenciaAdministrativa = schoolAdministrativeDependency.id;

                // if (is.existy(filterInfo.modeloRel)) {
                //     filter.tipoModelo = filterInfo.modeloRel
                // }
                // if (is.existy(filterInfo.grupoMunicipioIas)) {
                //     filter.idGrupoIAS = filterInfo.grupoMunicipioIas.id
                // }

                if (is.existy(filter.school)) {
                    var school = filter.school;
                    filter.idEscola = school.id;
                }

                filter.idPrograma = filterInfo.program.id

                // if (is.existy(filterInfo.anoEscolar)) {
                //     filter.anoEscolar = filterInfo.anoEscolar;
                // }

                if (resultInfo.detailing.id == 'T') {
                    resultInfo.atributesSize = 6;
                } else if (resultInfo.detailing.id == 'E') {
                    resultInfo.atributesSize = 5;
                } else if (resultInfo.detailing.id == 'R') {
                    resultInfo.atributesSize = 3;
                } else if (resultInfo.detailing.id == 'M') {
                    resultInfo.atributesSize = 3;
                } else if (resultInfo.detailing.id == 'U') {
                    resultInfo.atributesSize = 2;
                } else if (resultInfo.detailing.id == 'G') {
                    resultInfo.atributesSize = 2;
                } else if (resultInfo.detailing.id == '8') {
                    resultInfo.atributesSize = 3;
                }

                var selfMethod = this;
                var result = Relatorios.findPerfilTurmaSistematicaAcompanhamentoDialogos(filter).$promise
                    .then(function (data) {
                        /*
                        data = [
                            {
                                "id": 1,
                                "sgUF": "Ceará",
                                "numeroDeTurmas": 100,
                                "nomeMunicipio": "Ceará",
                                "noEscola": "Escola 1",
                                "noTurma": "Turma 1",
                                "anoEscolar": 1,
                                "alunosMatriculadosTotal": 6181,
                                "frequenciaPrevistaBimestre1": 0.25,
                                "frequenciaRealizadaBimestre1": 0.0,
                                "percentualFrequenciaBimestre1": 0.8,
                                "aulasPrevistasBimestre1": 20,
                                "aulasRealizadasBimestre1": 20,
                                "percentualAulasBimestre1": 0.8,
                                "percentualAdesaoEstudantesAutoAvaliacaoBimestre1": 0.75,
                                "percentualAdesaoEstudantesAvaliacaoProfessorBimestre1": 0.6,
                                "percentualAdesaoEducadoresBimestre1": 0.9,
                                "percentualEstudantesAbaixoMediaPortuguesBimestre1": 0.2,
                                "percentualEstudantesAbaixoMediaMatematicaBimestre1": 0.8,
                                "frequenciaPrevistaBimestre2": 0.50,
                                "frequenciaRealizadaBimestre2": 0.8,
                                "percentualFrequenciaBimestre2": 0.8,
                                "aulasPrevistasBimestre2": 40,
                                "aulasRealizadasBimestre2": 20,
                                "percentualAulasBimestre2": 0.8,
                                "percentualAdesaoEstudantesAutoAvaliacaoBimestre2": 0.75,
                                "percentualAdesaoEstudantesAvaliacaoProfessorBimestre2": 0.6,
                                "percentualAdesaoEducadoresBimestre2": 0.9,
                                "percentualEstudantesAbaixoMediaPortuguesBimestre2": 0.2,
                                "percentualEstudantesAbaixoMediaMatematicaBimestre2": 0.6,
                                "frequenciaPrevistaBimestre3": 0.75,
                                "frequenciaRealizadaBimestre3": 0.8,
                                "percentualFrequenciaBimestre3": 0.8,
                                "aulasPrevistasBimestre3": 60,
                                "aulasRealizadasBimestre3": 20,
                                "percentualAulasBimestre3": 0.8,
                                "percentualAdesaoEstudantesAutoAvaliacaoBimestre3": 0.75,
                                "percentualAdesaoEstudantesAvaliacaoProfessorBimestre3": 0.6,
                                "percentualAdesaoEducadoresBimestre3": 0.9,
                                "percentualEstudantesAbaixoMediaPortuguesBimestre3": 0.2,
                                "percentualEstudantesAbaixoMediaMatematicaBimestre3": 0.4,
                                "frequenciaPrevistaBimestre4": 0.90,
                                "frequenciaRealizadaBimestre4": 0.8,
                                "percentualFrequenciaBimestre4": 0.8,
                                "aulasPrevistasBimestre4": 80,
                                "aulasRealizadasBimestre4": 20,
                                "percentualAulasBimestre4": 0.8,
                                "percentualAdesaoEstudantesAutoAvaliacaoBimestre4": 0.75,
                                "percentualAdesaoEstudantesAvaliacaoProfessorBimestre4": 0.6,
                                "percentualAdesaoEducadoresBimestre4": 0.9,
                                "percentualEstudantesAbaixoMediaPortuguesBimestre4": 0.2,
                                "percentualEstudantesAbaixoMediaMatematicaBimestre4": 0.2,
                                "frequenciaPrevistaMensais12": 0.11,
                                "frequenciaRealizadaMensais12": 0.22,
                                "percentualFrequenciaMensais12": 0.33,
                                "aulasPrevistasMensais12": 44,
                                "aulasRealizadasMensais12": 55,
                                "percentualAulasMensais12": 0.66,
                                "frequenciaPrevistaMensais1": 0.10,
                                "frequenciaRealizadaMensais1": 0.20,
                                "percentualFrequenciaMensais1": 0.8,
                                "aulasPrevistasMensais1": 25,
                                "aulasRealizadasMensais1": 20,
                                "percentualAulasMensais1": 0.8,
                                "frequenciaPrevistaMensais2": 0.10,
                                "frequenciaRealizadaMensais2": 0.20,
                                "percentualFrequenciaMensais2": 0.8,
                                "aulasPrevistasMensais2": 25,
                                "aulasRealizadasMensais2": 20,
                                "percentualAulasMensais2": 0.8,
                                "frequenciaPrevistaMensais3": 0.10,
                                "frequenciaRealizadaMensais3": 0.20,
                                "percentualFrequenciaMensais3": 0.8,
                                "aulasPrevistasMensais3": 25,
                                "aulasRealizadasMensais3": 20,
                                "percentualAulasMensais3": 0.8,
                                "frequenciaPrevistaMensais4": 0.75,
                                "frequenciaRealizadaMensais4": 0.8,
                                "percentualFrequenciaMensais4": 0.8,
                                "aulasPrevistasMensais4": 25,
                                "aulasRealizadasMensais4": 20,
                                "percentualAulasMensais4": 0.8,
                                "frequenciaPrevistaMensais5": 0.75,
                                "frequenciaRealizadaMensais5": 0.8,
                                "percentualFrequenciaMensais5": 0.8,
                                "aulasPrevistasMensais5": 25,
                                "aulasRealizadasMensais5": 20,
                                "percentualAulasMensais5": 0.8,
                                "frequenciaPrevistaMensais6": 0.75,
                                "frequenciaRealizadaMensais6": 0.8,
                                "percentualFrequenciaMensais6": 0.8,
                                "aulasPrevistasMensais6": 25,
                                "aulasRealizadasMensais6": 20,
                                "percentualAulasMensais6": 0.8,
                                "frequenciaPrevistaMensais7": 0.75,
                                "frequenciaRealizadaMensais7": 0.8,
                                "percentualFrequenciaMensais7": 0.8,
                                "aulasPrevistasMensais7": 25,
                                "aulasRealizadasMensais7": 20,
                                "percentualAulasMensais7": 0.8,
                                "frequenciaPrevistaMensais8": 0.75,
                                "frequenciaRealizadaMensais8": 0.8,
                                "percentualFrequenciaMensais8": 0.8,
                                "aulasPrevistasMensais8": 25,
                                "aulasRealizadasMensais8": 20,
                                "percentualAulasMensais8": 0.8,
                                "frequenciaPrevistaMensais9": 0.75,
                                "frequenciaRealizadaMensais9": 0.8,
                                "percentualFrequenciaMensais9": 0.8,
                                "aulasPrevistasMensais9": 25,
                                "aulasRealizadasMensais9": 20,
                                "percentualAulasMensais9": 0.8,
                                "frequenciaPrevistaMensais10": 0.75,
                                "frequenciaRealizadaMensais10": 0.8,
                                "percentualFrequenciaMensais10": 0.8,
                                "aulasPrevistasMensais10": 25,
                                "aulasRealizadasMensais10": 20,
                                "percentualAulasMensais10": 0.8,
                                "frequenciaPrevistaMensais11": 0.75,
                                "frequenciaRealizadaMensais11": 0.8,
                                "percentualFrequenciaMensais11": 0.8,
                                "aulasPrevistasMensais11": 25,
                                "aulasRealizadasMensais11": 20,
                                "percentualAulasMensais11": 0.8,
                              },
                              {
                                "id": 2,
                                "sgUF": "Alagoas",
                                "numeroDeTurmas": 300,
                                "nomeMunicipio": "Alagoas",
                                "noEscola": "Escola 2",
                                "noTurma": "Turma 2",
                                "anoEscolar": 2,
                                "alunosMatriculadosTotal": 6181,
                                "frequenciaPrevistaBimestre1": 0.75,
                                "frequenciaRealizadaBimestre1": 0.8,
                                "percentualFrequenciaBimestre1": 0.8,
                                "aulasPrevistasBimestre1": 25,
                                "aulasRealizadasBimestre1": 20,
                                "percentualAulasBimestre1": 0.8,
                                "percentualAdesaoEstudantesAutoAvaliacaoBimestre1": 0.75,
                                "percentualAdesaoEstudantesAvaliacaoProfessorBimestre1": 0.6,
                                "percentualAdesaoEducadoresBimestre1": 0.9,
                                "percentualEstudantesAbaixoMediaPortuguesBimestre1": 0.2,
                                "percentualEstudantesAbaixoMediaMatematicaBimestre1": 0.3,
                                "frequenciaPrevistaBimestre2": 0.75,
                                "frequenciaRealizadaBimestre2": 0.8,
                                "percentualFrequenciaBimestre2": 0.8,
                                "aulasPrevistasBimestre2": 25,
                                "aulasRealizadasBimestre2": 20,
                                "percentualAulasBimestre2": 0.8,
                                "percentualAdesaoEstudantesAutoAvaliacaoBimestre2": 0.75,
                                "percentualAdesaoEstudantesAvaliacaoProfessorBimestre2": 0.6,
                                "percentualAdesaoEducadoresBimestre2": 0.9,
                                "percentualEstudantesAbaixoMediaPortuguesBimestre2": 0.2,
                                "percentualEstudantesAbaixoMediaMatematicaBimestre2": 0.3,
                                "frequenciaPrevistaBimestre3": 0.75,
                                "frequenciaRealizadaBimestre3": 0.8,
                                "percentualFrequenciaBimestre3": 0.8,
                                "aulasPrevistasBimestre3": 25,
                                "aulasRealizadasBimestre3": 20,
                                "percentualAulasBimestre3": 0.8,
                                "percentualAdesaoEstudantesAutoAvaliacaoBimestre3": 0.75,
                                "percentualAdesaoEstudantesAvaliacaoProfessorBimestre3": 0.6,
                                "percentualAdesaoEducadoresBimestre3": 0.9,
                                "percentualEstudantesAbaixoMediaPortuguesBimestre3": 0.2,
                                "percentualEstudantesAbaixoMediaMatematicaBimestre3": 0.3,
                                "frequenciaPrevistaBimestre4": 0.75,
                                "frequenciaRealizadaBimestre4": 0.8,
                                "percentualFrequenciaBimestre4": 0.8,
                                "aulasPrevistasBimestre4": 25,
                                "aulasRealizadasBimestre4": 20,
                                "percentualAulasBimestre4": 0.8,
                                "percentualAdesaoEstudantesAutoAvaliacaoBimestre4": 0.75,
                                "percentualAdesaoEstudantesAvaliacaoProfessorBimestre4": 0.6,
                                "percentualAdesaoEducadoresBimestre4": 0.9,
                                "percentualEstudantesAbaixoMediaPortuguesBimestre4": 0.2,
                                "percentualEstudantesAbaixoMediaMatematicaBimestre4": 0.3,
                                "frequenciaPrevistaMensais1": 0.10,
                                "frequenciaRealizadaMensais1": 0.20,
                                "percentualFrequenciaMensais1": 0.8,
                                "aulasPrevistasMensais1": 25,
                                "aulasRealizadasMensais1": 20,
                                "percentualAulasMensais1": 0.8,
                                "frequenciaPrevistaMensais2": 0.10,
                                "frequenciaRealizadaMensais2": 0.20,
                                "percentualFrequenciaMensais2": 0.8,
                                "aulasPrevistasMensais2": 25,
                                "aulasRealizadasMensais2": 20,
                                "percentualAulasMensais2": 0.8,
                                "frequenciaPrevistaMensais3": 0.10,
                                "frequenciaRealizadaMensais3": 0.20,
                                "percentualFrequenciaMensais3": 0.8,
                                "aulasPrevistasMensais3": 25,
                                "aulasRealizadasMensais3": 20,
                                "percentualAulasMensais3": 0.8,
                                "frequenciaPrevistaMensais4": 0.75,
                                "frequenciaRealizadaMensais4": 0.8,
                                "percentualFrequenciaMensais4": 0.8,
                                "aulasPrevistasMensais4": 25,
                                "aulasRealizadasMensais4": 20,
                                "percentualAulasMensais4": 0.8,
                                "frequenciaPrevistaMensais5": 0.75,
                                "frequenciaRealizadaMensais5": 0.8,
                                "percentualFrequenciaMensais5": 0.8,
                                "aulasPrevistasMensais5": 25,
                                "aulasRealizadasMensais5": 20,
                                "percentualAulasMensais5": 0.8,
                                "frequenciaPrevistaMensais6": 0.75,
                                "frequenciaRealizadaMensais6": 0.8,
                                "percentualFrequenciaMensais6": 0.8,
                                "aulasPrevistasMensais6": 25,
                                "aulasRealizadasMensais6": 20,
                                "percentualAulasMensais6": 0.8,
                                "frequenciaPrevistaMensais7": 0.75,
                                "frequenciaRealizadaMensais7": 0.8,
                                "percentualFrequenciaMensais7": 0.8,
                                "aulasPrevistasMensais7": 25,
                                "aulasRealizadasMensais7": 20,
                                "percentualAulasMensais7": 0.8,
                                "frequenciaPrevistaMensais8": 0.75,
                                "frequenciaRealizadaMensais8": 0.8,
                                "percentualFrequenciaMensais8": 0.8,
                                "aulasPrevistasMensais8": 25,
                                "aulasRealizadasMensais8": 20,
                                "percentualAulasMensais8": 0.8,
                                "frequenciaPrevistaMensais9": 0.75,
                                "frequenciaRealizadaMensais9": 0.8,
                                "percentualFrequenciaMensais9": 0.8,
                                "aulasPrevistasMensais9": 25,
                                "aulasRealizadasMensais9": 20,
                                "percentualAulasMensais9": 0.8,
                                "frequenciaPrevistaMensais10": 0.75,
                                "frequenciaRealizadaMensais10": 0.8,
                                "percentualFrequenciaMensais10": 0.8,
                                "aulasPrevistasMensais10": 25,
                                "aulasRealizadasMensais10": 20,
                                "percentualAulasMensais10": 0.8,
                                "frequenciaPrevistaMensais11": 0.75,
                                "frequenciaRealizadaMensais11": 0.8,
                                "percentualFrequenciaMensais11": 0.8,
                                "aulasPrevistasMensais11": 25,
                                "aulasRealizadasMensais11": 20,
                                "percentualAulasMensais11": 0.8,
                                "frequenciaPrevistaMensais12": 0.75,
                                "frequenciaRealizadaMensais12": 0.8,
                                "percentualFrequenciaMensais12": 0.8,
                                "aulasPrevistasMensais12": 25,
                                "aulasRealizadasMensais12": 20,
                                "percentualAulasMensais12": 0.8,
                              },
                              {
                                "id": 3,
                                "sgUF": "Bahia",
                                "numeroDeTurmas": 400,
                                "nomeMunicipio": "Bahia",
                                "noEscola": "Escola 3",
                                "noTurma": "Turma 3",
                                "anoEscolar": 3,
                                "alunosMatriculadosTotal": 6181,
                                "frequenciaPrevistaBimestre1": 0.75,
                                "frequenciaRealizadaBimestre1": 0.8,
                                "percentualFrequenciaBimestre1": 0.8,
                                "aulasPrevistasBimestre1": 25,
                                "aulasRealizadasBimestre1": 20,
                                "percentualAulasBimestre1": 0.8,
                                "percentualAdesaoEstudantesAutoAvaliacaoBimestre1": 0.75,
                                "percentualAdesaoEstudantesAvaliacaoProfessorBimestre1": 0.6,
                                "percentualAdesaoEducadoresBimestre1": 0.9,
                                "percentualEstudantesAbaixoMediaPortuguesBimestre1": 0.2,
                                "percentualEstudantesAbaixoMediaMatematicaBimestre1": 0.3,
                                "frequenciaPrevistaBimestre2": 0.75,
                                "frequenciaRealizadaBimestre2": 0.8,
                                "percentualFrequenciaBimestre2": 0.8,
                                "aulasPrevistasBimestre2": 25,
                                "aulasRealizadasBimestre2": 20,
                                "percentualAulasBimestre2": 0.8,
                                "percentualAdesaoEstudantesAutoAvaliacaoBimestre2": 0.75,
                                "percentualAdesaoEstudantesAvaliacaoProfessorBimestre2": 0.6,
                                "percentualAdesaoEducadoresBimestre2": 0.9,
                                "percentualEstudantesAbaixoMediaPortuguesBimestre2": 0.2,
                                "percentualEstudantesAbaixoMediaMatematicaBimestre2": 0.3,
                                "frequenciaPrevistaBimestre3": 0.75,
                                "frequenciaRealizadaBimestre3": 0.8,
                                "percentualFrequenciaBimestre3": 0.8,
                                "aulasPrevistasBimestre3": 25,
                                "aulasRealizadasBimestre3": 20,
                                "percentualAulasBimestre3": 0.8,
                                "percentualAdesaoEstudantesAutoAvaliacaoBimestre3": 0.75,
                                "percentualAdesaoEstudantesAvaliacaoProfessorBimestre3": 0.6,
                                "percentualAdesaoEducadoresBimestre3": 0.9,
                                "percentualEstudantesAbaixoMediaPortuguesBimestre3": 0.2,
                                "percentualEstudantesAbaixoMediaMatematicaBimestre3": 0.3,
                                "frequenciaPrevistaBimestre4": 0.75,
                                "frequenciaRealizadaBimestre4": 0.8,
                                "percentualFrequenciaBimestre4": 0.8,
                                "aulasPrevistasBimestre4": 25,
                                "aulasRealizadasBimestre4": 20,
                                "percentualAulasBimestre4": 0.8,
                                "percentualAdesaoEstudantesAutoAvaliacaoBimestre4": 0.75,
                                "percentualAdesaoEstudantesAvaliacaoProfessorBimestre4": 0.6,
                                "percentualAdesaoEducadoresBimestre4": 0.9,
                                "percentualEstudantesAbaixoMediaPortuguesBimestre4": 0.2,
                                "percentualEstudantesAbaixoMediaMatematicaBimestre4": 0.3,
                                "frequenciaPrevistaMensais1": 0.10,
                                "frequenciaRealizadaMensais1": 0.20,
                                "percentualFrequenciaMensais1": 0.8,
                                "aulasPrevistasMensais1": 25,
                                "aulasRealizadasMensais1": 20,
                                "percentualAulasMensais1": 0.8,
                                "frequenciaPrevistaMensais2": 0.10,
                                "frequenciaRealizadaMensais2": 0.20,
                                "percentualFrequenciaMensais2": 0.8,
                                "aulasPrevistasMensais2": 25,
                                "aulasRealizadasMensais2": 20,
                                "percentualAulasMensais2": 0.8,
                                "frequenciaPrevistaMensais3": 0.10,
                                "frequenciaRealizadaMensais3": 0.20,
                                "percentualFrequenciaMensais3": 0.8,
                                "aulasPrevistasMensais3": 25,
                                "aulasRealizadasMensais3": 20,
                                "percentualAulasMensais3": 0.8,
                                "frequenciaPrevistaMensais4": 0.75,
                                "frequenciaRealizadaMensais4": 0.8,
                                "percentualFrequenciaMensais4": 0.8,
                                "aulasPrevistasMensais4": 25,
                                "aulasRealizadasMensais4": 20,
                                "percentualAulasMensais4": 0.8,
                                "frequenciaPrevistaMensais5": 0.75,
                                "frequenciaRealizadaMensais5": 0.8,
                                "percentualFrequenciaMensais5": 0.8,
                                "aulasPrevistasMensais5": 25,
                                "aulasRealizadasMensais5": 20,
                                "percentualAulasMensais5": 0.8,
                                "frequenciaPrevistaMensais6": 0.75,
                                "frequenciaRealizadaMensais6": 0.8,
                                "percentualFrequenciaMensais6": 0.8,
                                "aulasPrevistasMensais6": 25,
                                "aulasRealizadasMensais6": 20,
                                "percentualAulasMensais6": 0.8,
                                "frequenciaPrevistaMensais7": 0.75,
                                "frequenciaRealizadaMensais7": 0.8,
                                "percentualFrequenciaMensais7": 0.8,
                                "aulasPrevistasMensais7": 25,
                                "aulasRealizadasMensais7": 20,
                                "percentualAulasMensais7": 0.8,
                                "frequenciaPrevistaMensais8": 0.75,
                                "frequenciaRealizadaMensais8": 0.8,
                                "percentualFrequenciaMensais8": 0.8,
                                "aulasPrevistasMensais8": 25,
                                "aulasRealizadasMensais8": 20,
                                "percentualAulasMensais8": 0.8,
                                "frequenciaPrevistaMensais9": 0.75,
                                "frequenciaRealizadaMensais9": 0.8,
                                "percentualFrequenciaMensais9": 0.8,
                                "aulasPrevistasMensais9": 25,
                                "aulasRealizadasMensais9": 20,
                                "percentualAulasMensais9": 0.8,
                                "frequenciaPrevistaMensais10": 0.75,
                                "frequenciaRealizadaMensais10": 0.8,
                                "percentualFrequenciaMensais10": 0.8,
                                "aulasPrevistasMensais10": 25,
                                "aulasRealizadasMensais10": 20,
                                "percentualAulasMensais10": 0.8,
                                "frequenciaPrevistaMensais11": 0.75,
                                "frequenciaRealizadaMensais11": 0.8,
                                "percentualFrequenciaMensais11": 0.8,
                                "aulasPrevistasMensais11": 25,
                                "aulasRealizadasMensais11": 20,
                                "percentualAulasMensais11": 0.8,
                                "frequenciaPrevistaMensais12": 0.75,
                                "frequenciaRealizadaMensais12": 0.8,
                                "percentualFrequenciaMensais12": 0.8,
                                "aulasPrevistasMensais12": 25,
                                "aulasRealizadasMensais12": 20,
                                "percentualAulasMensais12": 0.8,
                              },
                              {
                                "id": 4,
                                "sgUF": "Sao Paulo",
                                "numeroDeTurmas": 500,
                                "nomeMunicipio": "Sao Paulo",
                                "noEscola": "Escola 4",
                                "noTurma": "Turma 4",
                                "anoEscolar": 4,
                                "alunosMatriculadosTotal": 6181,
                                "frequenciaPrevistaBimestre1": 0.75,
                                "frequenciaRealizadaBimestre1": 0.8,
                                "percentualFrequenciaBimestre1": 0.8,
                                "aulasPrevistasBimestre1": 25,
                                "aulasRealizadasBimestre1": 20,
                                "percentualAulasBimestre1": 0.8,
                                "percentualAdesaoEstudantesAutoAvaliacaoBimestre1": 0.75,
                                "percentualAdesaoEstudantesAvaliacaoProfessorBimestre1": 0.6,
                                "percentualAdesaoEducadoresBimestre1": 0.9,
                                "percentualEstudantesAbaixoMediaPortuguesBimestre1": 0.2,
                                "percentualEstudantesAbaixoMediaMatematicaBimestre1": 0.3,
                                "frequenciaPrevistaBimestre2": 0.75,
                                "frequenciaRealizadaBimestre2": 0.8,
                                "percentualFrequenciaBimestre2": 0.8,
                                "aulasPrevistasBimestre2": 25,
                                "aulasRealizadasBimestre2": 20,
                                "percentualAulasBimestre2": 0.8,
                                "percentualAdesaoEstudantesAutoAvaliacaoBimestre2": 0.75,
                                "percentualAdesaoEstudantesAvaliacaoProfessorBimestre2": 0.6,
                                "percentualAdesaoEducadoresBimestre2": 0.9,
                                "percentualEstudantesAbaixoMediaPortuguesBimestre2": 0.2,
                                "percentualEstudantesAbaixoMediaMatematicaBimestre2": 0.3,
                                "frequenciaPrevistaBimestre3": 0.75,
                                "frequenciaRealizadaBimestre3": 0.8,
                                "percentualFrequenciaBimestre3": 0.8,
                                "aulasPrevistasBimestre3": 25,
                                "aulasRealizadasBimestre3": 20,
                                "percentualAulasBimestre3": 0.8,
                                "percentualAdesaoEstudantesAutoAvaliacaoBimestre3": 0.75,
                                "percentualAdesaoEstudantesAvaliacaoProfessorBimestre3": 0.6,
                                "percentualAdesaoEducadoresBimestre3": 0.9,
                                "percentualEstudantesAbaixoMediaPortuguesBimestre3": 0.2,
                                "percentualEstudantesAbaixoMediaMatematicaBimestre3": 0.3,
                                "frequenciaPrevistaBimestre4": 0.75,
                                "frequenciaRealizadaBimestre4": 0.8,
                                "percentualFrequenciaBimestre4": 0.8,
                                "aulasPrevistasBimestre4": 25,
                                "aulasRealizadasBimestre4": 20,
                                "percentualAulasBimestre4": 0.8,
                                "percentualAdesaoEstudantesAutoAvaliacaoBimestre4": 0.75,
                                "percentualAdesaoEstudantesAvaliacaoProfessorBimestre4": 0.6,
                                "percentualAdesaoEducadoresBimestre4": 0.9,
                                "percentualEstudantesAbaixoMediaPortuguesBimestre4": 0.2,
                                "percentualEstudantesAbaixoMediaMatematicaBimestre4": 0.3,
                                "frequenciaPrevistaMensais1": 0.10,
                                "frequenciaRealizadaMensais1": 0.20,
                                "percentualFrequenciaMensais1": 0.8,
                                "aulasPrevistasMensais1": 25,
                                "aulasRealizadasMensais1": 20,
                                "percentualAulasMensais1": 0.8,
                                "frequenciaPrevistaMensais2": 0.10,
                                "frequenciaRealizadaMensais2": 0.20,
                                "percentualFrequenciaMensais2": 0.8,
                                "aulasPrevistasMensais2": 25,
                                "aulasRealizadasMensais2": 20,
                                "percentualAulasMensais2": 0.8,
                                "frequenciaPrevistaMensais3": 0.10,
                                "frequenciaRealizadaMensais3": 0.20,
                                "percentualFrequenciaMensais3": 0.8,
                                "aulasPrevistasMensais3": 25,
                                "aulasRealizadasMensais3": 20,
                                "percentualAulasMensais3": 0.8,
                                "frequenciaPrevistaMensais4": 0.75,
                                "frequenciaRealizadaMensais4": 0.8,
                                "percentualFrequenciaMensais4": 0.8,
                                "aulasPrevistasMensais4": 25,
                                "aulasRealizadasMensais4": 20,
                                "percentualAulasMensais4": 0.8,
                                "frequenciaPrevistaMensais5": 0.75,
                                "frequenciaRealizadaMensais5": 0.8,
                                "percentualFrequenciaMensais5": 0.8,
                                "aulasPrevistasMensais5": 25,
                                "aulasRealizadasMensais5": 20,
                                "percentualAulasMensais5": 0.8,
                                "frequenciaPrevistaMensais6": 0.75,
                                "frequenciaRealizadaMensais6": 0.8,
                                "percentualFrequenciaMensais6": 0.8,
                                "aulasPrevistasMensais6": 25,
                                "aulasRealizadasMensais6": 20,
                                "percentualAulasMensais6": 0.8,
                                "frequenciaPrevistaMensais7": 0.75,
                                "frequenciaRealizadaMensais7": 0.8,
                                "percentualFrequenciaMensais7": 0.8,
                                "aulasPrevistasMensais7": 25,
                                "aulasRealizadasMensais7": 20,
                                "percentualAulasMensais7": 0.8,
                                "frequenciaPrevistaMensais8": 0.75,
                                "frequenciaRealizadaMensais8": 0.8,
                                "percentualFrequenciaMensais8": 0.8,
                                "aulasPrevistasMensais8": 25,
                                "aulasRealizadasMensais8": 20,
                                "percentualAulasMensais8": 0.8,
                                "frequenciaPrevistaMensais9": 0.75,
                                "frequenciaRealizadaMensais9": 0.8,
                                "percentualFrequenciaMensais9": 0.8,
                                "aulasPrevistasMensais9": 25,
                                "aulasRealizadasMensais9": 20,
                                "percentualAulasMensais9": 0.8,
                                "frequenciaPrevistaMensais10": 0.75,
                                "frequenciaRealizadaMensais10": 0.8,
                                "percentualFrequenciaMensais10": 0.8,
                                "aulasPrevistasMensais10": 25,
                                "aulasRealizadasMensais10": 20,
                                "percentualAulasMensais10": 0.8,
                                "frequenciaPrevistaMensais11": 0.75,
                                "frequenciaRealizadaMensais11": 0.8,
                                "percentualFrequenciaMensais11": 0.8,
                                "aulasPrevistasMensais11": 25,
                                "aulasRealizadasMensais11": 20,
                                "percentualAulasMensais11": 0.8,
                                "frequenciaPrevistaMensais12": 0.75,
                                "frequenciaRealizadaMensais12": 0.8,
                                "percentualFrequenciaMensais12": 0.8,
                                "aulasPrevistasMensais12": 25,
                                "aulasRealizadasMensais12": 20,
                                "percentualAulasMensais12": 0.8,
                              }
                        ];
                        */
                        resultInfo.reportDataCount = data.length;
                        for (var i = 0; i < data.length; i++) {
                            resultInfo.tipoRelatorio = filter.tipoRelatorio;
                            resultInfo.detalhamento = filter.detalhamento
                            resultInfo.reportData.push({
                                id: data[i].id,
                                uf: data[i].sgUF,
                                numeroDeTurmas: data[i].numeroDeTurmas,
                                numeroDeTurmasNoDialogos: data[i].numeroDeTurmasNoDialogos,
                                numeroDeProfessoresEad: data[i].numeroDeProfessoresEad,
                                numeroDeTurmasDialogosComRubricas: data[i].numeroDeTurmasDialogosComRubricas,
                                municipio: data[i].nomeMunicipio || "",
                                nomeDaEscola: data[i].noEscola || "",
                                turma: data[i].noTurma,
                                anoEscolar: data[i].anoEscolar,
                                alunosMatriculadosTotal: data[i].alunosMatriculadosTotal,
                                dadosMensais: data[i].dadosMensais,
                                dadosBimestrais: data[i].dadosBimestrais,
                                frequenciaPrevistaBimestre1: data[i].frequenciaPrevistaBimestre1,
                                frequenciaRealizadaBimestre1: data[i].frequenciaRealizadaBimestre1,
                                percentualFrequenciaBimestre1: (data[i].percentualFrequenciaBimestre1 * 100),
                                aulasPrevistasBimestre1: data[i].aulasPrevistasBimestre1,
                                aulasRealizadasBimestre1: data[i].aulasRealizadasBimestre1,
                                percentualAulasBimestre1: (data[i].percentualAulasBimestre1 * 100),
                                percentualAdesaoEstudantesAutoAvaliacaoBimestre1: (data[i].percentualAdesaoEstudantesAutoAvaliacaoBimestre1 * 100),
                                percentualAdesaoEstudantesAvaliacaoProfessorBimestre1: (data[i].percentualAdesaoEstudantesAvaliacaoProfessorBimestre1 * 100),
                                percentualAdesaoEducadoresBimestre1: (data[i].percentualAdesaoEducadoresBimestre1 * 100),
                                percentualEstudantesAbaixoMediaPortuguesBimestre1: (data[i].alunosAbaixoMediaPortuguesBimestre1 * 100 / data[i].alunosMediaTotal),
                                percentualEstudantesAbaixoMediaMatematicaBimestre1: (data[i].alunosAbaixoMediaMatematicaBimestre1 * 100 / data[i].alunosMediaTotal),
                                frequenciaPrevistaBimestre2: data[i].frequenciaPrevistaBimestre2,
                                frequenciaRealizadaBimestre2: data[i].frequenciaRealizadaBimestre2,
                                percentualFrequenciaBimestre2: (data[i].percentualFrequenciaBimestre2 * 100),
                                aulasPrevistasBimestre2: data[i].aulasPrevistasBimestre2,
                                aulasRealizadasBimestre2: data[i].aulasRealizadasBimestre2,
                                percentualAulasBimestre2: (data[i].percentualAulasBimestre2 * 100),
                                percentualAdesaoEstudantesAutoAvaliacaoBimestre2: (data[i].percentualAdesaoEstudantesAutoAvaliacaoBimestre2 * 100),
                                percentualAdesaoEstudantesAvaliacaoProfessorBimestre2: (data[i].percentualAdesaoEstudantesAvaliacaoProfessorBimestre2 * 100),
                                percentualAdesaoEducadoresBimestre2: (data[i].percentualAdesaoEducadoresBimestre2 * 100),
                                percentualEstudantesAbaixoMediaPortuguesBimestre2: (data[i].alunosAbaixoMediaPortuguesBimestre2 * 100 / data[i].alunosMediaTotal),
                                percentualEstudantesAbaixoMediaMatematicaBimestre2: (data[i].alunosAbaixoMediaMatematicaBimestre2 * 100 / data[i].alunosMediaTotal),
                                frequenciaPrevistaBimestre3: data[i].frequenciaPrevistaBimestre3,
                                frequenciaRealizadaBimestre3: data[i].frequenciaRealizadaBimestre3,
                                percentualFrequenciaBimestre3: (data[i].percentualFrequenciaBimestre3 * 100),
                                aulasPrevistasBimestre3: data[i].aulasPrevistasBimestre3,
                                aulasRealizadasBimestre3: data[i].aulasRealizadasBimestre3,
                                percentualAulasBimestre3: (data[i].percentualAulasBimestre3 * 100),
                                percentualAdesaoEstudantesAutoAvaliacaoBimestre3: (data[i].percentualAdesaoEstudantesAutoAvaliacaoBimestre3 * 100),
                                percentualAdesaoEstudantesAvaliacaoProfessorBimestre3: (data[i].percentualAdesaoEstudantesAvaliacaoProfessorBimestre3 * 100),
                                percentualAdesaoEducadoresBimestre3: (data[i].percentualAdesaoEducadoresBimestre3 * 100),
                                percentualEstudantesAbaixoMediaPortuguesBimestre3: (data[i].alunosAbaixoMediaPortuguesBimestre3 * 100 / data[i].alunosMediaTotal),
                                percentualEstudantesAbaixoMediaMatematicaBimestre3: (data[i].alunosAbaixoMediaMatematicaBimestre3 * 100 / data[i].alunosMediaTotal),
                                frequenciaPrevistaBimestre4: data[i].frequenciaPrevistaBimestre4,
                                frequenciaRealizadaBimestre4: data[i].frequenciaRealizadaBimestre4,
                                percentualFrequenciaBimestre4: (data[i].percentualFrequenciaBimestre4 * 100),
                                aulasPrevistasBimestre4: data[i].aulasPrevistasBimestre4,
                                aulasRealizadasBimestre4: data[i].aulasRealizadasBimestre4,
                                percentualAulasBimestre4: (data[i].percentualAulasBimestre4 * 100),
                                percentualAdesaoEstudantesAutoAvaliacaoBimestre4: (data[i].percentualAdesaoEstudantesAutoAvaliacaoBimestre4 * 100),
                                percentualAdesaoEstudantesAvaliacaoProfessorBimestre4: (data[i].percentualAdesaoEstudantesAvaliacaoProfessorBimestre4 * 100),
                                percentualAdesaoEducadoresBimestre4: (data[i].percentualAdesaoEducadoresBimestre4 * 100),
                                percentualEstudantesAbaixoMediaPortuguesBimestre4: (data[i].alunosAbaixoMediaPortuguesBimestre4 * 100 / data[i].alunosMediaTotal),
                                percentualEstudantesAbaixoMediaMatematicaBimestre4: (data[i].alunosAbaixoMediaMatematicaBimestre4 * 100 / data[i].alunosMediaTotal),

                                frequenciaPrevistaMensais1: data[i].frequenciaPrevistaMensais1,
                                frequenciaRealizadaMensais1: data[i].frequenciaRealizadaMensais1,
                                percentualFrequenciaMensais1: (data[i].percentualFrequenciaMensais1 * 100),
                                aulasPrevistasMensais1: data[i].aulasPrevistasMensais1,
                                aulasRealizadasMensais1: data[i].aulasRealizadasMensais1,
                                percentualAulasMensais1: (data[i].percentualAulasMensais1 * 100),


                                frequenciaPrevistaMensais2: data[i].frequenciaPrevistaMensais2,
                                frequenciaRealizadaMensais2: data[i].frequenciaRealizadaMensais2,
                                percentualFrequenciaMensais2: (data[i].percentualFrequenciaMensais2 * 100),
                                aulasPrevistasMensais2: data[i].aulasPrevistasMensais2,
                                aulasRealizadasMensais2: data[i].aulasRealizadasMensais2,
                                percentualAulasMensais2: (data[i].percentualAulasMensais2 * 100),


                                frequenciaPrevistaMensais3: data[i].frequenciaPrevistaMensais3,
                                frequenciaRealizadaMensais3: data[i].frequenciaRealizadaMensais3,
                                percentualFrequenciaMensais3: (data[i].percentualFrequenciaMensais3 * 100),
                                aulasPrevistasMensais3: data[i].aulasPrevistasMensais3,
                                aulasRealizadasMensais3: data[i].aulasRealizadasMensais3,
                                percentualAulasMensais3: (data[i].percentualAulasMensais3 * 100),

                                frequenciaPrevistaMensais4: data[i].frequenciaPrevistaMensais4,
                                frequenciaRealizadaMensais4: data[i].frequenciaRealizadaMensais4,
                                percentualFrequenciaMensais4: (data[i].percentualFrequenciaMensais4 * 100),
                                aulasPrevistasMensais4: data[i].aulasPrevistasMensais4,
                                aulasRealizadasMensais4: data[i].aulasRealizadasMensais4,
                                percentualAulasMensais4: (data[i].percentualAulasMensais4 * 100),

                                frequenciaPrevistaMensais5: data[i].frequenciaPrevistaMensais5,
                                frequenciaRealizadaMensais5: data[i].frequenciaRealizadaMensais5,
                                percentualFrequenciaMensais5: (data[i].percentualFrequenciaMensais5 * 100),
                                aulasPrevistasMensais5: data[i].aulasPrevistasMensais5,
                                aulasRealizadasMensais5: data[i].aulasRealizadasMensais5,
                                percentualAulasMensais5: (data[i].percentualAulasMensais5 * 100),

                                frequenciaPrevistaMensais6: data[i].frequenciaPrevistaMensais6,
                                frequenciaRealizadaMensais6: data[i].frequenciaRealizadaMensais6,
                                percentualFrequenciaMensais6: (data[i].percentualFrequenciaMensais6 * 100),
                                aulasPrevistasMensais6: data[i].aulasPrevistasMensais6,
                                aulasRealizadasMensais6: data[i].aulasRealizadasMensais6,
                                percentualAulasMensais6: (data[i].percentualAulasMensais6 * 100),

                                frequenciaPrevistaMensais7: data[i].frequenciaPrevistaMensais7,
                                frequenciaRealizadaMensais7: data[i].frequenciaRealizadaMensais7,
                                percentualFrequenciaMensais7: (data[i].percentualFrequenciaMensais7 * 100),
                                aulasPrevistasMensais7: data[i].aulasPrevistasMensais7,
                                aulasRealizadasMensais7: data[i].aulasRealizadasMensais7,
                                percentualAulasMensais7: (data[i].percentualAulasMensais7 * 100),

                                frequenciaPrevistaMensais8: data[i].frequenciaPrevistaMensais8,
                                frequenciaRealizadaMensais8: data[i].frequenciaRealizadaMensais8,
                                percentualFrequenciaMensais8: (data[i].percentualFrequenciaMensais8 * 100),
                                aulasPrevistasMensais8: data[i].aulasPrevistasMensais8,
                                aulasRealizadasMensais8: data[i].aulasRealizadasMensais8,
                                percentualAulasMensais8: (data[i].percentualAulasMensais8 * 100),

                                frequenciaPrevistaMensais9: data[i].frequenciaPrevistaMensais9,
                                frequenciaRealizadaMensais9: data[i].frequenciaRealizadaMensais9,
                                percentualFrequenciaMensais9: (data[i].percentualFrequenciaMensais9 * 100),
                                aulasPrevistasMensais9: data[i].aulasPrevistasMensais9,
                                aulasRealizadasMensais9: data[i].aulasRealizadasMensais9,
                                percentualAulasMensais9: (data[i].percentualAulasMensais9 * 100),

                                frequenciaPrevistaMensais10: data[i].frequenciaPrevistaMensais10,
                                frequenciaRealizadaMensais10: data[i].frequenciaRealizadaMensais10,
                                percentualFrequenciaMensais10: (data[i].percentualFrequenciaMensais10 * 100),
                                aulasPrevistasMensais10: data[i].aulasPrevistasMensais10,
                                aulasRealizadasMensais10: data[i].aulasRealizadasMensais10,
                                percentualAulasMensais10: (data[i].percentualAulasMensais10 * 100),

                                frequenciaPrevistaMensais11: data[i].frequenciaPrevistaMensais11,
                                frequenciaRealizadaMensais11: data[i].frequenciaRealizadaMensais11,
                                percentualFrequenciaMensais11: (data[i].percentualFrequenciaMensais11 * 100),
                                aulasPrevistasMensais11: data[i].aulasPrevistasMensais11,
                                aulasRealizadasMensais11: data[i].aulasRealizadasMensais11,
                                percentualAulasMensais11: (data[i].percentualAulasMensais11 * 100),

                                frequenciaPrevistaMensais12: data[i].frequenciaPrevistaMensais12,
                                frequenciaRealizadaMensais12: data[i].frequenciaRealizadaMensais12,
                                percentualFrequenciaMensais12: (data[i].percentualFrequenciaMensais12 * 100),
                                aulasPrevistasMensais12: data[i].aulasPrevistasMensais12,
                                aulasRealizadasMensais12: data[i].aulasRealizadasMensais12,
                                percentualAulasMensais12: (data[i].percentualAulasMensais12 * 100),
                            });
                        }

                        resultInfo.somatoriaPorcentagem = selfMethod.calculoSomaPorcentagensPerfilTurma(resultInfo.reportData, filter);

                    }, function (error) {
                    })
                    .finally(function () {
                    });
            }

            if (is.inArray(resultInfo.report.id, [REPORT_GROUP.PERFIL_TURMA.GESTAO_DA_ALFABETIZACAO])) {

                resultInfo.reportFields = this.fieldsByGroup(filterInfo.detailing.id, resultInfo.reportFields);
                resultInfo.reportFields = this.fieldsByModelo(filterInfo.modeloRel, resultInfo.reportFields);

                var objSchoolYear = {
                    'id': filterInfo.schoolYear.id
                };
                var objSchoolLocalization = filterInfo.schoolLocalization.id;
                var keyShift = null;
                if (is.existy(filterInfo.shift)) {
                    keyShift = filterInfo.shift.key;
                }

                resultInfo.detailing = filterInfo.detailing;
                var filter = angular.copy(filterInfo);

                filter.idPeriodoLetivo = objSchoolYear.id;
                filter.idLocalizacao = objSchoolLocalization;
                if (keyShift == '0') {
                    keyShift = null;
                }
                filter.turno = keyShift;


                filter.detalhamento = filterInfo.detailing.id;
                filter.tipoOrganizacaoEscola = filterInfo.schoolType.id;
                if (is.existy(filterInfo.stateRegional)) {
                    if (filterInfo.stateRegional.id != 0) {
                        filter.idRegionalUF = filterInfo.stateRegional.id;
                    }
                }


                if (is.existy(filterInfo.cityRegional)) {
                    if (filterInfo.cityRegional.id != 0) {
                        filter.idRegionalMunicipio = filterInfo.cityRegional.id;
                    }
                }

                if (is.existy(filterInfo.city)) {
                    if (filterInfo.city.id != 0) {
                        filter.idMunicipio = filterInfo.city.id;
                    }
                }

                if (is.existy(filterInfo.state)) {
                    if (filterInfo.state.id != 0) {
                        filter.idUF = filterInfo.state.id;
                    }
                }

                var schoolAdministrativeDependency = filter.schoolAdministrativeDependency;
                filter.idDependenciaAdministrativa = schoolAdministrativeDependency.id;

                if (is.existy(filterInfo.modeloRel)) {
                    filter.tipoModelo = filterInfo.modeloRel
                }
                if (is.existy(filterInfo.grupoMunicipioIas)) {
                    filter.idGrupoIAS = filterInfo.grupoMunicipioIas.id
                }
                if (is.existy(filter.school)) {
                    var school = filter.school;
                    filter.idEscola = school.id;
                }

                filter.idPrograma = filterInfo.program.id

                if (is.existy(filterInfo.anoEscolar)) {
                    filter.anoEscolar = filterInfo.anoEscolar;
                }

                if (resultInfo.detailing.id == 'T') {
                    resultInfo.atributesSize = 6;
                } else if (resultInfo.detailing.id == 'E') {
                    resultInfo.atributesSize = 5;
                } else if (resultInfo.detailing.id == 'R') {
                    resultInfo.atributesSize = 4;
                } else if (resultInfo.detailing.id == 'M') {
                    resultInfo.atributesSize = 3;
                } else if (resultInfo.detailing.id == 'U') {
                    resultInfo.atributesSize = 2;
                } else if (resultInfo.detailing.id == 'G') {
                    resultInfo.atributesSize = 3;
                } else if (resultInfo.detailing.id == '8') {
                    resultInfo.atributesSize = 4;
                }
                var selfMethod = this;
                var result = Relatorios.findPerfilTurmaGestaoDaAlfabetizacao(filter).$promise
                    .then(function (data) {
                        resultInfo.reportDataCount = data.length;
                        for (var i = 0; i < data.length; i++) {
                            resultInfo.tipoRelatorio = filter.tipoRelatorio;
                            resultInfo.detalhamento = filter.detalhamento
                            resultInfo.reportData.push({
                                id: data[i].id,
                                detalhamento: data[i].detalhamento,
                                alunosSexoMasculino: data[i].alunosMatriculadosMasculinoTotal,
                                numeroDeTurmas: data[i].numeroDeTurmas,
                                alunosSexoMasculinoPercentual: data[i].alunosMatriculadosMasculinoPercentual,
                                alunosSexoFeminino: data[i].alunosMatriculadosFemininoTotal,
                                alunosSexoFemininoPercentual: data[i].alunosMatriculadosFemininoPercentual,
                                alunosMatriculadosTotal: data[i].alunosMatriculadosTotal,
                                situacaoAnoAnteriorAlunosAprovados: data[i].situacaoAnoAnteriorAprovadoTotal,
                                situacaoAnoAnteriorAlunosAprovadosPercentual: data[i].situacaoAnoAnteriorAprovadoPercentual,
                                situacaoAnoAnteriorAlunosReprovados: data[i].situacaoAnoAnteriorReprovadoTotal,
                                situacaoAnoAnteriorAlunosReprovadosPercentual: data[i].situacaoAnoAnteriorReprovadoPercentual,
                                situacaoAnoAnteriorAlunosNaoFreq: data[i].situacaoAnoAnteriorNaoFrequentouTotal,
                                situacaoAnoAnteriorAlunosNaoFreqPercentual: data[i].situacaoAnoAnteriorNaoFrequentouPercentual,

                                idade5: data[i].idade05,
                                idade6: data[i].idade06,
                                idade7: data[i].idade07,
                                idade8: data[i].idade08,
                                idade9: data[i].idade09,
                                idade10: data[i].idade10,
                                idade11: data[i].idade11,
                                idade12: data[i].idade12,
                                idade13: data[i].idade13,
                                idade14mais: data[i].idade14Mais,
                                idadeTotal: data[i].idadeTotal,
                                pcInsercao: data[i].idadePercentual,

                                anoEscolar: data[i].anoEscolar,

                                municipio: data[i].nomeMunicipio,
                                nomeDaEscola: data[i].noEscola,
                                turma: data[i].noTurma,
                                uf: data[i].sgUF,
                                regionalMunicipal: data[i].noRegional ? data[i].noRegional : "Todos",
                                grupoIAS: data[i].noGrupo ? data[i].noGrupo : "Todos",
                                noRegionalUF: data[i].noRegional ? data[i].noRegional : "Todos",


                                matriculaFinal: data[i].matriculaFinal,
                                vlNivelPtInicio1: data[i].vlNivelPtInicio1,
                                vlNivelPtInicio2: data[i].vlNivelPtInicio2,
                                vlNivelPtInicio3: data[i].vlNivelPtInicio3,
                                vlNivelPtInicio4: data[i].vlNivelPtInicio4,
                                vlNivelPtFinal1: data[i].vlNivelPtFinal1,
                                vlNivelPtFinal2: data[i].vlNivelPtFinal2,
                                vlNivelPtFinal3: data[i].vlNivelPtFinal3,
                                vlNivelPtFinal4: data[i].vlNivelPtFinal4,
                                vlNivelMtInicio1: data[i].vlNivelMtInicio1,
                                vlNivelMtInicio2: data[i].vlNivelMtInicio2,
                                vlNivelMtInicio3: data[i].vlNivelMtInicio3,
                                vlNivelMtInicio4: data[i].vlNivelMtInicio4,
                                vlNivelMtFinal1: data[i].vlNivelMtFinal1,
                                vlNivelMtFinal2: data[i].vlNivelMtFinal2,
                                vlNivelMtFinal3: data[i].vlNivelMtFinal3,
                                vlNivelMtFinal4: data[i].vlNivelMtFinal4,
                                vlAprovados: data[i].vlAprovados,
                                pcAprovados: data[i].vlAprovados / (data[i].vlAprovados + data[i].vlNaoAprovados) * 100,
                                vlNaoAprovados: data[i].vlNaoAprovados,
                                pcNaoAprovados: data[i].vlNaoAprovados / (data[i].vlAprovados + data[i].vlNaoAprovados) * 100

                            });
                        }
                        resultInfo.somatoriaPorcentagem = selfMethod.calculoSomaPorcentagensPerfilTurma(resultInfo.reportData, filter);
                    }, function (error) {
                    })
                    .finally(function () {
                    });
            }
            if (is.inArray(resultInfo.report.id, [REPORT_GROUP.PERFIL_TURMA.FORMULA_VITORIA])) {
                var filter = this.filter(1, filterInfo);
                if (is.existy(filterInfo.shift)) {
                    filter.turno = filterInfo.shift.key;
                }
                resultInfo.detailing = filterInfo.detailing;
                resultInfo.filter = angular.copy(filterInfo);

                var result = Relatorios.findPerfilTurmaFormulaVitoria(filter).$promise.then(function (data) {
                    resultInfo.reportDataCount = data.length;
                    for (var i = 0; i < data.length; i++) {

                        resultInfo.reportData.push({
                            report: { id: i + 1 },
                            detailing: { name: data[i].detalhamento },
                            student: {
                                enrolled: {
                                    ammount: data[i].alunosMatriculadosTotal,
                                    male: { ammount: data[i].alunosMatriculadosMasculinoTotal, percent: data[i].alunosMatriculadosMasculinoPercentual },
                                    female: { ammount: data[i].alunosMatriculadosFemininoTotal, percent: data[i].alunosMatriculadosFemininoPercentual }
                                },
                                previeousYearSituation: {
                                    approved: { ammount: data[i].situacaoAnoAnteriorAprovadoTotal, percent: data[i].situacaoAnoAnteriorAprovadoPercentual },
                                    disapproved: { ammount: data[i].situacaoAnoAnteriorReprovadoTotal, percent: data[i].situacaoAnoAnteriorReprovadoPercentual },
                                    absent: { ammount: data[i].situacaoAnoAnteriorNaoFrequentouTotal, percent: data[i].situacaoAnoAnteriorNaoFrequentouPercentual }
                                },
                                originSerie: {
                                    ammount: data[i].anoSerieOrigemTotal,
                                    percent: data[i].anoSerieOrigemPercentual,
                                    serie5: { ammount: data[i].anoSerieOrigem65 },
                                    serie6: { ammount: data[i].anoSerieOrigem76 },
                                    serie7: { ammount: data[i].anoSerieOrigem87 },
                                    serie8: { ammount: data[i].anoSerieOrigem98 }
                                },
                                age: {
                                    ammount: data[i].idadeTotal,
                                    percent: data[i].idadePercentual,
                                    equals10: { ammount: data[i].idade10 },
                                    equals11: { ammount: data[i].idade11 },
                                    equals12: { ammount: data[i].idade12 },
                                    equals13: { ammount: data[i].idade13 },
                                    equals14: { ammount: data[i].idade14 },
                                    equals15: { ammount: data[i].idade15 },
                                    since16: { ammount: data[i].idade16Mais }
                                }
                            }
                        });

                    }

                }, function (error) {
                })
                    .finally(function () {
                    });
            }
            if (is.inArray(resultInfo.report.id, [REPORT_GROUP.PERFIL_TURMA.ENSINO_MEDIO_GESTAO_NOTA_10])) {

                var filter = this.filter(1, filterInfo);
                resultInfo.detailing = filterInfo.detailing;
                resultInfo.filter = angular.copy(filterInfo);

                var result = Relatorios.findPerfilTurmaEnsinoMedioGestaoNota10(filter).$promise.then(function (data) {
                    resultInfo.reportDataCount = data.length;
                    for (var i = 0; i < data.length; i++) {

                        resultInfo.reportData.push({
                            report: { id: i + 1 },
                            detailing: { name: data[i].detalhamento },
                            student: {
                                enrolled: {
                                    ammount: data[i].alunosMatriculadosTotal,
                                    male: { ammount: data[i].alunosMatriculadosMasculinoTotal, percent: data[i].alunosMatriculadosMasculinoPercentual },
                                    female: { ammount: data[i].alunosMatriculadosFemininoTotal, percent: data[i].alunosMatriculadosFemininoPercentual }
                                },
                                previeousYearSituation: {
                                    approved: { ammount: data[i].situacaoAnoAnteriorAprovadoTotal, percent: data[i].situacaoAnoAnteriorAprovadoPercentual },
                                    disapproved: { ammount: data[i].situacaoAnoAnteriorReprovadoTotal, percent: data[i].situacaoAnoAnteriorReprovadoPercentual },
                                    absent: { ammount: data[i].situacaoAnoAnteriorNaoFrequentouTotal, percent: data[i].situacaoAnoAnteriorNaoFrequentouPercentual }
                                },
                                originSerie: {
                                    ammount: data[i].anoSerieOrigemTotal,
                                    percent: data[i].anoSerieOrigemPercentual,
                                    serie98: { ammount: data[i].anoSerieOrigem98 },
                                    serie1: { ammount: data[i].anoSerieOrigem1 },
                                    serie2: { ammount: data[i].anoSerieOrigem2 },
                                    serie3: { ammount: data[i].anoSerieOrigem3 }
                                },
                                age: {
                                    ammount: data[i].idadeTotal,
                                    percent: data[i].idadePercentual,
                                    equals14: { ammount: data[i].idade14 },
                                    equals15: { ammount: data[i].idade15 },
                                    equals16: { ammount: data[i].idade16 },
                                    equals17: { ammount: data[i].idade17 },
                                    equals18: { ammount: data[i].idade18 },
                                    equals19: { ammount: data[i].idade19 },
                                    since20: { ammount: data[i].idade20Mais }
                                }
                            }
                        });

                    }

                }, function (error) {
                })
                    .finally(function () {
                    });
            }
            if (is.inArray(resultInfo.report.id, [REPORT_GROUP.PERFIL_TURMA.ENSINO_REGULAR])) {
                var filter = this.filter(1, filterInfo);
                resultInfo.detailing = filterInfo.detailing;
                resultInfo.filter = angular.copy(filterInfo);
                resultInfo.reportFields = this.fieldsByProgramId(filterInfo.program.id, resultInfo.reportFields);

                var result = Relatorios.findPerfilTurmaEnsinoRegular(filter).$promise.then(function (data) {
                    resultInfo.reportDataCount = data.length;
                    resultInfo.reportData = data;
                });
            }
            if (is.inArray(resultInfo.report.id, [REPORT_GROUP.OUTROS.MUNICIPIOS_POR_PROGRAMA])) {
                var filter = this.filter(1, filterInfo);
                var campos =
                    ["idRegionalUF", "idUF", "idDependenciaAdministrativa", "idAnoLetivo", "detalhamento"];

                for (var i = 0; i < campos.length; i++) {
                    var chave = campos[i];
                    filter[chave] = filter[chave] || 0;
                }

                resultInfo.detailing = filterInfo.detailing;
                resultInfo.filter = angular.copy(filterInfo);

                var result = Relatorios.findMunicipiosPorPrograma(filter).$promise.then(function (data) {
                    var programas = {};
                    var localidades = {};
                    for (var i = 0; i < data.length; i++) {
                        var localidade = data[i].localidade;
                        var programa = data[i].programa;
                        var idMunicipio = data[i].idMunicipio;
                        var quantidadeMunicipios = Number(data[i].quantidadeMunicipios);

                        localidades[localidade] = localidades[localidade] || {
                            municipios: {},
                            nome: localidade,
                            programas: {},
                            totalMunicipios: 0
                        };
                        localidades[localidade].programas[programa] = programa;
                        programas[programa] = programas[programa] || {
                            municipios: {},
                            nome: programa,
                            totalMunicipios: 0
                        };

                        localidades[localidade].municipios[idMunicipio + ""] =
                            programas[programa].municipios[idMunicipio + ""] = true;
                    }
                    var todosOsProgramas = filterInfo.todosOsProgramas;
                    for (var i = 0; i < todosOsProgramas.length; i++) {
                        var programa = todosOsProgramas[i].nome;
                        programas[programa] = programas[programa] || {
                            nome: programa,
                            totalMunicipios: 0
                        }
                    }

                    var totalGeral = 0;
                    for (var localidade in localidades) {
                        for (var municipio in localidades[localidade].municipios) {
                            localidades[localidade].totalMunicipios++;
                        }
                        totalGeral += localidades[localidade].totalMunicipios;
                    }
                    var totalProgramas = 0;
                    for (var programa in programas) {
                        for (var municipio in programas[programa].municipios) {
                            programas[programa].totalMunicipios++;
                        }
                        totalProgramas++;
                    }

                    resultInfo.reportDataCount = 0;
                    for (var localidade in localidades) {
                        resultInfo.reportDataCount++;
                    }

                    resultInfo.reportData = {
                        detalhamento: filterInfo.detailing.name,
                        localidades: localidades,
                        programas: programas,
                        totalGeral: totalGeral,
                        totalProgramas: totalProgramas
                    };

                });
            }
            if (is.inArray(resultInfo.report.id, [REPORT_GROUP.OUTROS.LISTA_UNIDADES_ESCOLARES])) {
                var filter = this.filter(1, filterInfo);

                var campos =
                    ["idUF", "idRegionalUF", "idPeriodoLetivo", "idPrograma", "idMunicipio",
                        "idRegionalMunicipal", "tipoOrganizacaoEscola", "idLocalizacao",
                        "idDependenciaAdministrativa"];

                for (var i = 0; i < campos.length; i++) {
                    var chave = campos[i];
                    filter[chave] = filter[chave] || 0;
                }

                resultInfo.detailing = filterInfo.detailing;
                resultInfo.filter = angular.copy(filterInfo);

                var result = Relatorios.findUnidadesEscolares(filter).$promise.then(function (data) {
                    var escolas = {};
                    for (var i = 0; i < data.length; i++) {
                        var escola = data[i];
                        escolas[i + ""] = {
                            codigoINEP: escola.codigoINEP,
                            dependenciaAdministrativa: escola.dependenciaAdministrativa,
                            nome: escola.nome
                        }
                    }

                    resultInfo.reportDataCount = 0;
                    for (var escola in escolas) {
                        resultInfo.reportDataCount++;
                    }
                    if (resultInfo.reportDataCount) {
                        resultInfo.reportData = {
                            escolas: escolas
                        };
                    }
                });
            }
            if (is.inArray(resultInfo.report.id, [REPORT_GROUP.OUTROS.MEDIA_DIAS_LETIVOS])) {
                var filter = this.filter(4, filterInfo);
                resultInfo.detailing = filterInfo.detailing;
                resultInfo.filter = angular.copy(filterInfo);

                if (is.not.existy(filter.idMunicipio)) {
                    filter.idMunicipio = 0;
                }

                if (is.not.existy(filter.idDependenciaAdministrativa)) {
                    filter.idDependenciaAdministrativa = 0;
                }

                if (is.not.existy(filter.idRegionalUF)) {
                    filter.idRegionalUF = 0;
                }

                if (is.not.existy(filter.idUF)) {
                    filter.idUF = 0;
                }

                Relatorios.findMediaDiasLetivos(filter).$promise.then(function (data) {
                    resultInfo.reportDataCount = data.length;
                    resultInfo.reportData = data;
                });
            }
            if (is.inArray(resultInfo.report.id, [REPORT_GROUP.ACOMPANHAMENTO_MENSAL.ACOMPANHAMENTO_MUNICIPIO])) {
                var filter = this.filter(1, filterInfo);
                resultInfo.detailing = filterInfo.detailing;
                resultInfo.filter = angular.copy(filterInfo);
                if (filterInfo.monitoring.id == 'A' && filterInfo.program.id == 1) {
                    resultInfo.reportFields = this.reportFields(resultInfo.report.id, '1.5');
                    resultInfo.reportTitle = 'Acompanhamento do Mediador';
                } else if (filterInfo.monitoring.id == 'A' && filterInfo.program.id == 2) {
                    resultInfo.reportFields = this.reportFields(resultInfo.report.id, '2.5');
                    resultInfo.reportTitle = 'Acompanhamento do Mediador';
                } else if (filterInfo.monitoring.id == 'I') {
                    resultInfo.reportFields = this.reportFields(resultInfo.report.id, '8.3');
                    resultInfo.reportTitle = 'Acompanhamento do Diretor de Escola I';
                } else if (filterInfo.monitoring.id == 'B' && filterInfo.program.id == 1) {
                    resultInfo.reportFields = this.reportFields(resultInfo.report.id, '1.6');
                    resultInfo.reportTitle = 'Acompanhamento do Coordenador Municipal/Regional B';
                } else if (filterInfo.monitoring.id == 'B' && filterInfo.program.id == 2) {
                    resultInfo.reportFields = this.reportFields(resultInfo.report.id, '2.6');
                    resultInfo.reportTitle = 'Acompanhamento do Coordenador Municipal/Regional B';
                } else if (filterInfo.monitoring.id == 'II') {
                    resultInfo.reportFields = this.reportFields(resultInfo.report.id, '8.4');
                    resultInfo.reportTitle = 'Acompanhamento do Gestor Regional/Secretário Municipal de Educação II';
                }

                // quantitativo
                if (filterInfo.monitoringTypeReport.id == '1') {
                    var result = Relatorios.findAcompanhamentoMunicipio(filter).$promise.then(function (data) {

                        if (filterInfo.monitoring.id == 'B' && (filterInfo.program.id == 1 || filterInfo.program.id == 2)) {
                            var rows = angular.copy(data);
                            resultInfo.reportData2 = [];
                            data = [];

                            angular.forEach(rows, function (value, key) {
                                if (value.id == 186) {
                                    resultInfo.reportData2.push(value);
                                } else if (value.id == 189) {
                                    resultInfo.totalSupervisores = value.total;
                                } else {
                                    data.push(value);
                                }
                            });
                        }

                        if (filterInfo.monitoring.id == 'II') {
                            var rows = angular.copy(data);
                            data = [];

                            angular.forEach(rows, function (value, key) {
                                if (value.id == 245) {
                                    resultInfo.totalDiretores = value.totalDiretores;
                                } else {
                                    data.push(value);
                                }
                            });
                        }

                        resultInfo.reportDataCount = data.length;
                        resultInfo.reportData = data;
                    });
                }

                // qualitativo
                if (filterInfo.monitoringTypeReport.id == '2') {
                    var result = Relatorios.findAcompanhamentoMunicipioDocx(filter).$promise.then(function (file) {
                        if (!file.data.byteLength) {
                            resultInfo.reportData = [];
                        } else {
                            resultInfo.reportData = [1];

                            var data = new Blob([file.data], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
                            FileSaver.saveAs(data, file.name);
                        }
                    }).catch(function (error) {
                        console.log(error);
                    });;
                }
            }

            // INDICADORES SUCESSO - RESULTADOS
            if (is.inArray(resultInfo.report.id, [REPORT_GROUP.RESULTADO_FINAL.INDICADORES_SUCESSO_RESULTADOS])) {
                var filter = this.filter(1, filterInfo);
                resultInfo.reportFields = this.reportFields(resultInfo.report.id, '1');

                Relatorios.findIndicadoresSucessoResultados(filter).$promise.then(function (data) {
                    resultInfo.reportDataCount = data.length;
                    resultInfo.reportData = data;
                });
            }

            // ACOMPANHAMENTO SEMESTRAL - LETRAMENTO EM PROGRAMAÇÃO
            if (is.inArray(resultInfo.report.id, [REPORT_GROUP.RESULTADO_BIMESTRAL.LETRAMENTO_EM_PROGRAMACAO])) {
                var filter = this.filter(1, filterInfo);

                resultInfo.detailing = filterInfo.detailing;
                resultInfo.filter = angular.copy(filterInfo);
                resultInfo.reportTitle = 'Letramento em Programação';

                if (resultInfo.detailing.id == 'R') {
                    resultInfo.reportFields = this.reportFields(resultInfo.report.id, '2');
                }

                else if (resultInfo.detailing.id == 'G') {
                    resultInfo.reportFields = this.reportFields(resultInfo.report.id, '1');
                }

                else if (resultInfo.detailing.id == '8') {
                    resultInfo.reportFields = this.reportFields(resultInfo.report.id, '3');
                }

                else if (resultInfo.detailing.id == 'U') {
                    resultInfo.reportFields = this.reportFields(resultInfo.report.id, '4');

                }

                else if (resultInfo.detailing.id == 'M') {
                    resultInfo.reportFields = this.reportFields(resultInfo.report.id, '5');
                }

                else if (resultInfo.detailing.id == 'E') {
                    resultInfo.reportFields = this.reportFields(resultInfo.report.id, '6');
                }

                else if (resultInfo.detailing.id == 'T') {
                    resultInfo.reportFields = this.reportFields(resultInfo.report.id, '7');
                }

                else if (resultInfo.detailing.id == 'P') {
                    resultInfo.reportFields = this.reportFields(resultInfo.report.id, '8');
                }

                var result = Relatorios.findResultadoBimestralLetramento(filter).$promise.then(function (data) {
                    resultInfo.reportDataCount = data.length;
                    resultInfo.reportData = data;
                });
            }

            // RESULTADO FINAL - LETRAMENTO EM PROGRAMACAO
            if (is.inArray(resultInfo.report.id, [REPORT_GROUP.RESULTADO_FINAL.LETRAMENTO_EM_PROGRAMACAO])) {
                var filter = this.filter(1, filterInfo);

                resultInfo.detailing = filterInfo.detailing;
                resultInfo.filter = angular.copy(filterInfo);
                resultInfo.reportTitle = 'Letramento em Programação';

                if (resultInfo.detailing.id == 'R') {
                    resultInfo.reportFields = this.reportFields(resultInfo.report.id, '2');
                }

                else if (resultInfo.detailing.id == 'G') {
                    resultInfo.reportFields = this.reportFields(resultInfo.report.id, '1');
                }

                else if (resultInfo.detailing.id == '8') {
                    resultInfo.reportFields = this.reportFields(resultInfo.report.id, '3');
                }

                else if (resultInfo.detailing.id == 'U') {
                    resultInfo.reportFields = this.reportFields(resultInfo.report.id, '4');
                }

                else if (resultInfo.detailing.id == 'M') {
                    resultInfo.reportFields = this.reportFields(resultInfo.report.id, '5');
                }

                else if (resultInfo.detailing.id == 'E') {
                    resultInfo.reportFields = this.reportFields(resultInfo.report.id, '6');
                }

                else if (resultInfo.detailing.id == 'T') {
                    resultInfo.reportFields = this.reportFields(resultInfo.report.id, '7');
                }

                else if (resultInfo.detailing.id == 'P') {
                    resultInfo.reportFields = this.reportFields(resultInfo.report.id, '8');
                }

                var result = Relatorios.findResultadoFinalLetramentoProgramacao(filter).$promise.then(function (data) {
                    resultInfo.reportDataCount = data.length;
                    resultInfo.reportData = data;
                });
            }

            if (is.inArray(resultInfo.report.id, [REPORT_GROUP.ACOMPANHAMENTO_MENSAL.LEITURA_ESCRITA_ORALIDADE_TEXTO])) {
                var filter = this.filter(4, filterInfo);
                resultInfo.detailing = filterInfo.detailing;
                resultInfo.filter = angular.copy(filterInfo);

                if (filterInfo.program.id == '1') { // Programa Se liga
                    Relatorios.findMovimentacaoMensalLeituraEscritaSeLiga(filter).$promise.then(function (data) {
                        resultInfo.reportDataCount = data.length;
                        resultInfo.reportData = data;
                    });
                }
            }
            if (is.inArray(resultInfo.report.id, [REPORT_GROUP.ACOMPANHAMENTO_MENSAL.LEITURA_ESCRITA_PRODUCAO_TEXTO])) {
                var filter = this.filter(4, filterInfo);
                resultInfo.detailing = filterInfo.detailing;
                resultInfo.filter = angular.copy(filterInfo);

                if (filterInfo.program.id == '2') { // Programa Acelera Brasil
                    Relatorios.findMovimentacaoMensalLeituraEscritaAceleraBrasil(filter).$promise.then(function (data) {
                        resultInfo.reportDataCount = data.length;
                        resultInfo.reportData = data;
                    });
                }
            }
            if (is.inArray(resultInfo.report.id, [REPORT_GROUP.ACOMPANHAMENTO_MENSAL.LEITURA_ESTRATEGIA_COMPREENSAO_MAT_NV_1])) {

                var filter = this.filter(4, filterInfo);
                resultInfo.detailing = filterInfo.detailing;
                resultInfo.filter = angular.copy(filterInfo);

                Relatorios.findMovimentacaoLeituraEstrategiaCompreensaoMatematicaNivel1(filter).$promise.then(function (data) {
                    resultInfo.reportDataCount = data.length;
                    resultInfo.reportData = data;
                });

            }
            if (is.inArray(resultInfo.report.id, [REPORT_GROUP.ACOMPANHAMENTO_MENSAL.FORMULA_VITORIA_LINGUA_PORTUGUESA_ACOMPANHAMENTO])) {

                resultInfo.reportFields = this.fieldsByGroup(filterInfo.detailing.id, resultInfo.reportFields);

                var filter = this.filter(1, filterInfo);
                resultInfo.detailing = filterInfo.detailing;
                resultInfo.filter = angular.copy(filterInfo);
                resultInfo.reportFieldsLista = [resultInfo.reportFields, resultInfo.reportFields, resultInfo.reportFields, resultInfo.reportFields];

                if (resultInfo.detailing.id == 'T') {
                    resultInfo.atributesSize = 3;
                    resultInfo.headerSize = 34;
                } else if (resultInfo.detailing.id == 'E') {
                    resultInfo.atributesSize = 4;
                    resultInfo.headerSize = 35;
                } else if (resultInfo.detailing.id == 'R') {
                    resultInfo.atributesSize = 3;
                    resultInfo.headerSize = 34;
                } else if (resultInfo.detailing.id == 'M') {
                    resultInfo.atributesSize = 3;
                    resultInfo.headerSize = 34;
                } else if (resultInfo.detailing.id == 'U') {
                    resultInfo.atributesSize = 2;
                    resultInfo.headerSize = 33;
                } else if (resultInfo.detailing.id == 'G') {
                    resultInfo.atributesSize = 3;
                    resultInfo.headerSize = 34;
                } else if (resultInfo.detailing.id == '8') {
                    resultInfo.atributesSize = 3;
                    resultInfo.headerSize = 34;
                }

                Relatorios.findFormulaVitoriaLinguaPortuguesaAcompanhamento(filter).$promise.then(function (data) {
                    resultInfo.reportDataCount = data.length;
                    resultInfo.reportData = data;

                    resultInfo.reportData[0].reportDataCount = resultInfo.reportData[0].listaResposta.length;
                    resultInfo.reportData[1].reportDataCount = resultInfo.reportData[1].listaResposta.length;
                    resultInfo.reportData[2].reportDataCount = resultInfo.reportData[2].listaResposta.length;
                    resultInfo.reportData[3].reportDataCount = resultInfo.reportData[3].listaResposta.length;

                    resultInfo.reportData[0].reportData = resultInfo.reportData[0].listaResposta;
                    resultInfo.reportData[1].reportData = resultInfo.reportData[1].listaResposta;
                    resultInfo.reportData[2].reportData = resultInfo.reportData[2].listaResposta;
                    resultInfo.reportData[3].reportData = resultInfo.reportData[3].listaResposta;

                });

            }
            if (is.inArray(resultInfo.report.id, [REPORT_GROUP.ACOMPANHAMENTO_MENSAL.LEITURA_ESTRATEGIA_COMPREENSAO_MAT_NV_2])) {

                var filter = this.filter(4, filterInfo);
                resultInfo.detailing = filterInfo.detailing;
                resultInfo.filter = angular.copy(filterInfo);

                if (filterInfo.program.id == '6') { // Programa: Fórmula da Vitória Matemática Nível 2 ID: 6
                    Relatorios.findMovimentacaoLeituraEstrategiaCompreensaoMatematicaNivel2(filter).$promise.then(function (data) {
                        resultInfo.reportDataCount = data.length;
                        resultInfo.reportData = data;
                    });
                }

            }
            if (is.inArray(resultInfo.report.id, [REPORT_GROUP.ACOMPANHAMENTO_MENSAL.ALFABETIZACAO_ACELERACAO_FORMULA_VITORIA])) {
                //debugger;
                var filter = this.filter(1, filterInfo);
                resultInfo.detailing = filterInfo.detailing;
                resultInfo.filter = angular.copy(filterInfo);
                resultInfo.program = filterInfo.program;
                var selfMethod = this;
                filter.auxTotalization = filterInfo.totalization;
                //RONIFER
                var result = Relatorios.findMovimentacaoMensal(filter).$promise.then(function (data) {
                    if (filter.idPrograma == 7) {
                        var resultGA = selfMethod.filterGestaoAlfabetizacao(data, filter)
                        resultInfo.reportDataCount = resultGA.length;
                        resultInfo.reportData = resultGA;
                        resultInfo.totalResultados = selfMethod.calculoTotalRelatorio(resultGA, filter, "MENSAL_GESTAO_ALFABETIZACAO");
                        resultInfo.somatoriasPorcentagem = selfMethod.calculoSomaPorcentagens(resultGA, filter);
                    } else {
                        resultInfo.reportDataCount = data.length;
                        resultInfo.reportData = data;
                    }

                });
            }
            if (is.inArray(resultInfo.report.id, [REPORT_GROUP.ACOMPANHAMENTO_MENSAL.FLUXO_AULAS])) {
                var filter = this.filter(4, filterInfo);
                resultInfo.detailing = filterInfo.detailing;
                resultInfo.filter = angular.copy(filterInfo);

                Relatorios.findFluxoAulas(filter).$promise.then(function (data) {
                    resultInfo.reportDataCount = data.length;
                    resultInfo.reportData = data;
                })
            }

            if (is.inArray(resultInfo.report.id, [REPORT_GROUP.ACOMPANHAMENTO_ANUAL.PERFIL_ATENDIMENTO_UNIDADES_ESCOLARES_TURMAS])) {
                var filter = this.filter(4, filterInfo);
                Relatorios.findPerfilAtendimentoUnidadesEscolaresTurmas(filter).$promise.then(function (data) {
                    resultInfo.reportDataCount = data.length;
                    angular.forEach(data, function (r, i) {
                        resultInfo.reportData.push({
                            report: { id: r.id },
                            school: { name: r.detalhamento },
                            ensinoregular: {
                                startyear: {
                                    m: r.ensinoRegularStartYearM,
                                    t: r.ensinoRegularStartYearT,
                                    n: r.ensinoRegularStartYearN,
                                    it: r.ensinoRegularStartYearIT
                                },
                                endyear: {
                                    m: r.ensinoRegularEndYearM,
                                    t: r.ensinoRegularEndYearT,
                                    n: r.ensinoRegularEndYearN,
                                    it: r.ensinoRegularEndYearIT
                                },
                                mediumyear: {
                                    m: r.ensinoRegularMediumYearM,
                                    t: r.ensinoRegularMediumYearT,
                                    n: r.ensinoRegularMediumYearN,
                                    it: r.ensinoRegularMediumYearIT
                                }
                            },
                            alfabetizacao: {
                                defasados: {
                                    m: r.alfabetizacaoDefasadosM,
                                    t: r.alfabetizacaoDefasadosT,
                                    n: r.alfabetizacaoDefasadosN,
                                    it: r.alfabetizacaoDefasadosIT
                                }
                            },
                            aceleracao: {
                                startyear: {
                                    m: r.aceleracaoStartYearM,
                                    t: r.aceleracaoStartYearT,
                                    n: r.aceleracaoStartYearN,
                                    it: r.aceleracaoStartYearIT
                                }
                            },
                            seliga: {
                                m: r.seLigaM,
                                t: r.seLigaT,
                                n: r.seLigaN,
                                it: r.seLigaIT
                            },
                            acelerabrasil: {
                                m: r.aceleraBrasilM,
                                t: r.aceleraBrasilT,
                                n: r.aceleraBrasilN,
                                it: r.aceleraBrasilIT
                            },
                            formulavitoria: {
                                m: r.formulaVitoriaM,
                                t: r.formulaVitoriaT,
                                n: r.formulaVitoriaN,
                                it: r.formulaVitoriaIT
                            },
                            formulavitoriaMatN1: {
                                m: r.formulaVitoriaMatN1M,
                                t: r.formulaVitoriaMatN1T,
                                n: r.formulaVitoriaMatN1N,
                                it: r.formulaVitoriaMatN1IT
                            },
                            formulavitoriaMatN2: {
                                m: r.formulaVitoriaMatN2M,
                                t: r.formulaVitoriaMatN2T,
                                n: r.formulaVitoriaMatN2N,
                                it: r.formulaVitoriaMatN2IT
                            },
                            totalturmas: { value: r.totalTurmas },
                            totalalunos: { value: r.totalAlunos }

                        });

                    });

                });

            }
            if (is.inArray(resultInfo.report.id, [REPORT_GROUP.ACOMPANHAMENTO_ANUAL.PERFIL_ATENDIMENTO_UNIDADES_ESCOLARES_CURSOS])) {
                var filter = this.filter(4, filterInfo);
                resultInfo.detailing = filterInfo.detailing;
                resultInfo.filter = angular.copy(filterInfo);

                Relatorios.findPerfilAtendimentoUnidadesEscolaresCursos(filter).$promise.then(function (data) {
                    resultInfo.reportDataCount = data.length;
                    resultInfo.reportData = data;
                });
            }
            if (is.inArray(resultInfo.report.id, [REPORT_GROUP.OUTROS.TOTAL_ESCOLAS_PROGRAMA])) {
                var filter = this.filter(4, filterInfo);
                resultInfo.detailing = filterInfo.detailing;
                resultInfo.filter = angular.copy(filterInfo);

                Relatorios.totalescolasporprograma(filter).$promise.then(function (data) {
                    resultInfo.reportDataCount = data.length;
                    resultInfo.reportData = data;
                });
            }
            if (is.inArray(resultInfo.report.id, [REPORT_GROUP.OUTROS.LISTA_UNIDADES_ESCOLARES_PERFIL_ATENDIMENTO])) {
                var filter = this.filter(4, filterInfo);
                resultInfo.detailing = filterInfo.detailing;
                resultInfo.filter = angular.copy(filterInfo);

                Relatorios.findUnidadesEscolaresPerfilAtendimento(filter).$promise.then(function (data) {
                    resultInfo.reportDataCount = data.length;
                    resultInfo.reportData = data;

                    var municipios = {};
                    var totalMunicipios = 0;
                    var municipio = data[0].idMunicipio;
                    var municipioID = null;

                    angular.forEach(resultInfo.reportData, function (value) {
                        if (value.idMunicipio != municipio) {
                            totalMunicipios = 1;
                            municipio = value.idMunicipio;
                        } else {
                            municipios[value.idMunicipio] = totalMunicipios + 1;
                            totalMunicipios += 1;
                        }

                        if (value.idMunicipio != municipioID) {
                            municipioID = value.idMunicipio;
                        } else {
                            value.municipio = null;
                        }
                    });

                    resultInfo.municipiosRowSpan = municipios;
                });
            }
            if (is.inArray(resultInfo.report.id, [REPORT_GROUP.ACOMPANHAMENTO_MENSAL.FLUXO_DE_OFICINAS])) {
                var filter = this.filter(4, filterInfo);
                resultInfo.detailing = filterInfo.detailing;
                resultInfo.filter = angular.copy(filterInfo);

                Relatorios.findFormulaVitoriaLinguaPortuguesa(filter).$promise.then(function (data) {
                    resultInfo.reportDataCount = data.length;
                    resultInfo.reportData = data;
                })
            }
            if (is.inArray(resultInfo.report.id, [REPORT_GROUP.RESULTADO_FINAL.IDADE_CONCLUSAO_4SERIE_5ANO])) {
                var filter = this.filter(4, filterInfo);
                resultInfo.detailing = filterInfo.detailing;
                resultInfo.filter = angular.copy(filterInfo);


                if (filterInfo.detailing.id == 'M') {
                    resultInfo.reportFields = this.reportFields(resultInfo.report.id, '1');

                } else if (filterInfo.detailing.id == 'U') {
                    resultInfo.reportFields = this.reportFields(resultInfo.report.id, '2');

                } else if (filterInfo.detailing.id == 'E') {
                    resultInfo.reportFields = this.reportFields(resultInfo.report.id, '2');

                } else if (filterInfo.detailing.id == 'T') {
                    resultInfo.reportFields = this.reportFields(resultInfo.report.id, '2');

                } else if (filterInfo.detailing.id == 'G') {
                    resultInfo.reportFields = this.reportFields(resultInfo.report.id, '2');

                } else if (filterInfo.detailing.id == 'R') {
                    resultInfo.reportFields = this.reportFields(resultInfo.report.id, '2');
                }

                Relatorios.findIdadeConclusao4Serie5Ano(filter).$promise.then(function (data) {
                    resultInfo.reportDataCount = data.length;
                    resultInfo.reportData = data;
                })

            }
            if (is.inArray(resultInfo.report.id, [REPORT_GROUP.RESULTADO_FINAL.IDADE_CONCLUSAO_8SERIE_9ANO])) {
                var filter = this.filter(4, filterInfo);
                resultInfo.detailing = filterInfo.detailing;
                resultInfo.filter = angular.copy(filterInfo);

                if (filterInfo.detailing.id == 'M') {
                    resultInfo.reportFields = this.reportFields(resultInfo.report.id, '1');

                } else if (filterInfo.detailing.id == 'U') {
                    resultInfo.reportFields = this.reportFields(resultInfo.report.id, '2');

                } else if (filterInfo.detailing.id == 'E') {
                    resultInfo.reportFields = this.reportFields(resultInfo.report.id, '2');

                } else if (filterInfo.detailing.id == 'T') {
                    resultInfo.reportFields = this.reportFields(resultInfo.report.id, '2');

                } else if (filterInfo.detailing.id == 'G') {
                    resultInfo.reportFields = this.reportFields(resultInfo.report.id, '2');

                } else if (filterInfo.detailing.id == 'R') {
                    resultInfo.reportFields = this.reportFields(resultInfo.report.id, '2')
                }

                Relatorios.findIdadeConclusao8Serie9Ano(filter).$promise.then(function (data) {
                    resultInfo.reportDataCount = data.length;
                    resultInfo.reportData = data;
                })

            }
            if (is.inArray(resultInfo.report.id, [REPORT_GROUP.OUTROS.GENERO_SITUACAO_ANO_ANTERIOR])) {
                var filter = this.filter(4, filterInfo);
                resultInfo.detailing = filterInfo.detailing;
                resultInfo.filter = angular.copy(filterInfo);

                Relatorios.findGeneroSituacaoAnoAnterior(filter).$promise.then(function (data) {
                    resultInfo.reportDataCount = data.length;
                    resultInfo.reportData = data;
                })

            }
            if (is.inArray(resultInfo.report.id, [REPORT_GROUP.OUTROS.MATRICULA_ENSINO_FUNDAMENTAL_REDE])) {
                var filter = this.filter(1, filterInfo);
                resultInfo.detailing = { name: 'Série' };
                resultInfo.filter = angular.copy(filterInfo);


                Relatorios.findMatriculaEnsinoFundamentalRede(filter).$promise.then(function (data) {

                    resultInfo.reportDataCount = data.length;
                    resultInfo.reportData = data;
                });
            }

            //RICSON
            if (is.inArray(resultInfo.report.id, [REPORT_GROUP.OUTROS.ESCOLAS_EDUCACAO_INFANTIL_JOVENS_ADULTOS])) {
                var filter = this.filter(1, filterInfo);
                resultInfo.filter = angular.copy(filterInfo);

                Relatorios.findEscolasEducacaoInfantilJovensAdultos(filter).$promise.then(function (data) {
                    resultInfo.reportDataCount = data.length;
                    resultInfo.reportData = data;
                });
            }

            if (is.inArray(resultInfo.report.id, [REPORT_GROUP.OUTROS.SISTEMATICA_ACOMPANHAMENTO_VISITAS_REGIONAL])) {
                var filter = this.filter(1, filterInfo);
                resultInfo.filter = angular.copy(filterInfo);

                Relatorios.findDialogosAcompanhamento(filter).$promise.then(function (data) {
                    resultInfo.reportDataCount = data.length;
                    resultInfo.reportData = data;
                });
            }

            if (is.inArray(resultInfo.report.id, [REPORT_GROUP.OUTROS.SISTEMATICA_MONITORAMENTO_REGIONAL])) {
                var filter = this.filter(1, filterInfo);
                resultInfo.filter = angular.copy(filterInfo);

                Relatorios.findDialogosMonitoramento(filter).$promise.then(function (data) {
                    resultInfo.reportDataCount = data.length;
                    resultInfo.reportData = data;
                });
            }

            if (is.inArray(resultInfo.report.id, [REPORT_GROUP.OUTROS.SISTEMATICA_ACOMPANHAMENTO_VISITAS_CENTRAL])) {
                var filter = this.filter(1, filterInfo);
                resultInfo.filter = angular.copy(filterInfo);

                Relatorios.findDialogosAcompanhamentoCentral(filter).$promise.then(function (data) {
                    resultInfo.reportDataCount = data.length;
                    resultInfo.reportData = data;
                });
            }

            if (is.inArray(resultInfo.report.id, [REPORT_GROUP.OUTROS.SISTEMATICA_MONITORAMENTO_CENTRAL])) {
                var filter = this.filter(1, filterInfo);
                resultInfo.filter = angular.copy(filterInfo);

                Relatorios.findDialogosMonitoramentoCentral(filter).$promise.then(function (data) {
                    resultInfo.reportDataCount = data.length;
                    resultInfo.reportData = data;
                });
            }
        };
    }
}());
