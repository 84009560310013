(function() {
  'use strict';

  angular.module('app.core').constant('ALUNO_SEARCH_BY', {
    CODE: {
      field: "id",
      placeholder: "código",
      mask: "9999999999",
      restrict: "[^0-9]",
      minLength: 1,
      maxLength: 10
    },
    NAME: {
      field: "nome",
      placeholder: "nome",
      mask: "*",
      restrict: "%",
      maxLength: 50,
      minLength: 3,
    },
    CPF: {
      field: "cpf",
      placeholder: "CPF",
      mask: "999.999.999-99",
      maxLength: 14,
      minLength: 14
    },
    EMAIL: {
      field: "email",
      placeholder: "e-mail",
      mask: "*",
      restrict: "%",
      maxLength: 100,
      minLength: 1
    },
    INEP: {
      field: "codigoINEP",
      placeholder: "código INEP",
      mask: "99999999999999999999",
      restrict: "[^0-9]",
      minLength: 1,
      maxLength: 20
    }
  });

}());
