/*
jslint
    devel: true, maxerr: 10
*/

/*
global
    required: true
*/

/*
 * User Resource
 * @module app.usuario (User Module)
 * @author iFactory Solutions Ltda.
 * @version 1.0.0
 * @since 1.0.0
 */
(function() {
    'use strict';

    angular.module('app.usuario').factory('Usuario', Usuario);
    Usuario.$inject = ['$resource', 'APP_PROPERTY', 'APP_MIME_TYPE', 'fileService'];

    function Usuario($resource, APP_PROPERTY, APP_MIME_TYPE, fileService) {
        return $resource(APP_PROPERTY.URL + '/usuarios/:id', { id: '@id' }, {
            update: {
                method: 'PUT'
            },
            filter: {
                method: 'GET',
                url: APP_PROPERTY.URL + '/usuarios',
                isArray: true
            },
            changeStatus: {
                method: 'GET',
                url: APP_PROPERTY.URL + '/usuarios/:id/:status'
            },
            changeProfile: {
                method: 'PATCH',
                isArray: false,
                url: APP_PROPERTY.URL + '/usuarios/:id/profile'
            },
            forgottenPassword: {
                method: 'POST',
                isArray: false,
                url: APP_PROPERTY.URL + '/usuarios/forgottenpassword'
            },
            changePassword: {
                method: 'POST',
                isArray: false,
                url: APP_PROPERTY.URL + '/usuarios/changepassword/:id'
            },
            findByToken: {
                method: 'GET',
                isArray: false,
                url: APP_PROPERTY.URL + '/usuarios/changepassword/:id'
            },
            exportByUfAndMunicipioAndEscola: {
                method: 'GET',
                cache: false,
                responseType: 'arraybuffer',
                url: APP_PROPERTY.URL + '/usuarios/exportar/:idAnoLetivo/:idPrograma/:idUf/:idMunicipio/:idEscola/import_template',
                headers: {
                    accept: [APP_MIME_TYPE.JSON, APP_MIME_TYPE.EXCEL].join()
                },
                transformResponse: function (data, headers) {
                    return fileService.transform(data, headers);
                }
            },
            exportByUfAndMunicipio: {
                method: 'GET',
                cache: false,
                responseType: 'arraybuffer',
                url: APP_PROPERTY.URL + '/usuarios/exportar/:idAnoLetivo/:idPrograma/:idUf/:idMunicipio/import_template',
                headers: {
                    accept: [APP_MIME_TYPE.JSON, APP_MIME_TYPE.EXCEL].join()
                },
                transformResponse: function (data, headers) {
                    return fileService.transform(data, headers);
                }
            },
            exportByUf: {
                method: 'GET',
                cache: false,
                responseType: 'arraybuffer',
                url: APP_PROPERTY.URL + '/usuarios/exportar/:idAnoLetivo/:idPrograma/:idUf/import_template',
                headers: {
                    accept: [APP_MIME_TYPE.JSON, APP_MIME_TYPE.EXCEL].join()
                },
                transformResponse: function (data, headers) {
                    return fileService.transform(data, headers);
                }
            },
            export: {
                method: 'GET',
                cache: false,
                responseType: 'arraybuffer',
                url: APP_PROPERTY.URL + '/usuarios/exportar/:idAnoLetivo/:idPrograma/import_template',
                headers: {
                    accept: [APP_MIME_TYPE.JSON, APP_MIME_TYPE.EXCEL].join()
                },
                transformResponse: function (data, headers) {
                    return fileService.transform(data, headers);
                }
            },
            validarEmail: {
                method: 'POST',
                isArray: false,
                url: APP_PROPERTY.URL + '/usuarios/email/validar/token?token=:token',
                params:{token: '@token'}
            }
        });
    }

}());
