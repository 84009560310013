(function(){
    'use strict';

    angular.module('app.core').service('fileService', FileService);
    FileService.$inject = ['FileSaver', 'Blob', 'APP_MIME_TYPE', 'Upload'];

    function FileService(FileSaver, Blob, APP_MIME_TYPE, Upload) {

        this.download = function(file) {
          var data = new Blob([file.data], { type: file.type });
          FileSaver.saveAs(data, file.name);
        };

        this.getFileNameFromHeader = function (headers) {
          var contentDisposition = headers('Content-Disposition');

          if (!contentDisposition) return null;

          var result = contentDisposition.split(";")[1].trim().split("=")[1];

          return result.replace(/"/g, '');
        };

        this.transform = function(data, headers) {
          var fileName = this.getFileNameFromHeader(headers);
          var fileType = headers('Content-Type');

          return {
              data: data,
              name: fileName,
              type: fileType
          };
        };

        this.upload = function(url, file) {            
           return Upload.upload({
                url: url,
                data: { file: file }     
            });
        };

        this.uploadAsArrayBuffer = function(url, file) {            
            return Upload.upload({
                 url: url,
                 data: { file: file },
                 responseType: 'arraybuffer'         
             });
         };

    }

}());
