(function(){
    'use strict';

    angular.module('app.coleta').service('coletaService', ColetaService);
    ColetaService.$inject = ['Coleta', '$q'];

    function ColetaService(Coleta, $q) {
        var self = this;

        this.tipos = [];
        this.status = [];
        this.abrangencias = [];
        this.granularidades = [];
        this.periodicidades = [];
        this.acessos = [];

        var _findByType = function(type) {
            if (self[type].length > 0) {
                return $q.resolve(self[type]);
            } else {
                var deferred = $q.defer();

                Coleta.findByType({type: type}).$promise.then(function(data) {
                        self[type] = _.map(data, function(item) {
                            return {
                                key: item.id,
                                value: item.name
                            };
                        });

                        deferred.resolve(self[type]);
                    }, function(response) {
                        deferred.reject(response);
                    });

                return deferred.promise;
            }
        };

        var _setAgendasCriadas = function(coleta) {
            var agendasCriadas = _.filter(coleta.agendas, function(agenda) {
                return !agenda.id;
            });

            if (agendasCriadas.length) {
                var agendasCriadasMoment = [];
                /*angular.forEach(agendasCriadas, function(agenda) {
                    agenda.dataInicio = moment(agenda.dataInicio, 'DD/MM/YYYY').format('YYYY-MM-DD');
                    agenda.dataFim = moment(agenda.dataFim, 'DD/MM/YYYY').format('YYYY-MM-DD');
                    agendasCriadasMoment.push(agenda);
                });*/
                coleta.agendasCriadas = agendasCriadas;
            }
        };

        var _setItensAlterados = function(coleta) {
            var itensAlterados = _.filter(coleta.itens, function(item) {
                return item._updated;
            });

            if (itensAlterados.length) {
                coleta.itensAlterados = itensAlterados;
            }
        };

        this.filter = function(filter) {
            return Coleta.filter(filter).$promise;
        };

        this.get = function(id) {
            return Coleta.get({ id: id }).$promise;
        };

        this.save = function(coleta) {
            _setAgendasCriadas(coleta);
            _setItensAlterados(coleta);
            coleta.agendas = [];
            console.log(coleta);
            var entity = new Coleta(coleta);
            if (entity.id) {
                return entity.$update();
            } else {
                return entity.$save();
            }
        };

        this.saveColetaProfessor = function(profParam){
            var entity = new Coleta(profParam);

            return entity.$saveColetaProfessor();
        };

        this.findColetaFiltrosByIdColeta = function(idColeta){
            return Coleta.findColetaFiltrosByIdColeta({ idColeta: idColeta });
        };

        this.findColetaProfessoresEscolhidos = function(idColeta){
            return Coleta.findColetaProfessoresEscolhidos({idColeta: idColeta});
        };

        this.findColetaByPrograma = function(id){
            return Coleta.findColetaByPrograma({ id: id });
        };

        this.porPrograma = function(id){
            return Coleta.porPrograma({ id: id });
        };

        this.saveFiltros = function(filters){
           var entity = new Coleta(filters);
            return entity.$saveFiltros();
        };

        this.saveItens = function(coleta) {
            return Coleta.saveItens({id: coleta.id}, coleta.itensCriados).$promise;
        };

        this.updateItens = function(coleta) {
            _setItensAlterados(coleta);

            return Coleta.updateItens({id: coleta.id}, coleta).$promise;
        };

        this.delete = function(coleta) {
            return Coleta.delete({id: coleta.id}).$promise;
        };

        this.findTipos = function() {
            return _findByType("tipos");
        };

        this.findAbrangencias = function() {
            return _findByType("abrangencias");
        };

        this.findStatus = function() {
            return _findByType("status");
        };

        this.findGranularidades = function() {
            return _findByType("granularidades");
        };

        this.findPeriodicidades = function() {
            return _findByType("periodicidades");
        };

        this.findAcessos = function() {
            return _findByType("acessos");
        };

        this.findItensByColetaId = function(coletaId) {
            return Coleta.findItens({id: coletaId}).$promise;
        };

        this.findItensAtivosByColetaId = function(coletaId) {
            return Coleta.findItens({id: coletaId, status: 'ATIVO'}).$promise;
        };

        this.viewById = function(coletaId) {
            return Coleta.viewById({id: coletaId}).$promise;
        };

        this.findAlvosByIdAndFilters = function(id, filter) {
            var newFilter = angular.copy(filter);
            newFilter.id = id;

            return Coleta.findAlvos(newFilter).$promise;
        };

        this.sendEmails = function(coletaId, emails) {
            return Coleta.sendEmails({id: coletaId}, emails).$promise;
        };

        this.verificaAberturaColeta = function(){
            return Coleta.verificaAberturaColeta().$promise;
        };

    }

}());
