(function() {
    'use strict';

    angular.module('app.core').directive('iasBetweenDateOrTimeValue', IasBetweenDateOrTimeValue);
    IasBetweenDateOrTimeValue.$inject = ['dateUtilService'];

    function IasBetweenDateOrTimeValue(dateUtilService) {
        return {
            require: 'ngModel',
            restrict: 'A',
            link: function(scope, element, attr, controller) {
                var minValue, maxValue;
                var iasBetweenDateType = is.existy(attr.iasBetweenDateOrTimeValue) ? attr.iasBetweenDateOrTimeValue : "datetime";
                var mask = attr.mask || attr.placeholder;
                var size = is.existy(mask) ? mask.length : 1;

                var minDateAttr = attr.iasBetweenMinDateOrTimeValue;
                var maxDateAttr = attr.iasBetweenMaxDateOrTimeValue;

                var _setMinValue = function(minDateAttr) {
                    if (is.existy(minDateAttr) && is.not.empty(minDateAttr)) {
                        minValue = dateUtilService.parseToInt(minDateAttr, iasBetweenDateType);
                        _registerValidators();
                    } else {
                        minValue = null;
                    }

                    controller.$validate();
                };

                var _setMaxValue = function(maxDateAttr) {
                    if (is.existy(maxDateAttr) && is.not.empty(maxDateAttr)) {
                        maxValue = dateUtilService.parseToInt(maxDateAttr, iasBetweenDateType);
                        _registerValidators();
                    } else {
                        maxValue = null;
                    }

                    controller.$validate();
                };

                var _unregisterValidators = function() {
                    delete controller.$validators.iasBetweenDateOrTimeValue;
                    delete controller.$validators.iasMinDateValue;
                    delete controller.$validators.iasMaxDateValue;
                };

                var _registerValidators = function() {
                    if (minValue && maxValue) {
                        _registerBetweenValidator();
                    } else if (minValue) {
                        _registerMinValidator();
                    } else if (maxValue) {
                        _registerMaxValidator();
                    }
                };

                var _registerBetweenValidator = function() {
                    if (is.not.existy(controller.$validators.iasBetweenDateOrTimeValue)) {
                        controller.$validators.iasBetweenDateOrTimeValue = function(modelValue, viewValue) {
                            var value = modelValue || viewValue;

                            if (value && minValue && maxValue) {
                                if (value.length == size) {
                                    var intValue = dateUtilService.parseToInt(value, iasBetweenDateType);

                                    return ((intValue >= minValue) && (intValue <= maxValue));
                                } else {
                                    return true;
                                }
                            } else {
                                return true;
                            }
                        };
                    }
                };

                var _registerMinValidator = function() {
                    if (is.not.existy(controller.$validators.iasMinDateValue)) {
                        controller.$validators.iasMinDateValue = function(modelValue, viewValue) {
                            var value = modelValue || viewValue;

                            if (value && minValue) {
                                if (value.length == size) {
                                    var intValue = dateUtilService.parseToInt(value, iasBetweenDateType);
                                    minValue = dateUtilService.parseToInt(attr.iasBetweenMinDateOrTimeValue, iasBetweenDateType);
                                    return intValue >= minValue;
                                } else {
                                    return true;
                                }
                            } else {
                                return true;
                            }
                        };
                    }
                };

                var _registerMaxValidator = function() {
                    if (is.not.existy(controller.$validators.iasMaxDateValue)) {
                        controller.$validators.iasMaxDateValue = function(modelValue, viewValue) {
                            var value = modelValue || viewValue;

                            if (value && maxValue) {
                                if (value.length == size) {
                                    var intValue = dateUtilService.parseToInt(value, iasBetweenDateType);

                                    return intValue <= maxValue;
                                } else {
                                    return true;
                                }
                            } else {
                                return true;
                            }
                        };
                    }
                };

                controller.$validators.iasInvalidDate = function(modelValue, viewValue) {
                    var value = modelValue || viewValue;

                    if (value && (value.length == size)) {
                        var date = dateUtilService.parseFromClient(value, iasBetweenDateType);

                        return dateUtilService.isValid(date);
                    } else {
                        return true;
                    }
                };

                attr.$observe('iasBetweenMinDateOrTimeValue', function(newMinValue) {
                    if (minDateAttr != newMinValue) {
                        _setMinValue(newMinValue);
                    } else if (is.not.existy(minDateAttr) || is.empty(minDateAttr)) {
                        _unregisterValidators();
                    }
                });

                attr.$observe('iasBetweenMaxDateOrTimeValue', function(newMaxValue) {
                    if (maxDateAttr != newMaxValue) {
                        _setMaxValue(newMaxValue);
                    } else if (is.not.existy(maxDateAttr) || is.empty(maxDateAttr)) {
                        _unregisterValidators();
                    }
                });

            }
        };
    }

})();