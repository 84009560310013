(function() {
    'use strict';

    angular.module('app.grupoMunicipio').factory('GrupoMunicipio', GrupoMunicipio);
    GrupoMunicipio.$inject = ['$resource', 'APP_PROPERTY'];

    function GrupoMunicipio($resource, APP_PROPERTY) {
        return $resource(APP_PROPERTY.URL + '/grupoMunicipio/:id', { id: '@id' }, {
            update: {
                method: 'PUT'
            },
            filter: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/grupoMunicipio'
            },
            findByStatus: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/grupoMunicipio/:status'
            },
            viewById: {
                method: 'GET',
                url: APP_PROPERTY.URL + '/grupoMunicipio/:id/view'
            },
            delete: {
                method: 'DELETE',
                transformResponse: function (data, headers, statusCode) {
                  return {
                    data: data,
                    responseStatusCode: statusCode
                  };
                }
            }
        });

    }

}());