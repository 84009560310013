(function() {
    'use strict';

    angular.module('app.grupoMunicipio').controller('GrupoMunicipioFormController', GrupoMunicipioFormController);
    GrupoMunicipioFormController.$inject = ['APP_STATE', 'APP_EVENT', 'TABLE_FIELDS', '$scope', '$state', 
        'grupoMunicipioService', 'toastService', 'dialogService', 'DialogService', '$rootScope',
        'municipiosService', 'ufService'
    ];

    function GrupoMunicipioFormController(APP_STATE, APP_EVENT, TABLE_FIELDS, $scope, $state, grupoMunicipioService,
        toastService, dialogService, DialogService, $rootScope, municipiosService, ufService) {

        var vm = this;
        var MODAL_TEMPLATE = 'coleta/partials/grupo-municipio-form-preview.html';

        var _init = function() {
            vm.fields = TABLE_FIELDS.GRUPO_MUNICIPIO;
            vm.editMode = false;
            vm.editPage = false;
            vm.hasScale = false;
            vm.refreshSearch = false;
            vm.grupoMunicipio = {};
            vm.modifiedItems = false;
            vm.grupoMunicipios = [];
            vm.uf = {};
            vm.ufs = ufService.ativos();

            vm.modalTemplateUrl = "grupo-municipio/partials/grupo-municipio-form-item-modal.html";

            if ($state.is(APP_STATE.GRUPO_MUNICIPIO.EDIT)) {
                vm.editPage = true;

                if ($state.params.id) {
                    grupoMunicipioService.get($state.params.id)
                        .then(function(grupoMunicipio) {
                            vm.grupoMunicipio = grupoMunicipio;

                            if (is.truthy($state.params.editMode)) {
                                vm.enableEditMode();
                            }                        
                        }, function(response) {
                            toastService.error({
                                status: response.status,
                                message: response.data.message
                            });
                        });
                } else {
                    vm.goBack();
                }
            } else {
                vm.enableEditMode();
                vm.grupoMunicipio = {};
            }  
        };

        vm.getMunicipiosPorUf = function(ufId) {
            if(ufId){
                vm.grupoMunicipios = municipiosService.porUf(ufId)
            } else {
                vm.grupoMunicipios = [];
            }
        };

        vm.addMunicipio = function(municipio){
            if(municipio && !_isMunicipioDuplicado(municipio)){
                vm.grupoMunicipio.grupoMunicipiosAdicionados = vm.grupoMunicipio.grupoMunicipiosAdicionados ? vm.grupoMunicipio.grupoMunicipiosAdicionados : [];
                municipio.uf = { nome :  vm.uf.nome};
                vm.grupoMunicipio.grupoMunicipiosAdicionados.push({municipio: municipio});
            }
        }

        vm.deleteMunicipio = function(index, isNew){
            if(!isNew && vm.grupoMunicipio.grupoMunicipios.length > index){
                vm.grupoMunicipio.grupoMunicipiosExcluidos = vm.grupoMunicipio.grupoMunicipiosExcluidos ? vm.grupoMunicipio.grupoMunicipiosExcluidos : [];
                vm.grupoMunicipio.grupoMunicipiosExcluidos.push(vm.grupoMunicipio.grupoMunicipios[index]);
                vm.grupoMunicipio.grupoMunicipios.splice(index, 1);    
            } else {
                vm.grupoMunicipio.grupoMunicipiosAdicionados.splice(index, 1);    
            }
        }

        var _isMunicipioDuplicado = function(municipio){
            var duplicado = false;
            if(vm.grupoMunicipio.grupoMunicipios && vm.grupoMunicipio.grupoMunicipios.length){
                for(var i = 0; i < vm.grupoMunicipio.grupoMunicipios.length; i++){
                    if(vm.grupoMunicipio.grupoMunicipios[i].municipio.id == municipio.id){
                        duplicado = true;
                        break;
                    }
                }    
            } 

            if(!duplicado && vm.grupoMunicipio.grupoMunicipiosAdicionados && vm.grupoMunicipio.grupoMunicipiosAdicionados.length){
                for(var i = 0; i < vm.grupoMunicipio.grupoMunicipiosAdicionados.length; i++){
                    if(vm.grupoMunicipio.grupoMunicipiosAdicionados[i].municipio.id == municipio.id){
                        duplicado = true;
                        break;
                    }
                }    
            } 
            return duplicado;
        }

        vm.goBack = function() {
            $state.go(APP_STATE.GRUPO_MUNICIPIO.SEARCH);
        };

        vm.save = function() {
            if (vm.registerForm.$valid) {
                if ((!vm.grupoMunicipio.grupoMunicipios || (vm.grupoMunicipio.grupoMunicipios.length <= 0))
                    && (!vm.grupoMunicipio.grupoMunicipiosAdicionados || (vm.grupoMunicipio.grupoMunicipiosAdicionados.length <= 0))) {
                    toastService.error("Pelo menos um município deve ser incluído.");
                } else {
                    grupoMunicipioService.save(vm.grupoMunicipio)
                        .then(function(grupoMunicipio) {
                            $scope.$emit(APP_EVENT.GRUPO_MUNICIPIO.SAVED);
                            vm.goBack();
                        }, function(response) {
                            toastService.error({
                                status: response.status,
                                message: response.data.message
                            });
                        });
                }
            }
        };

        vm.enableEditMode = function() {
            vm.editMode = true;
        };

        vm.cancel = function() {
            if (vm.refreshSearch) {
                $scope.$emit(APP_EVENT.GRUPO_MUNICIPIO.REFRESH);
            }

            vm.goBack();
        };

        (function() {
            _init();
        })();

    }

}());