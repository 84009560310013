(function(){
    'use strict';

    angular.module('app.contrato').filter('periodoLetivoFormatter', PeriodoLetivoFormatter);
    PeriodoLetivoFormatter.$inject = ['$filter'];

    function PeriodoLetivoFormatter($filter) {
        return function(item) {
            var dataInicio = $filter("date")(item.dataInicio, "dd/MM/yyyy");
            var dataFim = $filter("date")(item.dataFim, "dd/MM/yyyy");

            return dataInicio + " - " + dataFim;
        };
    }
}());
