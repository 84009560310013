(function () {
    'use strict';

    angular.module('app.periodoLetivo').controller('PeriodoLetivoFormController', PeriodoLetivoFormController);
    PeriodoLetivoFormController.$inject = ['APP_STATE', 'TABLE_FIELDS', 'APP_EVENT', 'COMMON_STATUS_LIST',
        '$state', '$scope', 'periodoLetivoService', 'dialogService', 'toastService', 'VALIDATION_PATTERN'];

    function PeriodoLetivoFormController(APP_STATE, TABLE_FIELDS, APP_EVENT, COMMON_STATUS_LIST,
        $state, $scope, periodoLetivoService, dialogService, toastService, VALIDATION_PATTERN) {

        var vm = this;

        vm.dateOptions = {
			formatYear: 'yy',
			startingDay: 1
		};
        
        vm.goBack = function () {
            $state.go(APP_STATE.PERIODO_LETIVO.SEARCH);
        };

        vm.save = function () {
            if (vm.registerForm.$valid) {
                var dataInicio = angular.copy(vm.periodoLetivo.dataInicio);
                var dataFim = angular.copy(vm.periodoLetivo.dataFim);
                dataInicio = parseInt(moment(dataInicio, 'DD/MM/YYYY').format('YYYYMMDD'));
                dataFim = parseInt(moment(dataFim, 'DD/MM/YYYY').format('YYYYMMDD'));

                if (dataInicio > dataFim) {
                    toastService.error({
                        message: "A Data Inicial deve ser menor que a Data Final."
                    });

                    return false;    
                }

                periodoLetivoService.save(vm.periodoLetivo).then(function (periodoLetivo) {
                    $scope.$emit(APP_EVENT.PERIODO_LETIVO.SAVED);
                    vm.goBack();
                }, function (response) {
                    toastService.error({
                        status: response.status,
                        message: response.data.message
                    });
                });
            }
        };

        vm.enableEditMode = function () {
            vm.editMode = true;
        };

        vm.cancel = function () {
            if (vm.refreshSearch) {
                $scope.$emit(APP_EVENT.PERIODO_LETIVO.REFRESH);
            }

            vm.goBack();
        };

        (function () {
            vm.editMode = false;
            vm.editPage = false;
            vm.defaultStatus = COMMON_STATUS_LIST[0];
            vm.VALIDATION_PATTERN = VALIDATION_PATTERN;
            
            if ($state.is(APP_STATE.PERIODO_LETIVO.EDIT)) {
                vm.editPage = true;

                if ($state.params.id) {
                    periodoLetivoService.get($state.params.id).then(function (periodoLetivo) {
                        periodoLetivo.dataInicio = moment(periodoLetivo.dataInicio, 'YYYY-MM-DD').format('DD/MM/YYYY');
                        periodoLetivo.dataFim = moment(periodoLetivo.dataFim, 'YYYY-MM-DD').format('DD/MM/YYYY');
                        vm.periodoLetivo = periodoLetivo;
                    }, function (response) {
                        toastService.error({
                            status: response.status,
                            message: response.data.message
                        });
                    });
                } else {
                    vm.goBack();
                }
            } else {
                vm.enableEditMode();
                vm.periodoLetivo = {
                    situacao: vm.defaultStatus,
                    periodoLetivoCorrente: false
                };
            }
        } ());
    }

} ());
