/*
jslint
    devel: true, maxerr: 10
*/

/*
global
    required: true
*/

/*
 * Relatórios Module
 * @controller Class Profile Report Controller
 * @author iFactory Solutions Ltda.
 * @version 1.0.0
 * @since 1.0.0
 */
(function() {
    'use strict';

    angular.module('app.relatorios').controller('RelatorioAcompanhamentoSemestralController', RelatorioAcompanhamentoSemestralController);
    RelatorioAcompanhamentoSemestralController.$inject = ['$state', 'APP_STATE', 'RelatoriosService', 'toastService', 'programasService', 'REPORT_GROUP', 'REPORT_PROGRAMS'];

    function RelatorioAcompanhamentoSemestralController($state, APP_STATE, reportService, toastService, programasService, REPORT_GROUP, REPORT_PROGRAMS) {
        var vm = this;
        var _clearReportResult = function() {
            vm.result.reportDataCount = 0;
            vm.result.reportFields = [];
            vm.result.reportData = [];
            vm.result.calculations = {};
        };

        vm.changeDetaling = function(detailingId){
            vm.view.searchWasPerformed = false;
            vm.filter.stateUFRegional = detailingId !== 'U';
            if (!vm.filter.stateUFRegional) {
                vm.model.stateRegionals = is.existy(vm.filter.state) ? reportService.loadStateRegionalsOfState(vm.filter.state.id) : [];
                vm.model.cities = [];
                vm.model.cityRegionals = [];
                vm.model.schools = [];
            } else {
                vm.changeState();
            }

        }

        vm.changeStateRegional = function() {
            if (is.existy(vm.filter.stateRegional)) {
                vm.model.cities = reportService.loadCitiesOfStateRegional(vm.filter.stateRegional.id);
            } else {
                // TODO Guardar a cidade anteriormente selecionada, caso haja; refazer a consulta pela UF (se houver uma selecioanda)
                // e já deixar selecionada a cidade previamente selecionada.
                if (is.existy(vm.filter.state)) {
                    vm.model.cities = reportService.loadCitiesOfState(vm.filter.state.id);
                } else {
                    vm.model.cities = [];
                }
                vm.model.cityRegionals = [];
                vm.model.schools = [];
            }
        };

        vm.changeCity = function() {
            if (is.existy(vm.filter.city)) {
                vm.model.cityRegionals = reportService.loadCityRegionalsOfCity(vm.filter.city.id);
                vm.model.schools = reportService.loadSchoolsOfCity(vm.filter.city.id);
                vm.filter.professor = null;
            } else {
                vm.model.cityRegionals = [];
                vm.model.schools = [];
                vm.model.profs = [];
            }
        };

        vm.changeSchoolType = function() {
            if (is.existy(vm.filter.city) && is.existy(vm.filter.schoolType)) {
                vm.model.schools = reportService.loadSchoolByFilter(vm.filter);
            } else if (is.existy(vm.filter.city)) {
                vm.model.schools = reportService.loadSchoolsOfCity(vm.filter.city.id);
            } else {
                vm.model.cityRegionals = [];
                vm.model.schools = [];
            }
        };

        vm.changeSchoolLocalization = function() {
            if (is.existy(vm.filter.city) && is.existy(vm.filter.schoolLocalization)) {
                vm.model.schools = reportService.loadSchoolByFilter(vm.filter);
            } else if (is.existy(vm.filter.city)) {
                vm.model.schools = reportService.loadSchoolsOfCity(vm.filter.city.id);
            } else {
                vm.model.cityRegionals = [];
                vm.model.schools = [];
            }
        };

        vm.changeSchoolAdministrativeDependency = function() {
            if (is.existy(vm.filter.schoolAdministrativeDependency) && is.existy(vm.filter.schoolAdministrativeDependency)) {
                vm.model.schools = reportService.loadSchoolByFilter(vm.filter);
            } else if (is.existy(vm.filter.city)) {
                vm.model.schools = reportService.loadSchoolsOfCity(vm.filter.city.id);
            } else {
                vm.model.cityRegionals = [];
                vm.model.schools = [];
            }
        };

        vm.changeProgram = function() {
            if (is.existy(vm.filter.program)) {
                vm.filter.model = vm.filter.program.model;
                vm.filter.option = vm.filter.program;
            } else {
                vm.filter.model = null;
            }
            if (is.existy(vm.result.report.events) && is.existy(vm.result.report.events.onChangeProgram)) {
                vm.result.report.events.onChangeProgram({ report: vm.result.report }, vm.filter.program);
            }
        };

        vm.changeMonitoring = function() {
            if (is.existy(vm.result.report.events) && is.existy(vm.result.report.events.onChangeMonitoring)) {
                vm.result.report.events.onChangeMonitoring({ report: vm.result.report }, vm.filter.typeMonitoring, vm.filter.typeMonitoringTypeReport);
            }
        }

        vm.generateReport = function() {
            vm.view.searchWasPerformed = true;
            vm.result.calculations = [];
            reportService.generateReportResult(vm.filter, vm.result);
        };

        vm.formatColumn = function(item, field) {
            return reportService.formatColumn(item, field, vm.result);
        };

        vm.formatSummaryColumnTotalSemestral = function(field) {
            return reportService.formatSummaryColumnTotalSemestral(vm.result.calculations, field, vm.result);
        };

        vm.formatSummaryColumn = function(field) {
            return reportService.formatSummaryColumn(vm.result.calculations, field, vm.result);
        };

        vm.formatSummaryColumnSpecific = function(field, result) {

            if (field.calculateType == 'average') {

                return result.somatorias[field.sequencia] / result.reportDataCount;
            }
            return result.somatorias[field.sequencia] / result.reportDataCount;

        };
        vm.performCalculationSpecific = function(field, item, qtd, result) {
            if(undefined == field.sequencia) {
                return;
            }
            if(undefined == result.somatorias) {
                result.somatorias = new Array(qtd);
            }
            if(undefined == result.somatorias[field.sequencia]) {
                result.somatorias[field.sequencia] = 0.0;
            }
            var variavel = reportService.formatColumn(item, field, vm.result).toString().replace(",", ".");
            variavel = Number(variavel);
            result.somatorias[field.sequencia] = variavel + result.somatorias[field.sequencia];

            var oldValue = vm.result.calculations[field.name];
            vm.result.calculations[field.name] = reportService.performatCalculation(item, field, oldValue, vm.result);
        };

         vm.formatSummaryColumnPerc = function(field) {
            var reportSumLastField = vm.formatSummaryColumn(vm.result.reportFields[vm.result.reportFields.length - 1]);
            return reportService.formatSummaryColumnPerc(vm.result.calculations, field, vm.result,reportSumLastField);
        };

        vm.performCalculation = function(field, item) {
            var oldValue = vm.result.calculations[field.name];
            vm.result.calculations[field.name] = reportService.performatCalculation(item, field, oldValue, vm.result);
        };

        vm.showResultReport = function(reportResultInfo) {
            var result = vm.result.reportDataCount > 0 && vm.view.searchWasPerformed;
            if (result && is.existy(reportResultInfo)) {
                if (is.existy(reportResultInfo.reportId)) {
                    result = result && is.existy(vm.result) && is.existy(vm.result.report) && vm.result.report.id == reportResultInfo.reportId;
                }
                if (is.existy(result && reportResultInfo.modelId)) {
                    result = result && is.existy(vm.filter) && is.existy(vm.filter.model) && vm.filter.model.id == reportResultInfo.modelId;
                }
            }
            return result;
        };

        vm.showFooter = function(fieldInfo) {
            var result = is.not.existy(fieldInfo.showFooter) || fieldInfo.showFooter;
            return result;
        };

        vm.isMonthlyTotalizationEnabled = function() {
            return vm.filter.totalization.id == vm.model.totalization[0].id;
        };

        vm.isAccumulatedTotalizationEnabled = function() {
            return vm.filter.totalization.id == vm.model.totalization[1].id;
        };

        vm.isModelValid = function() {
            var result = true;
            if (is.existy(vm.result.report.constraints) && is.truthy(vm.result.report.constraints.model)) {
                result = is.existy(vm.filter.model);
            }
            return result;
        };

        vm.isTypeReportValid = function() {
            var result = true;
            if (is.existy(vm.result.report.constraints) && is.truthy(vm.result.report.constraints.typeReport)) {
                result = is.existy(vm.filter.typeReport);
            }
            return result;
        };

        vm.isFilterValid = function() {
            return vm.reportForm.$valid && vm.isModelValid() && vm.isTypeReportValid();
        };

        vm.changeGrupoMunicipioIas = function(){
            if (is.existy(vm.filter.grupoMunicipioIas)) {
                vm.filter.state = null;
                vm.filter.cityRegional = null;
                vm.filter.city = null;
                vm.filter.stateRegional = null;
                vm.filter.school = null;
                vm.filter.professor = null;
            }
        };

        vm.changeState = function() {
            if (is.existy(vm.filter.state)) {
                vm.model.cities = reportService.loadCitiesOfState(vm.filter.state.id);
                vm.model.stateRegionals = reportService.loadStateRegionalsOfState(vm.filter.state.id);
                vm.filter.grupoMunicipioIas = null;
                vm.filter.school = null;
                vm.filter.professor = null;
            } else {
                vm.model.stateRegionals = [];
                vm.model.cities = [];
                vm.model.cityRegionals = [];
                vm.model.schools = [];
                vm.model.profs = [];
            }
        };

        vm.changeEscola = function(){
            vm.model.profs = [];
            vm.filter.professor = null;
            reportService.loadProfessor({idEscola: vm.filter.school.id}).then(function(result) {
                vm.model.profs = result;
            });
        };

        vm.totalization = function() {
            if (vm.filter.totalization.id == "B") {
                vm.filter.bimestreInicio = null;
                vm.filter.bimestreTermino = null;
            } else {
                vm.filter.bimestre = null;
            }
        };

        vm.isTotalizacaoBimestre = function() {
            return vm.filter.totalization.id == "B";
        };

        vm.isTotalizacaoAcumulada = function() {
            return vm.filter.totalization.id == "A";
        };

        vm.idInvalidComboBimestreTermino = function() {
            return false;
        };

        (function() {
            vm.model = {
                states: [],
                stateRegionals: [],
                cities: [],
                cityRegionals: [],
                cityDistricts: [],
                schools: [],
                modeloRel: [],
                schoolYears: [],
                schoolTypes: [],
                schoolLocalizations: [],
                schoolAdministrativeDependencies: [],
                partnershipTypes: [],
                programs: [],
                programsPortuguesFormulaVitoria:[],
                programsMatematicaNv1:[],
                programsMatematicaNv2:[],
                programsAlfabetizacaoAceleracao:[],
                programsAAFV:[],
                programsLeituraEscrita:[],
                programsLeituraEscritaProducaoTexto:[],
                programsMediador:[],
                programsLetramento:[],
                classes: [],
                classSeries: [],
                classShifts: [],
                classTypes: [],
                months: [],
                programsFluxoOficinas: [],
                classMultiserie: [
                    {
                        key: 'false',
                        value: "Regular"
                    },
                    {
                        key: 'true',
                        value: "Multisseriada"
                    },
                ],
                detailings: [
                    {
                        id: "G",
                        name: "Regional UF"
                    },
                    {
                        id: "R",
                        name: "Regional Municipal"
                    },
                    {
                        id: "8",
                        name: "Grupo de Município IAS"
                    },
                    {
                        id: "U",
                        name: "Estado"
                    },
                    {
                        id: "M",
                        name: "Município"
                    },
                    {
                        id: "E",
                        name: "Escola"
                    },
                    {
                        id: "T",
                        name: "Turma"
                    },
                    {
                        id: "P",
                        name: "Professor"
                    }
                ],
                totalization: [{
                    id: "B",
                    name: "Bimestral"
                },
                    {
                        id: "A",
                        name: "Acumulada"
                    }
                ],
                monitoring: [
                    {
                        id: "A",
                        name: "A"
                    },
                    {
                        id: "B",
                        name: "B"
                    },
                    {
                        id: "I",
                        name: "I"
                    },
                    {
                        id: "II",
                        name: "II"
                    }
                ],
                monitoringTypeReport: [{
                        id: "1",
                        name: "Quantitativo"
                    }
                ],
                anoEscolar: [{
                        id: "1",
                        name: "Não Informado"
                    },
                    {
                        id: "2",
                        name: "4°"
                    },
                    {
                        id: "3",
                        name: "5°"
                    },
                    {
                        id: "4",
                        name: "6°"
                    },
                    {
                        id: "5",
                        name: "7°"
                    },
                    {
                        id: "6",
                        name: "8°"
                    },
                    {
                        id: "7",
                        name: "9°"
                    }
                ],
                semestre: [{
                        id: "1",
                        name: "1°"
                    },
                    {
                        id: "2",
                        name: "2°"
                    }
                ],
                bimestres: [
                    {
                        key: 1,
                        value: "1º Bimestre"
                    },
                    {
                        key: 2,
                        value: "2º Bimestre"
                    },
                    {
                        key: 3,
                        value: "3º Bimestre"
                    },
                    {
                        key: 4,
                        value: "4º Bimestre"
                    }
                ],
                profs: [],
                grupoMunicipioIas: []
            };
            vm.filter = {
                anoEscolar: null,
                state: null,
                stateRegional: null,
                city: null,
                cityRegional: null,
                cityDistrict: null,
                schoolYear: null,
                modeloRel: null,
                schoolType: null,
                schoolLocalization: null,
                schoolAdministrativeDependency: null,
                partnershipType: null,
                detailing: null,
                shift: null,
                program: null,
                totalization: "B",
                model: null,
                class: null,
                classShift: null,
                classType: null,
                classSerie: null,
                yearTotalization: null,
                monthTotalization: null,
                yearTotalizationInitial: null,
                monthTotalizationInitial: null,
                yearTotalizationFinal: null,
                monthTotalizationFinal: null,
                mesInicio:null,
                mesFim:null,
                typeMonitoring: null,
                typeMonitoringTypeReport: null,
                stateUFRegional: true,
                monitoring: null,
                monitoringTypeReport: { id: "1", name: "Quantitativo" },
                school:null,
                grupoMunicipioIas: null,
                professor: null,
                semestre:null
            };

            vm.view = {
                available: true,
                searchWasPerformed: false,
                currentPage: null,
                allSchoolType: {
                    id: '0',
                    name: 'Todos'
                },
                allSchoolLocalization: {
                    id: '0',
                    nome: 'Todas'
                },
                allSchoolAdministrativeDependency: {
                    id: '0',
                    nome: 'Todas'
                },
                allClasses: {
                    key: '0',
                    value: 'Todas'
                },
                allClassMultiserie: {
                    key: '0',
                    value: 'Todos'
                },
                allClassShift: {
                    key: '0',
                    value: 'Todos'
                },
                allClassType: {
                    key: '0',
                    value: 'Todos'
                },
                disableYearMonth: false
            };
            vm.result = {
                report: null,
                reportDataCount: 0,
                reportFields: [],
                reportData: [],
                calculations: {}
            };

            vm.model.states = reportService.loadStates();

            reportService.loadMunicipiosIAS().then(function(data){
                vm.model.grupoMunicipioIas = data.data
            })

            vm.printDados = function(){
                console.log(vm.model)
                console.log(vm.filter)
            }

            vm.changeDetailingComplete = function() {

            };

            vm.model.schoolYears = reportService.loadSchoolYears();
            vm.model.schoolTypes = reportService.loadShoolTypes();
            vm.model.schoolLocalizations = reportService.loadLocalizations();
            vm.model.schoolAdministrativeDependencies = reportService.loadAdministrativeDependencies();
            vm.model.months = reportService.loadMonths();
            vm.model.mesInicioMonths = reportService.loadMonths();
            vm.model.mesFimMonths = reportService.loadMonths();
            reportService.loadClassShifts().then(function(data) {
                vm.model.classShifts = angular.copy(data);
            }, function() {
                vm.model.classShifts = [];
            });
            reportService.loadClassTypes().then(function(data) {
                vm.model.classTypes = angular.copy(data);
            }, function() {
                vm.model.classTypes = [];
            });
            vm.model.classes = reportService.loadClasses();

            // Por padrão, o detalhamento do relatório é feito por turma.
            vm.filter.detailing = vm.model.detailings[6];
            vm.filter.detailingsRelatorios = vm.model.detailings[5];
            // Por padrão, o tipo da escola é "Todos".
            vm.filter.schoolType = vm.view.allSchoolType;
            // Por padrão, a localização da escola é "Todas".
            vm.filter.schoolLocalization = vm.view.allSchoolLocalization;
            // Por padrão, a dependência administrativa da escola é "Todas".
            vm.filter.schoolAdministrativeDependency = vm.view.allSchoolAdministrativeDependency;
            // Por padrão, a totalização do relatório é feito por mês.
            vm.filter.totalization = vm.model.totalization[0];
            // Por padrão, o tipo de turma é "Todos".
            vm.filter.classType = vm.view.allClassType;
            // Por padrão o tipo de relatório de acompanhamento é qualitativo
            vm.filter.monitoringTypeReport = vm.model.monitoringTypeReport[0];

            // Por padrão, o tipo de turma é "Todos".
            vm.filter.classMultiserie = vm.view.allClassMultiserie;

            // TODO Realizar o tratamento adequado para o caso de não ter o report no parâmetro do $state (avisar ou já redirecionar para a tela base?)
            vm.result.report = $state.params.report;

            //console.log("sfwefwwfwef     " + vm.result.report.id);

            if (is.not.existy(vm.result.report)) {
                vm.view.available = true; // TODO Espalhar em toda a interface esse controle.
                toastService.error('Não foi possível recuperar as informações do relatório.');
            } else {
                reportService.loadPrograms({ reportId: vm.result.report.id }).then(function(data) {
                    vm.model.programs = data;
                });
            }

            programasService.ativosRelatorio().then(function(programsLetramento){
                angular.forEach(programsLetramento, function(value){
                    if (is.inArray(value.id, [30])) {
                        vm.model.programsLetramento.push({
                            id: value.id,
                            nome: value.nome
                        });

                        vm.filter.program = vm.model.programsLetramento[0];
                    }
                });
            });

        })();
    }
}());
