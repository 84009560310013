(function() {
    'use strict';

    angular.module('app.periodoLetivo').factory('PeriodoLetivo', PeriodoLetivo);
    PeriodoLetivo.$inject = ['$resource', 'APP_PROPERTY'];

    function PeriodoLetivo($resource, APP_PROPERTY) {
        return $resource(APP_PROPERTY.URL + '/periodo-letivo/:id', { id: '@id' }, {
            update: {
                method: 'PUT'
            },
            get: {
                method: 'GET'
            },
            filter: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/periodo-letivo'
            },
            ativo: {
                method: 'GET',
                url: APP_PROPERTY.URL + '/periodo-letivo/ATIVO',
                isArray: true
            },
            findByStatus: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/periodo-letivo/:status'
            },
            delete: {
                method: 'DELETE',
                transformResponse: function(data, headers, statusCode) {
                        var finalRsponse = {
                            data: data,
                            responseStatusCode: statusCode
                        };
                    return finalRsponse;
                },
                url: APP_PROPERTY.URL + '/periodo-letivo/:id'
            }
            
        });

    }

}());