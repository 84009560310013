(function() {
    'use strict';

    angular.module('app.formExemplo').controller('FormExemploController', FormExemploController);
    FormExemploController.$inject = ['APP_STATE', 'APP_EVENT', 'SEARCH_BY_FIELD', '$state', '$scope', 
        'formExemploService', 'FORM_MESSAGES'];

    function FormExemploController(APP_STATE, APP_EVENT, SEARCH_BY_FIELD, $state, $scope,
        formExemploService, FORM_MESSAGES) {

        var vm = this;


        vm.goEditPage = function(form) {
            formExemploService.edit(form)
            .then(function(response){
              console.log(response.resultado);
              if (response.resultado == true) {
                    toastService.success(FORM_MESSAGES.success.updated);
                  } else {
                    toastService.error(FORM_MESSAGES.error.updated);
                  }
            }, function(response) {
                  toastService.error({
                    status: response.status,
                    message: response.data.message
                  });
              });
        };


        vm.search = function() {
            vm.respostas = [];
         
            formExemploService.filter()
                .then(function(data) {
                    vm.respostas = angular.copy(data);
     
                }, function(response) {
                    toastService.error({
                        status: response.status,
                        message: response.data.message
                    });
                })
               
        };

    }

}());