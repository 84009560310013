(function() {
    'use strict';

    angular.module('app.coletaRubrica').controller('ColetaRubricaFormController', ColetaRubricaFormController);
    ColetaRubricaFormController.$inject = ['APP_STATE', 'APP_EVENT', 'TABLE_FIELDS', '$scope', '$state', 'coletaRubricaService',
        'toastService', 'formularioService', 'programasService', 'dialogService', 'VALIDATION_PATTERN', 'DialogService',
        '$rootScope', 'COLETA_RUBRICA_ANONIMA_DEFAULT_FIELDS','disciplinasService','ufService',
        'municipiosService','escolasService','turmasService','rubricasService','professorService',
        'periodoLetivoService'
    ];

    function ColetaRubricaFormController(APP_STATE, APP_EVENT, TABLE_FIELDS, $scope, $state, coletaRubricaService,
        toastService, formularioService, programasService, dialogService, VALIDATION_PATTERN, DialogService, $rootScope,
        COLETA_RUBRICA_ANONIMA_DEFAULT_FIELDS,disciplinasService,ufService,
        municipiosService,escolasService,turmasService,rubricasService,professorService,
        periodoLetivoService) {

        var vm = this;
        var _oldTipoAcesso = null;
        var _formularios = [];
        var _formulariosAnonimos = [];
        var _granularidadesNaoAnonima = [];
        
        var MODAL_TEMPLATE = 'coleta-rubrica/partials/coletaRubrica-form-preview.html';

        var _isColetaRubricaAnonima = function() {
            return vm.coletaRubrica.tipoAcesso && (vm.coletaRubrica.tipoAcesso.key === COLETA_RUBRICA_ANONIMA_DEFAULT_FIELDS.GRANULARIDADE.key);
        };

        var _init = function() {
            vm.btnsHovers = false;
            vm.salvarContinuar = false;
            vm.MAX_DAYS = 255;
            vm.editMode = false;
            vm.editPage = false;
            vm.agendaInicio = null;
            vm.agendaFim = null;
            vm.refreshSearch = false;
            vm.altInputFormats = ['yyyy-MM-dd'];
            vm.dateFormat = 'dd/MM/yyyy';
            vm.MOTIVO_INATIVACAO_MAX_CHARS = 200;
            vm.VALIDATION_PATTERN = VALIDATION_PATTERN;
            vm.coletaRubricaAnonima = false;
            
            vm.disciplinas = disciplinasService.ativos();
            vm.disciplinasEscolhidas = [];
            vm.rubricas = [];
            vm.rubricasEscolhidas = [];
            vm.professores = [];
            vm.professoresEscolhidos = [];
            vm.periodosLetivos = periodoLetivoService.ativos();

            vm.formularios = [];
            
            ufService.withPermissionFilter().then(function(ufs)
            {
                vm.ufs = ufs;
            });

            vm.changeUf = function(){
                if(vm.uf){
                    var params = {idUf:vm.uf.id};
                    municipiosService.withPermissionFilter(params).then(function(municipios){
                        vm.municipios = municipios
                        
                    });
                }
            }

            vm.changeMunicipio = function(){
                if(vm.municipio){
                    vm.programas = programasService.byUfAndMunicipio(vm.uf.id,vm.municipio.id);
                    vm.escolas = escolasService.porMunicipio(vm.municipio.id);
                }
                
            }

            vm.changeEscola = function(){
                if(vm.escola && vm.coletaRubrica.programa){
                    var params = {idEscola:vm.escola.id,idPrograma:vm.coletaRubrica.programa.id};
                    vm.turmas = turmasService.withPermissionFilter(params);
                }
            }
            vm.changePrograma = function(){
                if(vm.coletaRubrica.programa){
                    vm.rubricas = rubricasService.byPrograma(vm.coletaRubrica.programa.id);
                    vm.changeEscola();
                }
            }
            vm.changeTurma = function(){
                if(vm.turma){
                    vm.coletaRubrica.coletaRubrica.turmas = [];
                    vm.coletaRubrica.coletaRubrica.turmas.push(
                        {
                            turma:vm.turma,
                            situacao: {
                                key: 'A'
                            }

                        }
                    );
                    professorService.byTurma({idTurma:vm.turma.id}).then(function(professores){
                        vm.professores = professores;
                    });
                }
            }

            $scope.$watch('vm.rubricasEscolhidas', function(newValue, oldValue) {
                vm.changeItems();
            });

            vm.setDisciplinas = function(){
                if(vm.coletaRubrica){
                    vm.coletaRubrica.coletaRubrica.disciplinas = [];
                    _.each(vm.disciplinasEscolhidas,function(disciplina){
                        vm.coletaRubrica.coletaRubrica.disciplinas.push(
                            {
                                disciplina:disciplina,
                                situacao:{
                                    key: 'A',
                                }
                            }
                        )
                    });
                }
            }

            vm.setProfessores = function(){
                if(vm.coletaRubrica){
                    vm.coletaRubrica.coletaRubrica.professores = [];
                    _.each(vm.professoresEscolhidos,function(professor){
                        vm.coletaRubrica.coletaRubrica.professores.push(
                            {
                                professor:professor,
                                situacao:{
                                    key: 'A',
                                },
				criadorColeta:false
                            }
                        )
                    });
                }                
            }

            

            vm.changeItems = function(){
                if(vm.coletaRubrica){
                    if(vm.rubricasEscolhidas){
                        vm.labelsRubricas = {
                            selectAll: "Selecionar todos",
                            unselectAll: "Desmarcar",
                            itemsSelected: "selecionados",
                            search: "Pesquisar",
                            select: vm.rubricasEscolhidas.length > 1 ? vm.rubricasEscolhidas.length+" Selecionadas" : vm.rubricasEscolhidas.length+" Selecionada"
                        };
                    }
                    vm.coletaRubrica.itensExcluidos = vm.coletaRubrica.itensExcluidos || [];
                    vm.coletaRubrica.itensExcluidos = vm.coletaRubrica.itensExcluidos.concat(vm.coletaRubrica.itens).concat(vm.coletaRubrica.itensCriados);
                    vm.coletaRubrica.itens = [];
                    vm.coletaRubrica.itensCriados = [];
                    var i = 1;
                    _.each(vm.rubricasEscolhidas, function(rubrica) {
                        formularioService.findByRubrica(rubrica.id).then(function(formularios){
                            if(formularios.length > 0){
                                vm.coletaRubrica.itensCriados.push({formulario:formularios[0],nrOrdem:i++});
                            }
                        });
                    });            
                }
            }

            vm.setFields = function(){
                if(vm.coletaRubrica){
                    vm.setFieldsRubrica(vm.coletaRubrica);
                    var coletaRubrica = vm.coletaRubrica.coletaRubrica;
                    if(coletaRubrica){
                        vm.setFieldsTurma(coletaRubrica);
                        vm.setFieldsDisciplina(coletaRubrica);
                        vm.setFieldsProfessor(coletaRubrica);
                        
                    }
                }
            }
            vm.setFieldsTurma = function(coletaRubrica){
                if(coletaRubrica.turmas && coletaRubrica.turmas.length > 0){
                    var turma = coletaRubrica.turmas[0];
                    if(turma.turma){
                        var escola = turma.turma.escola;
                        if(escola){
                            vm.uf = escola.uf
                            vm.municipio = escola.municipio;
                            vm.escola = escola;
                            vm.turma = turma.turma;
                            vm.changeUf();
                            vm.changeMunicipio();
                            vm.changePrograma();
                            vm.changeEscola();
                            vm.changeTurma();
                        }
                    }
                }
            }

            vm.setFieldsDisciplina = function(coletaRubrica){
                if(coletaRubrica.disciplinas && coletaRubrica.disciplinas.length > 0){
                    var disciplinas = [];
                    _.each(coletaRubrica.disciplinas,function(disciplina){
                        disciplinas.push(disciplina.disciplina);
                    });
                    vm.disciplinasEscolhidas = disciplinas;
                }
            }
            vm.setFieldsProfessor = function(coletaRubrica){
                if(coletaRubrica.professores && coletaRubrica.professores.length > 0){
                    var professores = [];
                    _.each(coletaRubrica.professores,function(professor){
                        professores.push(professor.professor);
                    });
                    vm.professoresEscolhidos = professores;
                }
            }

            vm.setFieldsRubrica = function(coletaRubrica){
                var itens = coletaRubrica.itens;
                if(itens && itens.length > 0){
                    var rubricas = [];
                    _.each(itens,function(item){
                        if(item.formulario && item.formulario.rubrica){
                            rubricas.push(item.formulario.rubrica);
                        }
                    });
                    vm.rubricasEscolhidas = rubricas;
                }
            }


            vm.labelsRubricas = {
                selectAll: "Selecionar todos",
                unselectAll: "Desmarcar",
                itemsSelected: "selecionados",
                search: "Pesquisar",
                select: "Selecione"
            }; 
            vm.labels = {
                selectAll: "Selecionar todos",
                unselectAll: "Desmarcar",
                itemsSelected: "selecionados",
                search: "Pesquisar",
                select: "Selecione"
            };
            vm.dateOptions = {
                dateDisabled: _disabledWeekend,
                formatYear: 'yy',
                maxDate: new Date(2100, 5, 22),
                minDate: new Date(),
                startingDay: 1
            };

            vm.tableFields = TABLE_FIELDS.COLETA_RUBRICA;
            vm.modalTemplateUrl = "coleta-rubrica/partials/coleta-rubrica-form-item-modal.html";
            vm.modalDependencies = {};
            _populateDependencies();

            if ($state.is(APP_STATE.COLETA_RUBRICA.EDIT)) {
                vm.editPage = true;
                
                coletaRubricaService.get($state.params.id)
                    .then(function(coletaRubrica) {
                        vm.coletaRubrica = coletaRubrica;
                        vm.coletaRubricaAnonima = _isColetaRubricaAnonima();
                        vm.setFields();
                        
                        _refreshGranularidadeAndForms();

                        if (is.not.existy(_.findWhere(vm.programas, {id: vm.coletaRubrica.programa.id}))){
                            vm.programas.push(vm.coletaRubrica.programa);
                            vm.programas = _.sortBy(vm.programas, 'nome');
                        }

                        _setOrCreateLists();
                        if (is.truthy($state.params.editMode)) {
                                vm.enableEditMode();
                        }
                    }, function(response) {
                        toastService.error({
                            status: response.status,
                            message: response.data.message
                        });
                    });
            } else {
                if ($state.params.coletaRubrica) {
                    vm.coletaRubrica = $state.params.coletaRubrica;
                    vm.coletaRubricaAnonima = _isColetaRubricaAnonima();

                    _refreshGranularidadeAndForms();
                } else {
                    vm.coletaRubrica = {
                        controlaPrazo: false,
                        controlaAlteracao: false,
                        itens: [],
                        itensCriados: [],
                        itensExcluidos: [],
                        agendas: [],
                        tipoAcesso: {
                            key: 'A'
                        },
                        situacao: {
                            key: 'A'
                        },
                        tipoColeta: {
                            key: 'A'
                        },
                        granularidade:{
                            key: 'A'
                        },
                        coletaRubrica: {
                            situacao: {
                                key: 'A'
                            },
                            turmas: [],
                            disciplinas:[],
                            professores:[],
                        }
                    };
                }

                vm.enableEditMode();
            }
        };

        var _isAnonymousForm = function(form) {
            if (!form) {
                return false;
            }

            return form.granularidade.key === COLETA_RUBRICA_ANONIMA_DEFAULT_FIELDS.GRANULARIDADE.key;
        };

        var _populateDependencies = function() {
            coletaRubricaService.findTipos().then(function(tipos) {
                vm.tipos = tipos;
            });

            coletaRubricaService.findStatus().then(function(situacoes) {
                vm.situacoes = situacoes;
            });

            coletaRubricaService.findAbrangencias().then(function(abrangencias) {
                vm.abrangencias = abrangencias;
            });

            coletaRubricaService.findGranularidades().then(function(granularidades) {
                _granularidadesNaoAnonima = _.filter(granularidades, function(grao) {
                    return grao.key !== COLETA_RUBRICA_ANONIMA_DEFAULT_FIELDS.GRANULARIDADE.key;
                });

                _refreshGranularidade();
            });

            coletaRubricaService.findPeriodicidades().then(function(periodicidades) {
                vm.periodicidades = periodicidades;
            });

            coletaRubricaService.findAcessos().then(function(acessos) {
                vm.acessos = acessos;
            });

            formularioService.findAtivos("id,nome,descricao,situacao,tipo")
                .then(function(formularios) {
                    _formularios = [];
                    _formulariosAnonimos = [];

                    _.each(formularios, function(form) {
                        if (_isAnonymousForm(form)) {
                            _formulariosAnonimos.push(form); 
                        } else {
                            _formularios.push(form);
                        }
                    });

                    _refreshFormularios();
                });


            vm.modalDependencies.consideraMovimentacaoTurma = function(itemColeta) {
                if (itemColeta.formulario && itemColeta.formulario.periodicidade) {
                    if (itemColeta.formulario.periodicidade.key == 'M' && itemColeta.formulario.granularidade.key == 'T') {
                        itemColeta.exibeMovimentacaoMensalTurma = itemColeta.exibeMovimentacaoMensalTurma == undefined ? false : itemColeta.exibeMovimentacaoMensalTurma;
                        return true;
                    }
                }
                
                return false;
            }

            vm.modalDependencies.goToFormularioCadastro = function(){
                DialogService.confirm("Você tem certeza que deseja ser redirecionado para a tela de cadastro de formulário?", function(){
                    $state.go(APP_STATE.FORMULARIO.NEW);
                });
            }

            vm.modalDependencies.save = function(modalForm){
                vm.registerForm.$setDirty();
                this.ok(modalForm);
            }

        };

        var _saveOrUpdateItensMessages = function(success, response) {
            if (success) {
                vm.refreshSearch = true;
                vm.registerForm.$setPristine();
                toastService.success("Operação realizada com sucesso.");
            } else {
                vm.refreshSearch = false;

                toastService.error({
                    status: response.status,
                    message: response.data.message
                });
            }
        };

        var _disabledWeekend = function(data) {
            var date = data.date,
                mode = data.mode;

            return mode === 'day' && (date.getDay() === 0 || date.getDay() === 6);
        };

        var _deleteAllAgendas = function() {
            _clearAgendaErrors();

            if (vm.coletaRubrica.agendas) {
                vm.coletaRubrica.agendasExcluidas = _.filter(vm.coletaRubrica.agendas, function(agenda) {
                    return agenda.id;
                });
            }

            vm.coletaRubrica.agendas = [];
        };

        var _normalizeDate = function(date) {
            return moment(date, 'YYYY-MM-DD').toDate();
        }

        var _getAgendaOverlap = function(start, end) {
            var startMoment = parseInt(moment(start, 'DD/MM/YYYY').format('YYYYMMDD'));
            var endMoment = parseInt(moment(end, 'DD/MM/YYYY').format('YYYYMMDD'));
            var i, agenda, agendaInicio, agendaFim;
            var rangeSize = (vm.coletaRubrica.agendas) ? vm.coletaRubrica.agendas.length : 0;
            for (i = 0; i < rangeSize; i++) {
                agenda = vm.coletaRubrica.agendas[i];
                agendaInicio = parseInt(moment(agenda.dataInicio).format('YYYYMMDD'));
                agendaFim = parseInt(moment(agenda.dataFim).format('YYYYMMDD'));
                if (startMoment <= agendaFim && endMoment >= agendaInicio) {    
                    return agenda;
                }
            }

            return null;
        };

        var _clearAgendaErrors = function() {
            if (vm.registerForm.agendaInicio) {
                vm.registerForm.agendaInicio.$setValidity("dateOverlap", true);
                vm.registerForm.agendaInicio.$setValidity("required", true);
                vm.registerForm.agendaInicio.$setValidity("invalidDate", true);
                vm.registerForm.agendaInicio.$setValidity("dateLessNow", true);
            }

            if (vm.registerForm.agendaFim) {
                vm.registerForm.agendaFim.$setValidity("dateOverlap", true);
                vm.registerForm.agendaFim.$setValidity("dateLessThan", true);
                vm.registerForm.agendaFim.$setValidity("required", true);
                vm.registerForm.agendaFim.$setValidity("invalidDate", true);
            }

            vm.registerForm.$setValidity("noAgenda", true);
            vm.agendaHasError = false;
        };

        var _setOrCreateLists = function() {
            vm.coletaRubrica.itens = vm.coletaRubrica.itens || [];
            vm.coletaRubrica.itensCriados = vm.coletaRubrica.itensCriados || [];
            vm.coletaRubrica.itensExcluidos = vm.coletaRubrica.itensExcluidos || [];
            vm.coletaRubrica.agendas = vm.coletaRubrica.agendas || [];
        };

        var _agendaWatcher = $scope.$watchGroup(["vm.agendaInicio", "vm.agendaFim"],
            function(newValue, oldValue) {
                if (newValue != oldValue) {
                    _clearAgendaErrors();
                }
            });

        vm.goBack = function() {
            $state.go(APP_STATE.COLETA_RUBRICA.SEARCH);
        };

        vm.enableEditMode = function() {
            vm.editMode = true;
        };

        vm.clear = function() {
            vm.agendaInicio = null;
            vm.agendaFim = null;
        };

        vm.deleteDiasPrazo = function() {
            delete vm.coletaRubrica.diasPrazo;
        };

        vm.deleteDiasAlteracao = function() {
            delete vm.coletaRubrica.diasAlteracao;
        };

        vm.changeSituacao = function() {
            if (vm.coletaRubrica.situacao && vm.coletaRubrica.situacao != 'I') {
                delete vm.coletaRubrica.motivoInativacao;
            }
        };

        vm.changeTipo = function() {
            if (vm.coletaRubrica.tipoColeta) {
                if (vm.coletaRubrica.tipoColeta.key != 'P') {
                    delete vm.coletaRubrica.periodicidade;
                }

                if (vm.coletaRubrica.tipoColeta.key != 'A') {
                    _deleteAllAgendas();
                }

            } else {
                delete vm.coletaRubrica.periodicidade;
                _deleteAllAgendas();
            }
        };

        vm.checkRangeAgendas = function(dataInicio) {
            var dataColetaRubricaInicio = parseInt(moment(dataInicio, 'DD/MM/YYYY').format('YYYYMMDD'));
            var yesterday = new Date();
            yesterday.setDate(yesterday.getDate() - 1);
            var now = parseInt(moment(yesterday, 'DD/MM/YYYY').format('YYYYMMDD'));
            if (dataColetaRubricaInicio <= now) {
                return false;
            } else {
                return true;
            }
        };

        vm.addAgenda = function() {
            vm.agendaHasError = false;
            
            if (vm.agendaInicio && vm.agendaFim) {

                var dataColetaRubricaInicio = moment(vm.agendaInicio, 'DD/MM/YYYY');
                var dataColetaRubricaFim = moment(vm.agendaFim, 'DD/MM/YYYY');

                if (dataColetaRubricaInicio <= dataColetaRubricaFim) {
                    var overlap = _getAgendaOverlap(vm.agendaInicio, vm.agendaFim);
                }

                if (!dataColetaRubricaInicio.isValid() || !dataColetaRubricaFim.isValid()) {
                    vm.registerForm.agendaInicio.$setValidity("invalidDate", dataColetaRubricaInicio.isValid());
                    vm.registerForm.agendaFim.$setValidity("invalidDate", dataColetaRubricaFim.isValid());
                    vm.agendaHasError = true;
                } else {
                    if (!vm.checkRangeAgendas(vm.agendaInicio)) {
                        vm.registerForm.agendaInicio.$setValidity("dateLessNow", false);
                        vm.agendaHasError = true;
                    } else {

                        if (dataColetaRubricaInicio <= dataColetaRubricaFim) {
                            var overlap = _getAgendaOverlap(vm.agendaInicio, vm.agendaFim);

                            if (overlap) {
                                vm.registerForm.agendaInicio.$setValidity("dateOverlap", false);
                                vm.registerForm.agendaFim.$setValidity("dateOverlap", false);
                                vm.agendaHasError = true;
                            } else {
                                vm.coletaRubrica.agendas.push({
                                    dataInicio: dataColetaRubricaInicio.format('YYYY-MM-DD'),
                                    dataFim: dataColetaRubricaFim.format('YYYY-MM-DD'),
                                });
                                vm.agendaInicio = null;
                                vm.agendaFim = null;
                            }
                        } else {
                            vm.registerForm.agendaFim.$setValidity("dateLessThan", false);
                            vm.agendaHasError = true;
                        }
                    }
                }
            } else {
                vm.agendaHasError = true;
                vm.registerForm.agendaInicio.$setValidity("required", false);
                vm.registerForm.agendaFim.$setValidity("required", false);
            }
        };
        
        vm.editarUmaAgenda = function(index){
            var past = new Date();
            past.setFullYear(1500);
            var agendaTemp = {
                id:vm.coletaRubrica.agendas[index].id,
                dataInicio:moment(past, 'DD/MM/YYYY'),
                dataFim:moment(past, 'DD/MM/YYYY')
            };
            vm.agendaEmEdicao = vm.coletaRubrica.agendas[index];
            vm.agendaInicio = vm.dateToString(vm.agendaEmEdicao.dataInicio);
            vm.agendaFim = vm.dateToString(vm.agendaEmEdicao.dataFim);
            vm.coletaRubrica.agendas[index] = agendaTemp;
        }

        vm.dateToString = function(date){
            var tokens = date.split("-");
            var result = "";
            if(tokens.length == 3){
                result = tokens[2]+"/"+tokens[1]+"/"+tokens[0];
            }
            return result;
        }
        vm.atualizaUmaAgenda = function(index){
            var size = vm.coletaRubrica.agendas.length;
            vm.addAgenda();
            var newSize = vm.coletaRubrica.agendas.length;
            if(newSize > size){
                vm.agendaEmEdicao.dataInicio = vm.coletaRubrica.agendas[newSize - 1].dataInicio;
                vm.agendaEmEdicao.dataFim = vm.coletaRubrica.agendas[newSize - 1].dataFim;
                coletaRubricaService.updateAgenda(vm.agendaEmEdicao).then(function(agenda){
                    vm.coletaRubrica.agendas[index] = agenda;
                    vm.agendaEmEdicao = null;
                });
                vm.coletaRubrica.agendas.splice(size,1);
            }
        }

        var _formatDataHora = function(date){
            return date.getFullYear() + "-" + date.getMonth() + "-" + date.getDate() + " " + date.getHours() + ":" + date.getMinutes();
        }

        vm.encerrarAgenda = function(index){
            DialogService.confirm("Você tem certeza que deseja encerrar a avaliação?", function(){
                var agenda = vm.coletaRubrica.agendas[index];
                agenda.dataFimProrrogacao = _formatDataHora(new Date());
                coletaRubricaService.updateAgenda(agenda).then(function(agendaAtualizada){
                    vm.coletaRubrica.agendas[index] = agendaAtualizada;
                });
            });
        }

        vm.reabrirAgenda = function(index){
            vm.agendaProrrogacao = vm.coletaRubrica.agendas[index];
        }
        vm.atualizaProrrogacao = function(index){
            var agendaProrrogacaoFim = moment(vm.agendaProrrogacaoFim + " 23:59", 'DD/MM/YYYY HH:mm');
            if(agendaProrrogacaoFim < new Date()){
                vm.registerForm.agendaProrrogacaoFim.$setValidity("dateLessThan", false);
            }else{
                DialogService.confirm("Você tem certeza que deseja reabrir a coleta?", function(){
                    vm.agendaProrrogacao.dataFimProrrogacao = agendaProrrogacaoFim.format('YYYY-MM-DD HH:mm')
                    var agenda = vm.coletaRubrica.agendas[index];
                    coletaRubricaService.updateAgenda(agenda).then(function(agendaAtualizada){
                        vm.coletaRubrica.agendas[index] = agendaAtualizada;
                    });
                    vm.agendaProrrogacao = null;
                });
            }
            
        }
        vm.deleteAgenda = function(index) {
            var agendas = [];
            angular.copy(vm.coletaRubrica.agendas, agendas);
            var deleted = agendas.splice(index, 1);
            if (vm.checkRangeAgendas(deleted[0].dataInicio)) {
                if (deleted.length && deleted[0].id) {
                    if (!vm.coletaRubrica.agendasExcluidas) {
                        vm.coletaRubrica.agendasExcluidas = [];
                    }
                    vm.coletaRubrica.agendasExcluidas.push({ id: deleted[0].id });
                }
                vm.coletaRubrica.agendas.splice(index, 1);
            } else {
                DialogService.alert("Não é possível excluir agendamentos em vigência ou anteriores à data atual.");
            }
        };

        var _validateForm = function() {
            var hasNewItems = is.existy(vm.coletaRubrica.itensCriados) && is.not.empty(vm.coletaRubrica.itensCriados);
            var hasUpdatedItems = is.existy(vm.coletaRubrica.itensAlterados) && is.not.empty(vm.coletaRubrica.itensAlterados);
            var hasOldItems = is.existy(vm.coletaRubrica.itens) && is.not.empty(vm.coletaRubrica.itens);

            if (!hasNewItems && !hasOldItems && !hasUpdatedItems) {
                toastService.error("Pelo menos um item de coletaRubrica deve ser incluído.");
                return false;
            }

            if (vm.coletaRubrica.tipoColeta && vm.coletaRubrica.tipoColeta.key == 'A') {
                if (vm.coletaRubrica.agendas && vm.coletaRubrica.agendas.length > 0) {
                    return vm.registerForm.$valid;
                } else {
                    vm.registerForm.$setValidity("noAgenda", false);
                    toastService.error("A coleta deve ter um agendamento válido.");
                    return false;
                }
            } else {
                return vm.registerForm.$valid;
            }
        };

        vm.save = function() {
            vm.setDisciplinas();
            vm.setProfessores();
            // TODO Aqui é um dos dois únicos momentos em que vm.coletaRubrica.agendasCriadas é referenciado!
            if (vm.coletaRubrica.agendas.length > 0 || (vm.coletaRubrica.agendasCriadas && vm.coletaRubrica.agendasCriadas.length > 0)) {
                vm.registerForm.agendaInicio.$setValidity("required", true);
                vm.registerForm.agendaFim.$setValidity("required", true);
            }
            var agendas = vm.coletaRubrica.agendas;
            var agendasCriadas = vm.coletaRubrica.agendasCriadas;
            if (_validateForm()) {
                coletaRubricaService.save(vm.coletaRubrica)
                    .then(function(coletaRubrica) {
                        $scope.$emit(APP_EVENT.COLETA_RUBRICA.SAVED);

                        if (!vm.salvarContinuar) {
                            vm.goBack();
                        } else {
                            _init();
                            //Irá enviar notificação para limpar os itens selecionados
                            $rootScope.$broadcast(APP_EVENT.GENERIC.CLEAR_ITEMS_SELECTED)
                            vm.registerForm.$setPristine();
                            $state.go(APP_STATE.COLETA_RUBRICA.EDIT, { id: coletaRubrica.id, editMode: true });
                        }
                    }, function(response) {
                        toastService.error({
                            status: response.status,
                            message: response.data.message
                        });
                        vm.coletaRubrica.agendas = agendas;
                    });
            }
        };

        vm.saveItems = function() {
            if (vm.coletaRubrica.id) {
                coletaRubricaService.saveItens(vm.coletaRubrica)
                    .then(function(savedItens) {
                        vm.coletaRubrica.itensCriados = [];
                        vm.coletaRubrica.itens = vm.coletaRubrica.itens.concat(savedItens);
                        _saveOrUpdateItensMessages(true);

                    }, function(response) {
                        _saveOrUpdateItensMessages(false, response);
                    });
            } else {
                if (_validateForm()) {
                    coletaRubricaService.save(vm.coletaRubrica)
                        .then(function(coletaRubrica) {
                            vm.coletaRubrica = coletaRubrica;
                            _setOrCreateLists();
                            _saveOrUpdateItensMessages(true);

                        }, function(response) {
                            _saveOrUpdateItensMessages(false, response);
                        });
                } else {
                    vm.registerForm.$setSubmitted(true);
                }
            }
        };

        vm.updateItems = function() {
            coletaRubricaService.updateItens(vm.coletaRubrica)
                .then(function(itens) {
                    vm.coletaRubrica.itensAlterados = [];
                    vm.coletaRubrica.itensExcluidos = [];
                    vm.coletaRubrica.itens = itens;

                    _saveOrUpdateItensMessages(true);

                }, function(response) {
                    _saveOrUpdateItensMessages(false, response);
                });
        };

        vm.cancel = function() {
            if (vm.refreshSearch) {
                $scope.$emit(APP_EVENT.COLETA_RUBRICA.REFRESH);
            }

            vm.goBack();
        };

        vm.checkMaxDias = function(field) {
            if (field.$modelValue) {
                if (field.$modelValue > vm.MAX_DAYS) {
                    field.$setValidity("maxDaysSize", false);
                } else {
                    field.$setValidity("maxDaysSize", true);
                }
            } else {
                field.$setValidity("maxDaysSize", true);
            }
        };

        vm.clone = function() {
            var cloned = angular.copy(vm.coletaRubrica);
            delete cloned.id;
            delete cloned.$promise;
            delete cloned.$resod;

            cloned.itensCriados = _.map(cloned.itens, function(value) {
                delete value.id;
                return value;
            });

            cloned.agendas = _.map(cloned.agendas, function(value) {
                delete value.id;
                return value;
            });

            cloned.itens = [];
            cloned.itensExcluidos = [];
            cloned.itensAlterados = [];
            cloned.agendasCriadas = [];
            cloned.agendasExcluidas = [];

            $state.go(APP_STATE.COLETA_RUBRICA.NEW, { coletaRubrica: cloned });
        };

        vm.preview = function(item) {
            if (item.formulario) {
                formularioService.findItensAtivosById(item.formulario.id).then(function(itens) {
                    dialogService.modal(MODAL_TEMPLATE, function($uibModalInstance) {
                        var _vm = this;
                        _vm.itens = itens;
                        _vm.tipoFormulario = item.formulario.tipo.key;
                        _vm.modalTitle = item.formulario.nome;

                        _vm.cancel = function() {
                            $uibModalInstance.dismiss('cancel');
                        };
                    }, function() {});

                }, function(response) {
                    toastService.error({
                        status: response.status,
                        message: response.data.message
                    });
                });
            }
        };

        var _refreshGranularidade = function() {
            if (vm.coletaRubricaAnonima) {
                vm.granularidades = [COLETA_RUBRICA_ANONIMA_DEFAULT_FIELDS.GRANULARIDADE];
            } else {
                vm.granularidades = _granularidadesNaoAnonima;
            }
        };

        var _refreshFormularios = function() {
            vm.modalDependencies.formularios = (vm.coletaRubricaAnonima) ? _formulariosAnonimos : _formularios;
        };

        var _refreshGranularidadeAndForms = function() {
            _refreshGranularidade();
            _refreshFormularios();
        };

        var _deleteAllForms = function() {
            if (vm.coletaRubrica.itens) {
                var itensExcluidosTemp = _.filter(vm.coletaRubrica.itens, function(item) {
                    return item.id;
                });

                vm.coletaRubrica.itensExcluidos = itensExcluidosTemp.concat(vm.coletaRubrica.itensExcluidos);
            }

            vm.coletaRubrica.itens = [];
            vm.coletaRubrica.itensCriados = [];
            vm.coletaRubrica.itensAlterados = [];
        };

        var _configColetaRubricaByTipoAcesso = function() {
            vm.coletaRubricaAnonima = _isColetaRubricaAnonima();
            _refreshGranularidadeAndForms();

            if (vm.coletaRubricaAnonima) {
                vm.coletaRubrica.tipoAbrangencia = COLETA_RUBRICA_ANONIMA_DEFAULT_FIELDS.TIPO_ABRANGENCIA;
                vm.coletaRubrica.granularidade = COLETA_RUBRICA_ANONIMA_DEFAULT_FIELDS.GRANULARIDADE;
                vm.coletaRubrica.tipoColeta = COLETA_RUBRICA_ANONIMA_DEFAULT_FIELDS.TIPO_COLETA_RUBRICA;
            } else {
                vm.coletaRubrica.tipoAbrangencia = null;
                vm.coletaRubrica.granularidade = null;
                vm.coletaRubrica.tipoColeta = null;
            }

            delete vm.coletaRubrica.periodicidade;
            vm.coletaRubrica.controlaPrazo = false;
            vm.coletaRubrica.controlaAlteracao = false;

            vm.deleteDiasPrazo();
            vm.deleteDiasAlteracao();
            _deleteAllAgendas();
            _deleteAllForms();

            _oldTipoAcesso = vm.coletaRubrica.tipoAcesso;
        };

        var _hasItensColetaRubrica = function() {
            var _hasOldItens = is.existy(vm.coletaRubrica.itens) && is.not.empty(vm.coletaRubrica.itens);
            var _hasNewItens = is.existy(vm.coletaRubrica.itensCriados) && is.not.empty(vm.coletaRubrica.itensCriados);

            return _hasNewItens || _hasOldItens;
        };

        var _dependentFieldsWasFilled = function() {
             var _tipoAbrangenciaFilled = is.existy(vm.coletaRubrica.tipoAbrangencia);
             var _granularidadeFilled = is.existy(vm.coletaRubrica.granularidade);
             var _tipoColetaFilled = is.existy(vm.coletaRubrica.tipoColeta);
             var _itensColetaRubricaFilled = _hasItensColetaRubrica();
                
            return _tipoAbrangenciaFilled || _granularidadeFilled || _tipoColetaFilled || _itensColetaRubricaFilled;
        };


        $scope.$on("$destroy", function() {
            _agendaWatcher();
        });

        (function() {
            _init();
        })();

    }

}());
