(function () {
    'use strict';

    angular.module('app.usuario').service('usuarioService', UsuarioService);
    UsuarioService.$inject = ['Usuario', 'APP_PROPERTY', 'fileService', '$q', 'HTTP_STATUS_CODE'];

    function UsuarioService(Usuario, APP_PROPERTY, fileService, $q, HTTP_STATUS_CODE) {

        this.filter = function (filter) {
            return Usuario.filter(filter).$promise;
        };
        this.byTurma = function (filter) {
            return Usuario.byTurma(filter).$promise;
        };
        this.byEscola = function (filter) {
            return Usuario.byEscola(filter).$promise;
        };

        this.UsuarioesByEscola = function (filter) {
            return Usuario.UsuarioesByEscola(filter).$promise;
        };

        this.UsuarioByTurmaId = function (idTurma) {
            return Usuario.UsuarioByTurmaId({ idTurma: idTurma });
        }

        this.UsuarioByTurmaAndDisciplinaId = function (idTurma, disciplinas) {
            return Usuario.UsuarioByTurmaAndDisciplinaId({ idTurma: idTurma }, disciplinas).$promise;
        }

        this.get = function (id) {
            return Usuario.get({ id: id }).$promise;
        };

        this.delete = function (id) {
            return Usuario.delete({ id: id }).$promise;
        };

        this.findAtivos = function () {
            return Usuario.findByStatus({ situacao: 'ATIVO' }).$promise;
        };

        this.filterAtivos = function (filter) {
            var newFilter = angular.copy(filter);
            newFilter.situacao = 'ATIVO';

            return Usuario.findByStatus(newFilter).$promise;
        };

        this.removeOldValuesFromAtributo = function (dados) {
            if (is.existy(dados) && is.not.empty(dados)) {
                var newData = angular.copy(dados);
                delete newData[0].opcaoItem;
                delete newData[0].valueOpcaoItemResposta;
                delete newData[0].valueNumericResposta;
                delete newData[0].valueDateResposta;
                delete newData[0].valueTimeResposta;
                delete newData[0].valueDateTimeResposta;
                delete newData[0].linha;
                delete newData[0].paragrafo;

                return newData;
            } else {
                return [];
            }
        };

        this._normalizeAtributosToServer = function (usuario) {

            var atributosEntidadesAlteradosDTO = _.filter(usuario.atributosEntidadesDTO, function (item) {
                return item._updated;
            });

            usuario.atributosEntidadesAlteradosDTO = atributosEntidadesAlteradosDTO;

            var atributosEntidadesIncluidosDTO = _.filter(usuario.atributosEntidadesDTO, function (item) {
                return is.not.existy(item.id);
            });

            usuario.atributosEntidadesIncluidosDTO = atributosEntidadesIncluidosDTO;
        };

        this.existeUsuario = function (idPessoa) {
            return Usuario.existeUsuario({ idPessoa: idPessoa }).$promise;
        };

        this.criarUsuario = function (idPessoa) {
            return Usuario.criarUsuario({ idPessoa: idPessoa }).$promise;
        }

        this.import = function (file) {
            var url = APP_PROPERTY.URL + '/usuarios/importar/arquivo_excel';
            return $q(function (resolve, reject) {
                fileService.uploadAsArrayBuffer(url, file)
                    .then(function (response) {
                        resolve(response);
                    })
                    .catch(function (error) {
                        var customError = {};
                        switch (error.status) {
                            case HTTP_STATUS_CODE.PRECONDITION_FAILED:
                                customError.messages = error.data;
                                break;
                            case HTTP_STATUS_CODE.BAD_REQUEST:
                                customError.formConstraints = error.data;
                                break;
                            default:
                                customError.messages = [error.data.message];
                        }
                        reject(customError);
                    });
            });
        };

        this.exportByUfAndMunicipioAndEscola = function (idAnoLetivo, idPrograma, idUf, idMunicipio, idEscola) {
            return $q(function (resolve, reject) {
                Usuario.exportByUfAndMunicipioAndEscola({ idAnoLetivo: idAnoLetivo, idPrograma: idPrograma, idUf: idUf, idMunicipio: idMunicipio, idEscola: idEscola }).$promise
                    .then(function (file) {
                        fileService.download(file);
                        resolve();
                    })
                    .catch(function (error) {
                        reject(error);
                    });
            });
        };

        this.exportByUfAndMunicipio = function (idAnoLetivo, idPrograma, idUf, idMunicipio) {
            return $q(function (resolve, reject) {
                Usuario.exportByUfAndMunicipio({ idAnoLetivo: idAnoLetivo, idPrograma: idPrograma, idUf: idUf, idMunicipio: idMunicipio }).$promise
                    .then(function (file) {
                        fileService.download(file);
                        resolve();
                    })
                    .catch(function (error) {
                        reject(error);
                    });
            });
        };

        this.exportByUf = function (idAnoLetivo, idPrograma, idUf) {
            return $q(function (resolve, reject) {
                Usuario.exportByUf({ idAnoLetivo: idAnoLetivo, idPrograma: idPrograma, idUf: idUf }).$promise
                    .then(function (file) {
                        fileService.download(file);
                        resolve();
                    })
                    .catch(function (error) {
                        reject(error);
                    });
            });
        };

        this.export = function (idAnoLetivo, idPrograma) {
            return $q(function (resolve, reject) {
                Usuario.export({ idAnoLetivo: idAnoLetivo, idPrograma: idPrograma }).$promise
                    .then(function (file) {
                        fileService.download(file);
                        resolve();
                    })
                    .catch(function (error) {
                        reject(error);
                    });
            });
        };

    }

}());
