(function() {
    'use strict';

    angular.module('app.usuario').controller('UsuarioController', UsuarioController);
    UsuarioController.$inject = ['APP_STATE', '$state', 'Usuario', 'dialogService', 'toastService',
        'DialogService', '$scope', 'APP_EVENT', 'SecurityManagerFactory'
    ];

    function UsuarioController(APP_STATE, $state, Usuario, dialogService, toastService,
        DialogService, $scope, APP_EVENT, SecurityManagerFactory) {

        var vm = this;
        //
        vm.SEARCH_BY = {
            CODE: {            
                field: "id",
                            placeholder: "código",
                            mask: "9999999999",
                            restrict: "[^0-9]",
                minLength: 1,
                            maxLength: 10        
            },
                    NAME: {             field: "nome",             placeholder: "nome",             mask: "*",             restrict: "%",             maxLength: 50,             minLength: 3,          },
            CPF: {
                field: "cpf",
                placeholder: "CPF",
                mask: "999.999.999-99",
                maxLength: 14,
                minLength: 14
            },
            EMAIL: {
                field: "email",
                placeholder: "e-mail",
                mask: "*",
                restrict: "%",
                maxLength: 100,
                minLength: 1
            },
            LOGIN: {
                field: "login",
                placeholder: "login",
                mask: "*",
                restrict: "%",
                maxLength: 100,
                minLength: 1
            }
        };

        $scope.$on(APP_EVENT.USUARIO.REFRESH, function() {
            vm.search();
        });

        vm.usuarios = [];
        vm.searchValue = "";
        vm.searchType = vm.SEARCH_BY.NAME;
        vm.nomeSearch = '';
        vm.validation = { hasError: false, message: '' };
        vm.notFound = false;

        vm.mascaraCpf = function(valor) {
            return valor.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3\-\$4");
        }

        vm.clearSearchValue = function() {
            vm.searchValue = "";
        };

        vm.goNewPage = function() {
            $state.go(APP_STATE.USUARIO.NEW);
        };

        vm.goEditPage = function(id) {
            $state.go(APP_STATE.USUARIO.EDIT, { id: id });
        };

        vm.goImportTemplatePage = function() {
            $state.go(APP_STATE.USUARIO.IMPORT_TEMPLATE);
        };

        vm.stayPage = function() {
            $state.reload();
        };

        vm.onChangeFilter = function() {
            vm.usuariosSearch.$setPristine();
        }

        vm.usuarioSearchValue = function() {
            vm.searchValue = "";
        };

        vm.validTrue = function() {}

        vm.changeStatus = function(usuario, status) {
            var msnConfirm = null;
            var msnSuccess = null;
            var title = null;

            if (status == 'I') {
                title = "Inativar usuário";
                msnConfirm = "Você tem certeza que deseja inativar o usuário (" + usuario.pessoa.nomeCompleto + ")?";
                msnSuccess = "O usuário (" + usuario.pessoa.nomeCompleto + ") foi inativado com sucesso.";
            } else {
                title = "Ativar usuário";
                msnConfirm = "Você tem certeza que deseja ativar o usuário (" + usuario.pessoa.nomeCompleto + ")?";
                msnSuccess = "O usuário (" + usuario.pessoa.nomeCompleto + ") foi ativado com sucesso.";
            }

            DialogService.confirm(msnConfirm, function() {
                Usuario.changeStatus({ id: usuario.id, status: status }).$promise.then(function() {
                    toastService.success(msnSuccess);
                    vm.search();
                }, function(response) {
                    toastService.error({
                        message: response.data.message
                    });
                });
            }, title);
        }

        vm.delete = function(usuario) {
            dialogService.delete().then(function() {
                Usuario.delete({ id: usuario.id }).$promise
                    .then(function() {
                        toastService.success("Registro removido com sucesso.");
                        vm.search();
                    }, function(response) {
                        toastService.error({
                            status: response.status,
                            message: response.data.message
                        });
                    });
            }, function() {});
        };

        vm.search = function() {
            var filter = {};
            vm.usuarios = [];
            vm.searchWasPerformed = false;

            filter[vm.searchType.field] = vm.searchValue;

            if (vm.searchValue) {
                Usuario.filter(filter).$promise
                    .then(function(data) {
                        vm.usuarios = angular.copy(data);
                        vm.usuariosTotal = vm.usuarios.length;

                        if (vm.usuarios != 0) {
                            vm.notFound = false;
                        } else {
                            vm.notFound = true;
                        }
                    }, function(error) {
                        vm.usuarios = [];
                        vm.notFound = true;
                    }).finally(function() {
                        vm.searchWasPerformed = true;
                        vm.usuariosSearch.$setPristine();
                    });
            }
        };

        (function() {
            vm.hasPermissionView = SecurityManagerFactory.hasPermission('USU:VI');
            vm.searchWasPerformed = false;

            $scope.$on(APP_EVENT.USUARIO.REFRESH, function() {
                if (vm.usuariosSearch) {
                    vm.usuariosSearch.$setPristine();
                }
                vm.search();
            });

        }());

    }

}());
