(function () {
  'use strict';

  angular.module('app.core').constant('HTTP_STATUS_CODE', {
    OK: 200,
    ACCEPTED: 202,
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    METHOD_NOT_ALLOWED: 405,
    PRECONDITION_FAILED: 412,
    INTERNAL_SERVER_ERROR: 500
  });

}());
