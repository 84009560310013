(function() {
    'use strict';

    angular.module('app.core').directive("iasRegexRestrict", IasRegexRestrict);
    IasRegexRestrict.$inject = [];

    function IasRegexRestrict() {
        return {
            require: 'ngModel',
            restrict: "A",
            link: function(scope, element, attrs, controller) {
                var regex = attrs.iasRegexRestrict || "[^\\w\\s]";

                controller.$parsers.push(function(inputValue) {
                    if (inputValue == undefined) {
                        return '';
                    } else {
                        var cleanInputValue = inputValue.replace(new RegExp(regex, "gi"), '');

                        if (cleanInputValue != inputValue) {
                            controller.$setViewValue(cleanInputValue);
                            controller.$render();
                        }

                        return cleanInputValue;
                    }
                });
            }
        };
    }

})();