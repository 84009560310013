(function () {
    'use strict';

    angular.module('app.turmas').controller('TurmasFormController', TurmasFormController);
    TurmasFormController.$inject = ['APP_STATE', 'TABLE_FIELDS', 'APP_EVENT', 'COMMON_STATUS_LIST',
        '$state', '$scope', 'dialogService', 'toastService', 'Flash', 'ufService', 'municipiosService',
        'DialogService', 'atributoService', 'turmasService', '$filter', 'periodoLetivoService', 'etapaService',
        'modalidadeEnsinoService', 'escolasService', 'calendarioService', 'programasService',
        'dateUtilService', 'professorService', 'disciplinasService', 'SEARCH_BY_FIELD', 'alunoService',
        'Opcao', 'rubricasService', 'formularioService', 'areaConhecimentoService'
    ];

    function TurmasFormController(APP_STATE, TABLE_FIELDS, APP_EVENT, COMMON_STATUS_LIST,
        $state, $scope, dialogService, toastService, Flash, ufService, municipiosService,
        DialogService, atributoService, turmasService, $filter, periodoLetivoService, etapaService,
        modalidadeEnsinoService, escolasService, calendarioService, programasService,
        dateUtilService, professorService, disciplinasService, SEARCH_BY_FIELD, alunoService,
        Opcao, rubricasService, formularioService, areaConhecimentoService) {

        var vm = this;

        ufService.withPermissionFilter({}).then(function (response) {
            vm.ufs = response;
        });
        vm.modalidadesEnsinos = modalidadeEnsinoService.ativos();
        vm.municipios = [];
        vm.etapas = etapaService.ativos();

        turmasService.turnos().then(function (turnos) {
            vm.turnos = turnos;
        }, function () {
            vm.turnos = [];
        });

        turmasService.atendimentos().then(function (atendimentos) {
            vm.atendimentos = atendimentos;
        }, function () {
            vm.atendimentos = [];
        });

        turmasService.funcoesProfessor().then(function (funcoesProfessor) {
            vm.funcoesProfessor = funcoesProfessor;
        }, function () {
            vm.funcoesProfessor = [];
        });

        turmasService.tiposContratacaoProfessor().then(function (tiposContratacaoProfessor) {
            vm.tiposContratacaoProfessor = tiposContratacaoProfessor;
        }, function () {
            vm.tiposContratacaoProfessor = [];
        });


        vm.changeItems = function () {
            try {


                if (vm.rubricasEscolhidas) {
                    vm.labelsRubricas = {
                        selectAll: "Selecionar todos",
                        unselectAll: "Desmarcar",
                        itemsSelected: "selecionados",
                        search: "Pesquisar",
                        select: vm.rubricasEscolhidas == undefined || vm.rubricasEscolhidas.length == undefined ? "0 Selecionadas" : vm.rubricasEscolhidas.length > 1 ? vm.rubricasEscolhidas.length + " Selecionadas" : vm.rubricasEscolhidas.length + " Selecionada"
                    };
                }
                if (vm.formulariosEscolhidas) {
                    vm.labelsFormularios = {
                        selectAll: "Selecionar todos",
                        unselectAll: "Desmarcar",
                        itemsSelected: "selecionados",
                        search: "Pesquisar",
                        select: vm.formulariosEscolhidas == undefined || vm.formulariosEscolhidas.length == undefined ? "0 Selecionados" : vm.formulariosEscolhidas.length > 1 ? vm.formulariosEscolhidas.length + " Selecionados" : vm.formulariosEscolhidas.length + " Selecionado"
                    };
                }

            } catch (error) {
                console.log(error);
            }
        }

        $scope.$watch('vm.rubricasEscolhidas', function (newValue, oldValue) {
            vm.changeItems();
        });
        $scope.$watch('vm.formulariosEscolhidas', function (newValue, oldValue) {
            vm.changeItems();
        });

        vm.periodosLetivos = periodoLetivoService.ativos();
        vm.calendarios = [];
        vm.atributos = [];
        vm.atributosIncluidos = [];
        vm.atributosAlterados = [];
        vm.atributosExcluidos = [];
        vm.programas = [];
        vm.turmasProgramas = [];
        vm.turmasProgramasIncluidos = [];
        vm.turmasProgramasExcluidos = [];
        vm.msnCalendarioPadrao = false;
        vm.msnNaoExisteMeses = false;
        vm.turmasMovimentacaoMensal = [];
        vm.turmasMovimentacaoMensalIncluidas = [];
        vm.turmasMovimentacaoMensalAlteradas = [];
        vm.turmasMovimentacaoMensalExcluidas = [];
        vm.meses = [];
        vm.professores = [];
        vm.filter = {};
        vm.disciplinas = [];
        vm.turmaProfessor = {};
        vm.turmasProfessores = [];
        vm.turmasProfessoresIncluidas = [];
        vm.turmasProfessoresExcluidas = [];
        vm.habilitaBotaoProfessor = false;
        vm.professorDiciplinaDuplicado = false;
        vm.turmaAluno = [];
        vm.turmaAlunoIncluidos = [];
        vm.turmaAlunoExcluidos = [];
        vm.rubricasEscolhidas = [];
        vm.rubricas = [];
        vm.formulariosEscolhidas = [];
        vm.formularios = [];
        vm.areaConhecimento = [];
        vm.selectedAllAtributos = false;

        areaConhecimentoService.ativos().$promise.then(function (data) {
            vm.areaConhecimento = data;
        });

        vm.calendariosEscola = function (escola) {
            if (escola && vm.turma.periodoLetivo && vm.turma.calendarioEscola == 'false') {
                calendarioService.porEscolaIdSemPadrao(escola.id).then(function (response) {
                    vm.calendarios = $filter("filter")(response, function (value) {
                        return value.periodoLetivo.anoLetivo == vm.turma.periodoLetivo.anoLetivo;
                    });
                });
            }
        };

        vm.programasEscola = function (escola) {
            if (is.existy(escola)) {
                programasService.porIdEscola(escola.id).$promise.then(function (response) {
                    vm.programas = response;
                    angular.forEach(vm.programas, function (value, index) {
                        (rubricasService.byPrograma(value.id)).$promise.then(function (response) {
                            angular.forEach(response, function (valueR) {

                                let formExiste = false;
                                angular.forEach(vm.rubricas, function (formularioExistente) {
                                    if (formularioExistente.id == valueR.id) {
                                        formExiste = true;
                                    }
                                });
                                if (!formExiste) {
                                    vm.rubricas.push(valueR);
                                }

                            });
                        });
                    });
                });
            }
        };

        vm.programasEscolaLoad = function (escola) {
            if (is.existy(escola)) {
                programasService.porIdEscola(escola.id).$promise.then(function (response) {
                    vm.programas = response;
                });
            }
        };



        vm.formulariosTurma = function (idTurma) {
            formularioService.findByTurma(idTurma).$promise.then(function (response) {
                vm.formulariosEscolhidas = response;
            });
        };

        vm.modalMovimentacaoAluno = function () {
            DialogService.modal(
                'turmas/partials/turmas-form-aluno.html',
                function ($uibModalInstance) {
                    var _vm = this;
                    _vm.SEARCH_BY = SEARCH_BY_FIELD;
                    _vm.searchType = _vm.SEARCH_BY.NAME;
                    _vm.alunos = [];
                    _vm.alunosTotal = 0;
                    _vm.filterAlunoToPaginate = {};
                    _vm.turmaAluno = vm.turmaAluno;
                    _vm.searchWasPerformed = false;
                    _vm.alunosNaoEncontrado = false;

                    var _fecharAdd = function () {
                        _vm.todosAlunosSelecionado = false;
                        _vm.mostrarPequisaAlunos = false;
                        _vm.alunos = [];
                        _vm.searchType = _vm.SEARCH_BY.NAME;
                        _vm.searchValue = null;
                        _vm.formBuscaAluno.$submitted = false;
                    }

                    _vm.incluirAluno = function () {
                        _vm.mostrarPequisaAlunos = true;
                    };

                    _vm.selecionarTodosAlunos = function () {
                        if (_vm.todosAlunosSelecionado == true) {
                            angular.forEach(_vm.alunos, function (value) {
                                value.selected = true;
                            });
                        } else {
                            angular.forEach(_vm.alunos, function (value) {
                                value.selected = false;
                            });
                        }
                    };

                    _vm.selecionarTodosAlunosTurma = function () {
                        if (_vm.todosAlunosTurmaSelecionado == true) {
                            angular.forEach(vm.turmaAluno, function (value) {
                                value.selected = true;
                            });
                        } else {
                            angular.forEach(vm.turmaAluno, function (value) {
                                value.selected = false;
                            });
                        }
                    };

                    _vm.totalAlunosTurmaSelecionados = function () {
                        var totalSelecionados = $filter('filter')(vm.turmaAluno, function (value) {
                            return value.selected == true;
                        });

                        return totalSelecionados.length;
                    };

                    _vm.excluirAlunosTurma = function (alunos) {
                        DialogService.confirm("Você tem certeza que deseja excluir o(s) aluno(s) da turma?", function () {
                            var novosAlunos = [];

                            angular.forEach(alunos, function (value, index) {
                                if (!value.selected) {
                                    novosAlunos.push(value);
                                } else {
                                    if (is.existy(value.id)) {
                                        vm.turmaAlunoExcluidos.push(value);
                                    }
                                }
                            });

                            vm.turmaAluno = novosAlunos;
                            _vm.turmaAluno = vm.turmaAluno;
                            _vm.todosAlunosTurmaSelecionado = false;
                        });
                    };

                    var _verificarExisteAluno = function (aluno) {
                        var alunos = $filter('filter')(vm.turmaAluno, function (value) {
                            return value.aluno.id == aluno.id;
                        });

                        if (alunos.length > 0) {
                            return true;
                        }

                        return false;
                    };

                    _vm.addAluno = function (alunos) {
                        var turmaAluno = vm.turmaAluno;
                        var alunosSelecionados = $filter('filter')(alunos, function (value) {
                            return value.selected;
                        });

                        angular.forEach(alunosSelecionados, function (alunoSelecionado) {
                            var pessoa = alunoSelecionado.pessoa
                            alunoSelecionado.selected = false;

                            if (!_verificarExisteAluno(alunoSelecionado)) {
                                var data = {
                                    added: true,
                                    aluno: {
                                        id: alunoSelecionado.id,
                                        codigoINEP: alunoSelecionado.codigoINEP,
                                        pessoa: {
                                            nomeCompleto: pessoa.nomeCompleto
                                        }
                                    },
                                    situacao: { key: "A" }
                                };

                                vm.turmaAluno.push(data);
                            } else {
                                toastService.error("O aluno " + pessoa.nomeCompleto + " já foi adicionado à turma.", 'flash-modal');
                                return false;
                            }
                        });

                        _fecharAdd();
                        _vm.turmaAluno = vm.turmaAluno;
                    };

                    _vm.clearSearchValue = function () {
                        _vm.searchValue = "";
                        _vm.searchValueModel = "";
                        _vm.currentPage = 1;
                    };

                    _vm.pesquisar = function () {

                        if (_vm.searchValue) {
                            _vm.currentPage = 1;
                            _vm.searchWasPerformed = true;
                            var filter = {};
                            filter[_vm.searchType.field] = _vm.searchValue;
                            _vm.filterAlunoToPaginate = angular.copy(filter);

                            _findAlunosByFilters(filter);
                        }
                    };

                    _vm.cancelar = function () {
                        $uibModalInstance.dismiss('cancel');
                    };

                    _vm.onPageChangeAlunos = function (page, size) {
                        _vm.filterAlunoToPaginate.page = page;
                        _vm.filterAlunoToPaginate.size = size;
                        _findAlunosByFilters(_vm.filterAlunoToPaginate);
                    };

                    var _findAlunosByFilters = function (filter) {
                        _vm.alunosNaoEncontrado = false;

                        alunoService.searchBy(filter)
                            .then(function (result) {
                                _vm.alunos = result.content;
                                _vm.alunosTotal = result.totalElements;
                                _vm.alunosNaoEncontrado = (_vm.alunosTotal == 0);
                            });
                    };
                }
            );
        };

        vm.modalMovimentacao = function () {
            DialogService.modal(
                'turmas/partials/turmas-form-movimentacao.html',
                function ($uibModalInstance) {
                    var _vm = this;
                    _vm.parentController = vm;
                    var matriculaAtual = 0;
                    if (is.not.null(vm.turma.numeroMatriculaInicial) &&
                        is.not.empty(vm.turma.numeroMatriculaInicial) &&
                        is.not.undefined(vm.turma.numeroMatriculaInicial)) {
                        matriculaAtual = parseInt(vm.turma.numeroMatriculaInicial);
                    }

                    var _formatarReferenciaLista = function (turmasMovimentacaoMensal) {
                        angular.forEach(turmasMovimentacaoMensal, function (value, index) {
                            var referencia = String(value.referencia);
                            var ano = referencia.substr(0, 4);
                            var mes = referencia.substr(4);
                            mes = dateUtilService.getMonth(mes);

                            value.referenciaFormatada = mes.short + "/" + ano;
                        });

                        return turmasMovimentacaoMensal;
                    };

                    _vm.status = { open: false };
                    _vm.periodosLetivos = vm.periodosLetivos;
                    _vm.disabledReferencia = false;
                    _vm.disabledMatriculaAtual = false; // TODO A variável não está sendo mais utilizada para controlar o estado do campo na view, remove na primeira oportunidade.
                    _vm.movimentacoesClear = false;
                    _vm.movimentacoesFields = TABLE_FIELDS.MOVIMENTACOES_MENSAIS;
                    _vm.movimentacoes = _formatarReferenciaLista(vm.turmasMovimentacaoMensal);
                    _vm.movimentacoesIncluidas = vm.turmasMovimentacaoMensalIncluidas;
                    _vm.movimentacoesExcluidas = vm.turmasMovimentacaoMensalExcluidas;
                    _vm.movimentacoesAlteradas = vm.turmasMovimentacaoMensalAlteradas;
                    _vm.numeroMatriculaInicial = vm.turma.numeroMatriculaInicial;
                    _vm.meses = dateUtilService.getMonths();
                    _vm.numeroMatriculaAnterior = null;

                    _vm.showFormAdd = function (item) {
                        if (is.existy(item)) {
                            if (!_podeEditar(item)) {
                                toastService.error("Você só pode editar a última movimentação.", 'flash-modal');
                                _vm.status.open = false;
                                return false;
                            }

                            var referencia = String(item.referencia);

                            _vm.numeroMatriculaAnterior = _calculateRegistrationPreviousFeference(_vm.movimentacoes, referencia);
                            item.editing = true;
                            _vm.editMode = true;
                            _vm.disabledReferencia = true;
                            _vm.movimentacao = item;
                            _buildMovimentYearAndMonth(_vm.movimentacao);
                        } else {
                            var periodoLetivo = null;
                            if (vm.turma.periodoLetivo)
                                periodoLetivo = vm.turma.periodoLetivo;

                            _vm.disabledReferencia = false;
                            _vm.movimentacao = {
                                matriculaAtual: _ultimaMatricula(),
                                ano: periodoLetivo
                            };
                            _vm.numeroMatriculaAnterior = null;
                        }

                        _vm.status.open = true;
                    };

                    _vm.movimentacoesChange = function (selectedItems) {
                        _clearMovimentacaoForm();
                    };

                    var _ultimaMovimentacao = function () {
                        var totalMovimentacoes = parseInt(_vm.movimentacoes.length);
                        var ultimaMovimentacao = null;

                        if (totalMovimentacoes > 0) {
                            ultimaMovimentacao = _vm.movimentacoes[totalMovimentacoes - 1];
                        }

                        return ultimaMovimentacao;
                    };

                    var _podeEditar = function (item) {
                        var ultimaMovimentacao = _ultimaMovimentacao();

                        if (ultimaMovimentacao.referencia == item.referencia) {
                            return true;
                        }

                        return false;
                    };

                    var _ultimaMatricula = function () {
                        var ultimaMatricula = matriculaAtual;
                        var ultimaMovimentacao = _ultimaMovimentacao();

                        if (ultimaMovimentacao) {
                            ultimaMatricula = ultimaMovimentacao.matriculaAtual;
                            _vm.disabledMatriculaAtual = true;
                        }

                        if (ultimaMatricula > 0) {
                            _vm.disabledMatriculaAtual = true;
                        } else {
                            ultimaMatricula = "";
                        }

                        return ultimaMatricula;
                    };

                    _vm.calcularMatriculaAtual = function (movimentacao) {
                        toastService.clear();

                        var recebida = 0;
                        var expedida = 0;
                        var abandono = 0;
                        var anterior = 0;
                        var valorInicial = 0;

                        if (movimentacao.transferenciaRecebida)
                            recebida = parseInt(movimentacao.transferenciaRecebida);

                        if (movimentacao.transferenciaExpedida)
                            expedida = parseInt(movimentacao.transferenciaExpedida);

                        if (movimentacao.abandono)
                            abandono = parseInt(movimentacao.abandono);

                        if (_vm.numeroMatriculaAnterior)
                            anterior = parseInt(_vm.numeroMatriculaAnterior);

                        valorInicial = (anterior > 0) ? anterior : matriculaAtual;

                        var total = ((valorInicial + recebida) - expedida) - abandono;

                        if (total < 0) {
                            toastService.error("A matrícula atual não pode ficar negativa.", 'flash-modal');
                        }

                        movimentacao.matriculaAtual = total;
                    };

                    var _movimentacaoValida = function (movimentacao) {
                        toastService.clear();
                        var total = parseInt(movimentacao.matriculaAtual);

                        if (total < 0) {
                            toastService.error("A matrícula atual não pode ficar negativa.", 'flash-modal');
                            return false;
                        }

                        if (total > 255) {
                            toastService.error("A matrícula atual não pode ficar maior que 255.", 'flash-modal');
                            return false;
                        }

                        var jaTemMovimentacao = false;
                        if (is.not.existy(movimentacao.id)) {
                            angular.forEach(_vm.movimentacoes, function (value, index) {
                                if (value.$$hashKey != movimentacao.$$hashKey && value.referencia == movimentacao.referencia) {
                                    jaTemMovimentacao = true;
                                }
                            });
                        }
                        if (jaTemMovimentacao) {
                            toastService.error("Você não pode adicionar mais de uma movimentação por mês.", 'flash-modal');
                            return false;
                        }

                        var existeMovimentacaoPosterior = false;
                        if (_vm.movimentacoes != null) {
                            for (var i = 0; i < _vm.movimentacoes.length; i++) {
                                if (movimentacao.referencia < _vm.movimentacoes[i].referencia) {
                                    existeMovimentacaoPosterior = true;
                                    break;
                                }
                            };
                        }

                        if (existeMovimentacaoPosterior) {
                            toastService.error("Não é possível inserir movimentação para essa referência, pois já há movimentações posteriores cadastradas.", 'flash-modal');
                            return false;
                        }

                        return true;
                    };

                    _vm.save = function (movimentacao) {
                        if (_vm.movimentacaoForm.$valid) {
                            _buildMovimentReference(movimentacao);

                            if (_movimentacaoValida(movimentacao)) {
                                if (!movimentacao.editing) {
                                    movimentacao.situacao = { key: "A" };
                                    var novaMovimentacao = angular.copy(movimentacao);

                                    _vm.movimentacoes.push(novaMovimentacao);
                                    _vm.movimentacoesIncluidas.push(novaMovimentacao);
                                } else {
                                    if (movimentacao.id) {
                                        // Se a movimentação já foi alterada anteriormente, atualiza o objeto, caso contrário inclui.
                                        var index = _.findIndex(_vm.movimentacoesAlteradas, function (updated) {
                                            return updated.id == movimentacao.id;
                                        });
                                        if (index == -1) {
                                            // A movimentação ainda não foi alterada.
                                            _vm.movimentacoesAlteradas.push(angular.copy(movimentacao));
                                        } else {
                                            // A movimentação já foi alterada.
                                            _vm.movimentacoesAlteradas[index] = angular.copy(movimentacao);
                                        }
                                    }
                                }

                                _vm.movimentacoes = _orderMovimentsByReference(_vm.movimentacoes);
                                _vm.parentController.turmasMovimentacaoMensal = _vm.movimentacoes;
                                _clearMovimentacaoForm();
                                _buildAllMoviments(_vm.movimentacoes, _vm.movimentacoesIncluidas, _vm.movimentacoesAlteradas, _vm.movimentacoesExcluidas);
                            }
                        }
                    };

                    var _clearMovimentacaoForm = function () {
                        _vm.movimentacao = {};
                        _vm.status.open = false;
                        _vm.editMode = false;
                        _vm.disabledReferencia = false;
                        toastService.clear();
                    };

                    _vm.close = function () {
                        _clearMovimentacaoForm();
                        _buildAllMoviments(_vm.movimentacoes, _vm.movimentacoesIncluidas, _vm.movimentacoesAlteradas, _vm.movimentacoesExcluidas);
                        $uibModalInstance.dismiss('cancel');
                    };

                    _vm.cancel = function () {
                        _clearMovimentacaoForm();
                    };

                    var _orderMovimentsByReference = function (movimentacoes) {
                        return _.sortBy(movimentacoes, function (element) { return element.referencia; });
                    };

                    var _calculateRegistrationPreviousFeference = function (movements, reference) {
                        var result = null;
                        if (movements.length > 0) {
                            var references = _orderMovimentsByReference(movements);
                            _.forEach(references, function (element) {
                                if (element.referencia < reference) {
                                    result = element.matriculaAtual;
                                }
                            });
                        }
                        return result;
                    };

                    _vm.changeReference = function () {
                        if (is.existy(_vm.movimentacao.ano) && is.existy(_vm.movimentacao.mes)) {
                            var reference = _vm.movimentacao.ano.anoLetivo + '' + _vm.movimentacao.mes.key;
                            _vm.numeroMatriculaAnterior = _calculateRegistrationPreviousFeference(_vm.movimentacoes, reference);
                            _vm.calcularMatriculaAtual(_vm.movimentacao);
                        }
                    };

                    var _buildMovimentReference = function (moviment) {
                        var formatedReference = dateUtilService.getMonth(moviment.mes.key);
                        var year = moviment.ano.anoLetivo;
                        var month = moviment.mes.key;

                        if (is.not.existy(moviment.id) || is.not.existy(moviment.referencia)) {
                            moviment.referencia = year + month;
                        }

                        if (is.not.existy(moviment.id) || is.not.existy(moviment.referenciaFormatada)) {
                            moviment.referenciaFormatada = formatedReference.short + "/" + year;
                        }
                    };

                    var _buildMovimentYearAndMonth = function (moviment) {
                        if (is.existy(moviment.referencia) && is.not.empty(moviment.referencia)) {
                            var year = null;
                            var month = null;
                            if (is.integer(moviment.referencia)) {
                                year = parseInt(moviment.referencia / 100);
                                month = moviment.referencia - year * 100;
                                if (month >= 10) {
                                    month = String(month);
                                } else {
                                    month = '0' + month;
                                }
                            } else if (is.alphaNumeric(moviment.referencia)) {
                                year = parseInt(moviment.referencia.substr(0, 4));
                                month = moviment.referencia.substr(4, 2);
                            }

                            if (is.existy(year)) {
                                moviment.ano = _.find(_vm.periodosLetivos, function (element) {
                                    return element.anoLetivo == year;
                                });
                            }

                            if (is.existy(month)) {
                                moviment.mes = _.find(_vm.meses, function (element) {
                                    return element.key == month;
                                });
                            }
                        }
                    };

                    var _buildAllMoviments = function (moviments, createdMoviments, updatedMoviments, deletedMoviments) {
                        // Removendo a movimentação que está na lista para excluir mas que ainda permanece na movimentação corrente da turma (a lista de movimentações que o usuário visualiza).
                        if (deletedMoviments.length > 0) {
                            var elements = _.filter(moviments, function (element) {
                                return is.existy(element.id);
                            });
                            _.each(elements, function (element) {
                                var index = _.findIndex(deletedMoviments, function (deleted) {
                                    return element.id == deleted.id;
                                });
                                if (index > -1) {
                                    deletedMoviments.splice(index, 1);
                                }
                            });
                        }

                        // Removendo a movimentação que está na lista para incluir mas que não permanece na movimentação corrente da turma (a lista de movimentações que o usuário visualiza).
                        if (createdMoviments.length > 0) {
                            var elements = _.filter(moviments, function (element) {
                                return is.not.existy(element.id);
                            });
                            var indexes = [];
                            _.each(createdMoviments, function (created, i) {
                                var index = _.findIndex(elements, function (element) {
                                    return element.referencia == created.referencia;
                                });
                                if (index == -1) {
                                    indexes.push(i);
                                }
                            });
                            _.each(indexes, function (index) {
                                createdMoviments.splice(index, 1);
                            });
                        }

                        // Removendo a movimentação que está na lista de modificadas mas que não permanece na movimentação corrente da turma (a lista de movimentação que o usuário visualiza).
                        if (updatedMoviments.length > 0) {
                            var elements = _.filter(moviments, function (element) {
                                return is.existy(element.id);
                            });

                            var indexes = [];
                            _.each(updatedMoviments, function (updated, i) {
                                var index = _.findIndex(elements, function (element) {
                                    return element.id == updated.id;
                                });
                                if (index == -1) {
                                    indexes.push(i);
                                }
                            });
                            _.each(indexes, function (index) {
                                updatedMoviments.splice(index, 1);
                            });
                        }
                    };
                }
            );
        };

        vm.carregaFormularios = function () {
            vm.formularios = [];
            angular.forEach(vm.rubricasEscolhidas, function (rubrica) {

                if (rubrica !== undefined) {
                    formularioService.findByRubrica(rubrica.id).then(function (responseform) {

                        angular.forEach(responseform, function (formulariosresp) {
                            let formExiste = false;
                            angular.forEach(vm.formularios, function (formularioExistente) {
                                if (formularioExistente.id == formulariosresp.id) {
                                    formExiste = true;
                                }
                            });
                            if (!formExiste) {
                                vm.formularios.push(formulariosresp);
                            }
                        })
                        vm.changeItems();
                    });
                }
            });
        }

        vm.modalAtributos = function () {
            DialogService.modal(
                'turmas/partials/turmas-form-atributo.html',
                function ($uibModalInstance) {
                    var _vm = this;

                    _vm.status = { open: false };
                    _vm.atributosLista = [];
                    _vm.atributos = vm.atributos;
                    _vm.atributosIncluidos = vm.atributosIncluidos;
                    _vm.atributosAlterados = vm.atributosAlterados;
                    _vm.programas = [];
                    _vm.disableSelects = false;
                    angular.forEach(vm.turmasProgramas, function (p) {
                        var programasPermitidos = _.filter(vm.programas, function (programa) {
                            return p.programa.id == programa.id;
                        });

                        if (programasPermitidos.length > 0)
                            _vm.programas.push(p.programa);
                    });

                    _vm.populateAtributos = function (idPrograma) {
                        _vm.atributosLista = [];
                        if (idPrograma == null) {
                            return;
                        }
                        atributoService.porTipoEntidadePrograma('TURMA', idPrograma).then(function (atributos) {
                            var listaExistente = _vm.atributos.filter(obj => obj.programa.id == idPrograma).map(obj => obj.atributo.id);
                            atributos.forEach(obj => {
                                if (listaExistente.includes(obj.id)) return;
                                _vm.atributosLista.push({ atributo: obj });
                            });
                            _vm.atributosLista.forEach(function (attr2) {
                                _vm.setAtributo(attr2.atributo);
                            });
                        });
                    };

                    _vm.atributosPorPrograma = function (idPrograma) {
                        atributoService.porPrograma(idPrograma).then(function (response) {
                            var atributos = [];
                            angular.forEach(response, function (attr) {
                                atributos.push(attr.atributo);
                            });

                            _vm.atributosLista = atributos;
                        }, function (error) {
                            _vm.atributosLista = [];
                        });
                    };

                    _vm.totalAtributosSelecionados = function () {
                        if (undefined !== _vm.atributos) {
                            var totalSelecionados = $filter('filter')(_vm.atributos, function (value) {
                                return value.selected == true;
                            });

                            return totalSelecionados.length;
                        }

                        return 0;
                    };

                    _vm.totalAtributosSelecionadosEdit = function () {
                        if (undefined !== _vm.atributos) {
                            var totalSelecionados = $filter('filter')(_vm.atributos, function (value) {
                                return value.edit == true;
                            });

                            return totalSelecionados.length;
                        }

                        return 0;
                    };

                    _vm.totalAtributosEdicaoSelecionados = function () {
                        if (undefined !== _vm.atributosLista) {
                            var totalSelecionados = $filter('filter')(_vm.atributosLista, function (value) {
                                return value.selected == true;
                            });

                            return totalSelecionados.length;
                        }

                        return 0;
                    };

                    var _clearAll = function () {
                        _vm.atributo = {};
                        _vm.status.open = false;
                        _vm.disabledPrograma = false;
                        _vm.atributosLista = [];

                        angular.forEach(_vm.atributos, function (attr) {
                            attr.selected = false;
                        });
                    };

                    var _verificarExisteAtributo = function (atributo) {
                        if (undefined == _vm.atributos) {
                            return false;
                        }

                        var atributos = $filter('filter')(_vm.atributos, function (value) {
                            return value.atributo.id == atributo.atributo.id && value.programa.id == atributo.programa.id && atributo.edit != true;
                        });

                        if (atributos.length != null && atributos.length > 0) {
                            return true;
                        }

                        return false;
                    };

                    _vm.selecionarTodos = function () {
                        if (_vm.selectedAll == true) {
                            angular.forEach(_vm.atributos, function (value) {
                                value.selected = true;
                            });
                        } else {
                            angular.forEach(_vm.atributos, function (value) {
                                value.selected = false;
                                value.edit = false;
                            });
                        }
                    }

                    _vm.selecionarTodosAtributos = function () {
                        if (_vm.selectedAllAtributos == true) {
                            angular.forEach(_vm.atributosLista, function (value) {
                                value.selected = true;
                            });
                        } else {
                            angular.forEach(_vm.atributosLista, function (value) {
                                value.selected = false;
                            });
                        }
                    }

                    _vm.add = function () {
                        vm.disabledPrograma = false;
                        _vm.atributosLista.forEach(atributo => {
                            if (atributo.dados.length === 0) {
                                return;
                            }
                            if (atributo.edit) {
                                atributo.edit = false;
                                vm.atributos[atributo.key - 1] = atributo;
                                if (atributo.id)
                                    atributo.alterado = true;
                            } else {
                                var attr = angular.copy(atributo);
                                attr.situacao = { key: 'A' };
                                attr.programa = _vm.atributo.programa;
                                vm.atributos.push(attr);
                            }
                            _vm.status.open = false;
                            _vm.atributoForm.$setPristine();
                            _vm.atributoForm.$setUntouched();
                        });
                    }

                    _vm.setAtributo = function (atributo) {
                        if (atributo) {
                            atributo.tipo = atributo.tipoAtributo;

                            if (undefined !== atributo.idOpcao) {
                                atributo.opcao = atributo.idOpcao;
                            }
                            if (undefined !== atributo.idMedida) {
                                atributo.medida = atributo.idMedida;
                            }
                        }
                    }

                    _vm.excluir = function (atributos) {
                        DialogService.confirm("Você tem certeza que deseja excluir o(s) atributo(s)?", function () {
                            var novosAtributos = [];
                            angular.forEach(atributos, function (value, index) {
                                if (!value.selected) {
                                    novosAtributos.push(value);
                                } else {
                                    if (undefined !== value.id) {
                                        vm.atributosExcluidos.push(value);
                                    }

                                    _excluirDosIncluidos(value);
                                }
                            });

                            vm.atributos = novosAtributos;
                            _vm.atributosExcluidos = vm.atributosExcluidos;
                            _vm.atributos = vm.atributos;
                            _vm.selectedAll = false;
                            _vm.selectedAllAtributos = false;
                        });
                    };

                    var _excluirDosIncluidos = function (atributo) {
                        angular.forEach(_vm.atributosIncluidos, function (value, index) {
                            if (value.atributo.id == atributo.atributo.id) {
                                _vm.atributosIncluidos.splice(index, 1);
                            }
                        });
                    };

                    _vm.atributoValue = function (attr) {
                        if (attr.atributo.tipoAtributo.key == 'L') {
                            return attr.dados[0].linha;
                        } else if (attr.atributo.tipoAtributo.key == 'M') {
                            if (attr.atributo.medida.tipoValor.key == 'I' || attr.atributo.medida.tipoValor.key == 'R'
                                || attr.atributo.medida.tipoValor.key == 'M') {
                                return attr.dados[0].valueNumericResposta;
                            } else if (attr.atributo.medida.tipoValor.key == 'T') {
                                return moment(attr.dados[0].valueDateTimeResposta).format("DD/MM/YYYY h:mm");
                            } else if (attr.atributo.medida.tipoValor.key == 'D') {
                                return moment(attr.dados[0].valueDateTimeResposta).format("DD/MM/YYYY");
                            } else if (attr.atributo.medida.tipoValor.key == 'H') {
                                return attr.dados[0].valueTimeResposta;
                            }
                        } else if (attr.atributo.tipoAtributo.key == 'P') {
                            return attr.dados[0].paragrafo;
                        } else if (attr.atributo.tipoAtributo.key == 'O') {
                            Opcao.get({ id: attr.atributo.opcao.id }, function (opcoes) {
                                console.log("opcoes");
                                console.log(opcoes);
                            });
                        }

                        return "-";
                    };

                    _vm.editar = function () {
                        angular.forEach(_vm.atributos, function (value, index) {
                            if (value.selected) {
                                value.key = index + 1;
                                value.edit = true;
                                vm.atributos[index].alterado = true;
                                _vm.atributo = angular.copy(value);
                                _vm.atributosPorPrograma(value.programa.id);
                                _vm.setAtributo(value.atributo);
                                _vm.disabledPrograma = true;
                                _vm.disableSelects = true;
                                return true;
                            }
                        });
                    };

                    _vm.removeEdit = function (atributos) {
                        angular.forEach(atributos, function (value) {
                            if (value.selected) {
                                value.edit = false;
                                value.selected = false;
                            }
                        });
                        _vm.selectedAll = false;
                    };

                    _vm.new = function () {
                        _clearAll();
                        _vm.status.open = true;
                        _vm.disableSelects = false;
                    };

                    _vm.close = function () {
                        _vm.disableSelects = false;
                        _clearAll();
                        $uibModalInstance.dismiss('cancel');
                    };

                    _vm.cancel = function () {
                        _clearAll();
                    };

                    _clearAll();
                }
            );
        };

        vm.getMunicipiosPorUf = function (uf) {
            if (uf) {
                municipiosService.withPermissionFilter({ idUf: uf.id }).then(function (response) {
                    vm.municipios = response;
                });
            } else {
                vm.municipios = [];
            }
        };

        vm.getEscolasPorMunicipio = function (municipio) {
            if (municipio) {
                vm.escolas = escolasService.parceirasPorMunicipio(municipio.id);
            } else {
                vm.escolas = [];
            }
        }

        vm.goBack = function () {
            $state.go(APP_STATE.TURMA.SEARCH);
        };

        vm.enableEditMode = function () {
            vm.editMode = true;
        };

        vm.calendarioPadraoEscola = function (escola, calendarioPadrao) {
            if (is.not.existy(escola) || is.not.existy(escola.id)) {
                return;
            }

            if (vm.turma.calendarioEscola == 'true' || vm.turma.calendarioEscola == true) {
                vm.meses = [];

                escolasService.get(escola.id).then(function (response) {
                    var calendarios = response.calendarios;
                    var calendario = $filter('filter')(calendarios, function (value) {
                        return value.tipo.key == "E" && value.periodoLetivo.anoLetivo == vm.turma.periodoLetivo.anoLetivo;
                    });

                    if (calendario.length == 1) {
                        vm.meses = calendario[0].meses;
                    }
                });
            }

        };

        vm.calendarioTurma = function (calendario) {
            vm.meses = [];
            if (calendario && (vm.turma.calendarioEscola == 'false' || vm.turma.calendarioEscola == false)) {
                if (calendario.meses.length > 0 && calendario.periodoLetivo.anoLetivo == vm.turma.periodoLetivo.anoLetivo) {
                    vm.meses = calendario.meses;
                }
            }
        };

        vm.addPrograma = function (programa) {
            vm.programaInvalido = false;
            vm.programaVazio = false;

            if (undefined == programa) {
                vm.programaVazio = true;
                return false;
            }

            angular.forEach(vm.turmasProgramas, function (value) {
                if (value.programa.id == programa.id) {
                    vm.programaInvalido = true;
                }
            });

            if (vm.programaInvalido) {
                return false;
            }

            var novoPrograma = {
                programa: programa,
                situacao: { key: "A" }
            };

            (rubricasService.byPrograma(novoPrograma.programa.id)).$promise.then(function (response) {
                angular.forEach(response, function (value) {

                    let formExiste = false;
                    angular.forEach(vm.rubricas, function (rubricaExistente) {
                        if (rubricaExistente.id == value.id) {
                            formExiste = true;
                        }
                    });
                    if (!formExiste) {
                        vm.rubricas.push(value);
                    }

                });
            });
            vm.turmasProgramas.push(angular.copy(novoPrograma));
            vm.turmasProgramasIncluidos.push(angular.copy(novoPrograma));
            vm.programa = null;
        };



        vm.carregaDisciplina = function () {
            vm.disciplinas = [];
            disciplinasService.findByAreaConhecimento(vm.turmaProfessor.areaConhecimento.id).$promise.then(function (responseform) {
                angular.forEach(responseform[0], function (resp) {
                    vm.disciplinas.push(resp);
                });
            });
        }

        vm.addProfessor = function (turmaProfessor) {

            var disciplina = turmaProfessor.disciplina;
            if (is.not.existy(disciplina.id)) {
                disciplina = { nome: disciplina };
                turmaProfessor.disciplina = disciplina;
            }

            var input = vm.turmaProfessor.professor.pessoa.nomeCompleto + vm.turmaProfessor.disciplina.nome;
            vm.professorDiciplinaDuplicado = false;
            vm.registerForm.professor.$setValidity("duplicate", true);
            var valid = true;

            angular.forEach(vm.turmasProfessores, function (value) {
                var added = value.professor.pessoa.nomeCompleto + value.disciplina.nome;
                if (input == added) {
                    vm.professorDiciplinaDuplicado = true;
                    vm.registerForm.professor.$setValidity("duplicate", false);
                    valid = false;
                }
            });

            if (valid) {
                vm.turmasProfessores.push(angular.copy(turmaProfessor));
                vm.turmasProfessoresIncluidas.push(angular.copy(turmaProfessor));
                vm.cleanProfessorFields();
                vm.habilitaBotaoProfessor = false;
            }
        };

        vm.habilitarBotaoProfessor = function () {

            if (is.truthy(vm.turmaProfessor.professor) && is.not.empty(vm.turmaProfessor.professor) &&
                is.object(vm.turmaProfessor.professor) &&
                is.truthy(vm.turmaProfessor.disciplina) && is.not.empty(vm.turmaProfessor.disciplina) &&
                is.truthy(vm.turmaProfessor.funcao) && is.not.empty(vm.turmaProfessor.funcao) &&
                is.truthy(vm.turmaProfessor.tipoContratacao) &&
                is.truthy(vm.turmaProfessor.tipoContratacao)) {
                vm.habilitaBotaoProfessor = true;
            } else {
                vm.habilitaBotaoProfessor = false;
            }

            if (is.not.truthy(vm.turmaProfessor.professor) && is.empty(vm.turmaProfessor.professor) &&
                is.not.truthy(vm.turmaProfessor.disciplina) && is.empty(vm.turmaProfessor.disciplina)) {

                vm.professorDiciplinaDuplicado = false;
                vm.registerForm.professor.$setValidity("duplicate", true);
                var valid = true;
            }

        };

        vm.excluirPrograma = function (i) {
            var programa = vm.turmasProgramas[i];
            if (programa.id) {
                vm.turmasProgramasExcluidos.push(programa);
            }
            _excluirPrograma(programa);
        };

        var _excluirPrograma = function (programa) {
            //remoção de rubricas
            vm.rubricas = [];
            vm.rubricasEscolhidas = [];
            vm.formularios = [];
            vm.formulariosEscolhidas = [];


            angular.forEach(vm.turmasProgramas, function (value, index) {
                if (value.programa.id == programa.programa.id) {
                    vm.turmasProgramas.splice(index, 1);
                } else {
                    (rubricasService.byPrograma(value.programa.id)).$promise.then(function (response) {
                        angular.forEach(response, function (value) {


                            let formExiste = false;
                            angular.forEach(vm.rubricas, function (rubricaExistente) {
                                if (rubricaExistente.id == value.id) {
                                    formExiste = true;
                                }
                            });
                            if (!formExiste) {
                                vm.rubricas.push(value);
                            }

                        });
                    });
                }
            });
            angular.forEach(vm.turmasProgramasIncluidos, function (value, index) {
                if (value.programa.id == programa.programa.id) {
                    vm.turmasProgramasIncluidos.splice(index, 1);
                } else {
                    (rubricasService.byPrograma(value.programa.id)).$promise.then(function (response) {
                        angular.forEach(response, function (value) {

                            let formExiste = false;
                            angular.forEach(vm.rubricas, function (rubricaExistente) {
                                if (rubricaExistente.id == value.id) {
                                    formExiste = true;
                                }
                            });
                            if (!formExiste) {
                                vm.rubricas.push(value);
                            }

                        });
                    });
                }
            });
        };

        vm.excluirTurmaProfessor = function (i) {
            var turmaProfessor = vm.turmasProfessores[i];
            if (turmaProfessor.id) {
                vm.turmasProfessoresExcluidas.push(turmaProfessor);
            }
            _excluirTurmaProfessor(turmaProfessor);
        };

        var _excluirTurmaProfessor = function (turmaProfessor) {
            angular.forEach(vm.turmasProfessores, function (value, index) {
                if (value.id == turmaProfessor.id) {
                    vm.turmasProfessores.splice(index, 1);
                }
            });
            angular.forEach(vm.turmasProfessoresIncluidas, function (value, index) {
                if (value.id == turmaProfessor.id) {
                    vm.turmasProfessoresIncluidas.splice(index, 1);
                }
            });
        };

        vm.cleanProfessorFields = function () {
            vm.turmaProfessor.professor = "";
            vm.turmaProfessor.disciplina = "";
            vm.turmaProfessor.funcao = "";
            vm.turmaProfessor.tipoContratacao = "";
        };

        vm.getProfessores = function (nome) {
            vm.filter = { nome: nome, situacao: 'ATIVO' };
            return professorService.filter(vm.filter).then(function (professores) {
                return professores;
            });
        };

        vm.callbackFormAddProfessor = function (response) {
            vm.turmaProfessor = { professor: response };
        };

        var _atributosIncluidos = function (atributos) {
            return $filter('filter')(atributos, function (atributo) {
                return !atributo.id;
            });
        };

        var _atributosAlterados = function (atributos) {
            return $filter('filter')(atributos, function (atributo) {
                return atributo.alterado;
            });
        };

        vm.save = async function () {

            if (vm.registerForm.professor.$viewValue) {
                toastService.error("Você inseriu um nome de professor, é necessário clicar em 'Incluir Professor' para que o mesmo seja salvo.")
                return false
            }

            if (vm.registerForm.$valid) {
                vm.turma.atributosEntidadesDTO = vm.atributos;
                vm.turma.atributosEntidadesIncluidosDTO = _atributosIncluidos(vm.atributos);
                vm.turma.atributosEntidadesAlteradosDTO = _atributosAlterados(vm.atributos);
                vm.turma.atributosEntidadesExcluidosDTO = vm.atributosExcluidos;
                vm.turma.situacao = { key: "A" };
                if (is.existy(vm.turma.calendario)) {
                    vm.turma.calendario.situacao = { key: "A" };
                }
                vm.turma.turmasProgramas = vm.turmasProgramas;
                vm.turma.turmasProgramasIncluidos = vm.turmasProgramasIncluidos;
                vm.turma.turmasProgramasExcluidos = vm.turmasProgramasExcluidos;

                if (is.not.existy(vm.turmasProgramas) || is.empty(vm.turmasProgramas)) {
                    toastService.error("Pelo menos um programa deve ser incluído.");
                    return;
                }

                //Correção do problema de comparação do boolean
                if (typeof vm.turma.calendarioEscola == "string") {
                    vm.turma.calendarioEscola = vm.turma.calendarioEscola == "true";
                } else {
                    vm.turma.calendarioEscola = vm.turma.calendarioEscola == true;
                }

                vm.turma.turmasProfessoresIncluidas = vm.turmasProfessoresIncluidas;
                vm.turma.turmasProfessoresExcluidas = vm.turmasProfessoresExcluidas;

                if (vm.turma.controleAluno) {
                    var alunosIncluidos = $filter('filter')(vm.turmaAluno, function (value) {
                        return value.added;
                    });
                    vm.turma.turmaAlunoIncluidos = alunosIncluidos;
                    vm.turma.turmaAlunoExcluidos = vm.turmaAlunoExcluidos;

                    var movimentacaoExcluidas = $filter('filter')(vm.turmasMovimentacaoMensal, function (value) {
                        return value.id != null;
                    });

                    vm.turma.turmasMovimentacaoMensal = [];
                    vm.turma.turmasMovimentacaoMensalIncluidas = [];
                    vm.turma.turmasMovimentacaoMensalAlteradas = [];
                    vm.turma.turmasMovimentacaoMensalExcluidas = movimentacaoExcluidas;
                } else {
                    var alunoExcluidos = $filter('filter')(vm.turmaAluno, function (value) {
                        return value.id != null;
                    });

                    vm.turmaAluno = [];
                    vm.turma.turmaAlunoIncluidos = [];
                    vm.turma.turmaAlunoExcluidos = alunoExcluidos;

                    vm.turma.turmasMovimentacaoMensal = vm.turmasMovimentacaoMensal;
                    vm.turma.turmasMovimentacaoMensalIncluidas = vm.turmasMovimentacaoMensalIncluidas;
                    vm.turma.turmasMovimentacaoMensalAlteradas = vm.turmasMovimentacaoMensalAlteradas;
                    vm.turma.turmasMovimentacaoMensalExcluidas = vm.turmasMovimentacaoMensalExcluidas;

                }


                vm.turma.rubricas = vm.rubricasEscolhidas;
                vm.turma.formularios = vm.formulariosEscolhidas;

                turmasService.save(vm.turma).then(function (response) {
                    $scope.$emit(APP_EVENT.TURMA.SAVED);
                    vm.goBack();
                }, function (response) {
                    var message = 'Não foi possível processar a sua requisição.';
                    if (response.data && response.data.message) {
                        message = response.data.message;
                    }
                    toastService.error({
                        message: message
                    });
                });
            }
        };

        vm.cancel = function () {
            vm.goBack();
        };

        vm.showMsnEscolaPadrao = function () {

            if (angular.isUndefined(vm.turma)) {
                return false;
            }

            if ((vm.turma.calendarioEscola == 'true' || vm.turma.calendarioEscola == true) &&
                (angular.isUndefined(vm.turma.escola) || angular.isUndefined(vm.turma.periodoLetivo))) {
                return "Você deve selecionar a escola e o período letivo para visualizar o calendário.";
            } else if (vm.meses.length == 0 && (vm.turma.calendarioEscola == 'true' || vm.turma.calendarioEscola == true) &&
                is.not.empty(vm.turma.escola) && is.not.empty(vm.turma.periodoLetivo)) {
                return "A escola informada não possui calendário geral associado para o período letivo informado.";
            } else if ((vm.turma.calendarioEscola == 'false' || vm.turma.calendarioEscola == false) &&
                (angular.isUndefined(vm.turma.escola) || angular.isUndefined(vm.turma.periodoLetivo))) {
                return "Você deve selecionar a escola e o período letivo para visualizar os calendários.";
            } else if (vm.turma.escola && vm.turma.periodoLetivo && vm.calendarios.length == 0 &&
                (vm.turma.calendarioEscola == 'false' || vm.turma.calendarioEscola == false)) {
                return "A escola informada não possui calendário específico para o período letivo informado.";
            }

            return false;

        };

        (function () {
            vm.editMode = false;
            vm.editPage = false;
            vm.defaultStatus = COMMON_STATUS_LIST[0];
            vm.turmasProgramas = [];
            vm.turmasProgramasIncluidos = [];
            vm.turmasProgramasExcluidos = [];
            vm.professores = [];

            vm.filter = { situacao: 'ATIVO' };
            
            if ($state.is(APP_STATE.TURMA.EDIT)) {
                vm.editPage = true;

                if ($state.params.id) {
                    turmasService.get($state.params.id).then(function (response) {
                        vm.turma = response;
                        if (response.atributosEntidadesDTO) {
                            vm.atributos = response.atributosEntidadesDTO;
                        }
                        vm.turma.uf = response.escola.uf;
                        if (response.turmasProgramas) {
                            vm.turmasProgramas = response.turmasProgramas;
                        }

                        if (response.turmasMovimentacaoMensal) {
                            vm.turmasMovimentacaoMensal = response.turmasMovimentacaoMensal;
                        }

                        if (response.turmasProfessores) {
                            vm.turmasProfessores = response.turmasProfessores;
                        }

                        if (response.turmaAluno) {
                            vm.turmaAluno = response.turmaAluno;
                        }

                        vm.turma.calendarioEscola = 'true';
                        if (is.existy(response.calendario.calendario)) {
                            vm.turma.calendarioEscola = 'false';
                        }
                        vm.rubricasEscolhidas = response.rubricas;

                        angular.forEach(vm.rubricasEscolhidas, function (value, index) {
                            formularioService.findByRubrica(value.id).then(function (responseF) {
                                angular.forEach(responseF, function (valueFF) {
                                    vm.formularios.push(valueFF);
                                });
                            });
                        });


                        vm.formulariosEscolhidas = response.formularios;
                        vm.changeItems();


                        vm.getMunicipiosPorUf(vm.turma.uf);
                        vm.turma.municipio = response.escola.municipio;
                        vm.getEscolasPorMunicipio(vm.turma.municipio);
                        vm.calendariosEscola(vm.turma.escola);
                        vm.programasEscola(vm.turma.escola);
                        vm.calendarioTurma(vm.turma.calendario.calendario);
                        vm.calendarioPadraoEscola(vm.turma.escola, vm.turma.calendarioEscola);


                        vm.ufs.$promise.then(function (data) {
                            if (is.not.existy(_.findWhere(data, { id: vm.turma.uf.id }))) {
                                vm.ufs.push(vm.turma.uf);
                                vm.ufs = _.sortBy(vm.ufs, 'nome');
                            }
                        });

                        vm.municipios.$promise.then(function (data) {
                            if (is.not.existy(_.findWhere(data, { id: vm.turma.municipio.id }))) {
                                vm.municipios.push(vm.turma.municipio);
                                vm.municipios = _.sortBy(vm.municipios, 'nome');
                            }
                        });

                        vm.periodosLetivos.$promise.then(function (data) {
                            if (is.not.existy(_.findWhere(data, { id: vm.turma.periodoLetivo.id }))) {
                                vm.periodosLetivos.push(vm.turma.periodoLetivo);
                                vm.periodosLetivos = _.sortBy(vm.periodosLetivos, 'anoLetivo');
                            }
                        });
                    }, function (response) {
                        var message = 'Não foi possível recuperar as informações da turma.';
                        if (response.data && response.data.message) {
                            message = response.data.message;
                        }
                        toastService.error({
                            message: message
                        });
                    });
                } else {
                    vm.goBack();
                }
            } else {
                vm.enableEditMode();
                vm.turma = {};
            }
        }());
    }

}());