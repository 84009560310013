(function() {
    'use strict';

    angular.module('app.rubricas').controller('RubricaFormController', RubricaFormController);
    RubricaFormController.$inject = ['APP_STATE', 'TABLE_FIELDS', 'APP_EVENT', 'COMMON_STATUS_LIST',
        '$state', '$scope', 'rubricasService', 'dialogService', 'toastService',
        'CriterioSelecaoService', '$rootScope'
    ];

    function RubricaFormController(APP_STATE, TABLE_FIELDS, APP_EVENT, COMMON_STATUS_LIST,
        $state, $scope, rubricasService, dialogService, toastService,
        criterioSelecaoService, $rootScope) {

        var vm = this;

        var _init = function() {
            vm.selectedItems = [];
            vm.salvarContinuar = false;
            vm.fields = TABLE_FIELDS.RUBRICA_CRITERIO;
            vm.editMode = false;
            vm.editPage = false;
            vm.defaultStatus = COMMON_STATUS_LIST[0];
            vm.criterios = [];
            vm.newCriterios = [];
            vm.criteriosExcluidos = [];
            vm.modalTemplateUrl = "rubricas/partials/rubricas-form-criterios-modal.html";
            vm.modalDependencies = {
                statusList: COMMON_STATUS_LIST,
                criteriosSelecao: criterioSelecaoService.allEnabled(),
                criterio: {
                    situacao: vm.defaultStatus
                },
                save: _saveModalItem
            };

            if ($state.is(APP_STATE.RUBRICA.EDIT)) {
                vm.editPage = true;
                
                if ($state.params.id) {
                    rubricasService.get($state.params.id).then(function(rubrica) {
                        vm.rubrica = rubrica;
                        vm.criterios = rubrica.criterios;
                        if (is.truthy($state.params.editMode)) {
                                vm.enableEditMode();
                        }
                    }, function(response) {
                        toastService.error({
                            status: response.status,
                            message: response.data.message
                        });
                    });
                } else {
                    vm.goBack();
                }
            } else {
                vm.enableEditMode();
                vm.rubrica = {};
                vm.rubrica.situacao = vm.defaultStatus;
            }
        }; 

        var _saveModalItem = function(modalForm){
            vm.registerForm.$setDirty();
            this.ok(modalForm);
        }

        vm.goBack = function() {
            $state.go(APP_STATE.RUBRICA.SEARCH);
        };

        vm.save = function() {
            var criterios = vm.newCriterios.concat(vm.criterios);
            vm.rubrica.criterios = criterios;
            vm.rubrica.criteriosExcluidos = vm.criteriosExcluidos;

            if (vm.registerForm.$valid) {
                rubricasService.save(vm.rubrica).then(function(rubrica) {
                    $scope.$emit(APP_EVENT.RUBRICA.SAVED);
                    if (!vm.salvarContinuar) {
                        vm.goBack();
                    } else {
                        _init();
                        //Irá enviar notificação para limpar os itens selecionados
                        $rootScope.$broadcast(APP_EVENT.GENERIC.CLEAR_ITEMS_SELECTED)
                        vm.registerForm.$setPristine();
                        $state.go(APP_STATE.RUBRICA.EDIT, { id: rubrica.id, editMode: true });
                    }
                }, function(response) {
                    toastService.error({
                        status: response.status,
                        message: response.data.message
                    });
                });
            }
        };

        vm.saveCriterios = function() {
            _saveAndContinue(true);
        };

        vm.updateCriterios = function() {
            _saveAndContinue(false);
        };

        vm.enableEditMode = function() {
            vm.editMode = true;
        };

        vm.cancel = function() {
            if (vm.refreshSearch) {
                $scope.$emit(APP_EVENT.RUBRICA.REFRESH);
            }

            vm.goBack();
        };

        var _saveAndContinue = function(createNewItems) {
            var criterios = vm.criterios.concat(vm.newCriterios);
            
            vm.rubrica.criterios = criterios;
            vm.rubrica.criteriosExcluidos = vm.criteriosExcluidos;

            if (vm.registerForm.$valid) {
                rubricasService.save(vm.rubrica).then(function(response) {
                    vm.criterios = response.criterios;
                    vm.refreshSearch = true;
                    vm.registerForm.$setPristine();

                    vm.newCriterios = [];
                    vm.criteriosExcluidos = [];
                    
                    vm.rubrica = response;
                    toastService.success("Operação realizada com sucesso.");
                }, function(response) {
                    vm.refreshSearch = false;

                    toastService.error({
                        message: response.data.message
                    });
                });
            }
        };

        (function() {
            _init();     
        }());
    }

}());