(function() {
    'use strict';

    angular.module('app.relatorios').controller('RelatorioController', RelatorioController);
    RelatorioController.$inject = ['ufService', 'periodoLetivoService', 'municipiosService', 'programasService',
        'localizacaoService', 'dependenciaAdministrativaService', 'escolasService', 'etapaService',
        'turmasService', 'RelatoriosService', 'toastService', 'scrollService', 'TIPO_ORGANIZACAO_ESCOLA',
        'contratoService', 'dateUtilService', '$interpolate', 'VALIDATION_PATTERN', 'REPORT_TYPE',
        'ESCOLA_ANUAL_PERFIL_TURMA_FIELDS', 'objectService', '$filter', 'TABLE_FIELDS_TYPE'
    ];

    function RelatorioController(ufService, periodoLetivoService, municipiosService, programasService,
        localizacaoService, dependenciaAdministrativaService, escolasService, etapaService,
        turmasService, relatoriosService, toastService, scrollService, TIPO_ORGANIZACAO_ESCOLA,
        contratoService, dateUtilService, $interpolate, VALIDATION_PATTERN, REPORT_TYPE,
        ESCOLA_ANUAL_PERFIL_TURMA_FIELDS, objectService, $filter, TABLE_FIELDS_TYPE) {

        var vm = this;

        vm.changeUf = function() {
            if (vm.filter.uf) {
                vm.municipios = municipiosService.porUf(vm.filter.uf.id);
                vm.regionaisUf = ufService.regionais(vm.filter.uf.id);
            } else {
                vm.municipios = [];
                vm.regionaisUf = [];
                vm.regionaisMunicipios = [];
                vm.escolas = [];
            }
        };

        vm.changeMunicipio = function() {
            if (vm.filter.municipio) {
                vm.regionaisMunicipios = municipiosService.regionais(vm.filter.municipio.id);
                vm.escolas = escolasService.porMunicipio(vm.filter.municipio.id);
            } else {
                vm.regionaisMunicipios = [];
                vm.escolas = [];
            }
        };

        vm.getTotalizacaoFormated = function() {
            var template;

            if (vm.totalizacaoMensal) {
                template = "Totalização mensal: {{mesAnoTotalizacao}}";
            } else {
                template = "Totalização acumulada: {{mesAnoInicio}} - {{mesAnoFim}}";
            }

            return $interpolate(template)(vm.clonedFilter);
        };

        var _findData = function(filter) {
            relatoriosService.filter(filter)
                .then(function(result) {
                    vm.reportData = result.content;
                    vm.reportDataTotal = result.totalElements;
                    vm.reportDataLength = vm.reportData.length;
                    vm.currentPage = result.number + 1;
                    vm.searchWasPerformed = true;
                    vm.totais = {};

                    scrollService.scrollTo('tabela-relatorio');
                }, function(error) {
                    toastService.error(error);
                });
        };

        vm.generate = function(reportType) {
            vm.filter.page = 0;
            vm.filter.size = vm.itemsPerPage;
            vm.filter.reportType = reportType;
            vm.clonedFilter = angular.copy(vm.filter);

            _findData(vm.filter, true);
        };

        vm.onPageChange = function(page, size) {
            vm.clonedFilter.page = page;
            vm.clonedFilter.size = size;
            _findData(vm.clonedFilter);
        };

        vm.changeTotalizacaoMensal = function() {
            delete vm.filter.mesAnoTotalizacao;
            delete vm.filter.mesAnoInicio;
            delete vm.filter.mesAnoFim;
        };

        vm.formatColumn = function(item, field) {
            var formatedValue;

            switch (field.type) {
                case TABLE_FIELDS_TYPE.DATE:
                    formatedValue = $filter('date')(objectService.deepValue(item, field.name), 'dd/MM/yyyy');
                    break;
                case TABLE_FIELDS_TYPE.PERCENT:
                case TABLE_FIELDS_TYPE.NUMBER:
                case TABLE_FIELDS_TYPE.AVERAGE:
                    formatedValue = $filter('number')(objectService.deepValue(item, field.name), 1);
                    break;
                default:
                    formatedValue = objectService.deepValue(item, field.name);
            }

            return formatedValue;
        };

        vm.formatTotalColumn = function(field) {
            var formatedTotal = "";

            if (is.not.existy(field.notTotal) || is.falsy(field.notTotal)) {
                var total = vm.totais[field.name];
                var numElements = vm.reportDataLength;

                if (is.existy(total) && numElements > 0) {
                    if (is.existy(field.totalType) && field.totalType == TABLE_FIELDS_TYPE.AVERAGE) {
                        formatedTotal = $filter('number')(total / numElements, 1)
                    } else {
                        if (field.type == TABLE_FIELDS_TYPE.NUMBER) {
                            formatedTotal = $filter('number')(total, 1);
                        } else {
                            formatedTotal = total;
                        }
                    }
                }
            }

            return formatedTotal;
        };

        vm.initTotal = function(field, data) {
            if (is.not.existy(field.notTotal) || is.falsy(field.notTotal)) {
                var oldTotal = vm.totais[field.name];

                if (is.existy(oldTotal)) {
                    vm.totais[field.name] += data[field.name];
                } else {
                    vm.totais[field.name] = data[field.name];
                }
            }
        };

        (function() {
            vm.filter = {};
            vm.clonedFilter = {};
            vm.municipios = [];
            vm.regionaisUf = [];
            vm.regionaisMunicipios = [];
            vm.escolas = [];
            vm.ufs = ufService.ativos();
            vm.periodosLetivos = periodoLetivoService.ativos();
            vm.programas = programasService.ativos();
            vm.localizacoes = localizacaoService.ativos();
            vm.dependenciasAdministrativas = dependenciaAdministrativaService.ativos();
            vm.etapas = etapaService.ativos();
            vm.itemsPerPage = 10;
            vm.currentPage = 1;
            vm.tiposEscolas = TIPO_ORGANIZACAO_ESCOLA;
            vm.reportDataTotal = 0;
            vm.reportDataLength = 0;
            vm.reportData = [];
            vm.searchWasPerformed = false;
            vm.totalizacaoMensal = true;
            vm.totais = {};
            vm.fieldDateType = dateUtilService.TYPE.SHORT_DATE;
            vm.shortDatePattern = VALIDATION_PATTERN.SHORT_DATE;
            vm.REPORT_TYPE = REPORT_TYPE;
            vm.ESCOLA_ANUAL_PERFIL_TURMA_FIELDS = ESCOLA_ANUAL_PERFIL_TURMA_FIELDS;

            turmasService.turnos().then(function(turnos) {
                vm.turnos = turnos;
            }, function() {
                vm.turnos = [];
            });

            relatoriosService.findDetalhamentos().then(function(detalhamentos) {
                vm.detalhamentos = detalhamentos;
            }, function() {
                vm.detalhamentos = [];
            });

            contratoService.findEscopos().then(function(escopos) {
                vm.tiposDeContrato = escopos;
            }, function() {
                vm.tiposDeContrato = [];
            });

        })();

    }

}());