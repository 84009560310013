(function() {
    'use strict';

    angular.module('app.core').constant('CONTRATO_ESCOLA_SEARCH_BY', {
        CODE: {
            field: "id",
            placeholder: "código",
            mask: "9",
            restrict: "[^0-9]",
            maxLength: 9,
            minLength: 1
        },
        NAME: {
            field: "nome",
            placeholder: "nome",
            mask: "*",
            restrict: "%",
            maxLength: 50,
            minLength: 3
        },
        INEP: {
            field: "inep",
            placeholder: "código INEP",
            mask: "*",
            restrict: "%",
            maxLength: 50,
            minLength: 3
        }
    });

}());
