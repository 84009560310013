(function() {
    'use strict';

    angular.module('app.core').directive("iasPaginationServer", IasPaginationServer);

    function IasPaginationServer() {
        return {
            scope: {
                totalItems: '=',
                ngModel: '=?',
                maxSize: '=?',
                itemsPerPage: '=?',
                ngChange: '&?'
            },
            templateUrl: "core/directives/pagination/partials/ias-pagination-server.html",
            link: function(scope, element, attr) {
                scope.maxSize = scope.maxSize || 5;
                scope.ngModel = scope.ngModel || 1;
                scope.itemsPerPage = scope.itemsPerPage || 10;

                scope.onPageChange = function() {
                    if (scope.ngChange) {
                        var currentPage = (scope.ngModel > 0) ? scope.ngModel - 1 : 0;

                        scope.ngChange({page: currentPage, size: scope.itemsPerPage});
                    }
                };

            }
        };
    }

})();
