(function() {
    'use strict';

    angular.module('app.validarEmail').controller('ValidarEmailController', ValidarEmailController);
    ValidarEmailController.$inject = ['APP_STATE', '$state', 'Usuario', '$timeout'];

    function ValidarEmailController(APP_STATE, $state, Usuario, $timeout) {
        var vm = this;
        vm.logoImages = ["contents/images/logo_IAS_1.jpg", "contents/images/logo_IAS_2.jpg", "contents/images/logo_IAS_3.jpg", "contents/images/logo_IAS_4.jpg"];
        vm.success = false;

        var REDIRECT_TIMEOUT = 8000;
        (function() {
            Usuario.validarEmail({ token: $state.params.token }).$promise
                .then(function() {
                    vm.success = true;
                    $timeout(function() {
                        $state.go(APP_STATE.HOME.BASE);
                    }, REDIRECT_TIMEOUT);
                })
                .catch(function(error) {
                    vm.error = error;
                });
        })();

    }

}());
