(function() {
    'use strict';

    angular.module('app.core').directive("iasContratoAlertError", IasContratoAlertError);

    function IasContratoAlertError() {
        return {
            scope: {
                form: '='
            },
            restrict: "E",
            templateUrl: "core/directives/error/partials/ias-contrato-alert-error.html"
        };
    }

})();