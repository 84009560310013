/*
jslint
    devel: true, maxerr: 10
*/
/*
global
    angular
*/
/*
 * App Core Module
 * @service toastService
 * @author iFactory Solutions Ltda.
 * @version 1.0.0
 * @since 1.0.0
 */
(function() {
    'use strict';

    angular.module('app.core').service('toastService', ToastService);
    ToastService.$inject = ['$interpolate', 'Flash', 'utilService'];

    function ToastService($interpolate, Flash, utilService) {
        var duration = 10000; // TODO Criar configuração para esse valor e aí o tempo é definir via build.
        var template = '<strong><i class="glyphicon {{icon}}"></i></strong><span>{{message}}</span>';

        var _createToast = function(type, icon, message, container) {
            var options = {};
            options.container = container || 'flash-fixed';

            var formatedMsg = $interpolate(template)({ icon: icon, message: message });

            Flash.clear();
            return Flash.create(type, formatedMsg, duration, options, true);
        };

        this.clear = function() {
            // TODO Talvez fosse interessante chamar o clear em todas as transições realizadas com sucesso,
            // ou criar mais um atributo no state para indicar se deve ou não limpar todo os flash messages existentes.
            // implementar isso no app.module, mais especificamente no $stateChangeSuccess.
            Flash.clear();
        };

        this.dismiss = function(id) {
            Flash.dismiss(id);
        };

        this.info = function(message, container) {
            return _createToast('info', 'glyphicon-info-sign', message, container);
        };

        this.success = function(message, container) {
            return _createToast('success', 'glyphicon-ok-sign', message, container);
        };

        this.warning = function(message, container) {
            return _createToast('warning', 'glyphicon-warning-sign', message, container);
        };

        this.error = function(error, container) {
            var message = utilService.extractErrorMsg(error);

            return _createToast('danger', 'glyphicon-exclamation-sign', message, container);
        };
    }

}());