(function() {
    'use strict';

    angular.module('app.core').directive('iasMaxValue', IasMaxValueValidation);

    function IasMaxValueValidation() {
        return {
            require: 'ngModel',
            restrict: 'A',
            link: function(scope, element, attr, controller) {
                var maxValue = attr.iasMaxValue;

                attr.$observe('iasMaxValue', function(newMaxValue) {
                    maxValue = newMaxValue;
                });

                if (maxValue) {
                    controller.$validators.iasMaxValue = function(modelValue, viewValue) {
                        var value = modelValue || viewValue;

                        if (value && maxValue) {
                            if (is.integer(value)) {
                                return parseInt(value) <= parseInt(maxValue);
                            } else {
                                return parseFloat(value) <= parseFloat(maxValue);
                            }
                        } else {
                            return true;
                        }
                    };
                }
            }
        };
    }

})();