/*
jslint
    devel: true, maxerr: 10
*/

/*
global
    required: true
*/

/*
 * Layout Controller
 * @description The main controller for the main (home) page, for authenticated users.
 * @author iFactory Solutions Ltda.
 * @version 1.0.0
 * @since 1.0.0
 */
(function() {
    'use strict';

    angular.module('app.layout').controller('ShellController', ShellController);
    ShellController.$inject = ['SecurityManagerFactory', '$state', '$rootScope', 'APP_STATE'];

    function ShellController(securityManager, $state, $rootScope, APP_STATE) {
        var vm = this;

        vm.user = securityManager.getUserCredentials();
        vm.logoImages = ["contents/images/logo_IAS_1.jpg", "contents/images/logo_IAS_2.jpg", "contents/images/logo_IAS_3.jpg", "contents/images/logo_IAS_4.jpg"];

        vm.logOut = function() {
            securityManager.logOut();
            $state.go(APP_STATE.LOGIN.BASE);
        };

        vm.goProfilePage = function() {
            $state.go(APP_STATE.USUARIO.PROFILE);
        };

        $rootScope.$on('security.event.userUpdated', function(event, data) {
            vm.user = $rootScope._user;
        });

        $(document).ready(function(){
            $("#p-container").css("opacity", "0");
            $("#p-message").css("opacity", "0");
    
            $("#a-button").mouseover(function(){
                $("#p-container").css("opacity", "1");
                $("#p-message").css("opacity", "1");
            });
            $("#a-button").mouseout(function(){
                $("#p-container").css("opacity", "0");
                $("#p-message").css("opacity", "0");
            });
        });
    }

}());