/*
jslint
    devel: true, maxerr: 10
*/

/*
global
    required: true
*/

/*
 * Relatórios Module
 * @controller Class Profile Report Controller
 * @author iFactory Solutions Ltda.
 * @version 1.0.0
 * @since 1.0.0
 */
(function() {
    'use strict';

    angular.module('app.relatorios').controller('RelatorioPerfilTurmaController', RelatorioPerfilTurmaController);
    RelatorioPerfilTurmaController.$inject = ['$state', 'APP_STATE', 'RelatoriosService', 'toastService', 'programasService', 'REPORT_PROGRAMS', 'REPORT_GROUP'];

    function RelatorioPerfilTurmaController($state, APP_STATE, reportService, toastService, programasService, REPORT_PROGRAMS, REPORT_GROUP) {
        var vm = this;

        reportService.loadMunicipiosIAS().then(function(data){
            vm.model.grupoMunicipioIas = data.data
        })

        vm._clearReportResult = function() {
            vm.view.searchWasPerformed = false;
            vm.result.reportDataCount = 0;
            vm.result.reportFields = [];
            vm.result.reportData = [];
            vm.result.calculations = {};
        };

        vm.changeDetaling = function(detailingId){
            vm.view.searchWasPerformed = false;
            vm.filter.stateUFRegional = detailingId !== 'U';
            if (!vm.filter.stateUFRegional) {
                vm.model.stateRegionals = is.existy(vm.filter.state) ? reportService.loadStateRegionalsOfState(vm.filter.state.id) : [];
                vm.model.cities = [];
                vm.model.cityRegionals = [];
                vm.model.schools = [];
            } else {
                vm.changeState();
            }
            
        }

        vm.changeState = function() {
            if (is.existy(vm.filter.state)) {
                vm.model.cities = reportService.loadCitiesOfState(vm.filter.state.id);
                vm.model.stateRegionals = reportService.loadStateRegionalsOfState(vm.filter.state.id);
            } else {
                vm.model.stateRegionals = [];
                vm.model.cities = [];
                vm.model.cityRegionals = [];
                vm.model.schools = [];
            }
        };

        vm.changeStateRegional = function() {
            if (is.existy(vm.filter.stateRegional)) {
                vm.model.cities = reportService.loadCitiesOfStateRegional(vm.filter.stateRegional.id);
            } else {
                // TODO Guardar a cidade anteriormente selecionada, caso haja; refazer a consulta pela UF (se houver uma selecioanda) 
                // e já deixar selecionada a cidade previamente selecionada.
                if (is.existy(vm.filter.state)) {
                    vm.model.cities = reportService.loadCitiesOfState(vm.filter.state.id);
                } else {
                    vm.model.cities = [];
                }
                vm.model.cityRegionals = [];
                vm.model.schools = [];
            }
        };

        vm.changeCity = function() {
            if (is.existy(vm.filter.city)) {
                vm.model.cityRegionals = reportService.loadCityRegionalsOfCity(vm.filter.city.id);
                vm.model.schools = reportService.loadSchoolsOfCity(vm.filter.city.id);
            } else {
                vm.model.schools = [];
            }
        };

        vm.changeCityRegion = function() {
            if (is.existy(vm.filter.cityRegional)) {
                vm.model.schools = reportService.loadSchoolsOfCityRegion(vm.filter.cityRegional.id);
            } else {
                vm.model.schools = reportService.loadSchoolsOfCity(vm.filter.city.id);
            }
        };

        vm.generateReport = function() {
            vm.view.searchWasPerformed = true;
            vm.result.calculations = [];
            if(vm.filter.multiserie) {
                delete vm.filter.multiserie;
            }
            reportService.generateReportResult(vm.filter, vm.result);
        };

        vm.formatColumn = function(item, field) {
            return reportService.formatColumn(item, field, vm.result);
        };

        vm.formatSummaryColumn = function(field) {
            return reportService.formatSummaryColumn(vm.result.calculations, field, vm.result);
        };


        vm.formatSummaryColumnGestaoAlfabetizacao = function(field, data) {


            if(['pcInsercao'].indexOf(field.name) !== -1) {
                let idadeTotal = 0;
                let alunosMatriculadosTotal = 0;

                angular.forEach(data, function(dado){
                    idadeTotal+=dado.idadeTotal;
                    alunosMatriculadosTotal+=dado.alunosMatriculadosTotal;
                });
                return Number(Math.round(((idadeTotal / alunosMatriculadosTotal) *100)+'e'+1)+'e-'+1);
            }
            
            if(['pcAprovados'].indexOf(field.name) !== -1) {
                let aprovadosTotal = 0;
                let naoAprovadosTotal = 0;

                angular.forEach(data, function(dado){
                    aprovadosTotal+=dado.vlAprovados;
                    naoAprovadosTotal+=dado.vlNaoAprovados;
                    
                });
                return Number(Math.round(((aprovadosTotal / (naoAprovadosTotal + aprovadosTotal)) *100)+'e'+1)+'e-'+1);
            }
            if(['pcNaoAprovados'].indexOf(field.name) !== -1) {
                let aprovadosTotal = 0;
                let naoAprovadosTotal = 0;

                angular.forEach(data, function(dado){
                    aprovadosTotal+=dado.vlAprovados;
                    naoAprovadosTotal+=dado.vlNaoAprovados;
                    
                });
                return Number(Math.round(((naoAprovadosTotal / (naoAprovadosTotal + aprovadosTotal)) *100)+'e'+1)+'e-'+1);
            }



            return reportService.formatSummaryColumn(vm.result.calculations, field, vm.result);
        };

        vm.formatSummaryColumnSomatoria = function(field, somatoriasPorcentagem) {
            var selfMethod = this;
            var campoSumarizado = selfMethod.formatSummaryColumn(field);

            if(['idade5', 'idade6',  'idade7', 'idade7', 'idade8', 'idade9',  'idade10', 'idade11','idade12',  'idade13', 'idade14mais'].indexOf(field.name) !== -1) {
                if(somatoriasPorcentagem.idade == 0) {
                    return 0;
                }
                return Number(Math.round(((campoSumarizado / somatoriasPorcentagem.idade) *100)+'e'+1)+'e-'+1) +'%';
            }


            if(['vlNivelPtInicio1', 'vlNivelPtInicio2',  'vlNivelPtInicio3', 'vlNivelPtInicio4'].indexOf(field.name) !== -1) {
                if(somatoriasPorcentagem.portuguesInicio == 0) {
                    return 0;
                }
                return Number(Math.round(((campoSumarizado / somatoriasPorcentagem.portuguesInicio) *100)+'e'+1)+'e-'+1) +'%';
            }

            if(['vlNivelPtFinal1', 'vlNivelPtFinal2',  'vlNivelPtFinal3', 'vlNivelPtFinal4'].indexOf(field.name) !== -1) {
                if(somatoriasPorcentagem.portuguesFim == 0) {
                    return 0;
                }
                return Number(Math.round(((campoSumarizado / somatoriasPorcentagem.portuguesFim) *100)+'e'+1)+'e-'+1) +'%';
            }


            if(['vlNivelMtInicio1', 'vlNivelMtInicio2',  'vlNivelMtInicio3', 'vlNivelMtInicio4'].indexOf(field.name) !== -1) {
                if(somatoriasPorcentagem.matematicaInicio == 0) {
                    return 0;
                }
                return Number(Math.round(((campoSumarizado / somatoriasPorcentagem.matematicaInicio) *100)+'e'+1)+'e-'+1) +'%';
            }

            if(['vlNivelMtFinal1', 'vlNivelMtFinal2',  'vlNivelMtFinal3', 'vlNivelMtFinal4'].indexOf(field.name) !== -1) {
                if(somatoriasPorcentagem.matematicaFim == 0) {
                    return 0;
                }
                return Number(Math.round(((campoSumarizado / somatoriasPorcentagem.matematicaFim) *100)+'e'+1)+'e-'+1) +'%';
            }
        }

        vm.performCalculation = function(field, item) {
            var oldValue = vm.result.calculations[field.name];
            vm.result.calculations[field.name] = reportService.performatCalculation(item, field, oldValue, vm.result);
        };

        vm.showResultReport = function(reportResultInfo) {
            var result = vm.result.reportDataCount > 0 && vm.view.searchWasPerformed;
            if (!result && is.existy(reportResultInfo)) {
                result = result && vm.result.report.id == reportResultInfo.reportId &&
                    ((is.existy(reportResultInfo.model) && is.existy(vm.filter.model) && reportResultInfo.model.id == vm.filter.model.id) || is.not.existy(reportResultInfo.model) || is.not.existy(vm.filter.model));
            }
            return result;
        };

        vm.resetFilter = function() {
            vm.result.reportDataCount = 0;
            vm.showResultReport();
        }

        vm.showFooter = function(fieldInfo) {
            var result = is.not.existy(fieldInfo.showFooter) || fieldInfo.showFooter;
            return result;
        };

        (function() {
            vm.model = {
                states: [],
                stateRegionals: [],
                cities: [],
                cityRegionals: [],
                cityDistricts: [],
                schools: [],
                schoolYears: [],
                schoolTypes: [],
                schoolLocalizations: [],
                schoolAdministrativeDependencies: [],
                partnershipTypes: [],
                classMultiserie: [
                    {
                        key: 'false',
                        value: "Regular"    
                    },
                    {
                        key: 'true',
                        value: "Multisseriada"    
                    },
                ],
                shifts: [],
                programs: [],
                programsFormulaVitoria: [],
                programsEnsinoMedioGestaoNota10: [],
                programsEnsinoRegular: [],
                programsAlfabetizacaoAceleracao: [],
                programsGestaoDaAlfabetizacao: [],
                programsSistematicaAcompanhamentoDialogos: [],
                detailings: [{
                        id: "U",
                        name: "Estado"
                    },
                    {
                        id: "M",
                        name: "Município"
                    },
                    {
                        id: "E",
                        name: "Escola"
                    },
                    {
                        id: "T",
                        name: "Turma"
                    }
                ],
                detailingsCompleto: [{
                    id: "U",
                    name: "Estado"
                },
                {
                    id: "M",
                    name: "Município"
                },
                {
                    id: "E",
                    name: "Escola"
                },
                {
                    id: "T",
                    name: "Turma"
                },
                {
                    id: "G",
                    name: "Regional UF"
                },
                {
                    id: "R",
                    name: "Regional Municipal"
                },
                {
                    id: "8",
                    name: "Grupo de Município IAS"
                }
            ]};

            vm.filter = {
                state: null,
                stateRegional: null,
                city: null,
                cityRegional: null,
                cityDistrict: null,
                schoolYear: null,
                schoolType: null,
                schoolLocalization: null,
                schoolAdministrativeDependency: null,
                partnershipType: null,
                detailing: null,
                shift: null,
                program: null,
                stateUFRegional: true
            };
            vm.view = {
                available: true,
                searchWasPerformed: false,
                currentPage: null,
                allSchoolType: {
                    id: '0',
                    name: 'Todos'
                },
                allSchoolLocalization: {
                    id: '0',
                    nome: 'Todas'
                },
                allSchoolAdministrativeDependency: {
                    id: '0',
                    nome: 'Todas'
                },
                allClassMultiserie: {
                    key: '0',
                    value: 'Todos'
                },
                allShift: {
                    key: '0',
                    value: 'Todos'
                }
            };
            vm.result = {
                reportId: null,
                reportDataCount: 0,
                reportFields: [],
                reportData: [],
                calculations: {}
            };

            vm.showdetails = function(id) {
                var obj = vm.model.detailings.filter(function(detailing){ return detailing.id === id });
                return obj;
            };
            vm.model.states = reportService.loadStates();
            vm.model.schoolYears = reportService.loadSchoolYears();
            vm.model.schoolTypes = reportService.loadShoolTypes();
            vm.model.schoolLocalizations = reportService.loadLocalizations();
            vm.model.schoolAdministrativeDependencies = reportService.loadAdministrativeDependencies();
            reportService.loadClassShifts().then(function(data) {
                vm.model.shifts = angular.copy(data);
            }, function() {
                vm.model.shifts = [];
            });

            // Por padrão, o detalhamento do relatório é feito por turma.
            vm.filter.detailing = vm.model.detailings[3];
            // Por padrão, o tipo da escola é "Todos".
            vm.filter.schoolType = vm.view.allSchoolType;
            // Por padrão, a localização da escola é "Todas".
            vm.filter.schoolLocalization = vm.view.allSchoolLocalization.id;
            // Por padrão, a dependência administrativa da escola é "Todas".
            vm.filter.schoolAdministrativeDependency = vm.view.allSchoolAdministrativeDependency;
            // Por padrão, o tipo de turma é "Todos".
            vm.filter.classMultiserie = vm.view.allClassMultiserie;

            // TODO Realizar o tratamento adequado para o caso de não ter o report no parâmetro do $state (avisar ou já redirecionar para a tela base?)
            vm.result.report = $state.params.report;
            if (is.not.existy(vm.result.report)) {
                vm.view.available = false; // TODO Espalhar em toda a interface esse controle.
                toastService.error('Não foi possível recuperar as informações do relatório.');
            }

            programasService.ativosRelatorio().then(function(programs){   
                angular.forEach(programs, function(value){  
                    vm.model.programs.push({
                        id: value.id,
                        nome: value.nome
                    });
                });
            });

            programasService.ativosRelatorio().then(function(programsFormulaVitoria){   
                angular.forEach(programsFormulaVitoria, function(value){  
                    if (is.inArray(value.id, REPORT_PROGRAMS.IDS_FORMULA_VITORIA)) {
                        vm.model.programsFormulaVitoria.push({
                            id: value.id,
                            nome: value.nome
                        });
                    }
                });
            });

            programasService.ativosRelatorio().then(function(programsEnsinoMedioGestaoNota10){
                // var idsPrograms = REPORT_PROGRAMS.IDS_GESTAO_NOTA_10;
                // idsPrograms = idsPrograms.concat(REPORT_PROGRAMS.IDS_GESTAO_NOTA_10_ENSINO_MEDIO);
                var idsPrograms = REPORT_PROGRAMS.IDS_GESTAO_NOTA_10_ENSINO_MEDIO;

                angular.forEach(programsEnsinoMedioGestaoNota10, function(value){  
                    if (is.inArray(value.id, idsPrograms)) {
                        vm.model.programsEnsinoMedioGestaoNota10.push({
                            id: value.id,
                            nome: value.nome
                        });
                    }
                });
            });
            
            programasService.ativosRelatorio().then(function(programsEnsinoRegular){
                var idsPrograms = REPORT_PROGRAMS.IDS_CIRCUITO_CAMPEAO;
                idsPrograms = idsPrograms.concat(REPORT_PROGRAMS.IDS_GESTAO_NOTA_10);

                angular.forEach(programsEnsinoRegular, function(value){
                    if (is.inArray(value.id, idsPrograms)) {
                        vm.model.programsEnsinoRegular.push({
                            id: value.id,
                            nome: value.nome
                        });
                    }
                });
            });

            programasService.ativosRelatorio().then(function(programsAlfabetizacaoAceleracao){   
                angular.forEach(programsAlfabetizacaoAceleracao, function(value){
                    if (is.inArray(value.id, REPORT_PROGRAMS.IDS_ALFABETIZACAO_ACELERACAO)) {
                        vm.model.programsAlfabetizacaoAceleracao.push({
                            id: value.id,
                            nome: value.nome
                        });
                    }
                    if (is.inArray(value.id, REPORT_PROGRAMS.IDS_GESTAO_ALFABETIZACAO)) {
                        vm.model.programsGestaoDaAlfabetizacao.push({
                            id: value.id,
                            nome: value.nome
                        });

                        if(vm.result.report.id ===  REPORT_GROUP.PERFIL_TURMA.GESTAO_DA_ALFABETIZACAO) {
                            vm.filter.program = value;
                        }

                    }

                });
            });
            
            programasService.ativosRelatorio().then(function(programsSistematicaAcompanhamentoDialogos){   
                angular.forEach(programsSistematicaAcompanhamentoDialogos, function(value){
                    if (is.inArray(value.id, REPORT_PROGRAMS.IDS_SISTEMATICA_ACOMPANHAMENTO_DIALOGOS)) {
                        vm.model.programsSistematicaAcompanhamentoDialogos.push({
                            id: value.id,
                            nome: value.nome
                        });

                        if(vm.result.report.id ===  REPORT_GROUP.PERFIL_TURMA.SISTEMATICA_ACOMPANHAMENTO_DIALOGOS) {
                            vm.filter.program = value;
                        }
                    }
                });
            });

        })();

        let months = [
            { id: 1, name: 'Janeiro' },
            { id: 2, name: 'Fevereiro' },
            { id: 3, name: 'Março' },
            { id: 4, name: 'Abril' },
            { id: 5, name: 'Maio' },
            { id: 6, name: 'Junho' },
            { id: 7, name: 'Julho' },
            { id: 8, name: 'Agosto' },
            { id: 9, name: 'Setembro' },
            { id: 10, name: 'Outubro' },
            { id: 11, name: 'Novembro' },
            { id: 12, name: 'Dezembro' }
        ];

        vm.loadMonths = function() {
            return months;
        };
    }

}());