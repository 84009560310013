(function() {
  'use strict';

  angular.module('app.aluno').controller('AlunoController', AlunoController);
  AlunoController.$inject = ['ALUNO_MESSAGES', 'APP_STATE', 'VALIDATION_PATTERN', '$state', 'Aluno', 
    'dialogService', 'toastService', '$scope', 'APP_EVENT', 'alunoService', 'ALUNO_SEARCH_BY',
    'HTTP_STATUS_CODE', 'SecurityManagerFactory'];

  function AlunoController(ALUNO_MESSAGES, APP_STATE, VALIDATION_PATTERN, $state, Aluno, 
    dialogService, toastService, $scope, APP_EVENT, alunoService, ALUNO_SEARCH_BY,
    HTTP_STATUS_CODE, SecurityManagerFactory) {

    var vm = this;
    var MODAL_ATTR_ALUNOS = 'aluno/partials/aluno-search-modal-atributos.html';

    vm.mascaraCpf= function(valor) {
      return valor.replace(VALIDATION_PATTERN, "\$1.\$2.\$3\-\$4");
    }

    vm.clearSearchValue = function() {
      vm.searchValue = "";
    };

    vm.goNewPage = function() {
      $state.go(APP_STATE.ALUNO.NEW);
    };

    vm.goImportTemplatePage = function() {
      $state.go(APP_STATE.ALUNO.IMPORT_TEMPLATE);
  };

    vm.goEditPage = function(id) {
      $state.go(APP_STATE.ALUNO.EDIT, { id: id });
    };

    vm.stayPage = function(){
      $state.reload();
    };

    vm.delete = function(aluno) {
      dialogService.delete().then(function() {
        alunoService.delete(aluno.id)
          .then(function(response) {
              if (response.responseStatusCode == HTTP_STATUS_CODE.OK) {
                toastService.success(ALUNO_MESSAGES.success.deleted);
              } else if (response.responseStatusCode == HTTP_STATUS_CODE.ACCEPTED) {
                toastService.success(ALUNO_MESSAGES.success.inactivated);
              }

              vm.search();
          }, function(response) {
              toastService.error({
                status: response.status,
                message: response.data.message
              });
          });
      }, function() {});
    };
    vm.resetSenha = function(aluno){
      alunoService.resetSenha(aluno)
        .then(function(response){
          if (response.resultado == true) {
                toastService.success(ALUNO_MESSAGES.success.reset);
              } else {
                toastService.error(ALUNO_MESSAGES.error.reset);
              }
        }, function(response) {
              toastService.error({
                status: response.status,
                message: response.data.message
              });
          });  
    };

    vm.onChangeFilter = function() {
      vm.alunosSearch.$setPristine();
    }

    vm.search = function() {
      var filter = {};
      vm.alunos = [];
      vm.searchWasPerformed = false;

      filter[vm.searchType.field] = vm.searchValue;

      if (vm.searchValue) {

        filter.page = 0;
        filter.size = 10;

        vm.filterAlunoToPaginate = angular.copy(filter);

        _findAlunosByFilters(filter);
      }
    };

    vm.onPageChangeAlunos = function (page, size) {
      vm.filterAlunoToPaginate.page = page;
      vm.filterAlunoToPaginate.size = size;
      _findAlunosByFilters(vm.filterAlunoToPaginate);
    };

    var _findAlunosByFilters = function (filter) {
            
      alunoService.searchByPageable(filter).then(function(data) {
              vm.alunos = angular.copy(data.content);
              vm.alunosTotal = data.totalElements;
          })
          .catch(function() {
            toastService.error(ALUNO_MESSAGES.error.search);
          })
          .finally(function() {
              vm.searchWasPerformed = true;
              vm.notFound = is.empty(vm.alunos);
              vm.alunosSearch.$setPristine();
          });
    };


    vm.modalAtributos = function(aluno) {
      dialogService.modal(MODAL_ATTR_ALUNOS, function($uibModalInstance) {
        var _vm = this;

        _vm.cancelar = function() {
          $uibModalInstance.dismiss('cancel');
        };

        alunoService.get(aluno.id)
          .then(function(response) {
            _vm.atributos = response.atributosEntidadesDTO;
          })
          .catch(function(error) {
            _vm.atributos = [];
            _vm.cancelar();
            toastService.error(messages.error.attributes);
          });
      });
    };

    $scope.$on(APP_EVENT.ALUNO.REFRESH, function() {
      vm.search();
    });

    (function() {
        vm.hasPermissionView = SecurityManagerFactory.hasPermission('ALU:VI');
        vm.alunos = [];
        vm.nomeSearch = '';
        vm.searchValue = '';
        vm.SEARCH_BY = ALUNO_SEARCH_BY;
        vm.searchType = vm.SEARCH_BY.NAME;
        vm.validation = { hasError: false, message: '' };
        vm.notFound = false;
        vm.searchWasPerformed = false;
        vm.filterAlunoToPaginate = {};

        $scope.$on(APP_EVENT.ALUNO.REFRESH, function() {
          vm.search();
        });
    }());
  }
}());