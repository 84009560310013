(function() {
    'use strict';

    angular.module('app.coletaRubrica.resposta').constant('COLETA_RESPOSTA_MESSAGES', {
      info: {
        importTitle: 'Importar Resposta'
      },
      success: {
        import: 'A coleta foi importada com sucesso.',
        export: 'A coleta foi exportada com sucesso.'
      },
      error: {
        import: 'Ocorreu um erro durante a importação.',
        cannotImport: 'Somente é possível importar, se a coleta estiver nas seguintes situações: '
      },
      warning: {
        deleteResponses: 'Caso a coleta já possua respostas, elas serão removidas e substituídas pelas informadas na planilha importada. Tem certeza disso?'
      }
    });

}());
