/*
jslint
    devel: true, maxerr: 10
*/

/*
global
    required: true
*/

/*
 * Directive for Bread Crumb into the Application Core Module
 * @author iFactory Solutions Ltda.
 * @version 1.0.0
 * @since 1.0.0
 */
(function() {
    'use strict';

    angular.module("app.core").directive("iasBreadCrumb", IasBreadCrumb);
    IasBreadCrumb.$inject = ["$rootScope", "$state"];

    function IasBreadCrumb($rootScope, $state) {
        return {
            restrict: "E",
            scope: {},
            templateUrl: "core/directives/breadcrumb/partials/ias-breadcrumb.tpl.html",
            link: function(scope, element, attr) {
                scope.state = $state.current;
                scope.data = $state.current.data;
                scope.event = null;

                scope.goState = function(stateName) {
                    $state.go(stateName);
                };

                $rootScope.$on("$stateChangeSuccess", function (event, toState, toParams, fromState, fromParams) {
                    var data = {
                        toState: toState,
                        toParams: toParams,
                        fromState: fromState,
                        fromParams: fromParams
                    };
                    
                    $rootScope.$emit('ias.stateChangeSuccess:emit', data);
                });

                $rootScope.$on('ias.stateChangeSuccess:emit', function(event, data) {
                    scope.state = $state.current;
                    scope.event = event;
                    scope.data = data;
                });
            }
        };
    }
})();
