(function() {
    'use strict';

    angular.module('app.core').constant('MENU', [{
            LABEL: 'Cadastros',
            STATE: '',
            SUBMENU: [
                { LABEL: 'Critérios de Seleção', STATE: 'app.criterioSelecao.search', PERMISSION: 'CRS' },
                { LABEL: 'Programas', STATE: 'app.programa.search', PERMISSION: 'PRO' },
                { LABEL: 'UFs', STATE: 'app.uf.search', PERMISSION: 'UNF' },
                { LABEL: 'Municípios', STATE: 'app.municipios.search', PERMISSION: 'MUN' },
                { LABEL: 'Períodos Letivos', STATE: 'app.periodoLetivo.search', PERMISSION: 'PEL' },
                { LABEL: 'Grupos de Municípios IAS', STATE: 'app.grupoMunicipio.search', PERMISSION: 'GRM' },
                { LABEL: 'Atributos', STATE: 'app.atributo.search', PERMISSION: 'ATR' },
                { LABEL: 'Rubricas', STATE: 'app.rubrica.search', PERMISSION: 'RUB' },
                { LABEL: 'Disciplinas', STATE: 'app.disciplina.search', PERMISSION: 'DIS' }
            ]
        },
        {
            LABEL: 'Parcerias',
            STATE: '',
            SUBMENU: [
                { LABEL: 'Vincular Parceria', STATE: 'app.contrato.search', PERMISSION: 'PAR' },
                { LABEL: 'Escolas', STATE: 'app.escola.search', PERMISSION: 'ESC' },
                { LABEL: 'Turmas', STATE: 'app.turmas.search', PERMISSION: 'TUR' },
                { LABEL: 'Professores', STATE: 'app.professor.search', PERMISSION: 'PRF' },
                { LABEL: 'Alunos', STATE: 'app.aluno.search', PERMISSION: 'ALU' }
            ]
        },
        {
            LABEL: 'Formulários',
            STATE: '',
            SUBMENU: [
                { LABEL: 'Medidas', STATE: 'app.medida.search', PERMISSION: 'MED' },
                { LABEL: 'Escalas', STATE: 'app.escala.search', PERMISSION: 'ECL' },
                { LABEL: 'Opções', STATE: 'app.opcao.search', PERMISSION: 'OPC' },
                { LABEL: 'Cadastrar Formulários', STATE: 'app.formulario.search', PERMISSION: 'FOM' }
            ]
        },
        {
            LABEL: 'Coletas',
            STATE: '',
            SUBMENU: [
                { LABEL: 'Cadastrar Coletas', STATE: 'app.coleta.search', PERMISSION: 'COL' },
                { LABEL: 'Cadastrar Coletas Rubricas', STATE: 'app.coletaRubrica.search', PERMISSION: 'COR' },
                { LABEL: 'Respostas', STATE: 'app.coleta.resposta', PERMISSION: 'REC' }
            ]
        },
        {
            LABEL: 'Relatórios',
            STATE: '',
            SUBMENU: [
                { LABEL: 'Acompanhamento Mensal', STATE: 'app.relatorios.acompanhamento-mensal', PERMISSION: 'REL' },
                // { LABEL: 'Acompanhamento Semestral', STATE: 'app.relatorios.acompanhamento-semestral', PERMISSION: 'REL' },
                { LABEL: 'Acompanhamento Anual', STATE: 'app.relatorios.acompanhamento-anual', PERMISSION: 'REL' },
                { LABEL: 'Perfil da Turma', STATE: 'app.relatorios.perfil-turma', PERMISSION: 'REL' },
                { LABEL: 'Monitoramento', STATE: 'app.relatorios.base-monitoramento', PERMISSION: 'REL' },
                { LABEL: 'Resultado Final', STATE: 'app.relatorios.resultado-final', PERMISSION: 'REL' },
                { LABEL: 'Resultado Bimestral', STATE: 'app.relatorios.resultado-bimestral', PERMISSION: 'REL' },
                { LABEL: 'Outros', STATE: 'app.relatorios.outros', PERMISSION: 'REL' }
            ]
        },
        {
            LABEL: 'Segurança',
            STATE: '',
            SUBMENU: [
                { LABEL: 'Usuários', STATE: 'app.usuario.search', PERMISSION: 'USU' },
                { LABEL: 'Perfil de Acesso', STATE: 'app.perfilAcesso.search', PERMISSION: 'PLA' }
            ]
        }
    ]);

}());
