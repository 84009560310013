/*
jslint
    devel: true, maxerr: 10
*/

/*
global
    required: true
*/

/*
 * Relatórios Module
 * @module constants for class profile report
 * @author iFactory Solutions Ltda.
 * @version 1.0.0
 * @since 1.0.0
 */
(function() {
    'use strict';

    /* Lista de unidades escolares */
    angular.module('app.relatorios').constant('OUTROS_MEDIA_DIAS_LETIVOS_FIELDS', [ 
        { name: 'id', type: 'integer', calculateTotal: false, showFooter: false },
        { name: 'localidade', type: 'string', calculateTotal: false, showFooter: false  },
        { name: 'totalTurmas', hide:true, type: 'integer', calculateTotal: true, calculateType: 'sum'  },
        { name: 'janeiro', type: 'integer', hide:true, calculateTotal: true, calculateType: 'sum' },
        { name: 'mediaJaneiro', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'janeiro', referenceTotalField: 'totalTurmas' },
        { name: 'fevereiro', type: 'integer', hide:true, calculateTotal: true, calculateType: 'sum' },
        { name: 'mediaFevereiro', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'fevereiro', referenceTotalField: 'totalTurmas' },
        { name: 'marco', type: 'integer', hide:true, calculateTotal: true, calculateType: 'sum' },
        { name: 'mediaMarco', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'marco', referenceTotalField: 'totalTurmas' },
        { name: 'abril', type: 'integer', hide:true, calculateTotal: true, calculateType: 'sum' },
        { name: 'mediaAbril', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'abril', referenceTotalField: 'totalTurmas' },
        { name: 'maio', type: 'integer', hide:true, calculateTotal: true, calculateType: 'sum' },
        { name: 'mediaMaio', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'maio', referenceTotalField: 'totalTurmas' },
        { name: 'junho', type: 'integer', hide:true, calculateTotal: true, calculateType: 'sum' },
        { name: 'mediaJunho', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'junho', referenceTotalField: 'totalTurmas' },
        { name: 'julho', type: 'integer', hide:true, calculateTotal: true, calculateType: 'sum' },
        { name: 'mediaJulho', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'julho', referenceTotalField: 'totalTurmas' },
        { name: 'agosto', type: 'integer', hide:true, calculateTotal: true, calculateType: 'sum' },
        { name: 'mediaAgosto', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'agosto', referenceTotalField: 'totalTurmas' },
        { name: 'setembro', type: 'integer', hide:true, calculateTotal: true, calculateType: 'sum' },
        { name: 'mediaSetembro', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'setembro', referenceTotalField: 'totalTurmas' },
        { name: 'outubro', type: 'integer', hide:true, calculateTotal: true, calculateType: 'sum' },
        { name: 'mediaOutubro', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'outubro', referenceTotalField: 'totalTurmas' },
        { name: 'novembro', type: 'integer', hide:true, calculateTotal: true, calculateType: 'sum' },
        { name: 'mediaNovembro', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'novembro', referenceTotalField: 'totalTurmas' },
        { name: 'dezembro', type: 'integer', hide:true, calculateTotal: true, calculateType: 'sum' },
        { name: 'mediaDezembro', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'dezembro', referenceTotalField: 'totalTurmas' },
    ]);
}());