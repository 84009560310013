/*
jslint
    devel: true, maxerr: 10
*/

/*
global
    required: true
*/

/*
 * Home Controller
 * @description The home controller for the home page, for authenticated users.
 * @author iFactory Solutions Ltda.
 * @version 1.0.0
 * @since 1.0.0
 */
(function() {
    'use strict';

    angular.module('app.home').controller('HomeController', HomeController);
    HomeController.$inject = [];

    function HomeController() {
        var vm = this;        
    }

}());