(function() {
    'use strict';

    angular.module('app.core').directive('iasSimpleMarkdown',['$compile', function($compile) {
        return {
        scope: {
            textMarked: '=text'
        },
          template: "",
          link: function(scope, element) {

            var template = function(textMarked){

                var aux = "<span>";
                var bold = false;
                var italic = false;
                if(textMarked){
                    for(var i=0;i<textMarked.length;i++){
                        var c = textMarked.substring(i,i+1);
                        if(c == "*"){
                            if(bold){
                                aux += "</b>";
                                bold = false;
                            }else{
                                aux += "<b>";
                                bold = true;
                            }
                        }else if(c=="_"){
                            if(italic){
                                aux+= "</i>";
                                italic = false;
                            }else{
                                aux+= "<i>";
                                italic=true;
                            }
                        
                        }else if(c.charCodeAt(0)==10){
                            aux+="<br/>"
                        }else{
                            aux+=c;
                        }
                    }
                }
                aux += "</span>";
                return aux;
            }(scope.textMarked);

            element.html(template);
            $compile(element.contents())(scope);
        }          
          

        };
      }]);
})();
