(function() {
    'use strict';

    angular.module('app.municipios').controller('MunicipiosController', MunicipiosController);
    MunicipiosController.$inject = ['APP_STATE', 'APP_EVENT', 'SEARCH_BY_FIELD', '$state', '$scope', '$uibModal',
        'dialogService', 'municipiosService', 'toastService', 'SecurityManagerFactory'
    ];

    function MunicipiosController(APP_STATE, APP_EVENT, SEARCH_BY_FIELD, $state, $scope, $uibModal,
        dialogService, municipiosService, toastService, SecurityManagerFactory) {

        var vm = this;

        vm.goNewPage = function() {
            $state.go(APP_STATE.MUNICIPIOS.NEW);
        };

        vm.goEditPage = function(id) {
            $state.go(APP_STATE.MUNICIPIOS.EDIT, { id: id });
        };

        vm.clearSearchValue = function() {
            vm.searchValue = "";
            vm.searchValueModel = "";
        };

        vm.search = function() {
            vm.municipios = [];
            vm.municipiosTotal = 0;
            vm.searchWasPerformed = false;
            vm.searchValue = vm.searchValueModel || vm.searchValue;

            if (!vm.searchValueModel) {
                vm.searchValueModel = vm.searchValue;
            }

            if (vm.searchValue) {
                var filter = {};
                filter[vm.searchType.field] = vm.searchValue;

                municipiosService.filter(filter).then(function(data) {
                        vm.municipios = angular.copy(data);
                    }, function(response) {
                        toastService.error({
                            status: response.status,
                            message: response.data.message
                        });
                    })
                    .finally(function() {
                        vm.searchWasPerformed = true;
                        vm.municipiosTotal = vm.municipios.length;
                        vm.formSearch.$setPristine();
                    });
            }
        };

        vm.delete = function(municipio) {
            dialogService.delete().then(function() {
                municipiosService.delete({id: municipio.id}).then(function(response) {
                        if (response.responseStatusCode == 200) {
                            toastService.success("Registro removido com sucesso.");
                        } else if (response.responseStatusCode == 202) {
                            toastService.success("Registro inativado com sucesso.");
                        }
                    vm.search();
                }, function(response) {
                    toastService.error({
                        status: response.status,
                        message: response.data.message
                    });
                });
            }, function() {});
        };

        (function() {
            vm.hasPermissionView = SecurityManagerFactory.hasPermission('MUN:VI');
            vm.municipios = [];
            vm.searchValue = "";
            vm.searchValueModel = "";
            vm.SEARCH_BY = SEARCH_BY_FIELD;
            vm.searchType = vm.SEARCH_BY.NAME;
            vm.searchWasPerformed = false;

            $scope.$on(APP_EVENT.MUNICIPIOS.SAVED, function() {
                toastService.success("Operação realizada com sucesso.");
                vm.search();
            });

            $scope.$on(APP_EVENT.MUNICIPIOS.REFRESH, function() {
                vm.search();
            });

        }());

    }

}());