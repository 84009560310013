(function(){
    'use strict';

    angular.module('app.periodoLetivo').service('periodoLetivoService', PeridoLetivoService);
    PeridoLetivoService.$inject = ['PeriodoLetivo', 'STATUS_ENUM'];

    function PeridoLetivoService(PeriodoLetivo, STATUS_ENUM) {

        this.filter = function(filter) {
            return PeriodoLetivo.filter(filter).$promise;            
        };

        this.get = function(id) {
            return PeriodoLetivo.get({ id: id }).$promise;
        };

        this.save = function(peridoLetivo) {
            var data = angular.copy(peridoLetivo);

            data.dataInicio = moment(data.dataInicio, 'DD/MM/YYYY').format('YYYY-MM-DD');
            data.dataFim = moment(data.dataFim, 'DD/MM/YYYY').format('YYYY-MM-DD');

            var entity = new PeriodoLetivo(data);
            
            if (entity.id) {
                return entity.$update();
            } else {
                return entity.$save();
            }
        };

        this.delete = function(programa) {
            return PeriodoLetivo.delete({id: programa.id}).$promise;
        };

        this.ativos = function() {
            return PeriodoLetivo.ativo();
        };
    
    }

}());
