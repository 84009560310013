(function() {
    'use strict';

    angular.module('app.core').directive("iasRandomImages", IasRandomImages);

    function IasRandomImages() {
        return {
            scope: {
                images: '='
            },
            restrict: "E",
            template: '<img ng-src="{{src}}" />',
            link: function(scope, element, attr) {
                var DEFAULT_IMAGES = ["contents/images/logo_IAS_1.jpg", "contents/images/logo_IAS_2.jpg", "contents/images/logo_IAS_3.jpg", "contents/images/logo_IAS_4.jpg"];

                var images = scope.images || DEFAULT_IMAGES;
                var img = images[Math.floor(Math.random()*images.length)];
                scope.src = img;
            }
        };
    }

})();

