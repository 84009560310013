(function() {
    'use strict';

    angular.module('app.grupoMunicipio').controller('GrupoMunicipioController', GrupoMunicipioController);
    GrupoMunicipioController.$inject = ['APP_STATE', 'APP_EVENT', 'SEARCH_BY_FIELD', '$state', '$scope','$uibModal',
        'dialogService', 'grupoMunicipioService', 'toastService', 'SecurityManagerFactory', 'HTTP_STATUS_CODE'];

    function GrupoMunicipioController(APP_STATE, APP_EVENT, SEARCH_BY_FIELD, $state, $scope, $uibModal,
        dialogService, grupoMunicipioService, toastService, SecurityManagerFactory, HTTP_STATUS_CODE) {

        var vm = this;

        vm.goNewPage = function() {
            $state.go(APP_STATE.GRUPO_MUNICIPIO.NEW);
        };

        vm.goEditPage = function(id) {
            $state.go(APP_STATE.GRUPO_MUNICIPIO.EDIT, { id: id });
        };

        vm.clearSearchValue = function() {
            vm.searchValue = "";
            vm.searchValueModel = "";
        };

        vm.search = function() {
            vm.grupoMunicipios = [];
            vm.grupoMunicipiosTotal = 0;
            vm.searchWasPerformed = false;
            vm.searchValue = vm.searchValueModel || vm.searchValue;

            if (!vm.searchValueModel) {
                vm.searchValueModel = vm.searchValue;
            }

            if (vm.searchValue) {
                var filter = {};
                filter[vm.searchType.field] = vm.searchValue;

                grupoMunicipioService.filter(filter)
                    .then(function(data) {
                        vm.grupoMunicipios = angular.copy(data);
                    }, function(response) {
                        toastService.error({
                            status: response.status,
                            message: response.data.message
                        });
                    })
                    .finally(function() {
                        vm.searchWasPerformed = true;
                        vm.grupoMunicipiosTotal = vm.grupoMunicipios.length;
                        vm.formSearch.$setPristine();
                    });
            }
        };

        vm.showItems = function(grupoMunicipio) {
            var itemFormModal = $uibModal.open({
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl: 'grupo-municipio/partials/grupo-municipio-search-item-modal.html',
                controllerAs: 'vm',
                size: 'lg',
                controller: function($uibModalInstance) {
                    var _vm = this;
                    _vm.items = [];
                    _vm.modalTitle = grupoMunicipio.nome;

                    if (grupoMunicipio.grupoMunicipios) {
                        _vm.items = angular.copy(grupoMunicipio.grupoMunicipios);
                    }

                    _vm.cancel = function() {
                        $uibModalInstance.dismiss('cancel');
                    };
                }
            });

            itemFormModal.result.then(new Function(), new Function());
        };

        vm.delete = function(grupoMunicipio, index) {
            dialogService.delete().then(function() {
                grupoMunicipioService.delete({id: grupoMunicipio.id})
                    .then(function(response) {
                        if (response.responseStatusCode == HTTP_STATUS_CODE.OK) {
                            toastService.success('Registro removido com sucesso.');
                        } else if (response.responseStatusCode == HTTP_STATUS_CODE.ACCEPTED) {
                            toastService.success('Registro inativado com sucesso.');
                        }
                        vm.search();
                    }, function(response) {
                        toastService.error({
                            status: response.status,
                            message: response.data.message
                        });
                    });
            }, function() {});
        };

        (function() {
            vm.hasPermissionView = SecurityManagerFactory.hasPermission('GRM:VI');
            vm.grupoMunicipios = [];
            vm.searchValue = "";
            vm.searchValueModel = "";
            vm.SEARCH_BY = SEARCH_BY_FIELD;
            vm.searchType = vm.SEARCH_BY.NAME;
            vm.searchWasPerformed = false;

            $scope.$on(APP_EVENT.GRUPO_MUNICIPIO.SAVED, function() {
                toastService.success("Operação realizada com sucesso.");
                vm.search();
            });

            $scope.$on(APP_EVENT.GRUPO_MUNICIPIO.REFRESH, function() {
                vm.search();
            });

        }());

    }

}());