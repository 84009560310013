(function() {
    'use strict';

    angular.module('app.areaConhecimento').factory('AreaConhecimento', AreaConhecimento);
    AreaConhecimento.$inject = ['$resource', 'APP_PROPERTY'];

    function AreaConhecimento($resource, APP_PROPERTY) {
        return $resource(APP_PROPERTY.URL + '/areas/:id', { id: '@id' }, {
            update: {
                method: 'PUT'
            },
            get: {
                method: 'GET'
            },
            filter: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/areas'
            },
            porNome: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/areas?nome=:nome'
            },
            ativo: {
                method: 'GET',
                url: APP_PROPERTY.URL + '/areas/ATIVO',
                isArray: true
            },
            findByStatus: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/areas/:status'
            }
        });

    }

}());