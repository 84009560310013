(function(){
    'use strict';

    angular.module('app.aluno', ['app.core']).config(config);
    config.$inject = ['$stateProvider', 'APP_STATE'];

    function config($stateProvider, APP_STATE) {
        $stateProvider
            .state(APP_STATE.ALUNO.BASE, {
                url: '/alunos',
                abstract: true,
                views: {
                    'content': {
                        template: '<div ui-view="internal"></div>',
                        controller: 'AlunoController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state(APP_STATE.ALUNO.SEARCH, {
                url: '/search',
                views: {
                    'internal': {
                        templateUrl: 'aluno/partials/aluno-search.html'
                    }
                },
                data: {
                    permission: "ALU:RE",
                    breadCrumb: [{
                            display: "Parcerias",
                            active: false
                        },
                        {
                            display: "Alunos",
                            active: true
                        }
                    ],
                    search: {
                        enabled: false
                    }
                }
            })
            .state(APP_STATE.ALUNO.NEW, {
                url: '/new',
                views: {
                    'internal': {
                        templateUrl: 'aluno/partials/aluno-form.html',
                        controller: 'AlunoFormController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    permission: "ALU:CR",
                    breadCrumb: [{
                            display: "Parcerias",
                            active: false
                        },
                        {
                            display: "Alunos",
                            active: false,
                            stateName: APP_STATE.ALUNO.SEARCH
                        },
                        {
                            display: "Novo",
                            active: true
                        }
                    ],
                    search: {
                        placeHolder: "Digite o nome do aluno",
                        enabled: false
                    }
                }
            })
            .state(APP_STATE.ALUNO.IMPORT_TEMPLATE, {
                url: '/import_template',
                views: {
                    'internal': {
                        templateUrl: 'aluno/partials/aluno-import_template-form.html',
                        controller: 'alunoImportTemplateController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    permission: "ALU:CL",
                    breadCrumb: [{
                            display: "Parcerias",
                            active: false
                        },
                        {
                            display: "Alunos",
                            active: false,
                            stateName: APP_STATE.ALUNO.SEARCH
                        },
                        {
                            display: "Importar",
                            active: true
                        }
                    ],
                    search: {
                        placeHolder: "Digite o nome do aluno",
                        enabled: false
                    }
                }
            })
            .state(APP_STATE.ALUNO.EDIT, {
                url: '/edit/:id',
                views: {
                    'internal': {
                        templateUrl: 'aluno/partials/aluno-form.html',
                        controller: 'AlunoFormController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    permission: "ALU:VI",
                    breadCrumb: [{
                            display: "Parcerias",
                            active: false
                        },
                        {
                            display: "Alunos",
                            active: false,
                            stateName: APP_STATE.ALUNO.SEARCH
                        },
                        {
                            display: "Edição",
                            active: true
                        }
                    ],
                    search: {
                        placeHolder: "Digite o nome do aluno",
                        enabled: false
                    }
                }
            });
    }
}());