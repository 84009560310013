/*
jslint
    devel: true, maxerr: 10
*/

/*
global
    required: true
*/

/*
 * Funcionalidade do Sistema Service
 * @module Funcionalidade do Sistema
 * @author iFactory Solutions Ltda.
 * @version 1.0.0
 * @since 1.0.0
 */
(function() {
    'use strict';

    angular.module('app.funcionalidadeSistema').service('FuncionalidadeSistemaService', FuncionalidadeSistemaService);
    FuncionalidadeSistemaService.$inject = ['FuncionalidadeSistemaResource', 'STATUS_ENUM'];

    function FuncionalidadeSistemaService(resource, STATUS_ENUM) {
        this.search = function(filter) {
            return resource.search(filter).$promise;
        };

        this.get = function(id) {
            return resource.get({
                id: id
            }).$promise;
        };

        this.findActives = function() {
            return resource.findByStatus({
                status: STATUS_ENUM.ATIVO
            }).$promise;
        };

        this.findActiveActions = function(featureId) {
            return resource.findActionsByStatus({
                id: featureId,
                status: STATUS_ENUM.ATIVO
            }).$promise;
        }
    }

}());