(function() {
    'use strict';

    angular.module('app.core').filter('iasStartFrom', IasStartFrom);

    function IasStartFrom() {
        return function (input, start) {
            if (input) {
                start = +start;
                return input.slice(start);
            }

            return [];
        };
    }
}());