/*
jslint
    devel: true, maxerr: 10
*/

/*
global
    required: true
*/

/*
 * Relatórios Module
 * @controller Base Report Controller
 * @author iFactory Solutions Ltda.
 * @version 1.0.0
 * @since 1.0.0
 */
(function() {
    'use strict';

    angular.module('app.relatorios').controller('BaseReportController', BaseReportController);
    BaseReportController.$inject = ['$state', 'APP_STATE', 'RelatoriosService'];

    function BaseReportController($state, APP_STATE, relatoriosService) {
        var vm = this;

        vm.changeSelectedReport = function(report) {
            vm.view.reportEnabled = report.enabled;
        };

        vm.hasSelectedReport = function() {
            return is.existy(vm.model.selectedReport);
        };

        vm.goReportFilterPage = function() {
            if (vm.reportForm.$valid) {
                if (vm.hasSelectedReport() && vm.view.reportEnabled) {
                    $state.go(vm.model.selectedReport.state, { report: vm.model.selectedReport });
                }
            }
        };

        (function() {
            vm.model = {
                selectedReport: null,
                reports: []
            };
            vm.view = {
                reportEnabled: undefined
            };

            if (is.existy($state.current) && is.existy($state.current.data) && is.existy($state.current.data.reportGroup)) {
                vm.model.reports = relatoriosService.loadReportsFromGroup($state.current.data.reportGroup);
            }
        })();
    }

}());