/*
jslint
    devel: true, maxerr: 10
*/

/*
global
    required: true
*/

/*
 * Directive for Ignore Dirty into the Application Core Module
 * @author iFactory Solutions Ltda.
 * @version 1.0.0
 * @since 1.0.0
 */
(function() {
    'use strict';

    angular.module("app.core").directive("iasIgnoreDirty", IasIgnoreDirty);

    function IasIgnoreDirty() {
        return {
            restrict: "A",
            require: "ngModel",
            link: function(scope, element, attrs, ctrl) {
                ctrl.$setPristine = function() {};
                ctrl.$pristine = false;
            }
        };
    }
})();