(function() {
    'use strict';

    angular.module('app.turmas').controller('TurmasController', TurmasController);
    TurmasController.$inject = ['APP_STATE', 'APP_EVENT', 'SEARCH_BY_FIELD', '$state', '$scope', '$uibModal',
        'DialogService', 'turmasService', 'toastService', 'atributoService', 'RelatoriosService', 'dialogService', 'SecurityManagerFactory'
    ];

    function TurmasController(APP_STATE, APP_EVENT, SEARCH_BY_FIELD, $state, $scope, $uibModal,
        DialogService, turmasService, toastService, atributoService, reportService, dialogService, SecurityManagerFactory) {

        var vm = this;
        vm.model = {
            states: reportService.loadStates(),
            schoolYear: reportService.loadSchoolYears(),
            cities: []
        }
        vm.formTurma = {
            states: null,
            schoolYear:null,
            cities: null,
            code: null,
            name: null,
            inepClass: null,
            inepSchool: null
          }

          var preencherCidadesDoEstado = function () {
            if (is.existy(vm.formTurma.state)) {
                vm.model.cities = reportService.loadCitiesOfState(vm.formTurma.state.id);
            } else {
                vm.model.cities = [];
            }
        }
        vm.changeState = function() {
            preencherCidadesDoEstado();
        };


        vm.goNewPage = function() {
            $state.go(APP_STATE.TURMA.NEW);
        };

        vm.goImportTemplatePage = function() {
            $state.go(APP_STATE.TURMA.IMPORT_TEMPLATE);
        };

        vm.goCompetenciasLote = function() {
            $state.go(APP_STATE.TURMA.LOTE);
        };

        vm.goEditPage = function(id) {
            $state.go(APP_STATE.TURMA.EDIT, { id: id });
        };

        vm.clearSearchValue = function() {
            vm.searchValue = "";
            vm.searchValueModel = "";
        };

        vm.search = function() {
            vm.turmas = [];
            vm.turmasTotal = 0;
            vm.searchWasPerformed = false;

            if (vm.isFilterValid()) {
                var filter = {
                    idAnoLetivo:(vm.formTurma.schoolYear != null)? vm.formTurma.schoolYear.id: null,
                    idUf:(vm.formTurma.state != null)? vm.formTurma.state.id: null,
                    idMunicipio:(vm.formTurma.city != null)? vm.formTurma.city.id: null,
                    id: vm.formTurma.code,
                    nome: vm.formTurma.name,
                    codigoINEP: vm.formTurma.inepClass,
                    codigoINEPEscola: vm.formTurma.inepSchool
                }

                filter.page = 0;
                filter.size = 10;

                vm.filterTurmaToPaginate = angular.copy(filter);

                _findTurmasByFilters(filter);
            }
        };


        vm.onPageChangeTurmas = function (page, size) {
            vm.filterTurmaToPaginate.page = page;
            vm.filterTurmaToPaginate.size = size;
            _findTurmasByFilters(vm.filterTurmaToPaginate);
        };


        var _findTurmasByFilters = function (filter) {
            
            turmasService.filterPageable(filter).then(function(data) {
                    vm.turmas = angular.copy(data.content);
                    vm.turmasTotal = data.totalElements;
                })
                .finally(function() {
                    vm.searchWasPerformed = true;
                    vm.formSearch.$setPristine();
                });
        };


        /*vm.isFieldValid = function(str){
          return Boolean(str);
          //false for null,undefined,0,000,"",false
          //true for string "0" and whitespace " ".
        }*/

        vm.isFieldValid = function(e) {
          if(typeof e == "undefined" ||
            e == "" || e == 0 || e == null ||
            e == false){
              return false;
          }
          if(typeof e == "string"){
            if((e.replace(/\s/g,"") == "") ||
              (!/[^\s]/.test(e)) ||
              (/^\s*$/.test(e)) ||
              (e.length == 0)){
              return false;
            }
          }

          return true;
        }

        vm.isFilterValid = function() {
            if(vm.isFieldValid(vm.formTurma.schoolYear) ||
              vm.isFieldValid(vm.formTurma.state) ||
              vm.isFieldValid(vm.formTurma.code) ||
              vm.isFieldValid(vm.formTurma.name) ||
              vm.isFieldValid(vm.formTurma.inepClass) ||
              vm.isFieldValid(vm.formTurma.inepSchool)){
                return true;

            }
            return false;
        }

        vm.clearForm = function() {
          vm.formTurma = {
              states: null,
              schoolYear:null,
              cities: null,
              code: null,
              name: null,
              inepClass: null,
              inepSchool: null
            }
        }

        vm.modalAtributos = function(turma) {
            DialogService.modal(
                'turmas/partials/turmas-search-modal-atributos.html',
                function($uibModalInstance) {
                    var _vm = this;

                    turmasService.get(turma.id).then(function(response) {
                        _vm.atributos = response.atributosEntidadesDTO;
                    }, function(response) {
                        _vm.atributos = [];
                    });

                    _vm.cancelar = function() {
                        $uibModalInstance.dismiss('cancel');
                    };
                }
            );
        };

        vm.delete = function(turma) {
            dialogService.delete().then(function() {
                turmasService.delete({ id: turma.id })
                    .then(function(response) {
                        if (response.responseStatusCode == 200) {
                            toastService.success("Registro removido com sucesso.");
                        } else if (response.responseStatusCode == 202) {
                            toastService.success("Registro inativado com sucesso.");
                        }
                        vm.search();
                    }, function(response) {
                        toastService.error(response);
                    });
            }, function() {});
        };

        (function() {
            vm.hasPermissionView = SecurityManagerFactory.hasPermission('TUR:VI');
            vm.turmas = [];
            vm.searchValue = "";
            vm.searchValueModel = "";
            vm.SEARCH_BY = SEARCH_BY_FIELD;
            vm.searchType = vm.SEARCH_BY.NAME;
            vm.searchWasPerformed = false;
            vm.filterTurmaToPaginate = {};

            $scope.$on(APP_EVENT.TURMA.SAVED, function() {
                toastService.success("Operação realizada com sucesso.");
                vm.search();
            });

            $scope.$on(APP_EVENT.TURMA.REFRESH, function() {
                vm.search();
            });

        }());
    }

}());
