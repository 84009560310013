(function(){
    'use strict';

    angular.module('app.coleta.resposta').service('coletaRespostaSolicitacaoService', ColetaRespostaSolicitacaoService);
    ColetaRespostaSolicitacaoService.$inject = ['$q', 'ColetaRespostaSolicitacao'];

    function ColetaRespostaSolicitacaoService($q, ColetaRespostaSolicitacao) {
       
        this.viewById = function(id) {
            return ColetaRespostaSolicitacao.viewById({ id: id }).$promise;
        };

        this.save = function(idSolicitacao) {
           return ColetaRespostaSolicitacao.save(idSolicitacao);
        };

        this.update = function(id, idSolicitacao) {
           var data = idSolicitacao;
           data.idColetaResposta = id; 
           return ColetaRespostaSolicitacao.update(data);
        };

        this.findByTipos = function(){
           return ColetaRespostaSolicitacao.findByTipos();
        }

        this.findByColetaSolicitacaoId = function(id){
            return ColetaRespostaSolicitacao.findByColetaSolicitacaoId({id: id});
        }

        this.findByColetaSolicitacaoAnaliseId = function(id, valor){
            return ColetaRespostaSolicitacao.findByColetaSolicitacaoId({id: id, situacao: valor});
        }

        this.findByColetaSolicitacaoAnaliseAlteradasId = function(id, valor){
            return ColetaRespostaSolicitacao.findByColetaSolicitacaoId({id: id, situacao: valor});
        }

        this.findByColetaIdAndFilters = function(id, filters) {
            var filter = angular.copy(filters);
            filter.id = id;

            return ColetaRespostaSolicitacao.findByColetaId(filter).$promise;
        };

        this.findStatus = function() {
            return $q(function(resolve, reject) {
                ColetaRespostaSolicitacao.findStatus().$promise
                    .then(function(data) {
                        var situacoes = _.map(data, function(item) {
                            return {
                                key: item.id,
                                value: item.name
                            };
                        });

                        resolve(situacoes);
                    }, function(error) {
                        reject(error);
                    });
            });
        };

    }

}());