(function() {
    'use strict';

    angular.module('app.core').constant('APP_EVENT', {
        OPCAO: {
            SAVED: "app.opcao.saved",
            REFRESH: "app.opcao.refresh"
        },
        GRUPO_MUNICIPIO: {
            REFRESH: "app.grupoMunicipio.refresh",
            SAVED: "app.grupoMunicipio.saved"
        },
        UF: {
            SAVED: "app.uf.saved",
            REFRESH: "app.uf.refresh"
        },
        MUNICIPIOS: {
            SAVED: "app.municipios.saved",
            REFRESH: "app.municipios.refresh"
        },
        PROGRAMA: {
            SAVED: "app.programa.saved",
            REFRESH: "app.programa.refresh"
        },
        PERIODO_LETIVO: {
            SAVED: "app.periodoLetivo.saved",
            REFRESH: "app.periodoLetivo.refresh"
        },
        ESCALA: {
            SAVED: "app.escala.saved",
            REFRESH: "app.escala.refresh"
        },
        ESCOLA: {
            SAVED: "app.escola.saved",
            REFRESH: "app.escola.refresh"
        },
        TURMA: {
            SAVED: "app.turmas.saved",
            REFRESH: "app.turmas.refresh"
        },
        ATRIBUTO: {
            SAVED: "app.atributo.saved",
            REFRESH: "app.atributo.refresh"
        },
        COLETA: {
            SAVED: "app.coleta.saved",
            REFRESH: "app.coleta.refresh"
        },
        FORMULARIO: {
            REFRESH: "app.formulario.refresh"
        },
        MEDIDA: {
            REFRESH: "app.medida.refresh"
        },
        RESPOSTA_COLETA: {
            REFRESH: "app.coleta.refresh"
        },
        CONTRATO: {
            SAVED: "app.contrato.saved",
            REFRESH: "app.contrato.refresh"
        },
        USUARIO: {
            REFRESH: "app.usuario.refresh"
        },
        CRITERIO_SELECAO: {
            SAVED: "app.criterioSelecao.saved",
            REFRESH: "app.criterioSelecao.refresh"
        },

        PROFESSOR: {
            REFRESH: "app.professor.refresh"
        },    

        PERFIL_ACESSO: {
            SAVED: "app.perfilAcesso.saved",
            REFRESH: "app.perfilAcesso.refresh"
        },
        ALUNO: {
            REFRESH: "app.aluno.refresh"
        }, 
        RUBRICA: {
            SAVED: "app.rubrica.saved",
            REFRESH: "app.rubrica.refresh"
        },
        DISCIPLINA: {
            SAVED: "app.disciplina.saved",
            REFRESH: "app.disciplina.refresh"
        },

        GENERIC: {
            CLEAR_ITEMS_SELECTED: 'save.event.clearSelectedItens'
        },
        COLETA_RUBRICA: {
            SAVED: "app.coletaRubrica.saved",
            REFRESH: "app.coletaRubrica.refresh"
        },
        
    });

}());