


angular.module('app.relatorios').constant('ACOMPANHAMENTO_SEMESTRAL_REGIONAL_UF_FIELDS', [
        { name: 'ordem',                               type: 'integer' , showFooter: false},
        { name: 'estado',                              type: 'string'  , showFooter: false},
        { name: 'regionalUf',                          type: 'string'  , showFooter: false},

        { name: 'numeroTurmas',                        type: 'integer' , calculateTotal: true, calculateType: 'sum'},
        { name: 'freqProfNaFormacao',                  type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average'},

        { name: 'freqAulasMat',                        type: 'integer', calculateTotal: true, percent:"true", calculateType: 'sum', hide: true },
        { name: 'aulasMat',                            type: 'integer', calculateTotal: true, percent:"true", calculateType: 'sum', hide: true },
        { name: 'freqMediaMensalAlunosPrograma',       type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average',
                referenceField: 'freqAulasMat', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['freqAulasMat'] / data.calculations['aulasMat']) * 100).toFixed(1);
        }},

        { name: 'ano',                                 type: 'integer' , calculateTotal: true, calculateType: 'sum'},
        { name: 'bimestre',                            type: 'integer' , calculateTotal: true, calculateType: 'sum'},

        //
        { name: 'rec',                                 type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'transferenciaRecPercentagem',         type: 'integer', calculateTotal: true,  percent:"true", calculateType: 'average',
                referenceField: 'rec', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['rec'] / data.calculations['bimestre']) * 100).toFixed(1);
        } },
        { name: 'exp',  type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'transferenciaExpPercentagem',         type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average' ,
                referenceField: 'exp', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['exp'] / data.calculations['bimestre']) * 100).toFixed(1);
        }},
        { name: 'numeroDeAbandono',                    type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'percentagemAbandono',                 type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average' ,
                referenceField: 'numeroDeAbandono', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['numeroDeAbandono'] / data.calculations['bimestre']) * 100).toFixed(1);
        }},
        { name: 'atual',                               type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'percentagemMatricula',                type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average',
                referenceField: 'atual', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['atual'] / data.calculations['bimestre']) * 100).toFixed(1);
        }},

        //
        { name: 'totalProblemasPessoais',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'problemasPessoais',                   type: 'integer',calculateTotal: true, percent:"true", calculateType: 'average' ,
                referenceField: 'totalProblemasPessoais', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['totalProblemasPessoais'] / data.calculations['numeroDeAbandono']) * 100).toFixed(1);
        }},
        { name: 'totalConflitoHorarioTransporte',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'conflitoHorarioTransporte',           type: 'integer',calculateTotal: true, percent:"true", calculateType: 'average' ,
        referenceField: 'totalConflitoHorarioTransporte', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['totalConflitoHorarioTransporte'] / data.calculations['numeroDeAbandono']) * 100).toFixed(1);
        }},
        { name: 'totalDificuldadesPropostaPrograma',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'dificuldadesPropostaPrograma',        type: 'integer',calculateTotal: true, percent:"true", calculateType: 'average' ,
                referenceField: 'totalDificuldadesPropostaPrograma', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['totalDificuldadesPropostaPrograma'] / data.calculations['numeroDeAbandono']) * 100).toFixed(1);
        }},
        { name: 'totalOutros',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'outros',                              type: 'integer',calculateTotal: true, percent:"true", calculateType: 'average' ,
                referenceField: 'totalOutros', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['totalOutros'] / data.calculations['numeroDeAbandono']) * 100).toFixed(1);
        }},
        //

        { name: 'sim',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'totalSim',                 type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average'  ,
                referenceField: 'sim', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['sim'] / (data.calculations['sim']+data.calculations['nao'])) * 100).toFixed(1);
        }},
        { name: 'nao',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'totalNao',                 type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average' ,
                referenceField: 'nao', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['nao'] / (data.calculations['sim']+data.calculations['nao'])) * 100).toFixed(1);
        }}
]);

angular.module('app.relatorios').constant('ACOMPANHAMENTO_SEMESTRAL_REGIONAL_MUNICIPAL_FIELDS', [
        { name: 'ordem',                               type: 'integer' , showFooter: false},
        { name: 'estado',                              type: 'string'  , showFooter: false},
        { name: 'cidade',                              type: 'string'  , showFooter: false},
        { name: 'regionalMunicipal',                   type: 'string'  , showFooter: false},

        { name: 'numeroTurmas',                        type: 'integer' , calculateTotal: true, calculateType: 'sum'},
        { name: 'freqProfNaFormacao',                  type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average'},

        { name: 'freqAulasMat',                        type: 'integer', calculateTotal: true, percent:"true", calculateType: 'sum', hide: true },
        { name: 'aulasMat',                            type: 'integer', calculateTotal: true, percent:"true", calculateType: 'sum', hide: true },
        { name: 'freqMediaMensalAlunosPrograma',       type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average',
                referenceField: 'freqAulasMat', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['freqAulasMat'] / data.calculations['aulasMat']) * 100).toFixed(1);
        }},

        { name: 'ano',                                 type: 'integer' , calculateTotal: true, calculateType: 'sum'},
        { name: 'bimestre',                            type: 'integer' , calculateTotal: true, calculateType: 'sum'},

        //
        { name: 'rec',                                 type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'transferenciaRecPercentagem',         type: 'integer', calculateTotal: true,  percent:"true", calculateType: 'average',
                referenceField: 'rec', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['rec'] / data.calculations['bimestre']) * 100).toFixed(1);
        } },
        { name: 'exp',                                 type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'transferenciaExpPercentagem',         type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average' ,
                referenceField: 'exp', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['exp'] / data.calculations['bimestre']) * 100).toFixed(1);
        }},
        { name: 'numeroDeAbandono',                    type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'percentagemAbandono',                 type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average' ,
                referenceField: 'numeroDeAbandono', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['numeroDeAbandono'] / data.calculations['bimestre']) * 100).toFixed(1);
        }},
        { name: 'atual',                               type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'percentagemMatricula',                type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average',
                referenceField: 'atual', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['atual'] / data.calculations['bimestre']) * 100).toFixed(1);
        }},

        //
        { name: 'totalProblemasPessoais',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'problemasPessoais',                   type: 'integer',calculateTotal: true, percent:"true", calculateType: 'average' ,
                referenceField: 'totalProblemasPessoais', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['totalProblemasPessoais'] / data.calculations['numeroDeAbandono']) * 100).toFixed(1);
        }},
        { name: 'totalConflitoHorarioTransporte',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'conflitoHorarioTransporte',           type: 'integer',calculateTotal: true, percent:"true", calculateType: 'average' ,
        referenceField: 'totalConflitoHorarioTransporte', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['totalConflitoHorarioTransporte'] / data.calculations['numeroDeAbandono']) * 100).toFixed(1);
        }},
        { name: 'totalDificuldadesPropostaPrograma',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'dificuldadesPropostaPrograma',        type: 'integer',calculateTotal: true, percent:"true", calculateType: 'average' ,
                referenceField: 'totalDificuldadesPropostaPrograma', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['totalDificuldadesPropostaPrograma'] / data.calculations['numeroDeAbandono']) * 100).toFixed(1);
        }},
        { name: 'totalOutros',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'outros',                              type: 'integer',calculateTotal: true, percent:"true", calculateType: 'average' ,
                referenceField: 'totalOutros', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['totalOutros'] / data.calculations['numeroDeAbandono']) * 100).toFixed(1);
        }},
        //

        { name: 'sim',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'totalSim',                 type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average'  ,
                referenceField: 'sim', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['sim'] / (data.calculations['sim']+data.calculations['nao'])) * 100).toFixed(1);
        }},
        { name: 'nao',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'totalNao',                 type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average' ,
                referenceField: 'nao', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['nao'] / (data.calculations['sim']+data.calculations['nao'])) * 100).toFixed(1);
        }}
]);

angular.module('app.relatorios').constant('ACOMPANHAMENTO_SEMESTRAL_GRUPO_MUNICIPIO_IAS_FIELDS', [
        { name: 'ordem',                               type: 'integer' , showFooter: false},
        { name: 'grupoDeMunicipiosIas',                type: 'string' , showFooter: false },
        { name: 'numeroTurmas',                        type: 'integer' , calculateTotal: true, calculateType: 'sum'},

        { name: 'freqProfNaFormacao',                  type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average'},

        { name: 'freqAulasMat',                        type: 'integer', calculateTotal: true, percent:"true", calculateType: 'sum', hide: true },
        { name: 'aulasMat',                            type: 'integer', calculateTotal: true, percent:"true", calculateType: 'sum', hide: true },
        { name: 'freqMediaMensalAlunosPrograma',       type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average',
                referenceField: 'freqAulasMat', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['freqAulasMat'] / data.calculations['aulasMat']) * 100).toFixed(1);
        }},

        { name: 'ano',                                 type: 'integer' , calculateTotal: true, calculateType: 'sum'},
        { name: 'bimestre',                            type: 'integer' , calculateTotal: true, calculateType: 'sum'},

        //
        { name: 'rec',                                 type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'transferenciaRecPercentagem',         type: 'integer', calculateTotal: true,  percent:"true", calculateType: 'average',
                referenceField: 'rec', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['rec'] / data.calculations['bimestre']) * 100).toFixed(1);
        } },
        { name: 'exp',                                 type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'transferenciaExpPercentagem',         type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average' ,
                referenceField: 'exp', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['exp'] / data.calculations['bimestre']) * 100).toFixed(1);
        }},
        { name: 'numeroDeAbandono',                    type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'percentagemAbandono',                 type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average' ,
                referenceField: 'numeroDeAbandono', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['numeroDeAbandono'] / data.calculations['bimestre']) * 100).toFixed(1);
        }},
        { name: 'atual',                               type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'percentagemMatricula',                type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average',
                referenceField: 'atual', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['atual'] / data.calculations['bimestre']) * 100).toFixed(1);
        }},

        //
        { name: 'totalProblemasPessoais',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'problemasPessoais',                   type: 'integer',calculateTotal: true, percent:"true", calculateType: 'average' ,
                referenceField: 'totalProblemasPessoais', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['totalProblemasPessoais'] / data.calculations['numeroDeAbandono']) * 100).toFixed(1);
        }},
        { name: 'totalConflitoHorarioTransporte',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'conflitoHorarioTransporte',           type: 'integer',calculateTotal: true, percent:"true", calculateType: 'average' ,
        referenceField: 'totalConflitoHorarioTransporte', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['totalConflitoHorarioTransporte'] / data.calculations['numeroDeAbandono']) * 100).toFixed(1);
        }},
        { name: 'totalDificuldadesPropostaPrograma',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'dificuldadesPropostaPrograma',        type: 'integer',calculateTotal: true, percent:"true", calculateType: 'average' ,
                referenceField: 'totalDificuldadesPropostaPrograma', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['totalDificuldadesPropostaPrograma'] / data.calculations['numeroDeAbandono']) * 100).toFixed(1);
        }},
        { name: 'totalOutros',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'outros',                              type: 'integer',calculateTotal: true, percent:"true", calculateType: 'average' ,
                referenceField: 'totalOutros', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['totalOutros'] / data.calculations['numeroDeAbandono']) * 100).toFixed(1);
        }},
        //

        { name: 'sim',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'totalSim',                 type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average'  ,
                referenceField: 'sim', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['sim'] / (data.calculations['sim']+data.calculations['nao'])) * 100).toFixed(1);
        }},
        { name: 'nao',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'totalNao',                 type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average' ,
                referenceField: 'nao', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['nao'] / (data.calculations['sim']+data.calculations['nao'])) * 100).toFixed(1);
        }}
]);

angular.module('app.relatorios').constant('ACOMPANHAMENTO_SEMESTRAL_ESTADO_FIELDS', [
        { name: 'ordem',                               type: 'integer' , showFooter: false},
        { name: 'estado',                              type: 'string'  , showFooter: false},
        { name: 'numeroTurmas',                        type: 'integer' , calculateTotal: true, calculateType: 'sum'},

        { name: 'freqProfNaFormacao',                  type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average'},

        { name: 'freqAulasMat',                        type: 'integer', calculateTotal: true, percent:"true", calculateType: 'sum', hide: true },
        { name: 'aulasMat',                            type: 'integer', calculateTotal: true, percent:"true", calculateType: 'sum', hide: true },
        { name: 'freqMediaMensalAlunosPrograma',       type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average',
                referenceField: 'freqAulasMat', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['freqAulasMat'] / data.calculations['aulasMat']) * 100).toFixed(1);
        }},

        { name: 'ano',                                 type: 'integer' , calculateTotal: true, calculateType: 'sum'},
        { name: 'bimestre',                            type: 'integer' , calculateTotal: true, calculateType: 'sum'},

        //
        { name: 'rec',                                 type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'transferenciaRecPercentagem',         type: 'integer', calculateTotal: true,  percent:"true", calculateType: 'average',
                referenceField: 'rec', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['rec'] / data.calculations['bimestre']) * 100).toFixed(1);
        } },
        { name: 'exp',                                 type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'transferenciaExpPercentagem',         type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average' ,
                referenceField: 'exp', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['exp'] / data.calculations['bimestre']) * 100).toFixed(1);
        }},
        { name: 'numeroDeAbandono',                    type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'percentagemAbandono',                 type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average' ,
                referenceField: 'numeroDeAbandono', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['numeroDeAbandono'] / data.calculations['bimestre']) * 100).toFixed(1);
        }},
        { name: 'atual',                               type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'percentagemMatricula',                type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average',
                referenceField: 'atual', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['atual'] / data.calculations['bimestre']) * 100).toFixed(1);
        }},

        //
        { name: 'totalProblemasPessoais',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'problemasPessoais',                   type: 'integer',calculateTotal: true, percent:"true", calculateType: 'average' ,
                referenceField: 'totalProblemasPessoais', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['totalProblemasPessoais'] / data.calculations['numeroDeAbandono']) * 100).toFixed(1);
        }},
        { name: 'totalConflitoHorarioTransporte',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'conflitoHorarioTransporte',           type: 'integer',calculateTotal: true, percent:"true", calculateType: 'average' ,
        referenceField: 'totalConflitoHorarioTransporte', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['totalConflitoHorarioTransporte'] / data.calculations['numeroDeAbandono']) * 100).toFixed(1);
        }},
        { name: 'totalDificuldadesPropostaPrograma',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'dificuldadesPropostaPrograma',        type: 'integer',calculateTotal: true, percent:"true", calculateType: 'average' ,
                referenceField: 'totalDificuldadesPropostaPrograma', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['totalDificuldadesPropostaPrograma'] / data.calculations['numeroDeAbandono']) * 100).toFixed(1);
        }},
        { name: 'totalOutros',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'outros',                              type: 'integer',calculateTotal: true, percent:"true", calculateType: 'average' ,
                referenceField: 'totalOutros', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['totalOutros'] / data.calculations['numeroDeAbandono']) * 100).toFixed(1);
        }},
        //

        { name: 'sim',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'totalSim',                 type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average'  ,
                referenceField: 'sim', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['sim'] / (data.calculations['sim']+data.calculations['nao'])) * 100).toFixed(1);
        }},
        { name: 'nao',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'totalNao',                 type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average' ,
                referenceField: 'nao', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['nao'] / (data.calculations['sim']+data.calculations['nao'])) * 100).toFixed(1);
        }}
]);

angular.module('app.relatorios').constant('ACOMPANHAMENTO_SEMESTRAL_MUNICIPIO_FIELDS', [
        { name: 'ordem',                               type: 'integer' , showFooter: false},
        { name: 'estado',                              type: 'string'  , showFooter: false},
        { name: 'cidade',                              type: 'string'  , showFooter: false},

        { name: 'numeroTurmas',                        type: 'integer' , calculateTotal: true, calculateType: 'sum'},
        { name: 'freqProfNaFormacao',                  type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average'},

        { name: 'freqAulasMat',                        type: 'integer', calculateTotal: true, percent:"true", calculateType: 'sum', hide: true },
        { name: 'aulasMat',                            type: 'integer', calculateTotal: true, percent:"true", calculateType: 'sum', hide: true },
        { name: 'freqMediaMensalAlunosPrograma',       type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average',
                referenceField: 'freqAulasMat', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['freqAulasMat'] / data.calculations['aulasMat']) * 100).toFixed(1);
        }},


        { name: 'ano',                                 type: 'integer' , calculateTotal: true, calculateType: 'sum'},
        { name: 'bimestre',                            type: 'integer' , calculateTotal: true, calculateType: 'sum'},

        //
        { name: 'rec',                                 type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'transferenciaRecPercentagem',         type: 'integer', calculateTotal: true,  percent:"true", calculateType: 'average',
                referenceField: 'rec', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['rec'] / data.calculations['bimestre']) * 100).toFixed(1);
        } },
        { name: 'exp',                                 type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'transferenciaExpPercentagem',         type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average' ,
                referenceField: 'exp', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['exp'] / data.calculations['bimestre']) * 100).toFixed(1);
        }},
        { name: 'numeroDeAbandono',                    type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'percentagemAbandono',                 type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average' ,
                referenceField: 'numeroDeAbandono', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['numeroDeAbandono'] / data.calculations['bimestre']) * 100).toFixed(1);
        }},
        { name: 'atual',                               type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'percentagemMatricula',                type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average',
                referenceField: 'atual', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['atual'] / data.calculations['bimestre']) * 100).toFixed(1);
        }},

        //
        { name: 'totalProblemasPessoais',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'problemasPessoais',                   type: 'integer',calculateTotal: true, percent:"true", calculateType: 'average' ,
                referenceField: 'totalProblemasPessoais', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['totalProblemasPessoais'] / data.calculations['numeroDeAbandono']) * 100).toFixed(1);
        }},
        { name: 'totalConflitoHorarioTransporte',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'conflitoHorarioTransporte',           type: 'integer',calculateTotal: true, percent:"true", calculateType: 'average' ,
        referenceField: 'totalConflitoHorarioTransporte', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['totalConflitoHorarioTransporte'] / data.calculations['numeroDeAbandono']) * 100).toFixed(1);
        }},
        { name: 'totalDificuldadesPropostaPrograma',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'dificuldadesPropostaPrograma',        type: 'integer',calculateTotal: true, percent:"true", calculateType: 'average' ,
                referenceField: 'totalDificuldadesPropostaPrograma', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['totalDificuldadesPropostaPrograma'] / data.calculations['numeroDeAbandono']) * 100).toFixed(1);
        }},
        { name: 'totalOutros',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'outros',                              type: 'integer',calculateTotal: true, percent:"true", calculateType: 'average' ,
                referenceField: 'totalOutros', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['totalOutros'] / data.calculations['numeroDeAbandono']) * 100).toFixed(1);
        }},
        //

        { name: 'sim',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'totalSim',                 type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average'  ,
                referenceField: 'sim', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['sim'] / (data.calculations['sim']+data.calculations['nao'])) * 100).toFixed(1);
        }},
        { name: 'nao',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'totalNao',                 type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average' ,
                referenceField: 'nao', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['nao'] / (data.calculations['sim']+data.calculations['nao'])) * 100).toFixed(1);
        }}
]);

angular.module('app.relatorios').constant('ACOMPANHAMENTO_SEMESTRAL_ESCOLA_FIELDS', [
        { name: 'ordem',                               type: 'integer' , showFooter: false},
        { name: 'estado',                              type: 'string'  , showFooter: false},
        { name: 'cidade',                              type: 'string'  , showFooter: false},
        { name: 'escola',                              type: 'string'  , showFooter: false},

        { name: 'numeroTurmas',                        type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'freqProfNaFormacao',                  type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average'},

        { name: 'freqAulasMat',                        type: 'integer', calculateTotal: true, percent:"true", calculateType: 'sum', hide: true },
        { name: 'aulasMat',                            type: 'integer', calculateTotal: true, percent:"true", calculateType: 'sum', hide: true },
        { name: 'freqMediaMensalAlunosPrograma',       type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average',
                referenceField: 'freqAulasMat', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['freqAulasMat'] / data.calculations['aulasMat']) * 100).toFixed(1);
        }},

        { name: 'ano',                                 type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'bimestre',                            type: 'integer', calculateTotal: true, calculateType: 'sum' },

        //
        { name: 'rec',                                 type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'transferenciaRecPercentagem',         type: 'integer', calculateTotal: true,  percent:"true", calculateType: 'average',
                referenceField: 'rec', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['rec'] / data.calculations['bimestre']) * 100).toFixed(1);
        } },
        { name: 'exp',                                 type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'transferenciaExpPercentagem',         type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average' ,
                referenceField: 'exp', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['exp'] / data.calculations['bimestre']) * 100).toFixed(1);
        }},
        { name: 'numeroDeAbandono',                    type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'percentagemAbandono',                 type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average' ,
                referenceField: 'numeroDeAbandono', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['numeroDeAbandono'] / data.calculations['bimestre']) * 100).toFixed(1);
        }},
        { name: 'atual',                               type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'percentagemMatricula',                type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average',
                referenceField: 'atual', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['atual'] / data.calculations['bimestre']) * 100).toFixed(1);
        }},

        //
        { name: 'totalProblemasPessoais',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'problemasPessoais',                   type: 'integer',calculateTotal: true, percent:"true", calculateType: 'average' ,
                referenceField: 'totalProblemasPessoais', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['totalProblemasPessoais'] / data.calculations['numeroDeAbandono']) * 100).toFixed(1);
        }},
        { name: 'totalConflitoHorarioTransporte',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'conflitoHorarioTransporte',           type: 'integer',calculateTotal: true, percent:"true", calculateType: 'average' ,
        referenceField: 'totalConflitoHorarioTransporte', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['totalConflitoHorarioTransporte'] / data.calculations['numeroDeAbandono']) * 100).toFixed(1);
        }},
        { name: 'totalDificuldadesPropostaPrograma',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'dificuldadesPropostaPrograma',        type: 'integer',calculateTotal: true, percent:"true", calculateType: 'average' ,
                referenceField: 'totalDificuldadesPropostaPrograma', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['totalDificuldadesPropostaPrograma'] / data.calculations['numeroDeAbandono']) * 100).toFixed(1);
        }},
        { name: 'totalOutros',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'outros',                              type: 'integer',calculateTotal: true, percent:"true", calculateType: 'average' ,
                referenceField: 'totalOutros', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['totalOutros'] / data.calculations['numeroDeAbandono']) * 100).toFixed(1);
        }},
        //

        { name: 'sim',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'totalSim',                 type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average'  ,
                referenceField: 'sim', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['sim'] / (data.calculations['sim']+data.calculations['nao'])) * 100).toFixed(1);
        }},
        { name: 'nao',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'totalNao',                 type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average' ,
                referenceField: 'nao', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['nao'] / (data.calculations['sim']+data.calculations['nao'])) * 100).toFixed(1);
        }}
]);

angular.module('app.relatorios').constant('ACOMPANHAMENTO_SEMESTRAL_TURMA_FIELDS', [
        { name: 'ordem',                               type: 'integer', showFooter: false},
        { name: 'estado',                              type: 'string', showFooter: false  },
        { name: 'cidade',                              type: 'string', showFooter: false  },
        { name: 'escola',                              type: 'string', showFooter: false  },
        { name: 'professor',                           type: 'string', showFooter: false  },
        { name: 'turma',                               type: 'string', showFooter: false  },

        { name: 'freqProfNaFormacao',                  type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average'},
        { name: 'freqAulasMat',                        type: 'integer', calculateTotal: true, percent:"true", calculateType: 'sum', hide: true },
        { name: 'aulasMat',                            type: 'integer', calculateTotal: true, percent:"true", calculateType: 'sum', hide: true },
        { name: 'freqMediaMensalAlunosPrograma',       type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average',
                referenceField: 'freqAulasMat', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['freqAulasMat'] / data.calculations['aulasMat']) * 100).toFixed(1);
        }},
        { name: 'ano',                                 type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'bimestre',                            type: 'integer', calculateTotal: true, calculateType: 'sum' },
        //
        { name: 'rec',                                 type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'transferenciaRecPercentagem',         type: 'integer', calculateTotal: true,  percent:"true", calculateType: 'average',
                referenceField: 'rec', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['rec'] / data.calculations['bimestre']) * 100).toFixed(1);
        } },
        { name: 'exp',                                 type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'transferenciaExpPercentagem',         type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average' ,
                referenceField: 'exp', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['exp'] / data.calculations['bimestre']) * 100).toFixed(1);
        }},
        { name: 'numeroDeAbandono',                    type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'percentagemAbandono',                 type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average' ,
                referenceField: 'numeroDeAbandono', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['numeroDeAbandono'] / data.calculations['bimestre']) * 100).toFixed(1);
        }},
        { name: 'atual',                               type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'percentagemMatricula',                type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average',
                referenceField: 'atual', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['atual'] / data.calculations['bimestre']) * 100).toFixed(1);
        }},
        //
        { name: 'totalProblemasPessoais',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'problemasPessoais',                   type: 'integer',calculateTotal: true, percent:"true", calculateType: 'average' ,
                referenceField: 'totalProblemasPessoais', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['totalProblemasPessoais'] / data.calculations['numeroDeAbandono']) * 100).toFixed(1);
        }},
        { name: 'totalConflitoHorarioTransporte',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'conflitoHorarioTransporte',           type: 'integer',calculateTotal: true, percent:"true", calculateType: 'average' ,
        referenceField: 'totalConflitoHorarioTransporte', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['totalConflitoHorarioTransporte'] / data.calculations['numeroDeAbandono']) * 100).toFixed(1);
        }},
        { name: 'totalDificuldadesPropostaPrograma',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'dificuldadesPropostaPrograma',        type: 'integer',calculateTotal: true, percent:"true", calculateType: 'average' ,
                referenceField: 'totalDificuldadesPropostaPrograma', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['totalDificuldadesPropostaPrograma'] / data.calculations['numeroDeAbandono']) * 100).toFixed(1);
        }},
        { name: 'totalOutros',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'outros',                              type: 'integer',calculateTotal: true, percent:"true", calculateType: 'average' ,
                referenceField: 'totalOutros', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['totalOutros'] / data.calculations['numeroDeAbandono']) * 100).toFixed(1);
        }},
        //
        { name: 'haProfissionalApoio',                 type: 'string' }
]);

angular.module('app.relatorios').constant('ACOMPANHAMENTO_SEMESTRAL_PROFESSOR_FIELDS', [
        { name: 'ordem',                               type: 'integer' , showFooter: false},
        { name: 'professor',                           type: 'string' , showFooter: false},

        { name: 'numeroTurmas',                        type: 'integer', calculateTotal: true, calculateType: 'sum'},
        { name: 'haProfissionalApoio',                 type: 'string' },
        { name: 'freqProfNaFormacao',                  type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average'},

        { name: 'freqAulasMat',                        type: 'integer', calculateTotal: true, percent:"true", calculateType: 'sum', hide: true },
        { name: 'aulasMat',                            type: 'integer', calculateTotal: true, percent:"true", calculateType: 'sum', hide: true },
        { name: 'freqMediaMensalAlunosPrograma',       type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average',
                referenceField: 'freqAulasMat', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['freqAulasMat'] / data.calculations['aulasMat']) * 100).toFixed(1);
        }},

        { name: 'ano',                                 type: 'integer' , calculateTotal: true, calculateType: 'sum'},
        { name: 'bimestre',                            type: 'integer' , calculateTotal: true, calculateType: 'sum'},

        //
        { name: 'rec',                                 type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'transferenciaRecPercentagem',         type: 'integer', calculateTotal: true,  percent:"true", calculateType: 'average',
                referenceField: 'rec', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['rec'] / data.calculations['bimestre']) * 100).toFixed(1);
        } },
        { name: 'exp',                                 type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'transferenciaExpPercentagem',         type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average' ,
                referenceField: 'exp', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['exp'] / data.calculations['bimestre']) * 100).toFixed(1);
        }},
        { name: 'numeroDeAbandono',                    type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'percentagemAbandono',                 type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average' ,
                referenceField: 'numeroDeAbandono', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['numeroDeAbandono'] / data.calculations['bimestre']) * 100).toFixed(1);
        }},
        { name: 'atual',                               type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'percentagemMatricula',                type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average',
                referenceField: 'atual', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['atual'] / data.calculations['bimestre']) * 100).toFixed(1);
        }},

        //
        { name: 'totalProblemasPessoais',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'problemasPessoais',                   type: 'integer',calculateTotal: true, percent:"true", calculateType: 'average' ,
                referenceField: 'totalProblemasPessoais', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['totalProblemasPessoais'] / data.calculations['numeroDeAbandono']) * 100).toFixed(1);
        }},
        { name: 'totalConflitoHorarioTransporte',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'conflitoHorarioTransporte',           type: 'integer',calculateTotal: true, percent:"true", calculateType: 'average' ,
        referenceField: 'totalConflitoHorarioTransporte', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['totalConflitoHorarioTransporte'] / data.calculations['numeroDeAbandono']) * 100).toFixed(1);
        }},
        { name: 'totalDificuldadesPropostaPrograma',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'dificuldadesPropostaPrograma',        type: 'integer',calculateTotal: true, percent:"true", calculateType: 'average' ,
                referenceField: 'totalDificuldadesPropostaPrograma', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['totalDificuldadesPropostaPrograma'] / data.calculations['numeroDeAbandono']) * 100).toFixed(1);
        }},
        { name: 'totalOutros',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'outros',                              type: 'integer',calculateTotal: true, percent:"true", calculateType: 'average' ,
                referenceField: 'totalOutros', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['totalOutros'] / data.calculations['numeroDeAbandono']) * 100).toFixed(1);
        }},
        //
]);
