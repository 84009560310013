(function() {
    'use strict';

    angular.module('app.core').filter('iasCepMask', IasCepMask);

    function IasCepMask() {
        return function (input) {

            if (is.not.null(input)) {
                var str = input + '';
                str = str.replace(/\D/g, '');
                str = str.replace(/^(\d{2})(\d{3})(\d)/, '$1.$2-$3');
                return str;
            }

            return null;
            
        };
    }
}());