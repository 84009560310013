(function() {
    'use strict';

    angular.module('app.atributo').factory('Atributo', Atributo);
    Atributo.$inject = ['$resource', 'APP_PROPERTY'];

    function Atributo($resource, APP_PROPERTY) {
        return $resource(APP_PROPERTY.URL + '/atributos/:id', { id: '@id' }, {
            update: {
                method: 'PUT'
            },
            filter: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/atributos'
            },
            findTipos: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/atributos/tipos'
            },
            findEntidades: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/atributos/entidades'
            },
            ativos: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/atributos/ativo'
            },
            porPrograma: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/atributos?idPrograma=:idPrograma'
            },
            porTipoEntidadePrograma: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/atributos/filtros?tipoEntidade=:tipoEntidade&idPrograma=:idPrograma'
            },
            delete: {
                method: 'DELETE',
                transformResponse: function(data, headers, statusCode) {
                        var finalRsponse = {
                            data: data,
                            responseStatusCode: statusCode
                        };
                    return finalRsponse;
                },
                url: APP_PROPERTY.URL + '/atributos/:id'
            }
        });
    }

}());
