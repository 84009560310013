(function() {
    'use strict';

    angular.module('app.core').constant('PROFESSOR_MESSAGES', {
      success: {
        deleted: 'Registro removido com sucesso.',
        inactivated: 'Registro inativado com sucesso.',
        saved: 'Professor salvo com sucesso.',
        updated: 'Professor atualizado com sucesso.',
        import: 'A planilha foi importada e processada.',
        export: 'A planilha foi exportada com sucesso.',
        reset: 'Senha reiniciada com sucesso.',
      },
      error: {
        search: 'Erro ao pesquisar professores, tente novamente.',
        attributes: 'Não foi possível listar os atributos.',
        duplicated_attr: 'O atributo já foi adicionado.',
        import: 'Ocorreu um erro durante a importação.',
        cannotImport: 'Não é possível importar. ',
        reset: 'Falha ao reiniciar senha. Professor sem usuário. '

      },
      info: {
        importTitle: 'Importar Professores'
      },
      warning: {
        deleteResponses: 'Caso algum registro já exista a importação irá atualizá-los.'
      }
    });

}());
