(function() {
    'use strict';

    angular.module('app.medida').factory('Medida', Medida);
    Medida.$inject = ['$resource', 'APP_PROPERTY'];

    function Medida($resource, APP_PROPERTY) {
        return $resource(APP_PROPERTY.URL + '/medidas/crud/read/:id', { id: '@id' }, {
            update: {
                method: 'PUT'
            },
            delete: {
                method: 'DELETE',
                transformResponse: function(data, headers, statusCode) {
                        var finalRsponse = {
                            data: data,
                            responseStatusCode: statusCode
                        };
                    return finalRsponse;
                },
                url: APP_PROPERTY.URL + '/medidas/:id'
            },
            filter: {
                method: 'GET',
                url: APP_PROPERTY.URL + '/medidas/edit/?id=:id'
            },
            save: {
                method: 'POST',
                url: APP_PROPERTY.URL + '/medidas'
            },
            listasMedidas: {
                method: 'GET',
                isArray:true,
                url: APP_PROPERTY.URL + '/medidas/crud/read/:id'
            },
            listas: {
                search: 'GET',
                url: APP_PROPERTY.URL + '/medidas/crud/read',
                isArray: true
            },
            ativos: {
                method: 'GET',
                url: APP_PROPERTY.URL + '/medidas/ATIVO',
                isArray: true
            },
            listasStatus: {
                search: 'GET',
                url: APP_PROPERTY.URL + '/medidas/:status',
                isArray: true
            },
            findByStatus: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/medidas/:status'
            },
            findMedidasSinteticas: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/medidas/sinteticas/ativas'
            },
            isUsedInColeta: {
                method: 'GET',
                isArray:false,
                url: APP_PROPERTY.URL + '/medidas/:id/usada-coleta'
            },
        });
    }

}());
