(function() {
    'use strict';

    angular.module('app.atributo', ['app.core']).config(config);
    config.$inject = ['$stateProvider', 'APP_STATE'];

    function config($stateProvider, APP_STATE) {
        $stateProvider
            .state(APP_STATE.ATRIBUTO.BASE, {
                url: '/atributos',
                abstract: true,
                views: {
                    'content': {
                        template: '<div ui-view="internal"></div>',
                        controller: 'AtributoController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state(APP_STATE.ATRIBUTO.SEARCH, {
                url: '/search',
                views: {
                    'internal': {
                        templateUrl: 'atributo/partials/pesquisa-atributo.html'

                    }
                },
                data: {
                    permission: "ATR:RE",
                    breadCrumb: [{
                            display: "Cadastros",
                            active: false
                        },
                        {
                            display: "Atributos",
                            active: true
                        }
                    ],
                    search: {
                        enabled: false
                    }
                }
            })
            .state(APP_STATE.ATRIBUTO.NEW, {
                url: '/new',
                views: {
                    'internal': {
                        templateUrl: 'atributo/partials/atributo-form.html',
                        controller: 'AtributoFormController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    permission: "ATR:CR",
                    breadCrumb: [{
                            display: "Cadastros",
                            active: false
                        },
                        {
                            display: "Atributos",
                            active: false,
                            stateName: APP_STATE.ATRIBUTO.SEARCH
                        },
                        {
                            display: "Novo",
                            active: true
                        }
                    ],
                    search: {
                        placeHolder: "Digite o nome do atributo",
                        enabled: false
                    }
                }
            })
            .state(APP_STATE.ATRIBUTO.EDIT, {
                url: '/edit/:id',
                views: {
                    'internal': {
                        templateUrl: 'atributo/partials/atributo-form.html',
                        controller: 'AtributoFormController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    permission: "ATR:VI",
                    breadCrumb: [{
                            display: "Cadastros",
                            active: false
                        },
                        {
                            display: "Atributos",
                            active: false,
                            stateName: APP_STATE.ATRIBUTO.SEARCH
                        },
                        {
                            display: "Edição",
                            active: true
                        }
                    ],
                    search: {
                        placeHolder: "Digite o nome do atributo",
                        enabled: false
                    }
                }
            });
    }
}());