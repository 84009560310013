
(function () {
    'use strict';

    angular.module('app.atributo').controller('AtributoController', AtributoController);
    AtributoController.$inject = ['APP_STATE', 'APP_EVENT', '$scope', '$state', 'Atributo',
        '$document', 'toastService', 'dialogService', 'atributoService', 'MULTISELECT_LABELS', 'SecurityManagerFactory'];

    function AtributoController(APP_STATE, APP_EVENT, $scope, $state, Atributo,
        $document, toastService, dialogService, atributoService, MULTISELECT_LABELS, SecurityManagerFactory) {

        var vm = this;

        vm.clearSearchValue = function() {
            vm.itens = [];
            vm.searchValue = "";
            vm.searchValueModel = "";
        };

        vm.goNewPage = function() {
            $state.go(APP_STATE.ATRIBUTO.NEW);
        };

        vm.goEditPage = function(id) {
          $state.go(APP_STATE.ATRIBUTO.EDIT, { id: id });
        };

        vm.search = function() {
            vm.atributos = [];
            vm.atributosTotal = 0;
            vm.searchWasPerformed = false;
            vm.searchValue = vm.searchValueModel || vm.searchValue;

            if (!vm.searchValueModel) {
                vm.searchValueModel = vm.searchValue;
            }
            
            if (vm.searchValue) {
                var filter = {};
                filter[vm.searchType.field] = vm.searchValue;

                if (angular.isArray(vm.searchValue)) {
                        filter[vm.searchType.field] = _.map(vm.searchValue, function(value) {
                            return (value.id) ? value.id : value.key; 
                        });
                    } else {
                        filter[vm.searchType.field] = vm.searchValue;
                }

                Atributo.filter(filter).$promise
                    .then(function(data) {
                        vm.atributos = angular.copy(data);
                    }, function(response) {
                        toastService.error({
                            status: response.status,
                            message: response.data.message
                        });
                    })
                    .finally(function() {
                        vm.searchWasPerformed = true;
                        vm.atributosTotal = vm.atributos.length;
                        vm.formSearch.$setPristine();
                    });
            }
        };

        vm.delete = function(atributo) {
            dialogService.delete().then(function() {
                atributoService.delete({id: atributo.id})
                .then(function(response) {
                        if (response.responseStatusCode == 200) {
                            toastService.success("Registro removido com sucesso.");
                        } else if (response.responseStatusCode == 202) {
                            toastService.success("Registro inativado com sucesso.");
                        }
                    vm.search();
                }, function(response) {
                    toastService.error({
                        status: response.status,
                        message: response.data.message
                    });
                });
            }, function() {});
        };

        (function() {
            vm.hasPermissionView = SecurityManagerFactory.hasPermission('ATR:VI');
            vm.SEARCH_BY = {
                CODE: {
                    field: "id",
                    placeholder: "código",
                    mask: "999999999",
                    restrict: "[^0-9]",
                    type: "number",
                    minlength: 1
                },
                NAME: {
                    field: "nome",
                    placeholder: "nome",
                    mask: "*",
                    restrict: "%",
                    type: "text",
                    minlength: 3,
                    maxLength: 50
                },
                TYPE: {
                    field: "tipo",
                    placeholder: "tipo",
                    mask: "%"
                },
                SCOPE: {
                    field: "entidade",
                    placeholder: "tipo de entidade",
                    mask: "%"
                }
            };

            vm.tipos = [];
            vm.entidades = [];
            vm.itens = [];
            vm.searchValue = '';
            vm.searchValueModel = '';
            vm.searchType = vm.SEARCH_BY.NAME;
            vm.valueSelected = [];
            vm.searchWasPerformed = false;
            vm.MULTISELECT_LABELS = MULTISELECT_LABELS;

            atributoService.findTipos().then(function(tipos) {
                vm.tipos = tipos;
            });

            atributoService.findEntidades().then(function(entidades) {
                vm.entidades = entidades;
            });

            $scope.$on(APP_EVENT.ATRIBUTO.SAVED, function() {
                toastService.success("Operação realizada com sucesso.");
                vm.search();
            });
        })();

    }

})();
