(function () {
    'use strict';

    angular.module('app.professor').service('professorService', ProfessorService);
    ProfessorService.$inject = ['Professor', 'APP_PROPERTY', 'fileService', '$q', 'HTTP_STATUS_CODE'];

    function ProfessorService(Professor, APP_PROPERTY, fileService, $q, HTTP_STATUS_CODE) {

        this.resetSenha = function(professor){
            var entity = new Professor(professor);
            return entity.$resetSenha();
        };

        this.filter = function (filter) {
            return Professor.filter(filter).$promise;
        };
        this.byTurma = function (filter) {
            return Professor.byTurma(filter).$promise;
        };
        this.byEscola = function (filter) {
            return Professor.byEscola(filter).$promise;
        };

        this.ProfessoresByEscola = function (filter) {
            return Professor.ProfessoresByEscola(filter).$promise;
        };

        this.ProfessorByTurmaId = function (idTurma) {
            return Professor.ProfessorByTurmaId({ idTurma: idTurma });
        }

        this.ProfessorByTurmaAndDisciplinaId = function (idTurma, disciplinas) {
            return Professor.ProfessorByTurmaAndDisciplinaId({ idTurma: idTurma }, disciplinas).$promise;
        }

        this.get = function (id) {
            return Professor.get({ id: id }).$promise;
        };

        this.delete = function (id) {
            return Professor.delete({ id: id }).$promise;
        };

        this.findAtivos = function () {
            return Professor.findByStatus({ situacao: 'ATIVO' }).$promise;
        };

        this.filterAtivos = function (filter) {
            var newFilter = angular.copy(filter);
            newFilter.situacao = 'ATIVO';

            return Professor.findByStatus(newFilter).$promise;
        };

        this.removeOldValuesFromAtributo = function (dados) {
            if (is.existy(dados) && is.not.empty(dados)) {
                var newData = angular.copy(dados);
                delete newData[0].opcaoItem;
                delete newData[0].valueOpcaoItemResposta;
                delete newData[0].valueNumericResposta;
                delete newData[0].valueDateResposta;
                delete newData[0].valueTimeResposta;
                delete newData[0].valueDateTimeResposta;
                delete newData[0].linha;
                delete newData[0].paragrafo;

                return newData;
            } else {
                return [];
            }
        };

        this._normalizeAtributosToServer = function (professor) {

            var atributosEntidadesAlteradosDTO = _.filter(professor.atributosEntidadesDTO, function (item) {
                return item._updated;
            });

            professor.atributosEntidadesAlteradosDTO = atributosEntidadesAlteradosDTO;

            var atributosEntidadesIncluidosDTO = _.filter(professor.atributosEntidadesDTO, function (item) {
                return is.not.existy(item.id);
            });

            professor.atributosEntidadesIncluidosDTO = atributosEntidadesIncluidosDTO;
        };

        this.existeUsuario = function (idPessoa) {
            return Professor.existeUsuario({ idPessoa: idPessoa }).$promise;
        };

        this.criarUsuario = function (idPessoa) {
            return Professor.criarUsuario({ idPessoa: idPessoa }).$promise;
        }

        this.import = function (file) {
            var url = APP_PROPERTY.URL + '/professores/importar/arquivo_excel';
            return $q(function (resolve, reject) {
                fileService.uploadAsArrayBuffer(url, file)
                    .then(function (response) {
                        resolve(response);
                    })
                    .catch(function (error) {
                        var customError = {};
                        switch (error.status) {
                            case HTTP_STATUS_CODE.PRECONDITION_FAILED:
                                customError.messages = error.data;
                                break;
                            case HTTP_STATUS_CODE.BAD_REQUEST:
                                customError.formConstraints = error.data;
                                break;
                            default:
                                customError.messages = [error.data.message];
                        }
                        reject(customError);
                    });
            });
        };

        this.exportByUfAndMunicipioAndEscola = function (idAnoLetivo, idPrograma, idUf, idMunicipio, idEscola) {
            return $q(function (resolve, reject) {
                Professor.exportByUfAndMunicipioAndEscola({ idAnoLetivo: idAnoLetivo, idPrograma: idPrograma, idUf: idUf, idMunicipio: idMunicipio, idEscola: idEscola }).$promise
                    .then(function (file) {
                        fileService.download(file);
                        resolve();
                    })
                    .catch(function (error) {
                        reject(error);
                    });
            });
        };

        this.exportByUfAndMunicipio = function (idAnoLetivo, idPrograma, idUf, idMunicipio) {
            return $q(function (resolve, reject) {
                Professor.exportByUfAndMunicipio({ idAnoLetivo: idAnoLetivo, idPrograma: idPrograma, idUf: idUf, idMunicipio: idMunicipio }).$promise
                    .then(function (file) {
                        fileService.download(file);
                        resolve();
                    })
                    .catch(function (error) {
                        reject(error);
                    });
            });
        };

        this.exportByUf = function (idAnoLetivo, idPrograma, idUf) {
            return $q(function (resolve, reject) {
                Professor.exportByUf({ idAnoLetivo: idAnoLetivo, idPrograma: idPrograma, idUf: idUf }).$promise
                    .then(function (file) {
                        fileService.download(file);
                        resolve();
                    })
                    .catch(function (error) {
                        reject(error);
                    });
            });
        };

        this.export = function (idAnoLetivo, idPrograma) {
            return $q(function (resolve, reject) {
                Professor.export({ idAnoLetivo: idAnoLetivo, idPrograma: idPrograma }).$promise
                    .then(function (file) {
                        fileService.download(file);
                        resolve();
                    })
                    .catch(function (error) {
                        reject(error);
                    });
            });
        };

    }

}());
