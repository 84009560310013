(function() {
    'use strict';

    angular.module('app.formulario').factory('Formulario', Formulario);
    Formulario.$inject = ['$resource', 'APP_PROPERTY'];

    function Formulario($resource, APP_PROPERTY) {
        return $resource(APP_PROPERTY.URL + '/formularios/:id', { id: '@id' }, {
            update: {
                method: 'PUT'
            },
            filter: {
                method: 'GET',
                url: APP_PROPERTY.URL + '/formularios',
                isArray: true
            },
            filterPageable: {
                method: 'GET',
                url: APP_PROPERTY.URL + '/formularios/pageable',
                isArray: false
            },
            status: {
                method: 'GET',
                url: APP_PROPERTY.URL + '/formularios/status',
                isArray: true
            },
            findAll: {
                method: 'GET',
                url: APP_PROPERTY.URL + '/formularios/status-form',
                isArray: true
            },
            itens: {
                method: 'GET',
                url: APP_PROPERTY.URL + '/formularios/:id/itens',
                isArray: true
            },
            granularidades: {
                method: 'GET',
                url: APP_PROPERTY.URL + '/formularios/granularidades',
                isArray: true
            },
            periodicidades: {
                method: 'GET',
                url: APP_PROPERTY.URL + '/formularios/periodicidades',
                isArray: true
            },
            tipos: {
                method: 'GET',
                url: APP_PROPERTY.URL + '/formularios/tipos',
                isArray: true
            },
            tiposItens: {
                method: 'GET',
                url: APP_PROPERTY.URL + '/formularios/tipositens',
                isArray: true
            },
            findByStatus: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/formularios/:status'
            },
            findItensByFormularioId: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/formularios/:id/itens/:status'
            },
            isUsed: {
                method: 'GET',
                transformResponse: function(data, headers, statusCode) {
                        var finalRsponse = {
                            data: data,
                            responseStatusCode: statusCode
                        };
                    return finalRsponse;
                },
                url: APP_PROPERTY.URL + '/formularios/:id/usado'
            },
            delete: {
                method: 'DELETE',
                transformResponse: function(data, headers, statusCode) {
                        var finalRsponse = {
                            data: data,
                            responseStatusCode: statusCode
                        };
                    return finalRsponse;
                },
                url: APP_PROPERTY.URL + '/formularios/:id'
            },
            findByRubrica: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/formularios/rubricas'
            },
            findByTurmaRubrica: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/formularios/turma-rubricas'
            },
            findByTurma: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/formularios/turma'
            }
            
        });

    }

}());
