(function() {
    'use strict';

    angular.module('app.core').constant('TRANSLATE_EN', {
        SAVO_SUCESSO: 'The data has been saved successfully.',
        ERRO_SALVAR: 'There was an error saving.',
        TOTAL_REGISTRO: 'Total results: <b>{?}</b>.',
        NENHUM_REGISTRO: 'No results found.',
        CAMPO_PESQUISA_OBRIGATORIO: 'The field {?} Is required for the search.',
        PESQUISA_MINIMA: 'Search by name must contain at least three characters.',

        // Formulário
        FORMULARIO_ADD_NOVO: 'Do you want to add a new form?'
    });

}());
