(function() {
    'use strict';

    angular.module('app.core').directive('iasIsEqualDateOrTimeValue', IasIsEqualDateOrTimeValue);
    IasIsEqualDateOrTimeValue.$inject = ['dateUtilService'];

    function IasIsEqualDateOrTimeValue(dateUtilService) {
        return {
            require: 'ngModel',
            restrict: 'A',
            link: function(scope, element, attr, controller) {
                if (is.not.existy(controller.$validators.iasIsEqualDateOrTimeValue)) {
                    controller.$validators.iasIsEqualDateOrTimeValue = function(modelValue, viewValue) {
                        var result = !(attr.iasBetweenMinDateOrTimeValue === viewValue);

                        return result;
                    };
                }

                attr.$observe('iasBetweenMinDateOrTimeValue', function(newMinValue) {
                    if (newMinValue) {
                        controller.$validate();
                    }
                });
            }
        };
    }

})();