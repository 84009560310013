(function(){
    'use strict';

    angular.module('app.opcao').controller('OpcaoFormController', OpcaoFormController);
    OpcaoFormController.$inject = ['APP_STATE', 'TABLE_FIELDS', 'APP_EVENT', 'COMMON_STATUS_LIST',
        '$state', '$scope', 'opcaoService', 'escalaService', 'dialogService', 'toastService', '$rootScope'];

    function OpcaoFormController(APP_STATE, TABLE_FIELDS, APP_EVENT, COMMON_STATUS_LIST,
        $state, $scope, opcaoService, escalaService, dialogService, toastService, $rootScope) {

        var vm = this;

        var _init = function() {
            vm.salvarContinuar = false;
            vm.fields = TABLE_FIELDS.OPCAO;
            vm.editMode = false;
            vm.editPage = false;
            vm.hasScale = false;
            vm.refreshSearch = false;
            vm.escalas = [];
            vm.items = [];
            vm.newItems = [];
            vm.defaultStatus = COMMON_STATUS_LIST[0];
            vm.opcaoUsed = false;

            vm.modalTemplateUrl = "opcao/partials/opcao-form-item-modal.html";
            vm.modalDependencies = {
                statusList: COMMON_STATUS_LIST,
                itemOpcao: {
                    situacao: vm.defaultStatus,
                    permiteComplemento: false
                },
                save: _saveModalItem
            };

            escalaService.getActives().then(function(data) {
                vm.escalas = data;
            }, function(response) {
                toastService.error({
                    status: response.status,
                    message: response.data.message
                });
            });
        
            if ($state.is(APP_STATE.OPCAO.EDIT)) {
                vm.editPage = true;

                if ($state.params.id) {
                    opcaoService.get($state.params.id)
                        .then(function(opcao) {
                            vm.opcao = opcao;

                            if (vm.opcao.opcaoItensDTO) {
                                vm.items = angular.copy(vm.opcao.opcaoItensDTO);
                            }

                            if (vm.opcao.escalaDTO) {
                                if (vm.opcao.escalaDTO.escalaItensDTO && vm.opcao.escalaDTO.escalaItensDTO.length > 0) {
                                    vm.hasScale = true;
                                    escalaService.getActives()
                                        .then(function(data) {
                                            vm.escalas = data;
                                            vm.escalas.$promise.then(function(data){
                                                    if (is.not.existy(_.findWhere(data, {id: vm.opcao.escalaDTO.id}))){
                                                        vm.escalas.push(vm.opcao.escalaDTO);
                                                        vm.escalas = _.sortBy(vm.escalas, 'nome');
                                                    }
                                            });
                                        }, function(response) {
                                            toastService.error({
                                                status: response.status,
                                                message: response.data.message
                                            });
                                    });

                                }
                            }

                            if (is.truthy($state.params.editMode)) {
                                vm.enableEditMode();
                            }                        
                        }, function(response) {
                            toastService.error({
                                status: response.status,
                                message: response.data.message
                            });
                        });
                } else {
                    vm.goBack();
                }
            } else {
                vm.enableEditMode();
                vm.opcao = {};
                vm.opcao.situacao = vm.defaultStatus;
            }            
        };

        var _saveModalItem = function(modalForm){
            vm.registerForm.$setDirty();
            this.ok(modalForm);
        }

        var _clearItems = function() {
            vm.items = [];
            vm.newItems = [];
        };

        vm.goBack = function() {
            $state.go(APP_STATE.OPCAO.SEARCH);
        };

        vm.deleteFieldScale = function() {
            delete vm.opcao.escalaDTO;
            _clearItems();
        };

        vm.save = function() {
            if (vm.registerForm.$valid) {
                if ((vm.items.length <= 0) && (vm.newItems.length <= 0)) {
                    toastService.error("Pelo menos um item de opção deve ser incluído.");
                } else {
                    opcaoService.save(vm.opcao, vm.items, vm.newItems)
                        .then(function(opcao) {
                            $scope.$emit(APP_EVENT.OPCAO.SAVED);
                        
                            if (!vm.salvarContinuar) {
                                vm.goBack();
                            } else {
                                _init();
                                //Irá enviar notificação para limpar os itens selecionados
                                $rootScope.$broadcast(APP_EVENT.GENERIC.CLEAR_ITEMS_SELECTED)
                                vm.registerForm.$setPristine();
                                $state.go(APP_STATE.OPCAO.EDIT, { id: opcao.id, editMode: true });
                            }
                        }, function(response) {
                            toastService.error({
                                status: response.status,
                                message: response.data.message
                            });
                        });
                }
            }
        };

        var _saveAndContinue = function(createNewItems) {
            if (vm.registerForm.$valid) {
                var newItems = (createNewItems) ? vm.newItems : [];

                opcaoService.save(vm.opcao, vm.items, newItems)
                    .then(function(opcao) {
                        if (opcao.opcaoItensDTO) {
                            vm.items = angular.copy(opcao.opcaoItensDTO);
                        }
                        
                        vm.refreshSearch = true;
                        vm.registerForm.$setPristine();
                        vm.modifiedItems = false;

                        if (createNewItems) {
                            vm.newItems = [];
                        }

                        vm.opcao = opcao;

                        toastService.success("Operação realizada com sucesso.");
                    
                    }, function(response) {
                        vm.refreshSearch = false;

                        toastService.error({
                            status: response.status,
                            message: response.data.message
                        });
                    });
            }
        };

        vm.saveItems = function() {
            _saveAndContinue(true);
        };

        vm.updateItems = function() {
            _saveAndContinue();
        };

        vm.enableEditMode = function() {
            if (vm.opcao != undefined && vm.opcao !== null && vm.opcao.id != undefined && vm.opcao.id !== null) {
                opcaoService.isUsed(vm.opcao.id).then(function(response) {
                    if (response.responseStatusCode == 200) {
                        vm.opcaoUsed = true;
                    } else if (response.responseStatusCode == 204) {
                        vm.opcaoUsed = false;
                    }
                });
            }
            vm.editMode = true;
        };

        vm.cancel = function() {
            if (vm.refreshSearch) {
                $scope.$emit(APP_EVENT.OPCAO.REFRESH);
            }

            vm.goBack();
        };

        vm.cleanEscalaItens = function() {
            var msg = "Os itens de Opção provenientes da Escala serão removidos. Tem certeza disso?";
            dialogService.confirm(msg, "Substituir").then(function() {
                vm.deleteFieldScale();
            }, function() {
                vm.hasScale = true;
            });
        }

        vm.cloneEscalaItens = function() {
            var escala = vm.opcao.escalaDTO;

            if (escala) {
                var msg = "Os itens de Opção serão substituídos pelos itens da Escala. Tem certeza disso?";

                dialogService.confirm(msg, "Substituir").then(function() {
                    escalaService.findItensAtivosByEscalaId(escala.id).then(function(escalaItens) {
                        if (escalaItens.length) {
                            vm.items = [];

                            vm.newItems = _.map(escalaItens, function(escalaItem) {
                                return {
                                    nrOrdem: escalaItem.nrOrdem,
                                    nome: escalaItem.nome,
                                    situacao: escalaItem.situacao,
                                    valor: escalaItem.valor,
                                    descricao: escalaItem.descricao,
                                    escalaItemDTO: {
                                        id: escalaItem.id
                                    },
                                    permiteComplemento: false
                                };
                            });

                            escala.escalaItensDTO = escalaItens;
                        } else {
                            vm.items = [];
                            vm.newItems = [];
                            escala.escalaItensDTO = [];
                        }
                    });
                }, function() {
                    delete vm.opcao.escalaDTO;
                    vm.hasScale = false;
                });
            } else {
                vm.deleteFieldScale();
            }
        };

        (function() {
            _init();
        }());
    }

}());
