(function() {
    'use strict';

    angular.module('app.core').directive("iasFormFieldError", IasFormFieldError);
    IasFormFieldError.$inject = [];

    function IasFormFieldError() {
        return {
            scope: {
                label: '@',
                field: '=',
                form: '=',
                minLength: '=',
                maxLength: '=',
                minValue: '=?',
                maxValue: '=?',
                maxDateValue: '@?',
                minDateValue: '@?'
            },
            restrict: "E",
            templateUrl: "core/directives/error/partials/ias-form-field-error.html"
        };
    }

})();