(function() {
    'use strict';

    angular.module('app.core').directive("iasAdvancedTable", IasAdvancedTable);
    IasAdvancedTable.$inject = ['$q', '$uibModal', '$filter', 'toastService', 'objectService', '$interpolate'];

    function IasAdvancedTable($q, $uibModal, $filter, toastService, objectService, $interpolate) {
        var defaults = {
            canInsert: true,
            canDelete: true,
            canEditItem: true
        };

        return {
            scope: {
                entityName: '@',
                title: '@',
                fields: '=',
                items: '=',
                newItems: '=?',
                deleted: '=?',
                ngDisabled: '=',
                hasPreview: '=?',
                modalTemplateUrl: '@',
                modalDependencies: '=',
                modalSortField: '@',
                modalEntityName: '@?',
                modalUniqueField: '@?',
                modalUniqueLabel: '@?',
                saveItems: '&?',
                updateItems: '&?',
                previewItem: '&?',
                onlyUpdate: '=?',
                modified: '=?',
                canSort: '@?',
                canInsert: '=?',
                canDelete: '=?',
                canEditItem: '=?'
            },
            restrict: "E",
            templateUrl: "core/directives/table/partials/ias-advanced-table.html",
            link: function(scope, element, attr) {
                scope.newItems = scope.newItems || [];
                scope.modalSortField = scope.modalSortField || 'nrOrdem';
                scope.canSort = scope.canSort || null;
                scope.canInsert = angular.isUndefined(scope.canInsert) ? defaults.canInsert : scope.canInsert;
                scope.canDelete = angular.isUndefined(scope.canDelete ) ? defaults.canDelete : scope.canDelete;
                scope.canEditItem = angular.isUndefined(scope.canEditItem ) ? defaults.canEditItem : scope.canEditItem; 
                var _oldItemsModified = false;

                var _numberOfItems = function() {
                    return scope.items.length + scope.newItems.length;
                };

                var _valueToLowerCase = function(value) {
                    return value.toLowerCase();
                }

                var _isDuplicatedItem = function(key, uniqueField, list, item) {
                    var found = $filter('filter')(list, function(value, index, array) {
                        var value1 = _valueToLowerCase(objectService.deepValue(value, uniqueField));
                        var value2 = _valueToLowerCase(objectService.deepValue(item, uniqueField));

                        if (item[key]) {
                            return ((value1 == value2) && (value['$$hashKey'] != item[key]));
                        } else {
                            return value1 == value2;
                        }
                    });

                    return (found.length > 0);
                };

                var _findItem = function(searchKey, itemKey, list, item) {
                    var found = $filter('filter')(list, function(value, index, array) {
                        return value[searchKey] == item[itemKey];
                    });

                    return (found.length > 0) ? found[0] : null;
                };

                var _createModal = function(modalProperty) {
                    var modalTemplateUrl = modalProperty.templateUrl;
                    var modalDependencies = modalProperty.dependencies;
                    var modalEntityName = modalProperty.entityName;
                    var item = modalProperty.data;
                    var canChange = modalProperty.canChange;

                    var modal = $uibModal.open({
                        ariaLabelledBy: 'modal-title',
                        ariaDescribedBy: 'modal-body',
                        templateUrl: modalTemplateUrl,
                        size: 'lg',
                        controllerAs: 'vm',
                        controller: function($uibModalInstance) {
                            var _vm = this;
                            _vm.canChange = canChange;
                            var entityName = modalEntityName || "item";
                            var _oldItem = null;

                            angular.forEach(modalDependencies, function(value, key) {
                                _vm[key] = angular.copy(value);
                            });

                            if (item) {
                                _vm[entityName] = angular.copy(item);
                                _vm[entityName]._key = item.$$hashKey;
                                _oldItem = angular.copy(_vm[entityName]);
                            } else {
                                if (!_vm[entityName]) {
                                    _vm[entityName] = {};
                                }

                                _vm[entityName][scope.modalSortField] = _numberOfItems() + 1;
                            }

                            _vm.ok = function(form) {
                                if (form.$valid) {
                                    var key = (_vm[entityName].$$hashKey) ? "$$hashKey" : "_key";
                                    var uniqueField = scope.modalUniqueField;

                                    if (_isDuplicatedItem(key, uniqueField, scope.items, _vm[entityName]) ||
                                        _isDuplicatedItem(key, uniqueField, scope.newItems, _vm[entityName])) {

                                        var fieldName = scope.modalUniqueLabel || uniqueField;
                                        var msgTemplate = "Já existe um cadastro com o {{fieldName}} informado. Por favor, informe um outro {{fieldName}}.";
                                        var errorMessage = $interpolate(msgTemplate)({ fieldName: fieldName.toLowerCase() });
                                        toastService.error(errorMessage, 'flash-modal');
                                    } else {
                                        $uibModalInstance.close({
                                            item: _vm[entityName],
                                            oldItem: _oldItem
                                        });
                                    }
                                }
                            };

                            _vm.cancel = function() {
                                $uibModalInstance.dismiss('cancel');
                            };
                        }
                    });

                    return modal.result;
                }

                scope.showModal = function(modalItem) {
                    var deferred = $q.defer();

                    var modalProperty = {
                        templateUrl: scope.modalTemplateUrl,
                        dependencies: scope.modalDependencies,
                        entityName: scope.modalEntityName,
                        data: modalItem,
                        canChange: scope.canInsert
                    }
                    var modal = _createModal(modalProperty);

                    modal.then(function(result) {
                        var item = result.item;
                        var oldItem = result.oldItem;
                        var itemFound;

                        if (item.id) {
                            _oldItemsModified = !angular.equals(item, oldItem);
                            itemFound = _findItem("id", "id", scope.items, item);

                            if (itemFound) {
                                delete item._key;

                                if (!angular.equals(itemFound, item)) {
                                    item._updated = true;
                                }

                                angular.merge(itemFound, item);
                            }
                        } else {
                            itemFound = _findItem("$$hashKey", "_key", scope.newItems, item);

                            if (itemFound) {
                                angular.merge(itemFound, item);
                            } else {
                                scope.newItems.push(item);
                            }
                        }

                        scope.modified = (_oldItemsModified || scope.newItems.length > 0);

                        deferred.resolve();
                    }, function() {
                        deferred.reject();
                    });

                    return deferred.promise;
                };

                scope.save = function() {
                    if (scope.saveItems) {
                        scope.saveItems();
                    }
                };

                scope.preview = function(item) {
                    if (scope.previewItem) {
                        scope.previewItem({ item: item });
                    }
                };

                scope.update = function() {
                    if (scope.updateItems) {
                        scope.updateItems();
                    }
                };

                scope.onModified = function(wasModified) {
                    if (wasModified) {
                        _oldItemsModified = true;
                        scope.modified = true;
                    } else {
                        scope.modified = (_oldItemsModified || scope.newItems.length > 0);
                    }
                };

            }
        };
    }

})();