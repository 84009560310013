/*
jslint
    devel: true, maxerr: 10
*/

/*
global
    required: true
*/

/*
 * Relatórios Module
 * @module constants for class profile report
 * @author iFactory Solutions Ltda.
 * @version 1.0.0
 * @since 1.0.0
 */
(function() {
    'use strict';

    angular.module('app.relatorios').constant('ESCOLA_ANUAL_PERFIL_TURMA_FIELDS', [
        { name: 'idTurma', type: 'integer', notTotal: true },
        { name: 'nomeEtapa', type: 'string', notTotal: true },
        { name: 'turno.value', type: 'string', notTotal: true },
        { name: 'nomeTurma', type: 'string', notTotal: true },
        { name: 'mediaDiasLetivosAnteriorPrograma', type: 'integer', totalType: 'average' },

        { name: 'alunosMatriculadosMasculinoTotal', type: 'integer' },
        { name: 'alunosMatriculadosMasculinoPercencual', type: 'integer', totalType: 'average' },
        { name: 'alunosMatriculadosFemininoTotal', type: 'integer' },
        { name: 'alunosMatriculadosFemininoPercencual', type: 'integer', totalType: 'average' },
        { name: 'alunosMatriculadosTotal', type: 'integer' },

        { name: 'situacaoAnoAnteriorAprovadoTotal', type: 'integer' },
        { name: 'situacaoAnoAnteriorAprovadoPercentual', type: 'integer', totalType: 'average' },
        { name: 'situacaoAnoAnteriorReprovadoTotal', type: 'integer' },
        { name: 'situacaoAnoAnteriorReprovadoPercentual', type: 'integer', totalType: 'average' },
        { name: 'situacaoAnoAnteriorNaoFrequentouTotal', type: 'integer' },
        { name: 'situacaoAnoAnteriorNaoFrequentouPercentual', type: 'integer', totalType: 'average' },

        { name: 'anoSerieOrigem21', type: 'integer' },
        { name: 'anoSerieOrigem32', type: 'integer' },
        { name: 'anoSerieOrigem43', type: 'integer' },
        { name: 'anoSerieOrigem54', type: 'integer' },
        { name: 'anoSerieOrigemtotal', type: 'integer' },
        { name: 'anoSerieOrigemPercentual', type: 'integer', totalType: 'average' },
        { name: 'anoSerieOrigemSL', type: 'integer' },

        { name: 'idade8', type: 'integer' },
        { name: 'idade9', type: 'integer' },
        { name: 'idade10', type: 'integer' },
        { name: 'idade11', type: 'integer' },
        { name: 'idade12', type: 'integer' },
        { name: 'idade13', type: 'integer' },
        { name: 'idade14', type: 'integer' },
        { name: 'idade15', type: 'integer' },
        { name: 'idadeTotal', type: 'integer' },
        { name: 'idadePercentual', type: 'integer', totalType: 'average' }
    ]);

}());