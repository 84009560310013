(function(){
    'use strict';

    angular.module('app.programaExterno').service('programaExternoService', ProgramaExternoService);
    ProgramaExternoService.$inject = ['ProgramaExterno', 'STATUS_ENUM'];

    function ProgramaExternoService(ProgramaExterno, STATUS_ENUM) {

        this.filter = function(filter) {
            return ProgramaExterno.filter(filter).$promise;            
        };

        this.get = function(id) {
            return ProgramaExterno.get({ id: id }).$promise;
        };

        this.delete = function(programaExterno) {
            return ProgramaExterno.delete({id: programaExterno.id}).$promise;
        };

        this.ativos = function() {
            return ProgramaExterno.ativo();
        };

        this.porNome = function(nome) {
            return ProgramaExterno.porNome({ nome: nome }).$promise;
        };

        this.save = function(programaExterno) {
            var entity = new ProgramaExterno(programaExterno);
            
            if (entity.id) {
                return entity.$update();
            } else {
                return entity.$save();
            }
        };

    }

}());
