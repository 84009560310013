(function() {
    'use strict';

    angular.module('app.formExemplo', ['app.core']).config(config);
    config.$inject = ['$stateProvider', 'APP_STATE'];

    function config($stateProvider, APP_STATE) {
        $stateProvider
            .state(APP_STATE.FORM.BUSCA, {
                url: '/search',
                views: {
                    'internal': {
                        templateUrl: 'form-exemplo/partials/form-busca.html',
                        controller: 'FormExemploController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    permission: "FOM:UP",
                    breadCrumb: [{
                            display: "Formulários",
                            active: false
                        },
                        {
                            display: "Exemplo",
                            active: false,
                       
                        },
                        {
                            display: "Alterar",
                            active: true
                        }
                    ],
                    search: {
                        enabled: false
                    }
                }
            });
            
    }
}
());