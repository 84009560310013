(function(){
    'use strict';

    angular.module('app.modalidadeEnsino').service('modalidadeEnsinoService', ModalidadeEnsinoService);
    ModalidadeEnsinoService.$inject = ['ModalidadeEnsino', 'STATUS_ENUM'];

    function ModalidadeEnsinoService(ModalidadeEnsino, STATUS_ENUM) {

        this.filter = function(filter) {
            return ModalidadeEnsino.filter(filter).$promise;            
        };

        this.get = function(id) {
            return ModalidadeEnsino.get({ id: id }).$promise;
        };

        this.delete = function(modalidadeEnsino) {
            return ModalidadeEnsino.delete({id: modalidadeEnsino.id}).$promise;
        };

        this.ativos = function() {
            return ModalidadeEnsino.ativo();
        };

        this.save = function(modalidadeEnsino) {
            var entity = new ModalidadeEnsino(modalidadeEnsino);
            
            if (entity.id) {
                return entity.$update();
            } else {
                return entity.$save();
            }
        };

    }

}());
