/*
jslint
    devel: true, maxerr: 10
*/

/*
global
    required: true
*/

/*
 * Relatórios Module
 * @module constants for class profile report
 * @author iFactory Solutions Ltda.
 * @version 1.0.0
 * @since 1.0.0
 */
(function() {
    'use strict';

    /* Perfil da Turma: Fórmula da Vitória */
    angular.module('app.relatorios').constant('PERFIL_TURMA_REPORT_FORMULA_VITORIA_FIELDS', [
        { name: 'report.id', type: 'integer', calculateTotal: false, showFooter: false },

        { name: 'detailing.name', type: 'string', calculateTotal: false, showFooter: false },

        { name: 'student.enrolled.male.ammount', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'student.enrolled.male.percent', percent: true, referenceField: 'student.enrolled.male.ammount', 
            referenceTotalField: 'student.enrolled.ammount', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'student.enrolled.female.ammount', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'student.enrolled.female.percent', percent: true, referenceField: 'student.enrolled.female.ammount', 
            referenceTotalField: 'student.enrolled.ammount', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'student.enrolled.ammount', type: 'integer', calculateTotal: true, calculateType: 'sum' },

        { name: 'student.previeousYearSituation.approved.ammount', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'student.previeousYearSituation.approved.percent', percent: true, referenceField: 'student.previeousYearSituation.approved.ammount', 
            referenceTotalField: 'student.enrolled.ammount', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'student.previeousYearSituation.disapproved.ammount', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'student.previeousYearSituation.disapproved.percent', percent: true, referenceField: 'student.previeousYearSituation.disapproved.ammount', 
            referenceTotalField: 'student.enrolled.ammount', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'student.previeousYearSituation.absent.ammount', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'student.previeousYearSituation.absent.percent', percent: true, referenceField: 'student.previeousYearSituation.absent.ammount', 
            referenceTotalField: 'student.enrolled.ammount', type: 'integer', calculateTotal: true, calculateType: 'average' },

        { name: 'student.originSerie.serie5.ammount', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'student.originSerie.serie6.ammount', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'student.originSerie.serie7.ammount', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'student.originSerie.serie8.ammount', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'student.originSerie.ammount', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'student.originSerie.percent', percent: true, referenceField: 'student.originSerie.ammount', 
            referenceTotalField: 'student.enrolled.ammount', type: 'integer', calculateTotal: true, calculateType: 'average' },

        { name: 'student.age.equals10.ammount', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'student.age.equals11.ammount', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'student.age.equals12.ammount', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'student.age.equals13.ammount', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'student.age.equals14.ammount', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'student.age.equals15.ammount', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'student.age.since16.ammount', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'student.age.ammount', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'student.age.percent', percent: true, referenceField: 'student.age.ammount', referenceTotalField: 'student.enrolled.ammount',
            type: 'integer', calculateTotal: true, calculateType: 'average' }
    ]);

    /* Perfil da Turma: Alfabetização e Aceleração */
    angular.module('app.relatorios').constant('PERFIL_TURMA_REPORT_ALFABETIZACAO_ACELERACAO_FIELDS', [
        { name: 'report.id', type: 'integer', calculateTotal: false, showFooter: false },

        //{ name: 'program.name', type: 'integer', calculateTotal: false, showFooter: false },

        //{ name: 'class.shift', type: 'string', calculateTotal: false, showFooter: false },
        { name: 'detailing.name', type: 'string', calculateTotal: false, showFooter: false },

        { name: 'averageSchoolDaysPriorProgram.average', type: 'string', calculateTotal: false },

        { name: 'student.enrolled.male.ammount', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'student.enrolled.male.percent', percent: true, referenceField: 'student.enrolled.male.ammount', 
            referenceTotalField: 'student.enrolled.ammount', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'student.enrolled.female.ammount', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'student.enrolled.female.percent', percent: true, referenceField: 'student.enrolled.female.ammount', 
            referenceTotalField: 'student.enrolled.ammount', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'student.enrolled.ammount', type: 'integer', calculateTotal: true, calculateType: 'sum' },

        { name: 'student.previeousYearSituation.approved.ammount', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'student.previeousYearSituation.approved.percent', percent: true, referenceField: 'student.previeousYearSituation.approved.ammount', 
            referenceTotalField: 'student.enrolled.ammount', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'student.previeousYearSituation.disapproved.ammount', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'student.previeousYearSituation.disapproved.percent', percent: true, referenceField: 'student.previeousYearSituation.disapproved.ammount', 
            referenceTotalField: 'student.enrolled.ammount', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'student.previeousYearSituation.absent.ammount', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'student.previeousYearSituation.absent.percent', percent: true, referenceField: 'student.previeousYearSituation.absent.ammount', 
            referenceTotalField: 'student.enrolled.ammount', type: 'integer', calculateTotal: true, calculateType: 'average' },

        { name: 'student.originSerie.serie21.ammount', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'student.originSerie.serie32.ammount', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'student.originSerie.serie43.ammount', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'student.originSerie.serie54.ammount', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'student.originSerie.ammount', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'student.originSerie.percent', percent: true, referenceField: 'student.originSerie.ammount', 
            referenceTotalField: 'student.enrolled.ammount', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'student.originSerie.sl', type: 'integer', calculateTotal: true, calculateType: 'sum' },

        { name: 'student.age.equals8.ammount', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'student.age.equals9.ammount', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'student.age.equals10.ammount', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'student.age.equals11.ammount', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'student.age.equals12.ammount', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'student.age.equals13.ammount', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'student.age.equals14.ammount', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'student.age.equals15.ammount', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'student.age.ammount', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'student.age.percent', percent: true, referenceField: 'student.age.ammount', referenceTotalField: 'student.enrolled.ammount',
            type: 'integer', calculateTotal: true, calculateType: 'average' }
    ]);

    /* Perfil da Turma: Gestão Nota 10 */
    angular.module('app.relatorios').constant('PERFIL_TURMA_REPORT_ENSINO_MEDIO_GESTAO_NOTA_10_FIELDS', [
        { name: 'report.id', type: 'integer', calculateTotal: false, showFooter: false },

        { name: 'detailing.name', type: 'string', calculateTotal: false, showFooter: false },

        { name: 'student.enrolled.male.ammount', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'student.enrolled.male.percent', percent: true, referenceField: 'student.enrolled.male.ammount', 
            referenceTotalField: 'student.enrolled.ammount', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'student.enrolled.female.ammount', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'student.enrolled.female.percent', percent: true, referenceField: 'student.enrolled.female.ammount', 
            referenceTotalField: 'student.enrolled.ammount', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'student.enrolled.ammount', type: 'integer', calculateTotal: true, calculateType: 'sum' },

        { name: 'student.previeousYearSituation.approved.ammount', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'student.previeousYearSituation.approved.percent', percent: true, referenceField: 'student.previeousYearSituation.approved.ammount', 
            referenceTotalField: 'student.enrolled.ammount', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'student.previeousYearSituation.disapproved.ammount', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'student.previeousYearSituation.disapproved.percent', percent: true, referenceField: 'student.previeousYearSituation.disapproved.ammount', 
            referenceTotalField: 'student.enrolled.ammount', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'student.previeousYearSituation.absent.ammount', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'student.previeousYearSituation.absent.percent', percent: true, referenceField: 'student.previeousYearSituation.absent.ammount', 
            referenceTotalField: 'student.enrolled.ammount', type: 'integer', calculateTotal: true, calculateType: 'average' },

        { name: 'student.originSerie.serie98.ammount', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'student.originSerie.serie1.ammount', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'student.originSerie.serie2.ammount', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'student.originSerie.serie3.ammount', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'student.originSerie.ammount', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'student.originSerie.percent', percent: true, referenceField: 'student.originSerie.ammount', 
            referenceTotalField: 'student.enrolled.ammount', type: 'integer', calculateTotal: true, calculateType: 'average' },

        { name: 'student.age.equals14.ammount', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'student.age.equals15.ammount', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'student.age.equals16.ammount', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'student.age.equals17.ammount', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'student.age.equals18.ammount', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'student.age.equals19.ammount', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'student.age.since20.ammount', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'student.age.ammount', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'student.age.percent', percent: true,  referenceField: 'student.age.ammount', referenceTotalField: 'student.enrolled.ammount',
            type: 'integer', calculateTotal: true, calculateType: 'average' }
    ]);

    /* Perfil da Turma: Ensino Regular */
    angular.module('app.relatorios').constant('PERFIL_TURMA_REPORT_ENSINO_REGULAR_FIELDS', [
        { name: 'id', type: 'integer', calculateTotal: false, showFooter: false },
        { name: 'detalhamento', type: 'string', calculateTotal: false, showFooter: false },

        { name: 'alunosSexoMasculino', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'alunosSexoMasculinoPercentual', percent: true, referenceField: 'alunosSexoMasculino', 
            referenceTotalField: 'alunosMatriculadosTotal', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'alunosSexoFeminino', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'alunosSexoFemininoPercentual', percent: true, referenceField: 'alunosSexoFeminino', 
            referenceTotalField: 'alunosMatriculadosTotal', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'alunosMatriculadosTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        
        { name: 'situacaoAnoAnteriorAlunosAprovados', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'situacaoAnoAnteriorAlunosAprovadosPercentual', percent: true, referenceField: 'situacaoAnoAnteriorAlunosAprovados', 
            referenceTotalField: 'alunosMatriculadosTotal', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'situacaoAnoAnteriorAlunosReprovados', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'situacaoAnoAnteriorAlunosReprovadosPercentual', percent: true,  referenceField: 'situacaoAnoAnteriorAlunosReprovados', 
            referenceTotalField: 'alunosMatriculadosTotal', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'situacaoAnoAnteriorAlunosNaoFreq', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'situacaoAnoAnteriorAlunosNaoFreqPercentual', percent: true,  referenceField: 'situacaoAnoAnteriorAlunosNaoFreq', 
            referenceTotalField: 'alunosMatriculadosTotal', type: 'integer', calculateTotal: true, calculateType: 'average' },
        
        { name: 'anoOrigemAlunosEdInfantil', type: 'integer',  calculateTotal: true, calculateType: 'sum' },
        { name: 'anoOrigemAlunosEf1Ano', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'anoOrigemAlunosEf2Ano', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'anoOrigemAlunosEf3Ano', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'anoOrigemAlunosEf4Ano', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'anoOrigemAlunosEf5Ano', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'anoOrigemAlunosEf6Ano', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'anoOrigemAlunosEf7Ano', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'anoOrigemAlunosEf8Ano', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'anoOrigemAlunosEf9Ano', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'anoSerieOrigemTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'anoSerieOrigemPercentual', percent: true, referenceField: 'anoSerieOrigemTotal', referenceTotalField: 'alunosMatriculadosTotal', 
            type: 'integer', calculateTotal: true, calculateType: 'average' },
    
        { name: 'anoOrigemAlunosSeLiga', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'anoOrigemAlunosAceleraBrasil', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        
        { name: 'alunos6AnosAlfabetizados', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'alunos6AnosNaoAlfabetizados', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'alunos7AnosAlfabetizados', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'alunos7AnosNaoAlfabetizados', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'alunos8AnosAlfabetizados', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'alunos8AnosNaoAlfabetizados', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'alunos9AnosAlfabetizados', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'alunos9AnosNaoAlfabetizados', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'alunos10AnosAlfabetizados', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'alunos10AnosNaoAlfabetizados', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'alunos11AnosAlfabetizados', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'alunos11AnosNaoAlfabetizados', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'alunos12AnosAlfabetizados', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'alunos12AnosNaoAlfabetizados', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'alunos13AnosAlfabetizados', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'alunos13AnosNaoAlfabetizados', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'alunos14AnosAlfabetizados', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'alunos14AnosNaoAlfabetizados', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'alunos15AnosAlfabetizados', type: 'integer', hideByPrograms: [8,9,10,13,14], calculateTotal: true, calculateType: 'sum' },
        { name: 'alunos15AnosNaoAlfabetizados', type: 'integer', hideByPrograms: [8,9,10,13,14], calculateTotal: true, calculateType: 'sum' },
        { name: 'alunos15AnosOuMaisAlfabetizados', type: 'integer', hideByPrograms: [16,17,18,19,20,21,22,23,24], calculateTotal: true, calculateType: 'sum' },
        { name: 'alunos15AnosOuMaisNaoAlfabetizados', type: 'integer', hideByPrograms: [16,17,18,19,20,21,22,23,24], calculateTotal: true, calculateType: 'sum' },
        { name: 'alunos16AnosOuMaisAlfabetizados', type: 'integer', hideByPrograms: [8,9,10,13,14], calculateTotal: true, calculateType: 'sum' },
        { name: 'alunos16AnosOuMaisNaoAlfabetizados', type: 'integer', hideByPrograms: [8,9,10,13,14], calculateTotal: true, calculateType: 'sum' },
        { name: 'idadeAlfabetizacaoAlunosTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'idadeAlfabetizacaoAlunosPercentual', percent: true, referenceField: 'idadeAlfabetizacaoAlunosTotal', 
            referenceTotalField: 'alunosMatriculadosTotal', type: 'integer', calculateTotal: true, calculateType: 'average' },
    ]);

    /* Perfil da Turma: Sistematica Acompanhamento Diálogos */
    angular.module('app.relatorios').constant('PERFIL_TURMA_REPORT_SISTEMATICA_ACOMPANHAMENTO_DIALOGOS_FIELDS', [
        { name: 'id', type: 'integer', calculateTotal: false, showFooter: false },
        { name: 'uf', type: 'string', showOnGroup:['T', 'E', 'M', 'R', 'U', 'G'], calculateTotal: false, showFooter: false },
        { name: 'municipio', type: 'string', showOnGroup:['T', 'E', 'M', 'R', '8'], calculateTotal: false, showFooter: false },
        { name: 'nomeDaEscola', type: 'string', showOnGroup:['T', 'E'], calculateTotal: false, showFooter: false },
        { name: 'turma', type: 'string', showOnGroup:['T'], calculateTotal: false, showFooter: false },
        { name: 'anoEscolar', type: 'string', showOnGroup:['T', 'E', '8'], calculateTotal: false, showFooter: false },
        { name: 'numeroDeTurmas', type: 'integer', showOnGroup:['M', 'U', 'G', 'R', '8'], calculateTotal: true,  calculateType: 'sum'},
        { name: 'numeroDeTurmasNoDialogos', type: 'integer', showOnGroup:['M', 'U', 'G', 'R', '8'], calculateTotal: true,  calculateType: 'sum'},
        { name: 'numeroDeProfessoresEad', type: 'integer', showOnGroup:['M', 'U', 'G', 'R', '8'], calculateTotal: true,  calculateType: 'sum'},
        { name: 'numeroDeTurmasDialogosComRubricas', type: 'integer', showOnGroup:['M', 'U', 'G', 'R', '8'], calculateTotal: true,  calculateType: 'sum'},
        { name: 'alunosMatriculadosTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
    ]);

    /* Perfil da Turma: Gestão da Alfabetização */
    angular.module('app.relatorios').constant('PERFIL_TURMA_REPORT_GESTAO_DA_ALFABETIZACAO_FIELDS', [
        { name: 'id', type: 'integer', calculateTotal: false, showFooter: false },
        
        { name: 'uf', type: 'string', showOnGroup:['T', 'E', 'M', 'R', 'U', 'G'], calculateTotal: false, showFooter: false },
        { name: 'grupoIAS', type: 'string', showOnGroup:['8'], calculateTotal: false, showFooter: false },
        { name: 'municipio', type: 'string', showOnGroup:['T', 'E', 'M', 'R', '8'], calculateTotal: false, showFooter: false },
        { name: 'nomeDaEscola', type: 'string', showOnGroup:['T', 'E'], calculateTotal: false, showFooter: false },
        { name: 'turma', type: 'string', showOnGroup:['T'], calculateTotal: false, showFooter: false },
        { name: 'regionalMunicipal', type: 'string', showOnGroup:['R'], calculateTotal: false, showFooter: false },
        { name: 'anoEscolar', type: 'string', showOnGroup:['T', 'E', '8'], calculateTotal: false, showFooter: false },
        { name: 'noRegionalUF', type: 'string', showOnGroup:['G'], calculateTotal: false, showFooter: false },
        
        { name: 'numeroDeTurmas', type: 'integer', showOnModelo:[1, 2], showOnGroup:['M', 'U', 'G', 'R', '8'], calculateTotal: true,  calculateType: 'sum'},

        { name: 'alunosSexoMasculino', type: 'integer', showOnModelo:[1, 2], calculateTotal: true, calculateType: 'sum' },
        { name: 'alunosSexoMasculinoPercentual', percent: true, showOnModelo:[1, 2], referenceField: 'alunosSexoMasculino', 
            referenceTotalField: 'alunosMatriculadosTotal',  type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'alunosSexoFeminino', type: 'integer', showOnModelo:[1, 2], calculateTotal: true, calculateType: 'sum' },
        { name: 'alunosSexoFemininoPercentual',showOnModelo:[1, 2],  percent: true, referenceField: 'alunosSexoFeminino', 
            referenceTotalField: 'alunosMatriculadosTotal', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'alunosMatriculadosTotal', type: 'integer', showOnModelo:[1, 2], calculateTotal: true, calculateType: 'sum' },
        
        { name: 'situacaoAnoAnteriorAlunosAprovados', type: 'integer',showOnModelo:[1], calculateTotal: true, calculateType: 'sum' },
        { name: 'situacaoAnoAnteriorAlunosAprovadosPercentual', percent: true,showOnModelo:[1],  referenceField: 'situacaoAnoAnteriorAlunosAprovados', 
            referenceTotalField: 'alunosMatriculadosTotal', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'situacaoAnoAnteriorAlunosReprovados', type: 'integer', showOnModelo:[1], calculateTotal: true, calculateType: 'sum' },
        { name: 'situacaoAnoAnteriorAlunosReprovadosPercentual', percent: true, showOnModelo:[1],  referenceField: 'situacaoAnoAnteriorAlunosReprovados', 
            referenceTotalField: 'alunosMatriculadosTotal', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'situacaoAnoAnteriorAlunosNaoFreq', type: 'integer', showOnModelo:[1], calculateTotal: true, calculateType: 'sum' },
        { name: 'situacaoAnoAnteriorAlunosNaoFreqPercentual', percent: true,  showOnModelo:[1], referenceField: 'situacaoAnoAnteriorAlunosNaoFreq', 
            referenceTotalField: 'alunosMatriculadosTotal', type: 'integer', calculateTotal: true, calculateType: 'average' },
        
 
        { name: 'idade5', type: 'integer', showOnModelo:[1], calculateTotal: true, calculateType: 'sum' },
        { name: 'idade6', type: 'integer', showOnModelo:[1], calculateTotal: true, calculateType: 'sum' },
        { name: 'idade7', type: 'integer', showOnModelo:[1], calculateTotal: true, calculateType: 'sum' },
        { name: 'idade8', type: 'integer', showOnModelo:[1], calculateTotal: true, calculateType: 'sum' },
        { name: 'idade9', type: 'integer', showOnModelo:[1], calculateTotal: true, calculateType: 'sum' },
        { name: 'idade10', type: 'integer', showOnModelo:[1], calculateTotal: true, calculateType: 'sum' },
        { name: 'idade11', type: 'integer', showOnModelo:[1], calculateTotal: true, calculateType: 'sum' },
        { name: 'idade12', type: 'integer', showOnModelo:[1], calculateTotal: true, calculateType: 'sum' },
        { name: 'idade13', type: 'integer', showOnModelo:[1], calculateTotal: true, calculateType: 'sum' },
        { name: 'idade14mais', type: 'integer', showOnModelo:[1], calculateTotal: true, calculateType: 'sum' },
        { name: 'idadeTotal', type: 'integer', showOnModelo:[1], calculateTotal: true, calculateType: 'sum' },
        { name: 'pcInsercao', type: 'integer',percent: true, showOnModelo:[1], calculateTotal: true, calculateType: 'average' },



        //{ name: 'matriculaFinal', type: 'integer', showOnModelo:[2], calculateTotal: true, calculateType: 'sum' },
        { name: 'vlNivelPtInicio1', type: 'integer', showOnModelo:[2], calculateTotal: true, calculateType: 'sum' },
        { name: 'vlNivelPtInicio2', type: 'integer', showOnModelo:[2], calculateTotal: true, calculateType: 'sum' },
        { name: 'vlNivelPtInicio3', type: 'integer', showOnModelo:[2], calculateTotal: true, calculateType: 'sum' },
        { name: 'vlNivelPtInicio4', type: 'integer', showOnModelo:[2], calculateTotal: true, calculateType: 'sum' },
        { name: 'vlNivelPtFinal1', type: 'integer', showOnModelo:[2], calculateTotal: true, calculateType: 'sum' },
        { name: 'vlNivelPtFinal2', type: 'integer', showOnModelo:[2], calculateTotal: true, calculateType: 'sum' },
        { name: 'vlNivelPtFinal3', type: 'integer', showOnModelo:[2], calculateTotal: true, calculateType: 'sum' },
        { name: 'vlNivelPtFinal4', type: 'integer', showOnModelo:[2], calculateTotal: true, calculateType: 'sum' },
        { name: 'vlNivelMtInicio1', type: 'integer', showOnModelo:[2], calculateTotal: true, calculateType: 'sum' },
        { name: 'vlNivelMtInicio2', type: 'integer', showOnModelo:[2], calculateTotal: true, calculateType: 'sum' },
        { name: 'vlNivelMtInicio3', type: 'integer', showOnModelo:[2], calculateTotal: true, calculateType: 'sum' },
        { name: 'vlNivelMtInicio4', type: 'integer', showOnModelo:[2], calculateTotal: true, calculateType: 'sum' },
        { name: 'vlNivelMtFinal1', type: 'integer', showOnModelo:[2], calculateTotal: true, calculateType: 'sum' },
        { name: 'vlNivelMtFinal2', type: 'integer', showOnModelo:[2], calculateTotal: true, calculateType: 'sum' },
        { name: 'vlNivelMtFinal3', type: 'integer', showOnModelo:[2], calculateTotal: true, calculateType: 'sum' },
        { name: 'vlNivelMtFinal4', type: 'integer', showOnModelo:[2], calculateTotal: true, calculateType: 'sum' },

        { name: 'vlAprovados', type: 'integer', showOnModelo:[2], calculateTotal: true, calculateType: 'sum' },
        { name: 'pcAprovados', type: 'integer', percent: true, showOnModelo:[2], calculateTotal: true, calculateType: 'average' },
        { name: 'vlNaoAprovados', type: 'integer', showOnModelo:[2], calculateTotal: true, calculateType: 'sum' },
        { name: 'pcNaoAprovados', type: 'integer',percent: true, showOnModelo:[2], calculateTotal: true, calculateType: 'average' },
    ]);
}());