(function() {
    'use strict';

    angular.module('app.core').directive("iasFormAdd", IasFormAdd);
    IasFormAdd.$inject = [
        '$uibModal', 'DialogService', '$http',
        'toastService', 'APP_PROPERTY', 'ufService',
        'municipiosService', 'utilService'
    ];

    function IasFormAdd($uibModal, DialogService, $http,
                        toastService, APP_PROPERTY, ufService, municipiosService, utilService) {

        var _prepareData = function(restSave, data) {
            if (restSave == '/professores') {
                data.pessoa.nomeCurto = utilService.nomeCurto(data.pessoa.nomeCompleto);
            }

            return data;
        };

        var _setupVm = function(restSave, vm) {
            if (restSave == '/professores') {
                vm.professor = {};
                vm.municipios = [];
                vm.ufs = ufService.ativos();

                vm.getMunicipiosPorUf = function(ufId) {
                    if (ufId > 0) {
                        vm.municipios = municipiosService.porUf(ufId);
                    }
                };
            }

            return vm;
        }

        return {
            scope: {
                template: '@',
                restSave: '@',
                callback: '&?',
                ngDisabled: '=?'
            },
            restrict: "E",
            templateUrl: "core/directives/form/partials/form-add.html",
            link: function(scope) {
                scope.openModal = function () {
                    DialogService.confirm("Ao clicar neste botão, você irá realizar o cadastro de um novo Professor. Você tem certeza que o Professor desta turma realmente não está cadastrado? Para consultar o cadastro de Professores, acesse o menu Parcerias->Professores", function(){
                        DialogService.modal(
                            scope.template,
                            function($uibModalInstance){
                                var vm = this;
                                vm = _setupVm(scope.restSave, vm);

                                vm.save = function(data) {
                                    if (vm.formAdd.$valid) {
                                        data = _prepareData(scope.restSave, data);

                                        $http.post(APP_PROPERTY.URL + scope.restSave, data).then(function(response) {
                                            if (is.function(scope.callback)) {
                                                scope.callback({response:response.data});
                                            }

                                            $uibModalInstance.dismiss('cancel');
                                        }, function(error){
                                            toastService.error(error.data.message, 'flash-modal');
                                        });
                                    }
                                };

                                vm.cancel = function() {
                                    $uibModalInstance.dismiss('cancel');
                                };
                            }
                        );
                    })
                }
            }
        };
    }

})();
