(function() {
    'use strict';

    angular.module('app.core').directive("iasPagination", IasPagination);
    IasPagination.$inject = [];

    function IasPagination() {
        return {
            replace:false,
            scope: {
                list: '=',
                limitPage: '@'
            },
            templateUrl: "core/directives/pagination/partials/pagination.html",
            link: function(scope, element, attr) {
                var limitPage = 10;
                if (scope.limitPage) {
                    limitPage = parseInt(scope.limitPage);
                }
                var pagination = [];
                var totalRows = scope.list.length;
                var totalPages = Math.ceil(totalRows/limitPage);

                var _listPerPage = function(start, total, list) {
                    var newList = [];

                    for (var i = start; i < total; i++) {
                        if (list[i]) {
                            newList.push(list[i]);
                        }
                    }

                    return newList;
                };

                for(var p = 0; p < totalPages; p++) {
                    var start = p * limitPage;
                    var total = start + limitPage;

                    pagination.push({
                        'number': p + 1,
                        'current': (p == 0) ? true : false ,
                        'list': _listPerPage(start, total, scope.list)
                    });
                }

                scope.limitPage = limitPage;
                scope.totalList = scope.list.length;
                scope.totalRows = totalRows;
                scope.totalPages = totalPages;
                scope.pagination = pagination;
                scope.list = pagination[0].list;

                scope.getPage = function(page) {
                    scope.list = page.list;
                    angular.forEach(scope.pagination, function(value){
                        value.current = false;

                        if (value.number == page.number) {
                            page.current = true;
                        }
                    });
                }

            }
        };
    }

})();
