(function() {
    'use strict';

    angular.module('app.periodoLetivo').controller('PeriodoLetivoController', PeriodoLetivoController);
    PeriodoLetivoController.$inject = ['APP_STATE', 'APP_EVENT', 'SEARCH_BY_FIELD', '$state', '$scope','$uibModal', 
        'dialogService', 'periodoLetivoService', 'toastService', 'SecurityManagerFactory'];

    function PeriodoLetivoController(APP_STATE, APP_EVENT, SEARCH_BY_FIELD, $state, $scope, $uibModal, 
        dialogService, periodoLetivoService, toastService, SecurityManagerFactory) {

        var vm = this;

        vm.goNewPage = function() {
            $state.go(APP_STATE.PERIODO_LETIVO.NEW);
        };

        vm.goEditPage = function(id) {
            $state.go(APP_STATE.PERIODO_LETIVO.EDIT, { id: id });
        };

        vm.clearSearchValue = function() {
            vm.searchValue = "";
            vm.searchValueModel = "";
        };

        vm.search = function() {
            vm.periodoLetivo = [];
            vm.periodoLetivoTotal = 0;
            vm.searchWasPerformed = false;
            vm.searchValue = vm.searchValueModel || vm.searchValue;

            if (!vm.searchValueModel) {
                vm.searchValueModel = vm.searchValue;
            }

            if (vm.searchValue) {
                var filter = {};
                filter[vm.searchType.field] = vm.searchValue;

                periodoLetivoService.filter(filter).then(function(data) {
                    vm.periodoLetivo = angular.copy(data);
                }, function(response) {
                    toastService.error({
                        status: response.status,
                        message: response.data.message
                    });
                })
                .finally(function() {
                    vm.searchWasPerformed = true;
                    vm.periodoLetivoTotal = vm.periodoLetivo.length;
                    vm.formSearch.$setPristine();
                });
            }
        };

        vm.delete = function(periodoLetivo) {
            dialogService.delete().then(function() {
                periodoLetivoService.delete({id: periodoLetivo.id}).then(function(response) {
                        if (response.responseStatusCode == 200) {
                            toastService.success("Registro removido com sucesso.");
                        } else if (response.responseStatusCode == 202) {
                            toastService.success("Registro inativado com sucesso.");
                        }
                    vm.search();
                }, function(response) {
                    toastService.error({
                        status: response.status,
                        message: response.data.message
                    });
                });
            }, function() {});
        };

        (function() {
            vm.hasPermissionView = SecurityManagerFactory.hasPermission('PEL:VI');
            vm.periodoLetivo = [];
            vm.searchValue = "";
            vm.searchValueModel = "";
            vm.SEARCH_BY = SEARCH_BY_FIELD;
            vm.searchType = vm.SEARCH_BY.ANO_LETIVO;
            vm.searchWasPerformed = false;

            $scope.$on(APP_EVENT.PERIODO_LETIVO.SAVED, function() {
                toastService.success("Operação realizada com sucesso.");
                vm.search();
            });

            $scope.$on(APP_EVENT.PERIODO_LETIVO.REFRESH, function() {
                vm.search();
            });

        }());

    }

}());
