(function() {
    'use strict';

    angular.module('app.coletaRubrica.resposta').factory('ColetaRespostaProcessamentoLog', ColetaRespostaProcessamentoLog);
    ColetaRespostaProcessamentoLog.$inject = ['$resource', 'APP_PROPERTY'];

    function ColetaRespostaProcessamentoLog($resource, APP_PROPERTY) {
        return $resource(APP_PROPERTY.URL + '/coletas/respostas/processamento/log/:id', { id: '@id' }, {
            update: {
                method: 'PUT'
            },
            findByColetaRespostaProcessamentoId: {
                method: 'GET',
                isArray:true,
                url: APP_PROPERTY.URL + '/coletas/respostas/processamento/relatorio/log/:id'
            },
            save: {
                method: 'POST',
                url: APP_PROPERTY.URL + '/coletas/respostas/processamento'
            },

        });

    }

}());