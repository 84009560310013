(function() {
    'use strict';

    angular.module('app.core').directive('iasBetweenValue', IasBetweenValueValidation);

    function IasBetweenValueValidation() {
        return {
            require: 'ngModel',
            restrict: 'A',
            link: function(scope, element, attr, controller) {
                var minValue, maxValue;
                var minValueAttr = attr.iasBetweenMinValue;
                var maxValueAttr = attr.iasBetweenMaxValue;

                var _setMinValue = function(minValueAttr) {
                    if (is.existy(minValueAttr) && is.not.empty(minValueAttr)) {
                        minValue = parseFloat(minValueAttr);
                    } else {
                        minValue = null;
                    }

                    controller.$validate();
                };

                var _setMaxValue = function(maxValueAttr) {
                    if (is.existy(maxValueAttr) && is.not.empty(maxValueAttr)) {
                        maxValue = parseFloat(maxValueAttr);
                    } else {
                        maxValue = null;
                    }

                    controller.$validate();
                };

                var _registerValidators = function() {
                    if (minValue && maxValue) {
                        _registerBetweenValidator();
                    } else if (minValue) {
                        _registerMinValidator();
                    } else if (maxValue) {
                        _registerMaxValidator();
                    }
                };

                var _registerBetweenValidator = function() {
                    if (is.not.existy(controller.$validators.iasBetweenValue)) {
                        controller.$validators.iasBetweenValue = function(modelValue, viewValue) {
                            var value = modelValue || viewValue;

                            if (value && minValue && maxValue) {
                                var floatNumber = parseFloat(value);

                                return ((floatNumber >= minValue) && (floatNumber <= maxValue));
                            } else {
                                return true;
                            }
                        };
                    }
                };

                var _registerMinValidator = function() {
                    if (is.not.existy(controller.$validators.iasMinValue)) {
                        controller.$validators.iasMinValue = function(modelValue, viewValue) {
                            var value = modelValue || viewValue;

                            if (value && minValue) {
                                return parseFloat(value) >= minValue;
                            } else {
                                return true;
                            }
                        };
                    }
                };

                var _registerMaxValidator = function() {
                    if (is.not.existy(controller.$validators.iasMaxValue)) {
                        controller.$validators.iasMaxValue = function(modelValue, viewValue) {
                            var value = modelValue || viewValue;

                            if (value && maxValue) {
                                return parseFloat(value) <= maxValue;
                            } else {
                                return true;
                            }
                        };
                    }
                };

                _setMinValue(minValueAttr);
                _setMaxValue(maxValueAttr);
                _registerValidators();

                attr.$observe('iasBetweenMinValue', function(newMinValue) {
                  _setMinValue(newMinValue);
                  _registerValidators();
                });

                attr.$observe('iasBetweenMaxValue', function(newMaxValue) {
                  _setMaxValue(newMaxValue);
                  _registerValidators();
                });

            }
        };
    }

})();
