(function() {
    'use strict';

    angular.module('app.coleta').controller('ColetaController', ColetaController);
    ColetaController.$inject = ['APP_STATE', 'APP_EVENT', 'COLETA_SEARCH_BY', '$state', '$scope',
        'coletaService', 'dialogService', 'toastService', 'Upload', 'SecurityManagerFactory', 
        'COLETA_ANONIMA_DEFAULT_FIELDS', 'STATUS_ENUM_OBJECT', 'VALIDATION_PATTERN'
    ];

    function ColetaController(APP_STATE, APP_EVENT, COLETA_SEARCH_BY, $state, $scope,
        coletaService, dialogService, toastService, Upload, SecurityManagerFactory, 
        COLETA_ANONIMA_DEFAULT_FIELDS, STATUS_ENUM_OBJECT, VALIDATION_PATTERN) {

        var vm = this;
        var MODAL_TEMPLATE = 'coleta/partials/coleta-search-preview.html';
        var MODAL_SEND_MAILTO = 'coleta/partials/send-mailto-modal.html';

        vm.isColetaAnonima = function(coleta) {
            return coleta.tipoAcesso.key === COLETA_ANONIMA_DEFAULT_FIELDS.GRANULARIDADE.key;
        };

        vm.isColetaAnonimaAtiva = function(coleta) {
            if (coleta.situacao.key == STATUS_ENUM_OBJECT.ATIVO.key) {
                return vm.isColetaAnonima(coleta);
            }

            return false;
        };

        vm.goNewPage = function() {
            $state.go(APP_STATE.COLETA.NEW);
        };

        vm.goEditPage = function(id) {
            $state.go(APP_STATE.COLETA.EDIT, { id: id });
        };

        vm.clearSearchValue = function() {
            vm.searchValue = "";
            vm.searchValueModel = "";
        };

        vm.search = function() {
            vm.coletas = [];
            vm.coletasTotal = 0;
            if( !vm.searchValueModel && !vm.searchValue){
                return;
            }
            vm.searchWasPerformed = false;
            vm.searchValue = vm.searchValueModel || vm.searchValue;

            if (!vm.searchValueModel) {
                vm.searchValueModel = vm.searchValue;
            }

            if (vm.searchValue) {
                var filter = {};

                if (angular.isArray(vm.searchValue)) {
                    filter[vm.searchType.field] = _.map(vm.searchValue, function(value) {
                        return value.key;
                    });
                } else {
                    filter[vm.searchType.field] = vm.searchValue;
                }

                coletaService.filter(filter)
                    .then(function(data) {
                        vm.coletas = angular.copy(data);
                    }, function(response) {
                        toastService.error({
                            status: response.status,
                            message: response.data.message
                        });
                    })
                    .finally(function() {
                        vm.searchWasPerformed = true;
                        vm.coletasTotal = vm.coletas.length;
                        vm.formSearch.$setPristine();
                    });
            }
        };

        vm.delete = function(coleta) {
            dialogService.delete().then(function() {
                coletaService.delete({ id: coleta.id })
                    .then(function() {
                        toastService.success("Registro removido com sucesso.");
                        vm.search();
                    }, function(response) {
                        toastService.error({
                            status: response.status,
                            message: response.data.message
                        });
                    });
            }, function() {});
        };

        var _populateSearchFields = function() {
            coletaService.findTipos().then(function(tipos) {
                vm.tipos = tipos;
            });

            coletaService.findStatus().then(function(situacoes) {
                vm.situacoes = situacoes;
            });

            coletaService.findAbrangencias().then(function(abrangencias) {
                vm.abrangencias = abrangencias;
            });

            coletaService.findGranularidades().then(function(granularidades) {
                vm.granularidades = granularidades;
            });

            coletaService.findPeriodicidades().then(function(periodicidades) {
                vm.periodicidades = periodicidades;
            });

            coletaService.findAcessos().then(function(acessos) {
                vm.acessos = acessos;
            });
        };

        vm.preview = function(coleta) {
            coletaService.viewById(coleta.id).then(function(coletaView) {
                dialogService.modal(MODAL_TEMPLATE, function($uibModalInstance) {
                    var _vm = this;
                    _vm.coletaView = coletaView;
                    _vm.modalTitle = coleta.nome;

                    _vm.cancel = function() {
                        $uibModalInstance.dismiss('cancel');
                    };
                });

            }, function(response) {
                toastService.error({
                    status: response.status,
                    message: response.data.message
                });
            });
        };

        vm.goResponsePage = function(coleta) {
            $state.go(APP_STATE.RESPOSTA_COLETA.SEARCH, { coleta: coleta });
        };

        vm.sendByEmail = function(coleta) {
            if (vm.isColetaAnonimaAtiva(coleta)) {
                var modal = dialogService.modal(MODAL_SEND_MAILTO, function($uibModalInstance) {
                    var _vm = this;
                    _vm.emails = [];

                    _vm.emailPattern = VALIDATION_PATTERN.EMAIL.source;
                    
                    _vm.ok = function(form) {
                        if (form.$valid && is.not.empty(_vm.emails)) {
                            var emails = _.map(_vm.emails, function(tag) {
                                return tag.text;
                            });
                            coletaService.sendEmails(coleta.id, emails).then(function(result) {
                                $uibModalInstance.close(result);
                            }, function(response) {
                                toastService.error({
                                    status: response.status,
                                    message: response.data.message
                                }, 'flash-modal');
                            });
                        }
                    };

                    _vm.cancel = function() {
                        $uibModalInstance.dismiss('cancel');
                    };
                });

                modal.result.then(function(result) {
                    toastService.success("Emails enviados com sucesso.");
                }, function() {});
            }
        };

        (function() {
            vm.hasPermissionView = SecurityManagerFactory.hasPermission('COL:VI');
            vm.coletas = [];
            vm.searchValue = "";
            vm.SEARCH_BY = COLETA_SEARCH_BY;
            vm.searchType = vm.SEARCH_BY.NAME;
            vm.searchWasPerformed = false;
            vm.tipos = [];
            vm.situacoes = [];
            vm.abrangencias = [];
            vm.granularidades = [];
            vm.periodicidades = [];
            vm.acessos = [];
            vm.labels = {
                selectAll: "Selecionar todos",
                unselectAll: "Desmarcar",
                itemsSelected: "selecionados",
                search: "Pesquisar",
                select: "Selecione"
            };

            _populateSearchFields();

            $scope.$on(APP_EVENT.COLETA.SAVED, function() {
                toastService.success("Operação realizada com sucesso.");
                vm.search();
            });

            $scope.$on(APP_EVENT.COLETA.REFRESH, function() {
                vm.search();
            });

        }());

    }

}());