(function() {
    'use strict';

    angular.module('app.turmas').factory('Turmas', Turmas);
    Turmas.$inject = ['$resource', 'APP_PROPERTY', 'APP_MIME_TYPE', 'fileService'];

    function Turmas($resource, APP_PROPERTY, APP_MIME_TYPE, fileService) {
        return $resource(APP_PROPERTY.URL + '/turmas/:id', { id: '@id' }, {
            update: {
                method: 'PUT'
            },
            get: {
                method: 'GET'
            },
            salvarMovimentacaoMensal: {
                method: 'POST',
                url: APP_PROPERTY.URL + '/turmas/movimentacoes'
            },
            filter: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/turmas/searchBy'
            },
            filterPageable: {
                method: 'GET',
                isArray: false,
                url: APP_PROPERTY.URL + '/turmas/searchByPageable'
            },
            ativo: {
                method: 'GET',
                url: APP_PROPERTY.URL + '/turmas/ATIVO',
                isArray: true
            },
            findByStatus: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/turmas/:status'
            },
            porEscola: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/turmas/ativo?idEscola=:idEscola'
            },
            withPermissionFilter: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/turmas/ativo/withPermissionFilter'
            },
            turnos: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/turmas/turnos'
            },
            atendimentos: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/turmas/atendimentos'
            },
            delete: {
                method: 'DELETE',
                transformResponse: function(data, headers, statusCode) {
                    var finalRsponse = {
                        data: data,
                        responseStatusCode: statusCode
                    };
                    return finalRsponse;
                },
                url: APP_PROPERTY.URL + '/turmas/:id'
            },
            funcoesProfessor: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/turmas/funcoes-professor'
            },
            tiposContratacaoProfessor: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/turmas/tipos-contratacao-professor'
            },
            setCompetenciasLote: {
                method: 'PUT',
                isArray: true,
                url: APP_PROPERTY.URL + '/turmas/rubricas-em-lote/'
            },
            deleteCompetenciasLote: {
                method: 'PUT',
                isArray: true,
                url: APP_PROPERTY.URL + '/turmas/rubricas-em-lote/delete'
            },
            exportByUfAndMunicipioAndEscola: {
                method: 'GET',
                cache: false,
                responseType: 'arraybuffer',
                url: APP_PROPERTY.URL + '/turmas/exportar/:idAnoLetivo/:idPrograma/:idUf/:idMunicipio/:idEscola/import_template',
                headers: {
                    accept: [APP_MIME_TYPE.JSON, APP_MIME_TYPE.EXCEL].join()
                },
                transformResponse: function (data, headers) {
                    return fileService.transform(data, headers);
                }
            },
            exportByUfAndMunicipio: {
                method: 'GET',
                cache: false,
                responseType: 'arraybuffer',
                url: APP_PROPERTY.URL + '/turmas/exportar/:idAnoLetivo/:idPrograma/:idUf/:idMunicipio/import_template',
                headers: {
                    accept: [APP_MIME_TYPE.JSON, APP_MIME_TYPE.EXCEL].join()
                },
                transformResponse: function (data, headers) {
                    return fileService.transform(data, headers);
                }
            },
            exportByUf: {
                method: 'GET',
                cache: false,
                responseType: 'arraybuffer',
                url: APP_PROPERTY.URL + '/turmas/exportar/:idAnoLetivo/:idPrograma/:idUf/import_template',
                headers: {
                    accept: [APP_MIME_TYPE.JSON, APP_MIME_TYPE.EXCEL].join()
                },
                transformResponse: function (data, headers) {
                    return fileService.transform(data, headers);
                }
            },
            export: {
                method: 'GET',
                cache: false,
                responseType: 'arraybuffer',
                url: APP_PROPERTY.URL + '/turmas/exportar/:idAnoLetivo/:idPrograma/import_template',
                headers: {
                    accept: [APP_MIME_TYPE.JSON, APP_MIME_TYPE.EXCEL].join()
                },
                transformResponse: function (data, headers) {
                    return fileService.transform(data, headers);
                }
            }
        });

    }

}());
