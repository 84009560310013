(function () {
	'use strict';
	angular.module('app.turmas').controller('turmaImportTemplateController', TurmaImportTemplateController);
	TurmaImportTemplateController.$inject = [
		'APP_STATE', '$state', 'Professor', '$scope', 'DialogService', '$filter', 'APP_PROPERTY', 'toastService', 'validateService',
		'APP_EVENT', 'turmasService', 'programasService', 'PROFESSOR_MESSAGES', 'periodoLetivoService', 'contratoService'
	];

	function TurmaImportTemplateController(APP_STATE, $state, Professor, $scope,
		DialogService, $filter, APP_PROPERTY, toastService, validateService, APP_EVENT,
																				 turmasService, programasService, PROFESSOR_MESSAGES, periodoLetivoService, contratoService) {

		var vm = this;
		var msgs = PROFESSOR_MESSAGES;
		var MODAL_IMPORT_ERROR_LIST = 'turmas/partials/importacao-error-list.html';

		vm.professor = {};
		vm.periodosLetivos = periodoLetivoService.ativos();
		vm.programmas = [];
		vm.ufs = [];
		vm.municipios = [];
		vm.escolas = [];
		vm.mySwitch = true;

		vm.modalUpload = function () {
			DialogService.modal('turmas/partials/turma-form-upload.html',
				function ($uibModalInstance) {
					var _vm = this;
					_vm.status = { open: false };

					_vm.close = function () {
						$uibModalInstance.dismiss('cancel');
					};

					_vm.cancel = function () {
						_vm.close();
					};

					_vm.ok = function (file) {
						if (_vm.formUpload.$valid) {
							DialogService.confirm(msgs.warning.deleteResponses, function (response) {
								_vm.success = null;
								_vm.error = null;

								turmasService.import(file)
									.then(function (response) {
										_vm.success = msgs.success.import;
										_vm.sheetBack = response.data;
									})
									.catch(function (error) {
										_vm.error = error;
									});
							},
								msgs.info.importTitle);
						}
					};

					_vm.downloadXLSX = function () {
						if (_vm.sheetBack) {
							var contentType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
							var fileName = 'Resultado_Importacao_Turmas_' + new Date().getTime() + '.xlsx';
							var blob = new Blob([new Uint8Array(_vm.sheetBack)], { type: contentType });

							if (navigator.msSaveBlob) { // IE 10+
								navigator.msSaveBlob(blob, fileName);
							} else {
								var a = document.createElement('a');
								a.href = window.URL.createObjectURL(blob);
								a.target = '_blank';
								a.download = fileName;
								document.body.appendChild(a);
								a.click();
							}
						}
					}

					_vm.onChangeFile = function () {
						_vm.success = null;
						_vm.error = null;
					};

				});
		};

		vm.getProgramasPorAnoLetivo = function (anoLetivo) {
			if (anoLetivo) {
				vm.programas = programasService.ativosPorIdAnoLetivo(anoLetivo.id);
			} else {
				vm.programas = [];
			}
		}

		vm.getUfsPorPrograma = function (anoLetivo, programa) {
			if (anoLetivo && programa) {
				vm.ufs = contratoService.findUfs(anoLetivo.id, programa.id);
			} else {
				vm.ufs = [];
			}

			if (vm.professor.periodoLetivo && vm.professor.programa) {
				vm.mySwitch = false;
			} else {
				vm.mySwitch = true;
			}
		}

		vm.getMunicipiosPorUf = function (anoLetivo, programa, uf) {
			if (anoLetivo && programa && uf) {
				vm.municipios = contratoService.findMunicipios(anoLetivo.id, programa.id, uf.id);
			} else {
				vm.municipios = [];
			}
		};

		vm.getEscolasPorMunicipio = function (anoLetivo, programa, municipio) {
			if (anoLetivo && programa && municipio) {
				vm.escolas = contratoService.findEscolas(anoLetivo.id, programa.id, municipio.id);
			} else {
				vm.escolas = [];
			}
		}

		vm.exportImportTemplate = function (vm) {
			var idAnoLetivo = vm.professor.periodoLetivo.id;
			var idPrograma = vm.professor.programa.id;
			var idUf = vm.professor.uf ? vm.professor.uf.id : undefined;
			var idMunicipio = vm.professor.municipio ? vm.professor.municipio.id : undefined;
			var idEscola = vm.professor.escola ? vm.professor.escola.id : undefined;
			if (idUf && idMunicipio && idEscola) {
				turmasService.exportByUfAndMunicipioAndEscola(idAnoLetivo, idPrograma, idUf, idMunicipio, idEscola)
					.then(function () {
						toastService.success(msgs.success.export);
					})
					.catch(function (error) {
						toastService.error(error);
					});
			} else if (idUf && idMunicipio) {
				turmasService.exportByUfAndMunicipio(idAnoLetivo, idPrograma, idUf, idMunicipio)
					.then(function () {
						toastService.success(msgs.success.export);
					})
					.catch(function (error) {
						toastService.error(error);
					});
			} else if (idUf) {
				turmasService.exportByUf(idAnoLetivo, idPrograma, idUf)
					.then(function () {
						toastService.success(msgs.success.export);
					})
					.catch(function (error) {
						toastService.error(error);
					});
			} else {
				turmasService.export(idAnoLetivo, idPrograma)
					.then(function () {
						toastService.success(msgs.success.export);
					})
					.catch(function (error) {
						toastService.error(error);
					});
			}
		};

		vm.goSearchPage = function () {
			if (vm.refreshSearch) {
				$scope.$emit(APP_EVENT.PROFESSOR.REFRESH);
			}

			$state.go(APP_STATE.PROFESSOR.SEARCH);
		};
	}
}());
