(function(){
    'use strict';

    angular.module('app.rubricas').service('rubricasService', RubricasService);
    RubricasService.$inject = ['Rubricas', 'STATUS_ENUM'];

    function RubricasService(Rubricas, STATUS_ENUM) {

        this.filter = function(filter) {
            return Rubricas.filter(filter).$promise;            
        };

        this.get = function(id) {
            return Rubricas.get({ id: id }).$promise;
        };

        this.delete = function(rubrica) {
            return Rubricas.delete({id: rubrica.id}).$promise;
        };

        this.ativos = function() {
            return Rubricas.ativo();
        };

        this.ativosRelatorio = function() {
            return Rubricas.ativo().$promise;
        };
        
        this.byPrograma = function(idPrograma) {
            return Rubricas.byProgramaWithPermissionFilter({idPrograma: idPrograma});
        };

        this.porIdEscola = function(idEscola) {
            return Rubricas.porIdEscola({idEscola: idEscola});
        };

        this.findRubricasByTurma = function(idTurma) {
            return Rubricas.findRubricasByTurma({idTurma: idTurma});
        };

        this.save = function(rubrica) {
            var entity = new Rubricas(rubrica);
            
            if (entity.id) {
                return entity.$update();
            } else {
                return entity.$save();
            }
        };

    }

}());
