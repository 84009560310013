/*
jslint
    devel: true, maxerr: 10
*/

/*
global
    required: true
*/

/*
 * Selection Criteria Register Controller
 * @module app.criterioSelecao (Selection Criteria Module
 * @author iFactory Solutions Ltda.
 * @version 1.0.0
 * @since 1.0.0
 */
(function() {
    'use strict';

    angular.module('app.criterioSelecao').controller('CriterioSelecaoFormController', CriterioSelecaoFormController);
    CriterioSelecaoFormController.$inject = ['APP_STATE', 'TABLE_FIELDS', 'APP_EVENT', 'COMMON_STATUS_LIST',
        '$state', '$scope', 'CriterioSelecaoService', 'dialogService', 'toastService'
    ];

    function CriterioSelecaoFormController(APP_STATE, TABLE_FIELDS, APP_EVENT, COMMON_STATUS_LIST,
        $state, $scope, criterioSelecaoService, dialogService, toastService) {

        var vm = this;

        vm.goBack = function() {
            $state.go(APP_STATE.CRITERIO_SELECAO.SEARCH);
        };

        vm.save = function() {
            if (vm.registerForm.$valid) {
                criterioSelecaoService.save(vm.record).then(function(entity) {
                    $scope.$emit(APP_EVENT.CRITERIO_SELECAO.SAVED);
                    vm.goBack();
                }, function(response) {
                    toastService.error({
                        status: response.status,
                        message: response.data.message
                    });
                });
            }
        };

        vm.enableEditMode = function() {
            vm.editMode = true;
        };

        vm.cancel = function() {
            if (vm.refreshSearch) {
                $scope.$emit(APP_EVENT.CRITERIO_SELECAO.REFRESH);
            }

            vm.goBack();
        };

        (function() {
            vm.editMode = false;
            vm.editPage = false;
            vm.defaultStatus = COMMON_STATUS_LIST[0];

            if ($state.is(APP_STATE.CRITERIO_SELECAO.EDIT)) {
                vm.editPage = true;

                if ($state.params.id) {
                    criterioSelecaoService.get($state.params.id).then(function(entity) {
                        vm.record = entity;
                    }, function(response) {
                        toastService.error({
                            status: response.status,
                            message: response.data.message
                        });
                    });
                } else {
                    vm.goBack();
                }
            } else {
                vm.enableEditMode();
                vm.record = {};
                vm.record.situacao = vm.defaultStatus;
            }
        }());
    }

}());