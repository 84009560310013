(function(){
    'use strict';

    angular.module('app.contrato').service('contratoService', ContratoService);
    ContratoService.$inject = ['Contrato', 'STATUS_ENUM', '$q', 'Uf', 'Municipios', 'Programas'];

    function ContratoService(Contrato, STATUS_ENUM, $q, uf, municipio, programa) {

        this.filter = function(filter) {
            return Contrato.filter(filter).$promise;            
        };

        this.findProgramas = function() {
            return _findByType("programas");
        };

        var _findByType = function(type) {
            if (self[type].length > 0) {
                return $q.resolve(self[type]);
            } else {
                var deferred = $q.defer();

                Contrato.findByType({type: type}).$promise.then(function(data) {
                        self[type] = _.map(data, function(item) {
                            return {
                                key: item.id,
                                value: item.name
                            };
                        });

                        deferred.resolve(self[type]);
                    }, function(response) {
                        deferred.reject(response);
                    });

                return deferred.promise;
            }
        };

        this.get = function(id) {
            return Contrato.get({ id: id }).$promise;
        };

        var _setListasAlteradas = function(contrato) {
            var contratoProgramaEscolaAlterados = _.filter(contrato.contratoProgramaEscola, function(item) {
                return item._updated && item.id;
            });

            if (is.not.empty(contratoProgramaEscolaAlterados)) {
                contrato.contratoProgramaEscolaAlterados = contratoProgramaEscolaAlterados;
            }
        };

        var _setContratoProgramaEscolaIncluidos = function(contrato) {
            var programaEscolaIncluidos = _.filter(contrato.contratoProgramaEscola, function(item) {
                return is.not.existy(item.id);
            });

            contrato.contratoProgramaEscolaIncluidos = programaEscolaIncluidos;
        };

        this.save = function(contrato, dataInicio, dataFim) {
            _setListasAlteradas(contrato);
            _setContratoProgramaEscolaIncluidos(contrato);

            var entity = new Contrato(contrato);

            if (entity.escopo.key == 'E') {
                delete entity.municipio;
            } else {
                delete entity.uf;
            }

            entity.dataInicio = dataInicio;
            entity.dataFim = dataFim;

            if (entity.id) {
                return entity.$update();
            } else {
                return entity.$save();
            }
        };

        this.delete = function(contrato) {
            return Contrato.delete({id: contrato.id}).$promise;
        };

        this.findUfs = function(idPeriodoLetivo, idPrograma) {
            return Contrato.findUfs({periodo_letivo:idPeriodoLetivo, programa:idPrograma});    
        };

        this.findMunicipios = function(idPeriodoLetivo, idPrograma, idUf) {
            return Contrato.findMunicipios({periodo_letivo:idPeriodoLetivo, programa: idPrograma, uf: idUf});
        }

        this.findEscolas = function(idPeriodoLetivo, idPrograma, idMunicipio) {
            return Contrato.findEscolas({periodo_letivo:idPeriodoLetivo, programa: idPrograma, municipio: idMunicipio});
        },

        this.findActives = function() {
            return Contrato.findByStatus({status: STATUS_ENUM.ATIVO}).$promise;
        };

        this.findProgramas = function() {
            return Contrato.findProgramas().$promise;
        };

        this.findEscolasAssociadas = function(id) {
            return Contrato.findEscolasAssociadas({id: id}).$promise;
        }

        this.findProgramasCriterios = function(id) {
            return Contrato.findProgramasCriterios({id: id}).$promise;
        }

        this.findMunicipiosAtivos = function(id) {
            return municipio.porUf({idUf: id}).$promise;
        }

        this.findProgramasAtivos = function() {
            return programa.ativo().$promise;
        }

        this.findProgramasAssociados = function() {
            return Contrato.findProgramasAssociados({id: id}).$promise;
        }

        this.findUfsAtivas = function() {
            return uf.ativo().$promise;
        }

        this.findEscopos = function() {
            var deferred = $q.defer();

            Contrato.findEscopos().$promise.then(function(escopoList) {
                var escopos = _.map(escopoList, function(escopo) {
                    return {
                        key: escopo.id,
                        value: escopo.name
                    };
                });

                deferred.resolve(escopos);
            }, function(error) {
                deferred.reject(error);
            });

            return deferred.promise;
        };

        this.findContratoProgramaEscolas = function(contratoId) {
            return Contrato.findContratoProgramaEscolas({id: contratoId}).$promise;
        };

        this.findContratoProgramaEscolasOtimizado = function(contratoId) {
            return Contrato.findContratoProgramaEscolasOtimizado({id: contratoId}).$promise;
        };

        this.transformToContratoProgramaEscola = function(contratoId, escolas, escolaAssociada) {
            var adesao = escolaAssociada.adesao;
            var periodoLetivo = escolaAssociada.periodoLetivo;
            var programa = escolaAssociada.programa;

            var transformed = _.map(escolas, function(escola) {
                return {
                    contrato: {
                        id: contratoId
                    },
                    escola: {
                        id: escola.id,
                        nome: escola.nome,
                        codigoINEP: escola.codigoINEP
                    },
                    adesao: adesao,
                    periodoLetivo: {
                        id: periodoLetivo.id,
                        anoLetivo: periodoLetivo.anoLetivo
                    },
                    programa: {
                        id: programa.id,
                        nome: programa.nome
                    },
                    dataInicio: escolaAssociada.dataInicio,
                    dataFim: escolaAssociada.dataFim
                };
            });

            return transformed;
        };

        this.contratosProgramasToProgramas = function(contratosProgramas) {
            return _.map(contratosProgramas, function(contratoPrograma) {
                return contratoPrograma.programa;
            });
        };

        this.findCriteriosAdesao = function() {
            return $q(function(resolve, reject) {
                Contrato.findCriteriosAdesao().$promise.then(function(criterios) {
                    var transformed = _.map(criterios, function(criterio) {
                        return {
                            key: criterio.id,
                            value: criterio.name
                        };
                    });
                    
                    resolve(transformed);
                }, function(error) {
                    reject(error);
                });
            });
        };

        this.formatDateToServer = function(data) {
            if (is.existy(data) && is.not.empty(data)) {
                return moment(data, 'DD/MM/YYYY').format('YYYY-MM-DD');
            } else {
                return null;
            }
        };

        this.formatDateToClient = function(data) {
            if (is.existy(data) && is.not.empty(data)) {
                return moment(data, 'YYYY-MM-DD').format('DD/MM/YYYY');
            } else {
                return null;
            }
        };

    }

}());
