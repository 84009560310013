(function(){
    'use strict';

    angular.module('app.municipios').service('municipiosService', MunicipiosService);
    MunicipiosService.$inject = ['Municipios', 'STATUS_ENUM'];

    function MunicipiosService(Municipios, STATUS_ENUM) {

        this.filter = function(filter) {
            return Municipios.filter(filter).$promise;            
        };

        this.get = function(id) {
            return Municipios.get({ id: id }).$promise;
        };

        this.save = function(municipio) {
            var entity = new Municipios(municipio);
            
            if (entity.id) {
                return entity.$update();
            } else {
                return entity.$save();
            }
        };

        this.delete = function(programa) {
            return Municipios.delete({id: programa.id}).$promise;
        };

        this.ativos = function() {
            return Municipios.ativo();
        };
        
        this.porRegionalUf = function(idRegionalUf) {
            return Municipios.porRegionalUf({idRegionalUf: idRegionalUf});
        };
        
        this.porUf = function(idUf) {
            return Municipios.porUf({idUf: idUf});
        };
        
        this.withPermissionFilter = function(params) {
            return Municipios.withPermissionFilter(params).$promise;
        };
        
        this.regionais = function(id) {
            return Municipios.regionais({id: id});
        };

    }

}());
