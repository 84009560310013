(function() {
    'use strict';

    angular.module('app.login').controller('ResetPasswordController', ResetPasswordController);
    ResetPasswordController.$inject = ['$state', '$timeout', '$location', 'Usuario', 'utilService'];

    function ResetPasswordController($state, $timeout, $location, userResource, utilService) {
        var vm = this;
        vm.logoImages = ["contents/images/logo_IAS_1.jpg", "contents/images/logo_IAS_2.jpg", "contents/images/logo_IAS_3.jpg", "contents/images/logo_IAS_4.jpg"];
        vm.user = {};
        vm.login = "";
        vm.success = false;
        vm.errorMsg = "";

        var TYPE_ERROR = {
            BLANK: 'blank',
            SPACE: 'space',
            DIFF: 'diff',
            MINLENGTH: 'minlength',
            SERVER: 'server'
        };

        var REDIRECT_TIMEOUT = 6000;

        var _setErrorMsg = function(type, error) {
            switch (type) {
                case TYPE_ERROR.BLANK:
                    vm.errorMsg = 'Os campos de senha devem ser preenchidos.';
                    break;
                case TYPE_ERROR.SPACE:
                    vm.errorMsg = 'Os campos de senha não podem conter apenas espaços em branco.';
                    break;
                case TYPE_ERROR.DIFF:
                    vm.errorMsg = 'As senhas informadas não são iguais.';
                    break;
                case TYPE_ERROR.MINLENGTH:
                    vm.errorMsg = 'As senhas informadas devem ter no mínimo 6 digitos.';
                    break;
                default:
                    vm.errorMsg = utilService.extractErrorMsg(error);
            }
        };

        var _validateForm = function() {
            vm.errorMsg = "";
            vm.success = false;
            _setCustomValidity(true);

            var _newPasswordError = vm.resetPassForm.newPassword.$error;
            var _newPasswordConfirmedError = vm.resetPassForm.newPasswordConfirmed.$error;

            var _requiredError = _newPasswordError.required || _newPasswordConfirmedError.required;
            var _minlengthError = _newPasswordError.minlength || _newPasswordConfirmedError.minlength;

            if (_requiredError) {
                _setErrorMsg(TYPE_ERROR.BLANK);
            } else if (_minlengthError) {
                _setErrorMsg(TYPE_ERROR.MINLENGTH);
            } else {
                var _newPassword = vm.user.newPassword.trim();
                var _newPasswordConfirmed = vm.user.newPasswordConfirmed.trim();

                if (is.empty(_newPassword) || is.empty(_newPasswordConfirmed)) {
                    _setCustomValidity(false);
                    _setErrorMsg(TYPE_ERROR.SPACE);
                } else if (vm.user.newPassword !== vm.user.newPasswordConfirmed) {
                    _setCustomValidity(false);
                    _setErrorMsg(TYPE_ERROR.DIFF);
                } else {
                    return true;
                }
            }

            return false;
        };

        var _setCustomValidity = function(isValid) {
            vm.resetPassForm.newPassword.$setValidity("validPassword", isValid);
            vm.resetPassForm.newPasswordConfirmed.$setValidity("validPassword", isValid);
        };

        vm.resetForm = function() {
            vm.user = {};
            vm.resetPassForm.$setPristine();
        };

        vm.submitForm = function() {
            if (!_validateForm()) return;

            userResource.changePassword({ id: $state.params.id }, vm.user.newPassword,
                function(result) {
                    vm.success = true;
                    vm.resetForm();

                    $timeout(function() {
                        $location.url('/');
                    }, REDIRECT_TIMEOUT);
                },
                function(error) {
                    _setErrorMsg(TYPE_ERROR.SERVER, error);
                }
            );
        };

        (function() {
            userResource.findByToken({ id: $state.params.id }).$promise
                .then(function(usuario) {
                    vm.login = usuario.login;
                })
                .catch(function(error) {
                    vm.searchError = error;
                });
        })();

    }

}());