/*
jslint
    devel: true, maxerr: 10
*/

/*
global
    required: true
*/

/*
 * Relatórios Module
 * @module constants for class profile report
 * @author iFactory Solutions Ltda.
 * @version 1.0.0
 * @since 1.0.0
 */
(function() {
    'use strict';

    angular.module('app.relatorios').constant('OUTROS_GENERO_SITUACAO_ANO_ANTERIOR_FIELDS', [

        { name: 'etapa', type: 'string', calculateTotal: false, showFooter: false  },
        { name: 'alunosMatriculadosTotal', type: 'integer', calculateTotal: true, calculateType: 'sum'  },
        { name: 'alunosSexoMasculino', type: 'integer', calculateTotal: true, calculateType: 'sum'  },
        { name: 'alunosSexoMasculinoPercentual', type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average', referenceField: 'alunosSexoMasculino', referenceTotalField: 'alunosMatriculadosTotal' },
        { name: 'alunosSexoFeminino', type: 'integer', calculateTotal: true, calculateType: 'sum'  },
        { name: 'alunosSexoFemininoPercentual', type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average', referenceField: 'alunosSexoFeminino', referenceTotalField: 'alunosMatriculadosTotal' },
        { name: 'situacaoAnoAnteriorAlunosAprovados', type: 'integer', calculateTotal: true, calculateType: 'sum'  },
        { name: 'situacaoAnoAnteriorAlunosAprovadosPercentual', type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average', referenceField: 'situacaoAnoAnteriorAlunosAprovados', referenceTotalField: 'alunosMatriculadosTotal' },
        { name: 'situacaoDoAnoAnteriorAlunosReprovados', type: 'integer', calculateTotal: true, calculateType: 'sum'  },
        { name: 'situacaoDoAnoAnteriorAlunosReprovadosPercentual', type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average', referenceField: 'situacaoDoAnoAnteriorAlunosReprovados', referenceTotalField: 'alunosMatriculadosTotal' },
        { name: 'situacaoDoAnoAnteriorAlunosNaoFreq', type: 'integer', calculateTotal: true, calculateType: 'sum'  },
        { name: 'situacaoDoAnoAnteriorAlunosNaoFreqPercentual', type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average', referenceField: 'situacaoDoAnoAnteriorAlunosNaoFreq', referenceTotalField: 'alunosMatriculadosTotal' },

    ]);

    /* Outros - Perfil Atendimento Município */
    angular.module('app.relatorios').constant('OUTROS_PERFIL_ATENDIMENTO_MUNICIPIO_FIELDS', [
        { name: 'id', type: 'integer', calculateTotal: false, hide: true, showFooter: false },
        { name: 'localizacao', type: 'string', calculateTotal: false, showFooter: false  },
        { name: 'totalEscolas', type: 'integer', calculateTotal: true, calculateType: 'sum'  },
        { name: 'total8Iniciais', type: 'integer', calculateTotal: true, calculateType: 'sum'  },
        { name: 'percentual8Iniciais', type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average', referenceField: 'total8Iniciais', referenceTotalField: 'totalEscolas' },
        { name: 'total8Finais', type: 'integer', calculateTotal: true, calculateType: 'sum'  },
        { name: 'percentual8Finais', type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average', referenceField: 'total8Finais', referenceTotalField: 'totalEscolas'  },
        { name: 'total8Anos', type: 'integer', calculateTotal: true, calculateType: 'sum'  },
        { name: 'percentual8Anos', type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average', referenceField: 'total8Anos', referenceTotalField: 'totalEscolas' },
        { name: 'total9Iniciais', type: 'integer', calculateTotal: true, calculateType: 'sum'  },
        { name: 'percentual9Inciais', type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average', referenceField: 'total9Iniciais', referenceTotalField: 'totalEscolas' },
        { name: 'total9Finais', type: 'integer', calculateTotal: true, calculateType: 'sum'  },
        { name: 'percentual9Finais', type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average', referenceField: 'total9Finais', referenceTotalField: 'totalEscolas' },
        { name: 'total9Anos', type: 'integer', calculateTotal: true, calculateType: 'sum'  },
        { name: 'percentual9Anos', type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average', referenceField: 'total9Anos', referenceTotalField: 'totalEscolas' },
        { name: 'totalAno', type: 'integer', calculateTotal: true, calculateType: 'sum'  },
        { name: 'totalCiclo', type: 'integer', calculateTotal: true, calculateType: 'sum'  },
        { name: 'totalNucleo', type: 'integer', calculateTotal: true, calculateType: 'sum'  },
        { name: 'totalNucleada', type: 'integer', calculateTotal: true, calculateType: 'sum'  }

    ]);

    /* Outros - Perfil Atendimento Município */
    angular.module('app.relatorios').constant('OUTROS_UNIDADE_ESCOLAR_PERFIL_ATENDIMENTO_FIELDS', [
        { name: 'municipio', type: 'string', calculateTotal: false, showFooter: false },
        { name: 'id', type: 'integer', calculateTotal: false, hide: true, showFooter: false },
        { name: 'codigoINEP', type: 'string', calculateTotal: false, showFooter: false },
        { name: 'escola', type: 'string', calculateTotal: false, showFooter: false },
        { name: 'situacao', type: 'string', calculateTotal: false, showFooter: false },
        { name: 'etapas', type: 'string', etapaList: true, calculateTotal: false, showFooter: false },
        { name: 'programas', type: 'string', programaList: true, calculateTotal: false, showFooter: false },
    ]);

    angular.module('app.relatorios').constant('OUTROS_NUMERO_ALUNOS_POR_ANO_SITUACAO_FIELDS', [
        { name: 'nome', type: 'string', calculateTotal: false, showFooter: false  },
        { name: 'total', type: 'integer', calculateTotal: true, calculateType: 'sum'  },
        { name: 'alfabetizados', type: 'integer', calculateTotal: true, calculateType: 'sum'  },
        { name: 'alfabetizadosPercentual', type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average', referenceField: 'alfabetizados', referenceTotalField: 'total' },
        { name: 'naoAlfabetizados', type: 'integer', calculateTotal: true, calculateType: 'sum'  },
        { name: 'naoAlfabetizadosPercentual', type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average', referenceField: 'naoAlfabetizados', referenceTotalField: 'total' },
    ]);

    /* Outros - Matricula ensino fundamental rede */
    angular.module('app.relatorios').constant('OUTROS_MATRICULA_ENSINO_FUNDAMENTAL_REDE_FIELDS', [
        { name: 'serieMunicipal', type: 'string', calculateTotal: false, showFooter: true  },
        { name: 'matriculaMunicipal', type: 'integer', calculateTotal: true, calculateType: 'sum'},
        { name: 'serieEstadual', type: 'string', calculateTotal: false, showFooter: true  },
        { name: 'matriculaEstadual', type: 'integer', calculateTotal: true, calculateType: 'sum'  },
        { name: 'serieFederal', type: 'string', calculateTotal: false, showFooter: true  },
        { name: 'matriculaFederal', type: 'integer', calculateTotal: true, calculateType: 'sum'  },
        { name: 'serieParticular', type: 'string', calculateTotal: false, showFooter: true  },
        { name: 'matriculaParticular', type: 'integer', calculateTotal: true, calculateType: 'sum'  },
        { name: 'serieTotal', type: 'string', calculateTotal: false, showFooter: true  },
        { name: 'matriculaTotal', type: 'integer', calculateTotal: true, calculateType: 'sum'  },
    ]);


    angular.module('app.relatorios').constant('OUTROS_ESCOLAS_EDUCACAO_INFANTIL_JOVENS_ADULTOS_FIELDS', [
        { name: 'detalhamento', type: 'string', calculateTotal: false, showFooter: true  },
        { name: 'totalCreche', type: 'integer', calculateTotal: true, calculateType: 'sum'},
        { name: 'totalPreEscola', type: 'interger', calculateTotal: false, showFooter: 'sum' },
        { name: 'totalEFPresencial', type: 'integer', calculateTotal: true, calculateType: 'sum'  },
        { name: 'totalEFSemiPresencial', type: 'interger', calculateTotal: false, showFooter: 'sum'  },
    ]);

    angular.module('app.relatorios').constant('OUTROS_STATUS_INSERCAO_COLETAS_FIELDS', [
        { name: 'escola', type: 'string', calculateTotal: false, showFooter: false  },
        { name: 'totalTurmas', type: 'integer', calculateTotal: true, calculateType: 'sum'},
        { name: 'coletadas', type: 'integer', calculateTotal: true, showFooter: 'sum' },
        { name: 'naoColetadas', type: 'integer', calculateTotal: true, calculateType: 'sum'  },
    ]);

    angular.module('app.relatorios').constant('OUTROS_SISTEMATICA_ACOMPANHAMENTO_VISITAS_REGIONAL', [
        { name: 'escola', type: 'string', calculateTotal: false, showFooter: false  },
        { name: 'totalTurmas', type: 'integer', calculateTotal: true, calculateType: 'sum'},
        { name: 'coletadas', type: 'integer', calculateTotal: true, showFooter: 'sum' },
        { name: 'naoColetadas', type: 'integer', calculateTotal: true, calculateType: 'sum'  },
    ]);

    angular.module('app.relatorios').constant('OUTROS_SISTEMATICA_MONITORAMENTO_REGIONAL', [
        { name: 'escola', type: 'string', calculateTotal: false, showFooter: false  },
        { name: 'totalTurmas', type: 'integer', calculateTotal: true, calculateType: 'sum'},
        { name: 'coletadas', type: 'integer', calculateTotal: true, showFooter: 'sum' },
        { name: 'naoColetadas', type: 'integer', calculateTotal: true, calculateType: 'sum'  },
    ]);

    angular.module('app.relatorios').constant('OUTROS_SISTEMATICA_ACOMPANHAMENTO_VISITAS_CENTRAL', [
        { name: 'escola', type: 'string', calculateTotal: false, showFooter: false  },
        { name: 'totalTurmas', type: 'integer', calculateTotal: true, calculateType: 'sum'},
        { name: 'coletadas', type: 'integer', calculateTotal: true, showFooter: 'sum' },
        { name: 'naoColetadas', type: 'integer', calculateTotal: true, calculateType: 'sum'  },
    ]);

    angular.module('app.relatorios').constant('OUTROS_SISTEMATICA_MONITORAMENTO_CENTRAL', [
        { name: 'escola', type: 'string', calculateTotal: false, showFooter: false  },
        { name: 'totalTurmas', type: 'integer', calculateTotal: true, calculateType: 'sum'},
        { name: 'coletadas', type: 'integer', calculateTotal: true, showFooter: 'sum' },
        { name: 'naoColetadas', type: 'integer', calculateTotal: true, calculateType: 'sum'  },
    ]);
}());
