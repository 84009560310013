(function () {
	'use strict';

	angular.module('app.escolas').controller('EscolasFormController', EscolasFormController);
	EscolasFormController.$inject = ['SEARCH_BY_FIELD', 'APP_STATE', 'TABLE_FIELDS', 'APP_EVENT', 'COMMON_STATUS_LIST',
		'$state', '$scope', 'dialogService', 'toastService', 'Flash', 'ufService', 'municipiosService',
		'localizacaoService', 'dependenciaAdministrativaService', 'DialogService', 'atributoService',
		'escolasService', '$filter', 'periodoLetivoService', 'programaExternoService', 'etapaService',
		'modalidadeEnsinoService', 'programasService', 'scrollService', 'TIPO_AGRUPAMENTO_RESULTADO', 
		'TIPO_REGIME_ENSINO', 'TIPO_CALENDARIO', 'VALIDATION_PATTERN', 'SecurityManagerFactory'];

	function EscolasFormController(SEARCH_BY_FIELD, APP_STATE, TABLE_FIELDS, APP_EVENT, COMMON_STATUS_LIST,
		$state, $scope, dialogService, toastService, Flash, ufService, municipiosService,
		localizacaoService, dependenciaAdministrativaService, DialogService, atributoService,
		escolasService, $filter, periodoLetivoService, programaExternoService, etapaService,
		modalidadeEnsinoService, programasService, scrollService, TIPO_AGRUPAMENTO_RESULTADO, 
		TIPO_REGIME_ENSINO, TIPO_CALENDARIO, VALIDATION_PATTERN, SecurityManagerFactory) {

		var vm = this;

		vm.ufs = ufService.ativos();
		vm.localizacoes = localizacaoService.ativos();
		vm.dependenciasAdministrativas = dependenciaAdministrativaService.ativos();
		vm.modalidadesEnsinos = modalidadeEnsinoService.ativos();
		vm.programasExternos = programaExternoService.ativos();
		vm.municipios = [];
		vm.escolaProgramasExternos = [];
		vm.escolaProgramasExternosIncluidos = [];
		vm.escolaProgramasExternosExcluidos = [];
		vm.programaNomeInvalido = false;
		vm.escolasAssociadas = [];
		vm.escolasAssociadasExcluidas = [];
		vm.etapasList = etapaService.ativos();
		vm.etapas = [];
		vm.etapasExcluidas = [];
		vm.calendarios = [];
		vm.calendariosExcluidos = [];
		vm.programas = programasService.ativos();
		vm.atributos = atributoService.ativos();
		vm.tipoAgrupamentoResultados = TIPO_AGRUPAMENTO_RESULTADO;
		vm.regimesEnsino = TIPO_REGIME_ENSINO;
		vm.tiposCalendario = TIPO_CALENDARIO;	
		vm.tiposOrganizacao = [];
		vm.tiposCalendarios = escolasService.tiposCalendarios();
		vm.showMsnMudancaTipo = false;
		vm.periodosLetivos = periodoLetivoService.ativos();
		vm.escolaPeriodicidades = [];
		vm.escolaPeriodicidadesIncluidos = [];
		vm.escolaPeriodicidadesExcluidos = [];
		vm.escolaPeriodicidadesAlterados = [];
		vm.modalPeriodicidadesWasOpened = false;
		vm.VALIDATION_PATTERN = VALIDATION_PATTERN;

		vm.modalAtributos = function() {
			DialogService.modal(
                'escolas/partials/escolas-form-atributo.html',
                function($uibModalInstance) {
                    var _vm = this;
					_vm.status = { open: false };
					_vm.programas = vm.programas;
					_vm.escolasAtributosClear = false;
					_vm.escolaAtributosFields = TABLE_FIELDS.ESCOLA_ATRIBUTOS;
					_vm.escolasAtributos = vm.escola.atributosEntidadesDTO;
					_vm.escolasAtributosExcluidos = vm.escola.atributosEntidadesExcluidosDTO;

					var _clearAll = function() {
                        _vm.escolasAtributosClear = true;
						_clearAtributosEscolasForm();
                    };

					var _clearAtributosEscolasForm = function() {
						_vm.atributo = {};
						_vm.status.open = false;
						_vm.editMode = false;
					};

					_vm.populateAtributos = function(idPrograma){
						atributoService.porTipoEntidadePrograma('ESCOLA', idPrograma).then(function(atributos) {
							_vm.atributos = atributos;
							_vm.atributos.$promise.then(function(data){
                            	if (is.not.existy(_.findWhere(data, {id: _vm.atributo.atributo.id}))){
                                	_vm.atributos.push(_vm.atributo.atributo);
                                	_vm.atributos = _.sortBy(_vm.atributos, 'nome');
								}
							});
						});
					}

					var _verificarExisteAtributo = function(atributo) {
						var atributos;
						if (_vm.editMode) {
							atributos = $filter('filter')(_vm.escolasAtributos, function(value) {
								return (value.atributo.id == atributo.atributo.id) && (_vm.escolasAtributosSelecionados[0].atributo.id != atributo.atributo.id) &&
								       (value.programa.id == atributo.programa.id) && (_vm.escolasAtributosSelecionados[0].programa.id != atributo.programa.id);
							});
						} else {
							atributos = $filter('filter')(_vm.escolasAtributos, function(value) {
								return (value.atributo.id == atributo.atributo.id && value.programa.id == atributo.programa.id);
							});
						}

						return (atributos.length > 0);
					};

					_vm.escolasAtributosChange = function(selectedItems) {
						_clearAtributosEscolasForm();
                    };

					_vm.save = function(atributo) {
						if (_vm.atributoForm.$valid) {
							if (!_verificarExisteAtributo(atributo)) {
								if (_vm.editMode) {
									 _vm.escolasAtributosSelecionados[0].atributo = atributo.atributo;
									 _vm.escolasAtributosSelecionados[0].dados = atributo.dados;
									 _vm.escolasAtributosSelecionados[0].programa = atributo.programa;
									 
									 if (atributo.id)
                                    	_vm.escolasAtributosSelecionados[0]._updated = true;
								} else {
									_vm.escolasAtributos.push(angular.copy(atributo));
								}

								_clearAll();
							} else {
								toastService.error("O atributo já foi adicionado.", 'flash-modal');
								scrollService.scrollTo("esc-form-modal");
							}
						}
					};

					_vm.setAtributo = function(atributo, notModifyData) {
						
						if (atributo) {
							
							if (is.not.existy(notModifyData) || is.falsy(notModifyData)) {
								_vm.atributo.dados = escolasService.removeOldValuesFromAtributo(_vm.atributo.dados);
							}
							
							atributo.tipo = atributo.tipoAtributo;

							if (undefined !== atributo.idOpcao) {
								atributo.opcao = atributo.idOpcao;
							}
							if (undefined !== atributo.idMedida) {
								atributo.medida = atributo.idMedida;
							}
						} else {
							_vm.atributo = {};
						}
					};

					_vm.showFormAssociar = function(item) {                        
                        if (is.existy(item)) {
							_vm.editMode = true;
							_vm.atributo = angular.copy(item);
							_vm.programas.$promise.then(function(data){
                            	if (is.not.existy(_.findWhere(data, {id: _vm.atributo.programa.id}))){
                                	_vm.programas.push(_vm.atributo.programa);
                                	_vm.programas = _.sortBy(_vm.programas, 'nome');
								}
							});
							_vm.populateAtributos(_vm.atributo.programa.id);
							_vm.setAtributo(_vm.atributo.atributo, true);
                        }

						_vm.status.open = true;
                    };

					_vm.close = function () {
                        _clearAll();
                        $uibModalInstance.dismiss('cancel');
                    };

                    _vm.cancel = function () {
                        _clearAll();
                    };

					_clearAll();
                });	
		};

		var _meses = function() {
			return [
					{referencia: '1', mes: 'Janeiro', numeroDiasLetivos: null, situacao: {key:"A"}},
					{referencia: '2', mes: 'Fevereiro', numeroDiasLetivos: null, situacao: {key:"A"}},
					{referencia: '3', mes: 'Março', numeroDiasLetivos: null, situacao: {key:"A"}},
					{referencia: '4', mes: 'Abril', numeroDiasLetivos: null, situacao: {key:"A"}},
					{referencia: '5', mes: 'Maio', numeroDiasLetivos: null, situacao: {key:"A"}},
					{referencia: '6', mes: 'Junho', numeroDiasLetivos: null, situacao: {key:"A"}},
					{referencia: '7', mes: 'Julho', numeroDiasLetivos: null, situacao: {key:"A"}},
					{referencia: '8', mes: 'Agosto', numeroDiasLetivos: null, situacao: {key:"A"}},
					{referencia: '9', mes: 'Setembro', numeroDiasLetivos: null, situacao: {key:"A"}},
					{referencia: '10', mes: 'Outubro', numeroDiasLetivos: null, situacao: {key:"A"}},
					{referencia: '11', mes: 'Novembro', numeroDiasLetivos: null, situacao: {key:"A"}},
					{referencia: '12', mes: 'Dezembro', numeroDiasLetivos: null, situacao: {key:"A"}}
			];
		}

		vm.cancel = function() {
            if (vm.refreshSearch) {
                $scope.$emit(APP_EVENT.ESCOLA.REFRESH);
            }

            vm.goBack();
        };
		
		vm.modalCalendario = function() {
			DialogService.modal(
                'escolas/partials/escolas-form-calendario.html',
                function($uibModalInstance){
                    var _vm = this;
					_vm.calendarios = vm.calendarios;
					_vm.calendario = {
						meses: _meses()
					};
					
					_vm.totalDiasValue = 0;
					_vm.periodosLetivos = periodoLetivoService.ativos();
					_vm.tiposCalendarios = escolasService.tiposCalendarios();

					var _existeCalendario = function(calendario) {
						var calendarios;

						if (calendario.edit) {
							calendarios = $filter('filter')(vm.calendarios, function(value) {
								var valor1 = value.nome;
								var valor2 = calendario.nome;
								return valor1.toLowerCase() == valor2.toLowerCase() && (calendario.key != value.key);
							});
						} else {
							calendarios = $filter('filter')(vm.calendarios, function(value) {
								var valor1 = value.nome;
								var valor2 = calendario.nome;
								return valor1.toLowerCase() == valor2.toLowerCase();
							});
						};

						return (calendarios.length > 0);
					};

					var _existeCalendarioGeralAnoLetivo = function(calendario){
						var calendarios;

						//Só deverá prosseguir com as próximas validações se o tipo de calendário for Geral da Escola
						if(calendario.tipo.key.toUpperCase() != "E"){
							return false;
						}

						calendarios = $filter('filter')(vm.calendarios, function(value){
							var periodoId1 = value.periodoLetivo.id
							var periodoId2 = calendario.periodoLetivo.id

							return periodoId1 == periodoId2 && value.tipo.key == "E" && !value.edit;
						});

						return (calendarios.length > 0);
					};

					_vm.totalCalendariosSelecionadas = function() {
						var totalSelecionados = $filter('filter')(vm.calendarios, function(value) {
							return value.selected == true;
						});

						return totalSelecionados.length;
					};

					_vm.selecionarTodos = function() {
						if (_vm.selectedAll == true) {
							angular.forEach(_vm.calendarios, function(value) {
								value.selected = true;
							});		
						} else {
							angular.forEach(_vm.calendarios, function(value) {
								value.selected = false;
							});		
						}
					};

					var _validarCalendarios = function() {
						var valido = true;

						angular.forEach(_vm.calendarios, function(value) {
							if (value.tipo == 'E') {
								valido = false;
							}
						});

						return valido;	
					};

					var _getTotalDias = function() {
						var total = 0;

						angular.forEach(_vm.calendario.meses, function(value) {
							if (is.existy(value.numeroDiasLetivos) && is.not.empty(value.numeroDiasLetivos)) {
								total += parseInt(value.numeroDiasLetivos);
							}
						});

						return total;
					};
					
					var _clearCalendarioForm = function() {
						delete _vm.calendario;
								
						_vm.calendario = {
							meses: _meses()
						};

						_vm.totalDiasValue = 0;
						_vm.calendarioForm.$setUntouched();
						_vm.calendarioForm.$submitted = false;
						_vm.calendarioForm.$invalid = false; 
					};

					var _validarDias = function(calendario) {
						var retorno = true;

						angular.forEach(_vm.calendario.meses, function(value, index) {
							var mes = index + 1;
							if (!is.null(value.numeroDiasLetivos) && !is.empty(value.numeroDiasLetivos)) {
								var dias = parseInt(value.numeroDiasLetivos);
								
								if (mes == 1 && dias > 31) {
									toastService.error("Janeiro não pode ter mais que 31 dias.", 'flash-modal');
									retorno = false;
								} else if (mes == 2 && dias > 30) {
									toastService.error("Fevereiro não pode ter mais que 30 dias.", 'flash-modal');
									retorno = false;
								} else if (mes == 3 && dias > 31) {
									toastService.error("Março não pode ter mais que 31 dias.", 'flash-modal');
									retorno = false;
								} else if (mes == 4 && dias > 30) {
									toastService.error("Abril não pode ter mais que 30 dias.", 'flash-modal');
									retorno = false;
								} else if (mes == 5 && dias > 31) {
									toastService.error("Maio não pode ter mais que 31 dias.", 'flash-modal');
									retorno = false;
								} else if (mes == 6 && dias > 30) {
									toastService.error("Junho não pode ter mais que 30 dias.", 'flash-modal');
									retorno = false;
								} else if (mes == 7 && dias > 31) {
									toastService.error("Julho não pode ter mais que 31 dias.", 'flash-modal');
									retorno = false;
								} else if (mes == 8 && dias > 31) {
									toastService.error("Agosto não pode ter mais que 31 dias.", 'flash-modal');
									retorno = false;
								} else if (mes == 9 && dias > 30) {
									toastService.error("Setembro não pode ter mais que 30 dias.", 'flash-modal');
									retorno = false;
								} else if (mes == 10 && dias > 31) {
									toastService.error("Outubro não pode ter mais que 31 dias.", 'flash-modal');
									retorno = false;
								} else if (mes == 11 && dias > 30) {
									toastService.error("Novembro não pode ter mais que 30 dias.", 'flash-modal');
									retorno = false;
								} else if (mes == 12 && dias > 31) {
									toastService.error("Dezembro não pode ter mais que 31 dias.", 'flash-modal');
									retorno = false;
								}	
							}
						});

						return retorno;						
					};

					_vm.add = function(calendario) {
						calendario.situacao = { key:"A", value:"Ativo" };
						calendario.versao = 1;
						calendario.versaoRecente = 2;

						if (_vm.calendarioForm.$valid) {
							if (!_validarCalendarios() && calendario.tipo == 'E') {
								toastService.error("Você deve adcionar somente um calendário do tipo geral da escola.", 'flash-modal');
								return true;
							}
							
							if (!_validarDias()) {
								return true;
							}

							if(!_existeCalendarioGeralAnoLetivo(calendario)){
								if (!_existeCalendario(calendario)) {
									var meses = $filter('filter')(_vm.calendario.meses, function(value){
										var valor = parseInt(value.numeroDiasLetivos);
										return (valor > 0) || is.existy(value.id);
									});

									_vm.calendario.meses = meses;
									var tipo = {key: calendario.tipo.key};
									calendario.tipo = tipo;
									if (calendario.edit) {
										calendario.edit = false;
										vm.calendarios[calendario.key-1] = calendario;
									} else {
										vm.calendarios.push(angular.copy(calendario));
									}
									
									_vm.calendarios = vm.calendarios;
									_clearCalendarioForm();

									toastService.success("O calendário foi salvo com sucesso.", 'flash-modal');
								} else {
									toastService.error("O calendário já foi adicionado.", 'flash-modal');
								}
							} else {
								toastService.error("Já existe calendário geral informado para o período letivo selecionado.", "flash-modal")
							}

							scrollService.scrollTo("esc-modal-calendario");
						}
					};

					_vm.editar = function(calendarios) {
						angular.forEach(calendarios, function(value, index) {
							if (value.selected) {
								value.key = index + 1;
								value.selected = false;
								value.edit = true;

								var novoMeses = [];

								angular.forEach(_meses(), function(mes, indexMes){
									var mesCalendario = _.find(value.meses, function(item) {
										return item.referencia == mes.referencia;
									});
									
									if (undefined !== mesCalendario) {
										mes.numeroDiasLetivos = mesCalendario.numeroDiasLetivos;
										mes.id = mesCalendario.id;
									} else {
										mes.numeroDiasLetivos = 0;
									}

									novoMeses.push(mes);
								});
								
								value.meses = novoMeses;

								if(_vm.periodosLetivos.$promise){
									_vm.periodosLetivos.$promise.then(function(data){
										if (is.not.existy(_.findWhere(data, {id: value.periodoLetivo.id}))){
											_vm.periodosLetivos.push(value.periodoLetivo);
											_vm.periodosLetivos = _.sortBy(_vm.periodosLetivos, 'anoLetivo');
										}
                        			});
								}
								_vm.calendario = angular.copy(value);
								
								return true;
							}
						});

						_vm.updateTotalDias();
					};

					_vm.excluir = function(calendarios) {
						DialogService.confirm("Você tem certeza que deseja excluir o(s) calendário(s)?", function(){
							var novosCalendarios = [];
							angular.forEach(calendarios, function(value, index) {
								if (!value.selected) {
									novosCalendarios.push(value);
								} else {
									vm.calendariosExcluidos.push(value);
								}
							});

							vm.calendarios = novosCalendarios;
							_vm.calendarios = vm.calendarios;
							_vm.selectedAll = false;

							_clearCalendarioForm();
						});
					};

					_vm.updateTotalDias = function() {
						_vm.totalDiasValue = _getTotalDias();
					};

					_vm.cancel = function() {
                        $uibModalInstance.dismiss('cancel');
                    };
                }
            );
		};

		vm.modalEtapas = function(etapa) {
			DialogService.modal(
                'escolas/partials/escolas-form-table.html',
                function($uibModalInstance) {
                    var _vm = this;
					_vm.etapasList = vm.etapasList;
					_vm.modalidadesEnsinos = vm.modalidadesEnsinos;

					if (is.existy(etapa)) {
						_vm.etapa = etapa;
					} else {
						_vm.etapa = {};

						if (is.existy(_vm.modalidadesEnsinos) && is.not.empty(_vm.modalidadesEnsinos)) {
							_vm.etapa.modalidadeEnsino = {
								id: _vm.modalidadesEnsinos[0].id
							};
						}
					}
					

					var _verificarExisteEtapas = function(etapa, key) {
						var etapas = $filter('filter')(vm.etapas, function(value) {
							var valor1 = value.etapa.id;
							var valor2 = etapa.etapa.id;

							if (key+1 != value.key && valor1 == valor2
								&& etapa.tipo.key == value.tipo.key
								&& etapa.modalidadeEnsino.id == value.modalidadeEnsino.id) {
								return true;
							}
						});

						if (etapas.length > 0) {
							return true;
						}

						return false;
					};

					_vm.salvar = function(etapa) {
						if (_vm.FormEtapa.$valid) {
							etapa.situacao = {key:"A"};

							var etapas = $filter('filter')(_vm.etapasList, function(value) {
								return value.id == etapa.etapa.id;
							});
							etapa.etapa.nome = etapas[0].nome;
							
							if (etapa.key) {
								var key = etapa.key-1;
								if (!_verificarExisteEtapas(etapa, key)) {
									
									vm.etapas[key] = etapa;
									vm.etapas[key].key = "";
									vm.etapas[key].selected = false;
									
									$uibModalInstance.dismiss('cancel');
								} else {
									toastService.error("Você já adicionou uma etapa com o mesmo nome.", 'flash-modal');
								}								
							} else {
								if (!_verificarExisteEtapas(etapa, "")) {
									etapa.key = "";
									etapa.selected = false;
									vm.etapas.push(etapa);

									$uibModalInstance.dismiss('cancel');
								} else {
									toastService.error("Você já adicionou uma etapa com o mesmo nome.", 'flash-modal');
								}	
							}
						} else {
							_vm.modalidadesInvalid = false;
						}						
					};
                    
                    _vm.cancel = function() {
                        $uibModalInstance.dismiss('cancel');
                    };
                }
            );
		};
		
		vm.modalAssociar = function() {
			if (vm.escola.tipoOrganizacao && vm.escola.tipoOrganizacao.key != '1') {
				DialogService.modal(
					'escolas/partials/escolas-form-associar.html',
					function($uibModalInstance) {
						var _vm = this;
						_vm.SEARCH_BY = SEARCH_BY_FIELD;
						_vm.searchType = _vm.SEARCH_BY.NAME;
						_vm.escolas = [];
						_vm.escolasTotal = 0;
						_vm.filterEscolaToPaginate = {};
						_vm.escolasAssociadas = vm.escolasAssociadas;
						_vm.searchWasPerformed = false;
						
						_vm.incluirEscola = function() {
							_vm.mostrarPequisaEscolas = true;
						};

						_vm.selecionarTodasEscolas = function() {
							if (_vm.todasEscolasSelecionada == true) {
								angular.forEach(_vm.escolas, function(value) {
									value.selected = true;
								});		
							} else {
								angular.forEach(_vm.escolas, function(value) {
									value.selected = false;
								});		
							}
						};
						
						_vm.selecionarTodasEscolasAssociadas = function() {
							if (_vm.todasEscolasAssociadasSelecionada == true) {
								angular.forEach(vm.escolasAssociadas, function(value) {
									value.selected = true;
								});		
							} else {
								angular.forEach(vm.escolasAssociadas, function(value) {
									value.selected = false;
								});		
							}
						};

						_vm.totalEscolasAssociadasSelecionadas = function() {
							var totalSelecionados = $filter('filter')(vm.escolasAssociadas, function(value) {
								return value.selected == true;
							});

							return totalSelecionados.length;
						};

						_vm.excluirEscolasAssociadas = function(escolas) {
							DialogService.confirm("Você tem certeza que deseja excluir as escolas associadas?", function(){
								var novasEscolas = [];
								angular.forEach(escolas, function(value, index) {
									if (!value.selected) {
										novasEscolas.push(value);
									} else {
										vm.escolasAssociadasExcluidas.push(value);	
									}
								});

								vm.escolasAssociadas = novasEscolas;
								_vm.escolasAssociadas = vm.escolasAssociadas;
								_vm.todasEscolasAssociadasSelecionada = false;
							});
						};

						var _verificarExisteEscolaAssociada = function(escola) {
							var escolas = $filter('filter')(vm.escolasAssociadas, function(value) {
								return value.id == escola.id;
							});

							if (escolas.length > 0) {
								return true;
							}

							return false;
						};

						_vm.addEscola = function(escolas) {
							var escolasAssociadas = vm.escolasAssociadas;
							var escolasSelecionadas = $filter('filter')(escolas, function(value) {
								return value.selected;
							});

							var maisUmaEscola = false;
							if (vm.escola.tipoOrganizacao.key == '3' && escolasSelecionadas.length > 1) {
								maisUmaEscola = true;
							} else if (vm.escola.tipoOrganizacao.key == '3' && escolasAssociadas.length) {
								maisUmaEscola = true;
							}

							if (maisUmaEscola) {
								toastService.clear();
								toastService.error("Você só deve associar uma escola.", 'flash-modal');
								return false;	
							}	

							angular.forEach(escolasSelecionadas, function(value) {
								value.selected = false;
								if (!_verificarExisteEscolaAssociada(value)) {
									vm.escolasAssociadas.push(angular.copy(value));
								} else {
									toastService.error("A Escola " + value.nome + " já foi associada.", 'flash-modal');
									return false;	
								}								
							});

							_vm.escolasAssociadas = vm.escolasAssociadas;
						};

						_vm.clearSearchValue = function() {
							_vm.searchValue = "";
							_vm.searchValueModel = "";
							_vm.currentPage = 1;
						};

						_vm.pesquisar = function() {
							
							if (_vm.searchValue) {
								_vm.currentPage = 1;
								_vm.searchWasPerformed = true;
								var filter = {};
								filter[_vm.searchType.field] = _vm.searchValue;

								var tipoOrganizacaoFiltro = 3;
								var associacao = true;
								if (vm.escola.tipoOrganizacao.key == '3') {
									tipoOrganizacaoFiltro = 2;
								}

								if (vm.escola.tipoOrganizacao.key == '2') {
									tipoOrganizacaoFiltro = 3;
									associacao = false;
								}

								filter["tipoOrganizacao"] = tipoOrganizacaoFiltro;
								
								filter.page = 0;
								filter.size = 10;

								_vm.filterEscolaToPaginate = angular.copy(filter);

								_findEscolasByFilters(filter);


							}
						};
						
						_vm.cancelar = function() {
							$uibModalInstance.dismiss('cancel');
						};

						_vm.onPageChangeEscolas = function (page, size) {
							_vm.filterEscolaToPaginate.page = page;
							_vm.filterEscolaToPaginate.size = size;
							_findEscolasByFilters(_vm.filterEscolaToPaginate);
						};

						var _findEscolasByFilters = function (filter) {
							escolasService.semAssociacao(filter)
								.then(function (result) {
									_vm.escolas = result.content;
									_vm.escolasTotal = result.totalElements;
								});
						};
					}
				);
			} else {
				DialogService.alert("Escola única não pode possuir associações com outras escolas.");
			};

		};

		vm.selecionarTodasEtapas = function() {
			if (vm.todasEtapasSelecionada == true) {
				angular.forEach(vm.etapas, function(value) {
					value.selected = true;
				});		
			} else {
				angular.forEach(vm.etapas, function(value) {
					value.selected = false;
				});		
			}
		};

		/**
		 * Modal periodicidade.
		 * 
		 */
		vm.modalPeriodicidades = function() {
			var _getTipoPeriodoById = function(id) {
				var tiposPeriodos = _tiposPeriodos();
				for(var i = 0; i < tiposPeriodos.length; i++) {
					if(tiposPeriodos[i].id == id)
						return tiposPeriodos[i];
				}
			}

			var _tiposPeriodos = function() {
				return [
					{id: 'B', nome: 'Bimestral', valor: 4}, 
					{id: 'T', nome: 'Trimestral', valor: 3},
					{id: 'E', nome: 'Semestral', valor: 2}
				];
			}

			var _referenciaFromId = function(id) {
				id = id.toString();
				var ano = id.substring(0, 4);
				var num = id.substring(5,6);

				return {id: id, nome:  (num + '°/' + ano)};
			}

			var _formatPeriodicidade = function(dto) {
				var meses = _meses();

				return {
					id: dto.id,
					programa: dto.programa,
					periodoLetivo: dto.periodoLetivo,
					referencia: _referenciaFromId(dto.referencia),
					tipoPeriodo: _getTipoPeriodoById(dto.tipoPeriodicidade),
					mesCalendario: meses[dto.mesCalendario - 1],
					dataInicio: moment(dto.dataInicio, 'YYYY-MM-DD').format('DD/MM/YYYY'),
					dataFim: moment(dto.dataFim, 'YYYY-MM-DD').format('DD/MM/YYYY')
				};
			}

			var _formatPeriodicidades = function(periodicidadesDTO) {
				var periodicidades = [];
				var tiposPeriodos = _tiposPeriodos();

				for(var i  = 0; i < periodicidadesDTO.length; i++) {
					var dto = periodicidadesDTO[i];
					periodicidades.push(_formatPeriodicidade(dto));
				}

				return periodicidades;
			}

			var _updateItem = function(periodicidade, selecionado, escolaPeriodicidades, isToAdd) {
				var updated  = false;
				for (var i = 0; i < escolaPeriodicidades.length; i++) {
					var p = escolaPeriodicidades[i];
					 
					if(p.$$hashKey == selecionado.$$hashKey) {
						p.programa = periodicidade.programa;
						p.periodoLetivo = periodicidade.periodoLetivo;
						p.tipoPeriodo = periodicidade.tipoPeriodo;
						p.referencia = periodicidade.referencia;
						p.mesCalendario = periodicidade.mesCalendario;
						p.dataInicio = periodicidade.dataInicio;
						p.dataFim = periodicidade.dataFim;

						updated = true;
						break;
					}
				}

				if(!updated && isToAdd) {
					escolaPeriodicidades.push(periodicidade);
				}
			}

			var _updateItemFromAlterados = function(periodicidade, selecionado) {
				var escolaPeriodicidades = vm.escolaPeriodicidadesAlterados;
				var updated  = false;
				for (var i = 0; i < escolaPeriodicidades.length; i++) {
					var p = escolaPeriodicidades[i];
					 
					if(p.id == selecionado.id) {
						p.programa = periodicidade.programa;
						p.periodoLetivo = periodicidade.periodoLetivo;
						p.tipoPeriodo = periodicidade.tipoPeriodo;
						p.referencia = periodicidade.referencia;
						p.mesCalendario = periodicidade.mesCalendario;
						p.dataInicio = periodicidade.dataInicio;
						p.dataFim = periodicidade.dataFim;

						updated = true;
						break;
					}
				}

				if(!updated) {
					escolaPeriodicidades.push(periodicidade);
				}
			}

			var _updateItemFromIncluidos = function(periodicidade, selecionado) {
				var escolaPeriodicidades = vm.escolaPeriodicidadesIncluidos;
				var s = selecionado;
				var updated  = false;
				for (var i = 0; i < escolaPeriodicidades.length; i++) {
					var p = escolaPeriodicidades[i];
					 
					if(p.programa.id == s.programa.id
						&& p.periodoLetivo.id == s.periodoLetivo.id
						&& p.mesCalendario == s.mesCalendario
						&& p.referencia.id == s.referencia.id
						&& p.tipoPeriodicidade == s.tipoPeriodicidade
						&& p.dataInicio == s.dataInicio
						&& p.dataFim == s.dataFim) {
							p.programa = periodicidade.programa;
							p.periodoLetivo = periodicidade.periodoLetivo;
							p.tipoPeriodicidade = periodicidade.tipoPeriodicidade;
							p.referencia = periodicidade.referencia;
							p.mesCalendario = periodicidade.mesCalendario;
							p.dataInicio = periodicidade.dataInicio;
							p.dataFim = periodicidade.dataFim;

						updated = true;
						break;
					}
				}
			}

			/**
			 * Verifica 2 periodicidades que não sejam dto são iguais. 
			 */
			var _ehIgual = function(p, s) {
				if (p.programa.id == s.programa.id
					&& p.periodoLetivo.id == s.periodoLetivo.id
					&& p.mesCalendario.referencia == s.mesCalendario.referencia
					&& p.referencia.id == s.referencia.id
					&& p.tipoPeriodo.id == s.tipoPeriodo.id
					&& p.dataInicio == s.dataInicio
					&& p.dataFim == s.dataFim) {
						return true;
					}
				
				return false;
			}

			/**
			 * Remove os itens selecionado dos items excluidos ou alterados. 
			 */
			var _removerItems = function(selecionados, listagem) {
				var removidos = [];
				for(var i in selecionados) {
					var s = selecionados[i];
					for(var j in listagem) {
						var p = listagem[j];
						if (_ehIgual(_formatPeriodicidade(p), s)) {
							var deletedItem = listagem.splice(listagem.indexOf(p), 1);
							break;
						}
					}
				}
			}

			DialogService.modal(
                'escolas/partials/escolas-form-periodicidades.html',
                function($uibModalInstance) {
					var _vm = this;
					
					_vm.status = {open: false};
					_vm.VALIDATION_PATTERN = vm.VALIDATION_PATTERN;
					_vm.canCreate = SecurityManagerFactory.hasPermission('EPR:CR');
					_vm.canUpdate = SecurityManagerFactory.hasPermission('EPR:UP');
					_vm.canDelete = SecurityManagerFactory.hasPermission('EPR:DE');
					_vm.programas = programasService.apenasAtivos();
					_vm.periodosLetivos = vm.periodosLetivos;
					_vm.escolaPeriodicidadeFields = TABLE_FIELDS.ESCOLA_PERIODICIDADE;
					_vm.escolaPeriodicidades = !vm.modalPeriodicidadesWasOpened 
						? _formatPeriodicidades(vm.escola.escolaPeriodicidades)
						: vm.escola.escolaPeriodicidades;
					
					vm.modalPeriodicidadesWasOpened = true;
					vm.escola.escolaPeriodicidades = _vm.escolaPeriodicidades;

					_vm.escolaPeriodicidadesIncluidos = vm.escolaPeriodicidadesIncluidos;
					_vm.tiposPeriodos = _tiposPeriodos();
					_vm.meses = _meses();
					_vm.escolaPeriodicidadesExcluidos = vm.escolaPeriodicidadesExcluidos;

					var _createRerencias = function(periodoLetivo, tipoPeriodo) {
						if (!periodoLetivo || !tipoPeriodo)
							return [];

						var anoLetivo = periodoLetivo.anoLetivo;
						var valorPeriodo = tipoPeriodo.valor;
						var referencias = [];

						for (var i = 1; i <= valorPeriodo; i++) {
							referencias.push({
								id: anoLetivo + '0' + i,
								nome: i + '°/' + anoLetivo
							});
						}

						return referencias;
					}

					var _normalizeEscolaPeriodicidadeDTO = function(periodicidade) {
						var p = {
							programa: periodicidade.programa,
							periodoLetivo: periodicidade.periodoLetivo,
							mesCalendario: periodicidade.mesCalendario.referencia,
							referencia: periodicidade.referencia.id,
							tipoPeriodicidade: periodicidade.tipoPeriodo.id,
							dataInicio: moment(periodicidade.dataInicio, 'DD/MM/YYYY').format('YYYY-MM-DD'),
							dataFim: moment(periodicidade.dataFim, 'DD/MM/YYYY').format('YYYY-MM-DD')
						};

						if(periodicidade.id >= 0)
							p.id = periodicidade.id;

						return p;
					}

					var _checkExistsPeriodicidade = function(escolaPeriodicidades, p, tipo) {
						if (tipo != 'CADASTRO')
							escolaPeriodicidades = _getTodasExcetoAhSelecionada(angular.copy(escolaPeriodicidades), _vm.selecionado);

						for(var i = 0; i < escolaPeriodicidades.length; i++) {
							var item = escolaPeriodicidades[i];
						
							if(item.programa.id == p.programa.id
								&& item.periodoLetivo.anoLetivo == p.periodoLetivo.anoLetivo
								&& item.tipoPeriodo.id == p.tipoPeriodo.id
								&& (item.referencia.id == p.referencia.id || item.mesCalendario.referencia == p.mesCalendario.referencia)) {
								return true;
							}
						}

						return false;
					}

					var _getTodasExcetoAhSelecionada = function(escolaPeriodicidades, selecionado) {
						var p = selecionado
						var lista = [];
						
						for(var i = 0; i < escolaPeriodicidades.length; i++) {
							var item = escolaPeriodicidades[i];
							if(item.programa.id == p.programa.id
								&& item.periodoLetivo.anoLetivo == p.periodoLetivo.anoLetivo
								&& item.mesCalendario.referencia == p.mesCalendario.referencia
								&& item.referencia.id == p.referencia.id
								&& item.tipoPeriodo.id == p.tipoPeriodo.id) {

								continue;
							}

							lista.push(item);
						}

						return lista;
					}

					_vm.setPeriodoLetivo = function(periodoLetivo) {
						_vm.referencias = _createRerencias(_vm.periodicidade.periodoLetivo, _vm.periodicidade.tipoPeriodo);
					}

					_vm.setTipoPeriodo = function(tipoPeriodo) {
						_vm.referencias = _createRerencias(_vm.periodicidade.periodoLetivo, _vm.periodicidade.tipoPeriodo);
					}

					_vm.showFormAssociar = function(item) {
						_vm.selecionado = item;
						_vm.selecionadoCopy = angular.copy(item);
						_vm.status.open = true;

						if(!item) {
							_vm.periodicidade = {};
							_vm.referencias = [];
							return;
						}

						_vm.periodicidade = angular.copy(item);
						_vm.referencias = _createRerencias(item.periodoLetivo, item.tipoPeriodo);
						_vm.editMode = false;
					};

					var _clearAll = function() {
						_vm.periodicidade = {};
						_vm.status.open = false;
						_vm.editMode = false;
						_vm.referencias = [];
					}

					var _clearAtributosEscolasForm = function() {
						
					};

					_vm.close = function () {
						_clearAll();
						$uibModalInstance.dismiss('cancel');
                    };

                    _vm.cancel = function () {
						_clearAll();
					};

					_vm.onRemove = function() {
						_removerItems(_vm.escolaPeriodicidadesSelecionados, vm.escolaPeriodicidadesIncluidos);
						_removerItems(_vm.escolaPeriodicidadesSelecionados, vm.escolaPeriodicidadesAlterados);
					}
					
					_vm.save = function(periodicidade) {
						if (_vm.periodicidade.dataInicio == _vm.periodicidade.dataFim) {
							return;
						}

						if (!_vm.periodicidadeForm.$valid) {
							return;
						}

						if (!_vm.periodicidade.mesCalendario) {
							_vm.periodicidade.mesCalendario = _meses()[moment(_vm.periodicidade.dataFim, 'DD/MM/YYYY').month()];
						}
						
						if (periodicidade && periodicidade.selected) {
							if(_checkExistsPeriodicidade(angular.copy(_vm.escolaPeriodicidades), periodicidade, 'ALTERAR')) {
								toastService.error("A periodicidade já foi adicionada.", 'flash-modal');
								scrollService.scrollTo("esc-form-modal");
								return;
							}

							_updateItem(periodicidade, _vm.selecionado, _vm.escolaPeriodicidades, false);
							if(periodicidade.id >= 0)
							{
								_updateItemFromAlterados(_normalizeEscolaPeriodicidadeDTO(angular.copy(periodicidade)), _vm.selecionado);
							} else {
								_updateItemFromIncluidos(_normalizeEscolaPeriodicidadeDTO(angular.copy(periodicidade)), _normalizeEscolaPeriodicidadeDTO(_vm.selecionadoCopy));
							}
						} else {
							if(!(periodicidade && periodicidade.selected) && _checkExistsPeriodicidade(angular.copy(_vm.escolaPeriodicidades), periodicidade, 'CADASTRO')) {
								toastService.error("A periodicidade já foi adicionada.", 'flash-modal');
								scrollService.scrollTo("esc-form-modal");
								return;
							}

							_vm.escolaPeriodicidades.push(angular.copy(periodicidade));
							vm.escolaPeriodicidadesIncluidos.push(_normalizeEscolaPeriodicidadeDTO(angular.copy(periodicidade)));
						}

						_clearAll();
					};
					
					_clearAll();
                }
            );
		};

		vm.totalEtapasSelecionadas = function() {
			var totalSelecionados = $filter('filter')(vm.etapas, function(value) {
				return value.selected == true;
			});

			return totalSelecionados.length;
		};
		
		vm.editarEtapa = function(etapas) {
			angular.forEach(etapas, function(value, index) {
				if (value.selected) {
					value.key = index+1;
					vm.modalEtapas(angular.copy(value));
					return true;
				}
			});
		};
		
		vm.excluirEtapa = function(etapas) {
			DialogService.confirm("Você tem certeza que deseja excluir a(s) etapa(s) selecionada(s)?", function(){
				var novasEtapas = [];
				angular.forEach(etapas, function(value, index) {
					if (!value.selected) {
						novasEtapas.push(value);
					} else {
						vm.etapasExcluidas.push(value);
					}
				});

				vm.etapas = novasEtapas;
				vm.todasEtapasSelecionada = false;
			});
		};

		vm.getMunicipiosPorUf = function(ufId) {
			vm.municipios = municipiosService.porUf(ufId);
		};

		vm.getTiposOrganizacao = function() {
			vm.tiposOrganizacao = escolasService.getTiposOrganizacao();
		};

		vm.getRegionalMunicipio = function(municipioId) {
			if (municipioId) {
				vm.regionaisMunicipios = municipiosService.regionais(municipioId);
			} else {
				vm.regionaisMunicipios = [];
			}
		};

		vm.addPrograma = function(programaExterno) {
			vm.programaExternoInvalido = false;
			vm.programaExternoVazio = false;

			if (is.undefined(programaExterno) || is.null(programaExterno) || is.empty(programaExterno)) {
				vm.programaExternoVazio = true;
				return false;
			}

			if (is.string(programaExterno)) {
				var val1 = programaExterno;

				angular.forEach(vm.programasExternos, function(value){
					var val2 = value.nome;
					
					if (val2.toLowerCase() == val1.toLowerCase()) {
						programaExterno = value;
					}
				});
			}
			
			angular.forEach(vm.escolaProgramasExternos, function(value) {
				var val1 = value.programaExterno.nome;
				var val2 = programaExterno;

				if (!is.string(val2)) {
					val2 = programaExterno.nome;
				}

				if (val2.toLowerCase() == val1.toLowerCase()) {
					vm.programaExternoInvalido = true;
				}	
			});

			if (vm.programaExternoInvalido) {
				return false;
			}

			var novoPrograma = {};
			if (is.string(programaExterno)) {
				novoPrograma = {
					programaExterno: {
						nome: programaExterno,
						situacao: { key:"A" }
					},
					situacao: { key:"A" }
				};
			} else {
				novoPrograma = {
					programaExterno: programaExterno,
					situacao: { key:"A" }
				};
			}
			
			vm.escolaProgramasExternos.push(novoPrograma);
			vm.escolaProgramasExternosIncluidos.push(novoPrograma);
			vm.programaExterno = null;	
		};
		
		vm.excluirPrograma = function(i) {
			var programa = vm.escolaProgramasExternos[i];
			var excluido = false;

			angular.forEach(vm.escolaProgramasExternosIncluidos, function(value, index) {
				var val1 = value.programaExterno.nome;
				var val2 = programa.programaExterno.nome;
				
				if (val1.toLowerCase() == val2.toLowerCase()) {
					vm.escolaProgramasExternosIncluidos.splice(index, 1);
					excluido = true;
					return;
				}
			});

			if (!excluido) {
				vm.escolaProgramasExternosExcluidos.push(programa);	
			}

			vm.escolaProgramasExternos.splice(i, 1);
		};

		var _clearItems = function () {
			vm.items = [];
			vm.newItems = [];
		};

		vm.goBack = function () {
			$state.go(APP_STATE.ESCOLA.SEARCH);
		};

		vm.enableEditMode = function () {
			vm.editMode = true;
		};

		vm.cancel = function () {
			if (vm.refreshSearch) {
				$scope.$emit(APP_EVENT.ESCOLA.REFRESH);
			}

			vm.goBack();
		};

		vm.danger = function () {
			var message = '<strong><i class="glyphicon glyphicon-exclamation-sign "></i></strong> <span>Atenção você deseja sair sem incluir um atributo a esta escola?</span';
			Flash.create('danger', message);
		};

		vm.save = function () {
			if (vm.escola.tipoOrganizacao.key == '3' && vm.escolasAssociadas.length == 0) {
				toastService.error({
					message: "Para ser uma escola do tipo nucleada, é necessário associá-la a uma escola núcleo."
				});

				return;
			}

            if (vm.registerForm.$valid) {

				var etapasKey = [];
				var novoTipo = "";
				if (vm.etapas && vm.etapas.length > 0) {
					angular.forEach(vm.etapas, function(value, key) {
						novoTipo = {key: vm.etapas[key].tipo.key};
						vm.etapas[key].tipo = novoTipo;
					});
				}

				vm.escola.tipoOrganizacao = { key: vm.escola.tipoOrganizacao.key };
				vm.escola.tipoCadastro = {key: "M"};
				vm.escola.calendarios = vm.calendarios;
				vm.escola.calendariosExcluidos = vm.calendariosExcluidos;
				vm.escola.escolaProgramasExternos = vm.escolaProgramasExternos;
				vm.escola.escolaProgramasExternosIncluidos = vm.escolaProgramasExternosIncluidos;
				vm.escola.escolaProgramasExternosExcluidos = vm.escolaProgramasExternosExcluidos;
				vm.escola.etapas = vm.etapas;
				vm.escola.etapasExcluidas = vm.etapasExcluidas;
				vm.escola.escolasAssociadas = vm.escolasAssociadas;
				vm.escola.escolasAssociadasExcluidas = vm.escolasAssociadasExcluidas;

				var escola = angular.copy(vm.escola);
				escola.escolaPeriodicidadesIncluidos = vm.escolaPeriodicidadesIncluidos;
				escola.escolaPeriodicidadesExcluidos = vm.escolaPeriodicidadesExcluidos;
				escola.escolaPeriodicidadesAlterados = vm.escolaPeriodicidadesAlterados;
				escola.escolaPeriodicidades = null;

				escolasService.save(escola).then(function (response) {
                    $scope.$emit(APP_EVENT.ESCOLA.SAVED);
                    vm.goBack();
                }, function (response) {
                    toastService.error({
                        message: response.data.message
                    });
                });
            }
        };

		vm.mudarTipoOrganizacao = function (tipo) {
			vm.showMsnMudancaTipo = false;
			var totalAssociadas = vm.escolasAssociadas.length;
			vm.msnMudancaTipoParaNucleada = false;
			vm.msnMudancaTipoParaUnica = false;
			
			if (totalAssociadas > 0) {
				vm.showMsnMudancaTipo = true;
				vm.escola.tipoOrganizacao = {key: vm.escolaOriginal.tipoOrganizacao.key};
			} else {
				vm.escolaOriginal.tipoOrganizacao = {key: angular.copy(tipo)};
			}
		};	

		(function () {
			vm.msnMudancaTipoOrganizacao = false;
			vm.escolaOriginal = {};
            vm.editMode = false;
            vm.editPage = false;
            vm.defaultStatus = COMMON_STATUS_LIST[0];
			vm.getTiposOrganizacao();
            
            if ($state.is(APP_STATE.ESCOLA.EDIT)) {
                vm.editPage = true;
				
				if ($state.params.id) {
					
                    escolasService.get($state.params.id).then(function (response) {
						vm.escolaOriginal = angular.copy(response);
						vm.escola = response;
						vm.escolaProgramasExternos = response.escolaProgramasExternos;
						vm.escolasAssociadas = response.escolasAssociadas;
						vm.etapas = response.etapas;
						vm.calendarios = response.calendarios;
						vm.escola.atributosEntidadesIncluidosDTO = [];
						vm.escola.atributosEntidadesAlteradosDTO = [];
						vm.escola.atributosEntidadesExcluidosDTO = [];
						vm.escola.escolaPeriodicidadesIncluidos = [];
						vm.escola.escolaPeriodicidadesExcluidos = [];

						if (is.existy(response.atributosEntidadesDTO) && is.not.empty(response.atributosEntidadesDTO)) {
							vm.escola.atributosEntidadesDTO = response.atributosEntidadesDTO;
						} else {
							vm.escola.atributosEntidadesDTO = [];
						}

						vm.getMunicipiosPorUf(response.uf.id);
						vm.getRegionalMunicipio(response.municipio.id);

						vm.ufs.$promise.then(function(data){
                            if (is.not.existy(_.findWhere(data, {id: response.uf.id}))){
                                vm.ufs.push(response.uf);
                                vm.ufs = _.sortBy(vm.ufs, 'nome');
                            }
                        });

						vm.municipios.$promise.then(function(data){
                            if (is.not.existy(_.findWhere(data, {id: response.municipio.id}))){
                                vm.municipios.push(response.municipio);
                                vm.municipios = _.sortBy(vm.municipios, 'nome');
                            }
							vm.registerForm.$setPristine();
                        });

                    }, function (response) {
                        toastService.error({
                            message: response.data.message
                        });
                    });
                } else {
                    vm.goBack();
                }
            } else {
                vm.enableEditMode();
                vm.escola = {};
				vm.escola.atributosEntidadesDTO = [];
				vm.escola.atributosEntidadesIncluidosDTO = [];
				vm.escola.atributosEntidadesAlteradosDTO = [];
				vm.escola.atributosEntidadesExcluidosDTO = [];
				vm.escola.escolaPeriodicidadesExcluidos = [];
				vm.escola.escolaPeriodicidadesAlterados = [];
				vm.escola.escolaPeriodicidadesIncluidos = [];
				vm.escola.escolaPeriodicidades = [];
            }
        } ());
	}

} ());