(function () {
    'use strict';

    angular.module('app.contrato').controller('ContratoFormController', ContratoFormController);
    ContratoFormController.$inject = ['APP_STATE', 'TABLE_FIELDS', 'APP_EVENT', 'COMMON_STATUS_LIST',
        'CONTRATO_ESCOLA_SEARCH_BY', '$state', '$scope', 'toastService', 'contratoService', '$uibModal',
        'escolasService', 'periodoLetivoService', 'VALIDATION_PATTERN', 'dialogService', 'dateUtilService',
        'ufService', 'municipiosService', 'programasService'];

    function ContratoFormController(APP_STATE, TABLE_FIELDS, APP_EVENT, COMMON_STATUS_LIST, 
        CONTRATO_ESCOLA_SEARCH_BY, $state, $scope, toastService, contratoService, $uibModal,
        escolasService, periodoLetivoService, VALIDATION_PATTERN, dialogService, dateUtilService,
        ufService, municipiosService, programasService) {

        var vm = this;
        var MODAL_TEMPLATE = 'contrato/partials/contrato-escola-associar.html';

        vm.goBack = function () {
            $state.go(APP_STATE.CONTRATO.SEARCH);
        };

        vm.cancel = function () {
            if (vm.refreshSearch) {
                $scope.$emit(APP_EVENT.CONTRATO.REFRESH);
            }

            vm.goBack();
        };

        var _populateModalDependencies = function() {
            
            periodoLetivoService.ativos().$promise.then(function(periodos) {
                vm.periodosLetivos = periodos;
            });

            contratoService.findCriteriosAdesao().then(function(criteriosAdesao) {
                vm.criteriosAdesao = criteriosAdesao;
            });
        };

        var populateEscopos = function () {
            contratoService.findEscopos().then(function (escopos) {
                vm.escopos = escopos;
            });
        };

        vm.confirmDeleteProgram = function(contratoPrograma) {

            swal({
                title: "Você tem certeza?",
                text: "Deseja realmente remover o Programa Cadastrado!",
                icon: "warning",
                buttons: [
                  'Não',
                  'Sim'
                ],
                dangerMode: true,
              }).then(function(isConfirm) {
                if (isConfirm) {
                  swal({
                    title: 'Sucesso!',
                    text: 'Programa removido com sucesso!',
                    icon: 'success'
                  }).then(function() {
                    $scope.$evalAsync(function() { vm.delPrograma(contratoPrograma) });
                    
                  });
                } else {
                  swal("Cancelado", "O Programa Cadastrado não foi removido!", "error");
                  return false;
                }
              });
        }

        var populateUfs = function () {
            ufService.withPermissionFilter({}).then(function(response){
                vm.ufs = response;
            });
        };
        
        vm.populateMunicipios = function (idUf) {
            vm.municipios = []; 

            if (idUf) {
                municipiosService.withPermissionFilter({idUf: idUf}).then(function(response){
                    vm.municipios = response;
                });
            }
        };
        
        var populateProgramas = function () {
            contratoService.findProgramasAtivos().then(function (programas) {
                vm.programas = programas;
            });
        };

        vm.viewMunicipio = true;

        vm.viewMunicipioSelect = function(key) {
            if (key) {
                if (key == 'E') {
                    vm.viewMunicipio = false;
                    vm.model.municipio = null;
                } else {
                    if (is.existy(vm.model.uf)) {
                        vm.populateMunicipios(vm.model.uf.id);
                    }

                    vm.viewMunicipio = true;
                }
            } else {
                vm.model.uf = null;
                vm.model.municipio = null;
                vm.viewMunicipio = false;
            }
        };

        vm.enableEditMode = function () {
            vm.editMode = true;
        };

        vm.buscarProgramas = function (uf, municipio) {
            if (undefined == municipio) {
                municipio = {id:null};
            }

            if (undefined == uf) {
                uf = {id:null};
            }
            
            vm.programas = programasService.byUfAndMunicipio(uf.id, municipio.id);    
        };

        vm.addPrograma = function () {
            if (vm.programaSelected) {
                vm.model.contratosProgramasIncluidos.push({
                    programa: vm.programaSelected
                });
                
                vm.programasSel.push({
                    programa: vm.programaSelected
                });
                vm.model.contratosProgramasExcluidos = _.reject(vm.model.contratosProgramasExcluidos, function (p) { return p.programa.id == vm.programaSelected.id; });
                vm.programas = _.reject(vm.programas, function (p) { return p.id == vm.programaSelected.id; });
                vm.programas = _.sortBy(vm.programas, 'nome');
                vm.programaSelected = null;
            }

        }

        vm.delPrograma = function (contratoPrograma) {
                vm.model.contratosProgramasIncluidos = _.reject(vm.model.contratosProgramasIncluidos, function (p) { return p.programa.id == contratoPrograma.programa.id; });
                vm.programasSel = _.reject(vm.programasSel, function (p) { return p.programa.id == contratoPrograma.programa.id; });
    
                if (contratoPrograma.id) {
                    vm.model.contratosProgramasExcluidos.push(contratoPrograma);
                }
                if(!_existeProgramaNaLista(contratoPrograma.programa)){
                    vm.programas.push(contratoPrograma.programa);
                    vm.programas = _.sortBy(vm.programas, 'nome');    
                }   
        }
                       
        

        var _existeProgramaNaLista = function(programa){
            var existe = false;
            for(var i = 0; i < vm.programas.length; i++){
                if(programa.id == vm.programas[i].id){
                    existe = true;
                    break;
                }
            }
            return existe;
        }

        vm.save = function () {
            vm.registerForm.dataFim.$setValidity("dateLessThan", true);

            if (vm.registerForm.$valid) {
                var dataInicio = moment(vm.model.dataInicio, 'DD/MM/YYYY');
                var dataFim = moment(vm.model.dataFim, 'DD/MM/YYYY');

                if (dataInicio > dataFim) {
                    vm.registerForm.dataFim.$setValidity("dateLessThan", false);
                    return;
                }

                if (is.not.existy(vm.programasSel) || is.empty(vm.programasSel)) {
                    toastService.error("Pelo menos um programa deve ser incluído.");
                    return;
                }

                contratoService.save(vm.model, dataInicio, dataFim).
                    then(function (contrato) {
                        $scope.$emit(APP_EVENT.CONTRATO.SAVED);

                        vm.goBack();
                    }, function (response) {
                        toastService.error({
                            status: response.status,
                            message: response.data.message
                        });
                    });
            };
        };

        vm.canShowAssociarEscolas = function() {
            var canShow = false;

            if (is.existy(vm.model.escopo)) {
                var ufOrMunicipioExisty = false;

                if (vm.model.escopo.key == 'E') {
                    ufOrMunicipioExisty = is.existy(vm.model.uf);
                } else {
                    ufOrMunicipioExisty = is.existy(vm.model.uf) && is.existy(vm.model.municipio);
                }

                canShow = ufOrMunicipioExisty && is.existy(vm.programasSel) && is.not.empty(vm.programasSel);
            }

            return canShow;
        };

        vm.associarEscolas = function () {
             
            angular.forEach(vm.model.contratoProgramaEscola, function(item) {
                item.selected = false;
            });

            if (vm.canShowAssociarEscolas()) {
                dialogService.modal(MODAL_TEMPLATE, function($uibModalInstance) {
                    var _vm = this;

                    
                    _vm.clean = true;
                    _vm.escolaAssociada = {};
                    _vm.searchEscolasWasPerformed = false;
                    _vm.filterEscolasPaginate = {};
                    _vm.canShowFormAssociar = false;
                    _vm.VALIDATION_PATTERN = VALIDATION_PATTERN;
                    _vm.escolaFields = TABLE_FIELDS.CONTRATO_ESCOLA;
                    _vm.escolaAssociadaFields = TABLE_FIELDS.CONTRATO_ESCOLA_ASSOCIADA;
                    _vm.modalTitle = vm.idContrato;
                    
                    _vm.escolasAssociadas = vm.model.contratoProgramaEscola;
                    _vm.escolasAssociadasSelecionadas = [];
                    _vm.escolasAssociadasExcluidas = vm.model.contratoProgramaEscolaExcluidos;
                    _vm.escolasAssociadasClear = false;

                    _vm.escolas = [];
                    _vm.escolasSelecionadas = [];

                    _vm.uf = (vm.model.uf) ? vm.model.uf.nome : "";
                    _vm.municipio = (vm.model.municipio) ? vm.model.municipio.nome : "";

                    _vm.SEARCH_BY = CONTRATO_ESCOLA_SEARCH_BY;
                    _vm.searchType = CONTRATO_ESCOLA_SEARCH_BY.NAME;

                    _vm.criteriosSelecao = vm.criteriosSelecao;
                    _vm.criteriosAdesao = vm.criteriosAdesao;
                    _vm.periodosLetivos = vm.periodosLetivos;
                    _vm.programas = contratoService.contratosProgramasToProgramas(vm.programasSel);

                    _vm.escolasAssociadasChange = function(selectedItems) {
                         _vm.clean = true;
                        _clearAssociarEscolasForm();
                    };

                    var _findEscolas = function(filter) {
                        _vm.clean = true;
                        _vm.searchEscolasWasPerformed = false;
                        _vm.escolas = [];
                        filter.programas = [];
                        angular.forEach(vm.programasSel, function(value){
                            filter.programas.push(value.programa.id);
                        });

                        escolasService.searchByWithPermission(filter).then(function(response) {
                            _vm.escolas = response;
                            _vm.escolasTotal = response.length;
                            _vm.searchEscolasWasPerformed = true;
                        });
                    };

                    var _clearAll = function() {
                         _vm.clean = true;
                        _vm.escolasAssociadasClear = true;
                        _clearAssociarEscolasForm();
                    };

                    var _clearAssociarEscolasForm = function() {
                         _vm.clean = true;
                        _vm.escolaAssociada = {};
                        _vm.escolaDataInicio = null;
                        _vm.escolaDataFim = null;
                        _vm.escolasSelecionadas = [];
                        _vm.escolas = [];                        
                        _vm.escolasTotal = 0;
                        _vm.escolasCurrentPage = 0;
                        _vm.searchEscolasWasPerformed = false;
                        _vm.searchValue = "";
                        _vm.searchType = CONTRATO_ESCOLA_SEARCH_BY.NAME;
                        _vm.canShowFormAssociar = false;
                        _vm.editMode = false;
                    };

                    _vm.close = function () {
                        _clearAll();
                        $uibModalInstance.dismiss('cancel');
                    };

                    _vm.cancel = function () {
                        _clearAll();
                    };

                    _vm.clearSearchValue = function() {
                        _vm.searchValue = "";
                    };                        

                    _vm.searchEscolas = function() {
                        if (is.existy(_vm.searchValue) && is.not.empty(_vm.searchValue)) {
                            _vm.filterEscolas = {
                                page: 0, size: 10, situacao: 'ATIVO'
                            };
                            
                            if (is.existy(vm.model.uf)) {
                                _vm.filterEscolas.idUf = vm.model.uf.id;
                            }

                            if (is.existy(vm.model.municipio)) {
                                _vm.filterEscolas.idMunicipio = vm.model.municipio.id;
                            }

                            switch (_vm.searchType) {
                                case _vm.SEARCH_BY.CODE:
                                    _vm.filterEscolas.id = parseInt(_vm.searchValue);
                                    break;
                                case _vm.SEARCH_BY.INEP:
                                    _vm.filterEscolas.codigoINEP = _vm.searchValue;
                                    break;
                                default:
                                    _vm.filterEscolas.nome = _vm.searchValue;
                            }

                            _vm.filterEscolasPaginate = angular.copy(_vm.filterEscolas);
                            _findEscolas(_vm.filterEscolas);
                        }
                    };

                    _vm.onPageChangeEscolas = function(page, size) {
                        _vm.filterEscolasPaginate.page = page;
                        _vm.filterEscolasPaginate.size = size;
                        _findEscolas(_vm.filterEscolasPaginate);
                    };

                    _vm.showFormAssociar = function(item) {                        
                        if (is.existy(item)) {
                            _vm.editMode = true;
                            _vm.escolaAssociada = angular.copy(item);
                            _vm.escolaDataInicio = contratoService.formatDateToClient(_vm.escolaAssociada.dataInicio);
                            _vm.escolaDataFim = contratoService.formatDateToClient(_vm.escolaAssociada.dataFim);
                        } else {
                            _clearAssociarEscolasForm();
                        }

                        _vm.canShowFormAssociar = true;
                    };

                    var _isValidForm = function(form) {
                        var isValidDates = _validateDates(form);

                        return form.$valid && isValidDates;
                    };

                    var _validateDates = function(form) {

                        var valid = true;

                        if (_vm.escolaDataInicio != null && _vm.escolaDataFim != null && _vm.escolaDataInicio != '' && _vm.escolaDataFim != '' &&
                            vm.model.dataInicio  != null && vm.model.dataFim != null) {
                            
                            var dtIni = dateUtilService.parseFromClient(vm.model.dataInicio, dateUtilService.TYPE.DATE);
                            var dtFin = dateUtilService.parseFromClient(vm.model.dataFim, dateUtilService.TYPE.DATE);
                            var dtIniEscola = dateUtilService.parseFromClient(_vm.escolaDataInicio, dateUtilService.TYPE.DATE);
                            var dtFinEscola = dateUtilService.parseFromClient(_vm.escolaDataFim, dateUtilService.TYPE.DATE);

                            var validIni = dtIniEscola >= dtIni && dtIniEscola <= dtFin;
                            _vm.associarEscolasForm.escolaDataInicio.$setValidity("iasDatesOutOfRangeContractValue", validIni);

                            var validFin = dtFinEscola >= dtIni && dtFinEscola <= dtFin;
                            _vm.associarEscolasForm.escolaDataFim.$setValidity("iasDatesOutOfRangeContractValue", validFin);

                            if (validIni && validFin) {

                            }
                    
                        } else {
                            _vm.associarEscolasForm.escolaDataInicio.$setValidity("iasDatesOutOfRangeContractValue", true);
                            _vm.associarEscolasForm.escolaDataFim.$setValidity("iasDatesOutOfRangeContractValue", true);
                            
                            var escolaDataInicio = _vm.associarEscolasForm.escolaDataInicio.$viewValue;
                            var escolaDataFim = _vm.associarEscolasForm.escolaDataFim.$viewValue;
                            
                            
                            //Código comentado conforme task SITEINDICIAS-950
                            if ((undefined === escolaDataInicio || is.empty(escolaDataInicio) || is.null(escolaDataInicio))
                                &&
                                (undefined === escolaDataFim || is.empty(escolaDataFim) || is.null(escolaDataFim))) {
                                _vm.escolaDataInicio = vm.model.dataInicio;
                                _vm.escolaDataFim = vm.model.dataFim;
                            }
                            
                            
                        }
                        
                        if (is.existy(_vm.escolaDataInicio) && is.existy(_vm.escolaDataFim)) {
                            var dataInicio = moment(_vm.escolaDataInicio, 'DD/MM/YYYY');
                            var dataFim = moment(_vm.escolaDataFim, 'DD/MM/YYYY');

                            if (dataInicio > dataFim) {
                                valid = false;
                            }
                        } else if (is.not.existy(_vm.escolaDataInicio) && is.not.existy(_vm.escolaDataFim)) {
                            valid = true;
                        } else {
                            valid = false;
                        }

                        return valid;
                    };

                    _vm.associarEscolas = function(form) {
                        _vm.clean = true;
                        _vm.associarEscolasForm.escolaDataInicio.$setValidity("iasDatesOutOfRangeContractValue", true);
                        _vm.associarEscolasForm.escolaDataFim.$setValidity("iasDatesOutOfRangeContractValue", true);
                        if (_isValidForm(form)) {
                            if (_vm.editMode) {
                                if (is.not.empty(_vm.escolasAssociadasSelecionadas)) {
                                    _vm.escolaAssociada.escolaDataInicio = contratoService.formatDateToServer(_vm.escolaDataInicio);
                                    _vm.escolaAssociada.escolaDataFim = contratoService.formatDateToServer(_vm.escolaDataFim);

                                    _vm.escolaAssociada.dataInicio = contratoService.formatDateToServer(_vm.escolaDataInicio);
                                    _vm.escolaAssociada.dataFim = contratoService.formatDateToServer(_vm.escolaDataFim);

                                    angular.merge(_vm.escolasAssociadasSelecionadas[0], _vm.escolaAssociada);
                                    _vm.escolasAssociadasSelecionadas[0]._updated = true;

                                    _clearAll();
                                }
                            } else {
                                if (is.not.empty(_vm.escolasSelecionadas)) {
                                    _vm.escolaAssociada.escolaDataInicio = contratoService.formatDateToServer(_vm.escolaDataInicio);
                                    _vm.escolaAssociada.escolaDataFim = contratoService.formatDateToServer(_vm.escolaDataFim);

                                    _vm.escolaAssociada.dataInicio = contratoService.formatDateToServer(_vm.escolaDataInicio);
                                    _vm.escolaAssociada.dataFim = contratoService.formatDateToServer(_vm.escolaDataFim);

                                    var associadas = contratoService.transformToContratoProgramaEscola(vm.idContrato, 
                                        _vm.escolasSelecionadas, _vm.escolaAssociada);

                                    angular.forEach(associadas, function(associada) {
                                        var exist = false;
                                        angular.forEach(_vm.escolasAssociadas, function(item) {
                                            if (item.escola.id == associada.escola.id && item.programa.id == associada.programa.id) {
                                                exist = true;
                                            }
                                        });
                                        if (!exist) {
                                            _vm.clean = true;
                                            _vm.escolasAssociadas.push(associada);
                                        } else {
                                            _vm.clean = false;
                                        }
                                    });
                                    
                                    if (_vm.clean) {
                                        _clearAll();
                                    }
                                }
                            }
                        }
                    };
                });
            }
        };

        (function () {
            vm.escopos = [];
            vm.ufs = [];
            vm.municipios = [];
            vm.programas = [];
            vm.model = {};
            vm.model.contratosProgramasIncluidos = [];
            vm.model.contratosProgramasExcluidos = [];
            populateEscopos();
            populateUfs();
            populateProgramas();
            vm.editMode = false;
            vm.defaultStatus = COMMON_STATUS_LIST[0];
            vm.exibeFiltrosEscola = true;
            vm.criteriosSelecao = [];
            vm.periodosLetivos = [];
            vm.criteriosAdesao = [];
            vm.VALIDATION_PATTERN = VALIDATION_PATTERN;

            _populateModalDependencies();

            if ($state.is(APP_STATE.CONTRATO.EDIT)) {
                vm.editPage = true;

                if ($state.params.id) {
                    contratoService.get($state.params.id)
                        .then(function (contrato) {
                            vm.model = contrato;
                            vm.idContrato = vm.model.id;

                            if (vm.model.dataInicio != null) {
                                vm.model.dataInicio = moment(vm.model.dataInicio, 'YYYY-MM-DD').format('DD/MM/YYYY')
                            }

                            if (vm.model.dataFim != null) {
                                vm.model.dataFim = moment(vm.model.dataFim, 'YYYY-MM-DD').format('DD/MM/YYYY')
                            }

                            if (vm.model.escopo.key == 'M') {
                                populateUfs();
                                vm.populateMunicipios(vm.model.municipio.uf.id);
                                vm.model.uf = vm.model.municipio.uf;
                            } else {
                                vm.viewMunicipio = false;
                            }

                            if (vm.model.contratosProgramas && vm.model.contratosProgramas.length > 0) {
                                vm.programasSel = vm.model.contratosProgramas;

                                var indexBy = _.indexBy(vm.programasSel, function(contratoPrograma) {
                                    return contratoPrograma.programa.id;
                                });
                                vm.programas = _.reject(vm.programas, function (p) {
                                    return indexBy[p.id] != null;
                                });
                            }

                            vm.model.contratosProgramasIncluidos = [];
                            vm.model.contratosProgramasExcluidos = [];
                            
                            vm.model.contratoProgramaEscola = [];
                            vm.model.contratoProgramaEscolaExcluidos = [];

                            contratoService.findContratoProgramaEscolasOtimizado(vm.model.id).then(function(escolasAssociadas) {
                                vm.model.contratoProgramaEscola = escolasAssociadas;
                            });

                            vm.buscarProgramas(contrato.uf, contrato.municipio);
                        }, function (response) {
                            toastService.error({
                                status: response.status,
                                message: response.data.message
                            });
                        });
                } else {
                    vm.goBack();
                }
            } else {
                vm.enableEditMode();
                vm.model = {};
                vm.model.contratosProgramasIncluidos = [];
                vm.model.contratosProgramasExcluidos = [];
                vm.model.contratoProgramaEscola = [];
                vm.model.contratoProgramaEscolaExcluidos = [];
                vm.programasSel = [];
                vm.model.situacao = vm.defaultStatus;
                vm.model.escopo = { key: "M", value: "Municipal" };
            }

        } ());
    }

} ());
