(function () {
  'use strict';
  angular.module('app.aluno').controller('AlunoFormController', AlunoFormController);
  AlunoFormController.$inject = [
    'APP_STATE', '$state', 'Aluno', '$scope',
    'dialogService', 'ufService', '$filter', 'DialogService',
    'municipiosService', 'TABLE_FIELDS', 'scrollService',
    'APP_PROPERTY', 'toastService', 'validateService',
    'VALIDATION_PATTERN', 'APP_EVENT', 'alunoService', 'atributoService', 'programasService',
    'ALUNO_MESSAGES', '$timeout', 'professorService'
  ];

  function AlunoFormController(APP_STATE, $state, Aluno, $scope,
    dialogService, ufService, $filter, DialogService,
    municipiosService, TABLE_FIELDS, scrollService,
    APP_PROPERTY, toastService, validateService, VALIDATION_PATTERN, APP_EVENT,
    alunoService, atributoService, programasService, ALUNO_MESSAGES, $timeout, professorService) {

    var vm = this;
    var msgs = ALUNO_MESSAGES;
    var MODAL_ATTR_ALUNOS = 'aluno/partials/aluno-form-atributo.html';
    var MODAL_FORM_UPLOAD = 'aluno/partials/aluno-form-upload.html';
    var MODAL_IMPORT_ERROR_LIST = 'aluno/partials/importacao-error-list.html';

    vm.aluno = {};
    vm.ufs = ufService.ativos();
    vm.mySwitch = false;
    vm.refreshSearch = false;
    vm.VALIDATION_PATTERN = VALIDATION_PATTERN;
    vm.programas = programasService.ativos();
    vm.atributosEntidadesIncluidosDTO = [];
    vm.atributosEntidadesAlteradosDTO = [];
    vm.atributosEntidadesExcluidosDTO = [];
    vm.niveisEscolaridade = alunoService.niveisEscolaridade();
    
    var _extractNomeCurto = function (nome) {
      var nomeCurto = "";

      if (is.existy(nome) && is.not.empty(nome)) {
        var normalizedNome = nome.replace(/\s{2,}/g, ' ');
        var nomes = normalizedNome.split(" ");

        if (nomes.length > 1) {
          nomeCurto = nomes[0] + " " + nomes[nomes.length - 1];
        } else {
          nomeCurto = nomes[0]
        }
      }

      return nomeCurto;
    };

    this.existeUsuario = function (idPessoa) {
      return Professor.existeUsuario({ idPessoa: idPessoa }).$promise;
    };

    vm.criarUsuario = function () {
      if (vm.aluno && vm.aluno.pessoa && vm.aluno.pessoa.id) {
        alunoService.criarUsuario(vm.aluno.id).then(function (result) {
          vm.existeUsuario = { exist: true };
          toastService.success("Usuário criado para o aluno com sucesso.");
          vm.goSearchPage();
        }, function (response) {
          toastService.error(response.data.message);
        });
      }
    }

    var _showErrorMessage = function (error) {
      var msg;
      var errorData = error.data;
      var description = errorData.description;

      if (is.existy(description) && is.not.empty(description)) {
        msg = errorData.message + " " + description;
      } else {
        msg = errorData.message;
      }

      toastService.error(msg);
    };

    var _normalizeCpf = function () {
      if (is.existy(vm.aluno.pessoa.cpf)) {
        var cpf = vm.aluno.pessoa.cpf.replace(/\s/g, '');

        if (is.empty(cpf)) {
          vm.aluno.pessoa.cpf = null;
        }
      }
    };

    vm.modalAtributos = function () {
      dialogService.modal(MODAL_ATTR_ALUNOS, function ($uibModalInstance) {
        var _vm = this;
        _vm.status = { open: false };
        _vm.programas = vm.programas;
        _vm.alunosAtributosClear = false;
        _vm.alunoAtributosFields = TABLE_FIELDS.ALUNO_ATRIBUTOS;
        _vm.alunosAtributos = vm.atributosEntidadesDTO || [];
        _vm.alunosAtributosExcluidos = vm.atributosEntidadesExcluidosDTO;

        var _clearAll = function () {
          vm.atributosEntidadesDTO = _vm.alunosAtributos;
          _vm.alunosAtributosClear = true;
          _clearAtributosAlunosForm();
        };

        var _clearAtributosAlunosForm = function () {
          _vm.atributo = {};
          _vm.status.open = false;
          _vm.editMode = false;
        };

        var _verificarExisteAtributo = function (atributo) {
          var atributos;

          if (_vm.editMode) {
            atributos = $filter('filter')(_vm.alunosAtributos, function (value) {
              return (value.atributo.id == atributo.atributo.id) && (_vm.alunosAtributosSelecionados[0].atributo.id != atributo.atributo.id) &&
                (value.programa.id == atributo.programa.id) && (_vm.alunosAtributosSelecionados[0].programa.id != atributo.programa.id);
            });
          } else {
            atributos = $filter('filter')(_vm.alunosAtributos, function (value) {
              return (value.atributo.id == atributo.atributo.id && value.programa.id == atributo.programa.id);
            });
          }

          return (atributos.length > 0);
        };

        _vm.close = function () {
          _clearAll();
          $uibModalInstance.dismiss('cancel');
        };

        _vm.cancel = function () {
          _clearAll();
        };

        _vm.populateAtributos = function (idPrograma) {
          atributoService.porTipoEntidadePrograma('ALUNO', idPrograma).then(function (atributos) {
            _vm.atributos = atributos;

            _vm.atributos.$promise.then(function (data) {
              if (is.existy(_vm.atributo.atributo)) {
                if (is.not.existy(_.findWhere(data, { id: _vm.atributo.atributo.id }))) {
                  _vm.atributos.push(_vm.atributo.atributo.id);
                  _vm.atributos = _.sortBy(_vm.atributos, 'nome');
                }
              }
            });
          });
        };

        _vm.alunosAtributosChange = function (selectedItems) {
          _clearAtributosAlunosForm();
        };

        _vm.save = function (atributo) {
          if (_vm.atributoForm.$valid) {
            if (!_verificarExisteAtributo(atributo)) {
              vm.alunoForm.$setDirty();

              if (_vm.editMode) {
                _vm.alunosAtributosSelecionados[0].atributo = atributo.atributo;
                _vm.alunosAtributosSelecionados[0].dados = atributo.dados;
                _vm.alunosAtributosSelecionados[0].programa = atributo.programa;

                if (atributo.id) {
                  _vm.alunosAtributosSelecionados[0]._updated = true;
                }
              } else {
                _vm.alunosAtributos.push(angular.copy(atributo));
                vm.aluno.atributosEntidadesIncluidosDTO.push(angular.copy(atributo));
              }

              _clearAll();
            } else {
              toastService.error(ALUNO_MESSAGES.error.duplicated_attr, 'flash-modal');
              scrollService.scrollTo("esc-form-modal");
            }
          }
        };

        _vm.setAtributo = function (atributo, notModifyData) {
          if (atributo) {
            if (is.not.existy(notModifyData) || is.falsy(notModifyData)) {
              _vm.atributo.dados = alunoService.removeOldValuesFromAtributo(_vm.atributo.dados);
            }

            atributo.tipo = atributo.tipoAtributo;

            if (undefined !== atributo.idOpcao) {
              atributo.opcao = atributo.idOpcao;
            }

            if (undefined !== atributo.idMedida) {
              atributo.medida = atributo.idMedida;
            }
          } else {
            _vm.atributo = {};
          }
        };

        _vm.showFormAssociar = function (item) {
          if (is.existy(item)) {
            _vm.editMode = true;
            _vm.atributo = angular.copy(item);

            _vm.programas.$promise.then(function (data) {
              if (is.not.existy(_.findWhere(data, { id: _vm.atributo.programa.id }))) {
                _vm.programas.push(_vm.atributo.programa);
                _vm.programas = _.sortBy(_vm.programas, 'nome');
              }
            });

            _vm.populateAtributos(_vm.atributo.programa.id);
            _vm.setAtributo(_vm.atributo.atributo, true);
          }

          _vm.status.open = true;
        };

        _clearAll();
      });
    };

    vm.modalUpload = function () {
      dialogService.modal(MODAL_FORM_UPLOAD, function ($uibModalInstance) {

        var _vm = this;
        _vm.status = { open: false };



        _vm.close = function () {
          $uibModalInstance.dismiss('cancel');
        };

        _vm.cancel = function () {
          _vm.close();
        };

        _vm.ok = function (file) {
          if (_vm.formUpload.$valid) {
            dialogService.confirm(msgs.warning.deleteResponses, msgs.info.importTitle)
              .then(function (response) {
                _vm.success = null;
                _vm.error = null;
                alunoService.import(file)
                  .then(function (response) {
                    _vm.success = msgs.success.import;
                    _vm.sheetBack = response.data;
                  })
                  .catch(function (error) {
                    _vm.error = error;
                  });
              });
          }
        };

        _vm.showErrors = function (error) {
          dialogService.modal(MODAL_IMPORT_ERROR_LIST, function ($uibModalInstance) {
            this.formConstraints = error.formConstraints;
            this.messages = error.messages;

            this.cancel = function () {
              $uibModalInstance.dismiss('cancel');
            };
          });
        };

        _vm.onChangeFile = function () {
          _vm.success = null;
          _vm.error = null;
        };


      });
    };





    vm.validateDate = function () {
      if (validateService.date(vm.alunoForm.dataNascimento)) {
        return true;
      } else {
        vm.alunoForm.dataNascimento.$setValidity("dataNascimento", false);
      }
    };

    vm.getMunicipiosPorUf = function (ufId) {
      if (ufId > 0) {
        vm.municipios = municipiosService.porUf(ufId);
        vm.alunoForm.municipio.$setValidity("required", false);
      } else {
        vm.municipios = [];
        vm.alunoForm.municipio.$setValidity("required", true);
      }
    };

    vm.checkMunicipios = function (municipioId) {
      if (municipioId > 0) {
        vm.alunoForm.municipio.$setValidity("required", true);
      }
    };

    vm.goEditPage = function () {
      vm.mySwitch = false;
      vm.hasError = false;
      vm.disableEmail = false;
      vm.email = vm.aluno.pessoa.email;
    };

    vm.goNewPage = function () {
      $state.go(APP_STATE.ALUNO.NEW);
    };

    vm.goSearchPage = function () {
      if (vm.refreshSearch) {
        $scope.$emit(APP_EVENT.ALUNO.REFRESH);
      }

      $state.go(APP_STATE.ALUNO.SEARCH);
    };

    vm.stayPage = function () {
      $state.reload();
    };

    vm.edit = function (aluno, i) {
      vm.update = angular.copy(aluno);
      index = i;
    };

    vm.save = function () {
      var aluno = angular.copy(vm.aluno);

      if (aluno.pessoa.dataNascimento) {
        var dataNascimento = moment(aluno.pessoa.dataNascimento, 'DD/MM/YYYY').format('YYYY-MM-DD');
        aluno.pessoa.dataNascimento = dataNascimento;
      }

      aluno.login = aluno.pessoa.email;
      aluno.pessoa.nomeCurto = _extractNomeCurto(aluno.pessoa.nomeCompleto);

      if (!aluno.id) {
        aluno.senha = aluno.pessoa.email;
      }


      _normalizeCpf();

      aluno.atributosEntidadesExcluidosDTO = vm.atributosEntidadesExcluidosDTO;
      aluno.atributosEntidadesDTO = vm.atributosEntidadesDTO;
      alunoService._normalizeAtributosToServer(aluno);

      alunoService.save(aluno)
        .then(function () {
          vm.refreshSearch = true;

          if ($state.params.id) {
            toastService.success(ALUNO_MESSAGES.success.updated);
          } else {
            toastService.success(ALUNO_MESSAGES.success.saved);
          }

          vm.goSearchPage();
        })
        .catch(function (error) {
          vm.refreshSearch = false;
          _showErrorMessage(error);
        });
    };


    (function () {
      if ($state.is(APP_STATE.ALUNO.EDIT)) {
        if ($state.params.id) {
          vm.mySwitch = true;

          Aluno.get({ id: $state.params.id }).$promise.then(function (response) {
            vm.aluno = response;
            vm.aluno.atributosEntidadesIncluidosDTO = [];
            vm.aluno.atributosEntidadesAlteradosDTO = [];
            vm.aluno.atributosEntidadesExcluidosDTO = [];

            if (is.existy(response.atributosEntidadesDTO) && is.not.empty(response.atributosEntidadesDTO)) {
              vm.atributosEntidadesDTO = response.atributosEntidadesDTO;
            } else {
              vm.atributosEntidadesDTO = [];
            }

            _normalizeCpf();

            professorService.existeUsuario(vm.aluno.pessoa.id).then(function (result) {
              vm.existeUsuario = result;
            });

            if (vm.aluno.pessoa.dataNascimento != null) {
              vm.aluno.pessoa.dataNascimento = moment(vm.aluno.pessoa.dataNascimento, 'YYYY-MM-DD').format('DD/MM/YYYY')
            }

            if (vm.aluno.pessoa.municipio) {
              var pessoa = vm.aluno.pessoa;
              pessoa.uf = vm.aluno.pessoa.municipio.uf;
              vm.aluno.pessoa = pessoa;
              vm.getMunicipiosPorUf(vm.aluno.pessoa.uf.id);
            }

            $timeout(function () {
              vm.alunoForm.$setPristine();
              vm.alunoForm.$setUntouched();
            }, 100);
          });
        }
      } else {
        vm.aluno = {};
        vm.aluno.atributosEntidadesDTO = [];
        vm.aluno.atributosEntidadesIncluidosDTO = [];
        vm.aluno.atributosEntidadesAlteradosDTO = [];
        vm.aluno.atributosEntidadesExcluidosDTO = [];
      }
    }());
  }
}());
