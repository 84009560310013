(function() {
    'use strict';

    angular.module('app.formulario').controller('FormularioFormController', FormularioFormController);
    FormularioFormController.$inject = [
        'APP_STATE', '$state', '$filter',
        '$scope', '$window', '$uibModal',
        'Formulario', 'Opcao', 'Medida',
        'DialogService', 'toastService', 'APP_EVENT', 'formularioService',
        'rubricasService'
    ];

    function FormularioFormController(
        APP_STATE, $state, $filter,
        $scope, $window, $uibModal,
        Formulario, Opcao, Medida,
        DialogService, toastService, APP_EVENT, formularioService,
        rubricasService
    ) {
        var vm = this;

        var _init = function() {
            vm.hasPeriodicidade = true;
            vm.periodicidades = Formulario.periodicidades();
            vm.granularidades = Formulario.granularidades();
            vm.itens = [];
            vm.itensCriados = [];
            vm.itensAlterados = [];
            vm.itensExcluidos = [];
            vm.itemCriadoCountZeroDelete = false;
            vm.itemCriadoCountOneEdit = false;
            vm.seletedNewItemsCount = 0;
            vm.seletedSavedItemsCount = 0;
            vm.tipos = Formulario.tipos();
            vm.rubricas = rubricasService.ativos();
            vm.salvarContinuar = false;
            vm.editMode = false;
            vm.editPage = false;
            vm.hasScale = false;
            vm.newItems = [];
            vm.situacoes = [];
            vm.tiposItens = [];
            vm.refreshSearch = false;
            vm.formularioUsed = false;
            vm.tipoListOpcoes = [
                { key: 'A', value: 'Aberta' }, { key: 'F', value: 'Fechada' }
            ];

            formularioService.findSituacoes().then(function(situacoes) {
                vm.situacoes = situacoes;
            });

            formularioService.findTipos().then(function(tipos) {
                vm.tiposItens = tipos;
            });

            if ($state.is(APP_STATE.FORMULARIO.EDIT)) {
                vm.editPage = true;

                if ($state.params.id) {
                    Formulario.get({ id: $state.params.id }).$promise.then(function(formulario) {
                        vm.formulario = new Formulario(formulario);
                        vm.hasPeriodicidade = vm.formulario.granularidade.key != 'N';

                        if (vm.formulario.itens) {
                            vm.itens = angular.copy(vm.formulario.itens);
                        }

                        if (is.truthy($state.params.editMode)) {
                            vm.enableEditMode();
                        }
                    });

                    setTimeout(function() {
                        if(vm.formularioForm != undefined){
                            vm.formularioForm.$setPristine();
                        }
                    }, 1);
                } else {
                    vm.goBack();
                }
            } else {
                vm.enableEditMode();
                vm.formulario = new Formulario();
            }
        };


        vm.goBack = function() {
            vm.formItemDirty = false;
            $state.go(APP_STATE.FORMULARIO.SEARCH);

            if (vm.refreshSearch) {
                $scope.$emit(APP_EVENT.FORMULARIO.REFRESH);
            }
        };

        vm.toggleSelectNewItem = function(item) {
            if (item.selected) {
                vm.seletedNewItemsCount++;
            } else {
                vm.seletedNewItemsCount--;
            }
        };

        vm.toggleSelectSavedItem = function(item) {
            if (item.selected) {
                vm.seletedSavedItemsCount++;
            } else {
                vm.seletedSavedItemsCount--;
            }
        };

        // Editar os itens criados da lista em massa
        vm.editItensCriados = function() {
            var selectedCount = $filter('filter')(angular.copy(vm.itensCriados), { selected: true }).length;

            if (selectedCount == 1) {
                vm.itemCriadoCountOneEdit = false;

                angular.forEach(vm.itensCriados, function(itemCriado, key) {
                    if (itemCriado.selected) {
                        itemCriado.key = 1 + parseInt(key);
                        vm.showFormItem(itemCriado);
                    }
                });
            } else {
                vm.itemCriadoCountOneEdit = true;
            }
        };

        // Editar os itens da lista em massa
        vm.editItens = function() {
            var selectedCount = $filter('filter')(angular.copy(vm.itens), { selected: true }).length;

            if (selectedCount == 1) {
                vm.itemCountOneEdit = false;

                angular.forEach(vm.itens, function(item, key) {
                    if (item.selected) {
                        item.key = key+1;
                        vm.showFormItem(item);
                    }
                });
            } else {
                vm.itemCountOneEdit = true;
            }
        };

        vm.sortOrder = function(index, sort, tipoItens) {
            var start = index;
            var end = null;
            var lastNrOrdem = 0;
            var itens = vm.itens;

            if (tipoItens == 'itensCriados') {
                lastNrOrdem = vm.itens.length;
                itens = vm.itensCriados;
            }

            if (sort == 'up') {
                end = index - 1;
            } else if (sort == 'down') {
                end = index + 1;
            }

            itens.splice(end, 0, itens.splice(start, 1)[0]);
            angular.forEach(itens, function(item, i) {
                item.nrOrdem = lastNrOrdem+i+1;

                if (tipoItens != 'itensCriados') {
                    vm.itensAlterados.push(item);
                }
            });
        };

        // Deletar os itens criados da lista em massa
        vm.deleteItensCriados = function() {
            var selectedCount = $filter('filter')(vm.itensCriados, { selected: true }).length;

            if (selectedCount) {
                vm.itemCriadoCountZeroDelete = false;
                DialogService.confirm("Você tem certeza que deseja excluir o(s) item(ns) criado(s).", function(){
                    var itensCriados = [];
                    angular.forEach(vm.itensCriados, function(value, index) {
                        if (!value.selected) {
                            itensCriados.push(value);
                        }
                    });

                    vm.itensCriados = itensCriados;
                });
            } else {
                vm.itemCriadoCountZeroDelete = true;
            }
        };

        vm.selectedAllItensCriados = function() {
            if (vm.checkAll == true) {
                angular.forEach(vm.itensCriados, function(value) {
                    value.selected = true;
                });

                vm.seletedNewItemsCount = vm.itensCriados.length;
            } else {
                angular.forEach(vm.itensCriados, function(value) {
                    value.selected = false;
                });

                vm.seletedNewItemsCount = 0;	
            }
        };

        vm.selectedAllItens = function() {
            if (vm.checkItensAll == true) {
                angular.forEach(vm.itens, function(value) {
                    value.selected = true;
                });

                vm.seletedSavedItemsCount = vm.itens.length;
            } else {
                angular.forEach(vm.itens, function(value) {
                    value.selected = false;
                });

                vm.seletedSavedItemsCount = 0;
            }
        };

        vm.totalItensSelecionados = function() {
			var totalSelecionados = $filter('filter')(vm.itens, function(value) {
				return value.selected == true;
			});

			return totalSelecionados.length;
		};

        vm.totalItensCriadosSelecionados = function() {
			var totalSelecionados = $filter('filter')(vm.itensCriados, function(value) {
				return value.selected == true;
			});

			return totalSelecionados.length;
		};

        // Deletar os itens da lista em massa
        vm.deleteItens = function() {
            var selectedCount = $filter('filter')(vm.itens, { selected: true }).length;

            if (selectedCount) {
                vm.itemCountZeroDelete = false;
                DialogService.confirm("Você tem certeza que deseja excluir o(s) item(ns).", function(){
                    var novosItens = [];
                    angular.forEach(vm.itens, function(value, index) {
                        if (!value.selected) {
                            novosItens.push(value);
                        } else {
                            vm.itensExcluidos.push(value);
                        }
                    });

                    vm.itens = novosItens;
                });
            } else {
                vm.itemCountZeroDelete = true;
            }
        };

        vm.selectedItensCriadosCount = function() {
            var count = 0;
            angular.forEach($scope.students, function(student){
                count += student.isSelected ? 1 : 0;
            });
            return count;
        };

        vm.showFormItem = function(item) {
            var itemFormModal = DialogService.modal(
                'formulario/partials/formulario-form-item-modal.html',
                function($uibModalInstance){
                    var _vm = this;
                    _vm.formularioItem = {};
                    _vm.opcoes = Opcao.ativo();
                    _vm.medidas = Medida.ativos();
                    _vm.tiposItens = vm.tiposItens;
                    _vm.situacoes = vm.situacoes;
                    _vm.tipoListOpcoes = vm.tipoListOpcoes;
                    _vm.itemUsed = vm.formularioUsed;
                    if (item) {
                        _vm.formularioItem = angular.copy(item);

                        var tipoLista = _vm.formularioItem.listaFormularioItem;

                        if (is.existy(item.opcao)) {
                                _vm.opcoes.$promise.then(function(data){
                                    if (is.not.existy(_.findWhere(data, {id: item.opcao.id}))){
                                        _vm.opcoes.push(item.opcao);
                                        _vm.opcoes = _.sortBy(_vm.opcoes, 'nome');
                                    }
                                });
                        }

                        if (is.existy(item.medida)) {
                                _vm.medidas.$promise.then(function(data){
                                    if (is.not.existy(_.findWhere(data, {id: item.medida.id}))){
                                        _vm.medidas.push(item.medida);
                                        _vm.medidas = _.sortBy(_vm.medidas, 'nome');
                                    }
                                });
                        } 

                        // Compatibilidade com versões anteriores
                        if (is.existy(tipoLista) && tipoLista.key == 'F') {
                            _vm.formularioItem.multiplaRespostaFormularioItem = false;
                        }
                    } else {
                        _vm.formularioItem.situacao = {key: "A", value: "Ativo"};
                    }

                    if (!_vm.formularioItem.hasOwnProperty("verificaQtaAlunosFormularioItem")) {
                        _vm.formularioItem.verificaQtaAlunosFormularioItem = true;
                    }

                    if (!_vm.formularioItem.hasOwnProperty("preenchimentoObrigatorio")) {
                        _vm.formularioItem.preenchimentoObrigatorio = true;
                    }

                    _vm.changeTipoItem = function() {
                        var tipo = _vm.formularioItem.tipo;

                        if (tipo) {
                            switch (tipo.key) {
                                case 'O':
                                    delete _vm.formularioItem.medida;
                                    _vm.formularioItem.multiplaRespostaFormularioItem = false;
                                    break;
                                case 'M':
                                    delete _vm.formularioItem.opcao;
                                    delete _vm.formularioItem.listaFormularioItem;
                                    delete _vm.formularioItem.multiplaRespostaFormularioItem;
                                    break;
                                case 'L':
                                case 'P':
                                    delete _vm.formularioItem.medida;
                                    delete _vm.formularioItem.opcao;
                                    delete _vm.formularioItem.listaFormularioItem;
                                    delete _vm.formularioItem.multiplaRespostaFormularioItem;
                                    break;
                                case 'D':
                                    _vm.formularioItem.preenchimentoObrigatorio = false;
                                    break;
                            }
                        }
                    };

                    _vm.changeTipoLista = function() {
                        var tipoLista = _vm.formularioItem.listaFormularioItem;

                        if (is.existy(tipoLista)) {
                            _vm.formularioItem.multiplaRespostaFormularioItem = false;
                        } else {
                            delete _vm.formularioItem.multiplaRespostaFormularioItem;
                        }
                    };

                    var _exiteItem = function(item, itens) {
                        var resultado = $filter('filter')(itens, function(value, index, array) {   
                            var value1 = value.nome.toLowerCase();
                            var value2 = item.nome.toLowerCase();
                            
                            if (is.existy(item.key) && is.existy(item.id)) {
                                // verificando a editando item
                                if (is.existy(value.id)) {
                                    return value.id != item.id && value1 == value2;
                                } else {
                                    return value1 == value2;
                                }
                            } else if (is.existy(item.key) && is.not.existy(item.id)) {
                                var key = item.key-1;
                                // verificando a editando item criado
                                if (is.existy(value.id)) {
                                    return value1 == value2;
                                } else {
                                    return key != index && value1 == value2;
                                }
                            } else {
                                // verificando novo item                       
                                return value1 == value2;
                            }                             
                        });

                        return (resultado.length > 0);                            
                    };

                    var _verificarNomeIgual = function(item) {
                        var itens = vm.itens;
                        var itensCriados = vm.itensCriados;
                        
                        if (_exiteItem(item, itens)) {
                            return true;
                        }

                        if (_exiteItem(item, itensCriados)) {
                            return true;
                        }    

                        return false;
                    };

                    _vm.addItem = function (formularioItem) {
                        if (_vm.formAddItem.$valid) {
                            if (_verificarNomeIgual(formularioItem)) {
                                var errorMessage = "Já existe um cadastro com o nome informado. Por favor, informe um outro nome.";
                                toastService.error(errorMessage, 'flash-modal');
                                return true;
                            }

                            if (formularioItem.key) {
                                formularioItem.selected = false;
                                if (!formularioItem.id) {
                                    vm.itensCriados[formularioItem.key-1] = formularioItem;
                                } else {
                                    vm.itens[formularioItem.key-1] = formularioItem;
                                    vm.itensAlterados = vm.itens;
                                    vm.itensAlterados[formularioItem.key-1] = formularioItem;
                                }
                            } else {
                                if (!formularioItem.id) {
                                    formularioItem.nrOrdem = vm.itens.length + vm.itensCriados.length + 1;
                                    vm.itensCriados.push(angular.copy(formularioItem));
                                }
                                _vm.formularioItem = {};
                            }
                            vm.formularioForm.$setDirty();
                            $uibModalInstance.close();
                        }
                    };
                    _vm.cancel = function() {
                        $uibModalInstance.dismiss('cancel');
                    };
                }
            );
        };

        vm.granularidadeOnChange = function() {
            if (vm.formulario.granularidade) {
                vm.hasPeriodicidade = vm.formulario.granularidade.key != 'N';

                if (!vm.hasPeriodicidade) {
                    delete vm.formulario.periodicidade;
                }

            } else {
                vm.hasPeriodicidade = true;
            }
        };

        vm.changeTipoFormulario = function(){
            if(vm.formulario != undefined && vm.formulario.tipo){
                if(vm.formulario.tipo.key != 'R'){
                    vm.formulario.rubrica = null;
                }
            }
        };
        vm.save = function(continueEditing) {
            if (continueEditing && vm.formularioForm.$invalid) {
                vm.formularioForm.$setSubmitted();
                return false;
            }

            if (!vm.formulario.situacao) {
                vm.formulario.situacao =  {key: "A", value: "Ativo"};
            }

            vm.formulario.itens = vm.itens;
            vm.formulario.itensCriados = vm.itensCriados;
            vm.formulario.itensExcluidos = vm.itensExcluidos;
            vm.formulario.itensAlterados = vm.itensAlterados;

            if (vm.formulario.id) {
                if (vm.itens.length == 0 && vm.itensCriados.length == 0) {
                    toastService.error("Você precisa adicionar pelo menos um item.");
                    return true;
                }

                var selectedTipo = _.map(
                    _.filter(vm.tipos, function(element) {
                        return element.id == vm.formulario.tipo.key;
                    }),
                    function(element) {
                        return {key: element.id, value: element.name};
                    });

                if (selectedTipo && _.isArray(selectedTipo)) {
                    vm.formulario.tipo = selectedTipo[0];
                }

                if (vm.hasPeriodicidade) {
                    var selectedPeriodicidade = _.map(
                        _.filter(vm.periodicidades, function(element) {
                            return element.id == vm.formulario.periodicidade.key;
                        }),
                        function(element) {
                            return {key: element.id, value: element.name};
                        }
                    );

                    if (selectedPeriodicidade && _.isArray(selectedPeriodicidade)) {
                        vm.formulario.periodicidade = selectedPeriodicidade[0];
                    }
                }

                var selectedGranularidade = _.map(
                    _.filter(vm.granularidades, function(element) {
                        return element.id == vm.formulario.granularidade.key;
                    }),
                    function(element) {
                        return {key: element.id, value: element.name};
                    });

                if (selectedGranularidade && _.isArray(selectedGranularidade)) {
                    vm.formulario.granularidade = selectedGranularidade[0];
                }

                vm.formulario.$update().then(function(response) {
                    toastService.success("Formulário foi salvo com sucesso.");

                    vm.refreshSearch = true;

                    if (!vm.salvarContinuar) {
                        vm.refreshSearch = true;
                        vm.goBack();
                    } else {
                        _init();
                        $state.go(APP_STATE.FORMULARIO.EDIT, { id: response.id, editMode: true });
                    }
                }, function (response) {
                    vm.itens = Formulario.itens({id: vm.formulario.id});
                    vm.itensExcluidos = [];
                    toastService.error({
                        status: response.status,
                        message: response.data.message
                    });
                });
            } else {
                if (vm.itensCriados.length == 0) {
                    toastService.error("Você deve adicionar pelo menos um item.");
                    return true;
                }

                vm.formulario.permiteRetrocessoFormulario = 0;
                vm.formulario.$save().then(function(response) {
                    vm.formulario.id = response.id;
                    toastService.success("Formulário foi salvo com sucesso.");

                    if (!vm.salvarContinuar) {
                        vm.refreshSearch = true;
                        vm.goBack();
                    } else {
                        _init();
                        $state.go(APP_STATE.FORMULARIO.EDIT, { id: response.id, editMode: true });
                    }
                }, function (response) {
                    toastService.error({
                        status: response.status,
                        message: response.data.message
                    });
                });
            }
        };

        
        vm.enableEditMode = function() {
            if (vm.formulario != undefined && vm.formulario != null && vm.formulario.id != undefined && vm.formulario.id != null) {
                formularioService.isUsed(vm.formulario.id).then(function(response) {
                    if (response.responseStatusCode == 200) {
                        vm.formularioUsed = true;
                    } else if (response.responseStatusCode == 204) {
                        vm.formularioUsed = false;
                    }
                });
            }
            vm.editMode = !vm.editMode;
        };


        (function() {
            _init();
        }());
    }

}());
