(function() {
    'use strict';

    angular.module ('app.core').directive ('iasColetaPreview', IasColetaPreview);
    IasColetaPreview.$inject = ['dialogService'];

    function IasColetaPreview(dialogService) {
        return {
            scope: {
                items: '=?',
                ngDisabled: '=?',
                isAnonymous: '=?'
            },
            restrict: "E",
            templateUrl: "core/directives/coleta/partials/ias-coleta-preview.html",
            link: function(scope, element, attr) {
                var MODAL_LISTA_ERROS_RESPOSTA = 'core/directives/coleta/partials/ias-coleta-lista-erro.html';

                scope.formsName = {};
                scope.items = scope.items || [];
                scope.ngDisabled = is.existy(scope.ngDisabled) ? scope.ngDisabled : true;
                scope.isAnonymous = scope.isAnonymous || false;

                scope.pager = {
                    currentPage: 1
                };
                
                scope.showErrors = function() {
                    dialogService.modal(MODAL_LISTA_ERROS_RESPOSTA, function($uibModalInstance) {
                        var _vm = this;
                        
                        _vm.cancel = function() {
                            $uibModalInstance.dismiss('cancel');
                        };
                    });
                };

                scope.initFormName = function(index, name) {
                    scope.formsName[index] = name;
                };
            }
        };
    }

} ());
