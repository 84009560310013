(function(){
    'use strict';

    angular.module('app.programas').service('programasService', ProgramasService);
    ProgramasService.$inject = ['Programas', 'STATUS_ENUM'];

    function ProgramasService(Programas, STATUS_ENUM) {

        this.filter = function(filter) {
            return Programas.filter(filter).$promise;            
        };

        this.filterPageable = function(filter) {
            return Programas.filterPageable(filter).$promise;            
        };

        this.get = function(id) {
            return Programas.get({ id: id }).$promise;
        };

        this.delete = function(programa) {
            return Programas.delete({id: programa.id}).$promise;
        };

        this.ativos = function() {
            return Programas.ativo();
        };

        this.ativosIgnoringPermission = function() {
            return Programas.ativosIgnoringPermission();
        };

        this.apenasAtivos = function() {
            return Programas.ativos();
        };

        this.ativosPorIdAnoLetivo = function(idAnoLetivo) {
            return Programas.ativosPorIdAnoLetivo(idAnoLetivo);
        }

        this.ativosRelatorio = function() {
            return Programas.ativo().$promise;
        };
        
        this.byUfAndMunicipio = function(IdUf, IdMunicipio) {
            return Programas.byUfAndMunicipioWithPermissionFilter({idUf: IdUf, idMunicipio: IdMunicipio});
        };

        this.porIdEscola = function(idEscola) {
            return Programas.porIdEscola({idEscola: idEscola});
        };

        this.save = function(programa) {
            var entity = new Programas(programa);
            
            if (entity.id) {
                return entity.$update();
            } else {
                return entity.$save();
            }
        };

        this.configuracaoProgramas = function() {
            return Programas.configuracaoProgramas();
        }

    }

}());
