/*
jslint
    devel: true, maxerr: 10
*/

/*
global
    required: true
*/

/*
 * Funcionalidade do Sistema Module
 * @author iFactory Solutions Ltda.
 * @version 1.0.0
 * @since 1.0.0
 */
(function() {
    'use strict';

    angular.module('app.funcionalidadeSistema', ['app.core']).config(config);
    config.$inject = ['$stateProvider', '$urlRouterProvider', 'APP_STATE'];

    function config($stateProvider, $urlRouterProvider, APP_STATE) {

    }
})();