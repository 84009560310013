(function() {
    'use strict';

    angular.module('app.core').filter('iasAtributosValue', IasAtributosValue);

    function IasAtributosValue(opcaoService) {
        return function (attr) {
            var dados = attr.dados[0];
            
            if (attr.atributo.tipoAtributo.key == 'L') {
                return dados.linha; 
            } else if (attr.atributo.tipoAtributo.key == 'M') {
                if (attr.atributo.medida.tipoValor.key == 'I' || attr.atributo.medida.tipoValor.key == 'R' 
                || attr.atributo.medida.tipoValor.key == 'M') {
                    return dados.valueNumericResposta;
                } else if (attr.atributo.medida.tipoValor.key == 'T') {
                    return moment(dados.valueDateTimeResposta).format("DD/MM/YYYY HH:mm");
                } else if (attr.atributo.medida.tipoValor.key == 'D') {
                    return moment(dados.valueDateTimeResposta).format("DD/MM/YYYY");
                } else if (attr.atributo.medida.tipoValor.key == 'H') {
                    return dados.valueTimeResposta;
                }
            } else if (attr.atributo.tipoAtributo.key == 'P') {
                return dados.paragrafo;
            } else if (attr.atributo.tipoAtributo.key == 'O') {
                if (is.existy(dados.opcaoItem.escalaItemDTO)) {
                    return dados.opcaoItem.escalaItemDTO.nome;
                } else if (is.existy(dados.opcaoItem.nome)) {
                    if (is.existy(dados.opcaoComplemento)) {
                        return dados.opcaoItem.nome + ' ' + dados.opcaoComplemento; 
                    }

                    return dados.opcaoItem.nome;
                }
            }

            return null;
        };
    }
}());