(function() {
    'use strict';

    angular.module('app.core').directive("iasTipoFormulario", IasTipoFormulario);
    IasTipoFormulario.$inject = [];

    function IasTipoFormulario() {
        return {
            scope: {
                tipo : '=',
                itens: '=',
                ngModel: '=?',
                ngDisabled: '=?',
                formulario: '=formulario'
            },
            restrict: "E",
            templateUrl: "core/directives/formulario/partials/tipo-formulario.html",
            controller: ['$scope', 'Opcao', function IasTipoFormularioController($scope, Opcao){
                $scope.ngModel = $scope.ngModel || {};
                $scope.ngDisabled = is.existy($scope.ngDisabled) ? $scope.ngDisabled : true;
                $scope.formItems = is.existy($scope.itens) ? angular.copy($scope.itens) : [];

                $scope.paginate = function(item) {
                    angular.forEach($scope.formItems, function(value) {
                        value.selected = false;
                        
                        if (value.id == item.id) {
                            item.selected = true;
                        }
                    });
                };

                $scope.descricaoItens = function(item) {
                    if (item.opcao) {
                        var descricoes = _.filter(item.opcao.opcaoItensDTO, function(itemOpcao) {
                            return is.existy(itemOpcao.descricao) && is.not.empty(itemOpcao.descricao);
                        });

                        return descricoes;
                   } else {
                       return null;
                   }
                };

                /*
                    Função utilizada para realizar a correção do
                    item.selected, que está sendo utilizado tanto no momento que o usuário seleciona os itens da lista
                    quanto no momento de gerar a paginação com a devida classe de active quando o atributo 
                    'selected' do item estiver como 'true'
                */
                $scope.ajusteSelectedItens = function(itens){
                    angular.forEach(itens, function(item, k){
                        if(k != 0){
                            item.selected = false;
                        }
                    });
                }
            }]
        };
    }

})();
