/*
jslint
    devel: true, maxerr: 10
*/

/*
global
    required: true
*/

/*
 * Perfil de Acesso Module
 * @author iFactory Solutions Ltda.
 * @version 1.0.0
 * @since 1.0.0
 */
(function() {
    'use strict';

    angular.module('app.perfilAcesso', ['app.core', 'app.funcionalidadeSistema']).config(config);
    config.$inject = ['$stateProvider', '$urlRouterProvider', 'APP_STATE'];

    function config($stateProvider, $urlRouterProvider, APP_STATE) {
        $stateProvider
            .state(APP_STATE.PERFIL_ACESSO.BASE, {
                url: '/perfil-acesso',
                abstract: true,
                views: {
                    'content': {
                        template: '<div ui-view="internal"></div>',
                        controller: 'PerfilAcessoController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state(APP_STATE.PERFIL_ACESSO.SEARCH, {
                url: '/search',
                views: {
                    'internal': {
                        templateUrl: 'perfil-acesso/partials/perfil-acesso-search.tpl.html'
                    }
                },
                data: {
                    permission: "PLA:RE",
                    breadCrumb: [{
                            display: "Segurança",
                            active: false
                        },
                        {
                            display: "Perfis de Acesso",
                            active: true
                        }
                    ],
                    search: {
                        enabled: false
                    }
                }
            })
            .state(APP_STATE.PERFIL_ACESSO.NEW, {
                url: '/new',
                views: {
                    'internal': {
                        templateUrl: 'perfil-acesso/partials/perfil-acesso-view.tpl.html',
                        controller: 'PerfilAcessoViewController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    permission: "PLA:CR",
                    breadCrumb: [{
                            display: "Segurança",
                            active: false
                        },
                        {
                            display: "Perfis de Acesso",
                            active: false,
                            stateName: APP_STATE.PERFIL_ACESSO.SEARCH
                        },
                        {
                            display: "Novo",
                            active: true
                        }
                    ],
                    search: {
                        placeHolder: "Digite o nome do perfil de acesso",
                        enabled: false
                    }
                }
            })
            .state(APP_STATE.PERFIL_ACESSO.EDIT, {
                url: '/edit/:id',
                views: {
                    'internal': {
                        templateUrl: 'perfil-acesso/partials/perfil-acesso-view.tpl.html',
                        controller: 'PerfilAcessoViewController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    permission: "PLA:VI",
                    breadCrumb: [{
                            display: "Segurança",
                            active: false
                        },
                        {
                            display: "Perfis de Acesso",
                            active: false,
                            stateName: APP_STATE.PERFIL_ACESSO.SEARCH
                        },
                        {
                            display: "Edição",
                            active: true
                        }
                    ],
                    search: {
                        placeHolder: "Digite o nome do perfil de acesso",
                        enabled: false
                    }
                }
            });
    }
})();