(function() {
    'use strict';

    angular.module('app.escolas').factory('Escolas', Escolas);
    Escolas.$inject = ['$resource', 'APP_PROPERTY'];

    function Escolas($resource, APP_PROPERTY) {
        return $resource(APP_PROPERTY.URL + '/escolas/:id', { id: '@id' }, {
            update: {
                method: 'PUT'
            },
            get: {
                method: 'GET'
            },
            filter: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/escolas'
            },
            ativo: {
                method: 'GET',
                url: APP_PROPERTY.URL + '/escolas/ATIVO',
                isArray: true
            },
            findByStatus: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/escolas/ATIVO'
            },
            porRegionalMunicipio: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/escolas/ativo?idRegionalMunicipio=:idRegionalMunicipio'
            },
            porMunicipio: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/escolas/ativo?idMunicipio=:idMunicipio'
            },
            parceirasPorMunicipioOrUf: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/escolas/parceiras?idMunicipio=:idMunicipio&idUf=:idUf'
            },
            parceirasPorMunicipio: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/escolas/parceiras?idMunicipio=:idMunicipio'
            },
            getTiposOrganizacao: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/escolas/tipos-organizacao'
            },
            searchBy: {
                method: 'GET',
                isArray: false,
                url: APP_PROPERTY.URL + '/escolas/searchBy'
            },
            searchByWithPermission: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/escolas/searchByWithPermission'
            },
            searchByFilterAndProgramasWithPermission: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/escolas/searchByFilterAndProgramasWithPermission'
            },
            byFilter: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/escolas/by-filter'
            },
            semAssociacao: {
                method: 'GET',
                isArray: false,
                url: APP_PROPERTY.URL + '/escolas/sem-associacao'
            },
            filterAtivo: {
                method: 'GET',
                isArray: false,
                url: APP_PROPERTY.URL + '/escolas/ATIVO'
            },
            delete: {
                method: 'DELETE',
                transformResponse: function(data, headers, statusCode) {
                        var finalRsponse = {
                            data: data,
                            responseStatusCode: statusCode
                        };
                    return finalRsponse;
                },
                url: APP_PROPERTY.URL + '/escolas/:id'
            },
            tiposCalendarios: {
                method: 'GET',
                url: APP_PROPERTY.URL + '/escolas/tipos-calendarios',
                isArray: true
            },
        });

    }

}());