(function() {
    'use strict';

    angular.module('app.professor').factory('Professor', Professor);
    Professor.$inject = ['$resource', 'APP_PROPERTY', 'APP_MIME_TYPE', 'fileService'];

    function Professor($resource, APP_PROPERTY, APP_MIME_TYPE, fileService) {
        return $resource(APP_PROPERTY.URL + '/professores/:id', { id: '@id' }, {
            update: {
                method: 'PUT'
            },
            resetSenha: {
                method: 'PUT',
                url: APP_PROPERTY.URL + '/professores/reset-senha/:id' 
              },
            filter: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/professores'
            },
            byTurma: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/professores/porTurma'
            },
            ProfessorByTurmaId: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URSL + '/professores/professor-por-turma/:idTurma'
            },
            ProfessorByTurmaAndDisciplinaId: {
                method: 'POST',
                isArray: true,
                url: APP_PROPERTY.URL + '/professores/professor-por-turma/:idTurma'
            },
            byEscola: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/professores/porEscola'
            },
            ProfessoresByEscola:{
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/professores/por-escola'
            },
            findByStatus: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/professores/:situacao'
            },
            delete: {
                method: 'DELETE',
                transformResponse: function(data, headers, statusCode) {
                        var finalRsponse = {
                            data: data,
                            responseStatusCode: statusCode
                        };
                    return finalRsponse;
                },
                url: APP_PROPERTY.URL + '/professores/:id'
            },
            existeUsuario:{
                method: 'GET',
                isArray:false,
                url: APP_PROPERTY.URL + '/professores/existeUsuario'
            },
            criarUsuario:{
                method: 'PATCH',
                isArray:false,
                url: APP_PROPERTY.URL + '/professores/criarUsuario'
            },
            exportByUfAndMunicipioAndEscola: {
                method: 'GET',
                cache: false,
                responseType: 'arraybuffer',
                url: APP_PROPERTY.URL + '/professores/exportar/:idAnoLetivo/:idPrograma/:idUf/:idMunicipio/:idEscola/import_template',
                headers: {
                    accept: [APP_MIME_TYPE.JSON, APP_MIME_TYPE.EXCEL].join()
                },
                transformResponse: function (data, headers) {
                    return fileService.transform(data, headers);
                }
            },
            exportByUfAndMunicipio: {
                method: 'GET',
                cache: false,
                responseType: 'arraybuffer',
                url: APP_PROPERTY.URL + '/professores/exportar/:idAnoLetivo/:idPrograma/:idUf/:idMunicipio/import_template',
                headers: {
                    accept: [APP_MIME_TYPE.JSON, APP_MIME_TYPE.EXCEL].join()
                },
                transformResponse: function (data, headers) {
                    return fileService.transform(data, headers);
                }
            },
            exportByUf: {
                method: 'GET',
                cache: false,
                responseType: 'arraybuffer',
                url: APP_PROPERTY.URL + '/professores/exportar/:idAnoLetivo/:idPrograma/:idUf/import_template',
                headers: {
                    accept: [APP_MIME_TYPE.JSON, APP_MIME_TYPE.EXCEL].join()
                },
                transformResponse: function (data, headers) {
                    return fileService.transform(data, headers);
                }
            },
            export: {
                method: 'GET',
                cache: false,
                responseType: 'arraybuffer',
                url: APP_PROPERTY.URL + '/professores/exportar/:idAnoLetivo/:idPrograma/import_template',
                headers: {
                    accept: [APP_MIME_TYPE.JSON, APP_MIME_TYPE.EXCEL].join()
                },
                transformResponse: function (data, headers) {
                    return fileService.transform(data, headers);
                }
            }
        });

    }

}());