(function(){
    'use strict';

    angular.module('app.medida').service('medidaService', MedidaService);
    MedidaService.$inject = ['Medida', 'STATUS_ENUM'];

    function MedidaService(Medida, STATUS_ENUM) {

        this.findActives = function() {
            return Medida.findByStatus({status: STATUS_ENUM.ATIVO}).$promise;
        };
        
        this.delete = function(id) {
            return Medida.delete({id: id}).$promise;
        };

        this.findMedidasPaiExceptId = function(id) {
            return Medida.findMedidasSinteticas({exceptId: id}).$promise;
        }

        this.isUsedInColeta = function(id) {
            return Medida.isUsedInColeta({id: id}).$promise;
        }

    }

}());
