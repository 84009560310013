(function() {
    'use strict';

    angular.module('app.escala').factory('Escala', Escala);
    Escala.$inject = ['$resource', 'APP_PROPERTY'];

    function Escala($resource, APP_PROPERTY) {
        return $resource(APP_PROPERTY.URL + '/escalas/:id', { id: '@id' }, {
            update: {
                method: 'PUT'
            },
            filter: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/escalas'
            },
            findByStatus: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/escalas/:status'
            },
            findItens: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/escalas/:id/itens/:status'
            },
            isUsed: {
                method: 'GET',
                transformResponse: function(data, headers, statusCode) {
                        var finalRsponse = {
                            data: data,
                            responseStatusCode: statusCode
                        };
                    return finalRsponse;
                },
                url: APP_PROPERTY.URL + '/escalas/:id/usada'
            },
            delete: {
                method: 'DELETE',
                transformResponse: function(data, headers, statusCode) {
                        var finalRsponse = {
                            data: data,
                            responseStatusCode: statusCode
                        };
                    return finalRsponse;
                },
                url: APP_PROPERTY.URL + '/escalas/:id'
            }
        });

    }

}());