(function() {
    'use strict';

    angular.module('app.uf').factory('Uf', Uf);
    Uf.$inject = ['$resource', 'APP_PROPERTY'];

    function Uf($resource, APP_PROPERTY) {
        return $resource(APP_PROPERTY.URL + '/ufs/:id', { id: '@id' }, {
            update: {
                method: 'PUT'
            },
            get: {
                method: 'GET'
            },
            filter: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/ufs'
            },
            ativo: {
                method: 'GET',
                url: APP_PROPERTY.URL + '/ufs/ATIVO',
                isArray: true
            },
            withPermissionFilter: {
                method: 'GET',
                url: APP_PROPERTY.URL + '/ufs/with-permission-filter',
                isArray: true
            },
            findByStatus: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/ufs/:status'
            },
            regionais: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/ufs/:id/regionais/ativo'
            },
            delete: {
                method: 'DELETE',
                transformResponse: function(data, headers, statusCode) {
                        var finalRsponse = {
                            data: data,
                            responseStatusCode: statusCode
                        };
                    return finalRsponse;
                },
                url: APP_PROPERTY.URL + '/ufs/:id'
            }
        });

    }

}());