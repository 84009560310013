/*
jslint
    devel: true, maxerr: 10
*/

/*
global
    angular
*/

/*
 *  Config
 *  @author iFactory Solutions Ltda.
 */

(function () {
    'use strict';

    /**
     * App Config
     * @namespace app.config
     */
    angular.module('config', ['ngMask', 'ui.router', 'ui.validate', 'ngMessages', 'ngResource', 'chart.js']).config(config);
    config.$inject = ['$stateProvider', '$urlRouterProvider'];

    function config($stateProvider, $urlRouterProvider) {
      //$urlRouterProvider.otherwise("/login");
      //$urlRouterProvider.otherwise("/home");
    }

}());
