(function(){
    'use strict';

    angular.module('app.contrato').controller('ContratoController', ContratoController);
    ContratoController.$inject = ['APP_STATE', 'APP_EVENT','$state',
        '$scope','$uibModal','dialogService', 'toastService', 'contratoService', 'MULTISELECT_LABELS',
        'SecurityManagerFactory'];

    function ContratoController(APP_STATE, APP_EVENT, $state, $scope, $uibModal, 
        dialogService, toastService, contratoService, MULTISELECT_LABELS, SecurityManagerFactory) {

        var vm = this;

        vm.goNewPage = function() {
            $state.go(APP_STATE.CONTRATO.NEW);
        };
        //
        vm.goEditPage = function(id) {
            $state.go(APP_STATE.CONTRATO.EDIT, { id: id });
        };
        //
        vm.clearSearchValue = function() {
            vm.searchValue = "";
            vm.searchValueModel = "";
        };

        var _populateSearchFields = function() {
            contratoService.findProgramas().then(function(programas) {
                vm.programas = programas;
            });
            contratoService.findEscopos().then(function(escopos) {
                vm.escopos = escopos;
            });
        };

        vm.findEscolasAssociadas = function(id) {
            contratoService.findEscolasAssociadas(id).then(function(escolasAssociadas) {
                vm.escolasAssociadas = escolasAssociadas;

                var escolasAssociadasModal = $uibModal.open({
                    ariaLabelledBy: 'modal-title',
                    ariaDescribedBy: 'modal-body',
                    templateUrl: 'contrato/partials/contrato-search-modal-escolas.html',
                    controllerAs: 'vm',
                    size: 'lg',
                    controller: function($uibModalInstance) {
                        var _vm = this;
                        _vm.escolasAssociadas = [];
                        _vm.modalTitle = id;

                        if (escolasAssociadas) {
                            _vm.escolasAssociadas = angular.copy(escolasAssociadas);
                        }

                        _vm.cancel = function() {
                            $uibModalInstance.dismiss('cancel');
                        };
                    }
                });

                escolasAssociadasModal.result.then(new Function(), new Function());
            });
        }

        /*vm.findProgramasCriterios = function(id) {
            contratoService.findProgramasCriterios(id).then(function(programasCriterios) {
                vm.programasCriterios = programasCriterios;

                var programasCriteriosModal = $uibModal.open({
                    ariaLabelledBy: 'modal-title',
                    ariaDescribedBy: 'modal-body',
                    templateUrl: 'contrato/partials/contrato-search-modal-criterio.html',
                    controllerAs: 'vm',
                    size: 'lg',
                    controller: function($uibModalInstance) {
                        var _vm = this;
                        _vm.programasCriterios = [];
                        _vm.modalTitle = id;

                        if (programasCriterios) {
                            _vm.programasCriterios = angular.copy(programasCriterios);
                        }

                        _vm.cancel = function() {
                            $uibModalInstance.dismiss('cancel');
                        };
                    }
                });

                programasCriteriosModal.result.then(new Function(), new Function());
            });
        }*/

        vm.delete = function(contrato) {
            dialogService.delete().then(function() {
                contratoService.delete({id: contrato.id})
                    .then(function(response) {
                        if (response.responseStatusCode == 200) {
                            toastService.success("Registro removido com sucesso.");
                        } else if (response.responseStatusCode == 202) {
                            toastService.success("Registro inativado com sucesso.");
                        }
                        vm.search();
                    }, function(response) {
                        toastService.error({
                            status: response.status,
                            message: response.data.message
                        });
                    });
            }, function() {});
        };

        vm.search = function() {
            vm.contratos = [];
            vm.contratosTotal = 0;
            vm.searchWasPerformed = false;
            vm.searchValue = vm.searchValueModel || vm.searchValue;

                if (!vm.searchValueModel) {
                    vm.searchValueModel = vm.searchValue;
                }

                if (vm.searchValue) {
                    var filter = {};
                 
                    if (angular.isArray(vm.searchValue)) {
                        filter[vm.searchType.field] = _.map(vm.searchValue, function(value) {
                            return (value.id) ? value.id : value.key; 
                        });
                    } else {
                        filter[vm.searchType.field] = vm.searchValue;
                    }

                    contratoService.filter(filter)
                        .then(function(data) {
                            vm.contratos = angular.copy(data);
                        }, function(response) {
                            toastService.error({
                                status: response.status,
                                message: response.data.message
                            });
                        })
                        .finally(function() {
                            vm.searchWasPerformed = true;
                            vm.contratosTotal = vm.contratos.length;
                            vm.searchValueModel = "";
                            vm.formSearch.$setPristine();
                        });
                }
        };

        vm.SEARCH_BY = {
            CODE: {
                field: "id",
                placeholder: "código",
                mask: "9",
                restrict: "[^0-9]",
                maxLength: 10,
                minLength: 1
            },
            PROGRAMA: {
                field: "idPrograma",
                placeholder: "programa",
                mask: "%"
            },
            ESCOPO: {
                field: "escopo",
                placeholder: "tipo de vinculação",
                mask: "%"
            }
        };

        (function() {
            vm.hasPermissionView = SecurityManagerFactory.hasPermission('PAR:VI');
            vm.contratos = [];
            vm.programas = [];
            vm.escopos = [];
            vm.escolasAssociadas = [];
            vm.programasCriterios = [];
            vm.searchValue = "";
            vm.searchValueModel = "";
            vm.searchType = vm.SEARCH_BY.CODE;
            vm.searchWasPerformed = false;
            vm.MULTISELECT_LABELS = MULTISELECT_LABELS;
            _populateSearchFields();

            $scope.$on(APP_EVENT.CONTRATO.SAVED, function() {
                toastService.success("Operação realizada com sucesso.");
                vm.search();
            });

            $scope.$on(APP_EVENT.CONTRATO.REFRESH, function() {
                vm.search();
            });

        }());
    }

}());
