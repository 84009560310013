(function() {
    'use strict';

    angular.module('app.turmas').controller('TurmasLoteController', TurmasLoteController);
    TurmasLoteController.$inject = ['APP_STATE', 'COMMON_STATUS_LIST', 'SecurityManagerFactory',
        '$state', '$scope', 'toastService', 'ufService', 'municipiosService',
        'DialogService', 'atributoService', 'turmasService', '$filter', 'periodoLetivoService', 'etapaService',
        'escolasService', 'calendarioService', 'programasService', 'rubricasService', 'formularioService'
    ];

    function TurmasLoteController(APP_STATE, COMMON_STATUS_LIST, SecurityManagerFactory,
        $state, $scope, toastService, ufService, municipiosService,
        DialogService, atributoService, turmasService, $filter, periodoLetivoService, etapaService,
        escolasService, calendarioService, programasService, rubricasService, formularioService) {

        var vm = this;

        vm.arrTurmasSelecionadas = [];

        vm.filteredTurmas = [];
        vm.periodosLetivos = periodoLetivoService.ativos();
        vm.rubricasEscolhidas = [];
        vm.rubricas = [];
        vm.formulariosEscolhidas = [];
        vm.formularios = [];
        vm.etapas = etapaService.ativos();
        turmasService.turnos().then(function(turnos) {
            vm.turnos = turnos;
        }, function() {
            vm.turnos = [];
        });



        ufService.withPermissionFilter({}).then(function(response) {
            vm.ufs = response;
        });

        vm.enableEditMode = function() {
            vm.editMode = true;
        };

        vm.getMunicipiosPorUf = function(uf) {
            if (uf) {
                municipiosService.withPermissionFilter({ idUf: uf.id }).then(function(response) {
                    vm.municipios = response;
                });
            } else {
                vm.municipios = [];
            }
        };

        vm.getEscolasPorMunicipio = function(municipio) {
            if (municipio) {
                vm.escolas = escolasService.parceirasPorMunicipio(municipio.id);
            } else {
                vm.escolas = [];
            }
        }


        vm.calendariosEscola = function(escola) {
            if (escola && vm.turma.periodoLetivo && vm.turma.calendarioEscola == 'false') {
                calendarioService.porEscolaIdSemPadrao(escola.id).then(function(response) {
                    vm.calendarios = $filter("filter")(response, function(value) {
                        return value.periodoLetivo.anoLetivo == vm.turma.periodoLetivo.anoLetivo;
                    });
                });
            }
        };

        vm.programasEscola = function(escola) {
            if (is.existy(escola)) {
                programasService.porIdEscola(escola.id).$promise.then(function(response) {
                    vm.programas = response;
                    angular.forEach(vm.programas, function(value, index) {
                        (rubricasService.byPrograma(value.id)).$promise.then(function(response) {
                            angular.forEach(response, function(valueR) {

                                let formExiste = false;
                                angular.forEach(vm.rubricas, function(formularioExistente) {
                                    if (formularioExistente.id == valueR.id) {
                                        formExiste = true;
                                    }
                                });
                                if (!formExiste) {
                                    vm.rubricas.push(valueR);
                                }

                            });
                        });
                    });
                });
            }
        };



        vm.getProgramasPorMunicipio = function(idUf, idMunicipio) {
            //console.log(idUf.id, " ", idMunicipio.id);
            programasService.byUfAndMunicipio(idUf.id, idMunicipio.id).$promise.then(function(response) {
                vm.programasAtivos = response;
                //console.log(vm.programasAtivos);

            });

        };


        // programasService.ativos().$promise.then(function(response) {
        //     vm.programasAtivos = response;
        //     console.log(vm.programasAtivos);

        // });



        vm.formulariosTurma = function(idTurma) {
            formularioService.findByTurma(idTurma).$promise.then(function(response) {
                vm.formulariosEscolhidas = response;
            });
        };


        vm.retornoResultado = false;
        vm.retornoRemovido = false;
        // vm.verListaTurmas = function() {
        //     vm.listaTurmas = true;
        //     //console.log(vm.listaTurmas);

        //     console.log(vm.programa.nome);
        //     (rubricasService.byPrograma(vm.programa.nome.id)).$promise.then(function(response) {
        //         angular.forEach(response, function(value) {
        //             console.log(value);
        //             console.log('-------');

        //             vm.rubricas.push(value);


        //         });
        //         //console.log(vm.rubricas);
        //     });
        // };

        // vm.verRetornoResultado = function() {
        //     vm.retornoResultado = true;

        // };

        // vm.verRetornoRemovido = function() {
        //     vm.retornoRemovido = true;

        // };

        vm.carregaFormularios = function() {
            vm.formularios = [];
            angular.forEach(vm.rubricasEscolhidas, function(rubrica) {

                if (rubrica !== undefined) {
                    formularioService.findByRubrica(rubrica.id).then(function(responseform) {

                        angular.forEach(responseform, function(formulariosresp) {
                            let formExiste = false;
                            angular.forEach(vm.formularios, function(formularioExistente) {
                                if (formularioExistente.id == formulariosresp.id) {
                                    formExiste = true;
                                }
                            });
                            if (!formExiste) {
                                vm.formularios.push(formulariosresp);
                            }
                        })
                        vm.changeItems();
                    });
                }
            });
        }



        vm.changeItems = function() {
            try {
                //console.log(vm.rubricasEscolhidas);

                if (vm.rubricasEscolhidas) {
                    vm.labelsRubricas = {
                        selectAll: "Selecionar todos",
                        unselectAll: "Desmarcar",
                        itemsSelected: "selecionados",
                        search: "Pesquisar",
                        select: vm.rubricasEscolhidas == undefined || vm.rubricasEscolhidas.length == undefined ? "0 Selecionadas" : vm.rubricasEscolhidas.length > 1 ? vm.rubricasEscolhidas.length + " Selecionadas" : vm.rubricasEscolhidas.length + " Selecionada"
                    };
                }
                if (vm.formulariosEscolhidas) {
                    vm.labelsFormularios = {
                        selectAll: "Selecionar todos",
                        unselectAll: "Desmarcar",
                        itemsSelected: "selecionados",
                        search: "Pesquisar",
                        select: vm.formulariosEscolhidas == undefined || vm.formulariosEscolhidas.length == undefined ? "0 Selecionados" : vm.formulariosEscolhidas.length > 1 ? vm.formulariosEscolhidas.length + " Selecionados" : vm.formulariosEscolhidas.length + " Selecionado"
                    };
                }

            } catch (error) {
                console.log(error);
            }
        }

        $scope.$watch('vm.rubricasEscolhidas', function(newValue, oldValue) {
            vm.changeItems();
        });
        $scope.$watch('vm.formulariosEscolhidas', function(newValue, oldValue) {
            vm.changeItems();
        });


        vm.goBack = function() {
            $state.go(APP_STATE.TURMA.SEARCH);
        };

        vm.limparFormulario = function() {
            vm.turmas = [];
            vm.turmasTotal = 0;
            vm.turmas.turmasUf = vm.turma.uf;
            vm.turmas.turmasMunicipio = vm.turma.municipio;
            vm.turmas.turmasEscola = vm.turma.escola;
            vm.turmas.turmasPrograma = vm.programa.nome;
            vm.rubricas = [];
            vm.rubricasEscolhidas = [];
            vm.formulariosEscolhidas = [];
            vm.formularios = [];
            vm.arrTurmasSelecionadas = [];
        }

        vm.search = function() {
            //console.log("search");
            vm.turmas = [];
            vm.turmasTotal = 0;
            vm.turmas.turmasUf = vm.turma.uf;
            vm.turmas.turmasMunicipio = vm.turma.municipio;
            vm.turmas.turmasEscola = vm.turma.escola;
            vm.turmas.turmasPrograma = vm.programa.nome;
            vm.rubricas = [];
            vm.rubricasEscolhidas = [];
            vm.formulariosEscolhidas = [];
            vm.formularios = [];
            //console.log(vm.turmas);
            // vm.searchWasPerformed = false;
            // vm.searchValue = vm.searchValueModel || vm.searchValue;

            // if (!vm.searchValueModel) {
            //     vm.searchValueModel = vm.searchValue;
            // }

            // console.log(vm.searchValue);

            if (vm.turmas) {


                (rubricasService.byPrograma(vm.programa.nome.id)).$promise.then(function(response) {
                    angular.forEach(response, function(value) {
                        console.log(value);
                        console.log('-------');

                        vm.rubricas.push(value);


                    });
                    //console.log(vm.rubricas);
                });

                //console.log(vm.turmas.turmasMunicipio.id);
                turmasService.withPermissionFilter({ idEscola: vm.turmas.turmasEscola.id, idPrograma: vm.turmas.turmasPrograma.id }).then(function(data) {
                        //console.log(data);
                        vm.turmasRetorno = [];

                        angular.forEach(data, function(item, pos) {
                            var obj = {};
                            //console.log(pos);

                            obj.cod = (item.id != undefined ? item.id : '---');
                            obj.inep = (item.codigoINEP != undefined ? item.codigoINEP : '---');
                            obj.turma = (item.nome != undefined ? item.nome : '---');
                            obj.anoLetivo = (item.periodoLetivo.anoLetivo != undefined ? item.periodoLetivo.anoLetivo : '---');
                            obj.escola = (item.escola.nome != undefined ? item.escola.nome : '---');
                            obj.etapa = (item.etapa != undefined ? item.etapa.nome : '---');
                            obj.turno = (item.turno.value != undefined ? item.turno.value : '---');


                            vm.turmasRetorno.push(obj);
                            //console.log(vm.turmasRetorno);

                        });



                    }, function(response) {})
                    .finally(function() {

                        vm.searchWasPerformed = true;
                        vm.turmasRetornoTotal = vm.turmasRetorno.length;
                        vm.formSearch.$setPristine();
                    });

            }
        };



        vm.goEditPage = function(id) {
            $state.go(APP_STATE.TURMA.EDIT, { id: id });
        };

        vm.checkAllTurmas = function() {
            if (vm.selectedAll) {
                vm.selectedAll = false;
            } else {
                vm.selectedAll = true;
            }
            
            angular.forEach(vm.turmasRetorno, function(item) {
                // verificação para evitar erro quando valor é undefined
                if (item.Selected) { 
                    item.Selected = true;
                } else {
                    item.Selected = false;
                }
                
                if (vm.selectedAll == true && item.Selected == false) {
                    item.Selected = true;
                    vm.arrTurmasSelecionadas.push(item);
                } else if (vm.selectedAll == false && item.Selected == true) {
                    var index = vm.arrTurmasSelecionadas.map(function(e) { return e.cod; }).indexOf(item.cod);
                    vm.arrTurmasSelecionadas.splice(index, 1);
                    item.Selected = false;
                }                
            });

        };

        vm.checkTurma = function(item) {
            // console.log(vm.arrTurmasSelecionadas);
            if (item.Selected) {
                vm.arrTurmasSelecionadas.push(item);
            } else {
                var index = vm.arrTurmasSelecionadas.map(function(e) { return e.cod; }).indexOf(item.cod);
                vm.arrTurmasSelecionadas.splice(index, 1);
            }
            // console.log(vm.arrTurmasSelecionadas);
        }


        vm.setAplicarLote = function(turmas, rubricas, forms, tipo) {
            console.log("turmas",turmas);
            console.log("rubricas",rubricas);
            console.log("forms",forms);
            console.log("tipo",tipo);
            console.log("vm.selectedAll",vm.selectedAll)
           vm.tipo = tipo
           var arrTurmas = [];

            for (var i in vm.arrTurmasSelecionadas) {
               var obj = {};
                obj.id = vm.arrTurmasSelecionadas[i].cod;
                arrTurmas.push(obj);
            }

           console.log("arrTurmas",arrTurmas);
           if (tipo === "insere") {
               console.log("ARRTURMAS", arrTurmas);
               turmasService.setCompetenciasLote({ turmas: arrTurmas, rubricas: rubricas, formularios: forms }).then(function(response) {
                   console.log(response.data);
                   vm.retornoResultado = true;

                   //RUBRICAS RETORNO
                   vm.rubricasR = []
                   angular.forEach(rubricas, function(item) {
                       var objR = {}
                       objR.nome = item.nome;
                       vm.rubricasR.push(objR);
                   });

                   //RUBRICAS RETORNO
                   vm.rubricasR = []
                   angular.forEach(rubricas, function(item) {
                       vm.rubricasR.push(item.nome);
                   });

                   //FORMS RETORNO
                   vm.formsR = []
                   angular.forEach(forms, function(item) {
                       vm.formsR.push(item.nome);
                   });



               }).catch(function(e) {
                   console.log('Erro inserir em lote ', e);

               });


           } else if (tipo === "delete") {

               turmasService.deleteCompetenciasLote({ turmas: arrTurmas, rubricas: rubricas, formularios: forms }).then(function(response) {
                   //console.log("ok");
                   vm.retornoRemovido = true;

                   //RUBRICAS RETORNO
                   vm.rubricasR = []
                   angular.forEach(rubricas, function(item) {
                       var objR = {}
                       objR.nome = item.nome;
                       vm.rubricasR.push(objR);
                   });

                   //RUBRICAS RETORNO
                   vm.rubricasR = []
                   angular.forEach(rubricas, function(item) {
                       vm.rubricasR.push(item.nome);
                   });

                   //FORMS RETORNO
                   vm.formsR = []
                   angular.forEach(forms, function(item) {
                       vm.formsR.push(item.nome);
                   });



               }).catch(function(e) {
                   console.log('Erro deletar em lote ', e);

               });

           }


       };
        (function() {
            vm.hasPermissionView = SecurityManagerFactory.hasPermission('TUR:VI');
            vm.editMode = true;
            vm.editPage = true;
            vm.defaultStatus = COMMON_STATUS_LIST[0];
            vm.turmasProgramas = [];
            vm.turmasProgramasIncluidos = [];
            vm.turmasProgramasExcluidos = [];
            
            vm.filter = { situacao: 'ATIVO' };
            
            vm.editPage = true;

            vm.enableEditMode();
            vm.turma = {};
        }());

    }

}());