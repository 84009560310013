(function() {
    'use strict';

    angular.module('app.coletaRubrica.resposta').service('coletaRespostaService', ColetaRespostaService);
    ColetaRespostaService.$inject = ['$q', 'ColetaResposta', 'SITUACAO_COLETA', 'APP_PROPERTY', 
        'fileService', 'HTTP_STATUS_CODE'];

    function ColetaRespostaService($q, ColetaResposta, SITUACAO_COLETA, APP_PROPERTY, 
        fileService, HTTP_STATUS_CODE) {

        var self = this;

        var SITUACAO_ALLOWED_CHANGE = [
            SITUACAO_COLETA.NAO_INICIADA,
            SITUACAO_COLETA.EM_ANDAMENTO,
            SITUACAO_COLETA.EM_PROCESSO_ALTERACAO,
            SITUACAO_COLETA.EM_PROCESSO_PREENCHIMENTO,
            SITUACAO_COLETA.EM_PROCESSO_CONCLUSAO_REABERTURA,
        ];

        var _isAllEmptyData = function(data) {
            var periodKey, granKey, itemKey, granInstance, items;

            for (periodKey in data) {
                granInstance = data[periodKey];

                for (granKey in granInstance) {
                    items = granInstance[granKey];

                    for (itemKey in items) {
                        if (items[itemKey].length > 0) {
                            return false;
                        }
                    }
                }
            }

            return true;
        };

        this.isColetaNaoIniciada = function(situacao) {
            return SITUACAO_COLETA.NAO_INICIADA.key === situacao.key;
        };

        this.viewById = function(id) {
            return ColetaResposta.viewById({ id: id }).$promise;
        };

        this.saveAll = function(resposta, isAnonymous) {
            if (is.existy(resposta.generalConstraints) && is.not.empty(resposta.generalConstraints)) {
                return $q.reject({
                    status: HTTP_STATUS_CODE.PRECONDITION_FAILED,
                    constraints: resposta.generalConstraints
                });
            } else {
                return $q(function(resolve, reject) {
                    var allPromises = [];

                    angular.forEach(resposta.itensColeta, function(itemColeta) {
                        if (!_isAllEmptyData(itemColeta.dados)) {
                            var _id = (isAnonymous) ? resposta.key : resposta.idColetaResposta;
                            allPromises.push(self.save(_id, itemColeta, isAnonymous));
                        }
                    });

                    if (is.not.empty(allPromises)) {
                        $q.allSettled(allPromises).then(function(results) {
                            var error = null;
                            var constraints = null;

                            results.forEach(function(result) {
                                if (result.state === "fulfilled") {
                                    var value = result.value;

                                    self.mergeData(value.itemColeta.dados, value.dados);
                                } else {
                                    var errorMessage;
                                    var reason = result.reason;
                                    var status = reason.status;

                                    switch (status) {
                                        case HTTP_STATUS_CODE.PRECONDITION_FAILED:
                                            constraints = reason.data;
                                            break;
                                        case HTTP_STATUS_CODE.BAD_REQUEST:
                                            self.mergeData(reason.itemColeta.dados, reason.dados);
                                            errorMessage = "Não foi possível salvar a resposta da coleta. Verifique os formulários."
                                            break;
                                        default:
                                            errorMessage = reason.data.message;
                                    }

                                    error = {
                                        status: status,
                                        message: errorMessage,
                                        constraints: constraints
                                    }
                                }
                            });

                            if (is.existy(error)) {
                                reject(error);
                            } else {
                                //TODO Retornar a situação correta da resposta
                                if (self.isColetaNaoIniciada(resposta.situacao)) {
                                    resolve(SITUACAO_COLETA.EM_ANDAMENTO);
                                } else {
                                    resolve(resposta.situacao);
                                }
                            }
                        });
                    }
                });
            }
        };

        this.conclude = function(id) {
            return ColetaResposta.conclude({ id: id }).$promise;
        };

        this.save = function(id, itemColeta, isAnonymous) {
            return $q(function(resolve, reject) {
                var resource;

                if (isAnonymous) {
                    resource = ColetaResposta.updateAnonymous({ id: id }, itemColeta.dados);
                } else {
                    resource = ColetaResposta.update({ id: id }, itemColeta.dados);
                }

                resource.$promise
                    .then(function(dados) {
                        resolve({
                            itemColeta: itemColeta,
                            dados: dados
                        });
                    })
                    .catch(function(response) {
                        response.itemColeta = itemColeta;
                        reject(response);
                    });
            });
        };

        this.findByColetaIdAndFilters = function(id, filters) {
            var filter = angular.copy(filters);
            filter.id = id;

            return ColetaResposta.findByColetaId(filter).$promise;
        };

        this.findStatus = function() {
            return $q(function(resolve, reject) {
                ColetaResposta.findStatus().$promise
                    .then(function(data) {
                        var situacoes = _.map(data, function(item) {
                            return {
                                key: item.id,
                                value: item.name
                            };
                        });

                        resolve(situacoes);
                    }, function(error) {
                        reject(error);
                    });
            });
        };

        this.mergeData = function(destination, source) {
            delete source.$promise;
            delete source.$resolved;

            angular.forEach(source, function(periodInstance, periodKey) {
                angular.forEach(periodInstance, function(granInstance, granKey) {
                    angular.forEach(granInstance, function(formItems, formItemKey) {
                        if (is.empty(formItems)) {
                            destination[periodKey][granKey][formItemKey] = [];
                        } else {
                            destination[periodKey][granKey][formItemKey] = formItems;
                        }
                    });
                });
            });
        };

        this.export = function(idColetaResposta) {
             return $q(function(resolve, reject) {
                ColetaResposta.export({ id: idColetaResposta }).$promise
                    .then(function(file) {
                        fileService.download(file);
                        resolve();
                    })
                    .catch(function(error) {
                        reject(error);
                    });
            });
        };

        this.allowsChange = function(situacao) {
            var allowedChange = SITUACAO_ALLOWED_CHANGE.map(function(sit) {
                return sit.key;
            });

            return is.inArray(situacao.key, allowedChange);
        };

        this.getSituacoesAllowImport = function() {
            var allowImport = SITUACAO_ALLOWED_CHANGE.map(function(sit) {
                return sit.value;
            });

            return allowImport;
        };

        this.canImport = function(situacao) {
            return this.allowsChange(situacao);
        };

        this.import = function(idColetaResposta, file) {
            return $q(function(resolve, reject) {
                var url = APP_PROPERTY.URL + '/coletas/respostas/' + idColetaResposta + '/arquivo';

                fileService.upload(url, file)
                    .then(function(response) {
                        resolve(response);
                    })
                    .catch(function(error) {
                        var customError = {};

                        switch (error.status) {
                            case HTTP_STATUS_CODE.PRECONDITION_FAILED:
                                customError.messages = error.data;
                                break;
                            case HTTP_STATUS_CODE.BAD_REQUEST:
                                customError.formConstraints = error.data;
                                break;
                            default:
                                customError.messages = [error.data.message];
                        }

                        reject(customError);
                    });
            });
        };

        this.saveAuthenticated = function(resposta) {
            return this.saveAll(resposta, false);
        };

        this.viewAnonymous = function(id) {
            return ColetaResposta.viewAnonymous({ id: id }).$promise;
        };

        this.saveAnonymous = function(resposta) {
            return this.saveAll(resposta, true);
        };

        this.canConclude = function(situacao) {
            if (self.isColetaNaoIniciada(situacao)) {
                return false;
            }

            return this.allowsChange(situacao);
        };

        this.concludeAnonymous = function(id) {
            return ColetaResposta.concludeAnonymous({ id: id }).$promise;
        };
    }

}());