(function() {
    'use strict';

    angular.module('app.coleta').constant('COLETA_SEARCH_BY', {
        CODE: {
            field: "id",
            placeholder: "código",
            mask: "9",
            restrict: "[^0-9]",
            maxLength: 10,
            minLength: 1
        },
        NAME: {
            field: "nome",
            placeholder: "nome",
            mask: "*",
            restrict: "%",
            maxLength: 50,
            minLength: 3
        },
        TYPE: {
            field: "tipo",
            placeholder: "tipo"
        },
        SCOPE_TYPE: {
            field: "abrangencia",
            placeholder: "tipo de abrangência"
        },
        ACCESS_TYPE: {
            field: "acesso",
            placeholder: "tipo de acesso"
        },
        STATUS: {
            field: "situacao",
            placeholder: "situação"
        },
        GRANULARITY: {
            field: "granularidade",
            placeholder: "granularidade"
        },
        PERIODICITY: {
            field: "periodicidade",
            placeholder: "periodicidade"
        }
    });

    angular.module('app.coleta').constant('COLETA_ANONIMA_DEFAULT_FIELDS', {
        TIPO_ABRANGENCIA: { key: 'P', value: 'Padrão' },
        GRANULARIDADE: { key: 'N', value: 'Anônimo' },
        TIPO_COLETA: { key: 'E', value: 'Após eventos' }
    });

}());
