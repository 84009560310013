(function(){
    'use strict';

    angular.module('app.dependenciaAdministrativa', ['app.core']).config(config);
    config.$inject = ['$stateProvider', 'APP_STATE'];

    function config($stateProvider, APP_STATE) {
        
    }
}());
