(function() {
    'use strict';

    angular.module('app.municipios', ['app.core']).config(config);
    config.$inject = ['$stateProvider', 'APP_STATE'];

    function config($stateProvider, APP_STATE) {
        $stateProvider.state(APP_STATE.MUNICIPIOS.BASE, {
                url: '/municipios',
                abstract: true,
                views: {
                    'content': {
                        template: '<div ui-view="internal"></div>',
                        controller: 'MunicipiosController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state(APP_STATE.MUNICIPIOS.SEARCH, {
                url: '/search',
                views: {
                    'internal': {
                        templateUrl: 'municipios/partials/municipios-search.html'
                    }
                },
                data: {
                    permission: "MUN:RE",
                    breadCrumb: [{
                            display: "Cadastros",
                            active: false
                        },
                        {
                            display: "Municípios",
                            active: true
                        }
                    ],
                    search: {
                        enabled: false
                    }
                }
            })
            .state(APP_STATE.MUNICIPIOS.NEW, {
                url: '/new',
                views: {
                    'internal': {
                        templateUrl: 'municipios/partials/municipios-form.html',
                        controller: 'MunicipiosFormController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    permission: "MUN:CR",
                    breadCrumb: [{
                            display: "Cadastros",
                            active: false
                        },
                        {
                            display: "Municípios",
                            active: false,
                            stateName: APP_STATE.MUNICIPIOS.SEARCH
                        },
                        {
                            display: "Novo",
                            active: true
                        }
                    ],
                    search: {
                        placeHolder: "Digite o nome do Município",
                        enabled: false
                    }
                }
            })
            .state(APP_STATE.MUNICIPIOS.EDIT, {
                url: '/edit/:id',
                views: {
                    'internal': {
                        templateUrl: 'municipios/partials/municipios-form.html',
                        controller: 'MunicipiosFormController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    permission: "MUN:VI",
                    breadCrumb: [{
                            display: "Cadastros",
                            active: false
                        },
                        {
                            display: "Municípios",
                            active: false,
                            stateName: APP_STATE.MUNICIPIOS.SEARCH
                        },
                        {
                            display: "Edição",
                            active: true
                        }
                    ],
                    search: {
                        placeHolder: "Digite o nome da Município",
                        enabled: false
                    }
                }
            });
    }
}());