(function() {
    'use strict';

    angular.module('app.dependenciaAdministrativa').factory('DependenciaAdministrativa', DependenciaAdministrativa);
    DependenciaAdministrativa.$inject = ['$resource', 'APP_PROPERTY'];

    function DependenciaAdministrativa($resource, APP_PROPERTY) {
        return $resource(APP_PROPERTY.URL + '/dependencia-administrativa/:id', { id: '@id' }, {
            update: {
                method: 'PUT' 
            },
            get: {
                method: 'GET'
            },
            filter: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/dependencia-administrativa'
            },
            ativo: {
                method: 'GET',
                url: APP_PROPERTY.URL + '/dependencia-administrativa/ATIVO',
                isArray: true
            }
        });

    }

}());