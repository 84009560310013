(function(){
    'use strict';

    angular.module('app.formulario').controller('FormularioController', FormularioController);
    FormularioController.$inject = ['APP_STATE', 'APP_EVENT', '$scope', '$state', 'Formulario',
        '$document', 'dialogService', 'MULTISELECT_LABELS', 'toastService', 'SecurityManagerFactory'];

    function FormularioController(APP_STATE, APP_EVENT, $scope, $state, Formulario,
        $document, dialogService, MULTISELECT_LABELS, toastService, SecurityManagerFactory) {

        var vm = this;

        vm.SEARCH_BY = {
            CODE: {
                field: "id",
                placeholder: "código",
                mask: "9",
                restrict: "[^0-9]",
                maxLength: 10,
                minLength: 1
            },
            NAME: {
                field: "nome",
                placeholder: "nome",
                mask: "*",
                restrict: "%",
                maxLength: 50,
                minLength: 3
            },
            TYPE: {
                field: "tipo",
                placeholder: "tipo de exibição",
                mask: "%"
            },
            GRANULARITY: {
                field: "granularidade",
                placeholder: "granularidade",
                mask: "%"
            },
            PERIODICITY: {
                field: "periodicidade",
                placeholder: "periodicidade",
                mask: "%"
            }
        };

        vm.formularios = [];
        vm.searchValue = "";
        vm.searchType = vm.SEARCH_BY.NAME;

        vm.nomeSearch = '';

        vm.periodicidades = Formulario.periodicidades();
        vm.tipos = Formulario.tipos();
        vm.granularidades = Formulario.granularidades();

        vm.modernBrowsers = [];
        vm.periodicidades.$promise.then(function(data) {
            angular.forEach(data, function(value, key) {
                vm.modernBrowsers.push({name: value.name, ticked: false});
            });
        });

        // vm.modernBrowsers = [
        //     { name: "Opera",              maker: "(Opera Software)",        ticked: true  },
        //     { name: "Internet Explorer",  maker: "(Microsoft)",             ticked: false },
        //     { name: "Firefox",            maker: "(Mozilla Foundation)",    ticked: true  },
        //     { name: "Safari",             maker: "(Apple)",                 ticked: false },
        //     { name: "Chrome",             maker: "(Google)",                ticked: true  }
        // ];

        vm.goNewPage = function() {
            $state.go(APP_STATE.FORMULARIO.NEW);
        };

        vm.goEditPage = function(id) {
            $state.go(APP_STATE.FORMULARIO.EDIT, { id: id });
        };

        vm.delete = function(formulario) {
            dialogService.delete().then(function() {
                Formulario.delete({id: formulario.id}).$promise
                    .then(function(response) {
                        if (response.responseStatusCode == 200) {
                            toastService.success("Registro removido com sucesso.");
                        } else if (response.responseStatusCode == 202) {
                            toastService.success("Registro inativado com sucesso.");
                        }
                        vm.search();
                    }, function(response) {
                        toastService.error({
                            message: "O Formulário está sendo usado e não pode ser excluído."
                        });
                    });
            }, function() {});
        };

        vm.clearSearchValue = function() {
            vm.searchValue = "";
            vm.invalid = false;
            vm.valueSelected = [];
        };

        vm.search = function() {
            vm.formularios = [];
            vm.searchWasPerformed = false;

            if (vm.searchValue) {
                var filter = {};

                if (angular.isArray(vm.searchValue)) {
                    filter[vm.searchType.field] = _.map(vm.searchValue, function(value) {
                        return value.id;
                    });
                } else {
                    filter[vm.searchType.field] = vm.searchValue;
                }


                filter.page = 0;
                filter.size = 10;

                vm.filterFormularioToPaginate = angular.copy(filter);

                _findFormulariosByFilters(filter);
            } else {
                vm.invalid = true;
            }
        };



        vm.onPageChangeFormularios = function (page, size) {
            vm.filterFormularioToPaginate.page = page;
            vm.filterFormularioToPaginate.size = size;
            _findFormulariosByFilters(vm.filterFormularioToPaginate);
        };
    

        var _findFormulariosByFilters = function (filter) {

            Formulario.filterPageable(filter).$promise.then(function(data) {
                vm.formularios = angular.copy(data.content);
                vm.formulariosTotal = data.totalElements;
            }, function(error) {
                vm.formularios = [];
                vm.formulariosTotal = 0;
            })
            .finally(function() {
                vm.searchWasPerformed = true;
                vm.formSearch.$setPristine();
            });
        };


        $scope.$on(APP_EVENT.FORMULARIO.REFRESH, function() {
            vm.search();
        });

        (function() {
            vm.hasPermissionView = SecurityManagerFactory.hasPermission('FOM:VI');
            vm.tipos = Formulario.tipos();
            vm.valueSelected = [];
            vm.invalid = false;
            vm.MULTISELECT_LABELS = MULTISELECT_LABELS;
            vm.searchWasPerformed = false;
            vm.filterFormularioToPaginate = {};
        })();

    }

}());
