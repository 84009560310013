/*
jslint
    devel: true, maxerr: 10
*/

/*
global
    required: true
*/

/*
 * Perfil de Acesso Resource
 * @module Perfil de Acesso
 * @author iFactory Solutions Ltda.
 * @version 1.0.0
 * @since 1.0.0
 */
(function() {
    'use strict';

    angular.module('app.perfilAcesso').factory('PerfilAcessoResource', PerfilAcessoResource);
    PerfilAcessoResource.$inject = ['$resource', 'APP_PROPERTY'];

    function PerfilAcessoResource($resource, APP_PROPERTY) {
        return $resource(APP_PROPERTY.URL + '/perfis-acesso/:id', { id: '@id' }, {
            update: {
                method: 'PUT'
            },
            get: {
                method: 'GET'
            },
            search: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/perfis-acesso'
            },
            active: {
                method: 'GET',
                url: APP_PROPERTY.URL + '/perfis-acesso/ATIVO',
                isArray: true
            },
            permissionsUserCurrent: {
                method: 'GET',
                url: APP_PROPERTY.URL + '/perfis-acesso/permissions/user-current',
                isArray: true
            },
            findByStatus: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/perfis-acesso/:status'
            },
            findAssignedUsers: {
                method: 'GET',
                url: APP_PROPERTY.URL + '/perfis-acesso/:id/users',
                isArray: true
            },
            findAssignedFeatures: {
                method: 'GET',
                url: APP_PROPERTY.URL + '/perfis-acesso/:id/features',
                isArray: true
            },
            getForView: {
                method: 'GET',
                url: APP_PROPERTY.URL + '/perfis-acesso/:id/view',
                isArray: false
            },
            findAllFeaturesByStatus: {
                method: 'GET',
                url: APP_PROPERTY.URL + '/perfis-acesso/features/:status',
                isArray: true
            },
            delete: {
                method: 'DELETE',
                transformResponse: function(data, headers, statusCode) {
                        var finalRsponse = {
                            data: data,
                            responseStatusCode: statusCode
                        };
                    return finalRsponse;
                },
                url: APP_PROPERTY.URL + '/perfis-acesso/:id'
            }
        });
    }

}());