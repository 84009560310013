(function() {
    'use strict';

    angular.module('app.core').directive("iasNoDirty", IasNoDirty);

    function IasNoDirty() {
        return {
            require: 'ngModel',
            restrict: "A",
            link: function(scope, element, attrs, controller) {
                controller.$setDirty = angular.noop;
            }
        };
    }

})();