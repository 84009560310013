(function() {
    'use strict';

    angular.module('app.medida').controller('MedidaController', MedidaController);
    MedidaController.$inject = [
        'APP_STATE', 'APP_EVENT', '$scope', '$state', 'Medida',
        '$document', 'dialogService', 'medidaService', 'toastService', 'MULTISELECT_LABELS',
        'SecurityManagerFactory'
    ];

    function MedidaController(APP_STATE, APP_EVENT, $scope, $state, Medida,
        $document, dialogService, medidaService, toastService, MULTISELECT_LABELS,
        SecurityManagerFactory) {

        var vm = this;

        vm.SEARCH_BY = {
            CODE: {
                field: "id",
                placeholder: "código",
                mask: "9",
                restrict: "[^0-9]",
                maxLength: 10,
                minLength: 1
            },
            NAME: {
                field: "nome",
                placeholder: "nome",
                mask: "*",
                restrict: "%",
                maxLength: 50,
                minLength: 3
            },
            TYPE: {
                field: "tipoValor",
                placeholder: "tipo valor",
                mask: "*",
                maxlength: "100"

            },
            SCOPE: {
                field: "tipo",
                placeholder: "tipo espoco",
                mask: "*",
                maxlength: "100"

            }

        };

        vm.itens = [];
        vm.itensTotal = vm.itens.length;
        vm.searchValue = '';
        vm.searchValueModel = '';
        vm.tamCampo = null;
        vm.searchType = vm.SEARCH_BY.NAME;
        vm.valueSelected = [];
        vm.MULTISELECT_LABELS = MULTISELECT_LABELS;

        vm.clearSearchValue = function() {
            vm.searchValue = '';
            vm.tamCampo = null;
        };

        vm.delete = function(medida) {
            dialogService.delete().then(function() {
                medidaService.delete(medida.id)
                    .then(function(response) {
                        if (response.responseStatusCode == 200) {
                            toastService.success("Registro removido com sucesso.");
                        } else if (response.responseStatusCode == 202) {
                            toastService.success("Registro inativado com sucesso.");
                        }
                        vm.search();
                    }, function(response) {
                        toastService.error({
                            status: response.status,
                            message: response.data.message
                        });
                    });
            }, function() {});
        };

        vm.goNewPage = function() {
            $state.go(APP_STATE.MEDIDA.NEW);

        };

        vm.goEditPage = function(id) {
            $state.go(APP_STATE.MEDIDA.EDIT, { id: id });
        };

        vm.typesScope = Medida.listasMedidas({ id: "escopos" });

        vm.typesValue = Medida.listasMedidas({ id: "valores" });

        vm.search = function() {
            var filter = {};
            vm.itens = [];
            vm.itensTotal = vm.itens.length;
            filter[vm.searchType.field] = vm.searchValue;

            if (vm.searchValue) {
                vm.currentPage = 1;

                if (angular.isArray(vm.searchValue)) {
                    filter[vm.searchType.field] = _.map(vm.searchValue, function(value) {
                        return (value.id) ? value.id : value.key;
                    });
                } else {
                    filter[vm.searchType.field] = vm.searchValue;
                }

                if (vm.searchType.field == "nome") {
                    Medida.listas(filter).$promise
                        .then(function(data) {
                            vm.itens = angular.copy(data);
                            vm.tamCampo = vm.itens.length;
                            // vm.itensTotal = vm.itens.length;
                        }, function(error) {
                            vm.itens = [];
                            vm.tamCampo = 0;
                        })
                        .finally(function() {
                            vm.tamCampo = vm.itens.length;
                            vm.itensTotal = vm.itens.length;
                            vm.formSearch.$setPristine();
                        });
                } else if (vm.searchType.field == "id") {
                    Medida.listas(filter).$promise
                        .then(function(data) {
                            vm.itens = angular.copy(data);
                            vm.tamCampo = vm.itens.length;
                        }, function(error) {
                            vm.itens = [];
                            vm.tamCampo = 0;
                        })
                        .finally(function() {
                            vm.tamCampo = vm.itens.length;
                            vm.itensTotal = vm.itens.length;
                            vm.formSearch.$setPristine();
                        });
                } else if (vm.searchType.field == "tipo") {
                    Medida.listas(filter).$promise
                        .then(function(data) {
                            vm.itens = angular.copy(data);
                            vm.tamCampo = vm.itens.length;
                        }, function(error) {
                            vm.itens = [];
                            vm.tamCampo = 0;
                        })
                        .finally(function() {
                            vm.tamCampo = vm.itens.length;
                            vm.itensTotal = vm.itens.length;
                            vm.formSearch.$setPristine();
                        });
                } else if (vm.searchType.field == "tipoValor") {
                    Medida.listas(filter).$promise
                        .then(function(data) {
                            vm.itens = angular.copy(data);
                            vm.tamCampo = vm.itens.length;
                        }, function(error) {
                            vm.itens = [];
                            vm.tamCampo = 0;
                        })
                        .finally(function() {
                            vm.tamCampo = vm.itens.length;
                            vm.itensTotal = vm.itens.length;
                            vm.formSearch.$setPristine();
                        });
                }

            } else {
                //TODO Lista completa
                vm.itens = [];
                vm.itensTotal = vm.itens.length;
            }

        };

        (function() {
            vm.hasPermissionView = SecurityManagerFactory.hasPermission('MED:VI');
            $scope.$on(APP_EVENT.MEDIDA.SAVED, function() {
                vm.search();
            });

            $scope.$on(APP_EVENT.MEDIDA.REFRESH, function() {
                vm.search();
            });
        })();

    }

})();