(function(){
    'use strict';

    angular.module('app.disciplinas').service('disciplinasService', DisciplinasService);
    DisciplinasService.$inject = ['Disciplinas', 'STATUS_ENUM'];

    function DisciplinasService(Disciplinas, STATUS_ENUM) {

        this.filter = function(filter) {
            return Disciplinas.filter(filter).$promise;            
        };

        this.get = function(id) {
            return Disciplinas.get({ id: id }).$promise;
        };

        this.delete = function(disciplina) {
            return Disciplinas.delete({id: disciplina.id}).$promise;
        };

        this.findByTurma = function(idTurma){
            return Disciplinas.findByTurma({idTurma: idTurma});             
        }

        this.ativos = function() {
            return Disciplinas.ativo();
        };

        this.ativosRelatorio = function() {
            return Disciplinas.ativo().$promise;
        };
        
        this.byUfAndMunicipio = function(IdUf, IdMunicipio) {
            return Disciplinas.byUfAndMunicipioWithPermissionFilter({idUf: IdUf, idMunicipio: IdMunicipio});
        };

        this.porIdEscola = function(idEscola) {
            return Disciplinas.porIdEscola({idEscola: idEscola});
        };

        this.findByAreaConhecimento = function(id) {
            return Disciplinas.findByAreaConhecimento({id: id});
        };

        this.save = function(disciplina) {
            var entity = new Disciplinas(disciplina);
            
            if (entity.id) {
                return entity.$update();
            } else {
                return entity.$save();
            }
        };

    }

}());
