/*
jslint
    devel: true, maxerr: 10
*/

/*
global
    required: true
*/

/*
 * Relatórios Module
 * @module constants for final result report
 * @author iFactory Solutions Ltda.
 * @version 1.0.0
 * @since 1.0.0
 */
(function() {
    'use strict';

    angular.module('app.relatorios').constant('RESULTADO_FINAL_REPORT_FORMULA_VITORIA_FIELDS', [

        { name: 'id', type: 'integer', calculateTotal: false, showFooter: false },
        { name: 'detalhamento', type: 'string', calculateTotal: false, showFooter: false },

        { name: 'numeroAlunosTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'numeroAlunosAprovadosTotal', type: 'integer', calculateTotal: true, calculateType: 'sum'  },
        { name: 'numeroAlunosAprovadosPercentual', percent: "true", referenceField: 'numeroAlunosAprovadosTotal', 
            referenceTotalField: 'numeroAlunosTotal', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'numeroAlunosReprovadosDesempenhoTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'numeroAlunosReprovadosDesempenhoPercentual', percent: "true",  referenceField: 'numeroAlunosReprovadosDesempenhoTotal', 
            referenceTotalField: 'numeroAlunosTotal', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'numeroAlunosReprovadosFaltasTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'numeroAlunosReprovadosFaltasPercentual', percent: "true",  referenceField: 'numeroAlunosReprovadosFaltasTotal', 
            referenceTotalField: 'numeroAlunosTotal', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'numeroAlunosReprovadosTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'numeroAlunosReprovadosPercentual', percent: "true", referenceField: 'numeroAlunosReprovadosTotal', 
            referenceTotalField: 'numeroAlunosTotal', type: 'integer', calculateTotal: true, calculateType: 'average' },

        { name: 'superacaoPlenaDificuldadesTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'superacaoPlenaDificuldadesPercentual', percent: "true", referenceField: 'superacaoPlenaDificuldadesTotal', 
            referenceTotalField: 'numeroAlunosTotal', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'superacaoParcialDificuldadesTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'superacaoParcialDificuldadesPercentual', percent: "true", referenceField: 'superacaoParcialDificuldadesTotal', 
            referenceTotalField: 'numeroAlunosTotal', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'naoSuperacaoDificuldadesTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'naoSuperacaoDificuldadesPercentual', percent: "true", referenceField: 'naoSuperacaoDificuldadesTotal', 
            referenceTotalField: 'numeroAlunosTotal', type: 'integer', calculateTotal: true, calculateType: 'average' },

    ]);

    angular.module('app.relatorios').constant('RESULTADO_FINAL_REPORT_ALFABETIZACAO_ACELERACAO_SE_LIGA_MODELO_1_FIELDS', [
        { name: 'detalhamento', type: 'string', calculateTotal: false, showFooter: false },

        { name: 'numeroAlunosConcluentes', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'mesmaSerieTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'mesmaSeriePercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'mesmaSerieTotal', referenceTotalField: 'numeroAlunosConcluentes' },
        { name: 'mesmaSerieAlfabetizados', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'mesmaSerieAlfabetizadosPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'mesmaSerieAlfabetizados', referenceTotalField: 'numeroAlunosConcluentes' },
        { name: 'mesmaSerieNaoAlfabetizados', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'mesmaSerieNaoAlfabetizadosPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'mesmaSerieNaoAlfabetizados', referenceTotalField: 'numeroAlunosConcluentes' },
        { name: 'serieSeguinteTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'serieSeguintePercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'serieSeguinteTotal', referenceTotalField: 'numeroAlunosConcluentes' },
        { name: 'salto1SerieTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'salto1SeriePercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'salto1SerieTotal', referenceTotalField: 'numeroAlunosConcluentes' },
        { name: 'salto2SerieTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'salto2SeriePercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'salto2SerieTotal', referenceTotalField: 'numeroAlunosConcluentes' },
        { name: 'salto3SerieTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'salto3SeriePercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'salto3SerieTotal', referenceTotalField: 'numeroAlunosConcluentes' },
        { name: 'salto4SerieTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'salto4SeriePercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'salto4SerieTotal', referenceTotalField: 'numeroAlunosConcluentes' },
        { name: 'totalSeriesConcluidas', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'mediaSeries', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'totalSeriesConcluidas', referenceTotalField: 'numeroAlunosConcluentes' },
        { name: 'totalSaltosRealizados', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'mediaSaltos', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'totalSaltosRealizados', referenceTotalField: 'numeroAlunosConcluentes' },
        { name: 'alunosSaltaramTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'alunosSaltaramPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'alunosSaltaramTotal', referenceTotalField: 'numeroAlunosConcluentes' },
        { name: 'alunosPromovidosTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'alunosPromovidosPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'alunosPromovidosTotal', referenceTotalField: 'numeroAlunosConcluentes' },
        { name: 'alunosAlfabetizadosTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'alunosAlfabetizadosPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'alunosAlfabetizadosTotal', referenceTotalField: 'numeroAlunosConcluentes' },
    ]);

    angular.module('app.relatorios').constant('RESULTADO_FINAL_REPORT_GESTAO_DA_ALFABETIZACAO_FIELDS', [
        { name: 'detalhamento', type: 'string', calculateTotal: false, showFooter: false },

        { name: 'numeroAlunosConcluentes', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'mesmaSerieTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'mesmaSeriePercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'mesmaSerieTotal', referenceTotalField: 'numeroAlunosConcluentes' },
        { name: 'mesmaSerieAlfabetizados', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'mesmaSerieAlfabetizadosPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'mesmaSerieAlfabetizados', referenceTotalField: 'numeroAlunosConcluentes' },
        { name: 'mesmaSerieNaoAlfabetizados', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'mesmaSerieNaoAlfabetizadosPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'mesmaSerieNaoAlfabetizados', referenceTotalField: 'numeroAlunosConcluentes' },
        { name: 'serieSeguinteTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'serieSeguintePercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'serieSeguinteTotal', referenceTotalField: 'numeroAlunosConcluentes' },
        { name: 'salto1SerieTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'salto1SeriePercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'salto1SerieTotal', referenceTotalField: 'numeroAlunosConcluentes' },
        { name: 'salto2SerieTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'salto2SeriePercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'salto2SerieTotal', referenceTotalField: 'numeroAlunosConcluentes' },
        { name: 'salto3SerieTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'salto3SeriePercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'salto3SerieTotal', referenceTotalField: 'numeroAlunosConcluentes' },
        { name: 'salto4SerieTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'salto4SeriePercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'salto4SerieTotal', referenceTotalField: 'numeroAlunosConcluentes' },
        { name: 'totalSeriesConcluidas', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'mediaSeries', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'totalSeriesConcluidas', referenceTotalField: 'numeroAlunosConcluentes' },
        { name: 'totalSaltosRealizados', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'mediaSaltos', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'totalSaltosRealizados', referenceTotalField: 'numeroAlunosConcluentes' },
        { name: 'alunosSaltaramTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'alunosSaltaramPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'alunosSaltaramTotal', referenceTotalField: 'numeroAlunosConcluentes' },
        { name: 'alunosPromovidosTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'alunosPromovidosPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'alunosPromovidosTotal', referenceTotalField: 'numeroAlunosConcluentes' },
        { name: 'alunosNaoAlfabetizadosTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'alunosNaoAlfabetizadosPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'alunosNaoAlfabetizadosTotal', referenceTotalField: 'numeroAlunosConcluentes' },
    ]);
    
    angular.module('app.relatorios').constant('RESULTADO_FINAL_REPORT_ALFABETIZACAO_ACELERACAO_SE_LIGA_MODELO_2_FIELDS', [
        { name: 'detalhamento', type: 'string', calculateTotal: false, showFooter: false },

        { name: 'anoOrigemSerie', type: 'integer', showFooter: true, calculateTotal: false, calculateType: 'sum' },
        { name: 'anoOrigemTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano2Total', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano2Percentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'ano2Total', referenceTotalField: 'anoOrigemTotal' },
        { name: 'ano3Total', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano3Percentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'ano3Total', referenceTotalField: 'anoOrigemTotal' },
        { name: 'ano4Total', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano4Percentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'ano4Total', referenceTotalField: 'anoOrigemTotal' },
        { name: 'ano5Total', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano5Percentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'ano5Total', referenceTotalField: 'anoOrigemTotal' },
        { name: 'ano6Total', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano6Percentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'ano6Total', referenceTotalField: 'anoOrigemTotal' },
        { name: 'ano7Total', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano7Percentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'ano7Total', referenceTotalField: 'anoOrigemTotal' },
    ]);
    
    angular.module('app.relatorios').constant('RESULTADO_FINAL_REPORT_ALFABETIZACAO_ACELERACAO_ACELERA_BRASIL_MODELO_1_FIELDS', [
        { name: 'detalhamento', type: 'string', calculateTotal: false, showFooter: false },

        { name: 'numeroAlunosConcluintes', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'mesmaSerieTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'mesmaSeriePercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'mesmaSerieTotal', referenceTotalField: 'numeroAlunosConcluintes' },
        { name: 'serieSeguinteTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'serieSeguintePercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'serieSeguinteTotal', referenceTotalField: 'numeroAlunosConcluintes' },
        { name: 'salto1SerieTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'salto1SeriePercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'salto1SerieTotal', referenceTotalField: 'numeroAlunosConcluintes' },
        { name: 'salto2SerieTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'salto2SeriePercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'salto2SerieTotal', referenceTotalField: 'numeroAlunosConcluintes' },
        { name: 'salto3SerieTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'salto3SeriePercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'salto3SerieTotal', referenceTotalField: 'numeroAlunosConcluintes' },
        { name: 'salto4SerieTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'salto4SeriePercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'salto4SerieTotal', referenceTotalField: 'numeroAlunosConcluintes' },
        { name: 'totalSeriesConcluidas', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'mediaSeries', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'totalSeriesConcluidas', referenceTotalField: 'numeroAlunosConcluintes' },
        { name: 'totalSaltosRealizados', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'mediaSaltos', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'totalSaltosRealizados', referenceTotalField: 'numeroAlunosConcluintes' },
        { name: 'alunosSaltaramTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'alunosSaltaramPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'alunosSaltaramTotal', referenceTotalField: 'numeroAlunosConcluintes' },
        { name: 'alunosPromovidosTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'alunosPromovidosPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'alunosPromovidosTotal', referenceTotalField: 'numeroAlunosConcluintes' },
    ]);

    angular.module('app.relatorios').constant('RESULTADO_FINAL_REPORT_ALFABETIZACAO_ACELERACAO_ACELERA_BRASIL_MODELO_2_FIELDS', [
        { name: 'detalhamento', type: 'string', calculateTotal: false, showFooter: false },

        { name: 'anoOrigemSerie', type: 'integer', showFooter: true, calculateTotal: false, calculateType: 'sum' },
        { name: 'anoOrigemTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano2Total', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano2Percentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'ano2Total', referenceTotalField: 'anoOrigemTotal' },
        { name: 'ano3Total', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano3Percentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'ano3Total', referenceTotalField: 'anoOrigemTotal' },
        { name: 'ano4Total', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano4Percentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'ano4Total', referenceTotalField: 'anoOrigemTotal' },
        { name: 'ano5Total', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano5Percentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'ano5Total', referenceTotalField: 'anoOrigemTotal' },
        { name: 'ano6Total', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano6Percentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'ano6Total', referenceTotalField: 'anoOrigemTotal' },
        { name: 'ano7Total', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano7Percentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'ano7Total', referenceTotalField: 'anoOrigemTotal' },
    ]);

    angular.module('app.relatorios').constant('RESULTADO_FINAL_REPORT_ALFABETIZACAO_ACELERACAO_ACELERA_BRASIL_MODELO_3_FIELDS', [
        { name: 'detalhamento', type: 'string', calculateTotal: false, showFooter: false },
        
        { name: 'matriculaInicial', type: 'integer', showFooter: true, calculateTotal: true, calculateType: 'sum' },
        { name: 'transferenciaExpedida', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'transferenciaExpedidaPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'transferenciaExpedida', referenceTotalField: 'matriculaInicial' },
        { name: 'transferenciaRecebida', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'transferenciaRecebidaPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'transferenciaRecebida', referenceTotalField: 'matriculaInicial' },
        { name: 'matriculaFinal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'abandono', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'abandonoPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'abandono', referenceTotalField: 'matriculaFinal' },
        { name: 'promovido', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'promovidoPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'promovido', referenceTotalField: 'matriculaFinal' },
        { name: 'mesmaSerie', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'mesmaSeriePercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'mesmaSerie', referenceTotalField: 'matriculaFinal' },

    ]);

    angular.module('app.relatorios').constant('RESULTADO_FINAL_REPORT_ALFABETIZACAO_ACELERACAO_SE_LIGA_MODELO_3_FIELDS', [
        { name: 'detalhamento', type: 'string', calculateTotal: false, showFooter: false },
        
        { name: 'matriculaInicial', type: 'integer', showFooter: true, calculateTotal: true, calculateType: 'sum' },
        { name: 'transferenciaExpedida', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'transferenciaExpedidaPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'transferenciaExpedida', referenceTotalField: 'matriculaInicial' },
        { name: 'transferenciaRecebida', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'transferenciaRecebidaPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'transferenciaRecebida', referenceTotalField: 'matriculaInicial' },
        { name: 'matriculaFinal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'abandono', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'abandonoPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'abandono', referenceTotalField: 'matriculaFinal' },
        { name: 'promovido', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'promovidoPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'promovido', referenceTotalField: 'matriculaFinal' },
        { name: 'mesmaSerie', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'mesmaSeriePercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'mesmaSerie', referenceTotalField: 'matriculaFinal' },
        { name: 'alunosAlfabetizados', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'alunosAlfabetizadosPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'alunosAlfabetizados', referenceTotalField: 'matriculaFinal' },

    ]);

    angular.module('app.relatorios').constant('RESULTADO_FINAL_REPORT_ENSINO_REGULAR_INICIAIS_MODELO_1_FIELDS', [
        { name: 'id', type: 'integer', calculateTotal: false, showFooter: false },
        { name: 'detalhamento', type: 'string', calculateTotal: false, showFooter: false },

        { name: 'ano1MA', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano1A', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano1RD', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano1RDPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'ano1RD', referenceTotalField: 'ano1MA' },
        { name: 'ano1RF', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano1RFPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'ano1RF', referenceTotalField: 'ano1MA' },
        { name: 'ano1TotalR', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano1TotalRPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'ano1TotalR', referenceTotalField: 'ano1MA' },
        { name: 'ano1Alf', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano1NAlf', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        
        { name: 'ano2MA', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano2A', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano2RD', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano2RDPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'ano2RD', referenceTotalField: 'ano2MA' },
        { name: 'ano2RF', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano2RFPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'ano2RF', referenceTotalField: 'ano2MA' },
        { name: 'ano2TotalR', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano2TotalRPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'ano2TotalR', referenceTotalField: 'ano2MA' },
        { name: 'ano2Alf', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano2NAlf', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        
        { name: 'ano3MA', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano3A', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano3RD', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano3RDPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'ano3RD', referenceTotalField: 'ano3MA' },
        { name: 'ano3RF', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano3RFPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'ano3RF', referenceTotalField: 'ano3MA' },
        { name: 'ano3TotalR', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano3TotalRPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'ano3TotalR', referenceTotalField: 'ano3MA' },
        
        { name: 'ano4MA', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano4A', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano4RD', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano4RDPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'ano4RD', referenceTotalField: 'ano4MA' },
        { name: 'ano4RF', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano4RFPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'ano4RF', referenceTotalField: 'ano4MA' },
        { name: 'ano4TotalR', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano4TotalRPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'ano4TotalR', referenceTotalField: 'ano4MA' },
        
        { name: 'ano5MA', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano5A', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano5RD', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano5RDPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'ano5RD', referenceTotalField: 'ano5MA' },
        { name: 'ano5RF', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano5RFPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'ano5RF', referenceTotalField: 'ano5MA' },
        { name: 'ano5TotalR', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano5TotalRPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'ano5TotalR', referenceTotalField: 'ano5MA' }

    ]);

    angular.module('app.relatorios').constant('RESULTADO_FINAL_REPORT_ENSINO_REGULAR_INICIAIS_MODELO_2_FIELDS', [
        { name: 'id', type: 'integer', calculateTotal: false, showFooter: false },
        { name: 'detalhamento', type: 'string', calculateTotal: false, showFooter: false },

        { name: 'ano1MA', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano1RD', hide:true, type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano1RDPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'ano1RD', referenceTotalField: 'ano1MA' },
        { name: 'ano1RF', hide: true, type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano1RFPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'ano1RF', referenceTotalField: 'ano1MA' },
        { name: 'ano1TR', hide: true, type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano1TRPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'ano1TR', referenceTotalField: 'ano1MA' },
        { name: 'ano1Alf', hide: true, type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano1AlfPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'ano1Alf', referenceTotalField: 'ano1MA' },
        
        { name: 'ano2MA', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano2RD', hide:true, type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano2RDPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'ano2RD', referenceTotalField: 'ano2MA' },
        { name: 'ano2RF', hide: true, type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano2RFPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'ano2RF', referenceTotalField: 'ano2MA' },
        { name: 'ano2TR', hide: true, type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano2TRPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'ano2TR', referenceTotalField: 'ano2MA' },
        { name: 'ano2Alf', hide: true, type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano2AlfPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'ano2Alf', referenceTotalField: 'ano2MA' },
        
        { name: 'ano3MA', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano3RD', hide:true, type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano3RDPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'ano3RD', referenceTotalField: 'ano3MA' },
        { name: 'ano3RF', hide: true, type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano3RFPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'ano3RF', referenceTotalField: 'ano3MA' },
        { name: 'ano3TR', hide: true, type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano3TRPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'ano3TR', referenceTotalField: 'ano3MA' },
        
        { name: 'ano4MA', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano4RD', hide:true, type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano4RDPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'ano4RD', referenceTotalField: 'ano4MA' },
        { name: 'ano4RF', hide: true, type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano4RFPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'ano4RF', referenceTotalField: 'ano4MA' },
        { name: 'ano4TR', hide: true, type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano4TRPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'ano4TR', referenceTotalField: 'ano4MA' },
        
        { name: 'ano5MA', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano5RD', hide:true, type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano5RDPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'ano5RD', referenceTotalField: 'ano5MA' },
        { name: 'ano5RF', hide: true, type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano5RFPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'ano5RF', referenceTotalField: 'ano5MA' },
        { name: 'ano5TR', hide: true, type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano5TRPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'ano5TR', referenceTotalField: 'ano5MA' },
        
        { name: 'totalMA', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'totalRD', hide:true, type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'totalRDPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'totalRD', referenceTotalField: 'totalMA' },
        { name: 'totalRF', hide: true, type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'totalRFPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'totalRF', referenceTotalField: 'totalMA' },
        { name: 'totalTR', hide: true, type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'totalTRPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'totalTR', referenceTotalField: 'totalMA' },

    ]);

    angular.module('app.relatorios').constant('RESULTADO_FINAL_REPORT_ENSINO_REGULAR_FINAIS_MODELO_1_FIELDS', [
        { name: 'id', type: 'integer', calculateTotal: false, showFooter: false },
        { name: 'detalhamento', type: 'string', calculateTotal: false, showFooter: false },

        { name: 'ano6MA', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano6A', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano6RD', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano6RDPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'ano6RD', referenceTotalField: 'ano6MA' },
        { name: 'ano6RF', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano6RFPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'ano6RF', referenceTotalField: 'ano6MA' },
        { name: 'ano6TotalR', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano6TotalRPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'ano6TotalR', referenceTotalField: 'ano6MA' },

        { name: 'ano7MA', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano7A', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano7RD', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano7RDPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'ano7RD', referenceTotalField: 'ano7MA' },
        { name: 'ano7RF', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano7RFPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'ano7RF', referenceTotalField: 'ano7MA' },
        { name: 'ano7TotalR', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano7TotalRPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'ano7TotalR', referenceTotalField: 'ano7MA' },

        { name: 'ano8MA', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano8A', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano8RD', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano8RDPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'ano8RD', referenceTotalField: 'ano8MA' },
        { name: 'ano8RF', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano8RFPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'ano8RF', referenceTotalField: 'ano8MA' },
        { name: 'ano8TotalR', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano8TotalRPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'ano8TotalR', referenceTotalField: 'ano8MA' },

        { name: 'ano9MA', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano9A', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano9RD', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano9RDPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'ano9RD', referenceTotalField: 'ano9MA' },
        { name: 'ano9RF', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano9RFPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'ano9RF', referenceTotalField: 'ano9MA' },
        { name: 'ano9TotalR', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano9TotalRPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'ano9TotalR', referenceTotalField: 'ano9MA' }

    ]);

    angular.module('app.relatorios').constant('RESULTADO_FINAL_REPORT_ENSINO_REGULAR_FINAIS_MODELO_2_FIELDS', [
        { name: 'id', type: 'integer', calculateTotal: false, showFooter: false },
        { name: 'detalhamento', type: 'string', calculateTotal: false, showFooter: false },

        { name: 'ano6MA', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano6RD', hide:true, type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano6RDPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'ano6RD', referenceTotalField: 'ano6MA' },
        { name: 'ano6RF', hide: true, type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano6RFPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'ano6RF', referenceTotalField: 'ano6MA' },
        { name: 'ano6TR', hide: true, type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano6TRPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'ano6TR', referenceTotalField: 'ano6MA' },
        
        { name: 'ano7MA', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano7RD', hide:true, type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano7RDPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'ano7RD', referenceTotalField: 'ano7MA' },
        { name: 'ano7RF', hide: true, type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano7RFPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'ano7RF', referenceTotalField: 'ano7MA' },
        { name: 'ano7TR', hide: true, type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano7TRPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'ano7TR', referenceTotalField: 'ano7MA' },
        
        { name: 'ano8MA', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano8RD', hide:true, type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano8RDPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'ano8RD', referenceTotalField: 'ano8MA' },
        { name: 'ano8RF', hide: true, type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano8RFPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'ano8RF', referenceTotalField: 'ano8MA' },
        { name: 'ano8TR', hide: true, type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano8TRPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'ano8TR', referenceTotalField: 'ano8MA' },
        
        { name: 'ano9MA', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano9RD', hide:true, type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano9RDPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'ano9RD', referenceTotalField: 'ano9MA' },
        { name: 'ano9RF', hide: true, type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano9RFPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'ano9RF', referenceTotalField: 'ano9MA' },
        { name: 'ano9TR', hide: true, type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'ano9TRPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'ano9TR', referenceTotalField: 'ano9MA' },
        
        { name: 'totalMA', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'totalRD', hide:true, type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'totalRDPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'totalRD', referenceTotalField: 'totalMA' },
        { name: 'totalRF', hide: true, type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'totalRFPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'totalRF', referenceTotalField: 'totalMA' },
        { name: 'totalTR', hide: true, type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'totalTRPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'totalTR', referenceTotalField: 'totalMA' },

    ]);

    angular.module('app.relatorios').constant('RESULTADO_FINAL_REPORT_ENSINO_REGULAR_MATRICULA_MOVIMENTACAO_FIELDS', [

        { name: 'serie', type: 'string', calculateTotal: false, showFooter: false },
        { name: 'matriculaIncial', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        // { name: 'movimentacaoEntrada', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        // { name: 'movimentacaoSaida', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'transferenciaRecebida', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'transferenciaExpedida', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'abandono', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'matriculaFinal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'aprovacao', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'aprovacaoPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'aprovacao', referenceTotalField: 'matriculaFinal' },
        { name: 'reprovacaoPorFalta', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'reprovacaoPorFaltaPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'reprovacaoPorFalta', referenceTotalField: 'matriculaFinal' },
        { name: 'reprovacaoPorDesempenho', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'reprovacaoPorDesempenhoPercentual', percent:"true", type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'reprovacaoPorDesempenho', referenceTotalField: 'matriculaFinal' },
        
    ]);
    
    angular.module('app.relatorios').constant('RESULTADO_FINAL_REPORT_ALFABETIZACAO_2ANO_1SERIE_REGULAR_FIELDS', [
        { name: 'serie', type: 'string', calculateTotal: false, showFooter: false },
        { name: 'totalMatriculados', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'indiceAprovacao', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'indiceAlfabetizacao', type: 'integer', calculateTotal: true, calculateType: 'average' }
    ]);

    angular.module('app.relatorios').constant('RESULTADO_FINAL_REPORT_INDICADORES_SUCESSO_RESULTADOS_FIELDS', [
        { name: "id", type: 'integer',  showFooter: false},
        { name: "uf", type: 'string',  showFooter: false },
        { name: "municipio", type: 'string',  showFooter: false },
        { name: "escola", type: 'string',  showFooter: false },
        { name: "regionalUf", type: 'string',  showFooter: false },
        { name: "regionalMunicipio", type: 'string',  showFooter: false },

        { name: 'totalTurmas', type:'integer', calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'totalAulasDadas', type:'integer', calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'realSeriesIniciaisProfsFalta', type:'integer', calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'realSeriesIniciaisAulasPrevistas', type:'integer', calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'realSeriesIniciaisAlunosFalta', type:'integer', calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'realSeriesIniciaisAulasTotal', type:'integer', calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'realSeriesIniciaisReprovadosFalta', type:'integer', calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'realSeriesIniciaisEscolaAlunosTotal', type:'integer', calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'realSeriesIniciaisAprovados', type:'integer', calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'realSerie1AlunosAlfabetizados', type:'integer', calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'realSerie1EscolaAlunosTotal', type:'integer', calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'realSeriesFinaisProfsFalta', type:'integer', calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'realSeriesFinaisAulasPrevistas', type:'integer', calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'realSeriesFinaisAlunosFalta', type:'integer', calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'realSeriesFinaisAulasTotal', type:'integer', calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'realSeriesFinaisReprovadosFalta', type:'integer', calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'realSeriesFinaisEscolaAlunosTotal', type:'integer', calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'realSeriesFinaisAprovados', type:'integer', calculateTotal: true, calculateType: 'sum', hide: true},

        { name: "prevAulas", type: 'integer', percent: 'true',calculateType: 'average', calculateTotal: true, showFooter: true},
        { name: "realAulas", type: 'integer', percent: 'true',calculateType: 'average', calculateTotal: true, showFooter: true, 
                referenceField: 'totalTurmas', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['totalAulasDadas'] / data.calculations['totalTurmas']) / 200 * 100).toFixed(1);
        } },
        { name: "prevSeriesIniciaisProfsFreq", type: 'integer', percent: 'true',calculateType: 'average', calculateTotal: true, showFooter: true},
        { name: "realSeriesIniciaisProfsFreq", type: 'integer', percent: 'true',calculateType: 'average', calculateTotal: true, showFooter: true, 
                referenceField: 'realSeriesIniciaisProfsFalta', relCalcCustomTotal: function(data) {
                return parseFloat(100 - (data.calculations['realSeriesIniciaisProfsFalta'] / data.calculations['realSeriesIniciaisAulasPrevistas']) * 100).toFixed(1);
        } },
        { name: "prevSeriesIniciaisAlunosFreq", type: 'integer', percent: 'true',calculateType: 'average', calculateTotal: true, showFooter: true},
        { name: "realSeriesIniciaisAlunosFreq", type: 'integer', percent: 'true',calculateType: 'average', calculateTotal: true, showFooter: true, 
                referenceField: 'realSeriesIniciaisAlunosFalta', relCalcCustomTotal: function(data) {
                return parseFloat(100 - (data.calculations['realSeriesIniciaisAlunosFalta'] / data.calculations['realSeriesIniciaisAulasTotal']) * 100).toFixed(1);
        } },
        { name: "prevSeriesIniciaisAlunosReprovadosFalta", type: 'integer', percent: 'true',calculateType: 'average', calculateTotal: true, showFooter: true},
        { name: "realSeriesIniciaisAlunosReprovadosFalta", type: 'integer', percent: 'true',calculateType: 'average', calculateTotal: true, showFooter: true, 
                referenceField: 'realSeriesIniciaisReprovadosFalta', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['realSeriesIniciaisReprovadosFalta'] / data.calculations['realSeriesIniciaisEscolaAlunosTotal']) * 100).toFixed(1);
        } },
        { name: "prevSeriesIniciaisAlunosAprovados", type: 'integer', percent: 'true',calculateType: 'average', calculateTotal: true, showFooter: true},
        { name: "realSeriesIniciaisAlunosAprovados", type: 'integer', percent: 'true',calculateType: 'average', calculateTotal: true, showFooter: true, 
                referenceField: 'realSeriesIniciaisAprovados', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['realSeriesIniciaisAprovados'] / data.calculations['realSeriesIniciaisEscolaAlunosTotal']) * 100).toFixed(1);
        }},
        { name: "prevCorrecaoFluxo", type: 'integer', percent: 'true',calculateType: 'average', calculateTotal: true, showFooter: true},
        { name: "prevAlfSeries12AlunosAlfabetizados", type: 'integer', percent: 'true',calculateType: 'average', calculateTotal: true, showFooter: true},
        { name: "realAlfSeries12AlunosAlfabetizados", type: 'integer', percent: 'true',calculateType: 'average', calculateTotal: true, showFooter: true, 
                referenceField: 'realSerie1AlunosAlfabetizados', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['realSerie1AlunosAlfabetizados'] / data.calculations['realSerie1EscolaAlunosTotal']) * 100).toFixed(1);
        }},
        { name: "prevSeriesFinaisProfFreq", type: 'integer', percent: 'true',calculateType: 'average', calculateTotal: true, showFooter: true},
        { name: "realSeriesFinaisProfFreq", type: 'integer', percent: 'true',calculateType: 'average', calculateTotal: true, showFooter: true, 
                referenceField: 'realSeriesFinaisProfsFalta', relCalcCustomTotal: function(data) {
                return parseFloat(100 - (data.calculations['realSeriesFinaisProfsFalta'] / data.calculations['realSeriesFinaisAulasPrevistas']) * 100).toFixed(1);
        }},
        { name: "prevSeriesFinaisAlunosFreq", type: 'integer', percent: 'true',calculateType: 'average', calculateTotal: true, showFooter: true},
        { name: "realSeriesFinaisAlunosFreq", type: 'integer', percent: 'true',calculateType: 'average', calculateTotal: true, showFooter: true, 
                referenceField: 'realSeriesFinaisAlunosFalta', relCalcCustomTotal: function(data) {
                return parseFloat(100 - (data.calculations['realSeriesFinaisAlunosFalta'] / data.calculations['realSeriesFinaisAulasTotal']) * 100).toFixed(1);
        }},
        { name: "prevSeriesFinaisAlunosReprovacaoFalta", type: 'integer', percent: 'true',calculateType: 'average', calculateTotal: true, showFooter: true},
        { name: "realSeriesFinaisAlunosReprovacaoFalta", type: 'integer', percent: 'true',calculateType: 'average', calculateTotal: true, showFooter: true, 
                referenceField: 'realSeriesFinaisReprovadosFalta', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['realSeriesFinaisReprovadosFalta'] / data.calculations['realSeriesFinaisEscolaAlunosTotal']) * 100).toFixed(1);
        }},
        { name: "prevSeriesFinaisAlunosAprovados", type: 'integer', percent: 'true',calculateType: 'average', calculateTotal: true, showFooter: true},
        { name: "realSeriesFinaisAlunosAprovados", type: 'integer', percent: 'true',calculateType: 'average', calculateTotal: 'true', showFooter: 'true', 
                referenceField: 'realSeriesFinaisAprovados', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['realSeriesFinaisAprovados'] / data.calculations['realSeriesFinaisEscolaAlunosTotal']) * 100).toFixed(1);
        }},
    ]);

    /**
     * 
     *  Constantes 8.12 Letramento em programacao
     */

    angular.module('app.relatorios').constant('RESULTADO_FINAL_REGIONAL_UF_FIELDS', [
        { name: 'ordem',                               type: 'integer' ,showFooter: false},
        { name: 'estado',                              type: 'string'  ,showFooter: false},
        { name: 'regionalUf',                          type: 'string'  ,showFooter: false},   

        { name: 'numeroTurmas',                        type: 'integer', calculateTotal: true, calculateType: 'sum'},
        
        { name: 'freqProfNaFormacao',                  type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average'},
        
        { name: 'freqAulasMat',                        type: 'integer', calculateTotal: true, percent:"true", calculateType: 'sum', hide: true },
        { name: 'aulasMat',                            type: 'integer', calculateTotal: true, percent:"true", calculateType: 'sum', hide: true },
        { name: 'freqMediaMensalAlunosPrograma',       type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average', 
                referenceField: 'freqAulasMat', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['freqAulasMat'] / data.calculations['aulasMat']) * 100).toFixed(1);
        }},

        { name: 'ano',                                 type: 'integer', calculateTotal: true, calculateType: 'sum' },
        
        // 
        { name: 'rec',                                 type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'transferenciaRecPercentagem',         type: 'integer', calculateTotal: true,  percent:"true", calculateType: 'average', 
                referenceField: 'rec', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['rec'] / data.calculations['ano']) * 100).toFixed(1);
        } },
        { name: 'exp',                                 type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'transferenciaExpPercentagem',         type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average' , 
                referenceField: 'exp', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['exp'] / data.calculations['ano']) * 100).toFixed(1);
        }},
        { name: 'numeroDeAbandono',                    type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'percentagemAbandono',                 type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average' , 
                referenceField: 'numeroDeAbandono', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['numeroDeAbandono'] / data.calculations['ano']) * 100).toFixed(1);
        }},
        { name: 'atual',                               type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'percentagemMatricula',                type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average', 
                referenceField: 'atual', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['atual'] / data.calculations['ano']) * 100).toFixed(1);
        }},
        //
        { name: 'totalProblemasPessoais',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'problemasPessoais',                   type: 'integer',calculateTotal: true, percent:"true", calculateType: 'average' , 
                referenceField: 'totalProblemasPessoais', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['totalProblemasPessoais'] / data.calculations['numeroDeAbandono']) * 100).toFixed(1);
        }},
        { name: 'totalConflitoHorarioTransporte',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'conflitoHorarioTransporte',           type: 'integer',calculateTotal: true, percent:"true", calculateType: 'average' , 
        referenceField: 'totalConflitoHorarioTransporte', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['totalConflitoHorarioTransporte'] / data.calculations['numeroDeAbandono']) * 100).toFixed(1);
        }},
        { name: 'totalDificuldadesPropostaPrograma',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'dificuldadesPropostaPrograma',        type: 'integer',calculateTotal: true, percent:"true", calculateType: 'average' , 
                referenceField: 'totalDificuldadesPropostaPrograma', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['totalDificuldadesPropostaPrograma'] / data.calculations['numeroDeAbandono']) * 100).toFixed(1);
        }},
        { name: 'totalOutros',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'outros',                              type: 'integer',calculateTotal: true, percent:"true", calculateType: 'average' , 
                referenceField: 'totalOutros', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['totalOutros'] / data.calculations['numeroDeAbandono']) * 100).toFixed(1);
        }},
        //
        { name: 'conclusaoProjeto',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'percentagemConclusaoProjeto',       type: 'integer',calculateTotal: true, percent:"true", calculateType: 'average' , 
                referenceField: 'conclusaoProjeto', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['conclusaoProjeto'] / data.calculations['atual']) * 100).toFixed(1);
        }},
        { name: 'conclusaoModulo',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'percentagemConclusaoModulo',        type: 'integer',calculateTotal: true, percent:"true", calculateType: 'average' , 
                referenceField: 'conclusaoModulo', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['conclusaoModulo'] / data.calculations['atual']) * 100).toFixed(1);
        }},
        //
        { name: 'sim',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'totalSim',                 type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average'  , 
                referenceField: 'sim', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['sim'] / (data.calculations['sim']+data.calculations['nao'])) * 100).toFixed(1);
        }},
        { name: 'nao',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'totalNao',                 type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average' , 
                referenceField: 'nao', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['nao'] / (data.calculations['sim']+data.calculations['nao'])) * 100).toFixed(1);
        }}
    ]);

    angular.module('app.relatorios').constant('RESULTADO_FINAL_REGIONAL_MUNICIPAL_FIELDS', [
        { name: 'ordem',                               type: 'integer', showFooter: false},
        { name: 'estado',                              type: 'string', showFooter: false},   
        { name: 'cidade',                              type: 'string', showFooter: false},
        { name: 'regionalMunicipal',                   type: 'string', showFooter: false},   
        
        { name: 'numeroTurmas',                        type: 'integer', calculateTotal: true, calculateType: 'sum'},
        
        { name: 'freqProfNaFormacao',                  type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average'},
        
        { name: 'freqAulasMat',                        type: 'integer', calculateTotal: true, percent:"true", calculateType: 'sum', hide: true },
        { name: 'aulasMat',                            type: 'integer', calculateTotal: true, percent:"true", calculateType: 'sum', hide: true },
        { name: 'freqMediaMensalAlunosPrograma',       type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average', 
                referenceField: 'freqAulasMat', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['freqAulasMat'] / data.calculations['aulasMat']) * 100).toFixed(1);
        }},

        { name: 'ano',                                 type: 'integer', calculateTotal: true, calculateType: 'sum' },
        
        // 
        { name: 'rec',                                 type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'transferenciaRecPercentagem',         type: 'integer', calculateTotal: true,  percent:"true", calculateType: 'average', 
                referenceField: 'rec', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['rec'] / data.calculations['ano']) * 100).toFixed(1);
        } },
        { name: 'exp',                                 type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'transferenciaExpPercentagem',         type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average' , 
                referenceField: 'exp', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['exp'] / data.calculations['ano']) * 100).toFixed(1);
        }},
        { name: 'numeroDeAbandono',                    type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'percentagemAbandono',                 type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average' , 
                referenceField: 'numeroDeAbandono', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['numeroDeAbandono'] / data.calculations['ano']) * 100).toFixed(1);
        }},
        { name: 'atual',                               type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'percentagemMatricula',                type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average', 
                referenceField: 'atual', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['atual'] / data.calculations['ano']) * 100).toFixed(1);
        }},
        //
        { name: 'totalProblemasPessoais',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'problemasPessoais',                   type: 'integer',calculateTotal: true, percent:"true", calculateType: 'average' , 
                referenceField: 'totalProblemasPessoais', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['totalProblemasPessoais'] / data.calculations['numeroDeAbandono']) * 100).toFixed(1);
        }},
        { name: 'totalConflitoHorarioTransporte',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'conflitoHorarioTransporte',           type: 'integer',calculateTotal: true, percent:"true", calculateType: 'average' , 
        referenceField: 'totalConflitoHorarioTransporte', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['totalConflitoHorarioTransporte'] / data.calculations['numeroDeAbandono']) * 100).toFixed(1);
        }},
        { name: 'totalDificuldadesPropostaPrograma',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'dificuldadesPropostaPrograma',        type: 'integer',calculateTotal: true, percent:"true", calculateType: 'average' , 
                referenceField: 'totalDificuldadesPropostaPrograma', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['totalDificuldadesPropostaPrograma'] / data.calculations['numeroDeAbandono']) * 100).toFixed(1);
        }},
        { name: 'totalOutros',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'outros',                              type: 'integer',calculateTotal: true, percent:"true", calculateType: 'average' , 
                referenceField: 'totalOutros', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['totalOutros'] / data.calculations['numeroDeAbandono']) * 100).toFixed(1);
        }},
        //
        { name: 'conclusaoProjeto',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'percentagemConclusaoProjeto',       type: 'integer',calculateTotal: true, percent:"true", calculateType: 'average' , 
                referenceField: 'conclusaoProjeto', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['conclusaoProjeto'] / data.calculations['atual']) * 100).toFixed(1);
        }},
        { name: 'conclusaoModulo',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'percentagemConclusaoModulo',        type: 'integer',calculateTotal: true, percent:"true", calculateType: 'average' , 
                referenceField: 'conclusaoModulo', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['conclusaoModulo'] / data.calculations['atual']) * 100).toFixed(1);
        }},
        //
        { name: 'sim',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'totalSim',                 type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average'  , 
                referenceField: 'sim', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['sim'] / (data.calculations['sim']+data.calculations['nao'])) * 100).toFixed(1);
        }},
        { name: 'nao',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'totalNao',                 type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average' , 
                referenceField: 'nao', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['nao'] / (data.calculations['sim']+data.calculations['nao'])) * 100).toFixed(1);
        }}
    ]);

    angular.module('app.relatorios').constant('RESULTADO_FINAL_GRUPO_MUNICIPIO_IAS_FIELDS', [
        { name: 'ordem',                               type: 'integer', showFooter: false },
        { name: 'grupoDeMunicipiosIas',                type: 'string'  , showFooter: false},     
        
        { name: 'numeroTurmas',                        type: 'integer', calculateTotal: true, calculateType: 'sum'},
        
        { name: 'freqProfNaFormacao',                  type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average'},
        
        { name: 'freqAulasMat',                        type: 'integer', calculateTotal: true, percent:"true", calculateType: 'sum', hide: true },
        { name: 'aulasMat',                            type: 'integer', calculateTotal: true, percent:"true", calculateType: 'sum', hide: true },
        { name: 'freqMediaMensalAlunosPrograma',       type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average', 
                referenceField: 'freqAulasMat', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['freqAulasMat'] / data.calculations['aulasMat']) * 100).toFixed(1);
        }},

        { name: 'ano',                                 type: 'integer', calculateTotal: true, calculateType: 'sum' },
        
        // 
        { name: 'rec',                                 type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'transferenciaRecPercentagem',         type: 'integer', calculateTotal: true,  percent:"true", calculateType: 'average', 
                referenceField: 'rec', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['rec'] / data.calculations['ano']) * 100).toFixed(1);
        } },
        { name: 'exp',                                 type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'transferenciaExpPercentagem',         type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average' , 
                referenceField: 'exp', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['exp'] / data.calculations['ano']) * 100).toFixed(1);
        }},
        { name: 'numeroDeAbandono',                    type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'percentagemAbandono',                 type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average' , 
                referenceField: 'numeroDeAbandono', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['numeroDeAbandono'] / data.calculations['ano']) * 100).toFixed(1);
        }},
        { name: 'atual',                               type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'percentagemMatricula',                type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average', 
                referenceField: 'atual', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['atual'] / data.calculations['ano']) * 100).toFixed(1);
        }},
        //
        { name: 'totalProblemasPessoais',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'problemasPessoais',                   type: 'integer',calculateTotal: true, percent:"true", calculateType: 'average' , 
                referenceField: 'totalProblemasPessoais', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['totalProblemasPessoais'] / data.calculations['numeroDeAbandono']) * 100).toFixed(1);
        }},
        { name: 'totalConflitoHorarioTransporte',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'conflitoHorarioTransporte',           type: 'integer',calculateTotal: true, percent:"true", calculateType: 'average' , 
        referenceField: 'totalConflitoHorarioTransporte', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['totalConflitoHorarioTransporte'] / data.calculations['numeroDeAbandono']) * 100).toFixed(1);
        }},
        { name: 'totalDificuldadesPropostaPrograma',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'dificuldadesPropostaPrograma',        type: 'integer',calculateTotal: true, percent:"true", calculateType: 'average' , 
                referenceField: 'totalDificuldadesPropostaPrograma', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['totalDificuldadesPropostaPrograma'] / data.calculations['numeroDeAbandono']) * 100).toFixed(1);
        }},
        { name: 'totalOutros',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'outros',                              type: 'integer',calculateTotal: true, percent:"true", calculateType: 'average' , 
                referenceField: 'totalOutros', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['totalOutros'] / data.calculations['numeroDeAbandono']) * 100).toFixed(1);
        }},
        //
        { name: 'conclusaoProjeto',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'percentagemConclusaoProjeto',       type: 'integer',calculateTotal: true, percent:"true", calculateType: 'average' , 
                referenceField: 'conclusaoProjeto', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['conclusaoProjeto'] / data.calculations['atual']) * 100).toFixed(1);
        }},
        { name: 'conclusaoModulo',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'percentagemConclusaoModulo',        type: 'integer',calculateTotal: true, percent:"true", calculateType: 'average' , 
                referenceField: 'conclusaoModulo', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['conclusaoModulo'] / data.calculations['atual']) * 100).toFixed(1);
        }},
        //
        { name: 'sim',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'totalSim',                 type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average'  , 
                referenceField: 'sim', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['sim'] / (data.calculations['sim']+data.calculations['nao'])) * 100).toFixed(1);
        }},
        { name: 'nao',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'totalNao',                 type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average' , 
                referenceField: 'nao', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['nao'] / (data.calculations['sim']+data.calculations['nao'])) * 100).toFixed(1);
        }}    
    ]);

    angular.module('app.relatorios').constant('RESULTADO_FINAL_ESTADO_FIELDS', [
        { name: 'ordem',                               type: 'integer', showFooter: false},
        { name: 'estado',                              type: 'string', showFooter: false},
       
        { name: 'numeroTurmas',                        type: 'integer', calculateTotal: true, calculateType: 'sum'},
        
        { name: 'freqProfNaFormacao',                  type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average'},
        
        { name: 'freqAulasMat',                        type: 'integer', calculateTotal: true, percent:"true", calculateType: 'sum', hide: true },
        { name: 'aulasMat',                            type: 'integer', calculateTotal: true, percent:"true", calculateType: 'sum', hide: true },
        { name: 'freqMediaMensalAlunosPrograma',       type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average', 
                referenceField: 'freqAulasMat', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['freqAulasMat'] / data.calculations['aulasMat']) * 100).toFixed(1);
        }},

        { name: 'ano',                                 type: 'integer', calculateTotal: true, calculateType: 'sum' },
        
        // 
        { name: 'rec',                                 type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'transferenciaRecPercentagem',         type: 'integer', calculateTotal: true,  percent:"true", calculateType: 'average', 
                referenceField: 'rec', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['rec'] / data.calculations['ano']) * 100).toFixed(1);
        } },
        { name: 'exp',                                 type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'transferenciaExpPercentagem',         type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average' , 
                referenceField: 'exp', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['exp'] / data.calculations['ano']) * 100).toFixed(1);
        }},
        { name: 'numeroDeAbandono',                    type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'percentagemAbandono',                 type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average' , 
                referenceField: 'numeroDeAbandono', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['numeroDeAbandono'] / data.calculations['ano']) * 100).toFixed(1);
        }},
        { name: 'atual',                               type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'percentagemMatricula',                type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average', 
                referenceField: 'atual', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['atual'] / data.calculations['ano']) * 100).toFixed(1);
        }},
        //
        { name: 'totalProblemasPessoais',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'problemasPessoais',                   type: 'integer',calculateTotal: true, percent:"true", calculateType: 'average' , 
                referenceField: 'totalProblemasPessoais', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['totalProblemasPessoais'] / data.calculations['numeroDeAbandono']) * 100).toFixed(1);
        }},
        { name: 'totalConflitoHorarioTransporte',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'conflitoHorarioTransporte',           type: 'integer',calculateTotal: true, percent:"true", calculateType: 'average' , 
        referenceField: 'totalConflitoHorarioTransporte', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['totalConflitoHorarioTransporte'] / data.calculations['numeroDeAbandono']) * 100).toFixed(1);
        }},
        { name: 'totalDificuldadesPropostaPrograma',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'dificuldadesPropostaPrograma',        type: 'integer',calculateTotal: true, percent:"true", calculateType: 'average' , 
                referenceField: 'totalDificuldadesPropostaPrograma', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['totalDificuldadesPropostaPrograma'] / data.calculations['numeroDeAbandono']) * 100).toFixed(1);
        }},
        { name: 'totalOutros',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'outros',                              type: 'integer',calculateTotal: true, percent:"true", calculateType: 'average' , 
                referenceField: 'totalOutros', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['totalOutros'] / data.calculations['numeroDeAbandono']) * 100).toFixed(1);
        }},
        //
        { name: 'conclusaoProjeto',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'percentagemConclusaoProjeto',       type: 'integer',calculateTotal: true, percent:"true", calculateType: 'average' , 
                referenceField: 'conclusaoProjeto', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['conclusaoProjeto'] / data.calculations['atual']) * 100).toFixed(1);
        }},
        { name: 'conclusaoModulo',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'percentagemConclusaoModulo',        type: 'integer',calculateTotal: true, percent:"true", calculateType: 'average' , 
                referenceField: 'conclusaoModulo', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['conclusaoModulo'] / data.calculations['atual']) * 100).toFixed(1);
        }},
        //
        { name: 'sim',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'totalSim',                 type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average'  , 
                referenceField: 'sim', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['sim'] / (data.calculations['sim']+data.calculations['nao'])) * 100).toFixed(1);
        }},
        { name: 'nao',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'totalNao',                 type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average' , 
                referenceField: 'nao', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['nao'] / (data.calculations['sim']+data.calculations['nao'])) * 100).toFixed(1);
        }}    
    ]);

    angular.module('app.relatorios').constant('RESULTADO_FINAL_MUNICIPIO_FIELDS', [
        { name: 'ordem',                               type: 'integer', showFooter: false},
        { name: 'estado',                              type: 'string', showFooter: false},
        { name: 'cidade',                              type: 'string', showFooter: false},
        
        { name: 'numeroTurmas',                        type: 'integer', calculateTotal: true, calculateType: 'sum'},
        
        { name: 'freqProfNaFormacao',                  type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average'},
        
        { name: 'freqAulasMat',                        type: 'integer', calculateTotal: true, percent:"true", calculateType: 'sum', hide: true },
        { name: 'aulasMat',                            type: 'integer', calculateTotal: true, percent:"true", calculateType: 'sum', hide: true },
        { name: 'freqMediaMensalAlunosPrograma',       type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average', 
                referenceField: 'freqAulasMat', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['freqAulasMat'] / data.calculations['aulasMat']) * 100).toFixed(1);
        }},

        { name: 'ano',                                 type: 'integer', calculateTotal: true, calculateType: 'sum' },
        
        // 
        { name: 'rec',                                 type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'transferenciaRecPercentagem',         type: 'integer', calculateTotal: true,  percent:"true", calculateType: 'average', 
                referenceField: 'rec', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['rec'] / data.calculations['ano']) * 100).toFixed(1);
        } },
        { name: 'exp',                                 type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'transferenciaExpPercentagem',         type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average' , 
                referenceField: 'exp', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['exp'] / data.calculations['ano']) * 100).toFixed(1);
        }},
        { name: 'numeroDeAbandono',                    type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'percentagemAbandono',                 type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average' , 
                referenceField: 'numeroDeAbandono', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['numeroDeAbandono'] / data.calculations['ano']) * 100).toFixed(1);
        }},
        { name: 'atual',                               type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'percentagemMatricula',                type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average', 
                referenceField: 'atual', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['atual'] / data.calculations['ano']) * 100).toFixed(1);
        }},
        //
        { name: 'totalProblemasPessoais',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'problemasPessoais',                   type: 'integer',calculateTotal: true, percent:"true", calculateType: 'average' , 
                referenceField: 'totalProblemasPessoais', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['totalProblemasPessoais'] / data.calculations['numeroDeAbandono']) * 100).toFixed(1);
        }},
        { name: 'totalConflitoHorarioTransporte',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'conflitoHorarioTransporte',           type: 'integer',calculateTotal: true, percent:"true", calculateType: 'average' , 
        referenceField: 'totalConflitoHorarioTransporte', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['totalConflitoHorarioTransporte'] / data.calculations['numeroDeAbandono']) * 100).toFixed(1);
        }},
        { name: 'totalDificuldadesPropostaPrograma',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'dificuldadesPropostaPrograma',        type: 'integer',calculateTotal: true, percent:"true", calculateType: 'average' , 
                referenceField: 'totalDificuldadesPropostaPrograma', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['totalDificuldadesPropostaPrograma'] / data.calculations['numeroDeAbandono']) * 100).toFixed(1);
        }},
        { name: 'totalOutros',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'outros',                              type: 'integer',calculateTotal: true, percent:"true", calculateType: 'average' , 
                referenceField: 'totalOutros', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['totalOutros'] / data.calculations['numeroDeAbandono']) * 100).toFixed(1);
        }},
        //
        { name: 'conclusaoProjeto',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'percentagemConclusaoProjeto',       type: 'integer',calculateTotal: true, percent:"true", calculateType: 'average' , 
                referenceField: 'conclusaoProjeto', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['conclusaoProjeto'] / data.calculations['atual']) * 100).toFixed(1);
        }},
        { name: 'conclusaoModulo',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'percentagemConclusaoModulo',        type: 'integer',calculateTotal: true, percent:"true", calculateType: 'average' , 
                referenceField: 'conclusaoModulo', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['conclusaoModulo'] / data.calculations['atual']) * 100).toFixed(1);
        }},
        //
        { name: 'sim',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'totalSim',                 type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average'  , 
                referenceField: 'sim', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['sim'] / (data.calculations['sim']+data.calculations['nao'])) * 100).toFixed(1);
        }},
        { name: 'nao',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'totalNao',                 type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average' , 
                referenceField: 'nao', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['nao'] / (data.calculations['sim']+data.calculations['nao'])) * 100).toFixed(1);
        }}
    ]);

    angular.module('app.relatorios').constant('RESULTADO_FINAL_ESCOLA_FIELDS', [
        { name: 'ordem',                               type: 'integer', showFooter: false},
        { name: 'estado',                              type: 'string', showFooter: false},  
        { name: 'cidade',                              type: 'string', showFooter: false},       
        { name: 'escola',                              type: 'string', showFooter: false},
        
        { name: 'numeroTurmas',                        type: 'integer', calculateTotal: true, calculateType: 'sum'},

        { name: 'freqProfNaFormacao',                  type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average'},
        
        { name: 'freqAulasMat',                        type: 'integer', calculateTotal: true, percent:"true", calculateType: 'sum', hide: true },
        { name: 'aulasMat',                            type: 'integer', calculateTotal: true, percent:"true", calculateType: 'sum', hide: true },
        { name: 'freqMediaMensalAlunosPrograma',       type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average', 
                referenceField: 'freqAulasMat', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['freqAulasMat'] / data.calculations['aulasMat']) * 100).toFixed(1);
        }},

        { name: 'ano',                                 type: 'integer', calculateTotal: true, calculateType: 'sum' },
        
        // 
        { name: 'rec',                                 type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'transferenciaRecPercentagem',         type: 'integer', calculateTotal: true,  percent:"true", calculateType: 'average', 
                referenceField: 'rec', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['rec'] / data.calculations['ano']) * 100).toFixed(1);
        } },
        { name: 'exp',                                 type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'transferenciaExpPercentagem',         type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average' , 
                referenceField: 'exp', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['exp'] / data.calculations['ano']) * 100).toFixed(1);
        }},
        { name: 'numeroDeAbandono',                    type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'percentagemAbandono',                 type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average' , 
                referenceField: 'numeroDeAbandono', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['numeroDeAbandono'] / data.calculations['ano']) * 100).toFixed(1);
        }},
        { name: 'atual',                               type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'percentagemMatricula',                type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average', 
                referenceField: 'atual', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['atual'] / data.calculations['ano']) * 100).toFixed(1);
        }},
        //
        { name: 'totalProblemasPessoais',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'problemasPessoais',                   type: 'integer',calculateTotal: true, percent:"true", calculateType: 'average' , 
                referenceField: 'totalProblemasPessoais', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['totalProblemasPessoais'] / data.calculations['numeroDeAbandono']) * 100).toFixed(1);
        }},
        { name: 'totalConflitoHorarioTransporte',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'conflitoHorarioTransporte',           type: 'integer',calculateTotal: true, percent:"true", calculateType: 'average' , 
        referenceField: 'totalConflitoHorarioTransporte', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['totalConflitoHorarioTransporte'] / data.calculations['numeroDeAbandono']) * 100).toFixed(1);
        }},
        { name: 'totalDificuldadesPropostaPrograma',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'dificuldadesPropostaPrograma',        type: 'integer',calculateTotal: true, percent:"true", calculateType: 'average' , 
                referenceField: 'totalDificuldadesPropostaPrograma', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['totalDificuldadesPropostaPrograma'] / data.calculations['numeroDeAbandono']) * 100).toFixed(1);
        }},
        { name: 'totalOutros',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'outros',                              type: 'integer',calculateTotal: true, percent:"true", calculateType: 'average' , 
                referenceField: 'totalOutros', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['totalOutros'] / data.calculations['numeroDeAbandono']) * 100).toFixed(1);
        }},
        //
        { name: 'conclusaoProjeto',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'percentagemConclusaoProjeto',       type: 'integer',calculateTotal: true, percent:"true", calculateType: 'average' , 
                referenceField: 'conclusaoProjeto', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['conclusaoProjeto'] / data.calculations['atual']) * 100).toFixed(1);
        }},
        { name: 'conclusaoModulo',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'percentagemConclusaoModulo',        type: 'integer',calculateTotal: true, percent:"true", calculateType: 'average' , 
                referenceField: 'conclusaoModulo', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['conclusaoModulo'] / data.calculations['atual']) * 100).toFixed(1);
        }},
        //
        { name: 'sim',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'totalSim',                 type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average'  , 
                referenceField: 'sim', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['sim'] / (data.calculations['sim']+data.calculations['nao'])) * 100).toFixed(1);
        }},
        { name: 'nao',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'totalNao',                 type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average' , 
                referenceField: 'nao', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['nao'] / (data.calculations['sim']+data.calculations['nao'])) * 100).toFixed(1);
        }}
    ]);

    angular.module('app.relatorios').constant('RESULTADO_FINAL_TURMA_FIELDS', [
        { name: 'ordem',                               type: 'integer', showFooter: false },
        { name: 'estado',                              type: 'string', showFooter: false  },
        { name: 'cidade',                              type: 'string', showFooter: false  },
        { name: 'escola',                              type: 'string', showFooter: false  },
        { name: 'professor',                           type: 'string', showFooter: false  },
        { name: 'turma',                               type: 'string', showFooter: false  },

        { name: 'freqProfNaFormacao',                  type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average'},
        { name: 'freqMediaMensalAlunosPrograma',       type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average'},
        { name: 'ano',                                 type: 'integer', calculateTotal: true, calculateType: 'sum' },

        // 
        { name: 'rec',                                 type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'transferenciaRecPercentagem',         type: 'integer', calculateTotal: true,  percent:"true", calculateType: 'average', 
                referenceField: 'rec', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['rec'] / data.calculations['ano']) * 100).toFixed(1);
        } },
        { name: 'exp',                                 type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'transferenciaExpPercentagem',         type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average' , 
                referenceField: 'exp', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['exp'] / data.calculations['ano']) * 100).toFixed(1);
        }},
        { name: 'numeroDeAbandono',                    type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'percentagemAbandono',                 type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average' , 
                referenceField: 'numeroDeAbandono', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['numeroDeAbandono'] / data.calculations['ano']) * 100).toFixed(1);
        }},
        { name: 'atual',                               type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'percentagemMatricula',                type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average', 
                referenceField: 'atual', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['atual'] / data.calculations['ano']) * 100).toFixed(1);
        }},
        //
        { name: 'totalProblemasPessoais',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'problemasPessoais',                   type: 'integer',calculateTotal: true, percent:"true", calculateType: 'average' , 
                referenceField: 'totalProblemasPessoais', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['totalProblemasPessoais'] / data.calculations['numeroDeAbandono']) * 100).toFixed(1);
        }},
        { name: 'totalConflitoHorarioTransporte',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'conflitoHorarioTransporte',           type: 'integer',calculateTotal: true, percent:"true", calculateType: 'average' , 
        referenceField: 'totalConflitoHorarioTransporte', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['totalConflitoHorarioTransporte'] / data.calculations['numeroDeAbandono']) * 100).toFixed(1);
        }},
        { name: 'totalDificuldadesPropostaPrograma',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'dificuldadesPropostaPrograma',        type: 'integer',calculateTotal: true, percent:"true", calculateType: 'average' , 
                referenceField: 'totalDificuldadesPropostaPrograma', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['totalDificuldadesPropostaPrograma'] / data.calculations['numeroDeAbandono']) * 100).toFixed(1);
        }},
        { name: 'totalOutros',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'outros',                              type: 'integer',calculateTotal: true, percent:"true", calculateType: 'average' , 
                referenceField: 'totalOutros', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['totalOutros'] / data.calculations['numeroDeAbandono']) * 100).toFixed(1);
        }},
        //
        { name: 'conclusaoProjeto',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'percentagemConclusaoProjeto',       type: 'integer',calculateTotal: true, percent:"true", calculateType: 'average' , 
                referenceField: 'conclusaoProjeto', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['conclusaoProjeto'] / data.calculations['atual']) * 100).toFixed(1);
        }},
        { name: 'conclusaoModulo',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'percentagemConclusaoModulo',        type: 'integer',calculateTotal: true, percent:"true", calculateType: 'average' , 
                referenceField: 'conclusaoModulo', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['conclusaoModulo'] / data.calculations['atual']) * 100).toFixed(1);
        }},
        //
        { name: 'haProfissionalApoio',               type: 'string' }
    ]);

    angular.module('app.relatorios').constant('RESULTADO_FINAL_PROFESSOR_FIELDS', [ 
        { name: 'ordem',                               type: 'integer' , showFooter: false },     
        { name: 'professor',                                type: 'string'  , showFooter: false},            
        { name: 'numeroTurmas',                        type: 'integer' , calculateTotal: true, calculateType: 'sum'},
        
        { name: 'haProfissionalApoio',                 type: 'string'},
        
        { name: 'freqProfNaFormacao',                  type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average'},

        { name: 'freqAulasMat',                        type: 'integer', calculateTotal: true, percent:"true", calculateType: 'sum', hide: true },
        { name: 'aulasMat',                            type: 'integer', calculateTotal: true, percent:"true", calculateType: 'sum', hide: true },
        { name: 'freqMediaMensalAlunosPrograma',       type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average', 
                referenceField: 'freqAulasMat', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['freqAulasMat'] / data.calculations['aulasMat']) * 100).toFixed(1);
        }},

        { name: 'ano',                                 type: 'integer', calculateTotal: true, calculateType: 'sum' },
        
        // 
        { name: 'rec',                                 type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'transferenciaRecPercentagem',         type: 'integer', calculateTotal: true,  percent:"true", calculateType: 'average', 
                referenceField: 'rec', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['rec'] / data.calculations['ano']) * 100).toFixed(1);
        } },
        { name: 'exp',                                 type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'transferenciaExpPercentagem',         type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average' , 
                referenceField: 'exp', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['exp'] / data.calculations['ano']) * 100).toFixed(1);
        }},
        { name: 'numeroDeAbandono',                    type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'percentagemAbandono',                 type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average' , 
                referenceField: 'numeroDeAbandono', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['numeroDeAbandono'] / data.calculations['ano']) * 100).toFixed(1);
        }},
        { name: 'atual',                               type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'percentagemMatricula',                type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average', 
                referenceField: 'atual', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['atual'] / data.calculations['ano']) * 100).toFixed(1);
        }},
        //
        { name: 'totalProblemasPessoais',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'problemasPessoais',                   type: 'integer',calculateTotal: true, percent:"true", calculateType: 'average' , 
                referenceField: 'totalProblemasPessoais', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['totalProblemasPessoais'] / data.calculations['numeroDeAbandono']) * 100).toFixed(1);
        }},
        { name: 'totalConflitoHorarioTransporte',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'conflitoHorarioTransporte',           type: 'integer',calculateTotal: true, percent:"true", calculateType: 'average' , 
        referenceField: 'totalConflitoHorarioTransporte', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['totalConflitoHorarioTransporte'] / data.calculations['numeroDeAbandono']) * 100).toFixed(1);
        }},
        { name: 'totalDificuldadesPropostaPrograma',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'dificuldadesPropostaPrograma',        type: 'integer',calculateTotal: true, percent:"true", calculateType: 'average' , 
                referenceField: 'totalDificuldadesPropostaPrograma', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['totalDificuldadesPropostaPrograma'] / data.calculations['numeroDeAbandono']) * 100).toFixed(1);
        }},
        { name: 'totalOutros',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'outros',                              type: 'integer',calculateTotal: true, percent:"true", calculateType: 'average' , 
                referenceField: 'totalOutros', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['totalOutros'] / data.calculations['numeroDeAbandono']) * 100).toFixed(1);
        }},
        //
        { name: 'conclusaoProjeto',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'percentagemConclusaoProjeto',       type: 'integer',calculateTotal: true, percent:"true", calculateType: 'average' , 
                referenceField: 'conclusaoProjeto', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['conclusaoProjeto'] / data.calculations['atual']) * 100).toFixed(1);
        }},
        { name: 'conclusaoModulo',              type: 'integer',calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'percentagemConclusaoModulo',        type: 'integer',calculateTotal: true, percent:"true", calculateType: 'average' , 
                referenceField: 'conclusaoModulo', relCalcCustomTotal: function(data) {
                return parseFloat((data.calculations['conclusaoModulo'] / data.calculations['atual']) * 100).toFixed(1);
        }},
    ]);

    /**
     * 
     *  Constantes 8.12 Letramento em programacao
     */


    /**
     *  SERIES
     */

    angular.module('app.relatorios').constant('RESULTADO_FINAL_IDADE_CONCLUSAO_4SERIE_5ANO_FIELDS', [
        { name: 'id', type: 'integer', calculateTotal: false, showFooter: false  },
        { name: 'detalhamento', type: 'string', calculateTotal: false, showFooter: false  },
        
        { name: 'totalaprovados', type: 'integer', calculateTotal: true, calculateType: 'sum', 
            calcTotal: function(data) {
                var total = 0;
                for(var i = 0; i < data.length; i++) {
                    total += data[i]['totalaprovados'];
                }
                return total;
            } 
        },

        { name: 'faixaetaria9total', type: 'integer', calculateTotal: false, calculateType: 'sum',
            calcTotal: function(data) {
                var total = 0;
                for(var i = 0; i < data.length; i++) {
                    total += data[i]['faixaetaria9total'];
                }
                return total;
            }   
        },
        
        { name: 'faixaetaria9percentual', type: 'integer', calculateTotal: false, percent:"true",
        calculateType: 'average' , referenceField: 'faixaetaria9percentual', 
           
            calcTotal: function(data) {
                var total1 = 0;
                var total2 = 0;
                for(var i = 0; i < data.length; i++) {
                    total1 += data[i]['faixaetaria9total'];
                    total2 += data[i]['totalaprovados'];
                }
                var result = parseFloat(total1/total2*100).toFixed(1).toString().replace(".",",");
                
                if(result === "NaN"){
                    result = 0
                }
                return result 
            }   
        },
        { name: 'faixaetaria10total', type: 'integer', calculateTotal: false, calculateType: 'sum',
           
            calcTotal: function(data) {
                var total = 0;
                for(var i = 0; i < data.length; i++) {
                    total += data[i]['faixaetaria10total'];
                }
                return total;
            }
        },
        
        { name: 'faixaetaria10percentual', type: 'integer', calculateTotal: false, percent:"true", 
        calculateType: 'average',
        
            calcTotal: function(data) {
                var total1 = 0;
                var total2 = 0;
                for(var i = 0; i < data.length; i++) {
                    total1 += data[i]['faixaetaria10total'];
                    total2 += data[i]['totalaprovados'];
                }
                var result = parseFloat(total1/total2*100).toFixed(1).toString().replace(".",",");
                
                if(result === "NaN"){
                    result = 0
                }
                return result 
            }
    
        },
        
        { name: 'faixaetaria11total', type: 'integer', calculateTotal: false, calculateType: 'sum',
    
            calcTotal: function(data) {
                var total = 0;
                for(var i = 0; i < data.length; i++) {
                    total += data[i]['faixaetaria11total'];
                }
                return total;
            }
        },
        
        { name: 'faixaetaria11percentual', type: 'integer', calculateTotal: false, percent:"true", 
        calculateType: 'average',
            
            calcTotal: function(data) {
                var total1 = 0;
                var total2 = 0;
                for(var i = 0; i < data.length; i++) {
                    total1 += data[i]['faixaetaria11total'];
                    total2 += data[i]['totalaprovados'];
                }
                var result = parseFloat(total1/total2*100).toFixed(1).toString().replace(".",",");
                
                if(result === "NaN"){
                    result = 0
                }
                return result 
            }
    
        },
        
        { name: 'faixaetaria12total', type: 'integer', calculateTotal: false, calculateType: 'sum',
            
            calcTotal: function(data) {
                var total = 0;
                for(var i = 0; i < data.length; i++) {
                    total += data[i]['faixaetaria12total'];
                }
                return total;
            }
        },
        
        { name: 'faixaetaria12percentual', type: 'integer', calculateTotal: false, percent:"true", 
        calculateType: 'average'  ,

            calcTotal: function(data) {
                var total1 = 0;
                var total2 = 0;
                for(var i = 0; i < data.length; i++) {
                    total1 += data[i]['faixaetaria12total'];
                    total2 += data[i]['totalaprovados'];
                }
                var result = parseFloat(total1/total2*100).toFixed(1).toString().replace(".",",");
                
                if(result === "NaN"){
                    result = 0
                }
                return result 
            }
    
        },
        
        { name: 'faixaetaria13total', type: 'integer', calculateTotal: false, calculateType: 'sum',
        
            calcTotal: function(data) {
                var total = 0;
                for(var i = 0; i < data.length; i++) {
                    total += data[i]['faixaetaria13total'];
                }
                return total;
            }
        },
        
        { name: 'faixaetaria13percentual', type: 'integer', calculateTotal: false, percent:"true", 
        calculateType: 'average',

            calcTotal: function(data) {
                var total1 = 0;
                var total2 = 0;
                for(var i = 0; i < data.length; i++) {
                    total1 += data[i]['faixaetaria13total'];
                    total2 += data[i]['totalaprovados'];
                }
                var result = parseFloat(total1/total2*100).toFixed(1).toString().replace(".",",");
                
                if(result === "NaN"){
                    result = 0
                }
                return result 
            }
        },
        
        { name: 'faixaetaria14total', type: 'integer', calculateTotal: false, calculateType: 'sum',
        
            calcTotal: function(data) {
                var total = 0;
                for(var i = 0; i < data.length; i++) {
                    total += data[i]['faixaetaria14total'];
                }
                return total;
            }
        },
        
        { name: 'faixaetaria14percentual', type: 'integer', calculateTotal: false, percent:"true", 
        calculateType: 'average',
            
            calcTotal: function(data) {
                var total1 = 0;
                var total2 = 0;
                for(var i = 0; i < data.length; i++) {
                    total1 += data[i]['faixaetaria14total'];
                    total2 += data[i]['totalaprovados'];
                }
                var result = parseFloat(total1/total2*100).toFixed(1).toString().replace(".",",");
                
                if(result === "NaN"){
                    result = 0
                }
                return result 
            }
    
        },
        
        { name: 'faixaetaria15total', type: 'integer', calculateTotal: false, calculateType: 'sum',

            calcTotal: function(data) {
                var total = 0;
                for(var i = 0; i < data.length; i++) {
                    total += data[i]['faixaetaria15total'];
                }
                return total;
            }
        },
        
        { name: 'faixaetaria15percentual', type: 'integer', calculateTotal: false, percent:"true", 
        calculateType: 'average',

            calcTotal: function(data) {
                var total1 = 0;
                var total2 = 0;
                for(var i = 0; i < data.length; i++) {
                    total1 += data[i]['faixaetaria15total'];
                    total2 += data[i]['totalaprovados'];
                }
                var result = parseFloat(total1/total2*100).toFixed(1).toString().replace(".",",");
                
                if(result === "NaN"){
                    result = 0
                }
                return result 
            }
        },
        
        { name: 'matriculafinal', type: 'integer', calculateTotal: false, calculateType: 'sum',
    
            calcTotal: function(data) {
                var total = 0;
                for(var i = 0; i < data.length; i++) {
                    total += data[i]['matriculafinal'];
                }
                return total;
            }
        },
        
        { name: 'percentualaprovados', type: 'integer', calculateTotal: false, percent:"true", 
        calculateType: 'average',

            calcTotal: function(data) {
                var total1 = 0;
                var total2 = 0;
                for(var i = 0; i < data.length; i++) {
                    total1 += data[i]['matriculafinal'];
                    total2 += data[i]['totalaprovados'];
                }
                var result = parseFloat(total2/total1*100).toFixed(1).toString().replace(".",",");
                
                if(result === "NaN"){
                    result = 0
                }
                return result 
            }
        }
        
    ]);

    angular.module('app.relatorios').constant('RESULTADO_FINAL_IDADE_CONCLUSAO_4SERIE_5ANO_ESTADO_FIELDS', [
        { name: 'id', type: 'integer', calculateTotal: false, showFooter: false  },
        { name: 'uf', type: 'string', calculateTotal: false, showFooter: false  },
        { name: 'detalhamento', type: 'string', calculateTotal: false, showFooter: false  },
        
        { name: 'totalaprovados', type: 'integer', calculateTotal: true, calculateType: 'sum', 
            calcTotal: function(data) {
                var total = 0;
                for(var i = 0; i < data.length; i++) {
                    total += data[i]['totalaprovados'];
                }
                return total;
            } 
        },

        { name: 'faixaetaria9total', type: 'integer', calculateTotal: false, calculateType: 'sum',
            calcTotal: function(data) {
                var total = 0;
                for(var i = 0; i < data.length; i++) {
                    total += data[i]['faixaetaria9total'];
                }
                return total;
            }   
        },
        
        { name: 'faixaetaria9percentual', type: 'integer', calculateTotal: false, percent:"true",
        calculateType: 'average' , referenceField: 'faixaetaria9percentual', 
           
            calcTotal: function(data) {
                var total1 = 0;
                var total2 = 0;
                for(var i = 0; i < data.length; i++) {
                    total1 += data[i]['faixaetaria9total'];
                    total2 += data[i]['totalaprovados'];
                }
                var result = parseFloat(total1/total2*100).toFixed(1).toString().replace(".",",");
                
                if(result === "NaN"){
                    result = 0
                }
                return result 
            }   
        },
        { name: 'faixaetaria10total', type: 'integer', calculateTotal: false, calculateType: 'sum',
           
            calcTotal: function(data) {
                var total = 0;
                for(var i = 0; i < data.length; i++) {
                    total += data[i]['faixaetaria10total'];
                }
                return total;
            }
        },
        
        { name: 'faixaetaria10percentual', type: 'integer', calculateTotal: false, percent:"true", 
        calculateType: 'average',
        
            calcTotal: function(data) {
                var total1 = 0;
                var total2 = 0;
                for(var i = 0; i < data.length; i++) {
                    total1 += data[i]['faixaetaria10total'];
                    total2 += data[i]['totalaprovados'];
                }
                var result = parseFloat(total1/total2*100).toFixed(1).toString().replace(".",",");
                
                if(result === "NaN"){
                    result = 0
                }
                return result 
            }
    
        },
        
        { name: 'faixaetaria11total', type: 'integer', calculateTotal: false, calculateType: 'sum',
    
            calcTotal: function(data) {
                var total = 0;
                for(var i = 0; i < data.length; i++) {
                    total += data[i]['faixaetaria11total'];
                }
                return total;
            }
        },
        
        { name: 'faixaetaria11percentual', type: 'integer', calculateTotal: false, percent:"true", 
        calculateType: 'average',
            
            calcTotal: function(data) {
                var total1 = 0;
                var total2 = 0;
                for(var i = 0; i < data.length; i++) {
                    total1 += data[i]['faixaetaria11total'];
                    total2 += data[i]['totalaprovados'];
                }
                var result = parseFloat(total1/total2*100).toFixed(1).toString().replace(".",",");
                
                if(result === "NaN"){
                    result = 0
                }
                return result 
            }
    
        },
        
        { name: 'faixaetaria12total', type: 'integer', calculateTotal: false, calculateType: 'sum',
            
            calcTotal: function(data) {
                var total = 0;
                for(var i = 0; i < data.length; i++) {
                    total += data[i]['faixaetaria12total'];
                }
                return total;
            }
        },
        
        { name: 'faixaetaria12percentual', type: 'integer', calculateTotal: false, percent:"true", 
        calculateType: 'average'  ,

            calcTotal: function(data) {
                var total1 = 0;
                var total2 = 0;
                for(var i = 0; i < data.length; i++) {
                    total1 += data[i]['faixaetaria12total'];
                    total2 += data[i]['totalaprovados'];
                }
                var result = parseFloat(total1/total2*100).toFixed(1).toString().replace(".",",");
                
                if(result === "NaN"){
                    result = 0
                }
                return result 
            }
    
        },
        
        { name: 'faixaetaria13total', type: 'integer', calculateTotal: false, calculateType: 'sum',
        
            calcTotal: function(data) {
                var total = 0;
                for(var i = 0; i < data.length; i++) {
                    total += data[i]['faixaetaria13total'];
                }
                return total;
            }
        },
        
        { name: 'faixaetaria13percentual', type: 'integer', calculateTotal: false, percent:"true", 
        calculateType: 'average',

            calcTotal: function(data) {
                var total1 = 0;
                var total2 = 0;
                for(var i = 0; i < data.length; i++) {
                    total1 += data[i]['faixaetaria13total'];
                    total2 += data[i]['totalaprovados'];
                }
                var result = parseFloat(total1/total2*100).toFixed(1).toString().replace(".",",");
                
                if(result === "NaN"){
                    result = 0
                }
                return result 
            }
        },
        
        { name: 'faixaetaria14total', type: 'integer', calculateTotal: false, calculateType: 'sum',
        
            calcTotal: function(data) {
                var total = 0;
                for(var i = 0; i < data.length; i++) {
                    total += data[i]['faixaetaria14total'];
                }
                return total;
            }
        },
        
        { name: 'faixaetaria14percentual', type: 'integer', calculateTotal: false, percent:"true", 
        calculateType: 'average',
            
            calcTotal: function(data) {
                var total1 = 0;
                var total2 = 0;
                for(var i = 0; i < data.length; i++) {
                    total1 += data[i]['faixaetaria14total'];
                    total2 += data[i]['totalaprovados'];
                }
                var result = parseFloat(total1/total2*100).toFixed(1).toString().replace(".",",");
                
                if(result === "NaN"){
                    result = 0
                }
                return result 
            }
    
        },
        
        { name: 'faixaetaria15total', type: 'integer', calculateTotal: false, calculateType: 'sum',

            calcTotal: function(data) {
                var total = 0;
                for(var i = 0; i < data.length; i++) {
                    total += data[i]['faixaetaria15total'];
                }
                return total;
            }
        },
        
        { name: 'faixaetaria15percentual', type: 'integer', calculateTotal: false, percent:"true", 
        calculateType: 'average',

            calcTotal: function(data) {
                var total1 = 0;
                var total2 = 0;
                for(var i = 0; i < data.length; i++) {
                    total1 += data[i]['faixaetaria15total'];
                    total2 += data[i]['totalaprovados'];
                }
                var result = parseFloat(total1/total2*100).toFixed(1).toString().replace(".",",");
                
                if(result === "NaN"){
                    result = 0
                }
                return result 
            }
        },
        
        { name: 'matriculafinal', type: 'integer', calculateTotal: false, calculateType: 'sum',
    
            calcTotal: function(data) {
                var total = 0;
                for(var i = 0; i < data.length; i++) {
                    total += data[i]['matriculafinal'];
                }
                return total;
            }
        },
        
        { name: 'percentualaprovados', type: 'integer', calculateTotal: false, percent:"true", 
        calculateType: 'average',

            calcTotal: function(data) {
                var total1 = 0;
                var total2 = 0;
                for(var i = 0; i < data.length; i++) {
                    total1 += data[i]['matriculafinal'];
                    total2 += data[i]['totalaprovados'];
                }
                var result = parseFloat(total2/total1*100).toFixed(1).toString().replace(".",",");
                
                if(result === "NaN"){
                    result = 0
                }
                return result 
            }
        }
        
    ]);

    angular.module('app.relatorios').constant('RESULTADO_FINAL_IDADE_CONCLUSAO_8SERIE_9ANO_FIELDS', [
        { name: 'id', type: 'integer', calculateTotal: false, showFooter: false },
        { name: 'detalhamento', type: 'string', calculateTotal: false, showFooter: false },

        { name: 'totalAprovados', type: 'integer', calculateTotal: false, calculateType: 'sum',

            calcTotal: function(data) {
                var total = 0;
                for(var i = 0; i < data.length; i++) {
                    total += data[i]['totalAprovados'];
                }
                return total;
            }
        },

        { name: 'faixaEtaria13Total', type: 'integer', calculateTotal: false, calculateType: 'sum', 
            
            calcTotal: function(data) {
                var total = 0;
                for(var i = 0; i < data.length; i++) {
                    total += data[i]['faixaEtaria13Total'];
                }
                return total;
            }
        },

        { name: 'faixaEtaria13Percentual', type: 'integer', calculateTotal: false, percent:"true", calculateType: 'average', 
            calcTotal: function(data) {
                var total1 = 0;
                var total2 = 0;
                for(var i = 0; i < data.length; i++) {
                    total1 += data[i]['faixaEtaria13Total'];
                    total2 += data[i]['totalAprovados'];
                }
                var result = parseFloat(total1/total2*100).toFixed(1).toString().replace(".",",");
                
                if(result === "NaN"){
                    result = 0
                }
                return result 
            }
        },

        { name: 'faixaEtaria14Total', type: 'integer', calculateTotal: false, calculateType: 'sum',
            
            calcTotal: function(data) {
                var total = 0;
                for(var i = 0; i < data.length; i++) {
                    total += data[i]['faixaEtaria14Total'];
                }
                return total;
            }
        },

        { name: 'faixaEtaria14Percentual', type: 'integer', calculateTotal: false, percent:"true", calculateType: 'average',

            calcTotal: function(data) {
                var total1 = 0;
                var total2 = 0;
                for(var i = 0; i < data.length; i++) {
                    total1 += data[i]['faixaEtaria14Total'];
                    total2 += data[i]['totalAprovados'];
                }
                var result = parseFloat(total1/total2*100).toFixed(1).toString().replace(".",",");
                
                if(result === "NaN"){
                    result = 0
                }
                return result 
            }
        },

        { name: 'faixaEtaria15Total', type: 'integer', calculateTotal: false, calculateType: 'sum',

            calcTotal: function(data) {
                var total = 0;
                for(var i = 0; i < data.length; i++) {
                    total += data[i]['faixaEtaria15Total'];
                }
                return total;
            }
        },
        
        { name: 'faixaEtaria15Percentual', type: 'integer', calculateTotal: false, percent:"true", calculateType: 'average',
            calcTotal: function(data) {
                var total1 = 0;
                var total2 = 0;
                for(var i = 0; i < data.length; i++) {
                    total1 += data[i]['faixaEtaria15Total'];
                    total2 += data[i]['totalAprovados'];
                }
                var result = parseFloat(total1/total2*100).toFixed(1).toString().replace(".",",");
                
                if(result === "NaN"){
                    result = 0
                }
                return result 
            }
        },

        { name: 'faixaEtaria16OuMaisTotal', type: 'integer', calculateTotal: false, calculateType: 'sum',

            calcTotal: function(data) {
                var total = 0;
                for(var i = 0; i < data.length; i++) {
                    total += data[i]['faixaEtaria16OuMaisTotal'];
                }
                return total;
            }
        },

        { name: 'faixaEtaria16OuMaisPercentual', type: 'integer', calculateTotal: false, percent:"true", calculateType: 'average',
            calcTotal: function(data) {
                var total1 = 0;
                var total2 = 0;
                for(var i = 0; i < data.length; i++) {
                    total1 += data[i]['faixaEtaria16OuMaisTotal'];
                    total2 += data[i]['totalAprovados'];
                }
                var result = parseFloat(total1/total2*100).toFixed(1).toString().replace(".",",");
                
                if(result === "NaN"){
                    result = 0
                }
                return result 
            }
        },
        
        { name: 'matriculaFinal', type: 'integer', calculateTotal: false, calculateType: 'sum',

            calcTotal: function(data) {
                var total = 0;
                for(var i = 0; i < data.length; i++) {
                    total += data[i]['matriculaFinal'];
                }
                return total;
            }
        },

        { name: 'percentualAprovados', type: 'integer', calculateTotal: false, percent:"true", calculateType: 'average',
            calcTotal: function(data) {
                var total1 = 0;
                var total2 = 0;
                for(var i = 0; i < data.length; i++) {
                    total1 += data[i]['matriculaFinal'];
                    total2 += data[i]['totalAprovados'];
                }
                var result = parseFloat(total2/total1*100).toFixed(1).toString().replace(".",",");
                
                if(result === "NaN"){
                    result = 0
                }
                return result 
            }
        }        
    ]);
    /* Outros - Idade de Conclusão 8a Série / 9o Ano */
    angular.module('app.relatorios').constant('RESULTADO_FINAL_IDADE_CONCLUSAO_8SERIE_9ANO_ESTADO_FIELDS', [
        { name: 'id', type: 'integer', calculateTotal: false, showFooter: false },
        { name: 'uf', type: 'string', calculateTotal: false, showFooter: false  },
        { name: 'detalhamento', type: 'string', calculateTotal: false, showFooter: false },

        { name: 'totalAprovados', type: 'integer', calculateTotal: true, calculateType: 'sum',

            calcTotal: function(data) {
                var total = 0;
                for(var i = 0; i < data.length; i++) {
                    total += data[i]['totalAprovados'];
                }
                return total;
            }
        },

        { name: 'faixaEtaria13Total', type: 'integer', calculateTotal: true, calculateType: 'sum', 
            
            calcTotal: function(data) {
                var total = 0;
                for(var i = 0; i < data.length; i++) {
                    total += data[i]['faixaEtaria13Total'];
                }
                return total;
            }
        },

        { name: 'faixaEtaria13Percentual', type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average', 
            calcTotal: function(data) {
                var total1 = 0;
                var total2 = 0;
                for(var i = 0; i < data.length; i++) {
                    total1 += data[i]['faixaEtaria13Total'];
                    total2 += data[i]['totalAprovados'];
                }
                var result = parseFloat(total1/total2*100).toFixed(1).toString().replace(".",",");
                
                if(result === "NaN"){
                    result = 0
                } 
                return result 
            }
        },

        { name: 'faixaEtaria14Total', type: 'integer', calculateTotal: true, calculateType: 'sum',
            
            calcTotal: function(data) {
                var total = 0;
                for(var i = 0; i < data.length; i++) {
                    total += data[i]['faixaEtaria14Total'];
                }
                return total;
            }
        },

        { name: 'faixaEtaria14Percentual', type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average',

            calcTotal: function(data) {
                var total1 = 0;
                var total2 = 0;
                for(var i = 0; i < data.length; i++) {
                    total1 += data[i]['faixaEtaria14Total'];
                    total2 += data[i]['totalAprovados'];
                }
                var result = parseFloat(total1/total2*100).toFixed(1).toString().replace(".",",");
                
                if(result === "NaN"){
                    result = 0
                }
                return result 
            }
        },

        { name: 'faixaEtaria15Total', type: 'integer', calculateTotal: true, calculateType: 'sum',

            calcTotal: function(data) {
                var total = 0;
                for(var i = 0; i < data.length; i++) {
                    total += data[i]['faixaEtaria15Total'];
                }
                return total;
            }
        },
        
        { name: 'faixaEtaria15Percentual', type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average',
            calcTotal: function(data) {
                var total1 = 0;
                var total2 = 0;
                for(var i = 0; i < data.length; i++) {
                    total1 += data[i]['faixaEtaria15Total'];
                    total2 += data[i]['totalAprovados'];
                }
                var result = parseFloat(total1/total2*100).toFixed(1).toString().replace(".",",");
                
                if(result === "NaN"){
                    result = 0
                }
                return result 
            }
        },

        { name: 'faixaEtaria16OuMaisTotal', type: 'integer', calculateTotal: true, calculateType: 'sum',

            calcTotal: function(data) {
                var total = 0;
                for(var i = 0; i < data.length; i++) {
                    total += data[i]['faixaEtaria16OuMaisTotal'];
                }
                return total;
            }
        },

        { name: 'faixaEtaria16OuMaisPercentual', type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average',
            calcTotal: function(data) {
                var total1 = 0;
                var total2 = 0;
                for(var i = 0; i < data.length; i++) {
                    total1 += data[i]['faixaEtaria16OuMaisTotal'];
                    total2 += data[i]['totalAprovados'];
                }
                var result = parseFloat(total1/total2*100).toFixed(1).toString().replace(".",",");
                
                if(result === "NaN"){
                    result = 0
                }
                return result 
            }
        },
        
        { name: 'matriculaFinal', type: 'integer', calculateTotal: true, calculateType: 'sum',

            calcTotal: function(data) {
                var total = 0;
                for(var i = 0; i < data.length; i++) {
                    total += data[i]['matriculaFinal'];
                }
                return total;
            }
        },

        { name: 'percentualAprovados', type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average',
            calcTotal: function(data) {
                var total1 = 0;
                var total2 = 0;
                for(var i = 0; i < data.length; i++) {
                    total1 += data[i]['matriculaFinal'];
                    total2 += data[i]['totalAprovados'];
                }
                var result = parseFloat(total2/total1*100).toFixed(1).toString().replace(".",",");
                
                if(result === "NaN"){
                    result = 0
                }
                
                return result 
            }
        }        
    ]);

     /**
      * 
      * SERIES
      */
}());