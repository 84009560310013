(function(){
    'use strict';

    angular.module('app.dependenciaAdministrativa').service('dependenciaAdministrativaService', DependenciaAdministrativaService);
    DependenciaAdministrativaService.$inject = ['DependenciaAdministrativa', 'STATUS_ENUM'];

    function DependenciaAdministrativaService(DependenciaAdministrativa, STATUS_ENUM) {

        this.filter = function(filter) {
            return DependenciaAdministrativa.filter(filter).$promise;            
        };

        this.get = function(id) {
            return DependenciaAdministrativa.get({ id: id }).$promise;
        };

        this.delete = function(data) {
            return DependenciaAdministrativa.delete({id: data.id}).$promise;
        };

        this.ativos = function() {
            return DependenciaAdministrativa.ativo();
        };

    }

}());
