(function() {
    'use strict';

    angular.module('app.uf', ['app.core']).config(config);
    config.$inject = ['$stateProvider', 'APP_STATE'];

    function config($stateProvider, APP_STATE) {
        $stateProvider
            .state(APP_STATE.UF.BASE, {
                url: '/uf',
                abstract: true,
                views: {
                    'content': {
                        template: '<div ui-view="internal"></div>',
                        controller: 'UfController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state(APP_STATE.UF.SEARCH, {
                url: '/search',
                views: {
                    'internal': {
                        templateUrl: 'uf/partials/uf-search.html'
                    }
                },
                data: {
                    permission: "UNF:RE",
                    breadCrumb: [{
                            display: "Cadastros",
                            active: false
                        },
                        {
                            display: "UFs",
                            active: true
                        }
                    ],
                    search: {
                        enabled: false
                    }
                }
            })
            .state(APP_STATE.UF.NEW, {
                url: '/new',
                views: {
                    'internal': {
                        templateUrl: 'uf/partials/uf-form.html',
                        controller: 'UfFormController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    permission: "UNF:CR",
                    breadCrumb: [{
                            display: "Cadastros",
                            active: false
                        },
                        {
                            display: "UFs",
                            active: false,
                            stateName: APP_STATE.UF.SEARCH
                        },
                        {
                            display: "Nova",
                            active: true
                        }
                    ],
                    search: {
                        placeHolder: "Digite o nome da UF",
                        enabled: false
                    }
                }
            })
            .state(APP_STATE.UF.EDIT, {
                url: '/edit/:id',
                views: {
                    'internal': {
                        templateUrl: 'uf/partials/uf-form.html',
                        controller: 'UfFormController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    permission: "UNF:VI",
                    breadCrumb: [{
                            display: "Cadastros",
                            active: false
                        },
                        {
                            display: "UFs",
                            active: false,
                            stateName: APP_STATE.UF.SEARCH
                        },
                        {
                            display: "Edição",
                            active: true
                        }
                    ],
                    search: {
                        placeHolder: "Digite o nome da UF",
                        enabled: false
                    }
                }
            });
    }
}());