(function() {
    'use strict';

    angular.module('app.periodoLetivo', ['app.core']).config(config);
    config.$inject = ['$stateProvider', 'APP_STATE'];

    function config($stateProvider, APP_STATE) {
        $stateProvider.state(APP_STATE.PERIODO_LETIVO.BASE, {
                url: '/periodo-letivo',
                abstract: true,
                views: {
                    'content': {
                        template: '<div ui-view="internal"></div>',
                        controller: 'PeriodoLetivoController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state(APP_STATE.PERIODO_LETIVO.SEARCH, {
                url: '/search',
                views: {
                    'internal': {
                        templateUrl: 'periodo-letivo/partials/periodo-letivo-search.html'
                    }
                },
                data: {
                    permission: "PEL:RE",
                    breadCrumb: [{
                            display: "Cadastros",
                            active: false
                        },
                        {
                            display: "Períodos Letivos",
                            active: true
                        }
                    ],
                    search: {
                        enabled: false
                    }
                }
            })
            .state(APP_STATE.PERIODO_LETIVO.NEW, {
                url: '/new',
                views: {
                    'internal': {
                        templateUrl: 'periodo-letivo/partials/periodo-letivo-form.html',
                        controller: 'PeriodoLetivoFormController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    permission: "PEL:CR",
                    breadCrumb: [{
                            display: "Cadastros",
                            active: false
                        },
                        {
                            display: "Períodos Letivos",
                            active: false,
                            stateName: APP_STATE.PERIODO_LETIVO.SEARCH
                        },
                        {
                            display: "Novo",
                            active: true
                        }
                    ],
                    search: {
                        placeHolder: "Digite o período letivo",
                        enabled: false
                    }
                }
            })
            .state(APP_STATE.PERIODO_LETIVO.EDIT, {
                url: '/edit/:id',
                views: {
                    'internal': {
                        templateUrl: 'periodo-letivo/partials/periodo-letivo-form.html',
                        controller: 'PeriodoLetivoFormController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    permission: "PEL:VI",
                    breadCrumb: [{
                            display: "Cadastros",
                            active: false
                        },
                        {
                            display: "Períodos Letivos",
                            active: false,
                            stateName: APP_STATE.PERIODO_LETIVO.SEARCH
                        },
                        {
                            display: "Edição",
                            active: true
                        }
                    ],
                    search: {
                        placeHolder: "Digite o período letivo",
                        enabled: false
                    }
                }
            });
    }
}());