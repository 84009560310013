(function() {
    'use strict';

    angular.module('app.core').service('dialogService', DialogService);
    DialogService.$inject = ['$uibModal'];

    function DialogService($uibModal) {

        var _createModal = function(message, hasCancelButton, title) {
            var modal = $uibModal.open({
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl: 'core/service/dialog/partials/dialog-modal.html',
                controllerAs: 'vm',
                size: 'lg',
                controller: function($uibModalInstance) {
                    var _vm = this;

                    _vm.title = "";
                    if (title) {
                        _vm.title = title;
                    }

                    _vm.message = message;
                    _vm.hasCancelButton = hasCancelButton || false;

                    _vm.ok = function() {
                        $uibModalInstance.close(true);
                    };

                    _vm.cancel = function() {
                        $uibModalInstance.dismiss('cancel');
                    };
                }
            });

            return modal.result;
        };

        this.info = function(message, title) {
            var titleModel = (title) ? title : "";
            return _createModal(message, false, titleModel);
        }

        this.confirm = function(message, title) {
            var titleModel = (title) ? title : "Confirmar";
            return _createModal(message, true, titleModel);
        };

        this.delete = function(msn) {
            var msnConfirm = (msn) ? msn : "Deseja realmente excluir este item?";
            return _createModal(msnConfirm, true, "Excluir");
        };

        this.modal = function(templateUrl, controller) {
            var modal = $uibModal.open({
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl: templateUrl,
                size: 'lg',
                controllerAs: 'vm',
                controller: controller
            });

            return modal;
        };

    }

}());