(function(){
    'use strict';

    angular.module('app.escala').service('escalaService', EscalaService);
    EscalaService.$inject = ['$filter', 'Escala'];

    function EscalaService($filter, Escala) {

        this.filter = function(filter) {
            return Escala.filter(filter).$promise;            
        };

        this.get = function(id) {
            return Escala.get({ id: id }).$promise;
        };

        this.getActives = function() {
            return Escala.findByStatus({status: 'ATIVO'}).$promise;
        };

        this.save = function(escala, items, newItems) {
            var allItems = items.concat(newItems);

            var orderedItems = _.map(allItems, function(value, index) {
                value.nrOrdem = index + 1;

                return value;
            });

            var entity = new Escala(escala);
            entity.escalaItensDTO = orderedItems;

            if (entity.id) {
                return entity.$update();
            } else {
                return entity.$save();
            }
        };

        this.delete = function(escala) {
            return Escala.delete({id: escala.id}).$promise;
        };

        this.findItensByEscalaId = function(escalaId) {
            return Escala.findItens({id: escalaId}).$promise;
        };

        this.findItensAtivosByEscalaId = function(escalaId) {
            return Escala.findItens({id: escalaId, situacao: 'ATIVO'}).$promise;
        };

        this.isUsed = function(id) {
            return Escala.isUsed({id: id}).$promise;
        };

    }

}());