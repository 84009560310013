(function() {
    'use strict';

    angular.module('app.coletaRubrica').controller('ColetaRubricaController', ColetaRubricaController);
    ColetaRubricaController.$inject = ['APP_STATE', 'APP_EVENT', 'COLETA_RUBRICA_SEARCH_BY', '$state', '$scope',
        'coletaRubricaService', 'dialogService', 'toastService', 'Upload', 'SecurityManagerFactory', 
        'COLETA_RUBRICA_ANONIMA_DEFAULT_FIELDS', 'STATUS_ENUM_OBJECT', 'VALIDATION_PATTERN'
    ];

    function ColetaRubricaController(APP_STATE, APP_EVENT, COLETA_RUBRICA_SEARCH_BY, $state, $scope,
        coletaRubricaService, dialogService, toastService, Upload, SecurityManagerFactory, 
        COLETA_RUBRICA_ANONIMA_DEFAULT_FIELDS, STATUS_ENUM_OBJECT, VALIDATION_PATTERN) {

        var vm = this;
        var MODAL_TEMPLATE = 'coleta-rubrica/partials/coleta-rubrica-search-preview.html';
        var MODAL_SEND_MAILTO = 'coleta-rubrica/partials/send-mailto-modal.html';

        vm.isColetaRubricaAnonima = function(coletaRubrica) {
            return coletaRubrica.tipoAcesso.key === COLETA_RUBRICA_ANONIMA_DEFAULT_FIELDS.GRANULARIDADE.key;
        };

        vm.isColetaRubricaAnonimaAtiva = function(coletaRubrica) {
            if (coletaRubrica.situacao.key == STATUS_ENUM_OBJECT.ATIVO.key) {
                return vm.isColetaRubricaAnonima(coletaRubrica);
            }

            return false;
        };

        vm.goNewPage = function() {
            $state.go(APP_STATE.COLETA_RUBRICA.NEW);
        };

        vm.goEditPage = function(id) {
            $state.go(APP_STATE.COLETA_RUBRICA.EDIT, { id: id });
        };

        vm.clearSearchValue = function() {
            vm.searchValue = "";
            vm.searchValueModel = "";
        };

        vm.search = function() {
            vm.coletaRubricas = [];
            vm.coletaRubricasTotal = 0;
            if( !vm.searchValueModel && !vm.searchValue){
                return;
            }
            vm.searchWasPerformed = false;
            vm.searchValue = vm.searchValueModel || vm.searchValue;

            if (!vm.searchValueModel) {
                vm.searchValueModel = vm.searchValue;
            }

            if (vm.searchValue) {
                var filter = {};

                if (angular.isArray(vm.searchValue)) {
                    filter[vm.searchType.field] = _.map(vm.searchValue, function(value) {
                        return value.key;
                    });
                } else {
                    filter[vm.searchType.field] = vm.searchValue;
                }

                coletaRubricaService.filter(filter)
                    .then(function(data) {
                        vm.coletaRubricas = angular.copy(data);
                    }, function(response) {
                        toastService.error({
                            status: response.status,
                            message: response.data.message
                        });
                    })
                    .finally(function() {
                        vm.searchWasPerformed = true;
                        vm.coletaRubricasTotal = vm.coletaRubricas.length;
                        vm.formSearch.$setPristine();
                    });
            }
        };

        vm.delete = function(coletaRubrica) {
            dialogService.delete().then(function() {
                coletaRubricaService.delete({ id: coletaRubrica.id })
                    .then(function() {
                        toastService.success("Registro removido com sucesso.");
                        vm.search();
                    }, function(response) {
                        toastService.error({
                            status: response.status,
                            message: response.data.message
                        });
                    });
            }, function() {});
        };

        var _populateSearchFields = function() {
            coletaRubricaService.findTipos().then(function(tipos) {
                vm.tipos = tipos;
            });

            coletaRubricaService.findStatus().then(function(situacoes) {
                vm.situacoes = situacoes;
            });

            coletaRubricaService.findAbrangencias().then(function(abrangencias) {
                vm.abrangencias = abrangencias;
            });

            coletaRubricaService.findGranularidades().then(function(granularidades) {
                vm.granularidades = granularidades;
            });

            coletaRubricaService.findPeriodicidades().then(function(periodicidades) {
                vm.periodicidades = periodicidades;
            });

            coletaRubricaService.findAcessos().then(function(acessos) {
                vm.acessos = acessos;
            });
        };

        vm.preview = function(coletaRubrica) {
            coletaRubricaService.viewById(coletaRubrica.id).then(function(coletaRubricaView) {
                dialogService.modal(MODAL_TEMPLATE, function($uibModalInstance) {
                    var _vm = this;
                    _vm.coletaRubricaView = coletaRubricaView;
                    _vm.modalTitle = coletaRubrica.nome;

                    _vm.cancel = function() {
                        $uibModalInstance.dismiss('cancel');
                    };
                });

            }, function(response) {
                toastService.error({
                    status: response.status,
                    message: response.data.message
                });
            });
        };

        vm.goResponsePage = function(coletaRubrica) {
            $state.go(APP_STATE.RESPOSTA_COLETA_RUBRICA.SEARCH, { coletaRubrica: coletaRubrica });
        };

        vm.sendByEmail = function(coletaRubrica) {
            if (vm.isColetaRubricaAnonimaAtiva(coletaRubrica)) {
                var modal = dialogService.modal(MODAL_SEND_MAILTO, function($uibModalInstance) {
                    var _vm = this;
                    _vm.emails = [];

                    _vm.emailPattern = VALIDATION_PATTERN.EMAIL.source;
                    
                    _vm.ok = function(form) {
                        if (form.$valid && is.not.empty(_vm.emails)) {
                            var emails = _.map(_vm.emails, function(tag) {
                                return tag.text;
                            });
                            coletaRubricaService.sendEmails(coletaRubrica.id, emails).then(function(result) {
                                $uibModalInstance.close(result);
                            }, function(response) {
                                toastService.error({
                                    status: response.status,
                                    message: response.data.message
                                }, 'flash-modal');
                            });
                        }
                    };

                    _vm.cancel = function() {
                        $uibModalInstance.dismiss('cancel');
                    };
                });

                modal.result.then(function(result) {
                    toastService.success("Emails enviados com sucesso.");
                }, function() {});
            }
        };

        (function() {
            vm.hasPermissionView = SecurityManagerFactory.hasPermission('COR:VI');
            vm.coletaRubricas = [];
            vm.searchValue = "";
            vm.SEARCH_BY = COLETA_RUBRICA_SEARCH_BY;
            vm.searchType = vm.SEARCH_BY.NAME;
            vm.searchWasPerformed = false;
            vm.tipos = [];
            vm.situacoes = [];
            vm.abrangencias = [];
            vm.granularidades = [];
            vm.periodicidades = [];
            vm.acessos = [];
            vm.labels = {
                selectAll: "Selecionar todos",
                unselectAll: "Desmarcar",
                itemsSelected: "selecionados",
                search: "Pesquisar",
                select: "Selecione"
            };

            _populateSearchFields();

            $scope.$on(APP_EVENT.COLETA_RUBRICA.SAVED, function() {
                toastService.success("Operação realizada com sucesso.");
                vm.search();
            });

            $scope.$on(APP_EVENT.COLETA_RUBRICA.REFRESH, function() {
                vm.search();
            });

        }());

    }

}());