/*
jslint
    devel: true, maxerr: 10
*/

/*
global
    required: true
*/

/*
 * Perfil de Acesso Service
 * @module Perfil de Acesso
 * @author iFactory Solutions Ltda.
 * @version 1.0.0
 * @since 1.0.0
 */
(function() {
    'use strict';

    angular.module('app.perfilAcesso').service('PerfilAcessoService', PerfilAcessoService);
    PerfilAcessoService.$inject = ['PerfilAcessoResource', 'STATUS_ENUM'];

    function PerfilAcessoService(resource, STATUS_ENUM) {
        this.search = function(filter) {
            return resource.search(filter).$promise;
        };

        this.get = function(id) {
            return resource.get({
                id: id
            }).$promise;
        };

        this.save = function(entity, items, newItems) {
            var service = new resource(entity);
            if (entity.id) {
                return service.$update();
            } else {
                return service.$save();
            }
        };

        this.delete = function(record) {
            return resource.delete({
                id: record.id
            }).$promise;
        };

        this.findActives = function() {
            return resource.findByStatus({
                status: STATUS_ENUM.ATIVO
            }).$promise;
        };

        this.assignedUsers = function(accessProfileId) {
            return resource.findAssignedUsers({
                id: accessProfileId
            }).$promise;
        };

        this.assignedFeatures = function(accessProfileId) {
            return resource.findAssignedFeatures({
                id: accessProfileId
            }).$promise;
        };

        this.getForView = function(accessProfileId) {
            return resource.getForView({
                id: accessProfileId
            }).$promise;
        }
    }

}());