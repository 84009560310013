(function() {
    'use strict';

    angular.module('app.core').constant('SEARCH_BY_FIELD', {
        CODE: {
            field: "id",
            placeholder: "código",
            mask: "9",
            restrict: "[^0-9]",
            maxLength: 9,
            minLength: 1
        },
        NAME: {
            field: "nome",
            placeholder: "nome",
            mask: "*",
            restrict: "%",
            maxLength: 50,
            minLength: 3
        },
        CODIGO_INEP: {
            field: "codigoINEP",
            placeholder: "Código do INEP",
            mask: "*",
            restrict: "%",
            maxLength: 10,
            minLength: 3
        },
        SIGLA: {
            field: "sigla",
            placeholder: "sigla",
            mask: "*",
            restrict: "%",
            maxLength: 2,
            minLength: 2
        },
        ANO_LETIVO: {
            field: "anoLetivo",
            placeholder: "ano letivo",
            mask: "9999",
            maxLength: 4,
            minLength: 4
        },
        CODIGO_IBGE_UF: {
            field: "codigoIBGE",
            placeholder: "código IBGE",
            mask: "9",
            restrict: "%",
            maxLength: 2,
            minLength: 1
        },
        CODIGO_IBGE_MUNICIPIO: {
            field: "codigoIBGE",
            placeholder: "código IBGE",
            mask: "9",
            restrict: "%",
            maxLength: 7,
            minLength: 1
        }
    });

}());