(function() {
    'use strict';

    angular.module('app.professor', ['app.core']).config(config);
    config.$inject = ['$stateProvider', 'APP_STATE'];

    function config($stateProvider, APP_STATE) {

        $stateProvider
            .state(APP_STATE.PROFESSOR.BASE, {
                url: '/professores',
                abstract: true,
                views: {
                    'content': {
                        template: '<div ui-view="internal"></div>',
                        controller: 'ProfessorController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state(APP_STATE.PROFESSOR.SEARCH, {
                url: '/search',
                views: {
                    'internal': {
                        templateUrl: 'professor/partials/professor-search.html'
                    }
                },
                data: {
                    permission: "PRF:RE",
                    breadCrumb: [{
                            display: "Parcerias",
                            active: false
                        },
                        {
                            display: "Professores",
                            active: true
                        }
                    ],
                    search: {
                        enabled: false
                    }
                }
            })
            .state(APP_STATE.PROFESSOR.NEW, {
                url: '/new',
                views: {
                    'internal': {
                        templateUrl: 'professor/partials/professor-form.html',
                        controller: 'professorNewController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    permission: "PRF:CR",
                    breadCrumb: [{
                            display: "Parcerias",
                            active: false
                        },
                        {
                            display: "Professores",
                            active: false,
                            stateName: APP_STATE.PROFESSOR.SEARCH
                        },
                        {
                            display: "Novo",
                            active: true
                        }
                    ],
                    search: {
                        placeHolder: "Digite o nome do professor",
                        enabled: false
                    }
                }
            })
            .state(APP_STATE.PROFESSOR.IMPORT_TEMPLATE, {
                url: '/import_template',
                views: {
                    'internal': {
                        templateUrl: 'professor/partials/professor-import_template-form.html',
                        controller: 'professorImportTemplateController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    permission: "PRF:CL",
                    breadCrumb: [{
                            display: "Parcerias",
                            active: false
                        },
                        {
                            display: "Professores",
                            active: false,
                            stateName: APP_STATE.PROFESSOR.SEARCH
                        },
                        {
                            display: "Importar",
                            active: true
                        }
                    ],
                    search: {
                        placeHolder: "Digite o nome do professor",
                        enabled: false
                    }
                }
            })
            .state(APP_STATE.PROFESSOR.EDIT, {
                url: '/edit/:id',
                views: {
                    'internal': {
                        templateUrl: 'professor/partials/professor-form.html',
                        controller: 'professorNewController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    permission: "PRF:VI",
                    breadCrumb: [{
                            display: "Parcerias",
                            active: false
                        },
                        {
                            display: "Professores",
                            active: false,
                            stateName: APP_STATE.PROFESSOR.SEARCH
                        },
                        {
                            display: "Edição",
                            active: true
                        }
                    ],
                    search: {
                        placeHolder: "Digite o nome do professor",
                        enabled: false
                    }
                }
            });

    }
}());