(function() {
    'use strict';

    angular.module('app.core').service('utilService', UtilService);
    UtilService.$inject = [];

    function UtilService() {
        
        this.nomeCurto = function(nome) {
			var nomeCurto = "";

			if (is.existy(nome) && is.not.empty(nome)) {
				var normalizedNome = nome.replace(/\s{2,}/g, ' ');
				var nomes = normalizedNome.split(" ");

				if (nomes.length > 1 && nomes[nomes.length - 1].length > 2) {
					nomeCurto = nomes[0] + " " + nomes[nomes.length - 1];
				} else {
					nomeCurto = nomes[0]
				}
			}

			return nomeCurto;
        };
        
        var decode_err_message = function(data) {
			if ('TextDecoder' in window) {
				// Decode as UTF-8
				var dataView = new DataView(data);
				var decoder = new TextDecoder('utf8');
				return JSON.parse(decoder.decode(dataView));
			  } else {
				// Fallback decode as ASCII
				var decodedString = String.fromCharCode.apply(null, new Uint8Array(data));
				return JSON.parse(decodedString);
			  }
		}

		this.extractErrorMsg = function(error) {
            var message;
            
            if (typeof error === 'object') {
                if (is.existy(error.data)) {
                    if (is.existy(error.data.message)) {
                        message = error.data.message;
                    } else {
                        if (is.existy(error.data.data)) {
                            var json = decode_err_message(error.data.data);
                            if (json.status !== 500) {
                                message = json.message;
                            }
                        }
                    }
                } else {
                    message = error.message;
                }
            } else {
                message = error;
            }

            return message || 'Ocorreu um erro inesperado. Entre em contato com o administrador.';
        };

    }

}());