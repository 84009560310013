/*
jslint
    devel: true, maxerr: 10
*/

/*
global
    required: true
*/

/*
 * Relatórios Module
 * @controller Class Profile Report Controller
 * @author iFactory Solutions Ltda.
 * @version 1.0.0
 * @since 1.0.0
 */
(function() {
    'use strict';

    angular.module('app.relatorios').controller('RelatorioAcompanhamentoAnualController', RelatorioAcompanhamentoAnualController);
    RelatorioAcompanhamentoAnualController.$inject = ['$state', 'APP_STATE', 'escolasService', 'RelatoriosService', 'toastService', 'REPORT_GROUP'];

    function RelatorioAcompanhamentoAnualController($state, APP_STATE, escolasService, reportService, toastService, REPORT_GROUP) {
        var vm = this;

        var _clearReportResult = function() {
            vm.result.reportDataCount = 0;
            vm.result.reportFields = [];
            vm.result.reportData = [];
            vm.result.calculations = {};
        };

        vm.findSchool = function(filter) {
            var filterNew = {};
            filterNew.situacao = "A";
            
            if (undefined != filter.city && is.not.null(filter.city)) {
                if (filter.city.id != 0) {
                    filterNew.idMunicipio = filter.city.id; 
                }
            }    
            
            if (undefined != filter.schoolType && is.not.null(filter.schoolType)) {
                if (filter.schoolType.id != 0) {
                    filterNew.tipoOrganizacao = filter.schoolType.id; 
                }
            }
            
            if (undefined != filter.schoolAdministrativeDependency && is.not.null(filter.schoolAdministrativeDependency)) {
                if (filter.schoolAdministrativeDependency.id != 0) {
                    filterNew.idDependenciaAdministrativa = filter.schoolAdministrativeDependency.id; 
                }
            }

            if (is.existy(filterNew.idMunicipio) || is.existy(filterNew.tipoOrganizacao) || is.existy(filterNew.idDependenciaAdministrativa)) {
                reportService.loadSchoolByFilter(filterNew).then(function(data){
                    console.log("data");
                    console.log(data);
                    vm.model.schools = data;    
                });
            }

        };

        vm.changeState = function() {
            if (is.existy(vm.filter.state)) {
                vm.model.cities = reportService.loadCitiesOfState(vm.filter.state.id);
                vm.model.stateRegionals = reportService.loadStateRegionalsOfState(vm.filter.state.id);
            } else {
                vm.model.stateRegionals = [];
                vm.model.cities = [];
                vm.model.cityRegionals = [];
                vm.model.schools = [];
            }
        };

        vm.changeStateRegional = function() {
            if (is.existy(vm.filter.stateRegional)) {
                vm.model.cities = reportService.loadCitiesOfStateRegional(vm.filter.stateRegional.id);
            } else {
                // TODO Guardar a cidade anteriormente selecionada, caso haja; refazer a consulta pela UF (se houver uma selecioanda) 
                // e já deixar selecionada a cidade previamente selecionada.
                if (is.existy(vm.filter.state)) {
                    vm.model.cities = reportService.loadCitiesOfState(vm.filter.state.id);
                } else {
                    vm.model.cities = [];
                }
                vm.model.cityRegionals = [];
                vm.model.schools = [];
            }
        };

        vm.changeSchoolType = function() {
            if (is.existy(vm.filter.city) && is.existy(vm.filter.schoolType)) {
                vm.model.schools = reportService.loadSchoolByFilter(vm.filter);
            } else if (is.existy(vm.filter.city)) {
                vm.model.schools = reportService.loadSchoolsOfCity(vm.filter.city.id);
            } else {
                vm.model.cityRegionals = [];
                vm.model.schools = [];
            }
        };

        vm.changeSchoolLocalization = function() {
            if (is.existy(vm.filter.city) && is.existy(vm.filter.schoolLocalization)) {
                vm.model.schools = reportService.loadSchoolByFilter(vm.filter);
            } else if (is.existy(vm.filter.city)) {
                vm.model.schools = reportService.loadSchoolsOfCity(vm.filter.city.id);
            } else {
                vm.model.cityRegionals = [];
                vm.model.schools = [];
            }
        };

        vm.changeSchoolAdministrativeDependency = function() {
            if (is.existy(vm.filter.schoolAdministrativeDependency) && is.existy(vm.filter.schoolAdministrativeDependency)) {
                vm.model.schools = reportService.loadSchoolByFilter(vm.filter);
            } else if (is.existy(vm.filter.city)) {
                vm.model.schools = reportService.loadSchoolsOfCity(vm.filter.city.id);
            } else {
                vm.model.cityRegionals = [];
                vm.model.schools = [];
            }
        };

        vm.showdetails = function(id) {
            var obj = vm.model.detailings.filter(function(detailing){ return detailing.id === id });

            return obj;
        };
        vm.changeProgram = function() {
            if (is.existy(vm.filter.program)) {
                vm.filter.model = vm.filter.program.model;
            } else {
                vm.filter.model = null;
            }
            if (is.existy(vm.result.report.events) && is.existy(vm.result.report.events.onChangeProgram)) {
                vm.result.report.events.onChangeProgram({ report: vm.result.report }, vm.filter.program);
            }
        };

        vm.changeMonitoring = function() {
            if (is.existy(vm.result.report.events) && is.existy(vm.result.report.events.onChangeMonitoring)) {
                vm.result.report.events.onChangeMonitoring({ report: vm.result.report }, vm.filter.typeMonitoring, vm.filter.typeMonitoringTypeReport);
            }  
        }

        vm.generateReport = function() {
            vm.view.searchWasPerformed = true;
            vm.result.calculations = [];
            reportService.generateReportResult(vm.filter, vm.result);
        }; 

        vm.formatColumn = function(item, field) {
            return reportService.formatColumn(item, field, vm.result);
        };

        vm.formatSummaryColumn = function(field) {
            return reportService.formatSummaryColumn(vm.result.calculations, field, vm.result);
        };

        vm.performCalculation = function(field, item) {
            var oldValue = vm.result.calculations[field.name];
            vm.result.calculations[field.name] = reportService.performatCalculation(item, field, oldValue, vm.result);
        };

        vm.showResultReport = function(reportResultInfo) {
            var result = vm.result.reportDataCount > 0 && vm.view.searchWasPerformed;
            if (result && is.existy(reportResultInfo)) {
                if (is.existy(reportResultInfo.reportId)) {
                    result = result && is.existy(vm.result) && is.existy(vm.result.report) && vm.result.report.id == reportResultInfo.reportId;
                }
                if (is.existy(result && reportResultInfo.modelId)) {
                    result = result && is.existy(vm.filter) && is.existy(vm.filter.model) && vm.filter.model.id == reportResultInfo.modelId;
                }
            }
            return result;
        };

        vm.showFooter = function(fieldInfo) {
            var result = is.not.existy(fieldInfo.showFooter) || fieldInfo.showFooter;
            return result;
        };

        vm.isMonthlyTotalizationEnabled = function() {
            return vm.filter.totalization.id == vm.model.totalization[0].id;
        };

        vm.isAccumulatedTotalizationEnabled = function() {
            return vm.filter.totalization.id == vm.model.totalization[1].id;
        };

        vm.isModelValid = function() {
            var result = true;
            if (is.existy(vm.result.report.constraints) && is.truthy(vm.result.report.constraints.model)) {
                result = is.existy(vm.filter.model);
            }
            return result;
        };

        vm.isTypeReportValid = function() {
            var result = true;
            if (is.existy(vm.result.report.constraints) && is.truthy(vm.result.report.constraints.typeReport)) {
                result = is.existy(vm.filter.typeReport);
            }
            return result;
        };

        vm.isFilterValid = function() {
            return vm.reportForm.$valid && vm.isModelValid() && vm.isTypeReportValid();
        };

        (function() {
            vm.model = {
                states: [],
                stateRegionals: [],
                cities: [],
                cityRegionals: [],
                cityDistricts: [],
                schools: [],
                schoolYears: [],
                schoolTypes: [],
                schoolLocalizations: [],
                schoolAdministrativeDependencies: [],
                partnershipTypes: [],
                programs: [],
                classes: [],
                classSeries: [],
                classShifts: [],
                classTypes: [],
                months: [],
                detailings: [
                    {
                        id: "G",
                        name: "Regional"
                    },
                    {
                        id: "U",
                        name: "Estado"
                    },
                    {
                        id: "M",
                        name: "Município"
                    },
                    {
                        id: "R",
                        name: "Regional Municipal"
                    }
                ],
                detailingsV2: [
                    {
                        id: "U",
                        name: "Estado"
                    },
                    {
                        id: "M",
                        name: "Município"
                    },
                    {
                        id: "E",
                        name: "Escola"
                    }
                ],
                totalization: [{
                        id: "monthly",
                        name: "Mensal"
                    },
                    {
                        id: "accumulated",
                        name: "Acumulada"
                    }
                ],
                monitoring: [{
                        id: "a",
                        name: "Relatório A"
                    },
                    {
                        id: "b",
                        name: "Relatório B"
                    }
                ],
                monitoringTypeReport: [{
                        id: "1",
                        name: "Quantitativo"
                    },
                    {
                        id: "2",
                        name: "Qualitativo"
                    }
                ]

            };
            vm.filter = {
                state: null,
                stateRegional: null,
                city: null,
                cityRegional: null,
                cityDistrict: null,
                schoolYear: null,
                schoolType: null,
                schoolFilter: 'ID',
                schoolLocalization: null,
                schoolAdministrativeDependency: null,
                partnershipType: null,
                detailing: null,
                shift: null,
                program: null,
                totalization: null,
                model: null,
                class: null,
                classShift: null,
                classType: null,
                classSerie: null,
                yearTotalization: null,
                monthTotalization: null,
                yearTotalizationInitial: null,
                monthTotalizationInitial: null,
                yearTotalizationFinal: null,
                monthTotalizationFinal: null,
                typeMonitoring: null,
                typeMonitoringTypeReport: null
            };
            vm.view = {
                available: true,
                searchWasPerformed: false,
                currentPage: null,
                allSchoolType: {
                    id: '0',
                    name: 'Todos'
                },
                allSchoolLocalization: {
                    id: '0',
                    nome: 'Todas'
                },
                allSchoolAdministrativeDependency: {
                    id: '0',
                    nome: 'Todas'
                },
                allClasses: {
                    key: '0',
                    value: 'Todas'
                },
                allClassShift: {
                    key: '0',
                    value: 'Todos'
                },
                allClassType: {
                    key: '0',
                    value: 'Todos'
                },
                disableYearMonth: false
            };
            vm.result = {
                report: null,
                reportDataCount: 0,
                reportFields: [],
                reportData: [],
                calculations: {}
            };

            vm.model.states = reportService.loadStates();
            vm.model.schoolYears = reportService.loadSchoolYears();
            vm.model.schoolTypes = reportService.loadShoolTypes();
            vm.model.schoolLocalizations = reportService.loadLocalizations();
            vm.model.schoolAdministrativeDependencies = reportService.loadAdministrativeDependencies();
            vm.model.months = reportService.loadMonths();
            reportService.loadClassShifts().then(function(data) {
                vm.model.classShifts = angular.copy(data);
            }, function() {
                vm.model.classShifts = [];
            });
            reportService.loadClassTypes().then(function(data) {
                vm.model.classTypes = angular.copy(data);
            }, function() {
                vm.model.classTypes = [];
            });
            vm.model.classes = reportService.loadClasses();

            // Por padrão, o detalhamento do relatório é feito por turma.
            vm.filter.detailing = vm.model.detailings[3];
            // Por padrão, o tipo da escola é "Todos".
            vm.filter.schoolType = vm.view.allSchoolType;
            // Por padrão, a localização da escola é "Todas".
            vm.filter.schoolLocalization = vm.view.allSchoolLocalization;
            // Por padrão, a dependência administrativa da escola é "Todas".
            vm.filter.schoolAdministrativeDependency = vm.view.allSchoolAdministrativeDependency;
            // Por padrão, a totalização do relatório é feito por mês.
            vm.filter.totalization = vm.model.totalization[0];
            // Por padrão, o tipo de turma é "Todos".
            vm.filter.classType = vm.view.allClassType;
            // Por padrão o tipo de relatório de acompanhamento é qualitativo
            vm.filter.typeMonitoringTypeReport = vm.model.monitoringTypeReport[0];

            // TODO Realizar o tratamento adequado para o caso de não ter o report no parâmetro do $state (avisar ou já redirecionar para a tela base?)
            vm.result.report = $state.params.report;

            if (is.not.existy(vm.result.report)) {
                vm.view.available = false; // TODO Espalhar em toda a interface esse controle.
                toastService.error('Não foi possível recuperar as informações do relatório.');
            } else {
                if (vm.result.report.id == REPORT_GROUP.ACOMPANHAMENTO_ANUAL.PERFIL_ATENDIMENTO_UNIDADES_ESCOLARES_TURMAS) {
                    vm.filter.detailing = vm.model.detailingsV2[3] || vm.model.detailingsV2[2];
                }

                reportService.loadPrograms({ reportId: vm.result.report.id }).then(function(data) {
                    var programas = data;
                    if (vm.result.report.id == REPORT_GROUP.ACOMPANHAMENTO_ANUAL.PERFIL_ATENDIMENTO_UNIDADES_ESCOLARES_TURMAS) {
                        programas = [];
                        angular.forEach(data, function(value){
                            if (is.inArray(value.id, [8,9,10,13,14, 16,17,18,19,20, 7, 21,22,23,24, 27,28,2, 1, 2, 3, 5, 6])) {
                                programas.push({
                                    id: value.id,
                                    nome: value.nome
                                });
                            }
                        });
                    }

                    vm.model.programs = programas;
                });
            }
        })();
    }

}());