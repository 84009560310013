(function() {
    'use strict';

    angular.module('app.core').directive("iasTable", IasTable);
    IasTable.$inject = ['$filter', 'objectService', 'dialogService', '$rootScope', 'APP_EVENT'];

    function IasTable($filter, objectService, dialogService, $rootScope, APP_EVENT) {
        var defaults = {
            canInsert: true,
            canDelete: true,
            canEditItem: true
        };

        return {
            scope: {
                title: '@',
                question: '@',
                entityName: '@',
                sortField: '@',
                fields: '=',
                items: '=',
                deleted: '=?',
                canCreate: '=?',
                hasPreview: '=?',
                startIndex: '=?',
                ngDisabled: '=?',
                onShowForm: '&?',
                onSave: '&?',
                onPreview: '&?',
                onlyUpdate: '=?',
                modified: '=?',
                onModified: '&?',
                canSort: '=?',
                canInsert: '=?',
                canDelete: '=?',
                canEditItem: '=?'
            },
            restrict: "E",
            templateUrl: "core/directives/table/partials/ias-table.html",
            link: function(scope, element, attr) {
                scope.selectedItems = [];
                scope.selectedAll = false;
                scope.startIndex = scope.startIndex || 0;
                scope.deleted = scope.deleted || [];
                scope.canSort = scope.canSort != 'nao' || false;
                scope.canInsert = scope.canInsert || defaults.canInsert;
                scope.canDelete = scope.canDelete || defaults.canDelete;
                scope.canEditItem = angular.isUndefined(scope.canEditItem) ? defaults.canEditItem : scope.canEditItem;
                
                var _clearItems = function(){
                    if(scope.selectedItems != null 
                        && scope.selectedItems.length > 0){
                        for(var i = 0; i < scope.selectedItems.length; i++){
                            scope.selectedItems[i].selected = false;
                        }
                        scope.selectedItems = [];
                    }
                }

                $rootScope.$on(APP_EVENT.GENERIC.CLEAR_ITEMS_SELECTED, function(){
                    _clearItems();
                })

                var _reorderAll = function() {
                    angular.forEach(scope.items, function(value, index) {
                        value[scope.sortField] = index + 1;
                        value._updated = true;
                    });
                };

                var _moveItem = function(start, end) {
                    var target = scope.items[end];
                    var targetOrder = target[scope.sortField];
                    var source = scope.items.splice(start, 1)[0];
                    var sourceOrder = source[scope.sortField];

                    target[scope.sortField] = sourceOrder;
                    source[scope.sortField] = targetOrder;

                    source._updated = true;
                    target._updated = true;

                    scope.items.splice(end, 0, source);
                    scope.wasModified(true);
                };

                scope.totalItemsSelected = function() {
                    if(scope.items instanceof Array){
                        var itemsSelected = $filter('filter')(scope.items, function(item){
                            return item.selected == true;
                        });
                        return itemsSelected.length;
                    }
                    return 0;
                };

                scope.moveUp = function(startIndex) {
                    var endIndex = startIndex - 1;

                    if (endIndex >= 0) {
                        _moveItem(startIndex, endIndex);
                    }
                };

                scope.moveDown = function(startIndex) {
                    var endIndex = startIndex + 1;

                    if (endIndex <= scope.items.length) {
                        _moveItem(startIndex, endIndex);
                    }
                }

                scope.toggleMark = function(item) {
                    var itemIndex = scope.selectedItems.indexOf(item);

                    if (itemIndex === -1) {
                        scope.selectedItems.push(item);
                    } else {
                        scope.selectedItems.splice(itemIndex, 1);
                    }
                };

                scope.toggleSelectAll = function() {
                    scope.selectedAll = !scope.selectedAll;

                    if (scope.selectedAll) {
                        angular.forEach(scope.items, function(item, index) {
                            if (scope.selectedItems.indexOf(item) === -1) {
                                scope.selectedItems.push(item);
                                item.selected = true;
                            }
                        });
                    } else {
                        angular.forEach(scope.items, function(item, index) {
                            var itemIndex = scope.selectedItems.indexOf(item);

                            if (itemIndex !== -1) {
                                scope.selectedItems.splice(itemIndex, 1);
                                item.selected = false;
                            }
                        });
                    }
                };

                scope.deleteSelected = function() {
                    dialogService.delete().then(function() {
                        angular.forEach(scope.selectedItems, function(value, index) {
                            var deletedItem = scope.items.splice(scope.items.indexOf(value), 1);

                            if (deletedItem.length && deletedItem[0].id) {
                                scope.deleted.push({ id: deletedItem[0].id });
                            }
                        });

                        if (scope.selectedItems.length > 0) {
                            _reorderAll();
                            scope.selectedItems = [];
                        }

                        if (is.not.empty(scope.deleted)) {
                            scope.wasModified(true);
                        } else {
                            scope.wasModified(false);
                        }
                    }, function() {});
                };

                scope.showCreateForm = function() {
                    if (scope.onShowForm) {
                        scope.onShowForm().then(new Function(), new Function());
                    }
                };

                scope.showEditForm = function() {
                    if (scope.onShowForm && (scope.selectedItems.length == 1)) {
                        var promise = scope.onShowForm({ item: scope.selectedItems[0] }).then(new Function(), new Function());
                    }
                };

                scope.save = function() {
                    if (scope.onSave) {
                        scope.onSave();
                    }
                };

                scope.preview = function() {
                    if (scope.onPreview && (scope.selectedItems.length == 1)) {
                        scope.onPreview({ item: scope.selectedItems[0] });
                    }
                };

                scope.format = function(item, field) {
                    if (field.type == "date") {
                        return $filter('date')(objectService.deepValue(item, field.name), 'dd/MM/yyyy');
                    } else {
                        return objectService.deepValue(item, field.name);
                    }
                };

                scope.wasModified = function(wasModified) {
                    scope.modified = wasModified;

                    if (scope.onModified) {
                        scope.onModified({ wasModified: wasModified });
                    }
                };

                scope.showBoxButton = function() {
                    var ret = false;

                    if (scope.canCreate && !scope.onlyUpdate) {
                        ret = true;
                    }
                    
                    if (scope.selectedItems.length == 1) {
                        ret = true;
                    }
                    
                    if (scope.selectedItems.length > 0 && !scope.onlyUpdate) {
                        ret = true;
                    }
                    
                    if (scope.selectedItems.length == 1 && scope.hasPreview) {
                        ret = true;
                    }

                    return ret;
                };

            }
        };
    }

})();