(function() {
    'use strict';

    angular.module('app.programas').controller('ProgramaFormController', ProgramaFormController);
    ProgramaFormController.$inject = ['APP_STATE', 'TABLE_FIELDS', 'APP_EVENT', 'COMMON_STATUS_LIST',
        '$state', '$scope', 'programasService', 'dialogService', 'toastService',
        'CriterioSelecaoService', '$rootScope',"rubricasService"
    ];

    function ProgramaFormController(APP_STATE, TABLE_FIELDS, APP_EVENT, COMMON_STATUS_LIST,
        $state, $scope, programasService, dialogService, toastService,
        criterioSelecaoService, $rootScope,rubricasService) {

        var vm = this;

        var _init = function() {
            vm.selectedItems = [];
            vm.salvarContinuar = false;
            vm.fields = TABLE_FIELDS.PROGRAMA_CRITERIO;
            vm.editMode = false;
            vm.editPage = false;
            vm.defaultStatus = COMMON_STATUS_LIST[0];
            vm.criterios = [];
            vm.newCriterios = [];
            vm.criteriosExcluidos = [];
            vm.modalTemplateUrl = "programas/partials/programas-form-criterios-modal.html";
            vm.modalDependencies = {
                statusList: COMMON_STATUS_LIST,
                criteriosSelecao: criterioSelecaoService.allEnabled(),
                criterio: {
                    situacao: vm.defaultStatus
                },
                save: _saveModalItem
            };

            vm.fieldsRubrica = TABLE_FIELDS.PROGRAMA_RUBRICA;
            vm.rubricas = [];
            vm.newRubricas = [];
            vm.rubricasExcluidas = [];
            vm.modalRubricaUrl = "programas/partials/programas-form-rubricas-modal.html";
            vm.modalRubricasDependencies = {
                statusList: COMMON_STATUS_LIST,
                rubricasSelecao: rubricasService.ativos(),
                rubrica: {
                    situacao: vm.defaultStatus
                },
                save: _saveModalItem
            };


            if ($state.is(APP_STATE.PROGRAMA.EDIT)) {
                vm.editPage = true;
                
                if ($state.params.id) {
                    programasService.get($state.params.id).then(function(programa) {
                        vm.programa = programa;
                        vm.criterios = programa.criterios;
                        vm.rubricas = programa.rubricas;
                        if (is.truthy($state.params.editMode)) {
                                vm.enableEditMode();
                        }
                    }, function(response) {
                        toastService.error({
                            status: response.status,
                            message: response.data.message
                        });
                    });
                } else {
                    vm.goBack();
                }
            } else {
                vm.enableEditMode();
                vm.programa = {};
                vm.programa.situacao = vm.defaultStatus;
            }
        }; 

        var _saveModalItem = function(modalForm){
            vm.registerForm.$setDirty();
            this.ok(modalForm);
        }

        vm.goBack = function() {
            $state.go(APP_STATE.PROGRAMA.SEARCH);
        };

        vm.save = function() {
            var criterios = vm.newCriterios.concat(vm.criterios);
            vm.programa.criterios = criterios;
            vm.programa.criteriosExcluidos = vm.criteriosExcluidos;

            var rubricas = vm.newRubricas.concat(vm.rubricas);
            vm.programa.rubricas = rubricas;
            vm.programa.rubricasExcluidas = vm.rubricasExcluidas;

            if (vm.registerForm.$valid) {
                programasService.save(vm.programa).then(function(programa) {
                    $scope.$emit(APP_EVENT.PROGRAMA.SAVED);
                    if (!vm.salvarContinuar) {
                        vm.goBack();
                    } else {
                        _init();
                        //Irá enviar notificação para limpar os itens selecionados
                        $rootScope.$broadcast(APP_EVENT.GENERIC.CLEAR_ITEMS_SELECTED)
                        vm.registerForm.$setPristine();
                        $state.go(APP_STATE.PROGRAMA.EDIT, { id: programa.id, editMode: true });
                    }
                }, function(response) {
                    toastService.error({
                        status: response.status,
                        message: response.data.message
                    });
                });
            }
        };

        vm.saveCriterios = function() {
            _saveAndContinue(true);
        };

        vm.updateCriterios = function() {
            _saveAndContinue(false);
        };

        vm.saveRubricas = function() {
            _saveAndContinue(true);
        };

        vm.updateRubricas = function() {
            _saveAndContinue(false);
        };


        vm.enableEditMode = function() {
            vm.editMode = true;
        };

        vm.cancel = function() {
            if (vm.refreshSearch) {
                $scope.$emit(APP_EVENT.PROGRAMA.REFRESH);
            }

            vm.goBack();
        };

        var _saveAndContinue = function(createNewItems) {
            var criterios = vm.criterios.concat(vm.newCriterios);
            vm.programa.criterios = criterios;
            vm.programa.criteriosExcluidos = vm.criteriosExcluidos;

            var rubricas = vm.rubricas.concat(vm.newRubricas);
            vm.programa.rubricas = rubricas;
            vm.programa.rubricasExcluidas = vm.rubricasExcluidas;


            if (vm.registerForm.$valid) {
                programasService.save(vm.programa).then(function(response) {
                    vm.criterios = response.criterios;
                    vm.rubricas = response.rubricas;
                    vm.refreshSearch = true;
                    vm.registerForm.$setPristine();

                    vm.newCriterios = [];
                    vm.criteriosExcluidos = [];

                    vm.newRubricas = [];
                    vm.rubricasExcluidas = [];
                    
                    vm.programa = response;
                    toastService.success("Operação realizada com sucesso.");
                }, function(response) {
                    vm.refreshSearch = false;

                    toastService.error({
                        message: response.data.message
                    });
                });
            }
        };

        (function() {
            _init();     
        }());
    }

}());