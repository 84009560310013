/*
jslint
    devel: true, maxerr: 10
*/

/*
global
    required: true
*/

/*
 * Relatórios Module
 * @author iFactory Solutions Ltda.
 * @version 1.0.0
 * @since 1.0.0
 */
(function() {
    'use strict';

    angular.module('app.relatorios', ['app.core', 'app.escolas']).config(config);
    config.$inject = ['$stateProvider', 'APP_STATE', 'REPORT_GROUP'];

    function config($stateProvider, APP_STATE, REPORT_GROUP) {
        $stateProvider
            .state(APP_STATE.RELATORIOS.BASE, {
                url: '/relatorios',
                abstract: true,
                views: {
                    'content': {
                        template: '<div ui-view="internal"></div>'
                    }
                }
            })
            .state(APP_STATE.RELATORIOS.PERFIL_TURMA.BASE, {
                url: '/perfil-turma',
                views: {
                    'internal': {
                        templateUrl: 'relatorios/partials/perfil-turma/perfil-turma.tpl.html',
                        controller: 'BaseReportController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    permission: "REL:GR",
                    breadCrumb: [{
                            display: "Relatórios",
                            active: false
                        },
                        {
                            display: "Perfil de Turma",
                            active: true
                        }
                    ],
                    search: {
                        enabled: false
                    },
                    reportGroup: REPORT_GROUP.PERFIL_TURMA.BASE
                }
            })
            .state(APP_STATE.RELATORIOS.PERFIL_TURMA.ALFABETIZACAO_ACELERACAO, {
                url: '/perfil-turma/alfabetizacao-aceleracao',
                params: {
                    report: null
                },
                views: {
                    'internal': {
                        templateUrl: 'relatorios/partials/perfil-turma/perfil-turma-alfabetizacao-aceleracao-filter.tpl.html',
                        controller: 'RelatorioPerfilTurmaController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    permission: "REL:GR",
                    breadCrumb: [{
                            display: "Relatórios",
                            active: false
                        },
                        {
                            display: "Perfil de Turma",
                            active: false,
                            stateName: APP_STATE.RELATORIOS.PERFIL_TURMA.BASE
                        },
                        {
                            display: "Alfabetização e Aceleração",
                            active: true
                        }
                    ],
                    search: {
                        enabled: false
                    }
                }
            })
            .state(APP_STATE.RELATORIOS.PERFIL_TURMA.GESTAO_DA_ALFABETIZACAO, {
                url: '/perfil-turma/gestao-da-alfabetizacao',
                params: {
                    report: null
                },
                views: {
                    'internal': {
                        templateUrl: 'relatorios/partials/perfil-turma/perfil-turma-gestao-da-alfabetizacao-filter.tpl.html',
                        controller: 'RelatorioPerfilTurmaController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    permission: "REL:GR",
                    breadCrumb: [{
                            display: "Relatórios",
                            active: false
                        },
                        {
                            display: "Perfil de Turma",
                            active: false,
                            stateName: APP_STATE.RELATORIOS.PERFIL_TURMA.BASE
                        },
                        {
                            display: "Gestão da alfabetização",
                            active: true
                        }
                    ],
                    search: {
                        enabled: false
                    }
                }
            })
            .state(APP_STATE.RELATORIOS.PERFIL_TURMA.SISTEMATICA_ACOMPANHAMENTO_DIALOGOS , {
                url: '/perfil-turma/sistematica-acompanhamento-dialogos',
                params: {
                    report: null
                },
                views: {
                    'internal': {
                        templateUrl: 'relatorios/partials/perfil-turma/perfil-turma-sistematica-acompanhamento-dialogos-filter.tpl.html',
                        controller: 'RelatorioPerfilTurmaController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    permission: "REL:GR",
                    breadCrumb: [{
                            display: "Relatórios",
                            active: false
                        },
                        {
                            display: "Perfil de Turma",
                            active: false,
                            stateName: APP_STATE.RELATORIOS.PERFIL_TURMA.BASE
                        },
                        {
                            display: "Diálogos Socioemocionais - Sistemática",
                            active: true
                        }
                    ],
                    search: {
                        enabled: false
                    }
                }
            })
            .state(APP_STATE.RELATORIOS.PERFIL_TURMA.FORMULA_VITORIA, {
                url: '/perfil-turma/formula-vitoria',
                params: {
                    report: null
                },
                views: {
                    'internal': {
                        templateUrl: 'relatorios/partials/perfil-turma/perfil-turma-filter.tpl.html',
                        controller: 'RelatorioPerfilTurmaController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    permission: "REL:GR",
                    breadCrumb: [{
                            display: "Relatórios",
                            active: false
                        },
                        {
                            display: "Perfil de Turma",
                            active: false,
                            stateName: APP_STATE.RELATORIOS.PERFIL_TURMA.BASE
                        },
                        {
                            display: "Fórmula da Vitória",
                            active: true
                        }
                    ],
                    search: {
                        enabled: false
                    }
                }
            })
            .state(APP_STATE.RELATORIOS.PERFIL_TURMA.ENSINO_MEDIO_GESTAO_NOTA_10, {
                url: '/perfil-turma/ensino-medio-gestao-nota-10',
                params: {
                    report: null
                },
                views: {
                    'internal': {
                        templateUrl: 'relatorios/partials/perfil-turma/perfil-turma-ensino-medio-gestao-nota-10-filter.tpl.html',
                        controller: 'RelatorioPerfilTurmaController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    permission: "REL:GR",
                    breadCrumb: [{
                            display: "Relatórios",
                            active: false
                        },
                        {
                            display: "Perfil de Turma",
                            active: false,
                            stateName: APP_STATE.RELATORIOS.PERFIL_TURMA.BASE
                        },
                        {
                            display: "Ensino Médio - Gestão Nota 10",
                            active: true
                        }
                    ],
                    search: {
                        enabled: false
                    }
                }
            })
            .state(APP_STATE.RELATORIOS.PERFIL_TURMA.ENSINO_REGULAR, {
                url: '/perfil-turma/ensino-regular',
                params: {
                    report: null
                },
                views: {
                    'internal': {
                        templateUrl: 'relatorios/partials/perfil-turma/perfil-turma-ensino-regular-filter.tpl.html',
                        controller: 'RelatorioPerfilTurmaController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    permission: "REL:GR",
                    breadCrumb: [{
                            display: "Relatórios",
                            active: false
                        },
                        {
                            display: "Perfil de Turma",
                            active: false,
                            stateName: APP_STATE.RELATORIOS.PERFIL_TURMA.BASE
                        },
                        {
                            display: "Ensino Regular",
                            active: true
                        }
                    ],
                    search: {
                        enabled: false
                    }
                }
            })
            .state(APP_STATE.RELATORIOS.ESCOLA.ANUAL.PERFIL_TURMA_ALFAB_ACEL, {
                url: '/escola/anual/perfil-turma',
                views: {
                    'internal': {
                        templateUrl: 'relatorios/partials/escola/anual/perfil-turma.html',
                        controller: 'RelatorioController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    permission: "REL:GR",
                    breadCrumb: [{
                            display: "Relatórios",
                            active: false
                        },
                        {
                            display: "Escola",
                            active: false
                        },
                        {
                            display: "Anual",
                            active: false
                        },
                        {
                            display: "Perfil da Turma",
                            active: true
                        }
                    ],
                    search: {
                        enabled: false
                    }
                }
            })
            .state(APP_STATE.RELATORIOS.BASE_MONITORAMENTO.BASE, {
                url: '/base-monitoramento',
                views: {
                    'internal': {
                        templateUrl: 'relatorios/partials/base-monitoramento/base-monitoramento.tpl.html',
                        controller: 'BaseReportController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    permission: "REL:GR",
                    breadCrumb: [{
                            display: "Relatórios",
                            active: false
                        },
                        {
                            display: "Base Monitoramento",
                            active: true
                        }
                    ],
                    search: {
                        enabled: false
                    },
                    reportGroup: REPORT_GROUP.BASE_MONITORAMENTO.BASE
                }
            })
            .state(APP_STATE.RELATORIOS.BASE_MONITORAMENTO.ACOMPANHAMENTO, {
                url: '/base-monitoramento/acompanhamento',
                params: {
                    report: null
                },
                views: {
                    'internal': {
                        templateUrl: 'relatorios/partials/base-monitoramento/base-monitoramento-filter.tpl.html',
                        controller: 'BaseMonitoramentoController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    permission: "REL:GR",
                    breadCrumb: [{
                            display: "Relatórios",
                            active: false
                        },
                        {
                            display: "Base Monitoramento",
                            active: false,
                            stateName: APP_STATE.RELATORIOS.BASE_MONITORAMENTO.BASE
                        },
                        {
                            display: "Acompanhamento",
                            active: true
                        }
                    ],
                    search: {
                        enabled: false
                    }
                }
            })
            .state(APP_STATE.RELATORIOS.RESULTADO_FINAL.BASE, {
                url: '/resultado-final',
                views: {
                    'internal': {
                        templateUrl: 'relatorios/partials/resultado-final/resultado-final.tpl.html',
                        controller: 'BaseReportController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    permission: "REL:GR",
                    breadCrumb: [{
                            display: "Relatórios",
                            active: false
                        },
                        {
                            display: "Resultado Final",
                            active: true
                        }
                    ],
                    search: {
                        enabled: false
                    },
                    reportGroup: REPORT_GROUP.RESULTADO_FINAL.BASE
                }
            })
            .state(APP_STATE.RELATORIOS.RESULTADO_FINAL.ALFABETIZACAO_ACELERACAO, {
                url: '/resultado-final/alfabetizacao-aceleracao',
                params: {
                    report: null
                },
                views: {
                    'internal': {
                        templateUrl: 'relatorios/partials/resultado-final/resultado-final-alfabetizacao-aceleracao-filter.tpl.html',
                        controller: 'ResultadoFinalController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    permission: "REL:GR",
                    breadCrumb: [{
                            display: "Relatórios",
                            active: false
                        },
                        {
                            display: "Resultado Final",
                            active: false,
                            stateName: APP_STATE.RELATORIOS.RESULTADO_FINAL.BASE
                        },
                        {
                            display: "Alfabetização/Aceleração",
                            active: true
                        }
                    ],
                    search: {
                        enabled: false
                    }
                }
            })
            //RONIFER

            .state(APP_STATE.RELATORIOS.RESULTADO_FINAL.FORMULA_VITORIA, {
                url: '/resultado-final/formula-vitoria',
                params: {
                    report: null
                },
                views: {
                    'internal': {
                        templateUrl: 'relatorios/partials/resultado-final/resultado-final-filter.tpl.html',
                        controller: 'ResultadoFinalController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    permission: "REL:GR",
                    breadCrumb: [{
                            display: "Relatórios",
                            active: false
                        },
                        {
                            display: "Resultado Final",
                            active: false,
                            stateName: APP_STATE.RELATORIOS.RESULTADO_FINAL.BASE
                        },
                        {
                            display: "Fórmula da Vitória",
                            active: true
                        }
                    ],
                    search: {
                        enabled: false
                    }
                }
            })
            .state(APP_STATE.RELATORIOS.RESULTADO_FINAL.GESTAO_DA_ALFABETIZACAO, {
                url: '/resultado-final/gestao-alfabetizacao',
                params: {
                    report: null
                },
                views: {
                    'internal': {
                        templateUrl: 'relatorios/partials/resultado-final/resultado-final-gestao-alfabetizacao-filter.tpl.html',
                        controller: 'ResultadoFinalController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    permission: "REL:GR",
                    breadCrumb: [{
                            display: "Relatórios",
                            active: false
                        },
                        {
                            display: "Resultado Final",
                            active: false,
                            stateName: APP_STATE.RELATORIOS.RESULTADO_FINAL.BASE
                        },
                        {
                            display: "Gestão da Alfabetização",
                            active: true
                        }
                    ],
                    search: {
                        enabled: false
                    }
                }
            })
            .state(APP_STATE.RELATORIOS.RESULTADO_FINAL.ENSINO_REGULAR, {
                url: '/resultado-final/ensino-regular',
                params: {
                    report: null
                },
                views: {
                    'internal': {
                        templateUrl: 'relatorios/partials/resultado-final/resultado-final-ensino-regular-filter.tpl.html',
                        controller: 'ResultadoFinalController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    permission: "REL:GR",
                    breadCrumb: [{
                            display: "Relatórios",
                            active: false
                        },
                        {
                            display: "Resultado Final",
                            active: false,
                            stateName: APP_STATE.RELATORIOS.RESULTADO_FINAL.BASE
                        },
                        {
                            display: "Ensino Regular",
                            active: true
                        }
                    ],
                    search: {
                        enabled: false
                    }
                }
            })
            .state(APP_STATE.RELATORIOS.RESULTADO_FINAL.ENSINO_REGULAR_MATRICULA_MOVIMENTACAO, {
                url: '/resultado-final/ensino-regular-matricula-movimentacao',
                params: {
                    report: null
                },
                views: {
                    'internal': {
                        templateUrl: 'relatorios/partials/resultado-final/resultado-final-ensino-regular-matricula-movimentacao-filter.tpl.html',
                        controller: 'ResultadoFinalController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    permission: "REL:GR",
                    breadCrumb: [{
                            display: "Relatórios",
                            active: false
                        },
                        {
                            display: "Resultado Final",
                            active: false,
                            stateName: APP_STATE.RELATORIOS.RESULTADO_FINAL.BASE
                        },
                        {
                            display: "Matrícula, movimentação, rendimento e reprovação por falta e desempenho",
                            active: true
                        }
                    ],
                    search: {
                        enabled: false
                    }
                }
            })
            .state(APP_STATE.RELATORIOS.RESULTADO_FINAL.ALFABETIZACAO_2ANO_1SERIE_REGULAR, {
                url: '/resultado-final/alfabetizacao-2ano-1serie-regular',
                params: {
                    report: null
                },
                views: {
                    'internal': {
                        templateUrl: 'relatorios/partials/resultado-final/resultado-final-alfabetizacao-2ano-1serie-regular-filter.tpl.html',
                        controller: 'ResultadoFinalController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    permission: "REL:GR",
                    breadCrumb: [{
                            display: "Relatórios",
                            active: false
                        },
                        {
                            display: "Resultado Final",
                            active: false,
                            stateName: APP_STATE.RELATORIOS.RESULTADO_FINAL.BASE
                        },
                        {
                            display: "Alfabetização 2º ano/1ª série regular",
                            active: true
                        }
                    ],
                    search: {
                        enabled: false
                    }
                }
            })
           .state(APP_STATE.RELATORIOS.RESULTADO_FINAL.INDICADORES_SUCESSO_RESULTADOS, {
                url: '/resultado-final/indicadores-sucesso-resultados',
                params: {
                    report: null
                },
                views: {
                    'internal': {
                        templateUrl: 'relatorios/partials/resultado-final/resultado-final-indicadores-sucesso-resultados-filter.tpl.html',
                        controller: 'ResultadoFinalController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    permission: "REL:GR",
                    breadCrumb: [{
                            display: "Relatórios",
                            active: false
                        },
                        {
                            display: "Resultado Final",
                            active: false,
                            stateName: APP_STATE.RELATORIOS.RESULTADO_FINAL.BASE
                        },
                        {
                            display: "Indicadores de sucesso - Resultados",
                            active: true
                        }
                    ],
                    search: {
                        enabled: false
                    }
                }
            })
            .state(APP_STATE.RELATORIOS.ACOMPANHAMENTO_MENSAL.BASE, {
                url: '/acompanhamento-mensal',
                views: {
                    'internal': {
                        templateUrl: 'relatorios/partials/acompanhamento-mensal/acompanhamento-mensal.tpl.html',
                        controller: 'BaseReportController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    permission: "REL:GR",
                    breadCrumb: [{
                            display: "Relatórios",
                            active: false
                        },
                        {
                            display: "Acompanhamento Mensal",
                            active: true
                        }
                    ],
                    search: {
                        enabled: false
                    },
                    reportGroup: REPORT_GROUP.ACOMPANHAMENTO_MENSAL.BASE
                }
            })
            .state(APP_STATE.RELATORIOS.ACOMPANHAMENTO_ANUAL.BASE, {
                url: '/acompanhamento-anual',
                views: {
                    'internal': {
                        templateUrl: 'relatorios/partials/acompanhamento-anual/acompanhamento-anual.tpl.html',
                        controller: 'BaseReportController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    permission: "REL:GR",
                    breadCrumb: [{
                            display: "Relatórios",
                            active: false
                        },
                        {
                            display: "Acompanhamento Anual",
                            active: true
                        }
                    ],
                    search: {
                        enabled: false
                    },
                    reportGroup: REPORT_GROUP.ACOMPANHAMENTO_ANUAL.BASE
                }
            }) //
            .state(APP_STATE.RELATORIOS.ACOMPANHAMENTO_SEMESTRAL.BASE, {
                url: '/acompanhamento-semestral',
                params: {
                    report: null
                },
                views: {
                    'internal': {
                        templateUrl: 'relatorios/partials/acompanhamento-semestral/acompanhamento-semestral.tpl.html',
                        controller: 'BaseReportController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    permission: "REL:GR",
                    breadCrumb: [{
                            display: "Relatórios",
                            active: false
                        },
                        {
                            display: "Acompanhamento Semestral",
                            active: true
                        }
                    ],
                    search: {
                        enabled: false
                    },
                    reportGroup: REPORT_GROUP.ACOMPANHAMENTO_SEMESTRAL.BASE
                }
            })
            .state(APP_STATE.RELATORIOS.RESULTADO_FINAL.LETRAMENTO_EM_PROGRAMACAO, {
                url: '/resultado-final/letramento-em-programacao',
                params: {
                    report: null
                },
                views: {
                    'internal': {
                        templateUrl: 'relatorios/partials/resultado-final/resultado-final-letramento-em-programacao-filter.tpl.html',
                        controller: 'ResultadoFinalController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    permission: "REL:GR",
                    breadCrumb: [{
                            display: "Relatórios",
                            active: false
                        },
                        {
                            display: "Resultado Final",
                            active: false,
                            stateName: APP_STATE.RELATORIOS.RESULTADO_FINAL.BASE
                        },
                        {
                            display: "Letramento em Programação",
                            active: true
                        }
                    ],
                    search: {
                        enabled: false
                    }
                }
            })
            .state(APP_STATE.RELATORIOS.ACOMPANHAMENTO_ANUAL.PERFIL_ATENDIMENTO_UNIDADES_ESCOLARES_CURSOS, {
                url: '/perfil-atendimento-unidades-escolares-cursos',
                params: {
                    report: null
                },
                views: {
                    'internal': {
                        templateUrl: 'relatorios/partials/acompanhamento-anual/perfil-atendimento-unidades-escolares-cursos-filter.tpl.html',
                        controller: 'RelatorioAcompanhamentoAnualController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    permission: "REL:GR",

                    breadCrumb: [{
                            display: "Relatórios",
                            active: false
                        },
                        {
                            display: "Acompanhamento Anual",
                            active: false,
                            stateName: APP_STATE.RELATORIOS.ACOMPANHAMENTO_ANUAL.BASE
                        },
                        {
                            display: "Perfil do Atendimento das Unidades Escolares - Cursos",
                            active: true
                        }
                    ],
                    search: {
                        enabled: false
                    }
                }
            })
            .state(APP_STATE.RELATORIOS.ACOMPANHAMENTO_ANUAL.PERFIL_ATENDIMENTO_UNIDADES_ESCOLARES_TURMAS, {
                url: '/perfil-atendimento-unidades-escolares-turmas',
                params: {
                    report: null
                },
                views: {
                    'internal': {
                        templateUrl: 'relatorios/partials/acompanhamento-anual/perfil-atendimento-unidades-escolares-turmas-filter.tpl.html',
                        controller: 'RelatorioAcompanhamentoAnualController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    permission: "REL:GR",
                    breadCrumb: [{
                            display: "Relatórios",
                            active: false
                        },
                        {
                            display: "Acompanhamento Anual",
                            active: false,
                            stateName: APP_STATE.RELATORIOS.ACOMPANHAMENTO_ANUAL.BASE
                        },
                        {
                            display: "Perfil do Atendimento das Unidades Escolares - Turmas",
                            active: true
                        }
                    ],
                    search: {
                        enabled: false
                    }
                }
            })
            .state(APP_STATE.RELATORIOS.OUTROS.TOTAL_ESCOLAS_PROGRAMA, {
                url: '/total-escolas-por-programa',
                params: {
                    report: null
                },
                views: {
                    'internal': {
                        templateUrl: 'relatorios/partials/acompanhamento-anual/total-escolas-por-programa-filter.tpl.html',
                        controller: 'RelatorioAcompanhamentoAnualController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    permission: "REL:GR",
                    breadCrumb: [{
                            display: "Relatórios",
                            active: false
                        },
                        {
                            display: "Outros",
                            active: false,
                            stateName: APP_STATE.RELATORIOS.OUTROS.BASE
                        },
                        {
                            display: "Total de Escolas por Programa",
                            active: true
                        }
                    ],
                    search: {
                        enabled: false
                    }
                }
            })
            .state(APP_STATE.RELATORIOS.ACOMPANHAMENTO_MENSAL.LEITURA_ESCRITA_ORALIDADE_TEXTO, {
                url: '/mensal/leitura-escrita-oralidade-texto',
                params: {
                    report: null
                },
                views: {
                    'internal': {
                        templateUrl: 'relatorios/partials/acompanhamento-mensal/acompanhamento-mensal-leitura-escrita-oralidade-texto-filter.tpl.html',
                        controller: 'RelatorioAcompanhamentoMensalController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    permission: "REL:GR",
                    breadCrumb: [{
                            display: "Relatórios",
                            active: false
                        },
                        {
                            display: "Acompanhamento Mensal",
                            active: false,
                            stateName: APP_STATE.RELATORIOS.ACOMPANHAMENTO_MENSAL.BASE
                        },
                        {
                            display: "Leitura, Escrita e Oralidade",
                            active: true
                        }
                    ],
                    search: {
                        enabled: false
                    }
                }
            })
            .state(APP_STATE.RELATORIOS.ACOMPANHAMENTO_MENSAL.LEITURA_ESCRITA_PRODUCAO_TEXTO, {
                url: '/mensal/leitura-escrita-producao-texto',
                params: {
                    report: null
                },
                views: {
                    'internal': {
                        templateUrl: 'relatorios/partials/acompanhamento-mensal/acompanhamento-mensal-leitura-escrita-producao-texto-filter.tpl.html',
                        controller: 'RelatorioAcompanhamentoMensalController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    permission: "REL:GR",
                    breadCrumb: [{
                            display: "Relatórios",
                            active: false
                        },
                        {
                            display: "Acompanhamento Mensal",
                            active: false,
                            stateName: APP_STATE.RELATORIOS.ACOMPANHAMENTO_MENSAL.BASE
                        },
                        {
                            display: "Leitura, Escrita e Produção de Texto",
                            active: true
                        }
                    ],
                    search: {
                        enabled: false
                    }
                }
            })
            .state(APP_STATE.RELATORIOS.ACOMPANHAMENTO_MENSAL.FLUXO_DE_OFICINAS, {
                url: '/mensal/fluxo-de-oficinas',
                params: {
                    report: null
                },
                views: {
                    'internal': {
                        templateUrl: 'relatorios/partials/acompanhamento-mensal/acompanhamento-mensal-fluxo-de-oficinas-filter.tpl.html',
                        controller: 'RelatorioAcompanhamentoMensalController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    permission: "REL:GR",
                    breadCrumb: [{
                            display: "Relatórios",
                            active: false
                        },
                        {
                            display: "Acompanhamento Mensal",
                            active: false,
                            stateName: APP_STATE.RELATORIOS.ACOMPANHAMENTO_MENSAL.BASE
                        },
                        {
                            display: "Fluxo de Oficinas",
                            active: true
                        }
                    ],
                    search: {
                        enabled: false
                    }
                }
            })
            .state(APP_STATE.RELATORIOS.ACOMPANHAMENTO_MENSAL.LEITURA_ESTRATEGIA_COMPREENSAO_MAT_NV_1, {
                url: '/mensal/leitura-estrategia-compreensao-matematica-nivel-1',
                params: {
                    report: null
                },
                views: {
                    'internal': {
                        templateUrl: 'relatorios/partials/acompanhamento-mensal/acompanhamento-mensal-matematica-formula-vitoria-matematica-nivel-1-filter.tpl.html',
                        controller: 'RelatorioAcompanhamentoMensalController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    permission: "REL:GR",
                    breadCrumb: [{
                            display: "Relatórios",
                            active: false
                        },
                        {
                            display: "Acompanhamento Mensal",
                            active: false,
                            stateName: APP_STATE.RELATORIOS.ACOMPANHAMENTO_MENSAL.BASE
                        },
                        {
                            display: "Acompanhamento de Leitura, Estratégia e Compreensão Matemática Nível 1",
                            active: true
                        }
                    ],
                    search: {
                        enabled: false
                    }
                }
            })
            .state(APP_STATE.RELATORIOS.ACOMPANHAMENTO_MENSAL.FORMULA_VITORIA_LINGUA_PORTUGUESA_ACOMPANHAMENTO, {
                url: '/mensal/formula-vitoria-lingua-portuguesa-acompanhamento',
                params: {
                    report: null
                },
                views: {
                    'internal': {
                        templateUrl: 'relatorios/partials/acompanhamento-mensal/acompanhamento-mensal-formula-vitoria-lingua-portuguesa-acompanhamento-filter.tpl.html',
                        controller: 'RelatorioAcompanhamentoMensalController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    permission: "REL:GR",
                    breadCrumb: [{
                            display: "Relatórios",
                            active: false
                        },
                        {
                            display: "Acompanhamento Mensal",
                            active: false,
                            stateName: APP_STATE.RELATORIOS.ACOMPANHAMENTO_MENSAL.BASE
                        },
                        {
                            display: "Fórmula da Vitória Língua Portuguesa – Acompanhamento de Leitura, Escrita",
                            active: true
                        }
                    ],
                    search: {
                        enabled: false
                    }
                }
            })
            .state(APP_STATE.RELATORIOS.ACOMPANHAMENTO_MENSAL.LEITURA_ESTRATEGIA_COMPREENSAO_MAT_NV_2, {
                url: '/mensal/leitura-estrategia-compreensao-matematica-nivel-2',
                params: {
                    report: null
                },
                views: {
                    'internal': {
                        templateUrl: 'relatorios/partials/acompanhamento-mensal/acompanhamento-mensal-matematica-formula-vitoria-matematica-nivel-2-filter.tpl.html',
                        controller: 'RelatorioAcompanhamentoMensalController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    permission: "REL:GR",
                    breadCrumb: [{
                            display: "Relatórios",
                            active: false
                        },
                        {
                            display: "Acompanhamento Mensal",
                            active: false,
                            stateName: APP_STATE.RELATORIOS.ACOMPANHAMENTO_MENSAL.BASE
                        },
                        {
                            display: "Acompanhamento de Leitura, Estratégia e Compreensão Matemática Nível 2",
                            active: true
                        }
                    ],
                    search: {
                        enabled: false
                    }
                }
            })
            .state(APP_STATE.RELATORIOS.ACOMPANHAMENTO_MENSAL.ALFABETIZACAO_ACELERACAO_FORMULA_VITORIA, {
                url: '/mensal/acompanhamento-mensal',
                params: {
                    report: null
                },
                views: {
                    'internal': {
                        templateUrl: 'relatorios/partials/acompanhamento-mensal/acompanhamento-mensal-aafv-filter.tpl.html',
                        controller: 'RelatorioAcompanhamentoMensalController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    permission: "REL:GR",
                    breadCrumb: [{
                            display: "Relatórios",
                            active: false
                        },
                        {
                            display: "Acompanhamento Mensal",
                            active: false,
                            stateName: APP_STATE.RELATORIOS.ACOMPANHAMENTO_MENSAL.BASE
                        },
                        {
                            display: "Acompanhamento Mensal",
                            active: true
                        }
                    ],
                    search: {
                        enabled: false
                    }
                }
            })
            .state(APP_STATE.RELATORIOS.ACOMPANHAMENTO_MENSAL.ENSINO_REGULAR, {
                url: '/mensal/ensino-regular',
                params: {
                    report: null
                },
                views: {
                    'internal': {
                        templateUrl: 'relatorios/partials/acompanhamento-mensal/acompanhamento-mensal-totalizacao-mes-filter.tpl.html',
                        controller: 'RelatorioAcompanhamentoMensalController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    permission: "REL:GR",
                    breadCrumb: [{
                            display: "Relatórios",
                            active: false
                        },
                        {
                            display: "Acompanhamento Mensal",
                            active: false,
                            stateName: APP_STATE.RELATORIOS.ACOMPANHAMENTO_MENSAL.BASE
                        },
                        {
                            display: "Ensino Regular",
                            active: true
                        }
                    ],
                    search: {
                        enabled: false
                    }
                }
            })
            .state(APP_STATE.RELATORIOS.ACOMPANHAMENTO_MENSAL.FORMULA_VITORIA, {
                url: '/mensal/formula-vitoria',
                params: {
                    report: null
                },
                views: {
                    'internal': {
                        templateUrl: 'relatorios/partials/acompanhamento-mensal/acompanhamento-mensal-totalizacao-mes-filter.tpl.html',
                        controller: 'RelatorioAcompanhamentoMensalController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    permission: "REL:GR",
                    breadCrumb: [{
                            display: "Relatórios",
                            active: false
                        },
                        {
                            display: "Acompanhamento Mensal",
                            active: false,
                            stateName: APP_STATE.RELATORIOS.ACOMPANHAMENTO_MENSAL.BASE
                        },
                        {
                            display: "Fórmula da Vitória",
                            active: true
                        }
                    ],
                    search: {
                        enabled: false
                    }
                }
            })
            .state(APP_STATE.RELATORIOS.ACOMPANHAMENTO_MENSAL.MATEMATICA, {
                url: '/mensal/matematica',
                params: {
                    report: null
                },
                views: {
                    'internal': {
                        templateUrl: 'relatorios/partials/acompanhamento-mensal/acompanhamento-mensal-totalizacao-mes-filter.tpl.html',
                        controller: 'RelatorioAcompanhamentoMensalController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    permission: "REL:GR",
                    breadCrumb: [{
                            display: "Relatórios",
                            active: false
                        },
                        {
                            display: "Acompanhamento Mensal",
                            active: false,
                            stateName: APP_STATE.RELATORIOS.ACOMPANHAMENTO_MENSAL.BASE
                        },
                        {
                            display: "Matemática",
                            active: true
                        }
                    ],
                    search: {
                        enabled: false
                    }
                }
            })
            .state(APP_STATE.RELATORIOS.ACOMPANHAMENTO_MENSAL.FLUXO_AULAS, {
                url: '/mensal/fluxo-aulas',
                params: {
                    report: null
                },
                views: {
                    'internal': {
                        templateUrl: 'relatorios/partials/acompanhamento-mensal/acompanhamento-mensal-fluxo-aulas-filter.tpl.html',
                        controller: 'RelatorioAcompanhamentoMensalController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    permission: "REL:GR",
                    breadCrumb: [{
                            display: "Relatórios",
                            active: false
                        },
                        {
                            display: "Acompanhamento Mensal",
                            active: false,
                            stateName: APP_STATE.RELATORIOS.ACOMPANHAMENTO_MENSAL.BASE
                        },
                        {
                            display: "Fluxo de Aulas",
                            active: true
                        }
                    ],
                    search: {
                        enabled: false
                    }
                }
            })
            .state(APP_STATE.RELATORIOS.ACOMPANHAMENTO_MENSAL.ACOMPANHAMENTO_MUNICIPIO, {
                url: '/mensal/acompanhamento-mensal-municipio',
                params: {
                    report: null
                },
                views: {
                    'internal': {
                        templateUrl: 'relatorios/partials/acompanhamento-mensal/acompanhamento-municipio-mensal-filter.tpl.html',
                        controller: 'RelatorioAcompanhamentoMensalController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    permission: "REL:GR",
                    breadCrumb: [{
                            display: "Relatórios",
                            active: false
                        },
                        {
                            display: "Acompanhamento Mensal",
                            active: false,
                            stateName: APP_STATE.RELATORIOS.ACOMPANHAMENTO_MENSAL.BASE
                        },
                        {
                            display: "Acompanhamento Município Mensal",
                            active: true
                        }
                    ],
                    search: {
                        enabled: false
                    }
                }
            })
            .state(APP_STATE.RELATORIOS.OUTROS.BASE, {
                url: '/outros',
                views: {
                    'internal': {
                        templateUrl: 'relatorios/partials/outros/outros.tpl.html',
                        controller: 'BaseReportController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    permission: "REL:GR",
                    breadCrumb: [{
                            display: "Relatórios",
                            active: false
                        },
                        {
                            display: "Outros",
                            active: true
                        }
                    ],
                    search: {
                        enabled: false
                    },
                    reportGroup: REPORT_GROUP.OUTROS.BASE
                }
            })
            .state(APP_STATE.RELATORIOS.RESULTADO_FINAL.IDADE_CONCLUSAO_4SERIE_5ANO, {
                url: '/idade-conclusao-4serie-5ano',
                params: {
                    report: null
                },
                views: {
                    'internal': {
                        templateUrl: 'relatorios/partials/resultado-final/idade-conclusao-4serie-5ano-filter.tpl.html',
                        controller: 'RelatorioOutrosController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    permission: "REL:GR",
                    breadCrumb: [{
                            display: "Relatórios",
                            active: false
                        },
                        {
                            display: "Resultado Final",
                            active: false,
                            stateName: APP_STATE.RELATORIOS.RESULTADO_FINAL.BASE
                        },
                        {
                            display: "Idade de Conclusão da 4ª Série/5º ano",
                            active: true
                        }
                    ],
                    search: {
                        enabled: false
                    }
                }
            })
            .state(APP_STATE.RELATORIOS.OUTROS.NUMERO_ALUNOS_POR_ANO_SITUACAO, {
                url: '/numero-alunos-por-ano-situacao',
                params: {
                    report: null
                },
                views: {
                    'internal': {
                        templateUrl: 'relatorios/partials/outros/numero-alunos-por-ano-situacao-filter.tpl.html',
                        controller: 'RelatorioOutrosController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    permission: "REL:GR",
                    breadCrumb: [{
                            display: "Relatórios",
                            active: false
                        },
                        {
                            display: "Outros",
                            active: false,
                            stateName: APP_STATE.RELATORIOS.OUTROS.BASE
                        },
                        {
                            display: "Número de alunos por ano/série e situação",
                            active: true
                        }
                    ],
                    search: {
                        enabled: false
                    }
                }
            })
            .state(APP_STATE.RELATORIOS.OUTROS.STATUS_INSERCAO_COLETAS, {
                url: '/status-insercao-coletas',
                params: {
                    report: null
                },
                views: {
                    'internal': {
                        templateUrl: 'relatorios/partials/outros/status-insercao-coletas-filter.tpl.html',
                        controller: 'RelatorioOutrosController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    permission: "REL:GR",
                    breadCrumb: [{
                        display: "Relatórios",
                        active: false
                    },
                        {
                            display: "Outros",
                            active: false,
                            stateName: APP_STATE.RELATORIOS.OUTROS.BASE
                        },
                        {
                            display: "Status de inserção de coletas",
                            active: true
                        }
                    ],
                    search: {
                        enabled: false
                    }
                }
            })
            .state(APP_STATE.RELATORIOS.OUTROS.MUNICIPIOS_POR_PROGRAMA, {
                url: '/municipios-por-programa',
                params: {
                    report: null
                },
                views: {
                    'internal': {
                        templateUrl: 'relatorios/partials/municipios-por-programa/municipios-por-programa-filter.tpl.html',
                        controller: 'RelatorioMunicipiosPorProgramaController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    permission: "REL:GR",
                    breadCrumb: [{
                            display: "Relatórios",
                            active: false
                        },
                        {
                            display: "Outros",
                            active: false,
                            stateName: APP_STATE.RELATORIOS.OUTROS.BASE
                        },
                        {
                            display: "Total de Municípios por Programa",
                            active: true
                        }
                    ],
                    search: {
                        enabled: false
                    }
                }
            })
            .state(APP_STATE.RELATORIOS.OUTROS.ESCOLAS_EDUCACAO_INFANTIL_JOVENS_ADULTOS, {
                url: '/escolas-educacao-infantil-jovens-adultos',
                params: {
                    report: null
                },
                views: {
                    'internal': {
                        templateUrl: 'relatorios/partials/outros/escolas-educacao-infantil-jovens-adultos-filter.tpl.html',
                        controller: 'RelatorioOutrosController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    permission: "REL:GR",
                    breadCrumb: [{
                            display: "Relatórios",
                            active: false
                        },
                        {
                            display: "Outros",
                            active: false,
                            stateName: APP_STATE.RELATORIOS.OUTROS.BASE
                        },
                        {
                            display: "Escolas com educação infantil e de jovens e adultos",
                            active: true
                        }
                    ],
                    search: {
                        enabled: false
                    }
                }
            })
            .state(APP_STATE.RELATORIOS.OUTROS.PERFIL_ATENDIMENTO_MUNICIPIO, {
                url: '/perfil-atendimento-municipio',
                params: {
                    report: null
                },
                views: {
                    'internal': {
                        templateUrl: 'relatorios/partials/outros/perfil-atendimento-municipio-filter.tpl.html',
                        controller: 'RelatorioOutrosController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    permission: "REL:GR",
                    breadCrumb: [{
                            display: "Relatórios",
                            active: false
                        },
                        {
                            display: "Outros",
                            active: false,
                            stateName: APP_STATE.RELATORIOS.OUTROS.BASE
                        },
                        {
                            display: "Perfil do Atendimento do Município",
                            active: true
                        }
                    ],
                    search: {
                        enabled: false
                    }
                }
            })
            .state(APP_STATE.RELATORIOS.OUTROS.LISTA_UNIDADES_ESCOLARES, {
                url: '/lista-unidades-escolares',
                params: {
                    report: null
                },
                views: {
                    'internal': {
                        templateUrl: 'relatorios/partials/lista-unidades-escolares/lista-unidades-escolares-filter.tpl.html',
                        controller: 'RelatorioListaUnidadesEscolaresController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    permission: "REL:GR",
                    breadCrumb: [{
                            display: "Relatórios",
                            active: false
                        },
                        {
                            display: "Outros",
                            active: false,
                            stateName: APP_STATE.RELATORIOS.OUTROS.BASE
                        },
                        {
                            display: "Lista de unidades escolares",
                            active: true
                        }
                    ],
                    search: {
                        enabled: false
                    }
                }
            })
            .state(APP_STATE.RELATORIOS.OUTROS.LISTA_UNIDADES_ESCOLARES_PERFIL_ATENDIMENTO, {
                url: '/lista-unidades-escolares-perfil-atendimento',
                params: {
                    report: null
                },
                views: {
                    'internal': {
                        templateUrl: 'relatorios/partials/outros/lista-unidades-escolares-perfil-atendimento-filter.tpl.html',
                        controller: 'RelatorioOutrosController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    permission: "REL:GR",
                    breadCrumb: [{
                            display: "Relatórios",
                            active: false
                        },
                        {
                            display: "Outros",
                            active: false,
                            stateName: APP_STATE.RELATORIOS.OUTROS.BASE
                        },
                        {
                            display: "Lista de unidades escolares - Perfil do Atendimento",
                            active: true
                        }
                    ],
                    search: {
                        enabled: false
                    }
                }
            })
            .state(APP_STATE.RELATORIOS.OUTROS.MEDIA_DIAS_LETIVOS, {
                url: '/media-dias-letivos',
                params: {
                    report: null
                },
                views: {
                    'internal': {
                        templateUrl: 'relatorios/partials/media-dias-letivos/media-dias-letivos-filter.tpl.html',
                        controller: 'RelatorioMediaDiasLetivosController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    permission: "REL:GR",
                    breadCrumb: [{
                            display: "Relatórios",
                            active: false
                        },
                        {
                            display: "Outros",
                            active: false,
                            stateName: APP_STATE.RELATORIOS.OUTROS.BASE
                        },
                        {
                            display: "Média de dias letivos",
                            active: true
                        }
                    ],
                    search: {
                        enabled: false
                    }
                }
            })
            .state(APP_STATE.RELATORIOS.RESULTADO_FINAL.IDADE_CONCLUSAO_8SERIE_9ANO, {
                url: '/idade-conclusao-8serie-9ano',
                params: {
                    report: null
                },
                views: {
                    'internal': {
                        templateUrl: 'relatorios/partials/resultado-final/idade-conclusao-8serie-9ano-filter.tpl.html',
                        controller: 'RelatorioOutrosController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    permission: "REL:GR",
                    breadCrumb: [{
                            display: "Relatórios",
                            active: false
                        },
                        {
                            display: "Resultado Final",
                            active: false,
                            stateName: APP_STATE.RELATORIOS.RESULTADO_FINAL.BASE
                        },
                        {
                            display: "Idade de Conclusão da 8ª Série/9º ano",
                            active: true
                        }
                    ],
                    search: {
                        enabled: false
                    }
                }
            })
            .state(APP_STATE.RELATORIOS.OUTROS.MATRICULA_ENSINO_FUNDAMENTAL_REDE, {
                url: '/matricula-ensino-fundamental-rede',
                params: {
                    report: null
                },
                views: {
                    'internal': {
                        templateUrl: 'relatorios/partials/outros/matricula-ensino-fundamental-rede-filter.tpl.html',
                        controller: 'RelatorioOutrosController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    permission: "REL:GR",
                    breadCrumb: [{
                            display: "Relatórios",
                            active: false
                        },
                        {
                            display: "Outros",
                            active: false,
                            stateName: APP_STATE.RELATORIOS.OUTROS.BASE
                        },
                        {
                            display: "Matrícula do Ensino Fundamenal por Rede",
                            active: true
                        }
                    ],
                    search: {
                        enabled: false
                    }
                }
            })
            .state(APP_STATE.RELATORIOS.OUTROS.SISTEMATICA_ACOMPANHAMENTO_VISITAS_REGIONAL, {
                url: '/sistematica-acompanhamento-visitas-regional',
                params: {
                    report: null
                },
                views: {
                    'internal': {
                        templateUrl: 'relatorios/partials/outros/sistematica-acompanhamento-visitas-filter.tpl.html',
                        controller: 'RelatorioOutrosController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    permission: "REL:GR",
                    breadCrumb: [{
                            display: "Relatórios",
                            active: false
                        },
                        {
                            display: "Outros",
                            active: false,
                            stateName: APP_STATE.RELATORIOS.OUTROS.BASE
                        },
                        {
                            display: "Sistemática Acompanhamento de Visitas - Regional",
                            active: true
                        }
                    ],
                    search: {
                        enabled: false
                    }
                }
            })
            .state(APP_STATE.RELATORIOS.OUTROS.SISTEMATICA_MONITORAMENTO_REGIONAL, {
                url: '/sistematica-monitoramento-regional',
                params: {
                    report: null
                },
                views: {
                    'internal': {
                        templateUrl: 'relatorios/partials/outros/sistematica-monitoramento-filter.tpl.html',
                        controller: 'RelatorioOutrosController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    permission: "REL:GR",
                    breadCrumb: [{
                            display: "Relatórios",
                            active: false
                        },
                        {
                            display: "Outros",
                            active: false,
                            stateName: APP_STATE.RELATORIOS.OUTROS.BASE
                        },
                        {
                            display: "Sistemática Monitoramento - Regional",
                            active: true
                        }
                    ],
                    search: {
                        enabled: false
                    }
                }
            })
            .state(APP_STATE.RELATORIOS.OUTROS.SISTEMATICA_ACOMPANHAMENTO_VISITAS_CENTRAL, {
              url: '/sistematica-acompanhamento-visitas-central',
              params: {
                  report: null
              },
              views: {
                  'internal': {
                      templateUrl: 'relatorios/partials/outros/sistematica-acompanhamento-visitas-central-filter.tpl.html',
                      controller: 'RelatorioOutrosController',
                      controllerAs: 'vm'
                  }
              },
              data: {
                  permission: "REL:GR",
                  breadCrumb: [{
                      display: "Relatórios",
                      active: false
                  },
                      {
                          display: "Outros",
                          active: false,
                          stateName: APP_STATE.RELATORIOS.OUTROS.BASE
                      },
                      {
                          display: "Sistemática Acompanhamento de Visitas - Central",
                          active: true
                      }
                  ],
                  search: {
                      enabled: false
                  }
              }
          })
            .state(APP_STATE.RELATORIOS.OUTROS.SISTEMATICA_MONITORAMENTO_CENTRAL, {
              url: '/sistematica-monitoramento-central',
              params: {
                  report: null
              },
              views: {
                  'internal': {
                      templateUrl: 'relatorios/partials/outros/sistematica-monitoramento-central-filter.tpl.html',
                      controller: 'RelatorioOutrosController',
                      controllerAs: 'vm'
                  }
              },
              data: {
                  permission: "REL:GR",
                  breadCrumb: [{
                      display: "Relatórios",
                      active: false
                  },
                      {
                          display: "Outros",
                          active: false,
                          stateName: APP_STATE.RELATORIOS.OUTROS.BASE
                      },
                      {
                          display: "Sistemática Monitoramento - Central",
                          active: true
                      }
                  ],
                  search: {
                      enabled: false
                  }
              }
          })
          .state(APP_STATE.RELATORIOS.RESULTADO_BIMESTRAL.LETRAMENTO_EM_PROGRAMACAO, {
              url: '/resultado-bimestral/acompanhamento-semestral',
              params: {
                  report: null
              },
              views: {
                  'internal': {
                      templateUrl: 'relatorios/partials/acompanhamento-semestral/acompanhamento-semestral-filter.tpl.html',
                      controller: 'RelatorioAcompanhamentoSemestralController',
                      controllerAs: 'vm'
                  }
              },
              data: {
                  permission: "REL:GR",
                  breadCrumb: [{
                      display: "Relatórios",
                      active: false
                  },
                      {
                          display: "Acompanhamento Bimestral",
                          active: false,
                          stateName: APP_STATE.RELATORIOS.RESULTADO_BIMESTRAL.BASE
                      },
                      {
                          display: "Letramento em Programação",
                          active: true
                      }
                  ],
                  search: {
                      enabled: false
                  },
                  reportGroup: REPORT_GROUP.ACOMPANHAMENTO_SEMESTRAL.BASE
              }
          }) //ricson
          .state(APP_STATE.RELATORIOS.RESULTADO_BIMESTRAL.ENSINO_MEDIO_GESTAO_NOTA_10, {
                url: '/resultado-bimestral/ensino-medio-gestao-nota-10',
                params: {
                    report: null
                },
                views: {
                    'internal': {
                        templateUrl: 'relatorios/partials/resultado-bimestral/ensino-medio-gestao-nota-10.tpl.html',
                        controller: 'ResultadoBimestralController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    permission: "REL:GR",
                    breadCrumb: [{
                            display: "Relatórios",
                            active: false
                        },
                        {
                            display: "Resultado Bimestral",
                            active: false,
                            stateName: APP_STATE.RELATORIOS.RESULTADO_BIMESTRAL.BASE
                        },
                        {
                            display: "Ensino Médio - Gestão Nota 10",
                            active: true
                        }
                    ],
                    search: {
                        enabled: false
                    }
                }
            })
            .state(APP_STATE.RELATORIOS.RESULTADO_BIMESTRAL.FORMULA_VITORIA, {
                url: '/resultado-bimestral/formula-vitoria',
                params: {
                    report: null
                },
                views: {
                    'internal': {
                        templateUrl: 'relatorios/partials/resultado-bimestral/resultado-bimestral-formula-vitoria-filter.tpl.html',
                        controller: 'ResultadoBimestralController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    permission: "REL:GR",
                    breadCrumb: [{
                            display: "Relatórios",
                            active: false
                        },
                        {
                            display: "Resultado Bimestral",
                            active: false,
                            stateName: APP_STATE.RELATORIOS.RESULTADO_BIMESTRAL.BASE
                        },
                        {
                            display: "Fórmula da Vitória",
                            active: true
                        }
                    ],
                    search: {
                        enabled: false
                    }
                }
            })
            .state(APP_STATE.RELATORIOS.RESULTADO_BIMESTRAL.ENSINO_REGULAR, {
                url: '/resultado-bimestral/ensino-regular',
                params: {
                    report: null
                },
                views: {
                    'internal': {
                        templateUrl: 'relatorios/partials/resultado-bimestral/resultado-bimestral-ensino-regular-filter.tpl.html',
                        controller: 'ResultadoBimestralController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    permission: "REL:GR",
                    breadCrumb: [{
                            display: "Relatórios",
                            active: false
                        },
                        {
                            display: "Resultado Bimestral",
                            active: false,
                            stateName: APP_STATE.RELATORIOS.RESULTADO_BIMESTRAL.BASE
                        },
                        {
                            display: "Ensino Regular",
                            active: true
                        }
                    ],
                    search: {
                        enabled: false
                    }
                }
            })
            .state(APP_STATE.RELATORIOS.RESULTADO_BIMESTRAL.BASE, {
                url: '/resultado-bimestral',
                params: {
                    report: null
                },
                views: {
                    'internal': {
                        templateUrl: 'relatorios/partials/resultado-bimestral/resultado-bimestral-relatorios.tpl.html',
                        controller: 'BaseReportController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    permission: "REL:GR",
                    breadCrumb: [{
                            display: "Relatórios",
                            active: false
                        },
                        {
                            display: "Resultado Bimestral",
                            active: false,
                            stateName: APP_STATE.RELATORIOS.RESULTADO_BIMESTRAL.BASE
                        },
                        {
                            display: "Resultado Bimestral",
                            active: true
                        }
                    ],
                    search: {
                        enabled: false
                    },
                    reportGroup: REPORT_GROUP.RESULTADO_BIMESTRAL.BASE
                }
            })
            .state(APP_STATE.RELATORIOS.OUTROS.GENERO_SITUACAO_ANO_ANTERIOR, {
                url: '/genero-situacao-ano-anterior',
                params: {
                    report: null
                },
                views: {
                    'internal': {
                        templateUrl: 'relatorios/partials/outros/genero-situacao-ano-anterior-filter.tpl.html',
                        controller: 'RelatorioOutrosController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    permission: "REL:GR",
                    breadCrumb: [{
                            display: "Relatórios",
                            active: false
                        },
                        {
                            display: "Outros",
                            active: false,
                            stateName: APP_STATE.RELATORIOS.OUTROS.BASE
                        },
                        {
                            display: "Gênero e situação do ano anterior",
                            active: true
                        }
                    ],
                    search: {
                        enabled: false
                    }
                }
            })


    }
}());
