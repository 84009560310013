/*
jslint
    devel: true, maxerr: 10
*/

/*
global
    required: true
*/

/*
 * Selection Criteria Resource
 * @module app.criterioSelecao (Selection Criteria Module
 * @author iFactory Solutions Ltda.
 * @version 1.0.0
 * @since 1.0.0
 */
(function() {
    'use strict';

    angular.module('app.criterioSelecao').factory('CriterioSelecaoResource', CriterioSelecaoResource);
    CriterioSelecaoResource.$inject = ['$resource', 'APP_PROPERTY'];

    function CriterioSelecaoResource($resource, APP_PROPERTY) {
        return $resource(APP_PROPERTY.URL + '/criterios-selecao/:id', { id: '@id' }, {
            update: {
                method: 'PUT'
            },
            get: {
                method: 'GET'
            },
            filter: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/criterios-selecao'
            },
            findAllEnabled: {
                method: 'GET',
                url: APP_PROPERTY.URL + '/criterios-selecao/ATIVO',
                isArray: true
            },
            findByStatus: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/criterios-selecao/:status'
            },
            delete: {
                method: 'DELETE',
                transformResponse: function(data, headers, statusCode) {
                        var finalRsponse = {
                            data: data,
                            responseStatusCode: statusCode
                        };
                    return finalRsponse;
                },
                url: APP_PROPERTY.URL + '/criterios-selecao/:id'
            }
        });
    }

}());