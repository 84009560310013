(function () {
    'use strict';

    angular.module('app.municipios').controller('MunicipiosFormController', MunicipiosFormController);
    MunicipiosFormController.$inject = ['APP_STATE', 'TABLE_FIELDS', 'APP_EVENT', 'COMMON_STATUS_LIST',
        '$state', '$scope', 'municipiosService', 'dialogService', 'toastService', 'ufService'];

    function MunicipiosFormController(APP_STATE, TABLE_FIELDS, APP_EVENT, COMMON_STATUS_LIST,
        $state, $scope, municipiosService, dialogService, toastService, ufService) {

        var vm = this;
        vm.ufs = ufService.ativos();

        vm.getRegionais = function(ufId) {
            vm.regionais = ufService.regionais(ufId);
        }

        vm.goBack = function () {
            $state.go(APP_STATE.MUNICIPIOS.SEARCH);
        };

        vm.save = function () {
            if (vm.registerForm.$valid) {
                municipiosService.save(vm.municipio).then(function (municipio) {
                    $scope.$emit(APP_EVENT.MUNICIPIOS.SAVED);
                    vm.goBack();
                }, function (response) {
                    toastService.error({
                        status: response.status,
                        message: response.data.message
                    });
                });
            }
        };

        vm.enableEditMode = function () {
            vm.editMode = true;
        };

        vm.cancel = function () {
            if (vm.refreshSearch) {
                $scope.$emit(APP_EVENT.MUNICIPIOS.REFRESH);
            }

            vm.goBack();
        };

        (function () {
            vm.editMode = false;
            vm.editPage = false;
            vm.defaultStatus = COMMON_STATUS_LIST[0];
            
            if ($state.is(APP_STATE.MUNICIPIOS.EDIT)) {
                vm.editPage = true;

                if ($state.params.id) {
                    municipiosService.get($state.params.id).then(function (municipio) {
                        vm.ufs.$promise.then(function(data){
                            if (is.not.existy(_.findWhere(data, {id: municipio.uf.id}))){
                                vm.ufs.push(municipio.uf);
                                vm.ufs = _.sortBy(vm.ufs, 'nome');
                            }
                        });
                        ufService.regionais(municipio.uf.id).$promise.then(function (response){
                            vm.regionais = response;
                            vm.municipio = municipio;
                        });
                    }, function (response) {
                        toastService.error({
                            status: response.status,
                            message: response.data.message
                        });
                    });
                } else {
                    vm.goBack();
                }
            } else {
                vm.enableEditMode();
                vm.municipio = {};
                vm.municipio.situacao = vm.defaultStatus;
            }
        } ());
    }

} ());
