(function(){
    'use strict';

    angular.module('app.areaConhecimento').service('areaConhecimentoService', AreaConhecimentoService);
    AreaConhecimentoService.$inject = ['AreaConhecimento', 'STATUS_ENUM'];

    function AreaConhecimentoService(AreaConhecimento, STATUS_ENUM) {

        this.filter = function(filter) {
            return AreaConhecimento.filter(filter).$promise;            
        };

        this.get = function(id) {
            return AreaConhecimento.get({ id: id }).$promise;
        };

        this.delete = function(areaConhecimento) {
            return AreaConhecimento.delete({id: areaConhecimento.id}).$promise;
        };

        this.ativos = function() {
            return AreaConhecimento.ativo();
        };

        this.porNome = function(nome) {
            return AreaConhecimento.porNome({ nome: nome }).$promise;
        };

        this.save = function(areaConhecimento) {
            var entity = new AreaConhecimento(areaConhecimento);
            
            if (entity.id) {
                return entity.$update();
            } else {
                return entity.$save();
            }
        };

    }

}());
