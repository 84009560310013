(function() {
    'use strict';

    angular.module('app.coletaRubrica.resposta').controller('ColetaRespostaFormController', ColetaRespostaFormController);
    ColetaRespostaFormController.$inject = ['APP_STATE', 'APP_EVENT', '$scope', '$state',
        'coletaRespostaService', 'toastService', 'scrollService', 'DialogService'
    ];

    function ColetaRespostaFormController(APP_STATE, APP_EVENT, $scope, $state,
        coletaRespostaService, toastService, scrollService, DialogService) {

        var vm = this;

        vm.goBack = function() {
            $state.go(APP_STATE.RESPOSTA_COLETA.SEARCH);
        };

        vm.conclude = function() {
            coletaRespostaService.conclude($state.params.id).then(function(data) {
                toastService.success("Coleta concluída com sucesso.");
                $scope.$emit(APP_EVENT.RESPOSTA_COLETA.REFRESH);
                vm.goBack();
            }, function(error) {
                if (is.existy(error.data)) {
                    vm.resposta.constraints = error.data;
                } else {
                    delete vm.resposta.constraints;
                    toastService.error(error);
                }
            }).finally(function() {
                scrollService.scrollTop();
            });
        };

        vm.saveAll = function() {
            if (vm.registerForm.$valid) {
                coletaRespostaService.saveAuthenticated(vm.resposta).then(function(data) {
                    delete vm.resposta.constraints;
                    // TODO Remover essa lógica daqui e fazer com que o back-end informações mínimas, dentre elas a situação atualizada.
                    if (is.existy(vm.resposta) && is.existy(vm.resposta.situacao) && vm.resposta.situacao.key == '1') {
                        vm.resposta.situacao.key = '2';
                        vm.resposta.situacao.value = 'Em Andamento';
                    }
                    vm.registerForm.$setPristine();
                    toastService.success("Operação realizada com sucesso.");
                }, function(error) {
                    if (is.existy(error.constraints)) {
                        vm.resposta.constraints = error.constraints;
                    } else {
                        delete vm.resposta.constraints;
                        toastService.error(error);
                    }
                }).finally(function() {
                    scrollService.scrollTop();
                });
            }
        };

        vm.cancel = function() {
            $scope.$emit(APP_EVENT.RESPOSTA_COLETA.REFRESH);
            vm.goBack();
        };

        var _extractGeneralConstraints = function(answerCollect) {
            if (is.array(answerCollect.constraints)) {
                return _.filter(answerCollect.constraints, function(element) {
                    return is.existy(element.identification) && is.not.existy(element.identification.idFormularioItem);
                });
            } else {
                return [];
            }
        };

        (function() {
            vm.error = null;

            if ($state.params.id) {
                coletaRespostaService.viewById($state.params.id).then(function(resposta) {
                    if (resposta.itensColeta.length < 1) {
                        DialogService.alert("Coleta não possui dados de resposta.");
                        vm.cancel();
                    }
                    vm.resposta = resposta;
                    vm.resposta.idColetaResposta = $state.params.id;
                    vm.resposta.generalConstraints = _extractGeneralConstraints(resposta);

                }, function(error) {
                    vm.error = error;
                });
            } else {
                vm.goBack();
            }
        })();
    }

}());