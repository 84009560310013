/*
jslint
    devel: true, maxerr: 10
*/

/*
global
    required: true
*/

/*
 * Relatórios Module
 * @module constants
 * @author iFactory Solutions Ltda.
 * @version 1.0.0
 * @since 1.0.0
 */
(function() {
    'use strict';

    var ALVO = {
        ALUNO: 'ALUNO',
        PROFESSOR: 'PROFESSOR',
        TURMA: 'TURMA',
        ESCOLA: 'ESCOLA'
    };

    angular.module('app.relatorios').constant('REPORT_TYPE', {
        ESCOLA: {
            MENSAL: {
                ALFABETIZACAO_ACELERACAO: {
                    path: "acompanhamento-mensal-alfabetizacao-aceleracao",
                    alvo: ALVO.TURMA
                }
            },
            ANUAL: {
                PERFIL_TURMA_ALFAB_ACEL: {
                    path: "perfil-turma-alfabetizacao-aceleracao",
                    alvo: ALVO.TURMA
                }
            }
        }
    });

    angular.module('app.relatorios').constant('REPORT_PROGRAMS', {
        IDS_ALFABETIZACAO_ACELERACAO: [1,2],
        IDS_GESTAO_ALFABETIZACAO: [7],
        IDS_SISTEMATICA_ACOMPANHAMENTO_DIALOGOS: [34],
        IDS_FORMULA_VITORIA: [3,5,6],
        IDS_CIRCUITO_CAMPEAO: [8,9,10,13,14],
        IDS_GESTAO_NOTA_10: [11,12,16,17,18,19,20,21,22,23,24],
        IDS_GESTAO_NOTA_10_INICIAIS: [11,16,17,18,19,20],
        IDS_GESTAO_NOTA_10_FINAIS: [12,21,22,23,24],
        IDS_GESTAO_NOTA_10_6_ANO_AO_9_ANO: [21,22,23,24],
        IDS_GESTAO_NOTA_10_ENSINO_MEDIO: [27,28,29],
        IDS_ENSINO_REGULAR: [8,9,10,13,14,11,12,16,17,18,19,20,21,22,23,24]
    });

    angular.module('app.relatorios').constant('REPORT_GROUP', {
        RESULTADO_BIMESTRAL: {
            BASE: "resultado-bimestral",
            ENSINO_MEDIO_GESTAO_NOTA_10: "resultado-bimestral.ensino-medio-gestao-nota-10",
            FORMULA_VITORIA: "resultado-bimestral.formula-vitoria",
            ENSINO_REGULAR: "resultado-bimestral.ensino-regular",
            LETRAMENTO_EM_PROGRAMACAO: 'acompanhamento-semestral.letramento-em-programacao'
        },
        RESULTADO_FINAL: {
            BASE: 'resultado-final',
            ALFABETIZACAO_ACELERACAO: 'resultado-final.alfabetizacao-aceleracao',
            FORMULA_VITORIA: 'resultado-final.formula-vitoria',
            ENSINO_REGULAR: 'resultado-final.ensino-regular',
            ENSINO_REGULAR_MATRICULA_MOVIMENTACAO: 'resultado-final.ensino-regular-matricula-movimentacao',
            ALFABETIZACAO_2ANO_1SERIE_REGULAR: 'resultado-final.alfabetizacao-2ano-1serie-regular',
            GESTAO_DA_ALFABETIZACAO: 'resultado-final.gestao-alfabetizaca',

            IDADE_CONCLUSAO_4SERIE_5ANO: 'resultado-final.idade-conclusao-4serie-5ano',
            IDADE_CONCLUSAO_8SERIE_9ANO: 'resultado-final.idade-conclusao-8serie-9ano',

            LETRAMENTO_EM_PROGRAMACAO: 'resultado-final.letramento-em-programacao',
            INDICADORES_SUCESSO_RESULTADOS: 'resultado-final.indicadores-sucesso-resultados'
        },
        BASE_MONITORAMENTO: {
            BASE: 'base-monitoramento',
            ACOMPANHAMENTO: 'base-monitoramento.acompanhamento'
        },
        PERFIL_TURMA: {
            BASE: 'perfil-turma',
            ACELERACAO: 'perfil-turma.aceleracao',
            ALFABETIZACAO: 'perfil-turma.alfabetizacao',
            FORMULA_VITORIA: 'perfil-turma.formula-vitoria',
            ENSINO_MEDIO_GESTAO_NOTA_10: 'perfil-turma.ensino-medio-gestao-nota-10',
            ENSINO_REGULAR: 'perfil-turma.ensino-regular',
            ALFABETIZACAO_ACELERACAO: 'perfil-turma.alfabetizacao-aceleracao',
            GESTAO_DA_ALFABETIZACAO: 'perfil-turma.gestao-da-alfabetizacao',
            SISTEMATICA_ACOMPANHAMENTO_DIALOGOS: 'perfil-turma.sistematica-acompanhamento-dialogos'
        },
        ACOMPANHAMENTO_MENSAL: {
            BASE: 'acompanhamento-mensal',
            LEITURA_ESCRITA_ORALIDADE_TEXTO: 'acompanhamento-mensal.leitura-escrita-oralidade-texto',
            LEITURA_ESCRITA_PRODUCAO_TEXTO: 'acompanhamento-mensal.leitura-escrita-producao-texto',
            ALFABETIZACAO_ACELERACAO_FORMULA_VITORIA: 'acompanhamento-mensal.alfabetizacao-aceleracao-formula-vitoria',
            ENSINO_REGULAR: 'acompanhamento-mensal.ensino-regular',
            MATEMATICA: 'acompanhamento-mensal.matematica',
            FLUXO_AULAS: 'acompanhamento-mensal.fluxo-aulas',
            ACOMPANHAMENTO_MUNICIPIO: 'acompanhamento-mensal.acompanhamento-municipio',
            FLUXO_DE_OFICINAS : "acompanhamento-mensal.fluxo-de-oficinas",
            LEITURA_ESTRATEGIA_COMPREENSAO_MAT_NV_1: 'acompanhamento-mensal.leitura-estrategia-compreensao-mat-nv-1',
            LEITURA_ESTRATEGIA_COMPREENSAO_MAT_NV_2: 'acompanhamento-mensal.leitura-estrategia-compreensao-mat-nv-2',
            FORMULA_VITORIA_LINGUA_PORTUGUESA_ACOMPANHAMENTO: 'acompanhamento-mensal.formula-vitoria-lingua-portuguesa-acompanhamento'
        },
        ACOMPANHAMENTO_ANUAL: {
            BASE: 'acompanhamento-anual',
            PERFIL_ATENDIMENTO_UNIDADES_ESCOLARES_CURSOS: 'acompanhamento-anual.perfil-atendimento-unidades-escolares-cursos',
            PERFIL_ATENDIMENTO_UNIDADES_ESCOLARES_TURMAS: 'acompanhamento-anual.perfil-atendimento-unidades-escolares-turmas'
        },
        ACOMPANHAMENTO_SEMESTRAL: {
            BASE: 'acompanhamento-semestral'
        },
        OUTROS : {
            BASE: 'outros',
            NUMERO_ALUNOS_POR_ANO_SITUACAO: 'outros.numero-alunos-por-ano-situacao',
            MEDIADOR_POR_TURMAS: 'outros.merdiador-por-turmas',
            PERFIL_ATENDIMENTO_MUNICIPIO: 'outros.perfil-atendimento-municipio',
            MUNICIPIOS_POR_PROGRAMA: 'outros.municipios-por-programa',
            ESCOLAS_EDUCACAO_INFANTIL_JOVENS_ADULTOS:'outros.escolas-educacao-infantil-jovens-adultos',
            LISTA_UNIDADES_ESCOLARES: 'outros.lista-unidades-escolares',
            LISTA_UNIDADES_ESCOLARES_PERFIL_ATENDIMENTO: 'outros.lista-unidades-escolares-perfil-atendimento',
            TOTAL_ESCOLAS_PROGRAMA: 'outros.total-escolas-por-programa',
            GENERO_SITUACAO_ANO_ANTERIOR: 'outros.genero-situacao-ano-anterior',
            MEDIA_DIAS_LETIVOS: "outros.media-dias-letivos",
            MATRICULA_ENSINO_FUNDAMENTAL_REDE: "outros.matricula-ensino-fundamental-rede",
            STATUS_INSERCAO_COLETAS: "outros.status-insercao-coletas",
            SISTEMATICA_ACOMPANHAMENTO_VISITAS_REGIONAL: "outros.sistematica-acompanhamento-visitas-regional",
            SISTEMATICA_MONITORAMENTO_REGIONAL: "outros.sistematica-monitoramento-regional",
            SISTEMATICA_ACOMPANHAMENTO_VISITAS_CENTRAL: "outros.sistematica-acompanhamento-visitas-central",
            SISTEMATICA_MONITORAMENTO_CENTRAL: "outros.sistematica-monitoramento,central"
        }
    });
}());
