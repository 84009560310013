(function(){
    'use strict';

    angular.module('app.etapa').service('etapaService', EtapaService);
    EtapaService.$inject = ['Etapa', 'STATUS_ENUM'];

    function EtapaService(Etapa, STATUS_ENUM) {

        this.filter = function(filter) {
            return Etapa.filter(filter).$promise;            
        };

        this.get = function(id) {
            return Etapa.get({ id: id }).$promise;
        };

        this.delete = function(etapa) {
            return Etapa.delete({id: etapa.id}).$promise;
        };

        this.ativos = function() {
            return Etapa.ativo();
        };

        this.save = function(etapa) {
            var entity = new Etapa(etapa);
            
            if (entity.id) {
                return entity.$update();
            } else {
                return entity.$save();
            }
        };

    }

}());
