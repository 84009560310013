/*
jslint
    devel: true, maxerr: 10
*/

/*
global
    required: true
*/

/*
 * Login Module Controller
 * @author iFactory Solutions Ltda.
 * @version 1.0.0
 * @since 1.0.0
 */
(function() {
    'use strict';

    angular.module('app.login').controller('LoginController', LoginController);
    LoginController.$inject = ['$location', '$state', 'LoginResource', 'Usuario', 'APP_STATE', 'SecurityManagerFactory', 'VALIDATION_PATTERN', 'toastService', 'HTTP_STATUS_CODE'];

    function LoginController($location, $state, loginResource, usuarioResource, APP_STATE, securityManager, VALIDATION_PATTERN, toastService, HTTP_STATUS_CODE) {
        var vm = this;

        vm.login = "";
        vm.emailPattern = VALIDATION_PATTERN.EMAIL.source;
        vm.credentials = { login: '', password: '' };
        vm.validations = { code: 0, hasError: false, mainMessage: '', messages: [] };
        vm.logoImages = ["contents/images/logo_IAS_1.jpg", "contents/images/logo_IAS_2.jpg", "contents/images/logo_IAS_3.jpg", "contents/images/logo_IAS_4.jpg"];

        vm.submitForm = function(isValid) {
            if (vm.loginForm.$invalid) return;

            // TODO Modificar essas chamadas encadeadas para utilizar $q.sequence (referência http://www.codeducky.org/q-serial/)
            loginResource.logIn({ login: vm.credentials.login, password: vm.credentials.password })
                .then(function(result) {
                    var user = {
                        name: vm.credentials.login,
                        email: vm.credentials.login,
                        credentials: {
                            accessToken: result.data.access_token,
                            refreshToken: result.data.refresh_token,
                            expiresIn: result.data.expires_in,
                            scope: result.data.scope,
                            tokenType: result.data.token_type
                        }
                    };
                    loginResource.getUserInfo(user).then(function(r) {
                            user.id = r.data.principal.id;
                            user.name = r.data.principal.person.shortName || r.data.principal.person.fullName || r.data.principal.login;
                            user.login = r.data.principal.login;
                            user.admin = r.data.principal.admin;
                            user.email = r.data.principal.person.email || r.data.principal.login || user.email;
                            user.identity = {
                                id: r.data.principal.person.id,
                                shortName: r.data.principal.person.shortName,
                                fullName: r.data.principal.person.fullName,
                                email: r.data.principal.person.email
                            };
                            user.privilegies = vm.extractPrivilegies(r.data.principal.authorities);
                            securityManager.storeUserCredentials(user);
                            $state.go(APP_STATE.HOME.BASE, { name: user.name, email: user.email });
                        },
                        function(e) {
                            vm.validations.code = e.status;
                            vm.validations.hasError = true;
                            vm.validations.mainMessage = 'Não foi possível recuperar as informações do usuário.'
                            vm.validations.messages.push(e.error_description);
                        });
                }, function(error) {
                    vm.validations.code = error.status;
                    vm.validations.hasError = true;
                    vm.validations.mainMessage = 'Não foi possível realizar o login.'
                    vm.validations.messages.push(error.error_description);
                });
        };

        vm.validate = function() {
            if (!vm.credentials.login) {
                vm.validations.hasError = true;
                vm.validations.messages.push('Informe o seu e-mail para efetuar o login.');
            }

            if (!vm.credentials.password) {
                vm.validations.hasError = true;
                vm.validations.messages.push('Informe a sua senha para efetuar o login.');
            }

            return vm.validations;
        };

        vm.extractPrivilegies = function(authorities) {
            return _.groupBy(
                _.map(authorities, function(element, key) { return element.authority; }),
                function(element, key) { return element.split(':')[0]; });
        };

        vm.forgottenPassword = function() {
            if (vm.modalForm.$valid) {
                usuarioResource.forgottenPassword({ login: vm.login }, 
                    function() {
                        vm.resetForgottenPasswordForm();
                        toastService.success('Parabéns, um e-mail com instruções para alteração da sua senha foi enviado com sucesso.', 'flash-modal');
                    },
                    function(error) {
                        if (error.status === HTTP_STATUS_CODE.INTERNAL_SERVER_ERROR) {
                            toastService.error('Lamentamos informar, mas aconteceu um erro inesperado. Tente novamente mais tarde.', 'flash-modal');    
                        } else {
                            toastService.error(error, 'flash-modal');
                        }
                    });
            }
        };

        vm.resetForgottenPasswordForm = function() {
            vm.login = "";
            vm.modalForm.$setPristine();
        };

        $(document).ready(function(){
            $("#p-container").css("opacity", "0");
            $("#p-message").css("opacity", "0");
    
            $("#a-button").mouseover(function(){
                $("#p-container").css("opacity", "1");
                $("#p-message").css("opacity", "1");
            });
            $("#a-button").mouseout(function(){
                $("#p-container").css("opacity", "0");
                $("#p-message").css("opacity", "0");
            });
        });
    }

})();