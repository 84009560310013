(function () {
	'use strict';
	angular.module('app.professor').controller('professorNewController', professorNewController);
	professorNewController.$inject = [
		'APP_STATE', '$state', 'Professor', '$scope',
		'DialogService', 'ufService', '$filter',
		'municipiosService', 'TABLE_FIELDS', 'scrollService',
		'APP_PROPERTY', 'toastService', 'validateService',
		'VALIDATION_PATTERN', 'APP_EVENT', 'professorService', 'atributoService', 'programasService',
		'PROFESSOR_MESSAGES'
	];

	function professorNewController(APP_STATE, $state, Professor, $scope,
		DialogService, ufService, $filter,
		municipiosService, TABLE_FIELDS, scrollService,
		APP_PROPERTY, toastService, validateService, VALIDATION_PATTERN, APP_EVENT,
		professorService, atributoService, programasService, PROFESSOR_MESSAGES) {

		var vm = this;
		var msgs = PROFESSOR_MESSAGES;
		var MODAL_IMPORT_ERROR_LIST = 'professor/partials/importacao-error-list.html';

		vm.professor = {};
		vm.ufs = ufService.ativos();
		vm.validEmail = {
			emailValid: 'me@example.com'
		};
		vm.mySwitch = false;
		vm.validation = {
			hasError: false,
			message: ''
		};
		vm.refreshSearch = false;
		vm.VALIDATION_PATTERN = VALIDATION_PATTERN;
		vm.programas = programasService.ativos();
		vm.atributosEntidadesIncluidosDTO = [];
		vm.atributosEntidadesAlteradosDTO = [];
		vm.atributosEntidadesExcluidosDTO = [];

		vm.modalAtributos = function () {
			DialogService.modal(
				'professor/partials/professor-form-atributo.html',
				function ($uibModalInstance) {
					var _vm = this;

					_vm.status = { open: false };
					_vm.programas = vm.programas;
					_vm.professoresAtributosClear = false;
					_vm.professorAtributosFields = TABLE_FIELDS.PROFESSOR_ATRIBUTOS;

					_vm.professoresAtributos = vm.atributosEntidadesDTO || [];


					_vm.professoresAtributosExcluidos = vm.atributosEntidadesExcluidosDTO;

					var _clearAll = function () {
						vm.atributosEntidadesDTO = _vm.professoresAtributos;
						_vm.professoresAtributosClear = true;
						_clearAtributosProfessoresForm();
					};

					var _clearAtributosProfessoresForm = function () {
						_vm.atributo = {};
						_vm.status.open = false;
						_vm.editMode = false;
					};

					_vm.populateAtributos = function (idPrograma) {
						atributoService.porTipoEntidadePrograma('PROFESSOR', idPrograma).then(function (atributos) {
							_vm.atributos = atributos;
							_vm.atributos.$promise.then(function (data) {
								if (is.existy(_vm.atributo.atributo)) {
									if (is.not.existy(_.findWhere(data, { id: _vm.atributo.atributo.id }))) {
										_vm.atributos.push(_vm.atributo.atributo.id);
										_vm.atributos = _.sortBy(_vm.atributos, 'nome');
									}
								}
							});
						});
					}

					var _verificarExisteAtributo = function (atributo) {
						var atributos;
						if (_vm.editMode) {
							atributos = $filter('filter')(_vm.professoresAtributos, function (value) {
								return (value.atributo.id == atributo.atributo.id) && (_vm.professoresAtributosSelecionados[0].atributo.id != atributo.atributo.id) &&
									(value.programa.id == atributo.programa.id) && (_vm.professoresAtributosSelecionados[0].programa.id != atributo.programa.id);
							});
						} else {
							atributos = $filter('filter')(_vm.professoresAtributos, function (value) {
								return (value.atributo.id == atributo.atributo.id && value.programa.id == atributo.programa.id);
							});
						}

						return (atributos.length > 0);
					};

					_vm.professoresAtributosChange = function (selectedItems) {
						_clearAtributosProfessoresForm();
					};

					_vm.save = function (atributo) {
						if (_vm.atributoForm.$valid) {
							if (!_verificarExisteAtributo(atributo)) {
								vm.professorForm.$setDirty();
								if (_vm.editMode) {
									_vm.professoresAtributosSelecionados[0].atributo = atributo.atributo;
									_vm.professoresAtributosSelecionados[0].dados = atributo.dados;
									_vm.professoresAtributosSelecionados[0].programa = atributo.programa;
									if (atributo.id)
										_vm.professoresAtributosSelecionados[0]._updated = true;
								} else {
									_vm.professoresAtributos.push(angular.copy(atributo));
									vm.professor.atributosEntidadesIncluidosDTO.push(angular.copy(atributo));
								}

								_clearAll();
							} else {
								toastService.error("O atributo já foi adicionado.", 'flash-modal');
								scrollService.scrollTo("esc-form-modal");
							}
						}
					};

					_vm.setAtributo = function (atributo, notModifyData) {

						if (atributo) {

							if (is.not.existy(notModifyData) || is.falsy(notModifyData)) {
								_vm.atributo.dados = professorService.removeOldValuesFromAtributo(_vm.atributo.dados);
							}

							atributo.tipo = atributo.tipoAtributo;

							if (undefined !== atributo.idOpcao) {
								atributo.opcao = atributo.idOpcao;
							}
							if (undefined !== atributo.idMedida) {
								atributo.medida = atributo.idMedida;
							}
						} else {
							_vm.atributo = {};
						}
					};

					_vm.showFormAssociar = function (item) {
						if (is.existy(item)) {
							_vm.editMode = true;
							_vm.atributo = angular.copy(item);
							_vm.programas.$promise.then(function (data) {
								if (is.not.existy(_.findWhere(data, { id: _vm.atributo.programa.id }))) {
									_vm.programas.push(_vm.atributo.programa);
									_vm.programas = _.sortBy(_vm.programas, 'nome');
								}
							});
							_vm.populateAtributos(_vm.atributo.programa.id);
							_vm.setAtributo(_vm.atributo.atributo, true);
						}

						_vm.status.open = true;
					};

					_vm.close = function () {
						vm.professor.atributosEntidadesIncluidosDTO = _vm.professoresAtributos
						_clearAll();
						$uibModalInstance.dismiss('cancel');
					};

					_vm.cancel = function () {
						_clearAll();
					};

					_clearAll();
				});
		};

		vm.modalUpload = function () {
			DialogService.modal('professor/partials/professor-form-upload.html',
				function ($uibModalInstance) {
					var _vm = this;
					_vm.status = { open: false };

					_vm.close = function () {
						$uibModalInstance.dismiss('cancel');
					};

					_vm.cancel = function () {
						_vm.close();
					};

					_vm.ok = function (file) {
						if (_vm.formUpload.$valid) {
							DialogService.confirm(msgs.warning.deleteResponses, function (response) {
								_vm.success = null;
								_vm.error = null;

								professorService.import(file)
									.then(function (response) {
										_vm.success = msgs.success.import;
										_vm.sheetBack = response.data;
									})
									.catch(function (error) {
										_vm.error = error;
									});
							},
								msgs.info.importTitle);
						}
					};

					_vm.showErrors = function (error) {
						DialogService.modal(MODAL_IMPORT_ERROR_LIST, function ($uibModalInstance) {
							this.formConstraints = error.formConstraints;
							this.messages = error.messages;

							this.cancel = function () {
								$uibModalInstance.dismiss('cancel');
							};
						});
					};

					_vm.onChangeFile = function () {
						_vm.success = null;
						_vm.error = null;
					};


				});
		};


		vm.validateDate = function () {
			if (validateService.date(vm.professorForm.dataNascimento)) {
				return true;
			} else {
				vm.professorForm.dataNascimento.$setValidity("dataNascimento", false);
			}
		};

		vm.getMunicipiosPorUf = function (ufId) {
			if (ufId > 0) {
				vm.municipios = municipiosService.porUf(ufId);
				vm.professorForm.municipio.$setValidity("required", false);
			} else {
				vm.municipios = [];
				vm.professorForm.municipio.$setValidity("required", true);
			}
		};

		vm.checkMuncipios = function (municipioId) {
			if (municipioId > 0) {
				vm.professorForm.municipio.$setValidity("required", true);
			}
		}

		vm.criarUsuario = function () {
			if (vm.professor && vm.professor.pessoa && vm.professor.pessoa.id) {
				professorService.criarUsuario(vm.professor.pessoa.id).then(function (result) {
					vm.existeUsuario = { exist: true };
					toastService.success("Usuário criado para o professor com sucesso.");
					vm.goSearchPage();
				}, function (response) {
					toastService.error(response.data.message);
				});
			}
		}

		if (typeof ($state.params.id) != 'undefined' && $state.params.id != null) {
			vm.mySwitch = true;
			vm.goEditPage = function () {
				vm.mySwitch = false;
				vm.hasError = false;
				vm.disableEmail = false;
				vm.email = vm.professor.pessoa.email;
			};
		}

		if ($state.is(APP_STATE.PROFESSOR.EDIT)) {

			if ($state.params.id) {

				Professor.get({ id: $state.params.id }).$promise.then(function (response) {
					vm.professor = response;
					vm.professor.atributosEntidadesIncluidosDTO = [];
					vm.professor.atributosEntidadesAlteradosDTO = [];
					vm.professor.atributosEntidadesExcluidosDTO = [];

					professorService.existeUsuario(vm.professor.pessoa.id).then(function (result) {
						vm.existeUsuario = result;
					});

					if (is.existy(response.atributosEntidadesDTO) && is.not.empty(response.atributosEntidadesDTO)) {
						vm.atributosEntidadesDTO = response.atributosEntidadesDTO;
					} else {
						vm.atributosEntidadesDTO = [];
					}

					_normalizeCpf();

					if (vm.professor.pessoa.dataNascimento != null) {
						vm.professor.pessoa.dataNascimento = moment(vm.professor.pessoa.dataNascimento, 'YYYY-MM-DD').format('DD/MM/YYYY')
					}

					if (vm.professor.pessoa.municipio) {
						var pessoa = vm.professor.pessoa;
						pessoa.uf = vm.professor.pessoa.municipio.uf;
						vm.professor.pessoa = pessoa;
						vm.getMunicipiosPorUf(vm.professor.pessoa.uf.id);
					}

					setTimeout(function () {
						vm.professorForm.$setPristine();
					}, 1);
				});
			}
		} else {
			vm.professor = {};
			vm.professor.atributosEntidadesDTO = [];
			vm.professor.atributosEntidadesIncluidosDTO = [];
			vm.professor.atributosEntidadesAlteradosDTO = [];
			vm.professor.atributosEntidadesExcluidosDTO = [];
		}


		vm.goNewPage = function () {
			$state.go(APP_STATE.PROFESSOR.NEW);
		};

		vm.goSearchPage = function () {
			if (vm.refreshSearch) {
				$scope.$emit(APP_EVENT.PROFESSOR.REFRESH);
			}

			$state.go(APP_STATE.PROFESSOR.SEARCH);
		};

		vm.stayPage = function () {
			$state.reload();
		};

		vm.edit = function (professor, i) {
			vm.update = angular.copy(professor);
			index = i;
		};

		var _extractNomeCurto = function (nome) {
			var nomeCurto = "";

			if (is.existy(nome) && is.not.empty(nome)) {
				var normalizedNome = nome.replace(/\s{2,}/g, ' ');
				var nomes = normalizedNome.split(" ");

				if (nomes.length > 1) {
					nomeCurto = nomes[0] + " " + nomes[nomes.length - 1];
				} else {
					nomeCurto = nomes[0]
				}
			}

			return nomeCurto;
		};

		var _showErrorMessage = function (error) {
			var msg;
			var errorData = error.data;
			var description = errorData.description;

			if (is.existy(description) && is.not.empty(description)) {
				msg = errorData.message + " " + description;
			} else {
				msg = errorData.message;
			}

			toastService.error(msg);
		};

		var _normalizeCpf = function () {
			if (is.existy(vm.professor.pessoa.cpf)) {
				var cpf = vm.professor.pessoa.cpf.replace(/\s/g, '');

				if (is.empty(cpf)) {
					vm.professor.pessoa.cpf = null;
				}
			}
		};

		vm.save = function () {
			var professor = angular.copy(vm.professor);

			if (professor.pessoa.dataNascimento) {
				var dataNascimento = moment(professor.pessoa.dataNascimento, 'DD/MM/YYYY').format('YYYY-MM-DD');
				professor.pessoa.dataNascimento = dataNascimento;
			}

			professor.login = professor.pessoa.email;
			if (!professor.id) {
				professor.senha = professor.pessoa.email;
			}
			professor.pessoa.nomeCurto = _extractNomeCurto(professor.pessoa.nomeCompleto);

			_normalizeCpf();

			professor.atributosEntidadesExcluidosDTO = vm.atributosEntidadesExcluidosDTO;
			professor.atributosEntidadesDTO = vm.atributosEntidadesDTO;
			professorService._normalizeAtributosToServer(professor);

			if (typeof ($state.params.id) != 'undefined') {
				Professor.update(professor, function (response) {
					vm.refreshSearch = true;
					toastService.success("Professor foi salvo com sucesso.");
					vm.goSearchPage();
				}, function (error) {
					vm.refreshSearch = false;
					_showErrorMessage(error);
				});
			} else {
				// salvar
				Professor.save(professor, function (data) {
					vm.refreshSearch = true;
					vm.validation.hasOK = true;
					toastService.success("Professor foi salvo com sucesso.");
					vm.goSearchPage();
				}, function (error) {
					vm.refreshSearch = false;
					vm.validation.hasError = true;
					_showErrorMessage(error);
				});
			}

		};
	}
}());
