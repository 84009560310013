(function(){
    'use strict';

    angular.module('app.formExemplo').service('formExemploService', FormExemploService);
    FormExemploService.$inject = ['$filter', 'FormExemplo'];

    function FormExemploService($filter,FormExemplo) {

        this.filter = function() {
            return FormExemplo.filter().$promise;            
        };

       
        this.edit = function(filter) {
            return FormExemplo.edit(filter).$promise; 
        };

    }

}());
