(function() {
    'use strict';

    angular.module ('app.core').directive ('iasColetaRespostaError', IasColetaRespostaError);
    IasColetaRespostaError.$inject = [];

    function IasColetaRespostaError() {
        return {
            scope: {
                constraints: '=?'
            },
            restrict: "E",
            templateUrl: "core/directives/error/partials/ias-coleta-resposta-error.html",
            link: function(scope, element, attr) {
                scope.constraints = scope.constraints || [];
            }
        };
    }

} ());