(function() {
    'use strict';

    angular.module('app.core').directive('iasMinDateValue', IasMinDateValue);
    IasMinDateValue.$inject = ['dateUtilService'];

    function IasMinDateValue(dateUtilService) {
        return {
            require: 'ngModel',
            restrict: 'A',
            link: function(scope, element, attr, controller) {
                var minDateAttr = attr.iasMinDateValue;
                var iasMinDateType = attr.iasMinDateType || "date";
                var minValue;

                var _setMinValue = function(minDateAttr) {
                    switch (iasMinDateType) {
                        case "datetime":
                            minValue = dateUtilService.parseToDateTime(minDateAttr);
                            break;
                        case "time":
                            if (is.date(minDateAttr)) {
                                minValue = dateUtilService.parseToDateTime(minDateAttr);
                            } else {
                                minValue = dateUtilService.parseTimeToDate(minDateAttr);
                            }
                            
                            break;
                        default:
                            minValue = dateUtilService.parseToDate(minDateAttr);

                    }
                };

                _setMinValue(minDateAttr);

                attr.$observe('iasMinDateValue', function(newMinValue) {
                    if (minDateAttr != newMinValue) {
                        _setMinValue(newMinValue);
                    }
                });

                var _validate = function(modelValue, viewValue) {
                    var value = modelValue || viewValue;

                    if (value && minValue) {
                        return value >= minValue;
                    } else {
                        return true;
                    }
                };

                if (minValue) {
                    if (iasMinDateType == "date") {
                        controller.$validators.iasMinDateValue = _validate;
                    } else if (iasMinDateType == "time") {
                        controller.$validators.iasMinTimeValue = _validate;
                    } else {
                        controller.$validators.iasMinDateTimeValue = _validate;
                    }
                }
            }
        };
    }

})();