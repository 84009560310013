(function() {
    'use strict';

    angular.module('app.core').directive("iasErrorPage", IasErrorPage);
    IasErrorPage.$inject = ['$window'];

    function IasErrorPage($window) {
        return {
            scope: {
                error: '=',
                hideBackButton: '=?'
            },
            restrict: "E",
            templateUrl: "core/directives/error/partials/ias-error-page.html",
            link: function(scope) {
                scope.hideBackButton = scope.hideBackButton || false;

                scope.back = function() {
                    $window.history.back();
                };
            }
        };
    }

})();