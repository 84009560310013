(function() {
    'use strict';

    angular.module('app.core').directive("iasValidationFieldMedidaError", IasValidationFieldMedidaError);
    IasValidationFieldMedidaError.$inject = [];

    function IasValidationFieldMedidaError() {
        return {
            scope: {
                firstField: '=',
                secondField: '=?',
                form: '='
            },
            restrict: "E",
            templateUrl: "core/directives/error/partials/ias-validation-field-medida-error.html"
        };
    }

})();