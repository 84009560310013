/*
jslint
    devel: true, maxerr: 10
*/

/*
global
    required: true
*/

/*
 * Relatórios Module
 * @controller Class Profile Report Controller
 * @author iFactory Solutions Ltda.
 * @version 1.0.0
 * @since 1.0.0
 */
(function() {
    'use strict';

    angular.module('app.relatorios').controller('RelatorioMediaDiasLetivosController', RelatorioMediaDiasLetivosController);
    RelatorioMediaDiasLetivosController.$inject = ['$state', 'APP_STATE', 'RelatoriosService', 'toastService', 'programasService'];

    function RelatorioMediaDiasLetivosController($state, APP_STATE, reportService, toastService, programasService) {
        var vm = this;

        vm._clearReportResult = function() {
            vm.result.reportDataCount = 0;
            vm.result.reportFields = [];
            vm.result.reportData = [];
            vm.result.calculations = {};
        };

        var preencherCidadesDoEstado = function () {
            if (is.existy(vm.filter.state)) {
                vm.model.cities = reportService.loadCitiesOfState(vm.filter.state.id);                
                vm.model.stateRegionals = reportService.loadStateRegionalsOfState(vm.filter.state.id);
            } else {
                vm.model.stateRegionals = [];
                vm.model.cities = [];
            }
        }

        vm.changeState = function() {
            preencherCidadesDoEstado();
        };

        vm.changeStateRegional = function() {
            if (is.existy(vm.filter.stateRegional)) {
                vm.model.cities = reportService.loadCitiesOfStateRegional(vm.filter.stateRegional.id);
            } else {
                preencherCidadesDoEstado();
            }
        };

        vm.generateReport = function() {
            vm.view.searchWasPerformed = true;
            vm.result.calculations = [];
            vm.result.report = { id: "outros.media-dias-letivos", title: "Média de dias letivos previstos" };
            reportService.generateReportResult(vm.filter, vm.result);
        };

        vm.formatColumn = function(item, field) {
            return reportService.formatColumn(item, field, vm.result);
        };

        vm.formatSummaryColumn = function(field) {
            return reportService.formatSummaryColumn(vm.result.calculations, field, vm.result);
        };

        vm.performCalculation = function(field, item) {
            var oldValue = vm.result.calculations[field.name];
            vm.result.calculations[field.name] = reportService.performatCalculation(item, field, oldValue, vm.result);
        };

        vm.showResultReport = function(reportResultInfo) {
            var result = vm.result.reportDataCount > 0 && vm.view.searchWasPerformed;
            if (!result && is.existy(reportResultInfo)) {
                result = result && vm.result.report.id == reportResultInfo.reportId &&
                    ((is.existy(reportResultInfo.model) && is.existy(vm.filter.model) && reportResultInfo.model.id == vm.filter.model.id) || is.not.existy(reportResultInfo.model) || is.not.existy(vm.filter.model));
            }
            return result;
        };

        vm.resetFilter = function() {
            vm.result.reportDataCount = 0;
            vm.showResultReport();
        }

        vm.showFooter = function(fieldInfo) {
            var result = is.not.existy(fieldInfo.showFooter) || fieldInfo.showFooter;
            return result;
        };

        (function() {
            vm.model = {
                cities: [],
                detailings: [{
                        id: "3",
                        name: "Regional"
                    },
                    {
                        id: "1",
                        name: "Estado"
                    },
                    {
                        id: "2",
                        name: "Município"
                    }
                ],
                programs: [],
                schoolAdministrativeDependencies: [],
                schoolYears: [],                
                states: [],
                stateRegionals: []
            };

            vm.filter = {
                city: null,
                detailing: null,
                program: null,
                schoolAdministrativeDependency: null,
                schoolYear: null,
                state: null,
                stateRegional: null
            };
            vm.view = {
                available: true,
                searchWasPerformed: false,
                currentPage: null,
                allSchoolAdministrativeDependency: {
                    id: '0',
                    nome: 'Todas'
                },
                allSchoolLocalization: {
                    id: '0',
                    nome: 'Todas'
                },
                allSchoolType: {
                    id: '0',
                    name: 'Todos'
                }
            };
            vm.result = {
                reportId: null,
                reportDataCount: 0,
                reportFields: [],
                reportData: [],
                calculations: {}
            };

            vm.showdetails = function(id) {
                //var obj = vm.model.detailings.filter(function(detailing){ return detailing.id === id });
                //return obj;
            };
            vm.model.states = reportService.loadStates();
            vm.model.schoolYears = reportService.loadSchoolYears();
            vm.model.schoolAdministrativeDependencies = reportService.loadAdministrativeDependencies();

            reportService.loadPrograms();

            // Por padrão, o detalhamento do relatório é feito por município.
            for (var i = 0; i < vm.model.detailings.length; i++) {
                var det = vm.model.detailings[i];
                if (det.id == 2) {
                    vm.filter.detailing = det;
                    break;
                }
            }
            vm.filter.schoolAdministrativeDependency = vm.view.allSchoolAdministrativeDependency;

            programasService.ativosRelatorio().then(function(programs){   
                angular.forEach(programs, function(value){  
                    vm.model.programs.push({
                        id: value.id,
                        nome: value.nome
                    });
                });
            });

        })();
    }
}());