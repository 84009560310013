(function() {
    'use strict';

    angular.module('app.coleta').controller('ColetaFormController', ColetaFormController);
    ColetaFormController.$inject = ['APP_STATE', 'APP_EVENT', 'TABLE_FIELDS', '$scope', '$state', 'coletaService',
        'toastService', 'formularioService', 'programasService', 'dialogService', 'VALIDATION_PATTERN', 'DialogService',
        '$rootScope', 'COLETA_ANONIMA_DEFAULT_FIELDS','RelatoriosService','turmasService','rubricasService','professorService','disciplinasService','ufService',
        'municipiosService','escolasService','COLETA_MESSAGES','coletaRespostaService'
    ];

    function ColetaFormController(APP_STATE, APP_EVENT, TABLE_FIELDS, $scope, $state, coletaService,
        toastService, formularioService, programasService, dialogService, VALIDATION_PATTERN, DialogService, $rootScope,
        COLETA_ANONIMA_DEFAULT_FIELDS,reportService,turmasService,rubricasService,professorService,disciplinasService,ufService,
        municipiosService,escolasService, COLETA_MESSAGES,coletaRespostaService) {

        var vm = this;
        var _oldTipoAcesso = null;
        var _formularios = [];
        var _formulariosAnonimos = [];
        var _granularidadesNaoAnonima = [];
        var MODAL_TEMPLATE = 'coleta/partials/coleta-form-preview.html';

        var _isColetaAnonima = function() {
            return vm.coleta.tipoAcesso && (vm.coleta.tipoAcesso.key === COLETA_ANONIMA_DEFAULT_FIELDS.GRANULARIDADE.key);
        };

        var _init = function() {
            vm.btnsHovers = false;
            vm.salvarContinuar = false;
            vm.MAX_DAYS = 255;
            vm.editMode = false;
            vm.editPage = false;
            vm.agendaInicio = null;
            vm.agendaFim = null;
            vm.refreshSearch = false;
            vm.altInputFormats = ['yyyy-MM-dd'];
            vm.dateFormat = 'dd/MM/yyyy';
            vm.MOTIVO_INATIVACAO_MAX_CHARS = 200;
            vm.VALIDATION_PATTERN = VALIDATION_PATTERN;
            vm.coletaAnonima = false;
            vm.itemColeta = [];
            vm.itemColeta.formulario = [];
            vm.configuracaoProgramas = [];

            vm.dateOptions = {
                dateDisabled: _disabledWeekend,
                formatYear: 'yy',
                maxDate: new Date(2100, 5, 22),
                minDate: new Date(),
                startingDay: 1
            };

            vm.tableFields = TABLE_FIELDS.COLETA;
            vm.modalTemplateUrl = "coleta/partials/coleta-form-item-modal.html";
            vm.modalDependencies = {};
            _populateDependencies();
            //Aqui carrega os campos para edit
            if ($state.is(APP_STATE.COLETA.EDIT))  {
                vm.editPage = true;

                coletaService.get($state.params.id)
                    .then(function(coleta) {
                        vm.filtrosObj = {};
                        vm.profeObj ={};
                        vm.coleta = coleta;
                        vm.coletaAnonima = _isColetaAnonima();
                        vm.habilitaBotaoForm();

                        if(vm.coleta.programa.id == 33 || vm.possuiFiltroEscola(vm.coleta.programa.id)){
                        coletaService.findColetaFiltrosByIdColeta(vm.coleta.id).$promise.then(function(filtersResult){
                            vm.filtrosObj = filtersResult;
                            vm.filter = {};
                            //reportService.loadStates().$promise.then(function(dataState){
                            var idPrograma = vm.coleta.programa.id;
                            ufService.withPermissionFilter({idPrograma:idPrograma}).then(function(response){
                                vm.states = response;

                                angular.forEach(vm.states, function(valueState) {
                                    if (vm.filtrosObj.idUF == valueState.id) {
                                        try {
                                            vm.filter.state = valueState;

                                            //reportService.loadCitiesOfState(vm.filter.state.id).$promise.then(function(dataCity){
                                            var idEstado = vm.filter.state.id;
                                            municipiosService.withPermissionFilter({idUf: idEstado, idPrograma:idPrograma}).then(function(response){
                                                vm.cities = response;
                                                angular.forEach(vm.cities, function(valueCity) {
                                                    if(vm.filtrosObj.idMunicipio == valueCity.id){
                                                        try {
                                                            vm.filter.city = valueCity;
                                                            //reportService.loadSchoolsOfCity(vm.filter.city.id).$promise.then(function(dataSchool){
                                                            var idMunicipio =  vm.filter.city.id;

                                                            var programa = [];
                                                            programa.push(vm.coleta.programa.id);
                                                            escolasService.searchByWithPermission({situacao:"A",idMunicipio:idMunicipio, programas:programa}).then(function(escolas) {
                                                                vm.schools = escolas
                                                                angular.forEach(vm.schools, function(valueSchool) {
                                                                    if(vm.filtrosObj.idEscola == valueSchool.id){
                                                                        try {
                                                                            vm.filter.school = valueSchool;
                                                                            //turmasService.porEscola(vm.filter.school.id).$promise.then(function(dataTurmas){
                                                                            var idEscola = vm.filter.school.id;
                                                                            turmasService.withPermissionFilter({idEscola: idEscola, idPrograma: idPrograma}).then(function(turmas) {
                                                                                vm.turmas = turmas;
                                                                                angular.forEach(vm.turmas, function(valueTurma) {
                                                                                    if(vm.filtrosObj.idTurma == valueTurma.id){
                                                                                        try {
                                                                                            vm.filter.turma = valueTurma;

                                                                                            vm.rubricas = rubricasService.findRubricasByTurma(vm.filter.turma.id);
                                                                                             


                                                                                            //rubricasService.findRubricasByTurma(vm.filter.turma.id);
                                                                                            
                                                                                            vm.disciplinas = disciplinasService.findByTurma(vm.filter.turma.id);
                                                                                            
                                                                                            try {
                                                                                                coletaService.findColetaProfessoresEscolhidos(vm.coleta.id).$promise.then(function(dataProfessores){
                                                                                                    
                                                                                                        try {
                                                                                                            vm.profeObj = dataProfessores;
                                                                                                       
                                                                                                            vm.filter.rubricasEscolhidas = vm.filtrosObj.rubricas;
                                                                                                            vm.filter.professoresEscolhidos = dataProfessores.professores;
                                                                                                            vm.filter.disciplinasEscolhidos = dataProfessores.disciplinas;


                                                                                                            professorService.ProfessorByTurmaAndDisciplinaId(vm.filter.turma.id, vm.filter.disciplinasEscolhidos)
                                                                                                            .then(function(data) {
                                                                                                                vm.professores  = data;
                                                                                                                
                                                                                                            },function(response) {
                                                                                                                toastService.error({
                                                                                                                    status: response.status,
                                                                                                                    message: response.data.message
                                                                                                                });
                                                                                                            })
                                                                                                            
                                                                                                            
                                                                                                           // vm.changeItems();
                                                                                                        
                                                                                                            
                                                                                                        } catch (error) {
                                                                                                            console.log(error);
                                                                                                        }
                                                                                                        
                                                                                                });
                                                                                                 
                                                                                            } catch (error) {
                                                                                                console.log(error);
                                                                                            }

                                                                                        } catch (error) {
                                                                                            console.log(error);
                                                                                        }
                                                                                    }

                                                                                });

                                                                            });
                                                                        } catch (error) {
                                                                            console.log(error);
                                                                        }
                                                                    }
                                                                });

                                                            });

                                                        } catch (error) {
                                                            console.log(error);
                                                        }
                                                    }
                                                    
                                                });

                                            });
                                        } catch (error) {
                                            console.log(error);
                                        }
                                    }	
                                });
                              
                            });
                           
                        });
                    }

                        _refreshGranularidadeAndForms();

                        if (is.not.existy(_.findWhere(vm.programas, {id: vm.coleta.programa.id}))){
                            vm.programas.push(vm.coleta.programa);
                            vm.programas = _.sortBy(vm.programas, 'nome');
                        }

                        _setOrCreateLists();
                        if (is.truthy($state.params.editMode)) {
                                vm.enableEditMode();
                        }
                    }, function(response) {
                        toastService.error({
                            status: response.status,
                            message: response.data.message
                        });
                    });
;
                    
            } else {
                if ($state.params.coleta) {
                    vm.filter = $state.params.filter;
                    vm.states = $state.params.states;
                    vm.cities = $state.params.cities;
                    vm.schools = $state.params.schools;
                    vm.turmas = $state.params.turmas;
                    vm.rubricas = $state.params.rubricas;
                    vm.disciplinas = $state.params.disciplinas;
                    vm.professores = $state.params.professores;
                    vm.coleta = $state.params.coleta;
                    vm.coletaAnonima = _isColetaAnonima();

                

                    _refreshGranularidadeAndForms();
                } else {
                    vm.coleta = {
                        controlaPrazo: false,
                        controlaAlteracao: false,
                        itens: [],
                        itensCriados: [],
                        itensExcluidos: [],
                        agendas: [],
                        situacao: {
                            key: 'N'
                        }
                    };
                }

                vm.enableEditMode();
            }
        };


        var _isAnonymousForm = function(form) {
            if (!form) {
                return false;
            }

            return form.granularidade.key === COLETA_ANONIMA_DEFAULT_FIELDS.GRANULARIDADE.key;
        };

        var _populateDependencies = function() {
            coletaService.findTipos().then(function(tipos) {
                vm.tipos = tipos;
                if(vm.coleta.programa.id == 33){
                    vm.coleta.tipoColeta = vm.tipos[0];
                }

                
            });

            coletaService.findStatus().then(function(situacoes) {
                vm.situacoes = situacoes;
            });

            coletaService.findAbrangencias().then(function(abrangencias) {
                vm.abrangencias = abrangencias;

                if(vm.coleta.programa.id == 33 || vm.possuiFiltroEscola(vm.coleta.programa.id)) {
                    vm.coleta.tipoAbrangencia = vm.abrangencias[1];
                }

            });

            coletaService.findGranularidades().then(function(granularidades) {
                _granularidadesNaoAnonima = _.filter(granularidades, function(grao) {
                    return grao.key !== COLETA_ANONIMA_DEFAULT_FIELDS.GRANULARIDADE.key;
                });

                if(vm.coleta.programa.id == 33){
                    vm.coleta.granularidade = _granularidadesNaoAnonima[0];
                }

                if (vm.possuiFiltroEscola(vm.coleta.programa.id)) {
                    vm.coleta.granularidade = _granularidadesNaoAnonima[3];
                }

                _refreshGranularidade();
            });

            coletaService.findPeriodicidades().then(function(periodicidades) {
                vm.periodicidades = periodicidades;
            });

            coletaService.findAcessos().then(function(acessos) {
                vm.acessos = acessos;

                if(vm.coleta.programa.id == 33 || vm.possuiFiltroEscola(vm.coleta.programa.id)){
                    vm.coleta.tipoAcesso = vm.acessos[0];
                }
            });

            formularioService.findAtivos("id,nome,descricao,situacao,tipo")
                .then(function(formularios) {
                    _formularios = [];
                    _formulariosAnonimos = [];

                    _.each(formularios, function(form) {
                        if (_isAnonymousForm(form)) {
                            _formulariosAnonimos.push(form); 
                        } else {
                            _formularios.push(form);
                        }
                    });

                    _refreshFormularios();
                });

            programasService.ativos().$promise.then(function(programas) {
                vm.programas = programas;
            });

            programasService.configuracaoProgramas().$promise.then(function(configuracao){
                vm.configuracaoProgramas = configuracao;
            });

            vm.possuiFiltroEscola = function(idPrograma) {
                var programa = vm.configuracaoProgramas.find((configuracao) => configuracao.idPrograma === idPrograma);
                return programa && programa.filtroEscola === 'S';
            }

            vm.modalDependencies.consideraMovimentacaoTurma = function(itemColeta) {
                if (itemColeta.formulario && itemColeta.formulario.periodicidade) {
                    if (itemColeta.formulario.periodicidade.key == 'M' && itemColeta.formulario.granularidade.key == 'T') {
                        itemColeta.exibeMovimentacaoMensalTurma = itemColeta.exibeMovimentacaoMensalTurma == undefined ? false : itemColeta.exibeMovimentacaoMensalTurma;
                        return true;
                    }
                }
                
                return false;
            }

            vm.modalDependencies.goToFormularioCadastro = function(){
                DialogService.confirm("Você tem certeza que deseja ser redirecionado para a tela de cadastro de formulário?", function(){
                    $state.go(APP_STATE.FORMULARIO.NEW);
                });
            }

            vm.modalDependencies.save = function(modalForm){
                vm.registerForm.$setDirty();
                this.ok(modalForm);
            }

        };

            $scope.$watch('vm.filter.rubricasEscolhidas', function(newValue, oldValue) {
                try {
                    vm.changeItems();
                } catch (error) {
                    console.log('rubricas escolhidas ruim!!!');
                }
            });
            $scope.$watch('vm.filter.disciplinasEscolhidos', function(newValue, oldValue) {
                try {
                    vm.changeItems();
                } catch (error) {
                    console.log('disciplinas escolhidas ruim!!!');
                }
                
            });
            $scope.$watch('vm.filter.professoresEscolhidos', function(newValue, oldValue) {
                try {
                    vm.changeItems();
                } catch (error) {
                    console.log('professores escolhidas!!!');
                }
     
            });

        var _saveOrUpdateItensMessages = function(success, response) {
            if (success) {
                vm.refreshSearch = true;
                vm.registerForm.$setPristine();
                toastService.success("Operação realizada com sucesso.");
            } else {
                vm.refreshSearch = false;

                toastService.error({
                    status: response.status,
                    message: response.data.message
                });
            }
        };

        var _disabledWeekend = function(data) {
            var date = data.date,
                mode = data.mode;

            return mode === 'day' && (date.getDay() === 0 || date.getDay() === 6);
        };

        var _deleteAllAgendas = function() {
            _clearAgendaErrors();

            if (vm.coleta.agendas) {
                vm.coleta.agendasExcluidas = _.filter(vm.coleta.agendas, function(agenda) {
                    return agenda.id;
                });
            }

            vm.coleta.agendas = [];
        };

        var _normalizeDate = function(date) {
            return moment(date, 'YYYY-MM-DD').toDate();
        }

        var _getAgendaOverlap = function(start, end) {
            var startMoment = parseInt(moment(start, 'DD/MM/YYYY').format('YYYYMMDD'));
            var endMoment = parseInt(moment(end, 'DD/MM/YYYY').format('YYYYMMDD'));
            var i, agenda, agendaInicio, agendaFim;
            var rangeSize = (vm.coleta.agendas) ? vm.coleta.agendas.length : 0;
            for (i = 0; i < rangeSize; i++) {
                agenda = vm.coleta.agendas[i];
                agendaInicio = parseInt(moment(agenda.dataInicio).format('YYYYMMDD'));
                agendaFim = parseInt(moment(agenda.dataFim).format('YYYYMMDD'));
                if (startMoment <= agendaFim && endMoment >= agendaInicio) {    
                    return agenda;
                }
            }

            return null;
        };

        var _clearAgendaErrors = function() {
            if (vm.registerForm.agendaInicio) {
                vm.registerForm.agendaInicio.$setValidity("dateOverlap", true);
                vm.registerForm.agendaInicio.$setValidity("required", true);
                vm.registerForm.agendaInicio.$setValidity("invalidDate", true);
                vm.registerForm.agendaInicio.$setValidity("dateLessNow", true);
            }

            if (vm.registerForm.agendaFim) {
                vm.registerForm.agendaFim.$setValidity("dateOverlap", true);
                vm.registerForm.agendaFim.$setValidity("dateLessThan", true);
                vm.registerForm.agendaFim.$setValidity("required", true);
                vm.registerForm.agendaFim.$setValidity("invalidDate", true);
            }

            vm.registerForm.$setValidity("noAgenda", true);
            vm.agendaHasError = false;
        };

        var _setOrCreateLists = function() {
            vm.coleta.itens = vm.coleta.itens || [];
            vm.coleta.itensCriados = vm.coleta.itensCriados || [];
            vm.coleta.itensExcluidos = vm.coleta.itensExcluidos || [];
            vm.coleta.agendas = vm.coleta.agendas || [];
        };

        var _agendaWatcher = $scope.$watchGroup(["vm.agendaInicio", "vm.agendaFim"],
            function(newValue, oldValue) {
                if (newValue != oldValue) {
                    _clearAgendaErrors();
                }
            });

        vm.goBack = function() {
            $state.go(APP_STATE.COLETA.SEARCH);
        };

        vm.enableEditMode = function() {
            vm.editMode = true;
        };

        vm.clear = function() {
            vm.agendaInicio = null;
            vm.agendaFim = null;
        };

        vm.deleteDiasPrazo = function() {
            delete vm.coleta.diasPrazo;
        };

        vm.deleteDiasAlteracao = function() {
            delete vm.coleta.diasAlteracao;
        };

        vm.changeSituacao = function() {
            if (vm.coleta.situacao && vm.coleta.situacao != 'I') {
                delete vm.coleta.motivoInativacao;
            }
        };

        vm.changeTipo = function() {
            if (vm.coleta.tipoColeta) {
                if (vm.coleta.tipoColeta.key != 'P') {
                    delete vm.coleta.periodicidade;
                }

                if (vm.coleta.tipoColeta.key != 'A') {
                    _deleteAllAgendas();
                }

            } else {
                delete vm.coleta.periodicidade;
                _deleteAllAgendas();
            }
        };

        vm.checkRangeAgendas = function(dataInicio) {
            var dataColetaInicio = parseInt(moment(dataInicio, 'DD/MM/YYYY').format('YYYYMMDD'));
            var now = parseInt(moment(new Date(), 'DD/MM/YYYY').format('YYYYMMDD'));
            if (dataColetaInicio <= now) {
                return false;
            } else {
                return true;
            }
        };

        vm.addAgenda = function() {
            vm.agendaHasError = false;
            
            if (vm.agendaInicio && vm.agendaFim) {

                var dataColetaInicio = moment(vm.agendaInicio, 'DD/MM/YYYY');
                var dataColetaFim = moment(vm.agendaFim, 'DD/MM/YYYY');

                if (dataColetaInicio < dataColetaFim) {
                    var overlap = _getAgendaOverlap(vm.agendaInicio, vm.agendaFim);
                }

                if (!dataColetaInicio.isValid() || !dataColetaFim.isValid()) {
                    vm.registerForm.agendaInicio.$setValidity("invalidDate", dataColetaInicio.isValid());
                    vm.registerForm.agendaFim.$setValidity("invalidDate", dataColetaFim.isValid());
                    vm.agendaHasError = true;
                } else {
                    if (!vm.checkRangeAgendas(vm.agendaInicio)) {
                        vm.registerForm.agendaInicio.$setValidity("dateLessNow", false);
                        vm.agendaHasError = true;
                    } else {

                        if (dataColetaInicio < dataColetaFim) {
                            var overlap = _getAgendaOverlap(vm.agendaInicio, vm.agendaFim);

                            if (overlap) {
                                vm.registerForm.agendaInicio.$setValidity("dateOverlap", false);
                                vm.registerForm.agendaFim.$setValidity("dateOverlap", false);
                                vm.agendaHasError = true;
                            } else {
                                vm.coleta.agendas.push({
                                    dataInicio: dataColetaInicio.format('YYYY-MM-DD'),
                                    dataFim: dataColetaFim.format('YYYY-MM-DD'),
                                });

                                vm.agendaInicio = null;
                                vm.agendaFim = null;
                            }
                        } else {
                            vm.registerForm.agendaFim.$setValidity("dateLessThan", false);
                            vm.agendaHasError = true;
                        }
                    }
                }
            } else {
                vm.agendaHasError = true;
                vm.registerForm.agendaInicio.$setValidity("required", false);
                vm.registerForm.agendaFim.$setValidity("required", false);
            }
        };

        vm.deleteAgenda = function(index) {
            var agendas = [];
            angular.copy(vm.coleta.agendas, agendas);
            var deleted = agendas.splice(index, 1);
            if (vm.checkRangeAgendas(deleted[0].dataInicio)) {
                if (deleted.length && deleted[0].id) {
                    if (!vm.coleta.agendasExcluidas) {
                        vm.coleta.agendasExcluidas = [];
                    }
                    vm.coleta.agendasExcluidas.push({ id: deleted[0].id });
                }
                vm.coleta.agendas.splice(index, 1);
            } else {
                DialogService.alert("Não é possível excluir agendamentos em vigência ou anteriores à data atual.");
            }
        };

        var _validateForm = function() {
            var hasNewItems = is.existy(vm.coleta.itensCriados) && is.not.empty(vm.coleta.itensCriados);
            var hasUpdatedItems = is.existy(vm.coleta.itensAlterados) && is.not.empty(vm.coleta.itensAlterados);
            var hasOldItems = is.existy(vm.coleta.itens) && is.not.empty(vm.coleta.itens);

            if (!hasNewItems && !hasOldItems && !hasUpdatedItems && !vm.coleta.programa.id == 33) {
                toastService.error("Pelo menos um item de coleta deve ser incluído.");
                return false;
            }

            if (vm.coleta.tipoColeta && vm.coleta.tipoColeta.key == 'A') {
                if (vm.coleta.agendas && vm.coleta.agendas.length > 0) {
                    return vm.registerForm.$valid;
                } else {
                    vm.registerForm.$setValidity("noAgenda", false);

                    return false;
                }
            } else {
                return vm.registerForm.$valid;
            }
        };

        vm.save = function() {
            // TODO Aqui é um dos dois únicos momentos em que vm.coleta.agendasCriadas é referenciado!
            if (vm.coleta.agendas.length > 0 || (vm.coleta.agendasCriadas && vm.coleta.agendasCriadas.length > 0)) {
                vm.registerForm.agendaInicio.$setValidity("required", true);
                vm.registerForm.agendaFim.$setValidity("required", true);
               
            }
            
            var agendas = vm.coleta.agendas;
            var agendasCriadas = vm.coleta.agendasCriadas;
            if (_validateForm()) {
                coletaService.save(vm.coleta)
                    .then(function(coleta) {
                        // Aqui vai entrar a chamada para o metodo de save coleta filtros
                        if(vm.possuiFiltroEscola(vm.coleta.programa.id)){
                            try {
                                vm.filterParam = [];
                                vm.profParam = [];

                                if(vm.filtrosObj){
                                    vm.filterParam.id = vm.filtrosObj.id;
                                }
                                vm.filterParam.idColeta = coleta.id;
                                vm.filterParam.idUF = vm.filter.state.id;
                                vm.filterParam.idMunicipio = vm.filter.city.id;
                                vm.filterParam.idEscola = vm.filter.school.id;
                                // vm.filterParam.idTurma = vm.filter.turma.id;
                                // vm.filterParam.rubricas = vm.filter.rubricasEscolhidas;

                                if(vm.profeObj){
                                    vm.profParam.id = vm.profeObj.id;
                                }
    
                                vm.profParam.professores = vm.filter.professoresEscolhidos;
                                vm.profParam.disciplinas = vm.filter.disciplinasEscolhidos;
                                vm.profParam.idColeta = coleta.id;
    
                                coletaService.saveFiltros(vm.filterParam).then(
                                    function(resultFilters){
                                        console.log("filtros deu certo");
                                    },function(resultFiltersError){
                                        console.log("filtros deu ruim");
                                    }
                                );
    
                                // coletaService.saveColetaProfessor(vm.profParam).then(
                                //     function(resultColProf){
                                //         console.log("colProf deu certo");
                                //     }, function(resultColProfError){
                                //         console.log("colProf deu certo");
                                //     }
                                // );
                            } catch (error) {
                                console.log(error);
                            }
                           

                        }
                        $scope.$emit(APP_EVENT.COLETA.SAVED);

                        if (!vm.salvarContinuar) {
                            vm.goBack();
                        } else {
                            _init();
                            //Irá enviar notificação para limpar os itens selecionados
                            $rootScope.$broadcast(APP_EVENT.GENERIC.CLEAR_ITEMS_SELECTED)
                            vm.registerForm.$setPristine();
                            $state.go(APP_STATE.COLETA.EDIT, { id: coleta.id, editMode: true });
                        }
                    }, function(response) {
                        toastService.error({
                            status: response.status,
                            message: response.data.message
                        });
                        vm.coleta.agendas = agendas;
                    });
            }
        };

        vm.saveItems = function() {
            if (vm.coleta.id) {
                coletaService.saveItens(vm.coleta)
                    .then(function(savedItens) {
                        vm.coleta.itensCriados = [];
                        vm.coleta.itens = vm.coleta.itens.concat(savedItens);
                        _saveOrUpdateItensMessages(true);

                    }, function(response) {
                        _saveOrUpdateItensMessages(false, response);
                    });
            } else {
                if (_validateForm()) {
                    coletaService.save(vm.coleta)
                        .then(function(coleta) {
                            vm.coleta = coleta;
                            _setOrCreateLists();
                            _saveOrUpdateItensMessages(true);

                        }, function(response) {
                            _saveOrUpdateItensMessages(false, response);
                        });
                } else {
                    vm.registerForm.$setSubmitted(true);
                }
            }
        };

        vm.updateItems = function() {
            coletaService.updateItens(vm.coleta)
                .then(function(itens) {
                    vm.coleta.itensAlterados = [];
                    vm.coleta.itensExcluidos = [];
                    vm.coleta.itens = itens;

                    _saveOrUpdateItensMessages(true);

                }, function(response) {
                    _saveOrUpdateItensMessages(false, response);
                });
        };

        vm.cancel = function() {
            if (vm.refreshSearch) {
                $scope.$emit(APP_EVENT.COLETA.REFRESH);
            }

            vm.goBack();
        };

        vm.checkMaxDias = function(field) {
            if (field.$modelValue) {
                if (field.$modelValue > vm.MAX_DAYS) {
                    field.$setValidity("maxDaysSize", false);
                } else {
                    field.$setValidity("maxDaysSize", true);
                }
            } else {
                field.$setValidity("maxDaysSize", true);
            }
        };

        vm.clone = function() {
            var cloned = angular.copy(vm.coleta);
            var statesCloned = angular.copy(vm.states);
            var filterCloned = angular.copy(vm.filter);
            var citiesCloned = angular.copy(vm.cities);
            var schoolsCloned      = angular.copy(vm.schools);
            var turmasCloned       = angular.copy(vm.turmas);
            var rubricasCloned     = angular.copy(vm.rubricas);
            var disciplinasCloned  = angular.copy(vm.disciplinas);
            var professoresCloned  = angular.copy(vm.professores);


            delete cloned.id;
            delete cloned.$promise;
            delete cloned.$resolved;

            cloned.itensCriados = _.map(cloned.itens, function(value) {
                delete value.id;
                return value;
            });

            cloned.agendas = _.map(cloned.agendas, function(value) {
                delete value.id;
                return value;
            });

            cloned.itens = [];
            cloned.itensExcluidos = [];
            cloned.itensAlterados = [];
            cloned.agendasCriadas = [];
            cloned.agendasExcluidas = [];

            $state.go(APP_STATE.COLETA.NEW, { 
                coleta: cloned,
                filter: filterCloned,
                states: statesCloned,
                cities: citiesCloned,
                schools: schoolsCloned,
                turmas: turmasCloned,
                rubricas: rubricasCloned,
                disciplinas: disciplinasCloned,
                professores: professoresCloned });
        };

        vm.preview = function(item) {
            if (item.formulario) {
                formularioService.findItensAtivosById(item.formulario.id).then(function(itens) {
                    dialogService.modal(MODAL_TEMPLATE, function($uibModalInstance) {
                        var _vm = this;
                        _vm.itens = itens;
                        _vm.tipoFormulario = item.formulario.tipo.key;
                        _vm.modalTitle = item.formulario.nome;

                        _vm.cancel = function() {
                            $uibModalInstance.dismiss('cancel');
                        };
                    }, function() {});

                }, function(response) {
                    toastService.error({
                        status: response.status,
                        message: response.data.message
                    });
                });
            }
        };

        var _refreshGranularidade = function() {
            if (vm.coletaAnonima) {
                vm.granularidades = [COLETA_ANONIMA_DEFAULT_FIELDS.GRANULARIDADE];
            } else {
                vm.granularidades = _granularidadesNaoAnonima;
            }
        };

        var _refreshFormularios = function() {
            try {
                formularioService.findAll().then(function(data){
                    vm.modalDependencies.formularios = data;
                });
            } catch (error) {
                console.log(error);
            }
        };

        var _refreshGranularidadeAndForms = function() {
            _refreshGranularidade();
            _refreshFormularios();
        };

        var _deleteAllForms = function() {
            if (vm.coleta.itens) {
                var itensExcluidosTemp = _.filter(vm.coleta.itens, function(item) {
                    return item.id;
                });

                vm.coleta.itensExcluidos = itensExcluidosTemp.concat(vm.coleta.itensExcluidos);
            }

            vm.coleta.itens = [];
            vm.coleta.itensCriados = [];
            vm.coleta.itensAlterados = [];
        };

        var _configColetaByTipoAcesso = function() {
            vm.coletaAnonima = _isColetaAnonima();
            _refreshGranularidadeAndForms();

            if (vm.coletaAnonima) {
                vm.coleta.tipoAbrangencia = COLETA_ANONIMA_DEFAULT_FIELDS.TIPO_ABRANGENCIA;
                vm.coleta.granularidade = COLETA_ANONIMA_DEFAULT_FIELDS.GRANULARIDADE;
                vm.coleta.tipoColeta = COLETA_ANONIMA_DEFAULT_FIELDS.TIPO_COLETA;
            } else {
                vm.coleta.tipoAbrangencia = null;
                vm.coleta.granularidade = null;
                vm.coleta.tipoColeta = null;
            }

            delete vm.coleta.periodicidade;
            vm.coleta.controlaPrazo = false;
            vm.coleta.controlaAlteracao = false;

            vm.deleteDiasPrazo();
            vm.deleteDiasAlteracao();
            _deleteAllAgendas();
            _deleteAllForms();

            _oldTipoAcesso = vm.coleta.tipoAcesso;_refreshFormularios
        };

        var _hasItensColeta = function() {
            var _hasOldItens = is.existy(vm.coleta.itens) && is.not.empty(vm.coleta.itens);
            var _hasNewItens = is.existy(vm.coleta.itensCriados) && is.not.empty(vm.coleta.itensCriados);

            return _hasNewItens || _hasOldItens;
        };

        var _dependentFieldsWasFilled = function() {
             var _tipoAbrangenciaFilled = is.existy(vm.coleta.tipoAbrangencia);
             var _granularidadeFilled = is.existy(vm.coleta.granularidade);
             var _tipoColetaFilled = is.existy(vm.coleta.tipoColeta);
             var _itensColetaFilled = _hasItensColeta();
                
            return _tipoAbrangenciaFilled || _granularidadeFilled || _tipoColetaFilled || _itensColetaFilled;
        };

        vm.tipoAcessoOnChange = function() {
            if (_dependentFieldsWasFilled()) {
                var msg = "Os campos: Tipo de Abrangência, Granularidade, Tipo de Coleta serão modificados e os itens da coleta serão removidos para se adaptar ao Tipo de Acesso. Tem certeza disso?";
                dialogService.confirm(msg, "Substituir")
                    .then(function() {
                        _configColetaByTipoAcesso();
                    })
                    .catch(function() {
                        vm.coleta.tipoAcesso = _oldTipoAcesso;
                    });
            } else {
                _configColetaByTipoAcesso();
            }
        };

        vm.changePrograma = function(){
            //vm.states = reportService.loadStates();
            var idPrograma = vm.coleta.programa.id;
            _populateDependencies();


            ufService.withPermissionFilter({idPrograma:idPrograma}).then(function(response){
              
                vm.states = response;
            });
            vm.habilitaBotaoForm();
        };

        vm.changeState = function(){
            //vm.cities = reportService.loadCitiesOfState(vm.filter.state.id);
            var idPrograma = vm.coleta.programa.id;
            var idEstado  = vm.filter.state.id;

            municipiosService.withPermissionFilter({idUf: idEstado, idPrograma:idPrograma}).then(function(response){
                vm.cities = response;
            });
   
        };

        vm.changeCity = function(){
         
            //vm.schools = reportService.loadSchoolsOfCity(vm.filter.city.id);
            //var programa = vm.coleta.programa;


            var programa = [];
            programa.push(vm.coleta.programa.id);
            var idMunicipio =  vm.filter.city.id;

            escolasService.searchByWithPermission({situacao:"A",idMunicipio:idMunicipio, programas:programa}).then(function(escolas) {
                vm.schools = escolas;
            });

   
        };

        vm.changeSchool = function(){

            if(vm.filter.school != undefined){
                professorService.ProfessoresByEscola({idEscola: vm.filter.school.id})
                .then(function(data) {
                    vm.professores = data;
                    
                },function(response) {
                    toastService.error({
                        status: response.status,
                        message: response.data.message
                    });
                })
            }else{
                vm.filter.professoresEscolhidos = [];
                vm.professores = [];
            }

           var idPrograma = vm.coleta.programa.id;
           var idEscola = vm.filter.school.id;

            turmasService.withPermissionFilter({idEscola: idEscola, idPrograma: idPrograma}).then(function(turmas) {

                    vm.turmas = turmas;
            });

            

        };

       /* vm.labelsRubricas = {
            selectAll: "Selecionar todos",
            unselectAll: "Desmarcar",
            itemsSelected: "selecionados",
            search: "Pesquisar",
            select: " Selecionadas"
        };*/

        vm.changeTurma = function(){
            vm.settings = {enableSearch: true};
            vm.rubricas = [];
            rubricasService.findRubricasByTurma(vm.filter.turma.id).$promise.then(function(rubricasPorTurma){
                vm.rubricas = rubricasPorTurma;

                if(vm.rubricas.length == 0){
                    (rubricasService.byPrograma(vm.coleta.programa.id)).$promise.then(function(response) {
                        vm.rubricas = response;
                    });
                }
            });

           
            disciplinasService.findByTurma(vm.filter.turma.id).$promise.then(function(disciplinasResult){
                console.log(disciplinasResult);
                vm.disciplinas  = disciplinasResult;


            });
            
        };

        vm.changeRubrica = function(){
            //vm.tipoAcessoOnChange();
            vm.changeItems();
            _refreshFormularios();

        };

        vm.changeDisciplina = function(){
            vm.changeItems();
            /*if(vm.filter.disciplinasEscolhidos.length > 0){
                professorService.ProfessorByTurmaAndDisciplinaId(vm.filter.turma.id, vm.filter.disciplinasEscolhidos)
                .then(function(data) {
                    vm.professores = data;
                    
                },function(response) {
                    toastService.error({
                        status: response.status,
                        message: response.data.message
                    });
                })
            }else{
                vm.filter.professoresEscolhidos = [];
            }*/
        };

        vm.changeOutrosProf = function(){
            vm.changeItems();
        };


        vm.changeItems = function(){
            try {
                
                if(vm.filter !== undefined ){
                
                    if(vm.filter.professoresEscolhidos === undefined){
                        vm.filter.professoresEscolhidos = [];
                        vm.labelsOutrosProfessores = {
                            selectAll: "Selecionar todos",
                            unselectAll: "Desmarcar",
                            itemsSelected: "selecionados",
                            search: "Pesquisar",
                            select: "Selecione"

                        }

                    }else if(vm.filter.professoresEscolhidos){
                        vm.labelsOutrosProfessores = {
                            selectAll: "Selecionar todos",
                            unselectAll: "Desmarcar",
                            itemsSelected: "selecionados",
                            search: "Pesquisar",
                            select: vm.filter.professoresEscolhidos == undefined || vm.filter.professoresEscolhidos.length == undefined ? "Selecione" :  vm.filter.professoresEscolhidos.length > 0 ? vm.filter.professoresEscolhidos.length+" Selecionadas" : "Selecione"
                        };
                    }


                    if(vm.filter.disciplinasEscolhidos === undefined){
                        vm.filter.disciplinasEscolhidos = [];
                        vm.labelsDisciplina = {
                            selectAll: "Selecionar todos",
                            unselectAll: "Desmarcar",
                            itemsSelected: "selecionados",
                            search: "Pesquisar",
                            select: "Selecione"
                        };
                    }else if(vm.filter.disciplinasEscolhidos){
                        vm.labelsDisciplina = {
                            selectAll: "Selecionar todos",
                            unselectAll: "Desmarcar",
                            itemsSelected: "selecionados",
                            search: "Pesquisar",
                            select: vm.filter.disciplinasEscolhidos == undefined || vm.filter.disciplinasEscolhidos.length == undefined ? "Selecione" : vm.filter.disciplinasEscolhidos.length > 0 ? vm.filter.disciplinasEscolhidos.length+" Selecionados" : "Selecione"
                        };
                    }

                    if(vm.filter.rubricasEscolhidas === undefined){
                        vm.filter.rubricasEscolhidas = [];
                        vm.labelsRubricas = {
                            selectAll: "Selecionar todos",
                            unselectAll: "Desmarcar",
                            itemsSelected: "selecionados",
                            search: "Pesquisar",
                            select: "Selecione"
                        };
                    }else if(vm.filter.rubricasEscolhidas){
                        vm.labelsRubricas = {
                            selectAll: "Selecionar todos",
                            unselectAll: "Desmarcar",
                            itemsSelected: "selecionados",
                            search: "Pesquisar",
                            select: vm.filter.rubricasEscolhidas == undefined || vm.filter.rubricasEscolhidas.length == undefined ? "Selecione" : vm.filter.rubricasEscolhidas.length > 0 ? vm.filter.rubricasEscolhidas.length+" Selecionados" : "Selecione"
                        };
                    }

                }
                
            } catch (error) {
                console.log(error);
                console.log('deu ruim!!!!!!');
            }
        }

        vm.habilitaBotaoForm = function(){
            if(vm.coleta){
                if(!vm.coleta.programa || vm.coleta.programa.id != 33){
                    if(!vm.editMode || vm.coleta.situacao.key == 'I' || !vm.coleta.tipoAcesso){
                        return true;
                    }else{
                        return false;
                    }
                }else{
                    if(!vm.editMode || vm.coleta.situacao.key == 'I'){
                        return true;
                    }else{
                        return false;
                    }
    
                }
            }
            
        };

        vm.fecharColeta = function(){
            dialogService.confirm(COLETA_MESSAGES.warning.fecharColeta, COLETA_MESSAGES.info.title)
            .then(function (response) {

                coletaRespostaService.fecharColeta(vm.coleta);
                toastService.success(COLETA_MESSAGES.success.fecharColeta);
                /*coletaRespostaService.fecharColeta(vm.coleta).$promise.then(function(result){
                    try {
                        alert('sndiodsgiosdgiobsgob');
                        toastService.success(COLETA_MESSAGES.success.fecharColeta);
                    } catch (error) {
                        console.log(error);
                    }
                    
                });*/
                
            });
        }
        
        $scope.$on("$destroy", function() {
            _agendaWatcher();
        });

        (function() {
            _init();
        })();

    }

}());