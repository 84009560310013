(function() {
    'use strict';

    angular.module('app.core').directive("iasMessageBarUser", IasMessageBarUser);
    IasMessageBarUser.$inject = [];

    function IasMessageBarUser() {
         return {
            scope: {
                entity: '='
            },
            templateUrl: "core/directives/message/partials/ias-message-bar-user.html"
        };
    }

})();