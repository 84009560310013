(function() {
    'use strict';

    angular.module('app.coleta').factory('Coleta', Coleta);
    Coleta.$inject = ['$resource', 'APP_PROPERTY'];

    function Coleta($resource, APP_PROPERTY) {
        return $resource(APP_PROPERTY.URL + '/coletas/:id', { id: '@id' }, {
            update: {
                method: 'PUT'
            },
            filter: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/coletas'
            },
            findByStatus: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/coletas/:status'
            },
            findItens: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/coletas/:id/itens/:status'
            },
            viewById: {
                method: 'GET',
                url: APP_PROPERTY.URL + '/coletas/:id/view'
            },
            findByType: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/coletas/:type'
            },
            saveItens: {
                method: 'POST',
                isArray: true,
                url: APP_PROPERTY.URL + '/coletas/:id/itens'
            },
            updateItens: {
                method: 'PUT',
                isArray: true,
                url: APP_PROPERTY.URL + '/coletas/:id/itens'
            },
            findAlvos: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/coletas/:id/alvos'
            },
            findColetaFiltrosByIdColeta: {
                method: 'GET',
                isArray: false,
                url: APP_PROPERTY.URL + '/coletas/busca-filtros-por-coleta/:idColeta'
            },

            findColetaProfessoresEscolhidos:{
                method: 'GET',
                isArray: false,
                url: APP_PROPERTY.URL + '/coletas/busca-professores-por-coleta/:idColeta'
            },
            findColetaByPrograma:{
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/coletas/programa/:id'
            },
            porPrograma:{
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/coletas/controla-acesso/programa/:id'
            },
            sendEmails: {
                method: 'POST',
                isArray: true,
                url: APP_PROPERTY.URL + '/coletas/:id/send-emails'
            },
            verificaAberturaColeta: {
                method: 'GET',
                isArray: false,
                url: APP_PROPERTY.URL + '/coletas/varifica-abertura-coleta'
            },
            saveFiltros:{
                method: 'POST',
                isArray: true,
                url: APP_PROPERTY.URL + '/coletas/save-coleta-filtros'
            },
            saveColetaProfessor:{
                method: 'POST',
                isArray: true,
                url: APP_PROPERTY.URL + '/coletas/save-coleta-professores'
            }
        });

    }

}());
