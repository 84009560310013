// (function() {
//     'use strict';
//
//     angular.module('medidaApp').controller(MedidaCtrl);
//     MedidaCtrl.$inject = [];
//
//     function MedidaCtrl() {
//       var vm = this;
//
//       alert("hello");
//
//
//       vm.hello = function() {
//         alert("hello");
//       };
//
//     }
// })();
