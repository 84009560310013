(function(){
    'use strict';

    angular.module('app.coleta.resposta').controller('ColetaRespostaAnonimaController', ColetaRespostaAnonimaController);
    ColetaRespostaAnonimaController.$inject = ['$state', 'coletaRespostaService', 'toastService', 'scrollService',
        'HTTP_STATUS_CODE'];

    function ColetaRespostaAnonimaController($state, coletaRespostaService, toastService, scrollService,
        HTTP_STATUS_CODE) {

        var vm = this;

        var _setActionRestrictions = function(situacao) {
            vm.allowsChange = coletaRespostaService.allowsChange(situacao);
            vm.canBeConcluded = coletaRespostaService.canConclude(situacao);
        };

        vm.saveAll = function() {
            if (vm.registerForm.$valid) {
                coletaRespostaService.saveAnonymous(vm.resposta)
                    .then(function(situacao) {
                        vm.resposta.situacao = situacao;
                        _setActionRestrictions(vm.resposta.situacao);

                        delete vm.resposta.constraints;
                        vm.registerForm.$setPristine();
                        toastService.success("Operação realizada com sucesso.");
                    })
                    .catch(function(error) {
                        if (is.existy(error.constraints)) {
                            vm.resposta.constraints = error.constraints;
                        } else {
                            delete vm.resposta.constraints;
                            toastService.error(error);
                        }
                    })
                    .finally(function() {
                        scrollService.scrollTop();
                    });
            }
        };

        vm.conclude = function() {
            if (vm.registerForm.$valid) {
                coletaRespostaService.concludeAnonymous(vm.resposta.key)
                    .then(function(resposta) {
                        vm.resposta = resposta;
                        _setActionRestrictions(resposta.situacao);
                        toastService.success("Coleta concluída com sucesso.");
                    })
                    .catch(function(error) {
                        if (is.existy(error.data)) {
                            vm.resposta.constraints = error.data;
                        } else {
                            delete vm.resposta.constraints;
                            toastService.error(error);
                        }
                    })
                    .finally(function() {
                        scrollService.scrollTop();
                    });
            }
        };
        
        (function() {
            vm.error = null;
            vm.allowsChange = false;

            var coletaRespostaKey = $state.params.id;

            if (coletaRespostaKey) {
                coletaRespostaService.viewAnonymous(coletaRespostaKey)
                    .then(function(resposta) {
                        vm.resposta = resposta;
                        _setActionRestrictions(resposta.situacao);
                    })
                    .catch(function(error) {
                        vm.error = error;
                    });
            } else {
                vm.error = {
                    status: HTTP_STATUS_CODE.BAD_REQUEST,
                    data: {
                        message: 'Nenhuma coleta foi informada na URL do sistema.'
                    }
                }
            }
        })();
    }

}());