(function() {
    'use strict';

    angular.module('app.disciplinas').factory('Disciplinas', Disciplinas);
    Disciplinas.$inject = ['$resource', 'APP_PROPERTY'];

    function Disciplinas($resource, APP_PROPERTY) {
        return $resource(APP_PROPERTY.URL + '/disciplinas/:id', { id: '@id' }, {
            update: {
                method: 'PUT'
            },
            get: {
                method: 'GET'
            },
            filter: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/disciplinas'
            },
            findByTurma: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/disciplinas/busca-por-turma/:idTurma'
            },
            ativo: {
                method: 'GET',
                url: APP_PROPERTY.URL + '/disciplinas/ATIVO',
                isArray: true
            },
            byUfAndMunicipioWithPermissionFilter: {
                method: 'GET',
                url: APP_PROPERTY.URL + '/disciplinas/with-permission-filter',
                isArray: true
            },
            porIdEscola: {
                method: 'GET',
                url: APP_PROPERTY.URL + '/disciplinas',
                isArray: true
            },
            findByStatus: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/disciplinas/:status'
            },
            delete: {
                method: 'DELETE',
                transformResponse: function(data, headers, statusCode) {
                        var finalRsponse = {
                            data: data,
                            responseStatusCode: statusCode
                        };
                    return finalRsponse;
                },
                url: APP_PROPERTY.URL + '/disciplinas/:id'
            },
            findByAreaConhecimento: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/disciplinas/areaConhecimento/:id'
            },
        });

    }

}());