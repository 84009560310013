(function() {
    'use strict';

    angular.module('app.municipios').factory('Municipios', Municipios);
    Municipios.$inject = ['$resource', 'APP_PROPERTY'];

    function Municipios($resource, APP_PROPERTY) {
        return $resource(APP_PROPERTY.URL + '/municipios/:id', { id: '@id' }, {
            update: {
                method: 'PUT'
            },
            get: {
                method: 'GET'
            },
            filter: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/municipios'
            },
            ativo: {
                method: 'GET',
                url: APP_PROPERTY.URL + '/municipios/ATIVO',
                isArray: true
            },
            findByStatus: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/municipios/:status'
            },
            porRegionalUf: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/municipios/ativo?idRegionalUf=:idRegionalUf'
            },
            porUf: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/municipios/ativo?idUf=:idUf'
            },
            withPermissionFilter: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/municipios/with-permission-filter?idUf=:idUf'
            },
            regionais: {
                method: 'GET',
                isArray: true,
                url: APP_PROPERTY.URL + '/municipios/:id/regionais/ativo'
            },
            delete: {
                method: 'DELETE',
                transformResponse: function(data, headers, statusCode) {
                        var finalRsponse = {
                            data: data,
                            responseStatusCode: statusCode
                        };
                    return finalRsponse;
                },
                url: APP_PROPERTY.URL + '/municipios/:id'
            }
        });

    }

}());