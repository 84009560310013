(function() {
    'use strict';

    angular.module('app.core').directive('iasMaxDateValue', IasMaxDateValue);
    IasMaxDateValue.$inject = ['dateUtilService'];

    function IasMaxDateValue(dateUtilService) {
        return {
            require: 'ngModel',
            restrict: 'A',
            link: function(scope, element, attr, controller) {
                var maxDateAttr = attr.iasMaxDateValue;
                var iasMaxDateType = attr.iasMaxDateType || "date";
                var maxValue;

                var _setMaxValue = function(maxDateAttr) {
                    switch (iasMaxDateType) {
                        case "datetime":
                            maxValue = dateUtilService.parseToDateTime(maxDateAttr);
                            break;
                        case "time":
                            if (is.date(maxDateAttr)) {
                                maxValue = dateUtilService.parseToDateTime(maxDateAttr);
                            } else {
                                maxValue = dateUtilService.parseTimeToDate(maxDateAttr);
                            }
                            
                            break;
                        default:
                            maxValue = dateUtilService.parseToDate(maxDateAttr);
                    }
                };

                _setMaxValue(maxDateAttr);

                attr.$observe('iasMaxDateValue', function(newMaxValue) {
                    if (maxDateAttr != newMaxValue) {
                        _setMaxValue(newMaxValue);
                    }
                });

                var _validate = function(modelValue, viewValue) {
                    var value = modelValue || viewValue;

                    if (value && maxValue) {
                        return value <= maxValue;
                    } else {
                        return true;
                    }
                };

                if (maxValue) {
                    if (iasMaxDateType == "date") {
                        controller.$validators.iasMaxDateValue = _validate;
                    } else if (iasMaxDateType == "time") {
                        controller.$validators.iasMaxTimeValue = _validate;
                    } else {
                        controller.$validators.iasMaxDateTimeValue = _validate;
                    }
                }
            }
        };
    }

})();