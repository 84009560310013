(function() {
    'use strict';

    var coletaAlvoTableFields = {
        A: [
            { name: "id", description: "Código" },
            { name: "escola.id", description: "Código Escola" },
            { name: "escola.nome", description: "Escola" },
            { name: "turma.id", description: "Código Turma" },
            { name: "turma.nome", description: "Turma" },
            { name: "grao.id", description: "Código Aluno" },
            { name: "grao.nome", description: "Aluno" }
        ],
        P: [
            { name: "id", description: "Código" },
            { name: "escola.id", description: "Código Escola" },
            { name: "escola.nome", description: "Escola" },
            { name: "turma.id", description: "Código Turma" },
            { name: "turma.nome", description: "Turma" },
            { name: "grao.id", description: "Código Professor" },
            { name: "grao.nome", description: "Professor" }
        ],
        T: [
            { name: "id", description: "Código" },
            { name: "uf.nome", description: "UF" },
            { name: "municipio.nome", description: "Município" },
            { name: "escola.id", description: "Código Escola" },
            { name: "escola.nome", description: "Escola" },
            { name: "grao.id", description: "Código Turma" },
            { name: "grao.nome", description: "Turma" }
        ],
        E: [
            { name: "id", description: "Código" },
            { name: "uf.nome", description: "UF" },
            { name: "municipio.nome", description: "Município" },
            { name: "grao.id", description: "Código Escola" },
            { name: "grao.nome", description: "Escola" }
        ]
    };

    var respostaTableFields = [
        { name: "situacao.value", description: "Situação", type: "status"},
        { name: "dataInicioColeta", description: "Data Início", type: "date" },
        { name: "dataFimColeta", description: "Data Fim", type: "date" },
        { name: "dataHoraAtualizacao", description: "Data e Hora da última modificação", type: "datetime" },
        { name: "referencia", description: "Referência" }
    ];

    var situacaoCssClasses = {
        PENDING: 'status-resposta-pen',
        OK: 'status-resposta-ok',
        PROBLEM: 'status-resposta-inv'
    };

    var respostaSituacao = {
        1: situacaoCssClasses.PENDING,
        2: situacaoCssClasses.PENDING,
        3: situacaoCssClasses.OK,
        4: situacaoCssClasses.OK,
        5: situacaoCssClasses.PROBLEM,
        6: situacaoCssClasses.PENDING,
        7: situacaoCssClasses.PENDING,
    };

    angular.module('app.coletaRubrica.resposta').constant('COLETA_RESPOSTA', {
        TABLE_FIELDS: {
            ALVO: coletaAlvoTableFields,
            RESPOSTA: respostaTableFields
        },
        SITUACAO_CSS_CLASS: respostaSituacao
    });

    angular.module('app.coletaRubrica.resposta').constant('SITUACAO_COLETA', {
        NAO_INICIADA: { key: '1', value: 'Não iniciada' },
        EM_ANDAMENTO: { key: '2', value: 'Em andamento' },
        CONCLUIDA: { key: '3', value: 'Concluída' },
        CONCLUIDA_ATRASO: { key: '4', value: 'Concluída com atraso' },
        PRAZO_EXPIRADO: { key: '5', value: 'Prazo expirado para conclusão' },
        AGUARDANDO_LIBERACAO_REABERTURA: { key: '6', value: 'Aguardando liberação para reabertura' },
        AGUARDANDO_LIBERACAO_ALTERACAO: { key: '7', value: 'Aguardando liberação para conclusão após o prazo' },
        EM_PROCESSO_ALTERACAO: { key: '8', value: 'Em processo de conclusão após o prazo' },
        EM_PROCESSO_PREENCHIMENTO: { key: '9', value: 'Em processo de preenchimento após o prazo' },
        NEGADA: { key: 'A', value: 'Negada' },
        PRAZO_EXPIRADO_INICIO: { key: 'B', value: 'Prazo expirado para início' },
        EM_PROCESSO_CONCLUSAO_REABERTURA: { key: 'C', value: 'Em processo de conclusão após reabertura' }
    });

}());
