/*
jslint
    devel: true, maxerr: 10
*/

/*
global
    required: true
*/

/*
 * Relatórios Module
 * @controller Class Profile Report Controller
 * @author iFactory Solutions Ltda.
 * @version 1.0.0
 * @since 1.0.0
 */
(function() {
    'use strict';

    angular.module('app.relatorios').controller('RelatorioOutrosController', RelatorioOutrosController);
    RelatorioOutrosController.$inject = ['$sce', '$state', 'REPORT_PROGRAMS', 'APP_STATE', 'etapaService', 'escolasService', 'RelatoriosService', 'programasService', 'toastService', 'coletaService', 'REPORT_GROUP'];

    function RelatorioOutrosController($sce, $state, REPORT_PROGRAMS, APP_STATE, etapaService, escolasService, reportService, programasService, toastService, coletaService, REPORT_GROUP) {
        var vm = this;

        var _clearReportResult = function() {
            vm.result.reportDataCount = 0;
            vm.result.reportFields = [];
            vm.result.reportData = [];
            vm.result.calculations = {};
        };

        vm.findSchool = function(filter) {
            var filterNew = {};
            filterNew.situacao = "A";

            if (undefined != filter.city && is.not.null(filter.city)) {
                if (filter.city.id != 0) {
                    filterNew.idMunicipio = filter.city.id;
                }
            }

            if (undefined != filter.schoolType && is.not.null(filter.schoolType)) {
                if (filter.schoolType.id != 0) {
                    filterNew.tipoOrganizacao = filter.schoolType.id;
                }
            }

            if (undefined != filter.schoolAdministrativeDependency && is.not.null(filter.schoolAdministrativeDependency)) {
                if (filter.schoolAdministrativeDependency.id != 0) {
                    filterNew.idDependenciaAdministrativa = filter.schoolAdministrativeDependency.id;
                }
            }

            escolasService.searchByWithPermission(filterNew).then(function(data){
                vm.model.schools = data;
            });

        };

        vm.changeState = function() {
            if (is.existy(vm.filter.state)) {
                vm.model.cities = reportService.loadCitiesOfState(vm.filter.state.id);
                vm.model.stateRegionals = reportService.loadStateRegionalsOfState(vm.filter.state.id);
            } else {
                vm.model.stateRegionals = [];
                vm.model.cities = [];
                vm.model.cityRegionals = [];
                vm.model.schools = [];
            }
        };

        vm.changeStateRegional = function() {
            if (is.existy(vm.filter.stateRegional)) {
                vm.model.cities = reportService.loadCitiesOfStateRegional(vm.filter.stateRegional.id);
            } else {
                // TODO Guardar a cidade anteriormente selecionada, caso haja; refazer a consulta pela UF (se houver uma selecioanda)
                // e já deixar selecionada a cidade previamente selecionada.
                if (is.existy(vm.filter.state)) {
                    vm.model.cities = reportService.loadCitiesOfState(vm.filter.state.id);
                } else {
                    vm.model.cities = [];
                }
                vm.model.cityRegionals = [];
                vm.model.schools = [];
            }
        };

        vm.changeSchoolType = function() {
            if (is.existy(vm.filter.city) && is.existy(vm.filter.schoolType)) {
                vm.model.schools = reportService.loadSchoolByFilter(vm.filter);
            } else if (is.existy(vm.filter.city)) {
                vm.model.schools = reportService.loadSchoolsOfCity(vm.filter.city.id);
            } else {
                vm.model.cityRegionals = [];
                vm.model.schools = [];
            }
        };

        vm.changeSchoolLocalization = function() {
            if (is.existy(vm.filter.city) && is.existy(vm.filter.schoolLocalization)) {
                vm.model.schools = reportService.loadSchoolByFilter(vm.filter);
            } else if (is.existy(vm.filter.city)) {
                vm.model.schools = reportService.loadSchoolsOfCity(vm.filter.city.id);
            } else {
                vm.model.cityRegionals = [];
                vm.model.schools = [];
            }
        };

        vm.changeSchoolAdministrativeDependency = function() {
            if (is.existy(vm.filter.schoolAdministrativeDependency) && is.existy(vm.filter.schoolAdministrativeDependency)) {
                vm.model.schools = reportService.loadSchoolByFilter(vm.filter);
            } else if (is.existy(vm.filter.city)) {
                vm.model.schools = reportService.loadSchoolsOfCity(vm.filter.city.id);
            } else {
                vm.model.cityRegionals = [];
                vm.model.schools = [];
            }
        };

        vm.changeProgram = function() {
            if (is.existy(vm.filter.program)) {
                vm.filter.model = vm.filter.program.model;
            } else {
                vm.filter.model = null;
            }
            if (is.existy(vm.result.report.events) && is.existy(vm.result.report.events.onChangeProgram)) {
                vm.result.report.events.onChangeProgram({ report: vm.result.report }, vm.filter.program);
            }
        };

        vm.changeProgramCollect = function() {
            if (is.existy(vm.filter.program)) {
                vm.model.collects = coletaService.findColetaByPrograma(vm.filter.program.id);
            } else {
                vm.model.collects = null;
            }
        };

        vm.changeCollect = function() {
            if (is.existy(vm.filter.collect)) {
                if (vm.filter.collect.periodicidade.key == "B"){
                  vm.filter.indice = 2;
                  vm.filter.monthTotalization = null;
                  vm.filter.yearTotalization = null;
                  vm.filter.totalization = vm.model.totalization[vm.filter.indice];
                }else if (vm.filter.collect.periodicidade.key == "M"){
                    vm.filter.indice = 0;
                    vm.filter.biMonthlyTotalization = null;
                    vm.filter.totalization = vm.model.totalization[vm.filter.indice];
                } else{
                  vm.filter.indice = 1;
                  vm.filter.monthTotalization = null;
                  vm.filter.yearTotalization = null;
                  vm.filter.biMonthlyTotalization = null;
                  vm.filter.totalization = vm.model.totalization[vm.filter.indice];
                }
            } else {
                vm.model.collects = null;
            }
        };

        vm.changeMonitoring = function() {
            if (is.existy(vm.result.report.events) && is.existy(vm.result.report.events.onChangeMonitoring)) {
                vm.result.report.events.onChangeMonitoring({ report: vm.result.report }, vm.filter.typeMonitoring, vm.filter.typeMonitoringTypeReport);
            }
        }

        vm.changeCity = function() {
            if (is.existy(vm.filter.city)) {
                vm.model.cityRegionals = reportService.loadCityRegionalsOfCity(vm.filter.city.id);
                vm.model.schools = reportService.loadSchoolsOfCity(vm.filter.city.id);
            } else {
                vm.model.cityRegionals = [];
                vm.model.schools = [];
            }
        };

        vm.generateReport = function() {
            vm.view.searchWasPerformed = true;
            vm.result.calculations = [];
            reportService.generateReportResult(vm.filter, vm.result);
        };

        vm.formatColumn = function(item, field) {
            return reportService.formatColumn(item, field, vm.result);
        };

        vm.formatColumnHtml = function(item, field) {
            return $sce.trustAsHtml(reportService.formatColumn(item, field, vm.result));
        };

        vm.formatSummaryColumn = function(field) {
            return reportService.formatSummaryColumn(vm.result.calculations, field, vm.result);
        };

        vm.rowSpanMunicipio = function(data, field) {
            var total = 1;
            if (field.name == 'municipio') {
                if (is.not.null(data.municipio)) {
                    total = vm.result.municipiosRowSpan[data.idMunicipio];
                }
            }

            return total;
        };

        vm.performCalculation = function(field, item) {
            var oldValue = vm.result.calculations[field.name];
            vm.result.calculations[field.name] = reportService.performatCalculation(item, field, oldValue, vm.result);
        };

        vm.showResultReport = function(reportResultInfo) {
            var result = vm.result.reportDataCount > 0 && vm.view.searchWasPerformed;
            if (result && is.existy(reportResultInfo)) {
                if (is.existy(reportResultInfo.reportId)) {
                    result = result && is.existy(vm.result) && is.existy(vm.result.report) && vm.result.report.id == reportResultInfo.reportId;
                }
                if (is.existy(result && reportResultInfo.modelId)) {
                    result = result && is.existy(vm.filter) && is.existy(vm.filter.model) && vm.filter.model.id == reportResultInfo.modelId;
                }
            }
            return result;
        };

        vm.showFooter = function(fieldInfo) {
            var result = is.not.existy(fieldInfo.showFooter) || fieldInfo.showFooter;
            return result;
        };

        vm.isMonthlyTotalizationEnabled = function() {
            return (vm.filter.totalization.id == vm.model.totalization[0].id);
        };

        vm.isBiMonthlyTotalizationEnabled = function() {
          return (vm.filter.totalization.id == vm.model.totalization[2].id);
        };

        vm.isAccumulatedTotalizationEnabled = function() {
            return vm.filter.totalization.id == vm.model.totalization[1].id;
        };

        vm.isModelValid = function() {
            var result = true;
            if (is.existy(vm.result.report.constraints) && is.truthy(vm.result.report.constraints.model)) {
                result = is.existy(vm.filter.model);
            }
            return result;
        };

        vm.isTypeReportValid = function() {
            var result = true;
            if (is.existy(vm.result.report.constraints) && is.truthy(vm.result.report.constraints.typeReport)) {
                result = is.existy(vm.filter.typeReport);
            }
            return result;
        };

        vm.isFilterValid = function() {
            return vm.reportForm.$valid && vm.isModelValid() && vm.isTypeReportValid();
        };

        vm.changeDetailingComplete = function () {
            return vm.result.reportData = [];
        };

        (function() {
            vm.model = {
                states: [],
                stateRegionals: [],
                etapas: [],
                cities: [],
                cityRegionals: [],
                cityDistricts: [],
                schools: [],
                schoolYears: [],
                schoolTypes: [],
                schoolLocalizations: [],
                schoolAdministrativeDependencies: [],
                partnershipTypes: [],
                programs: [],
                collects: [],
                programsPerfilAtendimentoMunicipio: [],
                programsMediador: [],
                programsIdadeConclusao9ano: [],
                programsIdade8serie9ano: [],
                programsIdade4serie5ano:[],
                programasMonitoramentoRegional:[],
                programasSistematicaRegional:[],
                classes: [],
                classSeries: [],
                classShifts: [],
                classTypes: [],
                months: [],
                bimestres: [
                    {
                        key: 1,
                        value: "1º Bimestre"
                    },
                    {
                        key: 2,
                        value: "2º Bimestre"
                    },
                    {
                        key: 3,
                        value: "3º Bimestre"
                    },
                    {
                        key: 4,
                        value: "4º Bimestre"
                    }
                ],
                schoolStatus: [
                    {
                        id: "A",
                        name: "Ativas"
                    },
                    {
                        id: "I",
                        name: "Inativas"
                    }
                ],
                detailings: [
                    {
                        id: "U",
                        name: "Estado"
                    },
                    {
                        id: "R",
                        name: 'Regional'
                    },
                    {
                        id: "M",
                        name: "Município"
                    },
                    {
                        id: "E",
                        name: "Escola"
                    },
                    {
                        id: "T",
                        name: "Turma"
                    }
                ],
                detailingComplete:[
                    {
                        id: "G",
                        name: "Regional"
                    },
                    {
                        id: "U",
                        name: "Estado"
                    },
                    {
                        id: "M",
                        name: "Município"
                    },
                    {
                        id: "R",
                        name: "Regional Municipal"
                    },
                    {
                        id: "E",
                        name: "Escola"
                    },
                    {
                        id: "T",
                        name: "Turma"
                    }
                ],
                totalization: [{
                        id: "monthly",
                        name: "Mensal"
                    },
                    {
                        id: "accumulated",
                        name: "Acumulada"
                    },
                    {
                        id: "bimestre",
                        name: "Bimestral"
                    }
                ],
                monitoring: [
                    {
                        id: "A",
                        name: "A"
                    },
                    {
                        id: "B",
                        name: "B"
                    },
                    {
                        id: "I",
                        name: "I"
                    }
                ],
                monitoringTypeReport: [{
                        id: "1",
                        name: "Quantitativo"
                    }
                ],
                classMultiserie: [
                    {
                        key: 'false',
                        value: "Regular"
                    },
                    {
                        key: 'true',
                        value: "Multisseriada"
                    },
                ]

            };
            vm.filter = {
                state: null,
                stateRegional: null,
                city: null,
                cityRegional: null,
                cityDistrict: null,
                schoolYear: null,
                schoolType: null,
                schoolFilter: 'ID',
                indice: 0,
                schoolLocalization: null,
                schoolAdministrativeDependency: null,
                partnershipType: null,
                detailing: vm.model.detailingComplete[3],
                detailingComplete: vm.model.detailingComplete[5],
                shift: null,
                program: null,
                collect: null,
                totalization: null,
                model: null,
                class: null,
                classShift: null,
                classType: null,
                classSerie: null,
                yearTotalization: null,
                monthTotalization: null,
                biMonthlyTotalization: null,
                yearTotalizationInitial: null,
                monthTotalizationInitial: null,
                yearTotalizationFinal: null,
                monthTotalizationFinal: null,
                monitoring: {id:'A', name:'A'},
                monitoringTypeReport: { id: "1", name: "Quantitativo" }
            };
            vm.view = {
                available: true,
                searchWasPerformed: false,
                currentPage: null,
                allSchoolStatus: {
                    id: '0',
                    name: 'Todas'
                },
                allSchoolType: {
                    id: '0',
                    name: 'Todos'
                },
                allSchoolLocalization: {
                    id: '0',
                    nome: 'Todas'
                },
                allSchoolAdministrativeDependency: {
                    id: '0',
                    nome: 'Todas'
                },
                allClasses: {
                    key: '0',
                    value: 'Todas'
                },
                allClassShift: {
                    key: '0',
                    value: 'Todos'
                },
                allClassMultiserie: {
                    key: '0',
                    value: 'Todos'
                },
                allClassType: {
                    key: '0',
                    value: 'Todos'
                },
                disableYearMonth: false
            };
            vm.result = {
                report: null,
                reportDataCount: 0,
                reportFields: null,
                reportData: [],
                calculations: {}
            };

            vm.chart = {};

            vm.chart.showSistematicas = function() {
                return vm.result.reportData.$resolved && vm.result.reportData.graficoEscola;
            }

            vm.chart.showMonitoramento = function() {
                return vm.result.reportData.$resolved && vm.result.reportData.graficoAula;
            }

            vm.chart.escola = {
                series: ['Previstas', 'Realizadas'],
                colors: ['#5a9cd5', '#ed7c31'],
                labels: function() {
                    return vm.result.reportData.graficoEscola.map(function(label) {
                        return label.escola;
                    })
                },
                data: function() {
                    var listaVisitasPrevistas = vm.result.reportData.graficoEscola.map(function(label) {
                        return label.visitaPrevista;
                    });
                    var listaVisitasRealizadas = vm.result.reportData.graficoEscola.map(function(label) {
                        return label.visitaRealizada;
                    });
                    return [listaVisitasPrevistas, listaVisitasRealizadas];
                },
                options: {
                    scales: {
                        yAxes: [{
                            display: true,
                            ticks: {
                                suggestedMin: 0,
                                beginAtZero: true
                            }
                        }]
                    },
                    legend: { display: true }
                }
            }

            vm.chart.profissional = {
                series: ['Previstas', 'Realizadas'],
                colors: ['#ed7c31', '#5a9cd5'],
                labels: function() {
                    return vm.result.reportData.graficoVisita.map(function(label) {
                        return label.profissional;
                    });
                },
                data: function() {
                    var listaVisitasPrevistas = vm.result.reportData.graficoVisita && vm.result.reportData.graficoVisita.map(function(label) {
                        return label.visitaPrevista;
                    })
                    var listaVisitasRealizadas = vm.result.reportData.graficoVisita && vm.result.reportData.graficoVisita.map(function(label) {
                        return label.visitaRealizada;
                    })
                    return [listaVisitasPrevistas, listaVisitasRealizadas];
                },
                options: {
                    scales: {
                        yAxes: [{
                            display: true,
                            ticks: {
                                suggestedMin: 0,
                                beginAtZero: true
                            }
                        }]
                    },
                    legend: { display: true }
                }
            }

            vm.chart.motivo = {
                colors: ['#5a9cd5', '#ed7c31', '#a5a5a5', '#ffc000'],
                series: function() {
                    return vm.result.reportData.graficoMotivo.map(function(label) {
                        return label.motivo;
                    });
                },
                labels: function() {
                    return vm.result.reportData.graficoMotivo.map(function(label) {
                        return label.motivo;
                    });
                },
                data: function() {
                    return vm.result.reportData.graficoMotivo.map(function(label) {
                        return label.quantidade;
                    });
                },
                options: {
                    scales: {
                        yAxes: [{
                            display: true,
                            ticks: {
                                suggestedMin: 0,
                                beginAtZero: true
                            }
                        }]
                    },
                    legend: {
                        display: true
                    },
                    tooltips: {
                        callbacks: {
                            label: function(tooltipItem, data) {
                            var dataset = data.datasets[tooltipItem.datasetIndex];
                            var total = dataset.data.reduce(function(previousValue, currentValue, currentIndex, array) {
                                return previousValue + currentValue;
                            });
                            var currentValue = dataset.data[tooltipItem.index];
                            var percentage = Math.floor(((currentValue/total) * 100)+0.5);
                            return `${data.labels[tooltipItem.index]} ${percentage}%`;
                            }
                        }
                    }
                },
            }

            vm.chart.graficoFrequencia = {
                colors: ['#5a9cd5', '#ed7c31', '#a5a5a5', '#ffc000'],
                series: function() {
                    return vm.result.reportData.graficoFrequencia.map(function(label) {
                        return label.descricao;
                    });
                },
                labels: function() {
                    return vm.result.reportData.graficoFrequencia.map(function(label) {
                        return label.descricao;
                    });
                },
                data: function() {
                    return vm.result.reportData.graficoFrequencia.map(function(label) {
                        return label.valor;
                    });
                },
                options: {
                    scales: {
                        yAxes: [{
                            display: true,
                            ticks: {
                                suggestedMin: 0,
                                beginAtZero: true
                            }
                        }]
                    },
                    legend: {
                        display: false
                    },
                    tooltips: {
                        callbacks: {
                            label: function(tooltipItem, data) {
                            var dataset = data.datasets[tooltipItem.datasetIndex];
                            var total = dataset.data.reduce(function(previousValue, currentValue, currentIndex, array) {
                                return previousValue + currentValue;
                            });
                            var currentValue = dataset.data[tooltipItem.index];
                            var percentage = Math.floor(((currentValue/total) * 100)+0.5);
                            return `${data.labels[tooltipItem.index]} ${percentage}%`;
                            }
                        }
                    }
                },
            }

            vm.chart.graficoAula = {
                colors: ['#5a9cd5', '#ed7c31', '#a5a5a5', '#ffc000'],
                series: function() {
                    return vm.result.reportData.graficoAula.map(function(label) {
                        return label.descricao;
                    });
                },
                labels: function() {
                    return vm.result.reportData.graficoAula.map(function(label) {
                        return label.descricao;
                    });
                },
                data: function() {
                    return vm.result.reportData.graficoAula.map(function(label) {
                        return label.valor;
                    });
                },
                options: {
                    scales: {
                        yAxes: [{
                            display: true,
                            ticks: {
                                suggestedMin: 0,
                                beginAtZero: true
                            }
                        }]
                    },
                    legend: {
                        display: false
                    },
                    tooltips: {
                        callbacks: {
                            label: function(tooltipItem, data) {
                            var dataset = data.datasets[tooltipItem.datasetIndex];
                            var total = dataset.data.reduce(function(previousValue, currentValue, currentIndex, array) {
                                return previousValue + currentValue;
                            });
                            var currentValue = dataset.data[tooltipItem.index];
                            var percentage = Math.floor(((currentValue/total) * 100)+0.5);
                            return `${data.labels[tooltipItem.index]} ${percentage}%`;
                            }
                        }
                    }
                },
            }

            vm.chart.graficoRubricas = {
                colors: ['#5a9cd5', '#ed7c31', '#a5a5a5', '#ffc000'],
                series: function() {
                    return vm.result.reportData.graficoRubricas.map(function(label) {
                        return label.descricao;
                    });
                },
                labels: function() {
                    return vm.result.reportData.graficoRubricas.map(function(label) {
                        return label.descricao;
                    });
                },
                data: function() {
                    return vm.result.reportData.graficoRubricas.map(function(label) {
                        return label.valor;
                    });
                },
                options: {
                    scales: {
                        yAxes: [{
                            display: true,
                            ticks: {
                                suggestedMin: 0,
                                beginAtZero: true
                            }
                        }]
                    },
                    legend: {
                        display: false
                    },
                    tooltips: {
                        callbacks: {
                            label: function(tooltipItem, data) {
                            var dataset = data.datasets[tooltipItem.datasetIndex];
                            var total = dataset.data.reduce(function(previousValue, currentValue, currentIndex, array) {
                                return previousValue + currentValue;
                            });
                            var currentValue = dataset.data[tooltipItem.index];
                            var percentage = Math.floor(((currentValue/total) * 100)+0.5);
                            return `${data.labels[tooltipItem.index]} ${percentage}%`;
                            }
                        }
                    }
                },
            }

            vm.chart.graficoNotasAlunos = {
                colors: ['#5a9cd5', '#ed7c31', '#a5a5a5', '#ffc000'],
                series: function() {
                    return vm.result.reportData.graficoNotasAlunos.map(function(label) {
                        return label.descricao;
                    });
                },
                labels: function() {
                    return vm.result.reportData.graficoNotasAlunos.map(function(label) {
                        return label.descricao;
                    });
                },
                data: function() {
                    return vm.result.reportData.graficoNotasAlunos.map(function(label) {
                        return label.valor;
                    });
                },
                options: {
                    scales: {
                        yAxes: [{
                            display: true,
                            ticks: {
                                suggestedMin: 0,
                                beginAtZero: true
                            }
                        }]
                    },
                    legend: {
                        display: false
                    },
                    tooltips: {
                        callbacks: {
                            label: function(tooltipItem, data) {
                            var dataset = data.datasets[tooltipItem.datasetIndex];
                            var total = dataset.data.reduce(function(previousValue, currentValue, currentIndex, array) {
                                return previousValue + currentValue;
                            });
                            var currentValue = dataset.data[tooltipItem.index];
                            var percentage = Math.floor(((currentValue/total) * 100)+0.5);
                            return `${data.labels[tooltipItem.index]} ${percentage}%`;
                            }
                        }
                    }
                },
            }


            vm.model.states = reportService.loadStates();
            vm.model.schoolYears = reportService.loadSchoolYears();
            vm.model.schoolTypes = reportService.loadShoolTypes();
            vm.model.schoolLocalizations = reportService.loadLocalizations();
            vm.model.schoolAdministrativeDependencies = reportService.loadAdministrativeDependencies();
            vm.model.months = reportService.loadMonths();
            vm.model.etapas = etapaService.ativos();
            reportService.loadClassShifts().then(function(data) {
                vm.model.classShifts = angular.copy(data);
            }, function() {
                vm.model.classShifts = [];
            });
            reportService.loadClassTypes().then(function(data) {
                vm.model.classTypes = angular.copy(data);
            }, function() {
                vm.model.classTypes = [];
            });
            vm.model.classes = reportService.loadClasses();

            // Por padrão, o detalhamento do relatório é feito por turma.
            vm.filter.detailing = vm.model.detailingComplete[5];


            vm.filter.schoolType = vm.view.allSchoolType;
            vm.filter.schoolStatus = vm.view.allSchoolStatus;
            // Por padrão, a localização da escola é "Todas".
            vm.filter.schoolLocalization = vm.view.allSchoolLocalization;
            // Por padrão, a dependência administrativa da escola é "Todas".
            vm.filter.schoolAdministrativeDependency = vm.view.allSchoolAdministrativeDependency;
            // Por padrão, a totalização do relatório é feito por mês.
            vm.filter.totalization = vm.model.totalization[0];
            // Por padrão, o tipo de turma é "Todos".
            vm.filter.classType = vm.view.allClassType;
            // Por padrão o tipo de relatório de acompanhamento é qualitativo
            vm.filter.monitoringTypeReport = vm.model.monitoringTypeReport[0];
            // Por padrão, o tipo de turma é "Todos".
            vm.filter.classMultiserie = vm.view.allClassMultiserie;

            // TODO Realizar o tratamento adequado para o caso de não ter o report no parâmetro do $state (avisar ou já redirecionar para a tela base?)
            vm.result.report = $state.params.report;
            if (is.not.existy(vm.result.report)) {
                vm.view.available = false; // TODO Espalhar em toda a interface esse controle.
                toastService.error('Não foi possível recuperar as informações do relatório.');
            } else {
                reportService.loadPrograms({ reportId: vm.result.report.id }).then(function(data) {
                    vm.model.programs = data;
                });
            }

            programasService.ativosRelatorio().then(function(programas){
                angular.forEach(programas, function(value){
                    if (is.inArray(value.id, [34])) {
                        var program = {
                            id: value.id,
                            nome: value.nome
                        };
                        var reportGroup = [
                            REPORT_GROUP.OUTROS.SISTEMATICA_MONITORAMENTO_REGIONAL,
                            REPORT_GROUP.OUTROS.SISTEMATICA_ACOMPANHAMENTO_VISITAS_REGIONAL,
                            REPORT_GROUP.OUTROS.SISTEMATICA_ACOMPANHAMENTO_VISITAS_CENTRAL,
                            REPORT_GROUP.OUTROS.SISTEMATICA_MONITORAMENTO_CENTRAL
                        ];
                        vm.model.programasSistematicaRegional.push(program);
                        vm.model.programasMonitoramentoRegional.push(program);
                        if (reportGroup.indexOf(vm.result.report.id) > -1) {
                            vm.filter.program = program;
                        }
                    }
                });
                angular.forEach(programas, function(value){
                    if (is.inArray(value.id, [8,9,10,13,14,16,17,18,19,20,21,22,23,24])) {
                        vm.model.programsPerfilAtendimentoMunicipio.push({
                            id: value.id,
                            nome: value.nome
                        });
                    }
                });
                angular.forEach(programas, function(value){
                    if (is.inArray(value.id, [1,2,8,9,10,13,14])) {
                        vm.model.programsMediador.push({
                            id: value.id,
                            nome: value.nome
                        });
                    }
                });
                angular.forEach(programas, function(value){
                    if (is.inArray(value.id, [12])) {
                        vm.model.programsIdadeConclusao9ano.push({
                            id: value.id,
                            nome: value.nome
                        });
                    }
                });
                angular.forEach(programas, function(value){
                    if (is.inArray(value.id, [14,20])) {
                        vm.model.programsIdade4serie5ano.push({
                            id: value.id,
                            nome: value.nome
                        });
                    }
                });
                angular.forEach(programas, function(value){
                    if (is.inArray(value.id, [24])) {
                        vm.model.programsIdade8serie9ano.push({
                            id: value.id,
                            nome: value.nome
                        });
                    }
                });
            });

        })();
    }

}());
