(function() {
    'use strict';

    angular.module('app.turmas').service('turmasService', TurmasService);
    TurmasService.$inject = ['Turmas','APP_PROPERTY', 'STATUS_ENUM', '$q', 'HTTP_STATUS_CODE','fileService'];

    function TurmasService(Turmas, APP_PROPERTY, STATUS_ENUM, $q, HTTP_STATUS_CODE, fileService) {

        this.filter = function(filter) {
            return Turmas.filter(filter).$promise;
        };

        this.filterPageable = function(filter) {
            return Turmas.filterPageable(filter).$promise;
        };

        this.get = function(id) {
            return Turmas.get({ id: id }).$promise;
        };

        this.salvarMovimentacaoMensal = function(movimentacao) {
            return Turmas.salvarMovimentacaoMensal(movimentacao).$promise;
        };

        this.save = function(turma) {
            var entity = new Turmas(turma);

            if (entity.id) {
                return entity.$update();
            } else {
                return entity.$save();
            }
        };

        this.delete = function(programa) {
            return Turmas.delete({ id: programa.id }).$promise;
        };

        this.ativos = function() {
            return Turmas.ativo();
        };

        this.porEscola = function(idEscola) {
            return Turmas.porEscola({ idEscola: idEscola });
        };

        this.withPermissionFilter = function(params) {
            return Turmas.withPermissionFilter(params).$promise;
        };

        this.setCompetenciasLote = function(params) {
            return Turmas.setCompetenciasLote(params).$promise;
        };

        this.deleteCompetenciasLote = function(params) {
            return Turmas.deleteCompetenciasLote(params).$promise;
        };

        this.turnos = function() {
            return $q(function(resolve, reject) {
                Turmas.turnos().$promise.then(function(data) {
                    var turnos = _.map(data, function(item) {
                        return {
                            key: item.id,
                            value: item.name
                        };
                    });

                    resolve(turnos);
                }, function(error) {
                    reject(error);
                });
            });
        };

        this.atendimentos = function() {
            return $q(function(resolve, reject) {
                Turmas.atendimentos().$promise.then(function(data) {
                    var atendimentos = _.map(data, function(item) {
                        return {
                            key: item.id,
                            value: item.name
                        };
                    });

                    resolve(atendimentos);
                }, function(error) {
                    reject(error);
                });
            });
        };

        this.funcoesProfessor = function() {
            return $q(function(resolve, reject) {
                Turmas.funcoesProfessor().$promise.then(function(data) {
                    var funcoesProfessor = _.map(data, function(item) {
                        return {
                            key: item.id,
                            value: item.name
                        };
                    });

                    resolve(funcoesProfessor);
                }, function(error) {
                    reject(error);
                });
            });
        };

        this.tiposContratacaoProfessor = function() {
            return $q(function(resolve, reject) {
                Turmas.tiposContratacaoProfessor().$promise.then(function(data) {
                    var tiposContratacaoProfessor = _.map(data, function(item) {
                        return {
                            key: item.id,
                            value: item.name
                        };
                    });

                    resolve(tiposContratacaoProfessor);
                }, function(error) {
                    reject(error);
                });
            });
        };

        this.exportByUfAndMunicipioAndEscola = function (idAnoLetivo, idPrograma, idUf, idMunicipio, idEscola) {
            return $q(function (resolve, reject) {
                Turmas.exportByUfAndMunicipioAndEscola({ idAnoLetivo: idAnoLetivo, idPrograma: idPrograma, idUf: idUf, idMunicipio: idMunicipio, idEscola: idEscola }).$promise
                  .then(function (file) {
                      fileService.download(file);
                      resolve();
                  })
                  .catch(function (error) {
                      reject(error);
                  });
            });
        };

        this.exportByUfAndMunicipio = function (idAnoLetivo, idPrograma, idUf, idMunicipio) {
            return $q(function (resolve, reject) {
                Turmas.exportByUfAndMunicipio({ idAnoLetivo: idAnoLetivo, idPrograma: idPrograma, idUf: idUf, idMunicipio: idMunicipio }).$promise
                  .then(function (file) {
                      fileService.download(file);
                      resolve();
                  })
                  .catch(function (error) {
                      reject(error);
                  });
            });
        };

        this.exportByUf = function (idAnoLetivo, idPrograma, idUf) {
            return $q(function (resolve, reject) {
                Turmas.exportByUf({ idAnoLetivo: idAnoLetivo, idPrograma: idPrograma, idUf: idUf }).$promise
                  .then(function (file) {
                      fileService.download(file);
                      resolve();
                  })
                  .catch(function (error) {
                      reject(error);
                  });
            });
        };

        this.export = function (idAnoLetivo, idPrograma) {
            return $q(function (resolve, reject) {
                Turmas.export({ idAnoLetivo: idAnoLetivo, idPrograma: idPrograma }).$promise
                  .then(function (file) {
                      fileService.download(file);
                      resolve();
                  })
                  .catch(function (error) {
                      reject(error);
                  });
            });
        };

        this.import = function (file) {
            var url = APP_PROPERTY.URL + '/turmas/importar/arquivo_excel';
            return $q(function (resolve, reject) {
                fileService.uploadAsArrayBuffer(url, file)
                  .then(function (response) {
                      resolve(response);
                  })
                  .catch(function (error) {
                      var customError = {};
                      switch (error.status) {
                          case HTTP_STATUS_CODE.PRECONDITION_FAILED:
                              customError.messages = error.data;
                              break;
                          case HTTP_STATUS_CODE.BAD_REQUEST:
                              customError.formConstraints = error.data;
                              break;
                          default:
                              customError.messages = [error.data.message];
                      }
                      reject(customError);
                  });
            });
        };

    }

}());
