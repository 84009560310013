/*
jslint
    devel: true, maxerr: 10
*/

/*
global
    required: true
*/

/*
 * Home Module
 * @description The home definition of the application, when user is authenticated.
 * @author iFactory Solutions Ltda.
 * @version 1.0.0
 * @since 1.0.0
 */
(function() {
    'use strict';

    angular.module('app.home', ['app.core', 'app.layout']).config(config);
    config.$inject = ['$stateProvider', 'APP_STATE'];

    function config($stateProvider, APP_STATE) {
        $stateProvider
            .state(APP_STATE.HOME.BASE, {
                url: '/',
                controller: 'HomeController',
                controllerAs: 'vm',
                views: {
                    'content': {
                        templateUrl: 'home/partials/home.tpl.html'
                    }
                }
            });
    }

}());