(function(){
    'use strict';

    angular.module('app.escala').controller('EscalaFormController', EscalaFormController);
    EscalaFormController.$inject = ['APP_STATE', 'TABLE_FIELDS', 'APP_EVENT', 'COMMON_STATUS_LIST',
        '$state', '$scope', 'escalaService', 'toastService', '$rootScope'];

    function EscalaFormController(APP_STATE, TABLE_FIELDS, APP_EVENT, COMMON_STATUS_LIST,
        $state, $scope, escalaService, toastService, $rootScope) {

        var vm = this;

        var _init = function() {
            vm.salvarContinuar = false;
            vm.fields = TABLE_FIELDS.ESCALA;
            vm.editMode = false;
            vm.editPage = false;
            vm.hasScale = false;
            vm.refreshSearch = false;
            vm.escalas = [];
            vm.items = [];
            vm.newItems = [];
            vm.defaultStatus = COMMON_STATUS_LIST[0];
            vm.escalaUsed = false;

            vm.modalTemplateUrl = "escala/partials/escala-form-item-modal.html";
            vm.modalDependencies = {
                statusList: COMMON_STATUS_LIST,
                itemEscala: {
                    situacao: vm.defaultStatus
                },
                save: _saveModalItem
            };

            if ($state.is(APP_STATE.ESCALA.EDIT)) {
                vm.editPage = true;

                if ($state.params.id) {
                    escalaService.get($state.params.id)
                        .then(function(escala) {
                            vm.escala = escala;

                            if (vm.escala.escalaItensDTO) {
                                vm.items = angular.copy(vm.escala.escalaItensDTO);
                            }

                            if (is.truthy($state.params.editMode)) {
                                    vm.enableEditMode();
                            }

                        }, function(response) {
                            toastService.error(response);
                        });
                } else {
                    vm.goBack();
                }
            } else {
                vm.enableEditMode();
                vm.escala = {};
                vm.escala.situacao = vm.defaultStatus;
            }
        };

        var _saveModalItem = function(modalForm){
            vm.registerForm.$setDirty();
            this.ok(modalForm);
        }

        vm.goBack = function() {
            $state.go(APP_STATE.ESCALA.SEARCH);
        };

        vm.deleteFieldScale = function() {
            delete vm.escala.escalaDTO;
        };

        vm.save = function() {
            if (vm.registerForm.$valid) {
                if ((vm.items.length <= 0) && (vm.newItems.length <= 0)) {
                    toastService.error("Pelo menos um item de escala deve ser incluído.");
                } else {
                    escalaService.save(vm.escala, vm.items, vm.newItems)
                        .then(function(escala) {
                            $scope.$emit(APP_EVENT.ESCALA.SAVED);

                            if (!vm.salvarContinuar) {
                                vm.goBack();
                            } else {
                                _init();
                                //Irá enviar notificação para limpar os itens selecionados
                                $rootScope.$broadcast(APP_EVENT.GENERIC.CLEAR_ITEMS_SELECTED)
                                vm.registerForm.$setPristine();
                                $state.go(APP_STATE.ESCALA.EDIT, { id: escala.id, editMode: true });
                            }
                        }, function(response) {
                            toastService.error(response);
                        });
                }
            }
        };

        var _saveAndContinue = function(createNewItems) {
            if (vm.registerForm.$valid) {
                var newItems = (createNewItems) ? vm.newItems : [];

                escalaService.save(vm.escala, vm.items, newItems)
                    .then(function(escala) {
                        if (escala.escalaItensDTO) {
                            vm.items = angular.copy(escala.escalaItensDTO);
                        }

                        vm.refreshSearch = true;
                        vm.registerForm.$setPristine();

                        if (createNewItems) {
                            vm.newItems = [];
                        }

                        vm.escala = escala;
                        toastService.success("Operação realizada com sucesso.");

                    }, function(response) {
                        vm.refreshSearch = false;
                        toastService.error(response);
                    });
            }
        };

        vm.saveItems = function() {
            _saveAndContinue(true);
        };

        vm.updateItems = function() {
            _saveAndContinue();
        };

        vm.enableEditMode = function() {
            if (vm.escala != undefined && vm.escala !== null && vm.escala.id != undefined && vm.escala.id !== null) {
                escalaService.isUsed(vm.escala.id).then(function(response) {
                    if (response.responseStatusCode == 200) {
                        vm.escalaUsed = true;
                    } else if (response.responseStatusCode == 204) {
                        vm.escalaUsed = false;
                    }
                });
            }
            vm.editMode = true;
        };

        vm.cancel = function() {
            if (vm.refreshSearch) {
                $scope.$emit(APP_EVENT.ESCALA.REFRESH);
            }

            vm.goBack();
        };

        (function() {
            _init();
        }());
    }

}());
