(function() {
    'use strict';

    angular.module('app.core').constant('FORM_MESSAGES', {
      success: {
        updated: 'Alteração concluida com sucesso.',
      },
      error: {
        updated: 'Alteração não concluida com sucesso.',
  
      },
      info: {
        importTitle: 'Importar Alunos'
      },
      warning: {
        deleteResponses: 'Caso algum registro já exista a importação irá ignorá-los.'
      }        
    });

}());

