(function() {
    'use strict';

    angular.module('app.core').directive('iasMinlengthValidation', IasMinlengthValidation);

    function IasMinlengthValidation() {
        return {
            require: 'ngModel',
            restrict: 'A',
            link: function(scope, element, attr, controller) {
                var regex;
                var minlength = attr.ngMinlength || attr.minlength || attr.iasMinlengthValidation;

                attr.$observe('minlength', function(newMinlength) {
                    regex = new RegExp("(\\s*\\S\\s*){" + newMinlength + ",}");
                });

                if (minlength) {
                    controller.$validators.customMinlength = function(modelValue, viewValue) {
                        var value = modelValue || viewValue;

                        if (value && regex) {
                            return regex.test(value);
                        } else {
                            return true;
                        }
                    };
                } else {
                    throw "Minlength is required";
                }
            }
        };
    }

})();