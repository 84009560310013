(function() {
    'use strict';

    angular.module('app.core').directive('iasMinValue', IasMinValueValidation);

    function IasMinValueValidation() {
        return {
            require: 'ngModel',
            restrict: 'A',
            link: function(scope, element, attr, controller) {
                var minValue = attr.iasMinValue;

                attr.$observe('iasMinValue', function(newMinValue) {
                    minValue = newMinValue;
                });

                if (minValue) {
                    controller.$validators.iasMinValue = function(modelValue, viewValue) {
                        var value = modelValue || viewValue;

                        if (value && minValue) {
                            if (is.integer(value)) {
                                return parseInt(value) >= parseInt(minValue);
                            } else {
                                return parseFloat(value) >= parseFloat(minValue);
                            }
                        } else {
                            return true;
                        }
                    };
                }
            }
        };
    }

})();