/*
jslint
    devel: true, maxerr: 10
*/

/*
global
    required: true
*/

/*
 * Relatórios Module
 * @controller Class Profile Report Controller
 * @author iFactory Solutions Ltda.
 * @version 1.0.0
 * @since 1.0.0
 */ 
(function() {
    'use strict';

    angular.module('app.relatorios').controller('RelatorioAcompanhamentoMensalController', RelatorioAcompanhamentoMensalController);
    RelatorioAcompanhamentoMensalController.$inject = ['$state', 'APP_STATE', 'RelatoriosService', 'toastService', 'programasService', 'REPORT_GROUP', 'REPORT_PROGRAMS'];

    function RelatorioAcompanhamentoMensalController($state, APP_STATE, reportService, toastService, programasService, REPORT_GROUP, REPORT_PROGRAMS) {
        var vm = this;
        
        var _clearReportResult = function() {
            vm.result.reportDataCount = 0;
            vm.result.reportFields = [];
            vm.result.reportData = [];
            vm.result.calculations = {};
        };

        var _clearTotalization = function() {
            vm.filter.totalization = vm.model.totalization[0];
            delete vm.filter.yearTotalizationFinal;
            delete vm.filter.monthTotalizationFinal;
        };

        vm.calcularPorcentagem = function (field, value, somatoriasPorcentagem) {


            if(['motivoFaltaMotivoDoenca', 'motivoFaltaDificuldadeDeslocamento', 
            'motivoFaltaQuestoesSociais', 'motivoFaltaQuestoesPessoais', 'motivoFaltaMotivoFaltaApoio',
        'motivoFaltaTrabalhoInfantil'].indexOf(field) !== -1) {
            if(somatoriasPorcentagem.motivoFalta == 0 || somatoriasPorcentagem.motivoFalta == undefined) {
                return 0;
            }
            if(value == undefined) {
                return 0;
            }
            return Number(Math.round(((value / somatoriasPorcentagem.motivoFalta) *100)+'e'+1)+'e-'+1) +'%';
        }

        if(['vl_nivel_pt_inicio_1', 'vl_nivel_pt_inicio_2', 
            'vl_nivel_pt_inicio_3', 'vl_nivel_pt_inicio_4'].indexOf(field) !== -1) {
                if(somatoriasPorcentagem.portugues == 0 || somatoriasPorcentagem.portugues == undefined) {
                    return 0;
                }
                if(value == undefined) {
                    return 0;
                }
                return Number(Math.round(((value / somatoriasPorcentagem.portugues) *100)+'e'+1)+'e-'+1) +'%';
        }

        if(['vl_nivel_mt_inicio_1', 'vl_nivel_mt_inicio_2', 
        'vl_nivel_mt_inicio_3', 'vl_nivel_mt_inicio_4'].indexOf(field) !== -1) {
            if(somatoriasPorcentagem.matematica == 0 || somatoriasPorcentagem.matematica == undefined) {
                return 0;
            }
            if(value == undefined) {
                return 0;
            }
            return Number(Math.round(((value / somatoriasPorcentagem.matematica) *100)+'e'+1)+'e-'+1) +'%';
        }

            /*console.log('iniciopc');
            console.log(field);
            console.log(somatoriasPorcentagem);
            console.log('fimpc');*/
        }

        vm.changeDetaling = function(detailingId){
            vm.view.searchWasPerformed = false;
            vm.filter.stateUFRegional = detailingId !== 'U';
            if (!vm.filter.stateUFRegional) {
                vm.model.stateRegionals = is.existy(vm.filter.state) ? reportService.loadStateRegionalsOfState(vm.filter.state.id) : [];
                vm.model.cities = [];
                vm.model.cityRegionals = [];
                vm.model.schools = [];
            } else {
                vm.changeState();
            }
            
        }

        vm.changeState = function() {
            if (is.existy(vm.filter.state)) {
                vm.model.cities = reportService.loadCitiesOfState(vm.filter.state.id);
                vm.model.stateRegionals = reportService.loadStateRegionalsOfState(vm.filter.state.id);
            } else {
                vm.model.stateRegionals = [];
                vm.model.cities = [];
                vm.model.cityRegionals = [];
                vm.model.schools = [];
            }
        };

        vm.changeStateRegional = function() {
            if (is.existy(vm.filter.stateRegional)) {
                vm.model.cities = reportService.loadCitiesOfStateRegional(vm.filter.stateRegional.id);
            } else {
                // TODO Guardar a cidade anteriormente selecionada, caso haja; refazer a consulta pela UF (se houver uma selecioanda) 
                // e já deixar selecionada a cidade previamente selecionada.
                if (is.existy(vm.filter.state)) {
                    vm.model.cities = reportService.loadCitiesOfState(vm.filter.state.id);
                } else {
                    vm.model.cities = [];
                }
                vm.model.cityRegionals = [];
                vm.model.schools = [];
            }
        };

        vm.changeCity = function() {
            if (is.existy(vm.filter.city)) {
                vm.model.cityRegionals = reportService.loadCityRegionalsOfCity(vm.filter.city.id);
                vm.model.schools = reportService.loadSchoolsOfCity(vm.filter.city.id);
            } else {
                vm.model.cityRegionals = [];
                vm.model.schools = [];
            }
        };

        vm.changeSchoolType = function() {
            if (is.existy(vm.filter.city) && is.existy(vm.filter.schoolType)) {
                vm.model.schools = reportService.loadSchoolByFilter(vm.filter);
            } else if (is.existy(vm.filter.city)) {
                vm.model.schools = reportService.loadSchoolsOfCity(vm.filter.city.id);
            } else {
                vm.model.cityRegionals = [];
                vm.model.schools = [];
            }
        };

        vm.changeSchoolLocalization = function() {
            if (is.existy(vm.filter.city) && is.existy(vm.filter.schoolLocalization)) {
                vm.model.schools = reportService.loadSchoolByFilter(vm.filter);
            } else if (is.existy(vm.filter.city)) {
                vm.model.schools = reportService.loadSchoolsOfCity(vm.filter.city.id);
            } else {
                vm.model.cityRegionals = [];
                vm.model.schools = [];
            }
        };

        vm.changeSchoolAdministrativeDependency = function() {
            if (is.existy(vm.filter.schoolAdministrativeDependency) && is.existy(vm.filter.schoolAdministrativeDependency)) {
                vm.model.schools = reportService.loadSchoolByFilter(vm.filter);
            } else if (is.existy(vm.filter.city)) {
                vm.model.schools = reportService.loadSchoolsOfCity(vm.filter.city.id);
            } else {
                vm.model.cityRegionals = [];
                vm.model.schools = [];
            }
        };

        vm.changeProgram = function() {
            if (is.existy(vm.filter.program)) {
                vm.filter.model = vm.filter.program.model;
                vm.filter.option = vm.filter.program;
            } else {
                vm.filter.model = null;
            }
            if (is.existy(vm.result.report.events) && is.existy(vm.result.report.events.onChangeProgram)) {
                vm.result.report.events.onChangeProgram({ report: vm.result.report }, vm.filter.program);
            }

            _clearTotalization();
        };

        vm.changeMonitoring = function() {
            if (is.existy(vm.result.report.events) && is.existy(vm.result.report.events.onChangeMonitoring)) {
                vm.result.report.events.onChangeMonitoring({ report: vm.result.report }, vm.filter.typeMonitoring, vm.filter.typeMonitoringTypeReport);
            }  
        }

        vm.generateReport = function() {
            vm.view.searchWasPerformed = true;
            vm.result.calculations = [];

            reportService.generateReportResult(vm.filter, vm.result);
        }; 

        vm.formatColumn = function(item, field) {
            var totalizacao = angular.copy(vm.filter.totalization.id);
            if(totalizacao === 'accumulated'){
                if(field.name === 'andamentoDiasLetivosDadosMedia' || field.name === 'diasLetivosDadosMedia'){
                    field.hide = true;
                }
                if(field.name === 'andamentoDiasLetivosDados' || field.name === 'diasLetivosDados'){
                    field.hide = false;
                }
                if(field.name === 'andamentoDiasLetivosPrevistosMedia' || field.name === 'diasLetivosPrevistosMedia'){
                    field.hide = true;
                }
                if(field.name === 'andamentoDiasLetivosPrevistos' || field.name === 'diasLetivosPrevistos'){
                    field.hide = false;
                }
            } else  {
                if(field.name === 'andamentoDiasLetivosDadosMedia' || field.name === 'diasLetivosDadosMedia'){
                    field.hide = false;
                }
                if(field.name === 'andamentoDiasLetivosDados' || field.name === 'diasLetivosDados'){
                    field.hide = true;
                }
                if(field.name === 'andamentoDiasLetivosPrevistosMedia' || field.name === 'diasLetivosPrevistosMedia'){
                    field.hide = false;
                }
                if(field.name === 'andamentoDiasLetivosPrevistos' || field.name === 'diasLetivosPrevistos'){
                    field.hide = true;
                }
            }

            return reportService.formatColumn(item, field, vm.result);
        };

        vm.formatSummaryColumn = function(field) {
            return reportService.formatSummaryColumn(vm.result.calculations, field, vm.result);
        };

        vm.formatSummaryColumnSpecific = function(field, result) {
            //if(field.calculateType == 'average') {
            //    var value = reportService.formatSummaryColumn(vm.result.calculations, field, vm.result);
            //    value = value.replace(",", ".");
            //    return Number(value);    
            //}
            //return reportService.formatSummaryColumn(vm.result.calculations, field, vm.result);
            
            if(typeof result.somatorias == 'undefined'
                || typeof result.somatorias[field.sequencia] == 'undefined') {
                return 0;
            }
            if (field.calculateType == 'average') {
                if(field.referenceField && field.referenceTotalField) {
                    if(result.somatorias[field.referenceTotalField] == 0 ) {
                        return 0;
                    }
                    console.log("dividindo "+result.somatorias[field.referenceField]+' por '+result.somatorias[field.referenceTotalField])
                  return Number(Math.round((result.somatorias[field.referenceField] / result.somatorias[field.referenceTotalField])*100+'e'+1)+'e-'+1);
                }
                var value = result.somatorias[field.sequencia] / result.reportDataCount;
                return Number(Math.round(value+'e'+1)+'e-'+1);
            }

            return result.somatorias[field.sequencia];
        
        };

        vm.performCalculationSpecific = function(field, item, qtd, result) {
            if(undefined == field.sequencia) {
                return;
            }
            if(undefined == result.somatorias) {
                result.somatorias = new Array(qtd);
            }
            if(undefined == result.somatorias[field.sequencia]) {
                result.somatorias[field.sequencia] = 0.0;
            }
            var variavel = reportService.formatColumn(item, field, vm.result).toString().replace(",", ".");
            variavel = Number(variavel);
            result.somatorias[field.sequencia] = variavel + result.somatorias[field.sequencia];
            //console.log(field.name +' - '+  result.somatorias[field.sequencia]);
            //antigo
            var oldValue = vm.result.calculations[field.name];
            vm.result.calculations[field.name] = reportService.performatCalculation(item, field, oldValue, vm.result);
        };

         vm.formatSummaryColumnPerc = function(field) {
            var reportSumLastField = vm.formatSummaryColumn(vm.result.reportFields[vm.result.reportFields.length - 1]);
            return reportService.formatSummaryColumnPerc(vm.result.calculations, field, vm.result,reportSumLastField);
        };

        vm.performCalculation = function(field, item) {
            var oldValue = vm.result.calculations[field.name];
            vm.result.calculations[field.name] = reportService.performatCalculation(item, field, oldValue, vm.result);
        };


        vm.showResultReport = function(reportResultInfo) {
            var result = vm.result.reportDataCount > 0 && vm.view.searchWasPerformed;
            if (result && is.existy(reportResultInfo)) {
                if (is.existy(reportResultInfo.reportId)) {
                    result = result && is.existy(vm.result) && is.existy(vm.result.report) && vm.result.report.id == reportResultInfo.reportId;
                }
                if (is.existy(result && reportResultInfo.modelId)) {
                    result = result && is.existy(vm.filter) && is.existy(vm.filter.model) && vm.filter.model.id == reportResultInfo.modelId;
                }
            }
            return result;
        };

        vm.showFooter = function(fieldInfo) {
            var result = is.not.existy(fieldInfo.showFooter) || fieldInfo.showFooter;
            return result;
        };

        vm.isMonthlyTotalizationEnabled = function() {
            return vm.filter.totalization.id == vm.model.totalization[0].id;
        };

        vm.isAccumulatedTotalizationEnabled = function() {
            return vm.filter.totalization.id == vm.model.totalization[1].id;
        };

        vm.isModelValid = function() {
            var result = true;
            if (is.existy(vm.result.report.constraints) && is.truthy(vm.result.report.constraints.model)) {
                result = is.existy(vm.filter.model);
            }
            return result;
        };

        vm.isTypeReportValid = function() {
            var result = true;
            if (is.existy(vm.result.report.constraints) && is.truthy(vm.result.report.constraints.typeReport)) {
                result = is.existy(vm.filter.typeReport);
            }
            return result;
        };

        vm.isFilterValid = function() {
            return vm.reportForm.$valid && vm.isModelValid() && vm.isTypeReportValid();
        };

        vm.onChangeMesInicio = function() {
            if(!vm.filter || !vm.filter.mesInicio) {
                return;
            }

            var idMonth = vm.filter.mesInicio.id;

            vm.model.mesFimMonths = [];
            angular.forEach(reportService.loadMonths(), function(month) {
                if(month.id >= idMonth) {
                    vm.model.mesFimMonths.push(month);
                }
            });
        };

        vm.onChangeMonitoring = function() {
            if(!vm.filter || !vm.filter.monitoring) {
                return;
            }

            var id = vm.filter.monitoring.id;

            if(vm.filter.monitoringTypeReport.id == '1') {
                vm.filter.form = null;
            }
            vm.result.reportData = [];
            vm.view.searchWasPerformed = false;

            if(id == 'C') {
                vm.filter.monitoringTypeReport = vm.model.monitoringTypeReport[1];
            }
            
            if((id == 'I' || id == 'II') && vm.filter.monitoringTypeReport.id == '1') {
                vm.model.programsMediador = [
                    {id: '-1', nome: 'Se Liga/Acelera Brasil/Circuito Campeão'}
                ];
                vm.filter.program = {id: '-1'};
            } else if((id == 'A' || id == 'B') && vm.filter.monitoringTypeReport.id == '1') {
                vm.model.programsMediador = [
                    {id: '1', nome: 'Se Liga'},
                    {id: '2', nome: 'Acelera Brasil'}
                ];
            } else if((id == 'A' || id == 'B' || id == 'C') && vm.filter.monitoringTypeReport.id == '2') {
                vm.model.programsMediador = [
                    {id: '1', nome: 'Se Liga'},
                    {id: '2', nome: 'Acelera Brasil'},
                    {id: '11', nome: 'Gestão Nota 10'},
                    {id: '26', nome: 'Circuito Campeão'}
                ];
            } else if((id == 'I' || id == 'II') && vm.filter.monitoringTypeReport.id == '2') {
                vm.model.programsMediador = [
                    {id: '-1', nome: 'Se Liga/Acelera Brasil/Circuito Campeão'}
                ];
                vm.filter.program = {id: '-1'};
            } else {
                vm.model.programsMediador = [];
            }

            if(id == 'B' || id == 'II' || ((id == 'II' || id == 'C') && vm.filter.monitoringTypeReport.id == '2')) {
                vm.filter.schoolType = vm.view.allSchoolType;
                vm.filter.schoolLocalization = vm.view.allSchoolLocalization;
                vm.filter.schoolAdministrativeDependency = vm.view.allSchoolAdministrativeDependency;
                vm.filter.cityRegional = null;
                vm.filter.school = null;
            }

            if((id == 'A' || id == 'B' || id == 'I' || id == 'II' || id == 'C') && vm.filter.monitoringTypeReport.id == '2') {
                vm.loadForms();
            }
        };

        vm.loadForms = function() {
            if(vm.filter.monitoringTypeReport.id == '2' 
                && vm.filter.program && vm.filter.program.id != null 
                && vm.filter.monitoring && vm.filter.monitoring.id != null
                && (vm.filter.monitoring.id == 'A' || vm.filter.monitoring.id == 'B' || vm.filter.monitoring.id == 'I' || vm.filter.monitoring.id == 'II' || vm.filter.monitoring.id == 'C')) {
                
                if(vm.filter.program.id == '-1' && !(vm.filter.monitoring.id == 'I' || vm.filter.monitoring.id == 'II')) {
                    vm.filter.form = null;
                    return;    
                }

                vm.model.forms = reportService.loadFormsByFilter(vm.filter);
            } else {
                vm.filter.form = null;
            }
        };

        vm.totalization = function() {
                _clearTotalization();
        };

        (function() {
            vm.model = {
                states: [],
                stateRegionals: [],
                cities: [],
                grupoMunicipioIas: [],
                cityRegionals: [],
                cityDistricts: [],
                schools: [],
                modeloRel: [],
                schoolYears: [],
                schoolTypes: [],
                schoolLocalizations: [],
                schoolAdministrativeDependencies: [],
                partnershipTypes: [],
                programs: [],
                programsPortuguesFormulaVitoria:[],
                programsMatematicaNv1:[],
                programsMatematicaNv2:[],
                programsAlfabetizacaoAceleracao:[],
                programsAAFV:[],
                programsLeituraEscrita:[],
                programsLeituraEscritaProducaoTexto:[],
                programsMediador:[],
                classes: [],
                classSeries: [],
                classShifts: [],
                classTypes: [],
                months: [],
                programsFluxoOficinas: [],
                classMultiserie: [
                    {
                        key: 'false',
                        value: "Regular"    
                    },
                    {
                        key: 'true',
                        value: "Multisseriada"    
                    },
                ],
                detailings: [{
                        id: "U",
                        name: "Estado"
                    },
                    {
                        id: "M",
                        name: "Município"
                    },
                    {
                        id: "E",
                        name: "Escola"
                    },
                    {
                        id: "T",
                        name: "Turma"
                    },
                    {
                        id: "G",
                        name: "Regional UF"
                    },
                    {
                        id: "R",
                        name: "Regional Municipal"
                    },
                    {
                        id: "8",
                        name: "Grupo de Município IAS"
                    }
                ],
                detailingsRelatorios: [{
                    id: "U",
                    name: "Estado"
                },
                {
                    id: "M",
                    name: "Município"
                },
                {
                    id: "E",
                    name: "Escola"
                },
                {
                    id: "T",
                    name: "Turma"
                },
                {
                    id: "G",
                    name: "Regional UF"
                },
                {
                    id: "R",
                    name: "Regional Municipal"
                },
                {
                    id: "8",
                    name: "Grupo de Município IAS"
                }
                ],
                totalization: [{
                        id: "monthly",
                        name: "Mensal"
                    },
                    {
                        id: "accumulated",
                        name: "Acumulada"
                    }
                ],
                monitoring: [
                    {
                        id: "A",
                        name: "A"
                    },
                    {
                        id: "B",
                        name: "B"
                    },
                    {
                        id: "C",
                        name: "C"
                    },
                    {
                        id: "I",
                        name: "I"
                    },
                    {
                        id: "II",
                        name: "II"
                    }
                ],
                monitoringTypeReport: [
                    {
                        id: "1",
                        name: "Quantitativo"
                    },
                    {
                        id: "2",
                        name: "Qualitativo"
                    }
                ]
            };
            vm.filter = {
                anoEscolar: null,
                state: null,
                stateRegional: null,
                city: null,
                cityRegional: null,
                cityDistrict: null,
                schoolYear: null,
                modeloRel: null,
                schoolType: null,
                schoolLocalization: null,
                schoolAdministrativeDependency: null,
                partnershipType: null,
                detailing: null,
                shift: null,
                program: null,
                totalization: null,
                model: null,
                grupoMunicipioIas: null,
                class: null,
                classShift: null,
                classType: null,
                classSerie: null,
                yearTotalization: null,
                monthTotalization: null,
                yearTotalizationInitial: null,
                monthTotalizationInitial: null,
                yearTotalizationFinal: null,
                monthTotalizationFinal: null,
                mesInicio:null,
                mesFim:null,
                typeMonitoring: null,
                typeMonitoringTypeReport: null,
                stateUFRegional: true,
                monitoring: null,
                monitoringTypeReport: { id: "1", name: "Quantitativo" }
            };

            

            vm.view = {
                available: true,
                searchWasPerformed: false,
                currentPage: null,
                allSchoolType: {
                    id: '0',
                    name: 'Todos'
                },
                allSchoolLocalization: {
                    id: '0',
                    nome: 'Todas'
                },
                allSchoolAdministrativeDependency: {
                    id: '0',
                    nome: 'Todas'
                },
                allClasses: {
                    key: '0',
                    value: 'Todas'
                },
                allClassMultiserie: {
                    key: '0',
                    value: 'Todos'
                },
                allClassShift: {
                    key: '0',
                    value: 'Todos'
                },
                allClassType: {
                    key: '0',
                    value: 'Todos'
                },
                disableYearMonth: false
            };
            vm.result = {
                report: null,
                reportDataCount: 0,
                reportFields: [],
                reportData: [],
                calculations: {}
            };

            vm.model.states = reportService.loadStates();
            
            
            reportService.loadMunicipiosIAS().then(function(data){
                vm.model.grupoMunicipioIas = data.data
            })
            
            vm.printDados = function(){
                console.log(vm.model)
                console.log(vm.filter)
            }

            vm.model.schoolYears = reportService.loadSchoolYears();
            vm.model.schoolTypes = reportService.loadShoolTypes();
            vm.model.schoolLocalizations = reportService.loadLocalizations();
            vm.model.schoolAdministrativeDependencies = reportService.loadAdministrativeDependencies();
            vm.model.months = reportService.loadMonths();
            vm.model.mesInicioMonths = reportService.loadMonths();
            vm.model.mesFimMonths = reportService.loadMonths();
            reportService.loadClassShifts().then(function(data) {
                vm.model.classShifts = angular.copy(data);
            }, function() {
                vm.model.classShifts = [];
            });
            reportService.loadClassTypes().then(function(data) {
                vm.model.classTypes = angular.copy(data);
            }, function() {
                vm.model.classTypes = [];
            });
            vm.model.classes = reportService.loadClasses();

            // Por padrão, o detalhamento do relatório é feito por turma.
            vm.filter.detailing = vm.model.detailings[3];
            vm.filter.detailingsRelatorios = vm.model.detailings[3];
            // Por padrão, o tipo da escola é "Todos".
            vm.filter.schoolType = vm.view.allSchoolType;
            // Por padrão, a localização da escola é "Todas".
            vm.filter.schoolLocalization = vm.view.allSchoolLocalization;
            // Por padrão, a dependência administrativa da escola é "Todas".
            vm.filter.schoolAdministrativeDependency = vm.view.allSchoolAdministrativeDependency;
            // Por padrão, a totalização do relatório é feito por mês.
            vm.filter.totalization = vm.model.totalization[0];
            // Por padrão, o tipo de turma é "Todos".
            vm.filter.classType = vm.view.allClassType;
            // Por padrão o tipo de relatório de acompanhamento é qualitativo
            vm.filter.monitoringTypeReport = vm.model.monitoringTypeReport[0];
            
            // Por padrão, o tipo de turma é "Todos".
            vm.filter.classMultiserie = vm.view.allClassMultiserie;

            // TODO Realizar o tratamento adequado para o caso de não ter o report no parâmetro do $state (avisar ou já redirecionar para a tela base?)
            vm.result.report = $state.params.report;

            if (is.not.existy(vm.result.report)) {
                vm.view.available = false; // TODO Espalhar em toda a interface esse controle.
                toastService.error('Não foi possível recuperar as informações do relatório.');
            } else {
                reportService.loadPrograms({ reportId: vm.result.report.id }).then(function(data) {
                    vm.model.programs = data;
                });
            }

            programasService.ativosRelatorio().then(function(programsFluxoOficinas){
                angular.forEach(programsFluxoOficinas, function(value){
                    if (_.indexOf(REPORT_PROGRAMS.IDS_FORMULA_VITORIA, value.id) != -1) {
                        vm.model.programsFluxoOficinas.push({
                            id: value.id,
                            nome: value.nome
                        });
                    }
                });
            });
            
            programasService.ativosRelatorio().then(function(programsMatematicaNv1){   
                angular.forEach(programsMatematicaNv1, function(value){  
                    // Programa: Fórmula da Vitória Matemática Nível 1 ID: 5
                    if (is.existy(vm.result.report)  && 
                        vm.result.report.id == REPORT_GROUP.ACOMPANHAMENTO_MENSAL.LEITURA_ESTRATEGIA_COMPREENSAO_MAT_NV_1 && value.id == 5) { 
                        vm.filter.program = {
                            id: 5
                        }
                        vm.model.programsMatematicaNv1.push({
                            id: value.id,
                            nome: value.nome
                        });
                    }
                    if (is.existy(vm.result.report)  && 
                        vm.result.report.id == REPORT_GROUP.ACOMPANHAMENTO_MENSAL.FORMULA_VITORIA_LINGUA_PORTUGUESA_ACOMPANHAMENTO && value.id == 3) { 
                        vm.filter.program = {
                            id: 3
                        }
                        vm.model.programsPortuguesFormulaVitoria.push({
                            id: value.id,
                            nome: value.nome
                        });
                    }
                });
            });

            programasService.ativosRelatorio().then(function(programsMatematicaNv2){   
                angular.forEach(programsMatematicaNv2, function(value){  
                    // Programa: Fórmula da Vitória Matemática Nível 2 ID: 6
                    if (is.existy(vm.result.report)  && 
                        vm.result.report.id == REPORT_GROUP.ACOMPANHAMENTO_MENSAL.LEITURA_ESTRATEGIA_COMPREENSAO_MAT_NV_2 && value.id == 6) { 
                        vm.filter.program = {
                            id: 6
                        }
                        vm.model.programsMatematicaNv2.push({
                            id: value.id,
                            nome: value.nome
                        });
                    }
                });
            });

            programasService.ativosRelatorio().then(function(programsAlfabetizacaoAceleracao){
                angular.forEach(programsAlfabetizacaoAceleracao, function(value){
                    if (_.indexOf(REPORT_PROGRAMS.IDS_ALFABETIZACAO_ACELERACAO, value.id) != -1) {
                        vm.model.programsAlfabetizacaoAceleracao.push({
                            id: value.id,
                            nome: value.nome
                        });
                    }
                });
            });
            
            programasService.ativosRelatorio().then(function(programsAAFV){
                var idsProgramas = REPORT_PROGRAMS.IDS_ALFABETIZACAO_ACELERACAO;
                idsProgramas = idsProgramas.concat(REPORT_PROGRAMS.IDS_FORMULA_VITORIA);
                idsProgramas = idsProgramas.concat(REPORT_PROGRAMS.IDS_GESTAO_ALFABETIZACAO);
                idsProgramas = idsProgramas.concat(REPORT_PROGRAMS.IDS_SISTEMATICA_ACOMPANHAMENTO_DIALOGOS);
                idsProgramas = idsProgramas.concat(REPORT_PROGRAMS.IDS_CIRCUITO_CAMPEAO);
                idsProgramas = idsProgramas.concat(REPORT_PROGRAMS.IDS_GESTAO_NOTA_10_INICIAIS);
                idsProgramas = idsProgramas.concat(REPORT_PROGRAMS.IDS_GESTAO_NOTA_10_6_ANO_AO_9_ANO);
                
                angular.forEach(programsAAFV, function(value){
                    if (_.indexOf(idsProgramas, value.id) != -1) {
                        vm.model.programsAAFV.push({
                            id: value.id,
                            nome: value.nome
                        });
                    }
                });
            });
            
            programasService.ativosRelatorio().then(function(programsLeituraEscrita){   
                angular.forEach(programsLeituraEscrita, function(value){
                    if (is.existy(vm.result.report)  && 
                        vm.result.report.id == REPORT_GROUP.ACOMPANHAMENTO_MENSAL.LEITURA_ESCRITA_ORALIDADE_TEXTO && value.id == 1) { 
                        vm.filter.program = {
                            id: 1
                        }

                        vm.model.programsLeituraEscrita.push({
                            id: value.id,
                            nome: value.nome
                        });                        
                    }
                });
            });
            
            programasService.ativosRelatorio().then(function(programsLeituraEscritaProducaoTexto){   
                angular.forEach(programsLeituraEscritaProducaoTexto, function(value){
                    if (is.existy(vm.result.report)  && 
                        vm.result.report.id == REPORT_GROUP.ACOMPANHAMENTO_MENSAL.LEITURA_ESCRITA_PRODUCAO_TEXTO && value.id == 2) { 
                        vm.filter.program = {
                            id: 2
                        }

                        vm.model.programsLeituraEscritaProducaoTexto.push({
                            id: value.id,
                            nome: value.nome
                        });
                        
                    }
                });
            });

            programasService.ativosRelatorio().then(function(programsMediador){ 
                // var idsProgramas = REPORT_PROGRAMS.IDS_ALFABETIZACAO_ACELERACAO;
                // idsProgramas = idsProgramas.concat(REPORT_PROGRAMS.IDS_CIRCUITO_CAMPEAO);
                var idsProgramas = [1, 2];

                angular.forEach(programsMediador, function(value) {
                    if (is.inArray(value.id, idsProgramas)) {
                        vm.model.programsMediador.push({
                            id: value.id,
                            nome: value.nome
                        });
                    }
                });
            });

        })();
    }

    

}());