/*
jslint
    devel: true, maxerr: 10
*/

/*
global
    required: true
*/

/*
 * Relatórios Module
 * @module constants for class profile report
 * @author iFactory Solutions Ltda.
 * @version 1.0.0
 * @since 1.0.0
 */
(function() {
    'use strict';

    /* Acompanhamento de Leitura, Escrita e Oralidade/Produção de Texto: Acelera Brasil */
    angular.module('app.relatorios').constant('ACOMPANHAMENTO_MENSAL_REPORT_LEITURA_ESCRITA_ORALIDADE_TEXTO_MODEL_ACELERA_FIELDS', [
        { name: 'id', type: 'integer', calculateTotal: false, showFooter: false  },
        { name: 'detalhamento', type: 'string', calculateTotal: false, showFooter: false  },
        { name: 'totalTurmas', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'totalAlunos', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'leituraLeSilabandoTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'leituraLeSilabandoPercentual', percent: true, referenceField: 'leituraLeSilabandoTotal',
            referenceTotalField: 'totalAlunos',type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'leituraLePalavraPorPalavraTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'leituraLePalavraPorPalavraPercentual', percent: true, referenceField: 'leituraLePalavraPorPalavraTotal',
            referenceTotalField: 'totalAlunos', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'leituraLeComFluenciaTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'leituraLeComFluenciaPercentual', percent: true, referenceField: 'leituraLeComFluenciaTotal',
            referenceTotalField: 'totalAlunos', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'leituraTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'leituraTurmasColetadasTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'leituraTurmasColetadasPercentual', percent: true, referenceField: 'leituraTurmasColetadasTotal',
            referenceTotalField: 'totalTurmas', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'escritaEscreveSilabasSimplesNaoOrtografTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'escritaEscreveSilabasSimplesNaoOrtografPercentual', percent: true, referenceField: 'escritaEscreveSilabasSimplesNaoOrtografTotal',
            referenceTotalField: 'totalAlunos', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'escritaEscreveSilabasSimplesOrtografTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'escritaEscreveSilabasSimplesOrtografPercentual', percent: true, referenceField: 'escritaEscreveSilabasSimplesOrtografTotal',
            referenceTotalField: 'totalAlunos', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'escritaEscreveSilabasComplexasNaoOrtografTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'escritaEscreveSilabasComplexasNaoOrtografPercentual', percent: true, referenceField: 'escritaEscreveSilabasComplexasNaoOrtografTotal',
            referenceTotalField: 'totalAlunos', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'escritaEscreveSilabasComplexasOrtografTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'escritaEscreveSilabasComplexasOrtografPercentual', percent: true, referenceField: 'escritaEscreveSilabasComplexasOrtografTotal',
            referenceTotalField: 'totalAlunos', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'escritaTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'escritaTurmasColetadasTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'escritaTurmasColetadasPercentual', percent: true, referenceField: 'escritaTurmasColetadasTotal',
            referenceTotalField: 'totalTurmas', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'producaoTextoEscreveFrasesSoltasTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'producaoTextoEscreveFrasesSoltasPercentual', percent: true, referenceField: 'producaoTextoEscreveFrasesSoltasTotal',
            referenceTotalField: 'totalAlunos', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'producaoTextoEscreveTextoComecoMeioFimTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'producaoTextoEscreveTextoComecoMeioFimPercentual', percent: true, referenceField: 'producaoTextoEscreveTextoComecoMeioFimTotal',
            referenceTotalField: 'totalAlunos', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'producaoTextoEscreveFraseAmpliadaTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'producaoTextoEscreveFraseAmpliadaPercentual', percent: true, referenceField: 'producaoTextoEscreveFraseAmpliadaTotal',
            referenceTotalField: 'totalAlunos', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'producaoTextoTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'producaoTextoTurmasColetadasTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'producaoTextoTurmasColetadasPercentual', percent: true, referenceField: 'producaoTextoTurmasColetadasTotal',
            referenceTotalField: 'totalTurmas', type: 'integer', calculateTotal: true, calculateType: 'average' },
    ]);

    /* Acompanhamento de Leitura, Escrita e Oralidade/Produção de Texto: Se Liga */
    angular.module('app.relatorios').constant('ACOMPANHAMENTO_MENSAL_REPORT_LEITURA_ESCRITA_ORALIDADE_TEXTO_MODEL_SE_LIGA_FIELDS', [
        { name: 'id', type: 'integer', calculateTotal: false, showFooter: false  },
        { name: 'detalhamento', type: 'string', calculateTotal: false, showFooter: false  },
        { name: 'totalTurmas', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'totalAlunos', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'leituraAindaNaoLeTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'leituraAindaNaoLePercentual', percent: true, referenceField: 'leituraAindaNaoLeTotal', referenceTotalField: 'totalAlunos',
            type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'leituraRealizaLeituraPalavrasFrasesTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'leituraRealizaLeituraPalavrasFrasesPercentual', percent: true, referenceField: 'leituraRealizaLeituraPalavrasFrasesTotal',
            referenceTotalField: 'totalAlunos', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'leituraLocalizaInfoTextosCurtosTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'leituraLocalizaInfoTextosCurtosPercentual', percent: true, referenceField: 'leituraLocalizaInfoTextosCurtosTotal',
            referenceTotalField: 'totalAlunos', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'leituraLocalizaInfoTextosLongosTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'leituraLocalizaInfoTextosLongosPercentual', percent: true, referenceField: 'leituraLocalizaInfoTextosLongosTotal',
            referenceTotalField: 'totalAlunos', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'leituraTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'leituraTurmasColetadasTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'leituraTurmasColetadasPercentual', percent: true, referenceField: 'leituraTurmasColetadasTotal',
            referenceTotalField: 'totalTurmas', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'escritaEscreveSemCompreensaoTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'escritaEscreveSemCompreensaoPercentual', percent: true, referenceField: 'escritaEscreveSemCompreensaoTotal',
            referenceTotalField: 'totalAlunos', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'escritaEscrevePalavrasTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'escritaEscrevePalavrasPercentual', percent: true, referenceField: 'escritaEscrevePalavrasTotal',
            referenceTotalField: 'totalAlunos', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'escritaDesenvTemaParagrafosCurtosTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'escritaDesenvTemaParagrafosCurtosPercentual', percent: true, referenceField: 'escritaDesenvTemaParagrafosCurtosTotal',
            referenceTotalField: 'totalAlunos', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'escritaDesenvTemaParagrafosAmpliadosTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'escritaDesenvTemaParagrafosAmpliadosPercentual', percent: true, referenceField: 'escritaDesenvTemaParagrafosAmpliadosTotal',
            referenceTotalField: 'totalAlunos', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'escritaTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'escritaTurmasColetadasTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'escritaTurmasColetadasPercentual', percent: true, referenceField: 'escritaTurmasColetadasTotal',
            referenceTotalField: 'totalTurmas', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'oralidadeComunicaPoucaClarezaTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'oralidadeComunicaPoucaClarezaPercentual', percent: true, referenceField: 'oralidadeComunicaPoucaClarezaTotal',
            referenceTotalField: 'totalAlunos', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'oralidadeComunicaFormaAdequadaTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'oralidadeComunicaFormaAdequadaPercentual', percent: true, referenceField: 'oralidadeComunicaFormaAdequadaTotal',
            referenceTotalField: 'totalAlunos', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'oralidadeComunicaFormaOriginalTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'oralidadeComunicaFormaOriginalPercentual', percent: true, referenceField: 'oralidadeComunicaFormaOriginalTotal',
            referenceTotalField: 'totalAlunos', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'oralidadeTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'oralidadeTurmasColetadasTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'oralidadeTurmasColetadasPercentual', percent: true, referenceField: 'oralidadeTurmasColetadasTotal',
            referenceTotalField: 'totalTurmas', type: 'integer', calculateTotal: true, calculateType: 'average' },
    ]);

    /* Acompanhamento Mensal: Aceleração e Alfabetização */
    angular.module('app.relatorios').constant('ACOMPANHAMENTO_MENSAL_REPORT_ALFABETIZACAO_ACELERACAO_FIELDS', [
        { name: 'id', type: 'integer', calculateTotal: false, showFooter: false },
        { name: 'detalhamento', type: 'string', calculateTotal: false, showFooter: false },

        { name: 'turmasTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'turmasColetadas', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'turmasPercentual', type: 'integer', calculateTotal: true, percent: true, calculateType: 'average', referenceField: 'turmasColetadas', referenceTotalField: 'turmasTotal' },

        { name: 'andamentoDiasLetivosPrevistosMedia', type: 'integer', calculateTotal: true, calculateType: 'average',
          referenceField: 'andamentoDiasLetivosPrevistos',
          relCalcCustomTotal: function(data) {
            return parseFloat(data.calculations['aulasPrevistasSoma'] / data.calculations['turmasColetadas']).toFixed(1);
          }
        },
        { name: 'andamentoDiasLetivosPrevistos', type: 'integer', calculateTotal: true, calculateType: 'sum', hide: true },
        { name: 'andamentoDiasLetivosDadosMedia', type: 'integer', calculateTotal: true, calculateType: 'average',
          referenceField: 'andamentoDiasLetivosDados',
          relCalcCustomTotal: function(data) {
            return parseFloat(data.calculations['qtDiasLetivos'] / data.calculations['turmasColetadas']).toFixed(1);
          }
        },
        { name: 'andamentoDiasLetivosDados', type: 'integer', calculateTotal: true, calculateType: 'sum', hide: true },
        { name: 'qtDiasLetivos', type: 'integer', calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'aulasPrevistasSoma', type: 'integer', calculateTotal: true, calculateType: 'sum', hide: true},
        { name: 'andamentoDiasLetivosDadosPercentual', type: 'integer', calculateTotal: true, percent: true, calculateType: 'average',
            referenceField: 'andamentoDiasLetivosPrevistos',
            relCalcCustomTotal: function(data) {
            return parseFloat(((data.calculations['andamentoDiasLetivosDados'] / data.calculations['andamentoDiasLetivosPrevistos'])) * 100).toFixed(1);
        }},

        { name: 'professorFaltasJustificadas', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'professorFaltasNaoJustificadas', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'professorFaltasTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'professorFaltasPercentual', type: 'integer', calculateTotal: true, percent: true, calculateType: 'average',
         referenceField: 'professorFaltasTotal',
         relCalcCustomTotal: function(data) {
           return parseFloat(((data.calculations['professorFaltasTotal'] / data.reportDataCount) / (data.calculations['aulasPrevistasSoma']/ data.reportDataCount)) * 100).toFixed(1);
         }
        },
        { name: 'professorLicensas', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'paraCasaNaoFeitosTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'paraCasaNaoFeitosMedia', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'paraCasaNaoFeitos', referenceTotalField: 'matriculaAtual' },
        { name: 'paraCasaNaoFeitos', type: 'integer', calculateTotal: true, calculateType: 'sum', hide: true },
        { name: 'alunosFaltasJustificadas', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'alunosFaltasNaoJustificadas', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'alunosFaltasTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'diasLetivosMatriculaAtual', type: 'integer', calculateTotal: true, calculateType: 'sum', hide: true },
        { name: 'alunosFaltasPercentual',type: 'integer', calculateTotal: true, percent: true, calculateType: 'average' ,
            referenceField: 'alunosFaltasTotal',
            relCalcCustomTotal: function(data) {
              return parseFloat((data.calculations['alunosFaltasTotal'] / ((data.calculations['qtDiasLetivos'] / data.calculations['turmasColetadas']) * data.calculations['matriculaAtual']))*100).toFixed(1);
            }
        },
        { name: 'alunosLivrosLidos', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'alunosLivrosLidosMedia', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'alunosLivrosLidos', referenceTotalField: 'matriculaAtual' },

        { name: 'motivoFaltaMotivoDoenca', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'motivoFaltaDificuldadeDeslocamento', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'motivoFaltaQuestoesSociais', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'motivoFaltaQuestoesPessoais', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'motivoFaltaMotivoFaltaApoio', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'motivoFaltaTrabalhoInfantil', type: 'integer', calculateTotal: true, calculateType: 'sum' },

        { name: 'mediacaoVisitasMedia', type: 'integer', calculateTotal: true, calculateType: 'average', decimal: 2
            ,referenceField: 'mediacaoVisitas',
            relCalcCustomTotal: function(data) {
              return parseFloat(data.calculations['mediacaoVisitas'] / data.calculations['turmasColetadas']);
            }
        },
        { name: 'mediacaoVisitas', type: 'integer', calculateTotal: true, calculateType: 'sum', hide: true },
        { name: 'mediacaoVisitasMediaMes', type: 'integer', calculateTotal: true, calculateType: 'average', decimal: 2
            ,referenceField: 'mediacaoVisitas',
            relCalcCustomTotal: function(data) {
              return parseFloat(data.calculations['mediacaoVisitasMes'] / data.calculations['turmasColetadas']);
            }
        },
        { name: 'mediacaoVisitasMes', type: 'integer', calculateTotal: true, calculateType: 'sum', hide: true },
        { name: 'mediacaoReunioesMedia', type: 'integer', calculateTotal: true, calculateType: 'average', decimal: 2
            ,referenceField: 'mediacaoReunioes',
            relCalcCustomTotal: function(data) {
              return parseFloat(data.calculations['mediacaoReunioes'] / data.calculations['turmasColetadas']);
            }
        },
        { name: 'mediacaoReunioes', type: 'integer', calculateTotal: true, calculateType: 'sum', hide: true },
        { name: 'mediacaoReunioesMediaMes', type: 'integer', calculateTotal: true, calculateType: 'average', decimal: 2
            ,referenceField: 'mediacaoReunioes',
            relCalcCustomTotal: function(data) {
              return parseFloat(data.calculations['mediacaoReunioesMes'] / data.calculations['turmasColetadas']);
            }
        },
        { name: 'mediacaoReunioesMes', type: 'integer', calculateTotal: true, calculateType: 'sum', hide: true },

        { name: 'matriculaInicialAno', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'matriculaInicialMes', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'matriculaTransferenciaRecebidas', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'matriculaTransferenciaRecebidasPercentual', type: 'integer', calculateTotal: true, percent: true, calculateType: 'average', referenceField: 'matriculaTransferenciaRecebidas', referenceTotalField: 'matriculaInicialMes' },
        { name: 'matriculaTransferenciaExpedidas', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'matriculaTransferenciaExpedidasPercentual', type: 'integer', calculateTotal: true, percent: true, calculateType: 'average', referenceField: 'matriculaTransferenciaExpedidas', referenceTotalField: 'matriculaInicialMes' },
        { name: 'matriculaAbandonoNumero', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'matriculaAbandonoNumeroPercentual', type: 'integer', calculateTotal: true, percent: true, calculateType: 'average', referenceField: 'matriculaAbandonoNumero', referenceTotalField: 'matriculaInicialMes' },

        { name: 'matriculaAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'matriculaPercentual', referenceField: 'matriculaAtual', referenceTotalField: 'matriculaInicialMes',
            type: 'integer', calculateTotal: true, percent: true, calculateType: 'average' }
    ]);



    /* Acompanhamento Mensal: Ensino Regular */
    angular.module('app.relatorios').constant('ACOMPANHAMENTO_MENSAL_REPORT_ENSINO_REGULAR_FIELDS', [
        { name: 'id', type: 'integer', calculateTotal: false, showFooter: false },
        { name: 'detalhamento', type: 'string', calculateTotal: false, showFooter: false },

        { name: 'turmasTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'turmasColetadas', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'turmasPercentual', type: 'integer', calculateTotal: true, percent: true, calculateType: 'average', referenceField: 'turmasColetadas', referenceTotalField: 'turmasTotal' },

        { name: 'diasLetivosPrevistosMedia', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'diasLetivosPrevistos', referenceTotalField: 'turmasColetadas' },
        { name: 'diasLetivosPrevistos', type: 'integer', calculateTotal: true, calculateType: 'sum', hide: true },
        { name: 'diasLetivosDadosMedia', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'diasLetivosDados', referenceTotalField: 'turmasColetadas' },
        { name: 'diasLetivosDados', type: 'integer', calculateTotal: true, calculateType: 'sum', hide: true },
        { name: 'diasLetivosDadosPercentual', percent: true, referenceField: 'diasLetivosDados',
            referenceTotalField: 'diasLetivosPrevistos', type: 'integer', calculateTotal: true, calculateType: 'average' },

        { name: 'mediacaoReunioesMedia', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'mediacaoReunioes', referenceTotalField: 'turmasColetadas' },
        { name: 'mediacaoReunioes', type: 'integer', calculateTotal: true, calculateType: 'sum', hide: true },
        { name: 'mediacaoVisitasMedia', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'mediacaoVisitas', referenceTotalField: 'turmasColetadas' },
        { name: 'mediacaoVisitas', type: 'integer', calculateTotal: true, calculateType: 'sum', hide: true },

        { name: 'professorFaltasNaoJustificadas', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'professorFaltasNaoJustificadasPercentual', percent: true, referenceField: 'professorFaltasNaoJustificadas',
            referenceTotalField: 'diasLetivosPrevistos', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'professorFaltasJustificadas', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'professorFaltasJustificadasPercentual', percent: true, referenceField: 'professorFaltasJustificadas',
            referenceTotalField: 'diasLetivosPrevistos', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'professorLicensas', type: 'integer', calculateTotal: true, calculateType: 'sum' },

        { name: 'diasLetivosMatriculaAtual', type: 'integer', calculateTotal: true, calculateType: 'sum', hide: true },
        { name: 'paraCasaNaoFeitosTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'paraCasaNaoFeitosPercentual', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'paraCasaNaoFeitosTotal', referenceTotalField: 'matriculaAtual' },

        { name: 'alunoFaltasNaoJustificadas', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'alunoFaltasNaoJustificadasPercentual', percent: true, referenceField: 'alunoFaltasNaoJustificadas',
            getReferenceTotalField: function(data) {
                return (data.calculations['diasLetivosDados'] / data.calculations['turmasColetadas']) * data.calculations['matriculaAtual'];
            }, type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'alunoFaltasJustificadas', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'alunoFaltasJustificadasPercentual', percent: true, referenceField: 'alunoFaltasJustificadas',
            getReferenceTotalField: function(data) {
                return (data.calculations['diasLetivosDados'] / data.calculations['turmasColetadas']) * data.calculations['matriculaAtual'];
            }, type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'livrosLidosTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'livrosLidosPercentual', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'livrosLidosTotal', referenceTotalField: 'matriculaAtual' },

        { name: 'matriculaInicialAno', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'matriculaInicialMes', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'matriculaMovimentacaoEntrada', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'matriculaMovimentacaoSaida', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'matriculaTransferenciaRecebidas', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'matriculaTransferenciaRecebidasPercentual', percent: true, referenceField: 'matriculaTransferenciaRecebidas',
            referenceTotalField: 'matriculaInicialMes', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'matriculaTransferenciaExpedidas', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'matriculaTransferenciaExpedidasPercentual', percent: true, referenceField: 'matriculaTransferenciaExpedidas',
            referenceTotalField: 'matriculaInicialMes', type: 'integer', calculateTotal: true, calculateType: 'average' },

        { name: 'matriculaAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' },
    ]);

    /* Acompanhamento Mensal: Fórmula da Vitóriar */
    angular.module('app.relatorios').constant('ACOMPANHAMENTO_MENSAL_REPORT_FORMULA_VITORIA_FIELDS', [
        { name: 'id', type: 'integer', calculateTotal: false, showFooter: false },
        { name: 'detalhamento', type: 'string', calculateTotal: false, showFooter: false },
        { name: 'turmasTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'turmasColetadas', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'turmasPercentual', type: 'integer', calculateTotal: true, percent: true, calculateType: 'average', referenceField: 'turmasColetadas', referenceTotalField: 'turmasTotal' },
        { name: 'aulasPrevistasMedia', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'aulasPrevistas', referenceTotalField: 'turmasColetadas' },
        { name: 'aulasPrevistas', type: 'integer', calculateTotal: true, calculateType: 'sum', hide: true },
        { name: 'aulasDadasMedia', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'aulasDadas', referenceTotalField: 'turmasColetadas' },
        { name: 'aulasDadas', type: 'integer', calculateTotal: true, calculateType: 'sum', hide: true },
        { name: 'aulasDadasPercentual', percent: true, type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'aulasDadas', referenceTotalField: 'aulasPrevistas' },
        { name: 'professorFaltasJustificadas', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'professorFaltasNaoJustificadas', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'professorFaltasTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'professorFaltasPercentual', percent: true, referenceField: 'professorFaltasTotal',
            referenceTotalField: 'aulasPrevistas', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'professorLicensas', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'alunosFaltasJustificadas', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'alunosFaltasNaoJustificadas', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'alunosFaltasTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'diasLetivosMatriculaAtual', type: 'integer', calculateTotal: true, calculateType: 'sum', hide: true },
        { name: 'alunosFaltasPercentual', referenceField: 'alunosFaltasTotal',
        getReferenceTotalField: function(data) {
            return parseFloat(Math.round((data.calculations['aulasDadas'] / data.calculations['turmasColetadas']) * 100) / 100).toFixed(1) * data.calculations['matriculaAtual'];
        }, type: 'integer', calculateTotal: true, percent: true, calculateType: 'average' },
        { name: 'mediacaoReunioesMedia', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'mediacaoReunioes', referenceTotalField: 'turmasColetadas' },
        { name: 'mediacaoReunioes', type: 'integer', calculateTotal: true, calculateType: 'sum', hide: true },
        { name: 'mediacaoVisitasMedia', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'mediacaoVisitas', referenceTotalField: 'turmasColetadas' },
        { name: 'mediacaoVisitas', type: 'integer', calculateTotal: true, calculateType: 'sum', hide: true },
        { name: 'matriculaInicialAno', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'matriculaInicialMes', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'matriculaTransferenciaRecebidas', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'matriculaTransferenciaRecebidasPercentual', percent: true, referenceField: 'matriculaTransferenciaRecebidas',
            referenceTotalField: 'matriculaInicialMes' , type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'matriculaTransferenciaExpedidas', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'matriculaTransferenciaExpedidasPercentual', percent: true, referenceField: 'matriculaTransferenciaExpedidas',
            referenceTotalField: 'matriculaInicialMes', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'matriculaAbandonoNumero', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'matriculaAbandonoNumeroPercentual', percent: true, referenceField: 'matriculaAbandonoNumero',
            referenceTotalField: 'matriculaInicialMes', type: 'integer', calculateTotal: true, calculateType: 'average' },

        { name: 'matriculaAtual', type: 'integer', calculateTotal: true, calculateType: 'sum' }
    ]);

    /* Fórmula da Vitória portugues */
    angular.module('app.relatorios').constant('FORMULA_VITORIA_LINGUA_PORTUGUESA_ACOMPANHAMENTO_FIELDS', [
        { name: 'id', type: 'integer', calculateTotal: false, showFooter: false  },
        { name: 'nomeEstado', type: 'string', calculateTotal: false, showFooter: false  },
        { name: 'nomeMunicipio', showOnGroup:['E', 'M'], type: 'string', calculateTotal: false, showFooter: false  },
        { name: 'nomeEscola', showOnGroup:['E'], type: 'string', calculateTotal: false, showFooter: false  },
        { name: 'nomeGrupoIAS', showOnGroup:['8'], type: 'string', calculateTotal: false, showFooter: false  },
        { name: 'noRegionalUf', showOnGroup:['G'], type: 'string', calculateTotal: false, showFooter: false  },
        { name: 'noRegionalMunicipio', showOnGroup:['R'], type: 'string', calculateTotal: false, showFooter: false  },
        { name: 'noTurma', showOnGroup:['T'], type: 'string', calculateTotal: false, showFooter: false  },



        { name: 'quantidadeTurmasAbertas', type: 'integer', calculateTotal: true, calculateType: 'sum', sequencia: 1 },
        { name: 'alunosTotal', type: 'integer', calculateTotal: true, calculateType: 'sum', sequencia: 2 },

        { name: 'leituraModoFragmentadoTotal', type: 'integer', calculateTotal: true, calculateType: 'sum', sequencia: 3 },
        { name: 'leituraModoFragmentadoPercentual', type: 'integer', calculateTotal: true, calculateType: 'average', percent: true, sequencia: 4, referenceField: 3, referenceTotalField: 2},
        { name: 'leituraModoIsoladoTotal', type: 'integer', calculateTotal: true, calculateType: 'sum', sequencia: 5 },
        { name: 'leituraModoIsoladoPercentual', type: 'integer', calculateTotal: true, calculateType: 'average',  percent: true,sequencia: 6, referenceField: 5, referenceTotalField: 2},
        { name: 'leituraConsiderandoContextoTotal', type: 'integer', calculateTotal: true, calculateType: 'sum', sequencia: 7 },
        { name: 'leituraConsiderandoContextoPercentual', type: 'integer', calculateTotal: true, calculateType: 'average',  percent: true,sequencia: 8, referenceField: 7, referenceTotalField: 2 },
        { name: 'leituraOpinaTotal', type: 'integer', calculateTotal: true, calculateType: 'sum', sequencia: 9 },
        { name: 'leituraOpinaPercentual', type: 'integer', calculateTotal: true, calculateType: 'average',  percent: true, sequencia: 10, referenceField: 9, referenceTotalField: 2 },

        { name: 'leituraTotal', type: 'integer', calculateTotal: true, calculateType: 'sum', sequencia: 11 },

        { name: 'turmasColetadasTotal', type: 'integer', calculateTotal: true, calculateType: 'sum', sequencia: 12 },
        { name: 'turmasColetadasPorcentagem', type: 'integer', calculateTotal: true, calculateType: 'average', sequencia: 13 , referenceField: 12, referenceTotalField: 1},

        { name: 'escritaModoFragmentadoTotal', type: 'integer', calculateTotal: true, calculateType: 'sum', sequencia: 14 },
        { name: 'escritaModoFragmentadoPercentual', type: 'integer', calculateTotal: true, calculateType: 'average', sequencia: 15 , referenceField: 14, referenceTotalField: 2 },
        { name: 'escritaPoucoApoioTotal', type: 'integer', calculateTotal: true, calculateType: 'sum', sequencia: 16 },
        { name: 'escritaPoucoApoioPercentual', type: 'integer', calculateTotal: true, calculateType: 'average', sequencia: 17 , referenceField: 16, referenceTotalField: 2 },
        { name: 'escritaApoiaSeBemContextoTotal', type: 'integer', calculateTotal: true, calculateType: 'sum', sequencia: 18 },
        { name: 'escritaApoiaSeBemPercentual', type: 'integer', calculateTotal: true, calculateType: 'average', sequencia: 19 , referenceField: 18, referenceTotalField: 2 },

        { name: 'escritaTotal', type: 'integer', calculateTotal: true, calculateType: 'sum', sequencia: 20 },

        { name: 'turmasColetadasTotal', type: 'integer', calculateTotal: true, calculateType: 'sum', sequencia: 21 },
        { name: 'turmasColetadasPorcentagem', type: 'integer', calculateTotal: true, calculateType: 'average', sequencia: 22 , referenceField: 21, referenceTotalField: 1},


        { name: 'oralidadeOrganizaPrecariamenteTotal', type: 'integer', calculateTotal: true, calculateType: 'sum', sequencia: 23 },
        { name: 'oralidadeOrganizaPrecariamentePercentual', type: 'integer', calculateTotal: true, calculateType: 'average', sequencia: 24 , referenceField: 23, referenceTotalField: 2 },
        { name: 'oralidadeOrganizaFalaTotal', type: 'integer', calculateTotal: true, calculateType: 'sum', sequencia: 25 },
        { name: 'oralidadeOrganizaFalaPercentual', type: 'integer', calculateTotal: true, calculateType: 'average', sequencia: 26 , referenceField: 25, referenceTotalField: 2 },
        { name: 'oralidadeCompreendeEntonacaoTotal', type: 'integer', calculateTotal: true, calculateType: 'sum', sequencia: 27 },
        { name: 'oralidadeCompreendeEntonacaoPercentual', type: 'integer', calculateTotal: true, calculateType: 'average', sequencia: 28 , referenceField: 27, referenceTotalField: 2 },

        { name: 'oralidadeTotal', type: 'integer', calculateTotal: true, calculateType: 'sum', sequencia: 29 },

        { name: 'turmasColetadasTotal', type: 'integer', calculateTotal: true, calculateType: 'sum', sequencia: 30 },
        { name: 'turmasColetadasPorcentagem', type: 'integer', calculateTotal: true, calculateType: 'average', sequencia: 31 , referenceField: 30, referenceTotalField: 1},
    ]);
/* Fórmula da Vitória Matemática – Nível 1 */
angular.module('app.relatorios').constant('ACOMPANHAMENTO_MENSAL_REPORT_MATEMATICA_FORMULA_VITORIA_MATEMATICA_NIVEL_1_FIELDS', [
    { name: 'id', type: 'integer', calculateTotal: false, showFooter: false  },
    { name: 'detalhamento', type: 'string', calculateTotal: false, showFooter: false  },
    { name: 'totalTurmas', type: 'integer', calculateTotal: true, calculateType: 'sum' },
    { name: 'totalAlunos', type: 'integer', calculateTotal: true, calculateType: 'sum' },

    { name: 'leituraLeModoFragmentadoTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
    { name: 'leituraLeModoFragmentadoPercentual', percent: true, referenceField: 'leituraLeModoFragmentadoTotal',
        referenceTotalField: 'totalAlunos', type: 'integer', calculateTotal: true, calculateType: 'average' },
    { name: 'leituraLeFormaIndependenteTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
    { name: 'leituraLeFormaIndependentePercentual', percent: true, referenceField: 'leituraLeFormaIndependenteTotal',
        referenceTotalField: 'totalAlunos', type: 'integer', calculateTotal: true, calculateType: 'average' },
    { name: 'leituraLeInterpretaAutonomiaTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
    { name: 'leituraLeInterpretaAutonomiaPercentual', percent: true, referenceField: 'leituraLeInterpretaAutonomiaTotal',
        referenceTotalField: 'totalAlunos', type: 'integer', calculateTotal: true, calculateType: 'average' },
    { name: 'leituraTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
    { name: 'leituraTurmasColetadasTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
    { name: 'leituraTurmasColetadasPercentual', percent: true, referenceField: 'leituraTurmasColetadasTotal',
        referenceTotalField: 'totalTurmas', type: 'integer', calculateTotal: true, calculateType: 'average' },
    { name: 'resolucaoResolveProblemasComAjudaRepNaoConvecTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
    { name: 'resolucaoResolveProblemasComAjudaRepNaoConvecPercentual', percent: true, referenceField: 'resolucaoResolveProblemasComAjudaRepNaoConvecTotal',
        referenceTotalField: 'totalAlunos', type: 'integer', calculateTotal: true, calculateType: 'average' },
    { name: 'resolucaoResolveProblemasComAjudaRepConvecTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
    { name: 'resolucaoResolveProblemasComAjudaRepConvecPercentual', percent: true, referenceField: 'resolucaoResolveProblemasComAjudaRepConvecTotal',
        referenceTotalField: 'totalAlunos', type: 'integer', calculateTotal: true, calculateType: 'average' },
    { name: 'resolucaoResolveProblemaSemAjudaTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
    { name: 'resolucaoResolveProblemaSemAjudaPercentual', percent: true, referenceField: 'resolucaoResolveProblemaSemAjudaTotal',
        referenceTotalField: 'totalAlunos', type: 'integer', calculateTotal: true, calculateType: 'average' },
    { name: 'resolucaoTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
    { name: 'resolucaoTurmasColetadasTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
    { name: 'resolucaoTurmasColetadasPercentual', percent: true, referenceField: 'resolucaoTurmasColetadasTotal',
        referenceTotalField: 'totalTurmas', type: 'integer', calculateTotal: true, calculateType: 'average' },
    { name: 'resolveQuartoOperacaoComApoioTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
    { name: 'resolveQuartoOperacaoComApoioPercentual', percent: true, referenceField: 'resolveQuartoOperacaoComApoioTotal',
        referenceTotalField: 'totalAlunos', type: 'integer', calculateTotal: true, calculateType: 'average' },
    { name: 'resolveQuartoOperacaoSemApoioTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
    { name: 'resolveQuartoOperacaoSemApoioPercentual', percent: true, referenceField: 'resolveQuartoOperacaoSemApoioTotal',
        referenceTotalField: 'totalAlunos', type: 'integer', calculateTotal: true, calculateType: 'average' },
    { name: 'resolveQuartoOperacaoConvencionaisTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
    { name: 'resolveQuartoOperacaoConvencionaisPercentual', percent: true, referenceField: 'resolveQuartoOperacaoConvencionaisTotal',
        referenceTotalField: 'totalAlunos', type: 'integer', calculateTotal: true, calculateType: 'average' },
    { name: 'compreensaoTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
    { name: 'compreensaoTurmasColetadasTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
    { name: 'compreensaoTurmasColetadasPercentual', percent: true, referenceField: 'compreensaoTurmasColetadasTotal',
        referenceTotalField: 'totalTurmas', type: 'integer', calculateTotal: true, calculateType: 'average' }
]);
    /* Fórmula da Vitória Matemática – Nível 2 */
    angular.module('app.relatorios').constant('ACOMPANHAMENTO_MENSAL_REPORT_MATEMATICA_FORMULA_VITORIA_MATEMATICA_NIVEL_2_FIELDS', [
        { name: 'id', type: 'integer', calculateTotal: false, showFooter: false  },
        { name: 'detalhamento', type: 'string', calculateTotal: false, showFooter: false  },
        { name: 'totalTurmas', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'totalAlunos', type: 'integer', calculateTotal: true, calculateType: 'sum' },

        { name: 'leituraLeModoFragmentadoTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'leituraLeModoFragmentadoPercentual', percent: true, referenceField: 'leituraLeModoFragmentadoTotal',
            referenceTotalField: 'totalAlunos', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'leituraLeFormaIndependenteTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'leituraLeFormaIndependentePercentual', percent: true, referenceField: 'leituraLeFormaIndependenteTotal',
            referenceTotalField: 'totalAlunos', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'leituraLeInterpretaAutonomiaTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'leituraLeInterpretaAutonomiaPercentual', percent: true, referenceField: 'leituraLeInterpretaAutonomiaTotal',
            referenceTotalField: 'totalAlunos', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'leituraTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'leituraTurmasColetadasTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'leituraTurmasColetadasPercentual', percent: true, referenceField: 'leituraTurmasColetadasTotal',
            referenceTotalField: 'totalTurmas', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'resolucaoResolveProblemasComAjudaRepNaoConvecTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'resolucaoResolveProblemasComAjudaRepNaoConvecPercentual', percent: true, referenceField: 'resolucaoResolveProblemasComAjudaRepNaoConvecTotal',
            referenceTotalField: 'totalAlunos', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'resolucaoResolveProblemasComAjudaRepConvecTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'resolucaoResolveProblemasComAjudaRepConvecPercentual', percent: true, referenceField: 'resolucaoResolveProblemasComAjudaRepConvecTotal',
            referenceTotalField: 'totalAlunos', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'resolucaoResolveProblemaSemAjudaTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'resolucaoResolveProblemaSemAjudaPercentual', percent: true, referenceField: 'resolucaoResolveProblemaSemAjudaTotal',
            referenceTotalField: 'totalAlunos', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'resolucaoTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'resolucaoTurmasColetadasTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'resolucaoTurmasColetadasPercentual', percent: true, referenceField: 'resolucaoTurmasColetadasTotal',
            referenceTotalField: 'totalTurmas', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'compreensaoRepresentaCalculoAdicaoSubtracaoTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'compreensaoRepresentaCalculoAdicaoSubtracaoPercentual', percent: true, referenceField: 'compreensaoRepresentaCalculoAdicaoSubtracaoTotal',
            referenceTotalField: 'totalAlunos', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'compreensaoRepresentaCalculoMultiplicacaoDivisaoTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'compreensaoRepresentaCalculoMultiplicacaoDivisaoPercentual', percent: true, referenceField: 'compreensaoRepresentaCalculoMultiplicacaoDivisaoTotal',
            referenceTotalField: 'totalAlunos', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'compreensaoRepresentaFracoesNumerosDecimaisTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'compreensaoRepresentaFracoesNumerosDecimaisPercentual', percent: true, referenceField: 'compreensaoRepresentaFracoesNumerosDecimaisTotal',
            referenceTotalField: 'totalAlunos', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'compreensaoIdentificaFormasGeometricasTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'compreensaoIdentificaFormasGeometricasPercentual', percent: true, referenceField: 'compreensaoIdentificaFormasGeometricasTotal',
            referenceTotalField: 'totalAlunos', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'compreensaoLeInterpretaGraficoBarrasTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'compreensaoLeInterpretaGraficoBarrasPercentual', percent: true, referenceField: 'compreensaoLeInterpretaGraficoBarrasTotal',
            referenceTotalField: 'totalAlunos', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'compreensaoLeInterpretaGraficoLinhasTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'compreensaoLeInterpretaGraficoLinhasPercentual', percent: true, referenceField: 'compreensaoLeInterpretaGraficoLinhasTotal',
            referenceTotalField: 'totalAlunos', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'compreensaoUltilizaEscritaAlgebricaTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'compreensaoUltilizaEscritaAlgebricaPercentual', percent: true, referenceField: 'compreensaoUltilizaEscritaAlgebricaTotal',
            referenceTotalField: 'totalAlunos', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'compreensaoIdentificaResolveEquacoesPrimeiroGrauTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'compreensaoIdentificaResolveEquacoesPrimeiroGrauPercentual', percent: true, referenceField: 'compreensaoIdentificaResolveEquacoesPrimeiroGrauTotal',
            referenceTotalField: 'totalAlunos', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'compreensaoVocabularioMedidaTempoTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'compreensaoVocabularioMedidaTempoPercentual', percent: true, referenceField: 'compreensaoVocabularioMedidaTempoTotal',
            referenceTotalField: 'totalAlunos', type: 'integer', calculateTotal: true, calculateType: 'average' },
        { name: 'compreensaoTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'compreensaoTurmasColetadasTotal', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'compreensaoTurmasColetadasPercentual', percent: true, referenceField: 'compreensaoTurmasColetadasTotal',
            referenceTotalField: 'totalTurmas', type: 'integer', calculateTotal: true, calculateType: 'average' }
    ]);

    /* Acompanhamento Mensal: Fluxo de Aulas */
    angular.module('app.relatorios').constant('ACOMPANHAMENTO_MENSAL_REPORT_FLUXO_AULAS_FIELDS', [

        { name: 'detalhamento', type: 'string', calculateTotal: false, showFooter: false  },

        { name: 'livro11a10', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'livro111a20', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'livro121a30', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'livro21a10', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'livro211a20', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'livro221a30', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'livro31a10', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'livro311a20', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'livro321a30', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'livro41a10', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'livro411a20', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'livro421a30', type: 'integer', calculateTotal: true, calculateType: 'sum' },

        { name: 'total', type: 'integer', calculateTotal: true, calculateType: 'sum' }

    ]);

    angular.module('app.relatorios').constant('ACOMPANHAMENTO_MENSAL_REPORT_FLUXO_AULAS_SE_LIGA_FIELDS', [
        { name: 'detalhamento', type: 'string', calculateTotal: false, showFooter: false  },
        { name: 'aulas1a5', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'aulas6a10', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'aulas11a15', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'aulas16a20', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'aulas21a25', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'aulas26a30', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'aulas31a35', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'aulas36a40', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'aulas41a45', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'aulas46a50', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'aulas51a55', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'aulas56a60', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'aulas61a65', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'aulas66a70', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'aulas71a75', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'aulas76a80', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'aulas81a85', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'aulas86a90', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'aulas91a95', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'aulas96a100', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'aulas101a105', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'aulas106a110', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'aulas111a115', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'aulas116a120', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'total', type: 'integer', calculateTotal: true, calculateType: 'sum' }

    ]);

    /* Acompanhamento Mensal: Municipio - Relatório A - Quantitativo */
    angular.module('app.relatorios').constant('ACOMPANHAMENTO_MENSAL_REPORT_ACOMPANHAMENTO_MUNICIPIO_RELATORIO_A_QUANTITATIVO_FIELDS', [

        { name: 'report.id', type: 'integer', calculateTotal: false, showFooter: false, alignment: 'center' },

        { name: 'inRelationToTeachers.description', type: 'string', calculateTotal: false },

        { name: 'program.yes.ammount', type: 'integer', calculateTotal: true, calculateType: 'sum', alignment: 'right' },
        { name: 'program.yes.percent', type: 'integer', calculateTotal: true, calculateType: 'average', alignment: 'right' },

        { name: 'program.not.ammount', type: 'integer', calculateTotal: true, calculateType: 'sum', alignment: 'right' },
        { name: 'program.not.percent', type: 'integer', calculateTotal: true, calculateType: 'average', alignment: 'right' },

        { name: 'program.total.ammount', type: 'integer', calculateTotal: true, calculateType: 'sum', alignment: 'right' },
        { name: 'program.total.percent', type: 'integer', calculateTotal: true, calculateType: 'average', alignment: 'right' }

    ]);

    /* Perfil do Atendimento das Unidades Escolares Anual */
    angular.module('app.relatorios').constant('PERFIL_ATENDIMENTO_UNIDADES_ESCOLARES_ANUAL_FIELDS', [
        { name: 'report.id', type: 'integer', calculateTotal: false, showFooter: false, alignment: 'center' },
        { name: 'teachingModality', type: 'string', calculateTotal: false },
        { name: 'stage', type: 'string', calculateTotal: false },
        { name: 'cycle', type: 'string', calculateTotal: false }
    ]);

    angular.module('app.relatorios').constant('ACOMPANHAMENTO_MENSAL_FLUXO_DE_OFICINAS_FORMULA_VITORIA_LINGUA_PORTUGUESA_FIELDS', [

        { name: 'detalhamento', type: 'string', calculateTotal: false, showFooter: false  },

        { name: 'poemas1e3', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'poemas4e6', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'poemas7e10', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'carta1e3', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'carta4e6', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'carta7e10', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'contos1e3', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'contos4e6', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'contos8e11', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'total', type: 'integer', calculateTotal: true, calculateType: 'sum' }

    ]);

    angular.module('app.relatorios').constant('ACOMPANHAMENTO_MENSAL_FLUXO_DE_OFICINAS_FORMULA_VITORIA_MATEMATICA_FIELDS', [

        { name: 'detalhamento', type: 'string', calculateTotal: false, showFooter: false  },

        { name: 'unidade11e6', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'unidade17e13', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'unidade114e20', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'unidade21e6', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'unidade27e13', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'unidade214e20', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'unidade31e6', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'unidade37e13', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'unidade314e20', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'unidade41e6', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'unidade47e13', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'unidade414e20', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'unidade51e6', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'unidade57e13', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'unidade514e20', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'unidade61e6', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'unidade67e13', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'unidade614e20', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'unidade71e6', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'unidade77e13', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'unidade714e20', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'unidade81e6', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'unidade87e13', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'unidade814e20', type: 'integer', calculateTotal: true, calculateType: 'sum' },
        { name: 'total', type: 'integer', calculateTotal: true, calculateType: 'sum', hide: true }
    ]);

    angular.module('app.relatorios').constant('ACOMPANHAMENTO_MENSAL_MUNICIPIO_QUANTITATIVO_FIELDS', [
        { name: 'detalhamento', type: 'string', calculateTotal: false, showFooter: false  },
        { name: 'sim', type: 'integer', calculateTotal: true, calculateType: 'sum'  },
        { name: 'simPercentual', type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average', referenceField: 'sim', referenceTotalField: 'total' },
        { name: 'nao', type: 'integer', calculateTotal: true, calculateType: 'sum'  },
        { name: 'naoPercentual', type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average', referenceField: 'nao', referenceTotalField: 'total' },
        { name: 'total', type: 'integer', calculateTotal: true, calculateType: 'sum'  },
        { name: 'percentual', type: 'integer', calculateTotal: true, calculateType: 'average', referenceField: 'nao', referenceTotalField: 'total' },
    ]);

    angular.module('app.relatorios').constant('ACOMPANHAMENTO_MENSAL_MUNICIPIO_QUANTITATIVO_RELATORIO_I_FIELDS', [
        { name: 'detalhamento', type: 'string', calculateTotal: false, showFooter: false  },
        { name: 'sim', type: 'integer', calculateTotal: true, calculateType: 'sum'  },
        { name: 'simPercentual', type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average', referenceField: 'sim', referenceTotalField: 'total' },
        { name: 'nao', type: 'integer', calculateTotal: true, calculateType: 'sum'  },
        { name: 'naoPercentual', type: 'integer', calculateTotal: true, percent:"true", calculateType: 'average', referenceField: 'nao', referenceTotalField: 'total' },
    ]);

}());
