(function(){
    'use strict';

    angular.module('app.core').service('validateService', ValidateService);
    ValidateService.$inject = [];

    function ValidateService() {
        this.date = function(date) {
            var isValid = moment(date, 'DD/MM/YYYY',true).isValid();

            if (isValid) {
                return true;
            }

            return false;
        };
    }

}());
