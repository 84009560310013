(function() {
    'use strict';

    angular.module('app.core').constant('TABLE_FIELDS_TYPE', {
        AVERAGE: 'average',
        INTEGER: 'integer',
        PERCENT: 'percent',
        STRING: 'string',
        NUMBER: 'number',
        DATE: 'date',
        TIME: 'time',
        DATE_TIME: 'datetime'
    });

    angular.module('app.core').constant('TABLE_FIELDS', {
        OPCAO: [
            { name: "nome", description: "Nome", type: "string" },
            { name: "descricao", description: "Descrição", type: "string" },
            { name: "valor", description: "Valor", type: "integer" },
            { name: "situacao.value", description: "Situação", type: "string" }
        ],
        ESCALA: [
            { name: "nome", description: "Nome", type: "string" },
            { name: "descricao", description: "Descrição", type: "string" },
            { name: "valor", description: "Valor", type: "integer" },
            { name: "situacao.value", description: "Situação", type: "string" }
        ],
        PROGRAMA_CRITERIO: [
            { name: "criterioSelecao.nome", description: "Nome", type: "string" },
            { name: "criterioSelecao.descricao", description: "Descrição", type: "string" }
        ],
        PROGRAMA_RUBRICA: [
            { name: "rubrica.nome", description: "Nome", type: "string" }
        ],
        PERFIL_ACESSO: [
            { name: "id", description: "Código", type: "integer" },
            { name: "nome", description: "Nome", type: "string" },
            { name: "descricao", description: "Descrição", type: "string" },
            { name: "situacao.value", description: "Situação", type: "string" }
        ],
        COLETA: [
            { name: "formulario.id", description: "Código", type: "integer" },
            { name: "formulario.nome", description: "Nome", type: "string" },
            { name: "formulario.descricao", description: "Descrição", type: "string" },
            { name: "formulario.tipo.value", description: "Tipo de Exibição", type: "string" },
            { name: "formulario.situacao.value", description: "Situação", type: "string" }
        ],
        COLETA_RUBRICA: [
            { name: "formulario.id", description: "Código", type: "integer" },
            { name: "formulario.nome", description: "Nome", type: "string" },
            { name: "formulario.descricao", description: "Descrição", type: "string" },
            { name: "formulario.tipo.value", description: "Tipo de Exibição", type: "string" },
            { name: "formulario.situacao.value", description: "Situação", type: "string" }
        ],
        CONTRATO_ESCOLA: [
            { name: "id", description: "Código", type: "integer" },
            { name: "codigoINEP", description: "INEP", type: "string" },
            { name: "nome", description: "Nome", type: "string" },
            { name: "uf.nome", description: "UF", type: "string" },
            { name: "municipio.nome", description: "Município", type: "string" }
        ],
        CONTRATO_ESCOLA_ASSOCIADA: [
            { name: "escola.id", description: "Código", type: "integer" },
            { name: "escola.codigoINEP", description: "INEP", type: "string" },
            { name: "escola.nome", description: "Nome", type: "string" },
            { name: "periodoLetivo.anoLetivo", description: "Período Letivo", type: "string" },
            { name: "adesao.value", description: "Critério de Adesão", type: "string" },
            { name: "programa.nome", description: "Programa", type: "string" }
        ],
        ESCOLA_ATRIBUTOS: [
            { name: "atributo.id", description: "Código", type: "integer" },
            { name: "atributo.nome", description: "Nome", type: "string" },
            { name: "programa.nome", description: "Programa", type: "string" },
            { name: "atributo.situacao.value", description: "Situação", type: "string" },
            { name: "atributo.tipoAtributo.value", description: "Tipo", type: "string" }
        ],
        MOVIMENTACOES_MENSAIS: [
            { name: "referenciaFormatada", description: "Referência", type: "string" },
            { name: "matriculaAtual", description: "Matrícula Atual", type: "string" },
            { name: "transferenciaRecebida", description: "Transferências Recebidas", type: "string" },
            { name: "transferenciaExpedida", description: "Transferências Expedidas", type: "string" },
            { name: "abandono", description: "Abandono", type: "string" }
        ],
        PROFESSOR_ATRIBUTOS: [
            { name: "atributo.id", description: "Código", type: "integer" },
            { name: "atributo.nome", description: "Nome", type: "string" },
            { name: "programa.nome", description: "Programa", type: "string" },
            { name: "atributo.situacao.value", description: "Situação", type: "string" },
            { name: "atributo.tipoAtributo.value", description: "Tipo", type: "string" }
        ],
        ALUNO_ATRIBUTOS: [
            { name: "atributo.id", description: "Código", type: "integer" },
            { name: "atributo.nome", description: "Nome", type: "string" },
            { name: "programa.nome", description: "Programa", type: "string" },
            { name: "atributo.situacao.value", description: "Situação", type: "string" },
            { name: "atributo.tipoAtributo.value", description: "Tipo", type: "string" }
        ],
        ESCOLA_PERIODICIDADE: [
            { name: "programa.nome", description: "Programa", type: "string" },
            { name: "periodoLetivo.anoLetivo", description: "Período Letivo", type: "string" },
            { name: "tipoPeriodo.nome", description: "Tipo Período", type: "string" },
            { name: "referencia.nome", description: "Referência", type: "string" },
            { name: "mesCalendario.mes", description: "Mês Calendário", type: "string" },
            { name: "dataInicio", description: "Data Início", type: "string" },
            { name: "dataFim", description: "Data Fim", type: "string" }
        ]
    });

}());
