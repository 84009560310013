(function() {
    'use strict';

    angular.module('app.opcao', ['app.core']).config(config);
    config.$inject = ['$stateProvider', 'APP_STATE'];

    function config($stateProvider, APP_STATE) {
        $stateProvider
            .state(APP_STATE.OPCAO.BASE, {
                url: '/opcoes',
                abstract: true,
                views: {
                    'content': {
                        template: '<div ui-view="internal"></div>',
                        controller: 'OpcaoController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state(APP_STATE.OPCAO.SEARCH, {
                url: '/search',
                views: {
                    'internal': {
                        templateUrl: 'opcao/partials/opcao-search.html'
                    }
                },
                data: {
                    permission: "OPC:RE",
                    breadCrumb: [{
                            display: "Formulários",
                            active: false
                        },
                        {
                            display: "Opções",
                            active: true
                        }
                    ],
                    search: {
                        enabled: false
                    }
                }
            })
            .state(APP_STATE.OPCAO.NEW, {
                url: '/new',
                views: {
                    'internal': {
                        templateUrl: 'opcao/partials/opcao-form.html',
                        controller: 'OpcaoFormController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    permission: "OPC:CR",
                    breadCrumb: [{
                            display: "Formulários",
                            active: false
                        },
                        {
                            display: "Opções",
                            active: false,
                            stateName: APP_STATE.OPCAO.SEARCH
                        },
                        {
                            display: "Nova",
                            active: true
                        }
                    ],
                    search: {
                        placeHolder: "Digite o nome da opção",
                        enabled: false
                    }
                }
            })
            .state(APP_STATE.OPCAO.EDIT, {
                url: '/edit/:id',
                views: {
                    'internal': {
                        templateUrl: 'opcao/partials/opcao-form.html',
                        controller: 'OpcaoFormController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    editMode: null
                },
                data: {
                    permission: "OPC:VI",
                    breadCrumb: [{
                            display: "Formulários",
                            active: false
                        },
                        {
                            display: "Opções",
                            active: false,
                            stateName: APP_STATE.OPCAO.SEARCH
                        },
                        {
                            display: "Edição",
                            active: true
                        }
                    ],
                    search: {
                        placeHolder: "Digite o nome da opção",
                        enabled: false
                    }
                }
            });
    }
}());